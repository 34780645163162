import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import { cloneDeep, isEqual } from 'lodash';

import { useStepsState, useStepsDispatch } from '../../provider';
import { reserveSlot } from '../../../schedule/api';
import { Slot } from '../../models';

import Modal from '../../../shared/components/Modal';
import Slots from '../../../shared/components/Slots';
import { colors } from '../../../../styles/colors';
import { Button } from '../../../shared/components/styled';
import { Minor } from '../../../shared/models';
import { format } from 'date-fns';
import { config } from '../../../../config';
import { Breakpoints } from '../../../../dictionaries';

const Container = styled.div`
  max-width: 700px;
  @media (max-width: ${Breakpoints.sm}px) {
   padding: 50px 0px 0px 0px;
  }
  @media (max-width: ${Breakpoints.md}px) {
   padding: 50px 0px 0px 0px;
  }
`;

const Title = styled.h2`
  color: ${colors.fullBlack};
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 13px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 240px;
  margin: 30px -5px 0;

  & > * {
    margin: 0 5px;
  }
  @media (max-width: ${Breakpoints.sm}px) {
    display: grid;
    row-gap: 10px;
  }
`;

interface Props {
  showModal: boolean;
  closeModal: () => void;
}

const ChangeDateTimeModal: React.FC<Props> = ({ showModal, closeModal }) => {
  const { form } = useStepsState();
  const { updateFormValues } = useStepsDispatch();
  const appointment = { form };
  const { slot, isRapidTest } = form;
  const rollbackAppt = useMemo(() => cloneDeep(appointment), [showModal]);
  let newSlot: Slot | null = slot;
  const [loading, setLoading] = useState(false);

  return (
    <Modal open={showModal} onClose={closeModal}>
      <Container>
        <Title>Change Date & Time</Title>
        <Formik
          initialValues={{ slot }}
          onSubmit={() => {
            updateFormValues({ slot: newSlot });
            closeModal();
          }}
        >
          {() => (
            <Form>
              <Slots
                alwaysShowDatePicker
                appointment={form}
                isNextStep={false}
                onFieldUpdate={(update) => {
                  newSlot = { ...update.slot } as Slot;
                  updateFormValues({ slot: update.slot });
                }}
                onChangeDate={(newDepartureDate) => {
                  updateFormValues({
                    slot: undefined,
                    reservationId: null,
                    date: format(newDepartureDate, config.dateFormat),
                  });
                }}
                isSlotsLoading={loading}
                setSlotsLoading={setLoading}
                isWalkUpTest={form?.isWalkUpTest}
              />
              <ButtonsContainer>
                <Button
                  libraryType="default"
                  size="sm"
                  type="button"
                  onClick={async () => {
                    // updateFormValues({ rollbackAppt });

                    let form: any = rollbackAppt?.form;
                    updateFormValues({ ...form });

                    let prevSlot: Slot | null = rollbackAppt.form.slot;
                    let numberOfGuests: number =
                      rollbackAppt?.form.minors?.length + 1;
                    if (prevSlot && !isEqual(prevSlot, newSlot)) {
                      await reserveSlot(
                        prevSlot,
                        newSlot,
                        numberOfGuests,
                        form.isRapidTest,
                        form.reservationId,
                        false,
                        form
                      ).then((res) => {
                        if (res.data.status == 200) {
                          updateFormValues({
                            reservationId: res.data.reservationId,
                            reservationCount: res.data.numberOfGuests,
                          });
                          closeModal();
                        } else if (res.data.status == 400) {
                          updateFormValues({
                            slot: undefined,
                          });
                          closeModal();
                        }
                      });
                    }
                    closeModal();
                  }}
                  className='btn elevated-btn'
                >
                  Cancel
                </Button>
                <Button libraryType="primary" size="sm" type="submit" className='btn fill-btn'>
                  Change
                </Button>
              </ButtonsContainer>
            </Form>
          )}
        </Formik>
      </Container>
    </Modal>
  );
};

export default ChangeDateTimeModal;

//ltst