import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Form, Formik, FormikErrors } from 'formik';
import { parse, format } from 'date-fns';
import * as Sentry from '@sentry/react';

import {
    Button,
    Container,
    Content,
    PageHeader,
    PageSubTitle,
    PageTitle,
} from '../../shared/components/styled';
import { useManageDispatch } from '../provider';
import { isUSPhone, isValidEmail } from '../../../validations';
import { ManagePage } from '../dictionaries';
import {
    getAppointmentByPhoneAndDate,
    getAppointmentByConfirmationId,
    getAppointmentByPhoneorEmail,
    getQbenchOrderById,
    initiateOTP,
    validateOTP
} from '../api';
import { DatepickerField } from '../../shared/components/Datepicker';
import { colors } from '../../../styles/colors';
import { Appointment } from '../../shared/models';
import { QbenchOrderResponse, Result } from '../../admin/models';
import { appointmentList } from '../models';
import { config } from '../../../config';

import Input from '../../shared/components/form/Input';
import FormLabel from '../../shared/components/form/FormLabel';
import Phone from '../../schedule/components/Phone';


interface NoticeProps {
    margin?: string;
}

const Notice = styled.p`
  margin: ${({ margin }: NoticeProps) => margin || '27px 0 65px'};
  font-size: 14px;
`;

const Agreement = styled.p`
  margin: 0 0 24px 0;
`;

const Error = styled.div`
  color: ${colors.red};
  margin-top: 15px;
  text-align: center;
`;

const Divider = styled.h2`
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #1a96db;
  line-height: 0.1em;
  margin: 30px 0 20px 0;
  color: #2a5f87;
  font-size: 16px;
  font-weight: 700;
`;

const DividerSpan = styled.span`
  background: #fff;
  padding: 0 10px;
`;

const ResentText = styled.div`
    cursor: pointer;
    text-align: center;
    margin: 15px 0px 15px 0px;
    text-decoration: underline;
    &:hover {
        color: #3999D0  
    }
`;

interface ButtonProps {
    libraryType: 'primary' | 'default';
    accentColor?: string;
    size?: 'sm' | 'lg';
  }

const ReturnButton = styled.button`
    border: 1px solid
    ${({ accentColor }: ButtonProps) => accentColor || colors.darkBlue};
    font-size: ${({ size }: ButtonProps) => (size === 'sm' ? '14px' : '20px')};
    line-height: 1.6;
    border-radius: 5px;
    padding: ${({ size }: ButtonProps) =>
    size === 'sm' ? '4px 15px' : '9px 20px'};
    font-weight: ${({ size }: ButtonProps) =>
    size === 'sm' ? 'normal' : 'bold'};
    width: 100%;
    text-align: center;
    color: ${({ libraryType, accentColor }: ButtonProps) => {
    return libraryType === 'primary'
    ? colors.white
    : accentColor || colors.darkBlue;
    }};
    background: ${({ libraryType, accentColor }: ButtonProps) =>
    libraryType === 'primary' ? accentColor || colors.darkBlue : colors.white};
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:disabled {
    background: ${({ libraryType, accentColor }: ButtonProps) =>
    libraryType === 'primary'
        ? accentColor || colors.darkBlue50
        : colors.white};
    border: 1px solid transparent;
    color: ${({ libraryType, accentColor }: ButtonProps) => {
    return libraryType === 'primary'
        ? colors.white
        : accentColor || colors.darkBlue50;
    }};
    }
`;

function normalizeResultName(result: string) {
    const res = result.toLowerCase();

    if (res === 'detected') {
        return 'POSITIVE';
    }

    return 'NEGATIVE';
}

const VerifyIdentity: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const [isOTP, setOTPState] = useState(false)

    const [isOTPResent, setResentOTPState] = useState(false)

    const { goToPage, updateAppointment, updateResults, updateResultsScreen } = useManageDispatch();

    // useEffect(()=>{
    //     getAppointmentByPhoneorEmail("data", "data");
    // })

    interface FormValues {
        phone: string;
        email: string;
        otp: string;
        // birthDate: string;
        // confirmationId: string;
    }

    const validateForm = (values: any) => {

        let errors: FormikErrors<FormValues> = {};

        // if (values.confirmationId !== '') {
        //   let parts: string[] = values.confirmationId.replace('_', '-').split('-');
        //   if (!(parts.length === 4 && parts[0].toUpperCase() === 'HAL')) {
        //     errors.confirmationId = 'Please enter valid confirmation Id';
        //   }
        // }
        // let confrimationIdExists: boolean =
        //   values.confirmationId !== '' && typeof (errors.confirmationId) !== undefined;
        // if (!confrimationIdExists) {
        //   if (values.phone !== '' && !isUSPhone.test(values.phone)) {
        //     errors.phone = 'Please enter valid US phone number';
        //   }
        // if (values.phone === '') {
        //   errors.phone = 'Required field';
        // }
        // if (!values.birthDate) {
        //   errors.birthDate = 'Required field';
        // }
        // if (values.phone === '' || !values.birthDate) {
        //   errors.confirmationId =
        //     "ConfirmationId OR patient's date of birth and mobile number required";
        // }
        // }

        if (values.email == "" && values.phone == "") {
            errors.phone = 'Required field';
            errors.email = 'Required field';
        }

        if (values.email !== "" && !isValidEmail.test(values.email)) {
            errors.email = 'Please enter valid email address';
        }

        if (values.phone !== '' && !isUSPhone.test(values.phone)) {
            errors.phone = 'Please enter valid US phone number';
        }
        if (values.email !== "") {
            if (!isValidEmail.test(values.email)) {
                errors.email = 'Please enter valid email address';
            }
        }

        if (isOTP && values.otp == "") {
            errors.otp = 'Please enter valid your verfication code sent to your Email or Phone';
        }

        if (values.email !== '' && values.phone !== '') {
            setError('Please use any one of the method to continue.');

        }

        return errors;
    };


    async function getAppointments(otp: string, phone: string, email: string) {
        return new Promise(async (resolve, reject) => {
            let validation: any = await validateOTP(otp.trim(), phone.trim(), email.trim());

            // const validation = true;

            if (validation) {
                let result: any = [];
                if ((email === '' && phone !== '') || (email !== '' && phone === '')) {
                    setLoading(true);
                    setError(null);

                    if (email) {
                        result = await getAppointmentByPhoneorEmail("", email.trim());
                    } else if (phone) {
                        result = await getAppointmentByPhoneorEmail(phone.trim(), "");
                    }
                    setLoading(false);

                    if (result.length > 0) {
                        setLoading(false);
                        updateResultsScreen(result);
                        goToPage(ManagePage.TestResults);
                    }else{
                        setError('Appointment is not found');
                    }

                } else {
                    setError('Pleasse use any one of the method to continue.');
                }

            } else {
                setError("Invalid verification code");
                setResentOTPState(true);
            }
        })
    }

    return (
        <Container>
            <Content>
                <PageHeader>
                    <PageTitle>First, We need to verify your identity.</PageTitle>
                    <PageSubTitle>
                        One quick step to protect your privacy. All fields are required.
                    </PageSubTitle>
                </PageHeader>
                <Formik
                    initialValues={{
                        email: "",
                        phone: "",
                        // otp: "257527"
                        otp: ""
                        // confirmationId: '',
                    }}
                    onSubmit={async ({
                        phone,
                        email,
                        otp,
                        // birthDate,
                        // confirmationId,
                    }: {
                        phone: string;
                        email: string;
                        otp: string;
                        // birthDate: Date | null;
                        // confirmationId: string;
                    }) => {

                        if ((email === '' && phone !== '') || (email !== '' && phone === '')) {
                            if (!isOTP) {
                                const result = await initiateOTP(phone, email);
                                // const result = true;
                                if (result) {
                                    setOTPState(true)
                                }
                            }

                        } else {
                            setError('Pleasse use any one of the method to continue.');
                        }

                        // if (confirmationId || birthDate) {
                        //   setLoading(true);
                        //   setError(null);

                        //   let result: { id: any; data: any } | null = null;
                        //   try {
                        //     if (confirmationId) {
                        //       result = await getAppointmentByConfirmationId(confirmationId.toUpperCase());
                        //     } else if (birthDate) {
                        //       result = await getAppointmentByPhoneAndDate(phone, birthDate);
                        //     }

                        //     if (result && result.data) {
                        //       const appointment: Appointment = result.data;
                        //       const appointment: Appointment = result;

                        //       updateAppointment(appointment);

                        //       const qbenchOrderResult = await getQbenchOrderById(
                        //         result.data.qbenchId
                        //       );

                        //       const qbenchOrder: QbenchOrderResponse =
                        //         qbenchOrderResult.data;

                        //       const results: Result[] = [];

                        //       qbenchOrder.samples.forEach((sample) => {
                        //         const completedTests = sample.tests
                        //           .filter(
                        //             (test: { results: string | null; state: string }) =>
                        //               test.state === 'COMPLETED'
                        //           )
                        //           .sort((a: any, b: any) => {
                        //             const aDate = parse(
                        //               a.complete_date,
                        //               config.dateTimeFormat,
                        //               new Date()
                        //             );
                        //             const bDate = parse(
                        //               b.complete_date,
                        //               config.dateTimeFormat,
                        //               new Date()
                        //             );

                        //             return bDate.getTime() - aDate.getTime();
                        //           });

                        //         if (completedTests.length > 0) {
                        //           const latestTest = completedTests[0];

                        //           results.push({
                        //             confirmationId: appointment.confirmationId || '',
                        //             testResult: latestTest.results
                        //               ? normalizeResultName(latestTest.results)
                        //               : '',
                        //             testMethod: latestTest.assay.method,
                        //             sampleType:
                        //               sample.accessioning_type?.value || 'Nasopharyngeal',
                        //             patientName: `${sample.X_PATIENT_FIRST_NAME} ${sample.X_PATIENT_LAST_NAME}`,
                        //             patientDOB: sample.X_PATIENT_DOB,
                        //             collectionDate: appointment.date || '',
                        //             collectionLocation: appointment.location,
                        //             provider: sample.X_ASSIGNING_AUTHORITY,
                        //             reviewedBy:
                        //               latestTest.X_REVIEWED_BY?.text ||
                        //               config.reportData.reviewedBy,
                        //             qbenchId: appointment.qbenchId || '',
                        //             completeDate: format(
                        //               parse(
                        //                 latestTest.complete_date,
                        //                 config.dateTimeFormat,
                        //                 new Date()
                        //               ),
                        //               config.dateFormat
                        //             ),
                        //             specimenCount: qbenchOrder.X_SPECIMEN_COUNT,
                        //             patientId: sample.X_PATIENT_EXTERNAL_ID,
                        //             sampleId: sample.id,
                        //             accessionId: sample.X_EXTERNAL_ACCESSION_ID,
                        //             technician: config.reportData.technician,
                        //           });
                        //         }
                        //       });

                        //       if (results.length > 0) {
                        //         updateResults(results);
                        //       }

                        //       goToPage(
                        //         results.length === 0
                        //           ? ManagePage.TestingInfo
                        //           : ManagePage.TestResults
                        //       );
                        //     } else {
                        //       setLoading(false);
                        //       setError('Appointment is not found');
                        //     }
                        //   } catch (e) {
                        //     console.error(e);

                        //     Sentry.withScope((scope) => {
                        //       scope.setTag('scope', 'verifyIdentity');
                        //       Sentry.captureException(e);
                        //     });

                        //     setLoading(false);
                        //     setError('Error while fetching appointment');
                        //   }
                        // }
                    }}
                    enableReinitialize={false}
                    validate={validateForm}
                >
                    {({ values }) => (
                        <Form>
                            {/* 
                                <FormLabel label="Confirmation #">
                                    <Input
                                    name="confirmationId"
                                    noBottomMargin
                                    placeholder="eg. HAL-LASX-1XXXXXX-1XX"
                                    />
                                </FormLabel> 
                            */}
                            {
                                !isOTP &&
                                <>
                                    <FormLabel label="email">
                                        <Input
                                            name="email"
                                            noBottomMargin
                                        // disabled={isOTP}
                                        />
                                    </FormLabel>
                                    <Divider>
                                        <DividerSpan>OR</DividerSpan>
                                    </Divider>
                                    {/* <FormLabel label="Patient’s Date of Birth">
                                    <DatepickerField
                                    name="birthDate"
                                    inputPlaceholder="MM/DD/YYYY"
                                    isRequired={false}
                                    onChangeInput={(e: any) => {
                                        let errors: FormikErrors<FormValues> = {};
                                        let v = e.target.value;
                                        if (v.match(/^\d{2}$/) !== null) {
                                        e.target.value = v + '/';
                                        } else if (v.match(/^\d{2}\/\d{2}$/) !== null) {
                                        e.target.value = v + '/';
                                        } else if (v.match(/^\d{2}\/\d{2}\/\d{4}$/)) {
                                        // setFieldValue('birthDate', e.target.value);
                                        } else {
                                        errors.birthDate = "Enter a valid date"
                                        }
                                        return errors;
                                    }}
                                    />
                                </FormLabel> */}
                                    <FormLabel label="Mobile Number">
                                        <Input
                                            name="phone"
                                            noBottomMargin
                                        />
                                        <Notice margin={"27px 0 35px"} >
                                            The same 10-digit number you used when scheduling.
                                    </Notice>
                                    </FormLabel>
                                </>
                            }

                            {isOTP &&
                                <FormLabel label="Enter your verfication code sent to your email or phone">
                                    <Input
                                        name="otp"
                                        noBottomMargin

                                    />
                                    <Notice margin={"27px 0 35px"} />
                                </FormLabel>

                            }
                            <Agreement>
                                By verifying this information you legally confirm that you are
                                or are acting as caregiver for this person.
                            </Agreement>
                            {
                                isOTP ?
                                    <div style={{ display: "flex", }}>
                                        <ReturnButton disabled={loading} libraryType="default" onClick={() => { setOTPState(false); setResentOTPState(false); setError(null); }} >
                                            Return
                                        </ReturnButton>
                                        <div style={{ margin: "10px" }} />
                                        <Button onClick={() => { getAppointments(values.otp, values.phone, values.email); setResentOTPState(false); }} libraryType="primary" disabled={loading}>
                                            {loading ? 'Processing...' : 'Verify Identity'}
                                        </Button>
                                    </div>
                                    :
                                    <Button type="submit" libraryType="primary" disabled={loading}>
                                        {loading ? 'Processing...' : 'Get Verfication Code'}
                                    </Button>
                            }
                            {error && <Error>{error}</Error>}

                            {isOTP && isOTPResent &&
                                <ResentText
                                    onClick={ async () => {

                                        if ((values.email === '' && values.phone !== '') || (values.email !== '' && values.phone === '')) {
                                            if (isOTP) {
                                                const result = await initiateOTP(values.phone, values.email);
                                                // const result = true
                                                if (result) {
                                                    // setOTPState(true)
                                                    setError(null);
                                                    setResentOTPState(false)
                                                }
                                            }
                
                                        } else {
                                            setError('Pleasse use any one of the method to continue');
                                        }

                                    }}
                                >
                                    Resend Verification Code
                                </ResentText>
                            }
                        </Form>
                    )}
                </Formik>
            </Content>
        </Container>
    );

};

export default VerifyIdentity;
