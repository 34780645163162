import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Breakpoints } from '../../../../../../dictionaries';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import Modal from './../../../../../shared/components/Modal';
import { colors } from '../../../../../../styles/colors';
import { Button } from './../../../../../shared/components/styled';
import { initiateOTP } from '../../../../../manage/api';
import { MultiTenancyService } from '../../../../../../services/mutitenancy.service';
import GoogleAnalytics from '../../../../../../GoogleAnalytics';
const Container = styled.div``;
const Title = styled.h2`
  color: ${colors.fullBlack};
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 25px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 120px;
  margin: 0 -5px;

  & > * {
    margin: 0 5px;
  }
  @media (max-width: ${Breakpoints.sm}px) {
    display: grid;
    row-gap: 10px;
    margin-top: 30px;
  }
`;
const Text = styled.div`
  text-align: left;
  font-size: 15px;
  font-weight: 500;
  margin: 0px 25px;
`;
const SubContainer = styled.div`
  margin: 20px 25px;
`;
const Hide = styled.div`
  color: ${colors.green};
  margin-top: 5px;
  font-size: 15px;
  line-height: 16px;
  font-weight: bold;
  cursor: none;
`;
type Props = {
  handleVerifyScreen: () => void;
  open: boolean;
  handleEmailClose: () => void;
  signin: () => void;
  email: any;
};
const ConfirmationEmailModal: React.FC<Props> = ({
  open,
  handleVerifyScreen,
  handleEmailClose,
  signin,
  email,
}) => {
  const multiTenancyService: MultiTenancyService = new MultiTenancyService();
    useEffect(()=>{
    GoogleAnalytics('/ConfirmEmail');
  },[])
  return (
    <Modal
      open={open}
      onClose={handleEmailClose}
      maxWidth={600}
      blockScroll={false}
    >
      <Container>
        <Title>
          <AiOutlineQuestionCircle size={22} color={'#000'} />
          {' Your email has been updated'}
        </Title>
        <Text>
          Please confirm the changes by clicking the link that was sent to your
          email address. The changes will not take effect until this has been
          completed.
        </Text>
        <br />
        <Text>
          <Hide>
            By clicking "OK", a verification link will be sent to your new
            email. Your email address will not be changed until you verify.
          </Hide>
        </Text>
      </Container>
      <SubContainer>
        <ButtonsContainer>
          <Button
            onClick={async () => {
              handleEmailClose();
              handleVerifyScreen();

              await multiTenancyService.intiateOTP({
                email: email,
                phone: null,
                isUserOTP: true,
                validationMethod: 'email',
                form: '',
                localStorageData: '',
                isChangeEmail: true,
              });
            }}
            libraryType="primary"
            size="sm"
            type="submit"
            className='btn fill-btn'
          >
            {'OK'}
          </Button>
        </ButtonsContainer>
      </SubContainer>
    </Modal>
  );
};

export default ConfirmationEmailModal;
