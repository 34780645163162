import React, { useState } from 'react';
import styled from 'styled-components';
import { Form, Formik } from 'formik';

import {
  Container,
  Content,
  PageHeader,
  SubHeaderDescription,
  PageTitle, LogoCard, LogoImage
} from '../../shared/components/styled';
import { colors } from '../../../styles/colors';

import Header from '../../shared/components/Header';
import Loader from '../../shared/components/Loader';
import Footer from '../../shared/components/Footer';
import { Breakpoints } from '../../../dictionaries';
import wsllogo from '../../../assets/WLABS_CMYK.png';
import { useHistory } from 'react-router-dom';
import { PrimeContainer } from './ConfirmAccount';
import { useStepsState } from '../provider';
import { Register } from '../../shared/models';
import { useSharedDispatch, useSharedState } from '../../shared/provider';
import { registerLogin } from '../api';

export const WLLogo = styled.div`
  height: 60px;
  width: 270px;
  background: url(${wsllogo}) no-repeat center center;
  background-size: cover;
  margin-bottom: 15px;
  display: inline-block;

  @media (max-width: ${Breakpoints.sm}px) {
    margin-top: 20px;
  }
`;

const CTAButton = styled.button`
  display: inline-block;
  width: 60%;
  color: ${colors.white};
  text-align: center;
  padding: 15px;
  background: ${colors.orange};
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  border: 0;
  font-size: 18px;
  line-height: 12px;
  border-radius: 32px;
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 5%;
`;

const Loading = styled.div`
  color: ${colors.green};
  margin-top: 15px;
  text-align: center;
`;

const SuccessfulAccountCreation: React.FC = () => {
  let history = useHistory();
  const { setloggedUserData } = useSharedDispatch();
  const { userData, partnerLogo:{logo} } = useSharedState();

  const [loading, setLoading] = useState<string | null>(null);


  const {
    form: {
      email,
      password,
      postCode,
      firstName,
      birthDate,
      lastName,
      middleName,
      isINTNameFormat,
      phone,
      address,
      sex,
      race,
      ethnicity,
      passportCountry,
      passportNo,
      minors,
      hasMinors,
    },
  } = useStepsState();
  const { airline, verifyForm: {verifyEmail, verifyPassword}, isVerifyReq } = useSharedState();

  const redirect = async() => {
    const data: Register = {
      email: isVerifyReq ? verifyEmail : email,
      password: isVerifyReq ? verifyPassword : password,
      postCode: postCode,
      firstName: firstName,
      lastName: lastName,
      middleName: middleName,
      isINTNameFormat: isINTNameFormat,
      birthDate: birthDate,
      phone: phone,
      hasMinors: hasMinors,
      minors: minors,
      sex: sex,
      race: race,
      ethnicity: ethnicity,
      address: {
        zipCode: address.zipCode,
        state: address.state,
        county: address.county,
        address: address.address,
        city: address.city,
      },
      passportCountry: passportCountry,
      passportNo: passportNo,
      destination: 'HI', // HI
      IsAirline: airline === '' ? true : airline === 'HAL' ? true : false,
      airlineCode: airline ? airline : '',
      travelType: airline === '' ? 'DOM' : airline === 'HAL' ? 'DOM' : 'INT',
      cPartnerID: '',
      isVerified: true,
      registeringFor: '',
      guardian: {
        firstName: '',
        lastName:  '',
        relationship: '',
        phone: '',
        email: '',
      },
      isNotHavePermanentAddress: false
    };
    // history.push('/user/profile')
    setloggedUserData(data);
    await handleSignIn({
      email: isVerifyReq ? verifyEmail : email,
      password: isVerifyReq ? verifyPassword : password,
    })
    history.push('/user/appointments');
  };

  const redirectToBookAppt = async() => {
    await handleSignIn({
      email: isVerifyReq ? verifyEmail : email,
      password: isVerifyReq ? verifyPassword : password,
    })
    history.push('/bookappointment');

  }

  const handleSignIn = async (params: { email: string; password: string }) => {
    setLoading("Loading...");
    try {
      const loggedUser = await registerLogin(params);
      if (loggedUser?.data?.status == 200) {
        setloggedUserData(loggedUser?.data.data);
        localStorage.setItem('user', JSON.stringify(userData));
        setLoading('');

      } else {
        // setError(loggedUser?.data?.message);
        setLoading('');

      }
      // setLoading(false);
    } catch (e) {
      // setError('Incorrect email or password');
      setLoading('');
    }
  };

  return (
    <div>
      <Header isPWLogo isHelp isLogout />
      <PrimeContainer>
        <Container style={{ backgroundColor: '#fff' }}>
          <Content style={{ textAlign: 'center' }}>
            <>
            <LogoCard>
              <LogoImage src={logo.length ? logo : ''} />
            </LogoCard>
              <PageHeader>
                <PageTitle color={colors.fullBlack} textAlign="center">
                  Your account has been created!
                </PageTitle>
              </PageHeader>
              <SubHeaderDescription>
                Congrats! Your Worksite Labs account has been created. <br></br>{' '}
                What would you like to do next?
              </SubHeaderDescription>

              <CTAButton
                type="button"
                onClick={redirectToBookAppt}
              >
                Book Appointment
              </CTAButton>

              <CTAButton
                type="button"
                // onClick={() => handleSignIn({})}
                onClick={redirect}
                className='btn fill-btn'
              >
                Manage Account
              </CTAButton>

              <Loading>{loading}</Loading>
            </>
          </Content>
        </Container>
        <div style={{ marginTop: '40px' }}>
          <Footer />
        </div>
      </PrimeContainer>
    </div>
  );
};

export default SuccessfulAccountCreation;

//ltst