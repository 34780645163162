import React, { useState } from 'react';
import styled from 'styled-components';
import { Breakpoints } from '../../../../dictionaries';
import { Form, Formik } from 'formik';
import { useSharedDispatch, useSharedState } from '../../../shared/provider';
import { useRegisterStepsDispatch, useStepsState } from '../../provider';
import Modal from './../../../shared/components/Modal';
import { Button } from './../../../shared/components/styled';
import { colors } from '../../../../styles/colors';
import { updateRegister } from '../../api';
import CheckboxGroup from '../../../shared/components/form/CheckboxGroup';
import { NonFormikCheckbox } from '../../../shared/components/form/Checkbox';


const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 240px;
    margin: 0 -5px;

    & > * {
        margin: 0 5px;
    }
    @media (max-width: ${Breakpoints.sm}px) {
        display: grid;
        row-gap: 10px;
    }
`;

const Title = styled.h2`
    color: ${colors.fullBlack};
    font-weight: 700;
    font-size: 28px;
    margin-bottom: 25px;
`;
const Container = styled.div``;

type Props = {
    open: boolean;
    setOpenHubSpot: any;
    isProfile: boolean;
};
const ChangeHubSpotModal: React.FC<Props> = ({
    setOpenHubSpot,
    open,
    isProfile,
}) => {
    const [loading, setLoading] = useState(false);
    const { userData } = useSharedState();
    let isHubSpotContact: any = userData?.isHubSpotContact;
    const { setloggedUserData } = useSharedDispatch();
    const { updateFormValues } = useRegisterStepsDispatch();
    const [isHubSpot, setIsHubSpot] = useState<boolean>(isHubSpotContact || false);
console.log('isHubSpotContact,isHubSpot ::', isHubSpotContact, isHubSpot);

    const updateChangeOnClick = async (values: any) => {
        setLoading(true);
        if (isProfile) {
            let resultdata = await updateRegister({
                email: userData?.email,
                isHubSpotContact: values?.isHubSpotContact,
            });
            if (resultdata?.data?.status === 200) {
                setloggedUserData(resultdata?.data?.data);
                localStorage.setItem('user', JSON.stringify(userData));
            }
        }
        setOpenHubSpot(false);
        setLoading(false);
    };
    return (
        <Modal open={open} onClose={() => setOpenHubSpot(false)} maxWidth={800}>
            <Container>
                <Title>Marketing Communications</Title>
                {/* <Form> */}
                    <NonFormikCheckbox
                        name="isHubSpotContact"
                        checked={isHubSpot}
                        onChange={(checked) => setIsHubSpot(checked)}
                    >
                        I would like to receive marketing communications and
                        updates from Worksite Labs.
                    </NonFormikCheckbox>
                    <ButtonsContainer>
                        <Button
                            libraryType="default"
                            size="sm"
                            type="button"
                            onClick={() => {
                                setOpenHubSpot(false);
                            }}
                            className="btn elevated-btn"
                        >
                            Cancel
                        </Button>
                        <Button
                            libraryType="primary"
                            size="sm"
                            type="submit"
                            className="btn fill-btn"
                            onClick={() => {
                                updateFormValues({ isHubSpotContact:isHubSpot });
                                updateChangeOnClick({ isHubSpotContact:isHubSpot });
                            }}
                        >
                            {loading ? 'Processing...' : 'Confirm'}{' '}
                        </Button>
                    </ButtonsContainer>
                {/* </Form> */}
            </Container>
        </Modal>
    );
};

export default ChangeHubSpotModal;
