import React, { useState } from 'react';
import styled from 'styled-components';
import { Breakpoints } from '../../../../dictionaries';
import { Form, Formik } from 'formik';
import Input from '../../../shared/components/form/Input';
import { useSharedDispatch, useSharedState } from '../../../shared/provider';
import Modal from './../../../shared/components/Modal';
import { Button } from './../../../shared/components/styled';
import { colors } from '../../../../styles/colors';
import { updateRegister } from '../../api';
import FormLabel from '../../../shared/components/form/FormLabel';
import Select from '../../../shared/components/form/Select';
import { country } from '../../../schedule/components/PersonalInformation/PassportCountry';

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 240px;
  margin: 0 -5px;

  & > * {
    margin: 0 5px;
  }
  @media (max-width: ${Breakpoints.sm}px) {
    display: grid;
    row-gap: 10px;
  }
`;

const Title = styled.h2`
  color: ${colors.fullBlack};
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 25px;
`;
const Container = styled.div``;
const PassportRow = styled.div`
  display: flex;
  align-items: center;
  gap: 50px;

  & > *:first-child {
    width: 32%;
  }

  & > *:last-child {
    width: 32%;
  }
  @media (max-width: ${Breakpoints.sm}px) {
    gap: 5px;

    & > *:first-child {
      width: 50%;
    }

    & > *:last-child {
      width: 50%;
      margin-top: -35px;
    }
  }
`;
type Props = {
  open: boolean;
  close: () => void;
};
const ChangePassportModal: React.FC<Props> = ({close, open }) => {
  const [loading, setLoading] = useState(false);
  const { userData } = useSharedState();
  const { setloggedUserData } = useSharedDispatch();

  const updateChangeOnClick = async (values: any) => {
    setLoading(true);
    let resultdata = await updateRegister({
      email: userData?.email,
      passportCountry: values.passportCountry,
      passportNo: values.passportNo,
    });
    if (resultdata?.data?.status === 200) {
      setloggedUserData(resultdata?.data?.data);
      localStorage.setItem('user', JSON.stringify(resultdata?.data?.data));
      close();
    }
    setLoading(false);
  };

  return (
    <Modal open={open} onClose={close} maxWidth={800}>
      <Container>
        <Title>Enter your information</Title>
        <Formik
          enableReinitialize
          initialValues={{
            passportCountry: userData?.passportCountry,
            passportNo: userData?.passportNo,
          }}
          onSubmit={(values) => {
            updateChangeOnClick(values);
          }}
        >
          {() => (
            <Form>
              <PassportRow>
                <FormLabel label="Passport Country">
                  <Select<string>
                    name="passportCountry"
                    options={country}
                    validate={(value) => {
                      if (!value) {
                        return 'Required field';
                      }
                    }}
                  />
                </FormLabel>
                <FormLabel label="Passport #">
                  <Input
                    name="passportNo"
                    validate={(value) => {
                      if (!value || !value.trim().length) {
                        return 'Required field';
                      }
                    }}
                    isRequired
                  />
                </FormLabel>
              </PassportRow>
              <ButtonsContainer>
                <Button
                  libraryType="default"
                  size="sm"
                  type="button"
                  onClick={() => close()}
                  className='btn elevated-btn'
                >
                  Cancel
                </Button>
                <Button libraryType="primary" size="sm" type="submit" className='btn fill-btn'>
                  {loading ? 'Processing...' : 'Confirm'}{' '}
                </Button>
              </ButtonsContainer>
            </Form>
          )}
        </Formik>
      </Container>
    </Modal>
  );
};

export default ChangePassportModal;
