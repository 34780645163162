import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { Breakpoints } from '../../../../dictionaries';
import { Form, Formik } from 'formik';
import Input from '../../../shared/components/form/Input';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { useSharedDispatch, useSharedState } from '../../../shared/provider';
import { useRegisterStepsDispatch, useStepsState } from '../../provider';
import Modal from './../../../shared/components/Modal';
import { Button } from './../../../shared/components/styled';
import { colors } from '../../../../styles/colors';
import { updateRegister } from '../../api';
import FormLabel from '../../../shared/components/form/FormLabel';
import { config } from '../../../../config';
import { DatepickerField } from '../../../shared/components/Datepicker';
import { differenceInYears, format, parse } from 'date-fns';

const Content = styled.div`
  width: 100%;

  @media (min-width: ${Breakpoints.md}px) {
    width: 540px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 240px;
  margin: 0 -5px;

  & > * {
    margin: 0 5px;
  }
  @media (max-width: ${Breakpoints.sm}px) {
    display: grid;
    row-gap: 10px;
  }
`;

const Title = styled.h2`
  color: ${colors.fullBlack};
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 25px;
`;
const Container = styled.div``;
const InputRow = styled.div`
  display: flex;
  flex-direction: column;
  margin: -8px 0;

  @media (min-width: ${Breakpoints.sm}px) {
    flex-direction: row;
    margin: 0 -8px;
  }

  @media (min-width: ${Breakpoints.lg}px) {
    margin: 0 -15px;
  }

  & > * {
    flex-basis: 100%;
    margin: 8px 0;

    @media (min-width: ${Breakpoints.sm}px) {
      margin: 0 8px;
    }

    @media (min-width: ${Breakpoints.lg}px) {
      margin: 0 15px;
    }
  }
`;
type Props = {
  open: boolean;
  handleDobClose: () => void;
  isProfile: boolean;
};
const ChangeDobModal: React.FC<Props> = ({
  handleDobClose,
  open,
  isProfile,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const {
    form: { email, birthDate },
  } = useStepsState();
  const { userData } = useSharedState();
  const { setloggedUserData } = useSharedDispatch();
  const { updateFormValues, goToNextStep, goToPrevStep } = useRegisterStepsDispatch();

  const updateChangeOnClick = async (values: any) => {
    setLoading(true);

    if (isProfile) {
      let resultdata:any = await updateRegister({
        email: isProfile ? userData?.email : email,
        birthDate: values?.birthDate,
      });
      if (resultdata?.data?.status === 200) {
        setloggedUserData(resultdata?.data?.data);
        localStorage.setItem('user', JSON.stringify(resultdata?.data?.data));
      }
    }

    handleDobClose();
    setLoading(false);
  };

  return (
    <Modal open={open} onClose={handleDobClose} maxWidth={400}>
      <Container>
        <Title>Enter your information</Title>
        <Formik
          initialValues={{
            birthDate: isProfile
              ? userData?.birthDate &&
                parse(userData?.birthDate, config.dateFormat, new Date())
              : birthDate && parse(birthDate, config.dateFormat, new Date()),
          }}
          onSubmit={(values) => {
            updateFormValues({
              ...values,
              birthDate:
                values.birthDate && format(values.birthDate, config.dateFormat),
            });
            updateChangeOnClick({
              birthDate:
                values.birthDate && format(values.birthDate, config.dateFormat),
            });
          }}
        >
          {({ setFieldValue }) => {
            return (
              <Form>
                <InputRow>
                  <FormLabel label="Date of Birth">
                    <DatepickerField
                      name="birthDate"
                      inputPlaceholder="MM/DD/YYYY"
                      validate={(value) => {
                        let error;

                        if (differenceInYears(new Date(), value) < 18) {
                          error = 'You must be 18 years old or above';
                        }

                        return error;
                      }}
                    />
                  </FormLabel>
                </InputRow>
                <ButtonsContainer>
                  <Button
                    libraryType="default"
                    size="sm"
                    type="button"
                    onClick={() => {
                      handleDobClose();
                    }}
                    className='btn elevated-btn'
                  >
                    Cancel
                  </Button>
                  <Button libraryType="primary" size="sm" type="submit" className='btn fill-btn'>
                    {loading ? 'Processing...' : 'Confirm'}{' '}
                  </Button>
                </ButtonsContainer>
              </Form>
            );
          }}
        </Formik>
      </Container>
    </Modal>
  );
};

export default ChangeDobModal;
