import React, { useEffect, useState } from 'react';
import { Wrapper } from '../../../shared/components/styled';
import { AdminManagePage } from './dictionaries';
import { useAdminState } from '../../provider';
import ManageScreen from "./components/ManageScreen";
import MangeResults from "./components/MangeResults";
import ManageReschedule from "./components/ManageReschedule";
import ManageRescheduleConfirm from "./components/ManageRescheduleConfirm";
import ManageCancel from "./components/ManageCancel";
import ManageCancelConfirm from "./components/ManageCancelConfirm";


const pages = {
    [AdminManagePage.ManageScreen]: ManageScreen,
    [AdminManagePage.MangeResults]: MangeResults,
    [AdminManagePage.ManageReschedule]: ManageReschedule,
    [AdminManagePage.ManageRescheduleConfirm]: ManageRescheduleConfirm,
    [AdminManagePage.ManageCancel]: ManageCancel,
    [AdminManagePage.ManageCancelConfirm]: ManageCancelConfirm,
  };
  

const AdminManage: React.FC = () => {
    const { currentManagePage } = useAdminState();
    
    const Page = pages[currentManagePage] || null;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return(
        <Wrapper backgroundColor="#FFFFFF" >
            <div>
                <Page />
            </div>
        </Wrapper>
    )
}

export default AdminManage;