import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import styled from 'styled-components';
import {
    Container,
    Content,
    PageHeader,
    PageTitle,
} from '../../../shared/components/styled';
import { Breakpoints } from '../../../../dictionaries';

import FormLabel from '../../../shared/components/form/FormLabel';
import NavigationButtons from '../../../shared/components/NavigationButtons';
import Input from '../../../shared/components/form/Input';
import ZipCodeInput from './ZipCodeInput';
import Select from '../../../shared/components/form/Select';
import { useManageDispatch } from '../../../manage/provider';
import { useSharedDispatch, useSharedState } from '../../../shared/provider';
import { useStepsDispatch, useStepsState } from '../../../schedule/provider';
import Checkbox from '../../../shared/components/form/Checkbox';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { makeStyles } from '@material-ui/core/styles';
import { isFromUrl } from '../../../../utils';
import { states } from './states';
import { country } from '../../../schedule/components/PersonalInformation/PassportCountry';
import GoogleAnalytics from '../../../../GoogleAnalytics';
import { config } from '../../../../config';
import { Grid } from '@material-ui/core';
const CityStateRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > *:first-child {
    width: 60%;
  }

  & > *:last-child {
    width: 38%;
  }
  @media (max-width: ${Breakpoints.sm}px) {
    & > *:last-child {
      margin-top: 35px;
    }
  }
`;

const useStyles = makeStyles({
    countryDropDown: {
        borderRadius: '10px',
        width: '100%',
        border: "2px solid #619c8a",
        boxShadow: "0px 0px 4px 0px #4749a0c7",
        fontSize: "16px",
        lineHeight: "24px",
        padding: "15px 28px",
        webkitTransition: "all 0.15s ease-in-out",
        transition: "all 0.15s ease-in-out",
    
    },

    regionDropDown: {
        borderRadius: '10px',
        width: '100%',
        border: "2px solid #619c8a",
        boxShadow: "0px 0px 4px 0px #4749a0c7",
        fontSize: "16px",
        lineHeight: "24px",
        padding: "12px 20px",
        webkitTransition: "all 0.15s ease-in-out",
        transition: "all 0.15s ease-in-out",
    },
    regionRequiredDropDown: {
        padding: '14px',
        borderRadius: '10px',
        width: '210px',
        borderColor: 'red',
    },
});

const AddressWthOutReg: React.FC = () => {
    const { form } = useStepsState();
    useEffect(() => {
        GoogleAnalytics(form, '/Address');
        handlePaymentMethod()
        updateFormValues({payingMethod:''})
    }, [])
    const {
        updateFormValues,
        goToNextStep,
        goToPrevStep,
        goToSkip2Step,
        goToSkipStep,
        goToSkip3Step
    } = useStepsDispatch();
    const {
        isNotHavePermanentAddress,
        location,
        testSelection,
        address,
        cPartnerID,
        destination,
        travelType
    } = form;
    const [insurance, setInsurance] = useState(false);
    const [noInsurance, setNoInsurance] = useState(false);
    const [creditCard, setCreditCard] =useState(false);

    useEffect(() => {
        updateFormValues({
            address: !isNotHavePermanentAddress
                ? {
                    ...form.address,
                    //  state: states.find((state) => state.value === address.state),
                    country: form.address.country,
                    state: form.address.state,
                }
                : {
                    zipCode: location?.zipcode,
                    address: location?.address1,
                    city: location?.city,
                    country: location?.country || 'United States',
                    state: location?.state,
                },
        });
    }, [isNotHavePermanentAddress]);

    const [isCountryState, setCountryState] = useState<boolean>(false);
    const updateAddressValue = (newValue: any) => {
        updateFormValues({
            address: {
                ...address,
                ...newValue,
            },
        });
        validateAddressForm(newValue);
    };
    const classes = useStyles();
    const isURL: boolean = isFromUrl();

    const { updateStandradTest } = useManageDispatch();
    const { upateTestType } = useSharedDispatch();
    const { isWalkUp } = useSharedState()
    const validateAddressForm = (newValue: any) => {
        let validAddress = {
            ...address,
            ...newValue,
        };
        if (newValue.country) {
            validAddress.state = '';
        }
        if (
            validAddress.address == '' ||
            validAddress.city == '' ||
            validAddress.zipCode == '' ||
            validAddress.state == ''
        ) {
            setCountryState(false);
        } else {
            setCountryState(true);
        }
    };

    const handlePaymentMethod = () => {
	
        if (location?.isCreditCard.standard || location?.isCreditCard.antigen || location?.isCreditCard.rapid || location?.isCreditCard.express) {
          setCreditCard(true);
        } else {
          setCreditCard(false);
        }
        if (location?.isInsurance.standard || location?.isInsurance.antigen || location?.isInsurance.rapid || location?.isInsurance.express) {
          setInsurance(true);
        } else {
          setInsurance(false);
        }
        if (location?.isNoInsurance.standard || location?.isNoInsurance.antigen || location?.isNoInsurance.rapid || location?.isNoInsurance.express) {
          setNoInsurance(true);
        } else {
          setNoInsurance(false);
        }
    }
    return (
        <Container>
            <Content>
                <PageHeader>
                    <PageTitle>What is your address?</PageTitle>
                </PageHeader>

                <Formik
                    enableReinitialize
                    initialValues={{
                        address: !isNotHavePermanentAddress
                            ? {
                                ...address,
                                //  state: states.find((state) => state.value === address.state),
                                country: address.country,
                                state: address.state,
                                zipCode: address.zipCode,
                            }
                            : {
                                zipCode: location?.zipcode,
                                address: location?.address1,
                                city: location?.city,
                                country: location?.country,
                                state: location?.state,
                            },
                        isNotHavePermanentAddress,
                    }}
                    onSubmit={(values: any) => {
                        updateFormValues({
                            address: isNotHavePermanentAddress
                                ? {
                                    ...address,
                                    ...values.address,
                                    state: address.state || values.address.state || null,
                                    country: address.country || values.address.country || null,
                                }
                                : {
                                    ...address,
                                    state: address.state || null,
                                    country: address.country || null,
                                },
                            isNotHavePermanentAddress: values.isNotHavePermanentAddress,
                        });
                        if(location.qbenchCustomerId===config.qbenchCustomerId.SFOAirport_BARTStation && destination?.value=='JPN' && travelType.value=='INT' ){
                            updateFormValues({payingMethod:'Credit card',hasInsurance:false,isRapidTest:true})
                            // goToNextStep()
                            goToSkip3Step()
                        //   } else if ((location?.isInsuranceDisabled !== undefined && location.isInsuranceDisabled === true && location.qbenchCustomerId === config.qbenchCustomerId.SFOAirport_BARTStation)) {
                        //     updateFormValues({ payingMethod: 'Credit card', hasInsurance: false });
                        //     goToSkipStep();
                        //     goToSkipStep();
                    } else if (insurance && !creditCard && !noInsurance ) {
                        updateFormValues({ payingMethod: 'Insurance' });
                        updateFormValues({ hasInsurance: true });
                        goToSkipStep();
                        goToSkipStep();
                    } else if (!insurance && creditCard && !noInsurance ) {
                        updateFormValues({ payingMethod: "Credit Card" });
                        if((!location?.standardTest || !location?.isCreditCard.standard) && location?.isExpressSameDay && location?.isCreditCard.express){
                            updateFormValues({isExpressSameDayTest: true})
                        }else if((!location?.standardTest || !location?.isCreditCard.standard) && (!location?.isExpressSameDay || !location?.isCreditCard.express) && location?.rapidTest && location?.isCreditCard.rapid ){
                            updateFormValues({isRapidTest: true})
                        }else if((!location?.standardTest || !location?.isCreditCard.standard) && (!location?.isExpressSameDay || !location?.isCreditCard.express) && (!location?.rapidTest || !location?.isCreditCard.rapid) && location?.isAntigen && location?.isCreditCard.antigen ) {
                            updateFormValues({isAntigen : true})
                        }
                        goToSkipStep();
                        goToSkipStep();
                        goToNextStep();
                    } else if (!insurance && !creditCard && noInsurance ) {
                        updateFormValues({ payingMethod: "No Insurance" });
                        goToSkipStep();
                        goToNextStep();

                        }else if(testSelection === "flight" && location?.disableTravelInsurance){
                            updateFormValues({payingMethod:'Credit Card',hasInsurance:false})
                            goToSkipStep()
                            goToSkipStep()
                        }
                        // if (isWalkUp.active) {
                        //     goToNextStep()
                        // }
                        // if(location.qbenchCustomerId === config.qbenchCustomerId.LasVegasSpringValley)
                        // {
                        //     goToSkip2Step()
                        //     goToNextStep()
                        // }
                        // if (
                        //     (!isURL &&
                        //         (testSelection === 'flight' || testSelection === 'general') &&
                        //         form.address.country !== 'United States') ||
                        //     (location?.hasInsurance !== undefined &&
                        //         location.hasInsurance === true)
                        // ) {
                        //     updateFormValues({ payingMethod: 'Credit Card' });
                        //     goToSkipStep();
                        //     goToSkipStep();
                        //     goToNextStep();
                        // }
                        // else if (
                        //     testSelection !== 'school'
                        // ) {
                        //     goToNextStep();
                        // } else if (cPartnerID === 'POR001' || testSelection === 'school') {
                        //     updateFormValues({ payingMethod: 'Insurance' });
                        //     updateFormValues({ hasInsurance: true });
                        //     upateTestType(true);
                        //     updateStandradTest(true);
                        //     goToNextStep();
                        //     goToSkipStep();
                        //     goToNextStep();
                        // }
                        // else if (location?.qbenchCustomerId===238) {
                        //     updateFormValues({ hasInsurance: true,payingMethod: 'Insurance' })
                        //     goToSkipStep()
                        //     goToSkipStep()
                        // } 
                        else goToNextStep();

                        if(location?.cPartnerID==='KEN001'|| testSelection==='kern'){
                            updateFormValues({isAntigen: true})
                            // goToSkipStep();
                            // goToNextStep()
                        }
                        // SFO FLOW allows RAPID Test for the locationID 237
                        // if (location?.qbenchCustomerId === config.qbenchCustomerId.SFOAirport_BARTStation && testSelection === 'sfoairport') {
                        //     updateFormValues({ hasInsurance: true, payingMethod: 'Insurance' });
                        //     goToNextStep();
                        //     goToSkipStep();
                        //     // goToNextStep();
                        // }
                        // if (((testSelection === 'flight' || testSelection === 'general') && form.address.country === 'United States') &&
                        //     ([config.qbenchCustomerId.WestPalmBeach_AmericanResearchLabs,
                        //     config.qbenchCustomerId.FortLauderdale_AmericanResearchLabs,
                        //     config.qbenchCustomerId.PalmBeachGardens_AmericanResearchLabs,
                        //     config.qbenchCustomerId.WestPalmBeach_CTRX].includes(location?.qbenchCustomerId))) {
                        //     updateFormValues({ payingMethod: 'Credit card', hasInsurance: false });
                        //     goToNextStep();
                        //     goToNextStep();
                        //     goToNextStep();
                        //     goToNextStep();
                        //     //goToSkipStep();
                        // }
                    }}
                >
                    {(values: any) => (
                        <Form>
                            {testSelection !== 'sfoairport' && (
                                <Checkbox
                                    name="isNotHavePermanentAddress"
                                    onChange={(checked) => {
                                        updateFormValues({ isNotHavePermanentAddress: checked });
                                        if (!checked) {
                                            updateFormValues({
                                                address: {
                                                    zipCode: '',
                                                    address: '',
                                                    city: '',
                                                    country: 'United States',
                                                    state: '',
                                                },
                                            });
                                        }
                                    }}
                                >
                                    I don't currently have a permanent address.
                                </Checkbox>
                            )}
                            <FormLabel label="Country" isRequired>
                                {cPartnerID === 'LLT001' ? (
                                    <Input
                                        value="United States"
                                        onChange={(value) => updateAddressValue({ country: value, state: '' })}
                                        name="address.country"
                                        disabled
                                    />
                                ) : (
                                    <CountryDropdown
                                        classes={classes.countryDropDown}
                                        value={address.country}
                                        name="address.country"
                                        // defaultOptionLabel="United States"
                                        // valueType="short"
                                        onChange={(value) => updateAddressValue({ country: value, state: '' })}
                                    />

                                )}

                            </FormLabel>

                            <FormLabel label="Street address" isRequired>
                                <Input
                                    value={address.address}
                                    onChange={(value) => updateAddressValue({ address: value })}
                                    name="address.address"
                                    isRequired
                                />
                            </FormLabel>
                            <CityStateRow>
                                <Grid container item xs={12} spacing={1}>
                                    <Grid item xs={12} md={7} >
                                        <FormLabel label="City/Town" isRequired>
                                            <Input
                                                value={address.city}
                                                onChange={(value) => updateAddressValue({ city: value })}
                                                name="address.city"
                                                isRequired
                                            />
                                        </FormLabel>
                                    </Grid>
                                    <Grid item xs={12} md={5}>
                                        {/* <div style={{ marginBottom: '11px' }}> */}
                                        <FormLabel label="State/Province/Region" isRequired>
                                            {/* <div style={{ width: '100px', height: '80px' }}> */}
                                            <RegionDropdown
                                                classes={classes.regionDropDown}
                                                name="address.state"
                                                country={address.country}
                                                value={address.state}
                                                valueType="short"
                                                onChange={(value) =>
                                                    updateAddressValue({ state: value })
                                                }
                                            />
                                            {/* {form.address.country == 'United States' && cPartnerID !== 'LLT001' &&
                                                !form.address.state && (
                                                    <span
                                                        style={{
                                                            color: 'red',
                                                            fontSize: '13px',
                                                            marginLeft: '5px',
                                                        }}
                                                    >
                                                        Required field
                                                    </span>
                                                )}
                                        </div> */}
                                        </FormLabel>
                                        {/* </div> */}
                                    </Grid>
                                </Grid>
                            </CityStateRow>

                            {form.address.country === 'United States' && (
                                <ZipCodeInput
                                    updateCountyValue={updateAddressValue}
                                    updateAddressValue={updateAddressValue}
                                    country={address.country}
                                    state={address.state}
                                />
                            )}

                            <NavigationButtons
                                onReturnButtonClick={() => {
                                    goToPrevStep();
                                    if ((location?.isInsuranceDisabled !== undefined && location.isInsuranceDisabled === true && location.qbenchCustomerId === config.qbenchCustomerId.SFOAirport_BARTStation)) {
                                        goToPrevStep()
                                    }
                                }}
                                // isValid={ false}
                                isValid={
                                    !form.isNotHavePermanentAddress
                                        ? form.address.country == 'United States'
                                            ? form.address.state !== undefined &&
                                                form.address.state !== null &&
                                                form.address.state !== ''
                                                ? true
                                                : false
                                            : true
                                        : form.address.state && form.address.country
                                }
                            />
                        </Form>
                    )}
                </Formik>
            </Content>
        </Container>
    );
};

export default AddressWthOutReg;
