import React, { useEffect } from 'react';

import { useStepsState } from '../provider';
import { useSharedState } from '../../shared/provider';
import { Wrapper } from '../../shared/components/styled';

import Loader from '../../shared/components/Loader';
import Header from '../../shared/components/Header';
import Footer from '../../shared/components/Footer';

import register from './RegisterStart';

import info from '../components/PersonalInformation/PersonalInformation';
import phone from '../components/phone';
import Demographics from '../components/Demographics';
import Address from '../components/Address/Address';
import ConfirmAccountInfo from '../components/ConfirmAccountInfo';
import SuccessfulAccountCreation from '../components/SuccessfulAccountCreation';
import ConfirmAccount from './ConfirmAccount';
import FooterRegister from './FooterRegister';
import NewDemographics from './NewDemographics';
import NewPersonalInformation from './PersonalInformation/NewPersonalInformation';

export const steps = [
  register,
  NewPersonalInformation,
  phone,
  Address,
  NewDemographics,
  ConfirmAccountInfo,
  ConfirmAccount,
  SuccessfulAccountCreation,
];

function Steps() {
  const { step, configured } = useStepsState();
  //const { locations } = useSharedState();

  //   if (!configured || locations === null) {
  //     return <Loader />;
  //   }

  const CurrentStep = steps[step];

  return (
    <Wrapper backgroundColor="#FFFFFF">
      {step !== 0 && step !== 5 && step !== 6 && step !== 7 && (
        <Header isHelp />
      )}
       { step === 5 && 
        <Header isPWLogo isManage />
      }
      <div>
        <CurrentStep />
      </div>
      {step !== 0 && step !== 6 && step !== 7 && <FooterRegister />}
      {/* <FooterRegister /> */}
    </Wrapper>
  );
}

export default Steps;

//ltst