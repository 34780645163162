import React, { useState } from 'react';
import styled from 'styled-components';
import { Form, Formik } from 'formik';
import {
  Content,
  PageHeader,
  SubHeaderDescription,
  PageTitle,
} from '../../shared/components/styled';
import { colors } from '../../../styles/colors';

import Input from '../../shared/components/form/Input';
import Footer from '../../shared/components/Footer';
import { Breakpoints } from '../../../dictionaries';
import wsllogo from '../../../assets/WLABS_CMYK.png';
import hallogo from '../../../assets/hal_logo.png';
import orglogo from '../../../assets/org_logo.png';
import Publiclogo from '../../../assets/public_logo.png';
import groublogo from '../../../assets/groub_logo.png';
import circleRectAngle from '../../../assets/circle-rectangle-dark-blue.png';
import circleRectAngleBottom from '../../../assets/circle+rectangle-teal-bottom.png';
import { NonFormikCheckbox as Checkbox } from '../../shared/components/form/Checkbox';
import { MultiTenancyService } from '../../../services/mutitenancy.service';
import { initiateOTP } from '../../manage/api';
import { updateRegister, getRegisteredUser } from '../api';
import { PrimeContainer } from './ConfirmAccount';
import { useStepsDispatch, useStepsState } from '../../schedule/provider';
import { useSharedDispatch, useSharedState } from '../../shared/provider';
interface ContainerProps {
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  noPadding?: boolean;
  marginTop?: boolean;
  insurance?: boolean;
}
const Container = styled.div`
  margin: 0 auto;
  min-height: 350px;
  background-color: rgb(255, 255, 255);
  border: 0.5px solid rgba(36, 61, 77, 0.14);
  box-shadow: rgb(153 153 153 / 5%) -4px -4px 8px, rgb(0 0 0 / 15%) 4px 4px 8px;
  border-radius: 12px;
  max-width: ${({ size }: ContainerProps) => {
    if (size === 'md') {
      return '760px';
    }

    if (size === 'lg') {
      return '920px';
    }

    if (size === 'xl') {
      return '1110px';
    }

    if (size === 'xxl') {
      return '1400px';
    }

    return '650px';
  }};
  margin-top: ${({ marginTop }: ContainerProps) =>
    marginTop ? '40px' : '80px'};
  padding: ${({ noPadding }: ContainerProps) => (noPadding ? '0px' : '0 15px')};
  @media (max-width: ${Breakpoints.xl}px) {
    border: none;
    box-shadow: none;
    border-radius: none;
  }
  @media (min-width: ${Breakpoints.sm}px) and (max-width: ${Breakpoints.md}px) {
    margin-left: ${({ insurance }: ContainerProps) => (insurance ? '5%' : '')};
  }
`;
const FooterDiv = styled.footer`
  position: fixed;
  width: 100vw;
  z-index: 11111;
  bottom: 0px;
  background-color: rgb(36, 61, 77);
  height: 40px;
  @media (max-width: 960px) {
    height: 40px;
    position: fixed;
  }
  @media (max-width: 600px) {
    height: 70px;
    position: fixed;
  }
`;
const Background = styled.div`
  background-image: url(${circleRectAngle});
  position: absolute;
  width: 100vw;
  height: 92.2vh;
  z-index: 1;
  background-repeat: no-repeat;
  background-size: 55% 140%;
  background-position: right;
  // background-attachment: fixed;

  @media (max-width: ${Breakpoints.xl}px) {
    background-position: bottom;
    background-image: url(${circleRectAngleBottom});
    background-size: 100% 15%;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  @media (max-width: ${Breakpoints.md}px) {
    background-size: 107% 15%;
  }
  @media (max-width: ${Breakpoints.sm}px) {
    background-size: 130% 15%;
  }
`;
const Div = styled.div`
  position: absolute;
  bottom: 0px;
  width: 100vw;
  height: 92.2vh;
  overflow-x: hidden;
  overflow-y: scroll;
  @media (max-width: ${Breakpoints.xl}px) {
    overflow-x: hidden;
    overflow-y: scroll;
  }
`;
const Error = styled.div`
  color: ${colors.red};
  margin-top: 15px;
  text-align: center;
`;

export const Logos = styled.div`
  display: flex;
`;

export const WLLogo = styled.div`
  height: 18px;
  width: 118px;
  background: url(${wsllogo}) no-repeat center center;
  background-size: cover;
  margin-right: 20px;
  display: inline-block;

  @media (min-width: ${Breakpoints.sm}px) {
    height: 70px;
    width: 300px;
  }
`;

export const Logos1 = styled.div`
  position: absolute;
  width: 601px;
  height: 161px;
  right: 432px;
  bottom: 518px;

  border: 1px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15));
  border-radius: 10px;
`;

export const Haldiv = styled.div`
  position: absolute;
  width: 120px;
  height: 120px;
  left: 616px;
  top: 408px;

  border: 1px solid rgb(235 227 227 / 50%);
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
  border-radius: 10px;
`;

export const HALLogo = styled.div`
  height: 18px;
  width: 118px;
  background: url(${hallogo}) no-repeat center center;
  background-size: cover;
  display: inline-block;
  margin: -20px 0px 20px 0px;

  @media (min-width: ${Breakpoints.sm}px) {
    height: 100px;
    width: 300px;
  }
`;
export const Text = styled.div`
  position: absolute;
  width: 521px;
  height: 44px;
  left: 447px;
  top: 180px;

  font-family: Open Sans;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 44px;
  text-align: center;
  padding-right: 68px;
  color: #000000;
`;

export const SubText = styled.div`
  position: absolute;
  width: 460px;
  height: 39px;
  left: 472px;
  top: 220px;

  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  /* or 137% */
  text-align: center;
  padding-right: 30px;

  color: ${colors.black};
`;

const CatogryFlexContainer = styled.p`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
`;

export const Orglogo = styled.div`
  height: 18px;
  width: 118px;
  background: url(${orglogo}) no-repeat center center;
  background-size: cover;
  display: inline-block;
  @media (min-width: ${Breakpoints.sm}px) {
    height: 80px;
    width: 85px;
  }
`;

export const CategoryContentBox = styled.div`
  flex: 0.3 1 0%;
  justify-content: center;
  border-radius: 10px;
  border: 2px solid ${colors.lightGrey};
  box-sizing: border-box;
  padding: 10px 0px 10px 0px;
  margin: 0px 5px 0px 5px;
  cursor: pointer;
`;

export const PublicLogo = styled.div`
  height: 18px;
  width: 118px;
  background: url(${Publiclogo}) no-repeat center center;
  background-size: cover;
  display: inline-block;
  @media (min-width: ${Breakpoints.sm}px) {
    height: 80px;
    width: 85px;
  }
`;

const CheckboxWrapper = styled.div`
  text-align: left;
`;
export const GroupLogo = styled.div`
  height: 18px;
  width: 118px;
  background: url(${groublogo}) no-repeat center center;
  background-size: cover;
  display: inline-block;
  @media (min-width: ${Breakpoints.sm}px) {
    height: 79px;
    width: 97px;
  }
`;

export const CategoyTitle = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 18px;

  color: #2193d2;
  margin-top: 3px;
`;
const VerifyButton = styled.div`
  textalign: 'center';
`;
const ContinueButton = styled.button`
  width: 80%;
  color: ${colors.white};
  text-align: center;
  margin-left: 60px !important;
  padding: 15px;
  background: ${colors.orange};
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  border: 0;
  font-size: 20px;
  line-height: 15px;
  border-radius: 32px;
  font-weight: bold;
  cursor: pointer;
  @media (max-width: ${Breakpoints.sm}px) {
    margin-left: 8% !important;
    width: 70%;
  }
`;

const Hide = styled.div`
  color: ${colors.green};
  margin-top: 5px;
  font-size: 13px;
  line-height: 15px;

  font-weight: bold;
  cursor: none;
`;

const VerifyEmailWthOutReg: React.FC = () => {
  const { goToNextStep } = useStepsDispatch();
  const { form } = useStepsState();
  const  { email, firstName } = form;
  
  const multiTenancyService: MultiTenancyService = new MultiTenancyService();
  const [loading, setLoading] = useState(false);
  const [check, setCheck] = useState(false);
  const [res, setRes] = useState(false);
  const [next, setNext]  = useState(false);
  const [stop, setStop] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const splitEmail = () => {
    const domain = email.split('@');
    return email.substring(0, 2) + 'xxxxxxx@' + domain[domain.length - 1];
  };
  const { setloggedUserData, updateUserVerify } = useSharedDispatch();
  const { userData } = useSharedState();

  const validateOTP = async (otp: string) => {
    try {
      setLoading(true);
      const verifyOTP = await multiTenancyService.validateOTP({
        otp,
        email,
        phone: '',
      });
      if (verifyOTP.result.status === 200) {
       let resultdata = await updateRegister({ email, isVerified: true });
        if (resultdata?.data?.status === 200) {
          setStop(false);
          clearInterval(interval);
          interval = null
          updateUserVerify(true);
          setloggedUserData(resultdata?.data?.data);
          localStorage.setItem('user', JSON.stringify(resultdata?.data?.data));
          // goToNextStep();
          setNext(true)
        }
      } else {
        setLoading(false);
        setError('Incorrect OTP');
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError('Server Error');
    }
  };

  const hide = () => {
    setTimeout(() => {
      setRes(false);
    }, 5000);
  };

  let interval:any;
  const checkEmail = async () => {
    let resultdata = await getRegisteredUser({ email: email });
    // console.log('isVerfied',resultdata?.data?.data?.isVerified)
    if (resultdata?.data?.data?.isVerified) {
      localStorage.setItem('user', JSON.stringify(resultdata?.data?.data));
    //   console.log('isVerified',resultdata?.data?.data?.isVerified)
      setStop(false);
    //   console.log("intervalbefore",interval)
      clearInterval(interval);
      interval = null
      updateUserVerify(true);
      setloggedUserData(resultdata?.data?.data);
      localStorage.setItem('user', JSON.stringify(userData));
      // goToNextStep();
      setNext(true)
    }
  };

  const localStorageData = JSON.parse(JSON.stringify(localStorage))
  const otpResend = () => {

    const otpResponse = multiTenancyService.intiateOTP({
      email: email,
      phone: '',
      isUserOTP: true,
      validationMethod: 'email',
      form:form,
      localStorageData:localStorageData
    }).then((res) => {
      setRes(res?.data.appointments === 1 ? true : false);
      hide();
    });
  }


  const change = () => {
    // console.log('stop',stop,interval)
    if(!interval && stop === true){
       interval = setInterval(checkEmail, 3000);
     
    }
  }
  
  React.useEffect( () =>{
    if(next === true){
      goToNextStep();
    }
    
    // console.log('stop',stop)

    if(stop === false){
    //   console.log("interval",interval)
      clearInterval(interval);
      interval = null
    } else{
      change();
    }
    
  },[stop, next])

  return (
    <>
      <Div>
        <Background>
          <PrimeContainer>
            <Container>
              <Content style={{ padding: '30px 50px' }}>
                <>
                  <PageHeader>
                    <PageTitle
                      color={colors.fullBlack}
                      textAlign="left"
                      fontsize={'28px'}
                    >
                      {`An Email message with your
                            code has been sent to ` + splitEmail()}
                    </PageTitle>
                  </PageHeader>
                  <SubHeaderDescription
                    style={{ fontSize: '14px', marginBottom: '20px' }}
                  >
                    <span style={{ textTransform: 'capitalize' }}>
                      {firstName}
                    </span>
                    , please enter the verification code below to confirm your
                    account. Your verification code will expire in{' '}
                    <strong>20 minutes</strong>
                  </SubHeaderDescription>

                  <Formik
                    initialValues={{
                      otp: '',
                    }}
                    onSubmit={(values) => {
                      validateOTP(values.otp);
                    }}
                    enableReinitialize={false}
                  >
                    {() => (
                      <Form>
                        <Input
                          name="otp"
                          placeholder="OTP"
                          validate={(value: string) => {
                            let error;
                            if (!value) {
                              error = "OTP field can't be empty";
                            }
                            return error;
                          }}
                          isRequired
                        />
                        {/* <p>OTP is invalid</p> */}
                        <CheckboxWrapper style={{ fontSize: '13px' }}>
                          <Checkbox
                            name={''}
                            checked={check}
                            onChange={(checked) => setCheck(checked)}
                          >
                            Remember this device? Please do not check this box
                            if you are using a public computer.
                          </Checkbox>
                        </CheckboxWrapper>
                        {error && <Error>{error}</Error>}
                        <VerifyButton>
                          <ContinueButton
                            type="submit"
                            className="btn fill-btn"
                          >
                            {loading ? 'Processing...' : 'Verify'}
                          </ContinueButton>
                        </VerifyButton>
                      </Form>
                    )}
                  </Formik>
                  <SubHeaderDescription
                    style={{ margin: '20px 0px 0px 10px', fontSize: '14px' }}
                  >
                    Haven't received your verification code?
                    <br></br>
                    We send verification codes immediately, but many factors
                    influence how fast you receive them. If you've waited more
                    than 20 minutes{' '}
                    <a style={{ color: 'blue' }} onClick={() => otpResend()}>
                      {' '}
                      please request a new code{' '}
                    </a>
                    .
                    {res && (
                      <Hide>Check your mail for the verification code.</Hide>
                    )}
                  </SubHeaderDescription>
                </>
              </Content>
            </Container>
          </PrimeContainer>
          <div
            className="a"
            style={{
              position: 'absolute',
              width: '100vw',
              bottom: 0,
              backgroundColor: 'rgb(36, 61, 77)',
            }}
          ></div>
        </Background>
        <FooterDiv>
          <Footer />
        </FooterDiv>
      </Div>
    </>
  );
};

export default VerifyEmailWthOutReg;
