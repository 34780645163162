import React, { useState } from 'react';
import styled from 'styled-components';
import { Form, Formik } from 'formik';
import { useHistory } from 'react-router-dom';

import {
  PageHeader,
  SubHeaderDescription,
} from '../../shared/components/styled';
import { colors } from '../../../styles/colors';

import Input from '../../shared/components/form/Input';
import Header from '../../shared/components/Header';
import Footer from '../../shared/components/Footer';
import { Breakpoints } from '../../../dictionaries';
import wsllogo from '../../../assets/WLABS_CMYK.png';
import hallogo from '../../../assets/hal_logo.png';
import Publiclogo from '../../../assets/public_logo.png';
import groublogo from '../../../assets/groub_logo.png';
import { useStepsState, useRegisterStepsDispatch } from '../provider';
import { Button } from '../../shared/components/styled';
import { forgetPasswordUrl } from './../api';
import mixpanel from 'mixpanel-browser';
import { useSharedState } from '../../shared/provider';
import circleRectAngle from '../../../assets/circle-rectangle-dark-blue.png';
import circleRectAngleBottom from '../../../assets/circle-rectangle-dark-blue-bottom.png';
import wLogo from '../../../assets/wsl-w-only.png';

const MainPage = styled.div`
  overflow: auto;
  background-color: ${colors.blueLight};
  min-height: 100vh;
`;

const Error = styled.div`
  color: ${colors.red};
  margin-top: 15px;
  text-align: center;
`;

const Success = styled.div`
  color: ${colors.green};
  margin-top: 15px;
  text-align: center;
`;

export const Logos = styled.div`
  display: flex;
`;

export const WLLogo = styled.div`
  height: 18px;
  width: 118px;
  background: url(${wsllogo}) no-repeat center center;
  background-size: cover;
  margin-right: 20px;
  display: inline-block;

  @media (min-width: ${Breakpoints.sm}px) {
    height: 70px;
    width: 300px;
  }
`;

export const Logos1 = styled.div`
  position: absolute;
  width: 601px;
  height: 161px;
  right: 432px;
  bottom: 518px;

  border: 1px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15));
  border-radius: 10px;
`;

export const Haldiv = styled.div`
  position: absolute;
  width: 120px;
  height: 120px;
  left: 616px;
  top: 408px;

  border: 1px solid rgb(235 227 227 / 50%);
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
  border-radius: 10px;
`;

export const HALLogo = styled.div`
  height: 18px;
  width: 118px;
  background: url(${hallogo}) no-repeat center center;
  background-size: cover;
  display: inline-block;
  margin: -20px 0px 20px 0px;

  @media (min-width: ${Breakpoints.sm}px) {
    height: 100px;
    width: 300px;
  }
`;
export const Text = styled.div`
  position: absolute;
  width: 521px;
  height: 44px;
  left: 447px;
  top: 180px;

  font-family: Open Sans;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 44px;
  text-align: center;
  padding-right: 68px;
  color: #000000;
`;

export const SubText = styled.div`
  position: absolute;
  width: 460px;
  height: 39px;
  left: 472px;
  top: 220px;

  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  /* or 137% */
  text-align: center;
  padding-right: 30px;

  color: ${colors.black};
`;

export const CategoryContentBox = styled.div`
  flex: 0.3 1 0%;
  justify-content: center;
  border-radius: 10px;
  border: 2px solid ${colors.lightGrey};
  box-sizing: border-box;
  padding: 10px 0px 10px 0px;
  margin: 0px 5px 0px 5px;
  cursor: pointer;
`;

export const PublicLogo = styled.div`
  height: 18px;
  width: 118px;
  background: url(${Publiclogo}) no-repeat center center;
  background-size: cover;
  display: inline-block;
  @media (min-width: ${Breakpoints.sm}px) {
    height: 80px;
    width: 85px;
  }
`;

export const GroupLogo = styled.div`
  height: 18px;
  width: 118px;
  background: url(${groublogo}) no-repeat center center;
  background-size: cover;
  display: inline-block;
  @media (min-width: ${Breakpoints.sm}px) {
    height: 79px;
    width: 97px;
  }
`;

export const CategoyTitle = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 18px;

  color: #2193d2;
  margin-top: 3px;
`;

const PageTitle = styled.h2`
  color: ${colors.fullBlack};
  font-size: 28px;
  font-weight: 700;
  margin: 0;
  text-align: center;
  @media (max-width: ${Breakpoints.sm}px) {
    font-size: 24px;
    text-align: center;
    margin-top: 70px;
  }
`;
const Content = styled.div`
  padding: 30px 30px;
  text-align: center;
  @media (max-width: ${Breakpoints.sm}px) {
    padding: 0px !important;
    margin-top: 100px;
  }
`;
const BtnContainer = styled.div`
  @media (max-width: ${Breakpoints.sm}px) {
    margin-top: 20px;
  }
`;
const Div = styled.div`
    position: absolute;
    bottom: 0px;
    width: 100vw;
    height: 92.2vh;
    overflow-x: hidden;
    overflow-y: hidden;
    @media (max-width: ${Breakpoints.md}px) {
        overflow-x: hidden;
        overflow-y: auto;
    }
`
const Background = styled.div`
    background-image: url(${circleRectAngle});
    position: absolute;
    width: 100vw;
    height: 92.2vh;
    z-index: 1;
    background-repeat: no-repeat;
    background-size: 55% 140%;
    background-position: right;
    // background-attachment: fixed;

    @media (max-width: ${Breakpoints.xl}px) {
        background-position: bottom;
        background-image: url(${circleRectAngleBottom});
        background-size: 100% 15%;
        overflow-x: hidden;
        overflow-y: hidden;
    }
    @media (max-width: ${Breakpoints.md}px) {
        background-size: 107% 15%;
        height:100%;
    }
    @media (max-width: ${Breakpoints.sm}px) {
        background-size: 130% 15%
        height:100%;
    }
`
const FooterDiv = styled.footer`
    position: fixed;
    width: 100vw;
    z-index: 11111;
    bottom: 0px;
    background-color: rgb(36, 61, 77);
    height: 40px;
    @media (max-width: 960px) {
        height: 40px;
        position: fixed;
    }
    @media (max-width: 600px) {
        height: 70px;
        position: fixed;
    }
`
const Stripe = styled.div`
    position: absolute;
    width: 100vw;
    height: 6.5%;
    background-color: rgb(97, 156, 138);
`;
interface ContainerProps {
    size?: 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
    noPadding?: boolean;
    marginTop?: boolean;
    insurance?:boolean;
  }
const Container = styled.div`
    margin: 0 auto;
    min-height: 350px;
    background-color: rgb(255, 255, 255);
    border: 0.5px solid rgba(36, 61, 77, 0.14);
    box-shadow: rgb(153 153 153 / 5%) -4px -4px 8px, rgb(0 0 0 / 15%) 4px 4px 8px;
    border-radius: 12px;
    max-width: ${({ size }: ContainerProps) => {
        if (size === 'md') {
        return '760px';
        }

        if (size === 'lg') {
        return '920px';
        }

        if (size === 'xl') {
        return '1110px';
        }

        if (size === 'xxl') {
        return '1400px';
        }

        return '650px';
    }};
    margin-top: ${({ marginTop }: ContainerProps) => (marginTop ? '40px' : '200px')};
    padding: ${({ noPadding }: ContainerProps) => (noPadding ? '0px' : '0 15px')};
    @media (max-width: ${Breakpoints.xl}px) {
        border:none;
        box-shadow:none;
        border-radius:none;
    }
    @media (min-width: ${Breakpoints.sm}px) and (max-width: ${Breakpoints.md}px)  {
        margin-left:${({ insurance }: ContainerProps) => (insurance ? '5%' : '')};
        margin-top:150px
    }
`
const ResetPassword: React.FC = () => {
  const [error, setError] = useState<string | null>(null);
  const [success, setsuccess] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const {tenant} = useSharedState()
  const {
    form: { email },
  } = useStepsState();
  const { updateFormValues, goToNextStep } = useRegisterStepsDispatch();
  const [isLoginScreen, setLoginScreen] = useState(false);
  let history = useHistory();

  const handleReset = async (params: { email: string, tenant?:any }) => {
    setLoading(true);
    setError('');
    try {
      const loggedUser = await forgetPasswordUrl(params);
      if (loggedUser?.data?.status == 200) {
        setsuccess('Please check your mail to reset password');
      } else {
        setError(loggedUser?.data?.message);
      }
      setLoading(false);
    } catch (e) {
      setError('Incorrect email or password');
      setLoading(false);
    }
  };

  return (
    <>
		<Header withoutBg isHelp />
		<Div>
            <Stripe />
            <Background>
                <div style={{display:'flex', justifyContent:'center'}}>
                    <div style={{display:'flex', justifyContent:'center',  alignItems:'center', position:'absolute', height:'100vh', width:'100vh',transform:'translate(-42%)', zIndex:0}}>
                        <img src={wLogo} alt="" />
                    </div>
                    <div style={{position:'absolute', marginTop:'2%', zIndex:6}}>
                    <Container>
                        <Content>
                            <>
                                <PageHeader>
                                    <PageTitle> Reset password</PageTitle>
                                </PageHeader>
                                <SubHeaderDescription
                                    style={{
                                        margin: "-10px 20px 20px 20px",
                                        fontSize: "14px",
                                        textAlign: "center",
                                        padding: "5px 45px",
                                    }}>
                                    To reset your password, enter the email address you use to sign-in. Forgot your
                                    email?{" "}
                                    <a
                                        style={{color:'#243D4D', fontWeight:600}}
                                        className='underline f-600'
                                        onClick={() => {
                                        history.push('/findemail');
                                        }}
                                    >
                                        Click here
                                    </a>
                                    .
                                </SubHeaderDescription>

                                <Formik
                                    initialValues={{
                                        email,
                                    }}
                                    onSubmit={(values) => {
                                        updateFormValues({ email: values.email.toLowerCase().trim() });
                                        mixpanel.track("forgot password page");
                                        goToNextStep();
                                        setLoading(true);
                                        handleReset({
                                            email: values.email.toLowerCase().trim(),
                                            tenant: tenant?.toLocaleLowerCase()==='kern' ?? false,
                                        });
                                    }}
                                    enableReinitialize={false}>
                                    {() => (
                                        <Form
                                            style={{
                                                width: "80%",
                                                display: "inline-block",
                                                textAlign: "center",
                                            }}>
                                            <Input
                                                name="email"
                                                lowerCase={true}
                                                placeholder="Email"
                                                onChange={(value) =>
                                                    updateFormValues({ email: value.toLowerCase().trim() })
                                                }
                                                isRequired
                                            />
                                            <BtnContainer>
                                            <Button
                                                libraryType="primary"
                                                type="submit"
                                                className='btn fill-btn'
                                                >
                                                {loading ? 'Processing...' : 'Reset Password'}
                                            </Button>
                                            </BtnContainer>
                                            {error && <Error>{error}</Error>}
                                            {success && <Success>{success}</Success>}
                                        </Form>
                                    )}
                                </Formik>
                            </>
                        </Content>
                    </Container>
                    </div>
                </div>
            </Background>
            <FooterDiv>
                <Footer />
            </FooterDiv>
        </Div>
	</>
  );
};

export default ResetPassword;

//ltst