import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Scrollbar from '../../../shared/components/Scrollbar/Scrollbar';
import { Breakpoints } from '../../../../dictionaries';
import { firebase } from '../../../../firebase';
import { useStepsState } from '../../provider';
import { getAllClientPartner } from '../../../register/api';

const Container = styled.div`
  height: 300px;

  @media (min-width: ${Breakpoints.sm}px) {
    height: 250px;
  }
`;

const Subheader = styled.p`
  margin-bottom: 15px;
  color: rgba(38, 38, 38, 0.8);
  font-weight: 700;
`;

const Text = styled.p`
  padding-left: 16px;
  margin-bottom: 20px;

  ul {
    margin: 20px 0 0;
    list-style: disc;
    padding-left: 19px;

    li {
      margin-bottom: 20px;
    }
  }
`;

const Content = styled.div`
  padding-right: 15px;
`;
interface Consent {
    subHead1: string;
    subHead2: string;
    subHead3: string;
    subHead1Content: string[] | null;
    subHead1SubContent1: string;
    subHead1SubContent1List: string[] | null;
    subHead1SubContent2: string;
    subHead1SubContent2List: string[] | null;
    subHead1SubContent3: string;
    subHead2Content: string[] | null;
    subHead3Content: string[] | null;
}
const ScrollableDynamicConsent: React.FC = () => {
    const { form:{ testSelection,  cPartnerID } } = useStepsState();
    const db = firebase.firestore();
    const [consent, setConsent] = useState<Consent>({
      subHead1: '',
      subHead2: '',
      subHead3: '',
      subHead1Content: null,
      subHead1SubContent1: '',
      subHead1SubContent1List: null,
      subHead1SubContent2: '',
      subHead1SubContent2List: null,
      subHead1SubContent3: '',
      subHead2Content: null,
      subHead3Content: null
    });

    const fetchConsent = async() => {
        // const response = db.collection('clientpartner').where('testSelection', '==', testSelection);
        // const response = db.collection('clientpartner').where('cPartnerID', '==', cPartnerID);
        // const snapShotData = await response.get();
        // const data = snapShotData.docs.map((doc: any) =>
        //     doc.data()
        // );
        const res = await getAllClientPartner({ cPartnerID });
        if (res.data.status === 200) {
          const data: any = res.data.data;
          setConsent(data[0].consent);
        }
    }
    useEffect(() => {
        fetchConsent();
      }, []);
          
  return (
    <Container>
      <Scrollbar>
        <Content>
          <Subheader>
            {consent.subHead1}
          </Subheader>
          {consent.subHead1Content?.map((data) => (
            <Text>
              {data}
            </Text>
          ))}
           <Text>
            {consent.subHead1SubContent1}
            {consent.subHead1SubContent1List?.map((data) => (
            <ul>
              <li dangerouslySetInnerHTML={{__html: data}} />
            </ul>
            ))}
            {consent.subHead1SubContent2}
            {consent.subHead1SubContent2List?.map((data) => (
            <ul>
              <li>
                {data}
              </li>
            </ul>
            ))}
            {consent.subHead1SubContent3}
          </Text>
          <Subheader>{consent.subHead2}</Subheader>
          {consent.subHead2Content?.map((data) => (
            <Text>
              {data}
            </Text>
          ))}
          <Subheader>{consent.subHead3}</Subheader>
          {consent.subHead3Content?.map((data) => (
            <Text>
              {data}
            </Text>
          ))}
        </Content>
      </Scrollbar>
    </Container>
  );
};

export default ScrollableDynamicConsent;
