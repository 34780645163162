import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';

import {
  Container,
  Content,
  PageHeader,
  PageTitle,
  PageSubTitle,
} from '../../shared/components/styled';
import NavigationButtons from '../../shared/components/NavigationButtons';
import WhyAreWeAsking from '../../shared/components/WhyAreWeAsking';
import Select from '../../shared/components/form/Select';
import FormLabel from '../../shared/components/form/FormLabel';
import styled from 'styled-components';
import { Breakpoints } from '../../../dictionaries';
import { colors } from '../../../styles/colors';
import { MdDateRange } from 'react-icons/md';
import { IoPersonOutline } from 'react-icons/io5';
import { useStepsState, useStepsDispatch } from '../../schedule/provider';
import GoogleAnalytics from '../../../GoogleAnalytics';

export const tooltipText =
  'This information is required for result reporting to State and Local Department of Public Health to help track and trend the impact of COVID-19 on different communities. Your identity remains confidential as personally identifiable information is removed from data sets, so that the people whom the data describe remain anonymous.';

const ListRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  & > * {
    flex-basis: 33%;
  }
  @media (max-width: ${Breakpoints.sm}px) {
    flex-direction: column;
    gap: 0px;
  }
`;
const WrapContainer = styled.div`
  margin-bottom: 30px;
  align-items: center;
  padding: 20px;
  border: 0.5px solid rgba(36, 61, 77, 0.14);
  box-shadow: -4px -4px 8px rgba(153, 153, 153, 0.05),
    4px 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  @media (max-width: ${Breakpoints.sm}px) {
    position: relative;
  }
`;

const WrapContent = styled.div`
display: flex;
position: relative;
justify-content: space-between;
gap: 0px;
  @media (max-width: ${Breakpoints.sm}px) {
    display: grid;
    gap: 5px;
  }
`;
const Flexbox = styled.div`
  display: flex;
  align-items: center;
  width: 30%;
  &:nth-child(1),
  &:nth-child(2) {
    border: none;
    border-right: 1px solid #2a5f87;
    margin-right: 15px;
  }

  &:nth-child(3) {
    width: 210px;
  }
  @media (max-width: ${Breakpoints.sm}px) {
    margin-bottom: 20px;
    &:nth-child(1),
    &:nth-child(2) {
      width: 100%;
      border-right: none;
    }

    &:nth-child(3) {
      width: 100%;
    }
  }
`;

const FlexboxIcon = styled.div`
  position: relative;
  color: ${colors.grey};
  display: inline-flex;
  
`;
const FlexboxText = styled.div`
  display: block;
  font-size: 14px;
  font-weight: 500;
  padding-left: 10px;
  word-break: break-all;
`;
const genderSelect = [
  { label: 'Female', value: 'Female' },
  { label: 'Male', value: 'Male' },
  // {
  //   label: 'Non-binary',
  //   value: 'Non-binary',
  // },
  // {
  //   label: 'Prefer to self-describe',
  //   value: 'Prefer to self-describe',
  // },
  {
    label: 'Prefer not to state',
    value: 'Prefer not to state',
  },
];
const raceSelect = [
  {
    label: 'American Indian or Alaska Native',
    value: 'American Indian or Alaska Native',
  },
  { label: 'Asian', value: 'Asian' },
  {
    label: 'Black or African American',
    value: 'Black or African American',
  },
  {
    label: 'Native Hawaiian or Other Pacific Islander',
    value: 'Native Hawaiian or Other Pacific Islander',
  },
  {
    label: 'White',
    value: 'White',
  },
  {
    label: 'Other',
    value: 'Other',
  },
  {
    label: 'Prefer not to state',
    value: 'Prefer not to state',
  },
];
const ethnicitySelect = [
  {
    label: 'Hispanic or Latino',
    value: 'Hispanic or Latino',
  },
  {
    label: 'Not Hispanic or Latino',
    value: 'Not Hispanic or Latino',
  },
  {
    label: 'Prefer not to state',
    value: 'Prefer not to state',
  },
];
const NewDemographics: React.FC = () => {
  const { form } = useStepsState();
  useEffect(() => {
    window.scrollTo(0, 0);
    GoogleAnalytics(form,'/Demographics');
    }, []);
  const { sex, race, ethnicity, firstName, lastName, middleName, birthDate, minors, registeringFor } = form || {}
  const { updateFormValues, goToNextStep, goToPrevStep } = useStepsDispatch();
  const saveMinorDetails = (
    fieldName: any,
    index: number,
    newValue: any
  ) => {
    const newMinors: any = [...minors];
    newMinors[index][fieldName] = newValue;

    updateFormValues({
      minors: newMinors,
    });
  };
  return (
    <div>
      {/* <Header isPWLogo/> */}
      <Container size="md">
        <Content>
          <PageHeader>
            <PageTitle>
              Demographics
              <WhyAreWeAsking tooltipContent={tooltipText} />
            </PageTitle>
            <PageSubTitle>Please fill all of the sections below:</PageSubTitle>
          </PageHeader>
          <Formik
            initialValues={{
              sex: genderSelect.find((data) => data.value === sex),
              race: raceSelect.find((data) => data.value === race),
              ethnicity: ethnicitySelect.find((data) => data.value === ethnicity),
              minors: minors.map((minor: any) => ({
                ...minor,
                sex: genderSelect.find((data) => data.value === minor.sex),
                race: raceSelect.find((data) => data.value === minor.race),
                ethnicity: ethnicitySelect.find((data) => data.value === minor.ethnicity),
              })),
            }}
            onSubmit={(values) => {
              updateFormValues({
                sex: values.sex?.value,
                race: values.race?.value,
                ethnicity: values.ethnicity?.value,
                minors: values.minors.map((minor: any) => ({
                  ...minor,
                  sex: minor.sex?.value,
                  race: minor.race?.value,
                  ethnicity: minor.ethnicity?.value,
                })),
              });
              goToNextStep();
              console.log(values)
              console.log(sex, race, ethnicity)
              console.log("minors", minors);

            }}
          >
            {() => (
              <Form>
              {registeringFor !== 'minorOnly' && <>
                <WrapContainer>
                  <WrapContent>
                    <Flexbox>
                      <FlexboxIcon>
                        <IoPersonOutline size={20} />
                      </FlexboxIcon>
                      <FlexboxText>
                        {' '}
                        <strong>Account Primary</strong>
                      </FlexboxText>
                    </Flexbox>
                    <Flexbox>
                      <FlexboxIcon>
                        <IoPersonOutline size={20} />
                      </FlexboxIcon>
                      <FlexboxText>
                        {firstName} {middleName} {lastName}
                      </FlexboxText>
                    </Flexbox>
                    <Flexbox>
                      <FlexboxIcon>
                        <MdDateRange size={20} />
                      </FlexboxIcon>
                      <FlexboxText>
                        {'Date of birth : '}
                        {birthDate}
                      </FlexboxText>
                    </Flexbox>
                  </WrapContent>
                </WrapContainer>
                <ListRow>
                  <FormLabel label="Sex (at birth)">
                    <Select<string>
                      name="sex"
                      options={genderSelect}
                      onChange={(value) =>
                        updateFormValues({ sex: value })
                      }
                      validate={(value) => {
                        if (!value) {
                          return 'Required field';
                        }
                      }}
                    />
                  </FormLabel>
                  <FormLabel label="Race">
                    <Select<string>
                      name="race"
                      options={raceSelect}
                      onChange={(value) =>
                        updateFormValues({ race: value })
                      }
                      validate={(value) => {
                        if (!value) {
                          return 'Required field';
                        }
                      }}
                    />
                  </FormLabel>
                  <FormLabel label="Ethnicity">
                    <Select<string>
                      name="ethnicity"
                      options={ethnicitySelect}
                      onChange={(value) =>
                        updateFormValues({ ethnicity: value })
                      }
                      validate={(value) => {
                        if (!value) {
                          return 'Required field';
                        }
                      }}
                    />
                  </FormLabel>
                </ListRow>
                </>}
                {minors.length > 0 && (
                  <>
                    {minors.map(({ firstName, lastName, middleName, birthDate }: any, i: any) => (
                      <>
                        <WrapContainer key={i}>
                          <WrapContent>
                            <Flexbox>
                              <FlexboxIcon>
                                <IoPersonOutline size={20} />
                              </FlexboxIcon>
                              <FlexboxText>
                                {' '}
                                <strong>Minor {i + 1}</strong>
                              </FlexboxText>
                            </Flexbox>
                            <Flexbox>
                              <FlexboxIcon>
                                <IoPersonOutline size={20} />
                              </FlexboxIcon>
                              <FlexboxText>
                                {firstName} {middleName} {lastName}
                              </FlexboxText>
                            </Flexbox>
                            <Flexbox>
                              <FlexboxIcon>
                                <MdDateRange size={20} />
                              </FlexboxIcon>
                              <FlexboxText>
                                {'Date of birth : '}
                                {birthDate}
                              </FlexboxText>
                            </Flexbox>
                          </WrapContent>
                        </WrapContainer>
                        <ListRow>
                          <FormLabel label="Sex (at birth)">
                            <Select<string>
                              name={`minors.${i}.sex`}
                              options={genderSelect}
                              onChange={(value: any) =>
                                saveMinorDetails('sex', i, value)
                              }
                              validate={(value) => {
                                if (!value) {
                                  return 'Required field';
                                }
                              }}
                            />
                          </FormLabel>
                          <FormLabel label="Race">
                            <Select<string>
                              name={`minors.${i}.race`}
                              options={raceSelect}
                              onChange={(value: any) =>
                                saveMinorDetails('race', i, value)
                              }
                              validate={(value) => {
                                if (!value) {
                                  return 'Required field';
                                }
                              }}
                            />
                          </FormLabel>
                          <FormLabel label="Ethnicity">
                            <Select<string>
                              name={`minors.${i}.ethnicity`}
                              options={ethnicitySelect}
                              onChange={(value: any) =>
                                saveMinorDetails('ethnicity', i, value)
                              }
                              validate={(value) => {
                                if (!value) {
                                  return 'Required field';
                                }
                              }}
                            />
                          </FormLabel>
                        </ListRow>
                      </>
                    ))}
                  </>
                )}
                <NavigationButtons
                  onReturnButtonClick={() => {
                    if(form?.cPartnerID === 'LLT001' && form?.hasInsurance === true) {
                        goToPrevStep();
                        goToPrevStep();
                        goToPrevStep();
                        goToPrevStep();
                    } else if(form?.cPartnerID === 'LLT001' && form?.hasInsurance === false){
                        goToPrevStep();
                        goToPrevStep();
                        goToPrevStep();
                        goToPrevStep();
                        goToPrevStep();
                    }
                    goToPrevStep();
                  }}
                />
              </Form>
            )}
          </Formik>
        </Content>
      </Container>
    </div>
  );
};

export default NewDemographics;
