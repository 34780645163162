import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import styled from 'styled-components';

import {
  Container,
  PageHeader,
  Button,
  ErrorText,
} from '../../../shared/components/styled';
import { Breakpoints } from '../../../../dictionaries';
import { colors } from '../../../../styles/colors';
import Modal from './../../../shared/components/Modal';
import { useSharedDispatch, useSharedState } from '../../../shared/provider';
 import { deleteUserId, getGovtId } from '../../api';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import axios from 'axios';

export const Content = styled.div`
  padding: 10px;

  @media (min-width: ${Breakpoints.md}) {
    padding: 88px 0;
  }
  @media (max-width: ${Breakpoints.sm}px) {
    padding: 0px !important;
  }
`;
const BtnContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  flex-wrap: nowrap;
  padding-top: 20px;

  @media (min-width: 450px) {
    flex-direction: row;
  }
`;
const Space = styled.div`
  height: 10px;
  width: 20px;
`;

export const StyledButton = styled.button`
  border: 1px dashed ${colors.grey};
  border-radius: 10px;
  font-size: 16px;
  font-weight: 500;
  color: rgb(26 150 219);
  line-height: 24px;
  padding: 15px 28px;
  transition: all 0.15s ease-in-out;
  width: 100%;
  cursor: pointer;

  &:focus,
  &:active {
    border: 1px dotted ${colors.blueLight};
    box-shadow: 0 0 4px ${(colors.blueLight, 0.8)};
  }
  @media (max-width: ${Breakpoints.sm}px) {
    margin-top: 40px;
  }
`;
export const PageTitle = styled.h2`
  color: ${colors.fullBlack};
  font-size: 28px;
  font-weight: 700;
  margin: 0;
  text-align: left;

  @media (max-width: ${Breakpoints.sm}px) {
    font-size: 24px;
  }
`;
const Text = styled.p`
  margin-bottom: 45px;
`;
export interface  govtId{
  id:string;
  email:string;
  frontCard:string | null;
  backCard: string | null;
}
type Props = {
  open: boolean;
  close: () => void;
  idList: govtId;
};
const CancelGovernmentId: React.FC<Props> = ({
  open,
  close,
  idList,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [loading, setLoading] = useState(false);
  const { userData,userGovtId } = useSharedState();
  const { updateGovtId } = useSharedDispatch();


  const deleteGovtId=async()=>{
    try{
      let deleteData = await deleteUserId({
        id:idList.id   
         })
         if (deleteData?.data?.status === 200) {
          try {
            setLoading(true);
            let updatedData = await getGovtId({email:userData?.email});
            if (updatedData?.data?.status === 200) {
              updateGovtId(updatedData?.data?.data);
            } else {
              updateGovtId([]);
            }
            close();
            setLoading(false);
          } catch (e) {
            setLoading(false);
            close();
          }
        }
        setLoading(false);
      } catch(e){
        setLoading(false);
      }
    }
  

  return (
    <Modal open={open} onClose={close} maxWidth={800} blockScroll={false}>
      <Content>
        <Container marginTop>
          <PageHeader>
            <PageTitle>
              <AiOutlineQuestionCircle size={22} color={'#000'} />
              {' Delete your Government Id '}
            </PageTitle>
          </PageHeader>
          <Formik
            enableReinitialize
            initialValues={{}}
            onSubmit={(values) => {
              deleteGovtId();
            }}
          >
            {() => {
              return (
                <Form>
                  <Text>
                    {'Are you sure want to delete your government id detail?'}
                  </Text>
                  <BtnContainer>
                    <Button
                      libraryType="default"
                      type="button"
                      className="btn elevated-btn"
                      onClick={() => close()}
                    >
                      Return
                    </Button>
                    <Space />

                    <Button
                      libraryType="primary"
                      type="submit"
                      className="btn fill-btn"
                      disabled={loading}
                    >
                      {loading ? 'Processing...' : 'Confirm'}{' '}
                    </Button>
                  </BtnContainer>
                </Form>
              );
            }}
          </Formik>
        </Container>
      </Content>
    </Modal>
  );
};

export default CancelGovernmentId;
