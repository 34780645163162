import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import { config } from '../../../config';
import {
	Container,
	Content,
	PageHeader,
	Button,
} from '../../shared/components/styled';
import { colors } from '../../../styles/colors';
// import { useStepsDispatch } from '../provider';
import { useStepsState, useStepsDispatch } from '../../schedule/provider';
import Checkbox from '../../shared/components/form/Checkbox';
import NavigationButtons from '../../shared/components/NavigationButtons';
import pencil from '../../../assets/pencil.png';
import { Breakpoints } from '../../../dictionaries';
import ChangeLocationModal from '../../shared/components/ChangeLocationModal';
import ChangeDateTimeModal from '../../schedule/components/ConfirmAndPay/ChangeDateTimeModal';
import { useSharedState, useSharedDispatch } from '../../shared/provider';
import PaymentModal from '../../schedule/components/ConfirmAndPay/PaymentModal';
import ChangeApptAttendeeModal from '../../schedule/components/ConfirmAndPay/ChangeApptAttendeeModal';
import { cloneDeep, includes } from 'lodash';
import FormLabel from '../../shared/components/form/FormLabel';
import Datepicker, {
	DatepickerField,
} from '../../shared/components/Datepicker';
import { format, parse, isBefore, subHours, startOfDay } from 'date-fns';
import pin from '../../../assets/pin-grey.svg';
import calendar from '../../../assets/calendar-grey.svg';
import clock from '../../../assets/clock.svg';
import person from '../../../assets/person-grey.svg';
import { convertPricing, getPeriodsFromSchedule } from '../../../utils';
// import ChangeNameDialog from './../../schedule/components/ChangePersonalInfo/ChangeNameDialog';
import ChangeDobDialog from './../../schedule/components/ChangePersonalInfo/ChangeDobDiaog';
import ChangePhoneDialog from './../../schedule/components/ChangePersonalInfo/ChangePhoneDialog';
import ChangeAddressDialog from './../../schedule/components/ChangePersonalInfo/ChangeAddressDialog';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import ChangeNameModal from '../../schedule/components/ChangePersonalInfo/ChangeNameModal';
import ChangeDobModal from '../../schedule/components/ChangePersonalInfo/ChangeDobModal';
import ChangePhoneModal from '../../schedule/components/ChangePersonalInfo/ChangePhoneModal';
import ChangeAddressModal from '../../schedule/components/ChangePersonalInfo/ChangeAddressModal';
import ChangeMinorModal from '../../schedule/components/ChangePersonalInfo/ChangeMinorModal';
import moment from 'moment';
import {
	createAppointment,
	checkReservationValidity,
	getCoupon,
	updatePayment,
	getNpDestionation,
	createAppointmentForLasLomitas,
	finalRefersion,
	getEmployeeCoupon,
} from '../../schedule/api';
import { createInsurance, AddGovtId, updateRegister } from '../api';
import Inputs from '../../shared/components/form/Input';
import GoogleAnalytics from '../../../GoogleAnalytics';
import { EditOutlined } from '@ant-design/icons';
import ChangeEmployeeIdModal from '../../schedule/components/ChangePersonalInfo/ChangeEmployeeIdModal';
import { Tooltip } from 'antd';
import GoHealthCompleteButton from '../../shared/GoHealthCompleteButton';
import { updateIsRebookedBySampleId } from '../../shared/api';
import { filterRebookLocations } from '../../shared/components/form/reusableFunction';

const PageSubTitle = styled.div`
  margin: 10px 0 10px 0;
`;
const Checkboxes = styled.div`
  margin-bottom: 50px;
`;
const Title = styled.h3`
  color: rgba(38, 38, 38, 0.8);
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  margin: 30px 0 20px 0;
`;
const WrapContainer = styled.div`
  background: ${colors.white};
  margin-bottom: 30px;
  align-items: center;
  padding: 20px;
  border: 0.5px solid rgba(36, 61, 77, 0.14);
  border-radius: 12px;
  @media (max-width: ${Breakpoints.sm}px) {
    position: relative;
  }
`;
const WrapContent = styled.div`
  display: flex;
  position: relative;
  gap: 50px;
  @media (max-width: ${Breakpoints.sm}px) {
    display: grid;
    gap: 5px;
  }
`;
const Flexbox = styled.div`
  display: flex;
  gap: 30px;
  width: 33%;
  &:nth-child(1),
  &:nth-child(2) {
    width: 40%;
  }

  &:nth-child(3) {
    width: 20%;
    height: 20%;
  }
  @media (max-width: ${Breakpoints.sm}px) {
    margin-bottom: 20px;
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      width: 100%;
      height: 70%;
    }
  }
`;
const Vl = styled.div`
  border-left: 1px solid #2a5f87;
  height: 100%;
  margin-left: 80px;
  @media (max-width: ${Breakpoints.sm}px) {
    display: none;
  }
`;
const FlexboxIcon = styled.div`
  position: relative;
  color: ${colors.grey};
`;
const FlexboxText = styled.div`
  display: block;
  font-size: 14px;
  font-weight: 500;
  margin-top: 5px;
`;

const Cardtitle = styled.div`
  display: flex;
  justify-content: flex-start;
  flex: 1 1 0%;

  margin-bottom: 15px;
  @media (max-width: ${Breakpoints.sm}px) {
    width: 88vw;
  }
`;
const TitleKey = styled.h3`
margin: 0 0 14px 0;
color: rgba(38, 38, 38, 0.8);
font-weight: 700;
font-size: 16px;
line-height: 22px;
min-width: 175px;
@media (max-width: ${Breakpoints.sm}px) {
  min-width: 120px;
  margin-left: 0px !important;
  }

`;

const TitleValue = styled.h4`
  margin: 0 0 14px 0;
  color: rgba(38, 38, 38, 0.8);
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  @media (max-width: ${Breakpoints.sm}px) {
  width: 110%;
  padding: 0 20px;
  overflow-wrap: anywhere;
  font-size: 14px;
}
`;
const PencilLogo = styled.div`
  height: 18px;
  width: 118px;
  padding-left: 10%;

  background: url(${pencil}) no-repeat center center;

  @media (min-width: ${Breakpoints.sm}px) {
    height: 22px;
    width: 22px;
    border-radius: 50%;
    padding: 14px;
    background-color: rgb(240 248 255);
  }
`;
const Subcontent = styled.h4`
  margin: 50px 0 0 0;
  @media (max-width: ${Breakpoints.sm}px) {
    max-width: min-content;
  }
`;

interface ButtonProps {
	libraryType: 'primary' | 'default';
	accentColor?: string;
	size?: 'sm' | 'lg';
}

const ReturnButton = styled.button`
  border: 1px solid
    ${({ accentColor }: ButtonProps) => accentColor || colors.darkBlue};
  font-size: ${({ size }: ButtonProps) => (size === 'sm' ? '14px' : '20px')};
  line-height: 1.6;
  border-radius: 5px;
  padding: ${({ size }: ButtonProps) =>
		size === 'sm' ? '4px 15px' : '9px 20px'};
  font-weight: ${({ size }: ButtonProps) =>
		size === 'sm' ? 'normal' : 'bold'};
  width: 100%;
  text-align: center;
  color: ${({ libraryType, accentColor }: ButtonProps) => {
		return libraryType === 'primary'
			? colors.white
			: accentColor || colors.darkBlue;
	}};
  background: ${({ libraryType, accentColor }: ButtonProps) =>
		libraryType === 'primary' ? accentColor || colors.darkBlue : colors.white};
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:disabled {
    background: ${({ libraryType, accentColor }: ButtonProps) =>
		libraryType === 'primary'
			? accentColor || colors.darkBlue50
			: colors.white};
    border: 1px solid transparent;
    color: ${({ libraryType, accentColor }: ButtonProps) => {
		return libraryType === 'primary'
			? colors.white
			: accentColor || colors.darkBlue50;
	}};
  }
`;

export const PageTitle = styled.h2`
  color: ${colors.fullBlack};
  font-size: 28px;
  font-weight: 700;
  margin: 0;
  text-align: left;

  @media (max-width: ${Breakpoints.sm}px) {
    font-size: 25px;
    margin-top: 30%;
  }
`;
const TestingInfoBtn = styled(Button)`
  border: 1px solid #EE782B;
  border-radius: 1000px;
  max-width: 33%;
  @media (max-width: ${Breakpoints.sm}px) {
    font-size: 15px;
    max-width: 100%;
  }
`;
const Error = styled.div`
  color: ${colors.red};
  margin-top: 15px;
  text-align: center;
`;
const InnerBox = styled.div`
  border: 1px solid rgb(26, 150, 219);
  margin: 0px 5px;
  width: 100%;
  border-radius: 10px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  cursor: pointer;
  align-items: center;
  display: grid;
  background: ${({ selected }: { selected?: boolean }) =>
		selected ? colors.rebrandBlack : colors.white};
`;
const TestTypeTitle = styled.p`
  font-size: medium;
  font-weight: bold;
  color: ${({ selected }: { selected?: boolean }) =>
		selected ? colors.white : colors.rebrandBlack};

  @media (max-width: ${Breakpoints.sm}px) {
    font-size: 15px;
    font-weight: bold;
    color: ${({ selected }: { selected: boolean }) =>
		selected ? colors.white : colors.rebrandBlack};
  }
`;

const TestCostLabel = styled.p`
  font-size: 40px;
  font-weight: bold;
  color: ${({ selected }: { selected: boolean }) =>
		selected ? colors.white : colors.rebrandBlack};

  @media (max-width: ${Breakpoints.sm}px) {
    font-size: 30px;
    font-weight: bold;
    color: ${({ selected }: { selected: boolean }) =>
		selected ? colors.white : colors.rebrandBlack};
  }
`;
const TestDurationInfoLabel = styled.p`
  font-size: 12px;
  font-weight: bold;
  color: ${({ selected }: { selected: boolean }) =>
		selected ? colors.white : colors.rebrandBlack};

  @media (max-width: ${Breakpoints.sm}px) {
    font-size: 9px;
    font-weight: bold;
    color: ${({ selected }: { selected?: boolean }) =>
		selected ? colors.white : colors.rebrandBlack};
  }
`;
export const TestingInfoContainer = styled.div`
  background: ${colors.rebrandBlack};
  padding: 25px;
  display: flex;
  color:white;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 6px;
  border: 0.5px solid #619C8A;
  border-radius: 15px;

  & > * {
    &:first-child {
      margin-bottom: 20px;
    }
  }

  @media (min-width: ${Breakpoints.sm}px) {
    flex-direction: row;
    padding: 30px;
    justify-content: space-between;

    & > * {
      &:first-child {
        margin-bottom: 0;
      }
    }
  }
`;
export const TestingInfo = styled.div`
  position: relative;
  padding-left: 31px;

  svg {
    width: 24px;
    height: 24px;
  }
`;
interface TestingInfoRow {
	width?: string;
}
export const TestingInfoRow = styled.div`
  display: flex;
  flex-direction: column;
  margin: -8px 0;
  width: ${({ width }: TestingInfoRow) => width || ''} & > * {
    margin: 8px 0;
  }
  @media (min-width: ${Breakpoints.md}px) {
    flex-direction: row;
    margin: 0 -30px;

    & > * {
      margin: 0 10px;
    }
  }
  @media (max-width: ${Breakpoints.sm}px) {
    gap: 10px;
  }
`;
export const Icon = styled.div`
  position: absolute;
  left: 0;
  top: 1px;
`;
export const PinIcon = styled(Icon)`
  left: 5px;
  width: 14px;
  height: 20px;
  background: url(${pin}) no-repeat center center;
`;
export const CalendarIcon = styled(Icon)`
  width: 24px;
  height: 24px;
  background: url(${calendar}) no-repeat center center;
`;

export const ClockIcon = styled(Icon)`
  width: 24px;
  height: 24px;
  background: url(${clock}) no-repeat center center;
`;

export const PersonIcon = styled(Icon)`
  width: 24px;
  height: 24px;
  background: url(${person}) no-repeat center center;
`;
const BtnContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  flex-wrap: nowrap;
  padding-top: 20px;

  @media (min-width: 450px) {
    flex-direction: row;
  }
`;
const Space = styled.div`
  height: 10px;
  width: 20px;
`;
const EditIcon = styled.div`
  width: 30px;
  @media (max-width: ${Breakpoints.sm}px) {
    width: 45px;
  }
`;
const CouponContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
  @media (max-width: ${Breakpoints.sm}px) {
    display: block;        
}
`;

const periods = getPeriodsFromSchedule(config.schedule);

export function disableFutureDt(): Date {
	const date = new Date();
	const year = date.getFullYear();
	const month = date.getMonth();
	const day = date.getDate();
	const disableFuture = new Date(year + 1, month, day); // PLUS 1 YEAR
	return disableFuture;
}
let count = 0;
const ConfirmBooking: React.FC = () => {
	const {
		form: {
			location,
			date,
			slot,
			minors,
			isExpressSameDayTest,
			departureDateAndTime,
			registeringFor,
			isRapidTest,
			destination,
			airlineCode,
			testSelection,
			hasInsurance,
			payingMethod,
			insurance,
			email,
			promoCode,
			couponId,
			discountAmount,
			govtId,
			hasGovtId,
			isAntigen,
			isNpDestination,
			isWalkUpTest,
			cPartnerID,
			isPanel,
		},
		form,
		prices,
		slotsList,
		rebooking
	} = useStepsState();
	const {
		confirmationId,
		agreeToCancel,
		commitToAttend,
		consentForTesting,
		hipaaConfirmed,
		confirmEmail,
		...appointment
	} = form;
	const {
		toggleChangeLocationModal,
		updateFormValues,
		goToNextStep,
		goToPrevStep,
		goToSkip2Step,
		goToStep
	} = useStepsDispatch();
	const {
		userData,
		airline,
		standradTest,
		travellType,
		district,
		tenant,
		testSelectFlow,
		promos,
		locations,
		isLocation
	} = useSharedState();

	const employeePromocode = promos?.filter((e:any) => {
        if( e.iswslEmployee){
            return true
        }
    }).map((ee:any) => ee.code)
    
    const allPromocodes = promos?.filter((e:any) => {
        if( e.isAmountReduce){
            return true
        }
    }).map((ee:any) => ee.code)
	// const fulloffCoupon = promos?.filter((e:any) => {if(e.isAmountReduce){return true} return false}).map((ee:any) => ee.code)
  
	const [departureDateAndTimedata, setdepartureDateAndTimedata] = useState<any>(
		departureDateAndTime
			? parse(departureDateAndTime, config.dateTimeFormat, new Date())
			: null
	);
	const [showChangeDatetimeModal, setShowChangeDatetimeModal] = useState(false);
	const [showPaymentModal, setShowPaymentModal] = useState(false);
	const [showChangeApptAttendeeModal, setShowChangeApptAttendeeModal] =
		useState(false);
	const [showDepartureDateandTime, setShowDepartureDateandTime] =
		useState(false);
	// const [npArray, setNpArray] = useState<any>([]);
	const [isPromoCodeValid, setisPromoCodeValid] = useState(true)
	const [apply, setApply] = useState(false)
    const [noOfTestsInsu, setNoOfTestsInsu] = useState(0)
    const [noOfTestsNoInsu, setNoOfTestsNoInsu] = useState(0)
    const [noOfTestsCred, setNoOfTestsCred] = useState(0)
    const [npArray, setNpArray] = useState<any>([]);
    let datadestination : any = form?.destination && (typeof(form?.destination) === "string" ? form?.destination : form?.destination?.value ?? 0)
	const npData = npArray?.includes(datadestination) && form?.testSelection === 'flight'
    const filterloc=(loc:any)=>{
        console.log("loc",loc)
    let testType= form.isAntigen ?'antigen' : form.isExpressSameDayTest ?'express' 
      : form.isRapidTest ?'rapid' :'standard'
      if(form.previousPayment==='credit card'){
         return loc.isCreditCard[testType]
      }else if (form.previousPayment==='insurance'){
          return loc.isInsurance[testType]
      }else return loc.isNoInsurance[testType]
  }
	const rollbackValues = useMemo(
		() => cloneDeep(departureDateAndTime),
		[showDepartureDateandTime]
	);
	const rollbackSlot = useMemo(
		() => cloneDeep(slot),
		[showDepartureDateandTime]
	);
	const rollbackDate = useMemo(
		() => cloneDeep(date),
		[showDepartureDateandTime]
	);
	const [showDepartureTime, setshowDepartureTime] = useState(false);
	const [isEmpEmail, setIsEmpEmail] = useState(false);
    const fulloffCoupon = promos?.filter((e:any) => {if(e.isAmountReduce){return true} return false}).map((ee:any) => ee.code)
    useEffect(() => {
        handleNoOfTests()
        if(payingMethod !== 'Insurance' && payingMethod !== 'No Insurance' && (payingMethod === 'Credit card' || payingMethod === 'Credit Card')){
            empMail();
            }
    }, [showChangeDatetimeModal]);
    useEffect(()=>{
        if(count < 0){
            handleLocationChange()
        }
        count++
    }, [toggleChangeLocationModal])
	const price = convertPricing(
		form.isExpressSameDayTest,
		form.isRapidTest,
		prices,
		travellType,
		destination,
		isAntigen,
		npArray,
		location?.isNpDestination
	); //form.isExpressSameDayTest ? prices.expedited : form.isRapidTest? prices.rapid : prices.standard;
    const handleLocationChange = () => {
        if (!hasInsurance && payingMethod !== 'No Insurance') {

            if ((!location?.standardTest || !location?.isCreditCard?.standard) && location?.isExpressSameDay && location?.isCreditCard?.express) {
                updateFormValues({ isExpressSameDayTest: true })
            } else if ((!location?.standardTest || !location?.isCreditCard?.standard) && (!location?.isExpressSameDay || !location?.isCreditCard?.express) && location?.rapidTest && location?.isCreditCard?.rapid) {
                updateFormValues({ isRapidTest: true })
            } else if ((!location?.standardTest || !location?.isCreditCard?.standard) && (!location?.isExpressSameDay || !location?.isCreditCard?.express) && (!location?.rapidTest || !location?.isCreditCard?.rapid) && location?.isAntigen && location?.isCreditCard?.antigen) {
                updateFormValues({ isAntigen: true })
            }

        }
        else if (hasInsurance) {

            if ((!location?.standardTest || !location?.isInsurance?.standard) && location?.isExpressSameDay && location?.isInsurance?.express) {
                updateFormValues({ isExpressSameDayTest: true })
            } else if ((!location?.standardTest || !location?.isInsurance?.standard) && (!location?.isExpressSameDay || !location?.isInsurance?.express) && location?.rapidTest && location?.isInsurance?.rapid) {
                updateFormValues({ isRapidTest: true })
            } else if ((!location?.standardTest || !location?.isInsurance?.standard) && (!location?.isExpressSameDay || !location?.isInsurance?.express) && (!location?.rapidTest || !location?.isInsurance?.rapid) && location?.isAntigen && location?.isInsurance?.antigen) {
                updateFormValues({ isAntigen: true })
            } 

        } else if (payingMethod === 'No Insurance') {

            if ((!location?.standardTest || !location?.isNoInsurance?.standard) && location?.isExpressSameDay && location?.isNoInsurance?.express) {
                updateFormValues({ isExpressSameDayTest: true })
            } else if ((!location?.standardTest || !location?.isNoInsurance?.standard) && (!location?.isExpressSameDay || !location?.isNoInsurance?.express) && location?.rapidTest && location?.isNoInsurance?.rapid) {
                updateFormValues({ isRapidTest: true })
            } else if ((!location?.standardTest || !location?.isNoInsurance?.standard) && (!location?.isExpressSameDay || !location?.isNoInsurance?.express) && (!location?.rapidTest || !location?.isNoInsurance?.rapid) && location?.isAntigen && location?.isNoInsurance?.antigen) {
                updateFormValues({ isAntigen: true })
            } 
        }

    }
    const handleNoOfTests = () => {
        let countins = 0
        let countnoIns = 0
        let countcred = 0
        
        if (location?.isInsurance?.rapid && location?.rapidTest) {
            countins++
        }
        if (location?.isInsurance?.express && location?.isExpressSameDay) {
            countins++
        }
        if (location?.isInsurance?.standard && location?.standardTest ) {
            countins++
        }
        if (location?.isInsurance?.antigen && location?.isAntigen) {
            countins++
        }
        setNoOfTestsInsu(countins)
        if (location?.isNoInsurance?.rapid && location.rapidTest) {
            countnoIns++
        }
        if (location?.isNoInsurance?.express && location?.isExpressSameDay) {
            countnoIns++
        }
        if (location?.isNoInsurance?.standard && location?.standardTest) {
            countnoIns++
        }
        if (location?.isNoInsurance?.antigen && location?.isAntigen) {
            countnoIns++
        }
        setNoOfTestsNoInsu(countnoIns)
        if (location?.isCreditCard?.rapid && location.rapidTest) {
            countcred++
        }
        if (location?.isCreditCard?.express && location?.isExpressSameDay) {
            countcred++
        }
        if (location?.isCreditCard?.standard && location?.standardTest) {
            countcred++
        }
        if (location?.isCreditCard?.antigen && location?.isAntigen) {
            countcred++
        }
        setNoOfTestsCred(countcred)
    }
	var amount: number = 0;

	if (registeringFor === 'minorOnly') {
		amount = price * form.minors.length;
	} else if (registeringFor === 'myself') {
		amount = price;
	} else {
		amount = price + price * form.minors.length;
	}

	const { upateTestType,closedLocation } = useSharedDispatch();

	const selectedDate = date
		? parse(date, config.dateFormat, new Date())
		: new Date();

	const locationStartDate = location
		? parse(location.startDate, config.dateFormat, new Date())
		: null;

	const [error, setError] = useState<string | null>(null);
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const [openNameDialog, setopenNameDialog] = React.useState<boolean>(false);
	const handleNameOpen = () => {
		setopenNameDialog(true);
	};
	const handleNameClose = () => {
		setopenNameDialog(false);
	};
	const [openDobDialog, setopenDobDialog] = React.useState<boolean>(false);
	const handleDobOpen = () => {
		setopenDobDialog(true);
	};
	const handleDobClose = () => {
		setopenDobDialog(false);
	};
	const [openPhoneDialog, setopenPhoneDialog] = React.useState<boolean>(false);
	const handlePhoneOpen = () => {
		setopenPhoneDialog(true);
	};
	const handlePhoneClose = () => {
		setopenPhoneDialog(false);
	};
	const [openAddressDialog, setopenAddressDialog] =
		React.useState<boolean>(false);
	const handleAddressOpen = () => {
		setopenAddressDialog(true);
	};
	const handleAddressClose = () => {
		setopenAddressDialog(false);
	};
	const [openEmployeeIDdialog, setopenEmployeeIdDialog] = React.useState<boolean>(false);
	const handleEmployeeIdOpen = () => {
		setopenEmployeeIdDialog(true);
	};
	const handleEmployeeIdClose = () => {
		setopenEmployeeIdDialog(false);
	};
	const [openMinorDialog, setopenMinorDialog] = React.useState<boolean>(false);
	const handleMinorOpen = () => {
		setopenMinorDialog(true);
	};
	const handleMinorClose = () => {
		setopenMinorDialog(false);
	};
	const handleSetError = () => {
		setError('');
	};

	const [editKey, setEditKey] = useState({ key: -1 });
	const [loading, setLoading] = useState<boolean>(false);
	const [btnloading, setBtnLoading] = useState<boolean>(false);
	const [message, setMessage] = useState('');

	const [isAmountReduce, setAmountReduce] = useState(false);

	const [freeCode, setFreeCode] = useState(false);
	const [promoCodes, setPromoCode] = useState('EMPLOYEE');

	const { apptFromSchdule, isVaccinate, reasonType, vaccineType, toNextStep } =
		useSharedState();

	const [testType, setTestType] = useState('');
	const [flow, setFlow] = useState('');
	// let datadestination : any = form?.destination && (typeof(form?.destination) === "string" ? form?.destination : form?.destination?.value ?? 0)
	// const npData = npArray.includes(datadestination) && form?.testSelection === 'flight'
	const adminLogin = localStorage.getItem('isAdmin');

	const getDestination = async () => {
		const arr: any = [];
		const response = await getNpDestionation({}).then((result: any) => {
			const data = result.data.data;
			data.forEach((element: any) => {
				arr.push(element.value);
			});
			console.log('values', arr);
		});
		setNpArray(arr);
	};
	useEffect(() => {
		if (npArray.length) {
			updateFormValues({amount:
				convertPricing(
					form?.isExpressSameDayTest,
					form?.isRapidTest,
					prices,
					travellType,
					form?.destination,
					form?.isAntigen,
					npArray,
					form?.location?.isNpDestination
				) * form.reservationCount
			})
		}
	}, [npArray.length])

	// set user data into form
	useEffect(() => {
		getDestination();
		GoogleAnalytics(form, '/Confirm Booking');
		upateTestType(
			isRapidTest
				? false
				: isExpressSameDayTest
					? false
					: isAntigen
						? false
                        : isPanel ? false
						: true
		);
		window.scrollTo(0, 0);
		updateFormValues({
			...userData,
			destination: destination,
			travelType: travellType,
			airline: airline,
			airlineCode: airlineCode,
			registeringFor: registeringFor,
			cPartnerID: location?.cPartnerID || '',
			minors: [...form.minors],
			amount: amount,
            isHubSpotContact:appointment?.isHubSpotContact,
            passportCountry:appointment?.passportCountry,
            passportNo: appointment?.passportNo
		});
		if (promoCode === '') {
			setMessage('');
			updateFormValues({
				couponId: '',
				discountAmount: 0,
			});
		}

		if (
			(isExpressSameDayTest || isRapidTest || standradTest) &&
			promoCode !== '' &&
			!hasInsurance &&
			payingMethod !== 'No Insurance'
		) {
			promoCodeValidation('');
			// setMessage('');
			// updateFormValues({
			//     couponId: '',
			//     discountAmount: 0,
			// });
		}

		let validTestType = isExpressSameDayTest
							? 'Express'
								: isRapidTest
								? 'Rapid'
									: isAntigen
									? 'Antigen'
										: isPanel
										? 'Flu A&B/Covid-19'
											: 'Standard';
        let validFlow = testSelection == 'flight'
                        ? "isTravel"
                        : testSelection == 'general'
                            ? "isGeneral"
                            : testSelection == 'school'
                                ? "isSchool"
                                : testSelection == 'community'
                                    ? "isCommunity"
                                    : "isWork";
		// updateFormValues({...form, testType:validTestType})
        setTestType(validTestType);
        setFlow(validFlow)

	}, [isExpressSameDayTest, isRapidTest, standradTest, isPanel]);

	useEffect(() => {
		if (
			payingMethod !== 'Insurance' &&
			payingMethod !== 'No Insurance' &&
			(payingMethod === 'Credit card' || payingMethod === 'Credit Card')
		) {
			empMail();
		}
	}, [standradTest || isExpressSameDayTest, showChangeDatetimeModal]);

	useEffect(() => {
		if (testSelection === 'flight' && destination === 'JPN' && location) {
			location.isAntigen = false
		}
	}, [location])

	useEffect(() => {
		if(rebooking.active){
			 if( locations && locations.filter((e:any)=>e?.qbenchCustomerId==appointment?.location?.qbenchCustomerId).length ===0){
				 closedLocation(true) 
			 }
			 const filteredloc:any=locations?.filter((loc:any)=>loc?.qbenchCustomerId===form.location?.qbenchCustomerId)
			 if( filteredloc?.length && !filterRebookLocations(form,filteredloc).length){
				 closedLocation(true)
			 }
		   }
	 }, [])

	 
	const empMail = async () => {
		let empData = await getEmployeeCoupon(userData?.email);
		const dataEmail: any = userData?.email?.split('@');
		const worksiteEmail = dataEmail[1];

		if (
			(worksiteEmail === 'worksitelabs.com' || !!empData.data) &&
			testSelection === 'general' &&
			!hasInsurance &&
			(payingMethod === 'Credit Card' || payingMethod === 'Credit card')
		) {
			setFreeCode(true);
			updateFormValues({ promoCode: employeePromocode?.toString()});
			promoCodeValidation(employeePromocode?.toString());
			// updateFormValues({ promoCode: 'EMPLOYEE' });
			// promoCodeValidation('EMPLOYEE');
			setIsEmpEmail(true);
		} else {
			setFreeCode(false);
		}
	};

	const promoCodeValidation = async (promoCodes: any) => {
		try {
			setBtnLoading(true);
			let count = 0;
			if(appointment.registeringFor === 'myself'){
				count = 1;
			}else if(appointment.registeringFor === 'minorOnly'){
				count = appointment.minors.length;
			}else{
				count = 1 + +appointment.minors.length;
			}

			const response = await getCoupon({
				code: promoCodes ? promoCodes : promoCode,
				email: userData?.email,
				testSelection: testSelection,
				qbenchCustomerId: location?.qbenchCustomerId,
                testType,
                flow,
				count
			});

			if (
				response?.data?.status === 200 &&
				!hasInsurance &&
				payingMethod !== 'Insurance' &&
				payingMethod !== 'No Insurance'
			) {
				setisPromoCodeValid(false)
				updateFormValues({ couponId: response.data.couponId });
				setAmountReduce(response.data.data.isAmountReduce);
                setApply(true);
				if (response.data.data.isAmountReduce === false) {
					if (isRapidTest) {
						updateFormValues({
							discountAmount: response.data.data.RapidTest_Amount * count,
						});
						setMessage(
							`Coupon code applied succesfully. You have got $${response.data.data.RapidTest_Amount * count} as discount. Please click pay now.`
						);
					} else if (isExpressSameDayTest) {
						updateFormValues({
							discountAmount: response.data.data.ExpressTest_Amount * count,
						});
						setMessage(
							`Coupon code applied succesfully. You have got $${response.data.data.ExpressTest_Amount * count} as discount. Please click pay now.`
						);
					} else if (isAntigen) {
                        updateFormValues({
                            discountAmount: response.data.data.AntigenTest_Amount * count,
                        });
                        setMessage(
                            `Coupon code applied succesfully. You have got $${response.data.data.AntigenTest_Amount * count
                            } as discount. Please click pay now.`
                        );
					} else {
						updateFormValues({
							discountAmount: response.data.data.StandardTest_Amount * count,
						});
						setMessage(
							`Coupon code applied succesfully. You have got $${response.data.data.StandardTest_Amount * count} as discount. Please click pay now.`
						);
					}
				} else if (
					response.data.data.isAmountReduce === true &&  response.data.data.iswslEmployee === false &&
					(
						allPromocodes?.includes(promoCode) ||
                        employeePromocode?.includes(promoCodes) ||
                        employeePromocode?.includes(promoCode && !hasInsurance)
						// promoCode === 'PORTMEDPMM' ||
						// promoCodes === 'EMPLOYEE' ||
						// promoCode === 'EMPLOYEE' || 
						// promoCode === 'BGC10' || 
						// promoCodes === 'BGC10'
					) 
				) {
					updateFormValues({
						discountAmount: amount,
					});
					if((promoCode === 'PORTMEDPMM' && (testSelection.toLowerCase() ==='flight' || testSelection.toLowerCase() ==='general')) || (promoCode !== 'PORTMEDPMM')){
						updateFormValues({
                            discountAmount: amount,
                        });
                        setMessage(
							'Coupon code successfully applied.  There will be no credit card charge.  Please click "Confirm" to complete your booking.'
						);
                    } else {
						updateFormValues({
                            discountAmount: 0,
                        });
						updateFormValues({ promoCode:'' })
                        setAmountReduce(false);
						setMessage(promoCode === 'PORTMEDPMM' ? 'PORTMEDPMM will applicable for Travel and General flow' :
						'Sorry, you cannot book Rapid PCR Test or Antigen Test using this Email. Please change the test type to Standard or Express'
						);
					}
				} else if((response.data.data.iswslEmployee === true &&
                    (response.data.data.isMaxRedemption ||
                    employeePromocode?.includes(promoCodes) ||
                    employeePromocode?.includes(promoCode && !hasInsurance)))
                    ){
                        if(form?.minors.length === 0 || response.data.data.isMaxRedemption){
                            updateFormValues({
                                discountAmount: amount,
                            })
                            setMessage(
                                'Coupon code successfully applied.  There will be no credit card charge.  Please click "Confirm" to complete your booking.'
                            );
                        } else if (form?.minors.length !== 0 && isRapidTest) {
                            updateFormValues({
                                // discountAmount: (response.data.amount / 100) * 3,
                                discountAmount: response.data.data.RapidTest_Amount,
                            });
                            setMessage(
                                `Coupon code applied succesfully. You have got $${response.data.data.RapidTest_Amount} as discount. Please click pay now.`
                            );
                        } else if (form?.minors.length !== 0  && isExpressSameDayTest) {
                            updateFormValues({
                                discountAmount:
                                    response.data.data.ExpressTest_Amount,
                            });
                            setMessage(
                                `Coupon code applied succesfully. You have got $${response.data.data.ExpressTest_Amount} as discount. Please click pay now.`
                            );
                        }else if (form?.minors.length !== 0  && isAntigen) {
                            updateFormValues({
                                discountAmount:
                                response.data.data.AntigenTest_Amount,
                            });
                            setMessage(
                                `Coupon code applied succesfully. You have got $${response.data.data.AntigenTest_Amount} as discount. Please click pay now.`
                            )
                        } else {
                            updateFormValues({
                                discountAmount:
                                    response.data.data.StandardTest_Amount,
                            });
                            setMessage(
                                `Coupon code applied succesfully. You have got $${response.data.data.StandardTest_Amount} as discount. Please click pay now.`
                            );
                        }
                } else {
                    updateFormValues({
                        discountAmount: 0,
                    });
                    setMessage(
                        `Invalid Coupon Code`
                    );
                    updateFormValues({ promoCode: '' })
                    setAmountReduce(false)
                }
			} else {
				if (promoCode !== '') 
				setMessage(response?.data?.message);
				updateFormValues({ discountAmount: 0 });
				updateFormValues({ promoCode: '' });
				setAmountReduce(false);
			}
			setBtnLoading(false);
		} catch (e) {
			setMessage('Invalid Coupon Code');
			updateFormValues({ discountAmount: 0 });
			updateFormValues({ promoCode: '' });
			console.log('error1', e);
			setBtnLoading(false);
			setAmountReduce(false);
		}
	};

	const handleRefersionInsurance = async () => {
		await finalRefersion({
			appointmentDetails: {
				shipping: amount,
				customer: {
					first_name: form?.firstName,
					last_name: form?.lastName,
					email: form?.email,
				},
				items: {
					price: amount,
					quantity:
						form?.registeringFor === 'minorOnly'
							? form?.minors.length
							: registeringFor === 'myself'
								? 1
								: form?.minors.length + 1,
					sku: 'covid test',
				},
			},
		});
	};

	const handleCreateAppointment = async (method: string) => {
		try {
			setLoading(true);
			const response: any = await checkReservationValidity(form?.reservationId);

			if (response.data.status == 400 && location.cPartnerID !== 'LLT001') {
				setLoading(false);
				updateFormValues({
					slot: undefined,
				});

				if (hasInsurance || payingMethod === 'No Insurance') {
					if (rfsn_id && rfsn_aid && rfsn_cs) {
						handleRefersionInsurance();
					}
				}
			} else if (cPartnerID === 'LLT001') {
				const result = await createAppointmentForLasLomitas({
					...appointment,
					travelType: travellType,
					paymentIntentId: method,
					firstName:
						form?.registeringFor === 'minorOnly' && !!form?.guardian?.firstName
							? form?.guardian?.firstName
							: form?.firstName,
					lastName:
						form?.registeringFor === 'minorOnly' && !!form?.guardian?.lastName
							? form?.guardian?.lastName
							: form?.lastName,
					guardian:
						form?.registeringFor === 'minorOnly' && !!form?.guardian
							? form?.guardian
							: null,
					minors: minors.length
						? minors?.map((minor: any) => {
							if (
								minor['isChecked'] === true ||
								minor['isChecked'] === false
							) {
								delete minor['isChecked'];
								delete minor['index'];
							}
							return minor;
						})
						: [],
					insurance: hasInsurance ? form?.insurance : null,
					destination: destination,
					registeringFor: form?.registeringFor,

					district: district,
					isNotHavePermanentAddress: !!userData?.isNotHavePermanentAddress
						? userData?.isNotHavePermanentAddress
						: form.isNotHavePermanentAddress,

					school: testSelection === 'school' ? form?.school : '',
					schoolTestFor: testSelection === 'school' ? form?.schoolTestFor : '',
					studentID: testSelection === 'school' ? form?.studentID : '',
					adminLogin:
						adminLogin && adminLogin !== null && adminLogin !== undefined
							? true
							: false,
				});

				await new Promise<void>((resolve) => {
					setTimeout(() => {
						resolve();
					}, 500);
				});
				updateFormValues({
					confirmationId: result.data.confirmationId,
					acknowledgementId: result.data.acknowledgementId,
				});
				if (hasInsurance || payingMethod === 'No Insurance') {
					if (rfsn_id && rfsn_aid && rfsn_cs) {
						handleRefersionInsurance();
					}
				}
				goToSkip2Step();
				setLoading(false);
			} else {
				let Data: any = {
					email: userData?.email ? userData?.email : form?.email,
					frontCard: form?.govtId?.frontCard,
					backCard: form?.govtId?.backCard,
				};
				updateFormValues({
					govtId: Data,
				});
				if (
					hasGovtId &&
					(form?.govtId?.email === '' ||
						form?.govtId?.email === null ||
						form?.govtId?.email === undefined)
				) {
					// console.log('check',{
					//   email: userData?.email ? userData?.email : form?.email,
					//   frontCard: form?.govtId?.frontCard,
					//   backCard: form?.govtId?.backCard,
					// })
					await AddGovtId({
						...govtId,
						email: userData?.email ? userData?.email : form?.email,
					});
				}
				const result: any = await createAppointment({
					...appointment,
					travelType: travellType,
					paymentIntentId: method,
					firstName:
						form?.registeringFor === 'minorOnly' && !!form?.guardian?.firstName
							? form?.guardian?.firstName
							: form?.firstName,
					lastName:
						form?.registeringFor === 'minorOnly' && !!form?.guardian?.lastName
							? form?.guardian?.lastName
							: form?.lastName,
					guardian:
						form?.registeringFor === 'minorOnly' && !!form?.guardian
							? form?.guardian
							: null,
					minors: minors.length
						? minors?.map((minor: any) => {
							if (
								minor['isChecked'] === true ||
								minor['isChecked'] === false
							) {
								delete minor['isChecked'];
								delete minor['index'];
							}
							return minor;
						})
						: [],
					insurance: hasInsurance ? form?.insurance : null,                    
					destination: destination,
					registeringFor: form?.registeringFor,
					testType:testType,
					// licenseBackCard: '',
					// licenseFrontCard: '',
					district: district,
					isNotHavePermanentAddress: !!userData?.isNotHavePermanentAddress
						? userData?.isNotHavePermanentAddress
						: form.isNotHavePermanentAddress,
					adminLogin:
						adminLogin && adminLogin !== null && adminLogin !== undefined
							? true
							: false,
				});console.log("form",form.insurance)

				await new Promise<void>((resolve) => {
					setTimeout(() => {
						resolve();
					}, 500);
				});
				if (result?.data?.confirmationId || result?.data?.acknowledgementId || rebooking.active) {
					let isPromoCode: boolean = false;
					if (
						couponId &&
						promoCode !== null &&
						promoCode !== '' &&
						discountAmount
					) {
						isPromoCode = true;
					}
					if (
						method === 'school' ||
						method === 'community' ||
						cPartnerID === 'KEN001' ||
						isPromoCode || rebooking.active
					)
						updatePayment(form?.reservationId, 3, form);
				}

				// const response: any = await checkReservationValidity(form?.reservationId);
				// console.log(response, 'response');
				// if (response.data.status == 400) {
				//   setLoading(false);
				//   updateFormValues({
				//     slot: undefined,
				//   });
				// } else {

				// }
                updateRegister({
                    email: userData?.email,
                    isHubSpotContact: appointment?.isHubSpotContact,
                    collectionSite: appointment?.location?.hubSpotCollectionSite ?? ''
                });
				updateFormValues({
					confirmationId: result.data.confirmationId,
					acknowledgementId: result.data.acknowledgementId,
				});
				updateFormValues({
					govtId: {
						...govtId,
						email: userData?.email ? userData?.email : form?.email,
					},
				});
				if (hasInsurance || payingMethod === 'No Insurance') {
					if (rfsn_id && rfsn_aid && rfsn_cs) {
						handleRefersionInsurance();
					}
				}
				if(rebooking.active){
                    updateIsRebookedBySampleId(rebooking)
                    goToStep(1)
                }else {
					goToSkip2Step();
				}
				setLoading(false);
			}
		} catch (e: any) {
			setError(e.message);
			setLoading(false);
		}
	};

	const rfsn_id = localStorage.getItem('rfsn_v4_id');
	const rfsn_aid = localStorage.getItem('rfsn_v4_aid');
	const rfsn_cs = localStorage.getItem('rfsn_v4_cs');

	const TestTypeBox: React.FC<any> = ({
		testType,
		location,
		values,
		locationTestType,
		title,
		testDuration,
		isCostLabelVisibile,
        tooltip=''
	}) => {
		let rapidTest = false,
			expressTest = false,
			standardTest = false,
			antigen = false,
            panel = false;
		if (title === 'RAPID PCR TEST') {
			rapidTest = true;
			expressTest = false;
			standardTest = false;
			antigen = false;
            panel = false;
		} else if (title === 'EXPRESS PCR TEST') {
			rapidTest = false;
			expressTest = true;
			standardTest = false;
			antigen = false;
            panel = false;
		} else if (title === 'STANDARD PCR TEST') {
			rapidTest = false;
			expressTest = false;
			standardTest = true;
			antigen = false;
            panel = false;
		} else if (title === 'ANTIGEN TEST') {
			rapidTest = false;
			expressTest = false;
			standardTest = false;
			antigen = true;
            panel = false;
		}
        else if (title === 'Flu A&B/Covid-19') {
            rapidTest = false;
            expressTest = false;
            standardTest = false;
            antigen = false;
            panel = true;
        }
		return (
            <Tooltip title={ tooltip ?? ''}  placement='bottom'>
			<InnerBox
				selected={testType}
				style={
					standardTest &&
						(location.qbenchCustomerId !==
							config.qbenchCustomerId.SFOAirport_BARTStation ||
							(location.qbenchCustomerId ==
								config.qbenchCustomerId.SFOAirport_BARTStation &&
								testSelection == 'sfoairport'))
						? {}
						: {
							border: locationTestType
								? '1px solid #D3D3D3'
								: '1px solid rgba(36, 61, 77, 0.14)',
							background: locationTestType
								? '#D3D3D3'
								: testType
									? colors.rebrandBlack
									: colors.white,
							cursor: locationTestType ? 'not-allowed' : 'pointer',
							textAlign: 'center',
						}
				}
				aria-disabled={
					location?.qbenchCustomerId ===
						config.qbenchCustomerId.SFOAirport_BARTStation &&
						testSelection !== 'sfoairport'
						? true
						: standardTest
				}
				onClick={async () => {
					if (
						!(
							standardTest &&
							(location.qbenchCustomerId !==
								config.qbenchCustomerId.SFOAirport_BARTStation ||
								(location.qbenchCustomerId ==
									config.qbenchCustomerId.SFOAirport_BARTStation &&
									testSelection == 'sfoairport'))
						)
					) {
						if (locationTestType) {
							return;
						}
					}
					upateTestType(standardTest);
					appointment.isWalkUpTest
						? updateFormValues({
							isExpressSameDayTest: expressTest,
							isRapidTest: rapidTest,
							isAntigen: antigen,
                            isPanel: panel
						})
						: updateFormValues({
							slot: undefined,
							isExpressSameDayTest: expressTest,
							isRapidTest: rapidTest,
							isAntigen: antigen,
                            isPanel: panel,
							testType:testType
						});
					if (!values.departureDateAndTime) {
						return;
					}
					const overTime = new Date(departureDateAndTimedata).getHours();
					let newDate: any = new Date(departureDateAndTimedata).getHours();
					if (standardTest) {
						newDate = subHours(
							startOfDay(departureDateAndTimedata),
							config.maxAdvanceHours
						);
						upateTestType(standardTest);
					} else {
						newDate = subHours(
							departureDateAndTimedata,
							overTime >= 12
								? 24
								: rapidTest
									? config.rapidSlots.minAdvanceHours
									: config.vipSlots.minAdvanceHours
						);
					}
					appointment.isWalkUpTest
						? updateFormValues({
							isRapidTest: rapidTest,
							isAntigen: antigen,
							isExpressSameDayTest: expressTest,
                            isPanel: panel,
							date: format(newDate, config.dateFormat),
						})
						: updateFormValues({
							slot: undefined,
							isRapidTest: rapidTest,
							isAntigen: antigen,
							isExpressSameDayTest: expressTest,
                            isPanel: panel, 
							date: format(newDate, config.dateFormat),
							reservationId: null,
							testType:testType,
						});
				}}
			>
				<TestTypeTitle
					selected={testType}
					style={
						standardTest &&
							(location.qbenchCustomerId !==
								config.qbenchCustomerId.SFOAirport_BARTStation ||
								(location.qbenchCustomerId ==
									config.qbenchCustomerId.SFOAirport_BARTStation &&
									testSelection == 'sfoairport'))
							? {}
							: {
								color: locationTestType
									? '#939393'
									: testType
										? colors.white
										: colors.rebrandBlack,
							}
					}
				>
					{title}
				</TestTypeTitle>
				{isCostLabelVisibile && (
					<TestCostLabel
						selected={testType}
						style={
							standardTest &&
								(location.qbenchCustomerId !==
									config.qbenchCustomerId.SFOAirport_BARTStation ||
									(location.qbenchCustomerId ==
										config.qbenchCustomerId.SFOAirport_BARTStation &&
										testSelection == 'sfoairport'))
								? {}
								: {
									color: locationTestType
										? '#939393'
										: testType
											? colors.white
											: colors.rebrandBlack,
								}
						}
					>
						$
						{convertPricing(
							expressTest,
							rapidTest,
							prices,
							travellType,
							destination,
							antigen,
							npArray,
							location?.isNpDestination
						)}
					</TestCostLabel>
				)}
				<TestDurationInfoLabel
					selected={testType}
					style={
						standardTest &&
							(location.qbenchCustomerId !==
								config.qbenchCustomerId.SFOAirport_BARTStation ||
								(location.qbenchCustomerId ==
									config.qbenchCustomerId.SFOAirport_BARTStation &&
									testSelection == 'sfoairport'))
							? {}
							: {
								color: locationTestType
									? '#939393'
									: testType
										? colors.white
										: colors.rebrandBlack,
							}
					}
				>
					{testDuration}
				</TestDurationInfoLabel>
			</InnerBox>
            </Tooltip>
		);
	};
	return (
		<Container size="lg">
			<Content>
				<PageHeader>
					<PageTitle>{location?.partnerName === 'goHealth' ? <strong><span style={{color:'#248900'}}>Step 1</span> out of 2 Complete</strong> :'Confirm your appointment details'}</PageTitle>
					<PageSubTitle>
					{location?.partnerName !== 'goHealth' &&
						'Please review and confirm the information below is correct by clicking the'}
						{location?.partnerName !== 'goHealth' ? location?.cPartnerID === 'KEN001' || rebooking.active ? (
							<strong>"Confirm".</strong>
						) : testSelection === 'school' ||
							payingMethod === 'Insurance' ||
							payingMethod === 'No Insurance' ||
							(!!isEmpEmail &&
								testSelection === 'general' &&
								(payingMethod === 'Credit Card' ||
									payingMethod === 'Credit card')) ? (
							<strong>"Confirm".</strong>
						) : (
							<strong>"Confirm & pay".</strong>
						):''}{' '}
						{location?.cPartnerID === 'LLT001' ? (
							<>
								Please note that you are only required to register one time.
								This registration will automatically place you for weekly
								testing at the location below.
							</>
						) : (
							''
						)}
					</PageSubTitle>
                    {location?.partnerName === 'goHealth' ? <GoHealthCompleteButton/> : ''}
				</PageHeader>
				<Formik
					initialValues={{
						slot,
						commitToAttend,
						agreeToCancel,
						isExpressSameDayTest,
						departureDateAndTime,
					}}
					onSubmit={async (values) => {
						if(rebooking.active){
                            handleCreateAppointment(testSelection);
                        } else if (
							!form.firstName ||
							!form.lastName ||
							!form.birthDate ||
							!form.address.state ||
							!form.address.country
						) {
							setError(
								`${!form.address.state || !form.address.country
									? 'Please update your address details'
									: 'Your details are incomplete. Please edit before proceed to next step.'
								}`
							);
						} else if (
							apptFromSchdule &&
							testSelectFlow &&
							govtId.frontCard !== undefined &&
							govtId.frontCard !== '' &&
							govtId.frontCard !== null &&
							(!userData?.isVerified || toNextStep)
						) {
							setError('');
							handleCreateAppointment(testSelection);
						}else if (
							apptFromSchdule &&
							testSelectFlow &&
							hasInsurance &&
							(!userData?.isVerified || form?.isIdntHaveAccnt || toNextStep)
						) {
							createInsurance({
								...insurance,
                                createdDate: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
								email: userData?.email ? userData?.email : email,
							});
							setError('');
							handleCreateAppointment('general');
						}                         
                        else if (
							payingMethod === 'No Insurance' ||
							payingMethod === 'Insurance' ||
							allPromocodes?.includes(promoCode)
							// promoCode === 'EYEPROD' ||
							// promoCode === 'EMPLOYEE' ||
							// promoCode === 'BGC10' ||
							// promoCode === 'PORTMEDPMM'

						) {
							setError('');
							if (form?.registeringFor === "myselfAndMinor" && employeePromocode?.includes(promoCode)) {
								setShowPaymentModal(true);
							} else {
								setError('');
								handleCreateAppointment(testSelection);
							}
						} else if (hasInsurance) {
							setError('');
							handleCreateAppointment(testSelection);
						} else if (
							testSelection === 'general' ||
							testSelection === 'flight' ||
							testSelection === 'sfoairport' ||
							payingMethod === 'Credit card'
						) {
							// setLoading(false);
							// setShowPaymentModal(true);

							//   (testSelection === 'general' || testSelection === 'flight') &&
							//   payingMethod === 'Out of pocket'
							// ) {
							setLoading(true)
							const response: any = await checkReservationValidity(
								form?.reservationId
							);

							if (response.data.status == 400) {
								setLoading(false);
								updateFormValues({
									slot: undefined,
								});
							} else {
								setLoading(false);
								setShowPaymentModal(true);
							}
						} else if (
							(testSelection === 'general' || testSelection === 'flight') &&
							payingMethod === 'No Insurance'
						) {
							setError('');
							handleCreateAppointment('general');
						} else if (testSelection === 'kern' || cPartnerID === 'KEN001' || ((payingMethod)?.toLowerCase() === ('No payment').toLowerCase() && rebooking.active)) {
							setError('');
							handleCreateAppointment('general');
						} else {
							goToNextStep();
						}
					}}
				>
					{({ values }) => (
						<Form>
							{/* <Checkboxes>
								<Checkbox
									name="commitToAttend"
									isRequired
									onChange={(checked) =>{
									updateFormValues({ commitToAttend: checked });
									}}
								>
									I commit to attend this appointment as shown<br/>
									</Checkbox>
									<Checkbox
										name="agreeToCancel"
										isRequired
										onChange={(checked) => {
											updateFormValues({ agreeToCancel: checked })
										}
										}
										>
									I <strong>AGREE</strong> to cancel if I am unable to attend so the appointment
									can be released to another employee
								</Checkbox>
								</Checkboxes> */}
							<Title>Testing information</Title>

							{/* {!!testSelection && testSelection !== 'flight' ? null : (
                                <TestingInfoContainer>
                                    <TestingInfo>
                                        <PinIcon />
                                        <strong>Departure date and time</strong>
                                        <br />
                                        {departureDateAndTime}
                                    </TestingInfo>
                                    <TestingInfoBtn
                                        type="button"
                                        disabled={loading}
                                        onClick={() => {
                                            setShowDepartureDateandTime(
                                                showDepartureDateandTime ? false : true
                                            );
                                            updateFormValues({
                                                departureDateAndTime: rollbackValues,
                                            });
                                            // toggleShowDepartureDateTimeModal(true)
                                            setdepartureDateAndTimedata(
                                                departureDateAndTime
                                                    ? parse(
                                                        departureDateAndTime,
                                                        config.dateTimeFormat,
                                                        new Date()
                                                    )
                                                    : null
                                            );
                                            setshowDepartureTime(true);
                                        }}
                                        size="sm"
                                        libraryType="default"
                                    >
                                        Change departure date and time
                                    </TestingInfoBtn>
                                </TestingInfoContainer>
                            )}
                            {showDepartureDateandTime && (
                                <>
                                    <ChangeDepartureTimeModal
                                    showModal={showDepartureTime}
                                    closeModal={() => setshowDepartureTime(false)}
                                    />
                                    <FormLabel label="Flight departure date & time (use the final leg of departure)">
                                        <DatepickerField
                                            dateAndTime={true}
                                            name="departureDateAndTimedata"
                                            showTimeSelect
                                            minDate={
                                                locationStartDate === null ||
                                                    isBefore(locationStartDate, new Date())
                                                    ? new Date()
                                                    : locationStartDate
                                            }
                                            maxDate={disableFutureDt()}
                                            onChange={(newDepartureDate: any) => {
                                                //This time zone offset is for pacific daylight time
                                                //Will need to add the other time zones offset later.
                                                let getTimeZoneOffSet = new Date(
                                                    newDepartureDate
                                                ).getTimezoneOffset();

                                                const overTime = new Date(newDepartureDate).getHours();

                                                let newDate = values.isExpressSameDayTest
                                                    ? subHours(
                                                        newDepartureDate,
                                                        overTime >= 12
                                                            ? 24
                                                            : config.vipSlots.minAdvanceHours
                                                    ) // startOfDay(newDepartureDate)
                                                    : // : subHours(newDepartureDate, config.maxAdvanceHours);
                                                    subHours(
                                                        newDepartureDate,
                                                        getTimeZoneOffSet == 420 ||
                                                            getTimeZoneOffSet == 300 ||
                                                            getTimeZoneOffSet == 240
                                                            ? 71
                                                            : config.maxAdvanceHours
                                                    );

                                                if (isBefore(newDate, new Date())) {
                                                    newDate = startOfDay(new Date());
                                                }

                                                setdepartureDateAndTimedata(newDepartureDate);

                                                updateFormValues({
                                                    slot: undefined,
                                                    departureDateAndTime: format(
                                                        newDepartureDate,
                                                        config.dateTimeFormat
                                                    ),
                                                    date: format(newDate, config.dateFormat),
                                                });
                                            }}
                                        />
                                    </FormLabel>
                                    <div style={{ display: 'flex', margin: '0px 0px 25px 0px' }}>
                                        <ReturnButton
                                            disabled={false}
                                            libraryType="default"
                                            onClick={() => {
                                                updateFormValues({
                                                    departureDateAndTime: rollbackValues,
                                                });
                                                updateFormValues({ slot: rollbackSlot });
                                                setdepartureDateAndTimedata(
                                                    rollbackValues
                                                        ? parse(
                                                            rollbackValues,
                                                            config.dateTimeFormat,
                                                            new Date()
                                                        )
                                                        : null
                                                );
                                                setShowDepartureDateandTime(false);
                                                updateFormValues({ date: rollbackDate });
                                            }}
                                        >
                                            Cancel
                                        </ReturnButton>
                                        <div style={{ margin: '10px' }} />
                                        <Button
                                            onClick={() => {
                                                setShowDepartureDateandTime(false);
                                                slot === undefined
                                                    ? setShowChangeDatetimeModal(true)
                                                    : setShowChangeDatetimeModal(false);
                                            }}
                                            libraryType="primary"
                                            disabled={false}
                                        >
                                         {loading ? 'Processing...' : 'Verify Identity'}
                                            Change
                                        </Button>
                                    </div>
                                </>
                            )} */}
							<TestingInfoContainer>
								<TestingInfo className='white'>
									<PinIcon />
									<strong>{form.location?.name}</strong>
									<br />
									{location?.address1} {location?.address2}
									<br />
									{cPartnerID === 'LLT001' ? location?.room : ''}
								</TestingInfo>
								{location?.partnerName !== 'goHealth' && <TestingInfoBtn
									hidden={
										testSelection === 'kern' ||
											isWalkUpTest ||
											location?.cPartnerID === 'LLT001'
											? true
											: false
									}
									type="button"
									disabled={loading}
									onClick={() => toggleChangeLocationModal(true)}
									size="sm"
									libraryType="default"
									className='btn elevated-btn'
								>
									Change Location
								</TestingInfoBtn>}
							</TestingInfoContainer>
								{isLocation && <>{
                                }<Error>Location or Test is not active , Please select other location</Error></>}
							<TestingInfoContainer className='white'>
								<TestingInfoRow>
									<TestingInfo>
										<CalendarIcon />
										<strong>Date</strong>
										<br />
										{format(selectedDate, config.weekDateFormat)}
									</TestingInfo>
									<TestingInfo>
										<ClockIcon />
										<strong>Time</strong>
										<br />
										{slot !== undefined && slot !== null ? slot.label : ''}
									</TestingInfo>
									{location?.cPartnerID === 'LLT001' && (
										<TestingInfo>
											<strong>Cadence</strong>
											<br />
											Weekly
										</TestingInfo>
									)}
								</TestingInfoRow>
								{location?.partnerName !== 'goHealth' && <TestingInfoBtn
									hidden={
										(isWalkUpTest && slot !== undefined) ||
											location?.cPartnerID === 'LLT001'
											? true
											: false
									}
									type="button"
									disabled={loading}
									onClick={() => setShowChangeDatetimeModal(true)}
									size="sm"
									libraryType="default"
									className='btn elevated-btn'
								>
									Change Date & Time
								</TestingInfoBtn>}
							</TestingInfoContainer>
							{(slot === null || slot ===undefined)  && <Error>Please select slot time</Error>}
							{/*<EditableInfosWrapper>*/}
							{/*  <EditableInfo label="Name" name="name" value={name} />*/}
							{/*  <EditableInfo label="Phone" name="phone" value={phone} />*/}
							{/*</EditableInfosWrapper>*/}
							{/* <TestingInfoContainer>
                                <TestingInfoRow>
                                <TestingInfo>
                                    <PersonIcon />
                                    <strong>Appointment for</strong>
                                    <br />
                                    {form?.firstName} {form?.lastName}
                                </TestingInfo>
                                </TestingInfoRow>
                                <TestingInfoBtn
                                type="button"
                                onClick={() => setShowChangeApptAttendeeModal(true)}
                                size="sm"
                                libraryType="default"
                                >
                                Change Appointment Attendees
                                </TestingInfoBtn>
                            </TestingInfoContainer> */}
							{
                            //  !rebooking.active && testSelection !== 'school' &&
							// 	(!hasInsurance || !form.hasInsurance) &&
							// 	!(testSelection === 'kern') && (
							// 		<>
							// 			<TestingInfoContainer>
							// 				<TestingInfoRow>
							// 					<TestingInfo className='white'>
							// 						{/* <PersonIcon /> */}
							// 						<strong>Test Type</strong>
							// 						<br />
							// 						{form?.registeringFor === 'myselfAndMinor'
							// 							? isExpressSameDayTest
							// 								? `Express PCR Test`
							// 								: isRapidTest
							// 									? `Rapid PCR Test`
							// 									: isAntigen
							// 										? `Antigen Test`
							// 										: isPanel
                          	// 				? 'Flu A&B/Covid-19' 
							// 												: `Standard PCR Test`
							// 							: isExpressSameDayTest
							// 								? `Express PCR Test`
							// 								: isRapidTest
							// 									? `Rapid PCR Test`
							// 									: isAntigen
							// 										? `Antigen Test ${payingMethod !== 'No Insurance'
							// 											? `- ${amount}`
							// 											: ''
							// 										}`
							// 										:
							// 										isPanel
                          	// 				? 'Flu A&B/Covid-19'   
							// 										: `Standard PCR Test`}
							// 						{/* {location.qbenchCustomerId ===
							// 							config.qbenchCustomerId.PCMADestination && (
							// 								<>
							// 									<br />
							// 									{isExpressSameDayTest
							// 										? `Results within 12 hours`
							// 										: isRapidTest
							// 											? `Results within 2 hours`
							// 											: (isAntigen || isPanel)
							// 												? `Results within 30 minutes`
							// 												: `Results within 24 hours`}
							// 								</>
							// 							)} */}
							// 					</TestingInfo>
							// 				</TestingInfoRow>
							// 			</TestingInfoContainer>
							// 			{location?.partnerName !== 'goHealth' &&
							// 			<TestingInfoContainer style={{ background: 'white' }}>
							// 				<TestingInfoRow
							// 					style={{ width: '100%', textAlign: 'center' }}
							// 				>
							// 					<TestingInfo style={{ flex: 1, color: '#000' }}>
							// 						{/* <PersonIcon /> */}
							// 						<strong>Please select your testing option</strong>
							// 						<br />
							// 					</TestingInfo>
							// 					{payingMethod === 'Credit card' &&

							// 						testSelection !== 'community' && (
							// 							<TestTypeBox
							// 								testType={isRapidTest}
							// 								location={location}
							// 								values={values}
							// 								locationTestType={
							// 									!location?.rapidTest || isEmpEmail
							// 								}
							// 								title={'RAPID PCR TEST'}
							// 								testDuration={'Results within 2 hours'}
							// 								isCostLabelVisibile={
							// 									appointment.testSelection === 'sfoairport' &&
							// 										appointment.hasInsurance === true
							// 										? false
							// 										: true
							// 								}
							// 							/>
							// 						)}
							// 					{location?.qbenchCustomerId !==
							// 						config.qbenchCustomerId.SFOAirport_BARTStation &&
							// 						// location?.qbenchCustomerId !==
							// 						// config.qbenchCustomerId.LasVegasSpringValley && 
							// 						location.qbenchCustomerId !==
							// 						config.qbenchCustomerId.PCMADestination &&
							// 						// location?.qbenchCustomerId !==config.qbenchCustomerId.Brooklyn &&
							// 						(
							// 							<>
							// 								<TestTypeBox
							// 									testType={isExpressSameDayTest}
							// 									location={location}
							// 									values={values}
							// 									locationTestType={!location?.hasVipSlots}
							// 									title={'EXPRESS PCR TEST'}
							// 									testDuration={'Results within 12 hours'}
							// 									isCostLabelVisibile={
							// 										appointment.testSelection === 'sfoairport' &&
							// 											appointment.hasInsurance === true
							// 											? false
							// 											: true
							// 									}
							// 								/>
							// 								<TestTypeBox
							// 									testType={
							// 										location?.qbenchCustomerId ===
							// 											config.qbenchCustomerId.SFOAirport_BARTStation
							// 											? false
							// 											: standradTest
							// 									}
							// 									location={location}
							// 									values={values}
							// 									locationTestType={
							// 										location?.qbenchCustomerId ===
							// 											config.qbenchCustomerId.SFOAirport_BARTStation
							// 											? true
							// 											: standradTest
							// 									}
							// 									title={'STANDARD PCR TEST'}
							// 									testDuration={
							// 										includes(
							// 											config.locationsfor36Hrs,
							// 											location?.qbenchCustomerId
							// 										)
							// 											? 'Results within 36 hours'
							// 											: includes(
							// 												config.locationsfor48Hrs,
							// 												location?.qbenchCustomerId
							// 											)
							// 												? 'Results within 48 hours'
							// 												: 'Results within 24 hours'
							// 									}
							// 									isCostLabelVisibile={
							// 										appointment.testSelection === 'sfoairport' &&
							// 											appointment.hasInsurance === true
							// 											? false
							// 											: true
							// 									}
							// 								/>
							// 							</>
							// 						)}
							// 					{location?.qbenchCustomerId === config.qbenchCustomerId.SFOAirport_BARTStation
							// 						&& testSelection === 'sfoairport' &&
							// 						<TestTypeBox testType={standradTest}
							// 							location={location} values={values}
							// 							locationTestType={standradTest} title={'STANDARD PCR TEST'}
							// 							testDuration={includes(config.locationsfor36Hrs, location?.qbenchCustomerId) ?
							// 								'Results within 36 hours' : includes(config.locationsfor48Hrs, location?.qbenchCustomerId)
							// 									? 'Results within 48 hours' : 'Results within 24 hours'}
							// 							isCostLabelVisibile={(appointment.testSelection === 'sfoairport'
							// 								&& appointment.hasInsurance === true) ? false : true} />}
							// 					{destination?.value !== 'JPN' &&
							// 						!!location?.isAntigen && (
							// 							<TestTypeBox
							// 								testType={isAntigen}
							// 								location={location}
							// 								values={values}
							// 								locationTestType={
							// 									!location?.isAntigen || isEmpEmail
							// 								}
							// 								title={'ANTIGEN TEST'}
							// 								testDuration={'Results within 30 minutes'}
							// 								isCostLabelVisibile={
							// 									appointment.testSelection === 'sfoairport' &&
							// 										appointment.hasInsurance === true
							// 										? false
							// 										: true
							// 								}
							// 							/>
							// 						)}
							// 				</TestingInfoRow>
							// 			</TestingInfoContainer>}
							// 		</>
							// 	)
                                }
							{
                            //  !rebooking.active && testSelection === 'community' &&
							// 	form.hasInsurance &&
							// 	includes(
							// 		[
							// 			config.qbenchCustomerId.SouthLosAngeles,
							// 			// config.qbenchCustomerId.Brooklyn,
							// 			config.qbenchCustomerId.BoysGirlsClub,
							// 		],
							// 		form.location?.qbenchCustomerId
							// 	) && (
							// 		<>
							// 			<TestingInfoContainer>
							// 				<TestingInfoRow>
							// 					<TestingInfo className='white'>
							// 						{/* <PersonIcon /> */}
							// 						<strong>Test Type</strong>
							// 						<br />
							// 						{form?.registeringFor === 'myselfAndMinor'
							// 							? isExpressSameDayTest
							// 								? `Express PCR Test`
							// 								: isRapidTest
							// 									? `Rapid PCR Test`
							// 									: isAntigen
							// 										? `Antigen Test`
							// 										: isPanel
                          	// 			? 'Flu A&B/Covid-19' 
							// 											: `Standard PCR Test`
							// 							: isExpressSameDayTest
							// 								? `Express PCR Test`
							// 								: isRapidTest
							// 									? `Rapid PCR Test`
							// 									: isAntigen
							// 										? `Antigen Test`
							// 										: isPanel
                          	// 				? 'Flu A&B/Covid-19' 
							// 											: `Standard PCR Test`}
							// 					</TestingInfo>
							// 				</TestingInfoRow>
							// 			</TestingInfoContainer>
							// 			{location?.partnerName !== 'goHealth' &&
							// 			<TestingInfoContainer style={{ background: 'white' }}>
							// 				<TestingInfoRow
							// 					style={{
							// 						width: '75%',
							// 						textAlign: 'center',
							// 					}}
							// 				>
							// 					<TestingInfo style={{ flex: 1, color: '#000' }}>
							// 						{/* <PersonIcon /> */}
							// 						<strong>Please select your testing option</strong>
							// 						<br />
							// 					</TestingInfo>
							// 					{
							// 						//    ( location?.qbenchCustomerId !==config.qbenchCustomerId.Brooklyn) && 
							// 						<TestTypeBox
							// 							testType={standradTest}
							// 							location={location}
							// 							values={values}
							// 							locationTestType={standradTest}
							// 							title={'STANDARD PCR TEST'}
							// 							testDuration={
							// 								includes(
							// 									config.locationsfor36Hrs,
							// 									location?.qbenchCustomerId
							// 								)
							// 									? 'Results within 36 hours'
							// 									: includes(
							// 										config.locationsfor48Hrs,
							// 										location?.qbenchCustomerId
							// 									)
							// 										? 'Results within 48 hours'
							// 										: 'Results within 24 hours'
							// 							}
							// 							isCostLabelVisibile={false}
							// 						/>}
							// 					{!!location?.isAntigen && (
							// 						<TestTypeBox
							// 							testType={isAntigen}
							// 							location={location}
							// 							values={values}
							// 							locationTestType={!location?.isAntigen}
							// 							title={'ANTIGEN TEST'}
							// 							testDuration={'Results within 30 minutes'}
							// 							isCostLabelVisibile={false}
							// 						/>
							// 					)}
							// 				</TestingInfoRow>
							// 			</TestingInfoContainer>}
							// 		</>
							// 	)
                                }
                                 { location?.partnerName === 'goHealth' && (
                                <>
                                 <TestingInfoContainer>
                                            <TestingInfoRow>
                                                <TestingInfo className='white'>
                                                    {/* <PersonIcon /> */}
                                                    <strong>Test type</strong>
                                                    <br />
                                                    {form?.registeringFor ===
                                                        'myselfAndMinor'
                                                        ? isExpressSameDayTest
                                                            ? `Express PCR Test`
                                                            : isRapidTest
                                                                ? `Rapid PCR Test`
                                                                : isAntigen
                                                                    ? `Antigen Test`
                                                                    : isPanel
                                                                       ? `Flu A&B/Covid-19`  
                                                                      : `Standard PCR Test`
                                                        : isExpressSameDayTest
                                                            ? `Express PCR Test`
                                                            : isRapidTest
                                                                ? `Rapid PCR Test`
                                                                : isAntigen
                                                                    ? `Antigen Test`
                                                                    : isPanel
                                                                       ? `Flu A&B/Covid-19`                                                                    
                                                                       : `Standard PCR Test`}
                                                        <br />
                                                        </TestingInfo>
                                            </TestingInfoRow>
                                        </TestingInfoContainer>
                                        </>)}
							{
                            // !rebooking.active && cPartnerID === 'SCS001' && (
							// 	<>
							// 		<TestingInfoContainer>
							// 			<TestingInfoRow>
							// 				<TestingInfo className='white'>
							// 					{/* <PersonIcon /> */}
							// 					<strong>Test Type</strong>
							// 					<br />
							// 					Antigen Test
							// 				</TestingInfo>
							// 			</TestingInfoRow>
							// 		</TestingInfoContainer>
							// 		{/* <TestingInfoContainer>
                            //             <TestingInfoRow
                            //                 style={{
                            //                     width: '100%',
                            //                     textAlign: 'center',
                            //                 }}
                            //             >
                            //             </TestingInfoRow>
                            //         </TestingInfoContainer> */}
							// 	</>
							// )
                            }
							{ 
                            // !rebooking.active && payingMethod === 'Insurance' &&
							// 	cPartnerID !== 'SCS001' &&
							// 	testSelection !== 'community' && location?.partnerName !== 'goHealth' && (
							// 		<TestingInfoContainer>
							// 			<TestingInfoRow>
							// 				<TestingInfo className='white'>
							// 					{/* <PersonIcon /> */}
							// 					<strong>Test Type</strong>
							// 					<br />
							// 					{form?.registeringFor === 'myselfAndMinor'
							// 						? isExpressSameDayTest
							// 							? `Express PCR Test`
							// 							: isRapidTest
							// 								? `Rapid PCR Test`
							// 								: isAntigen
							// 									? `Antigen Test`
							// 									: isPanel
                          	// 			? 'Flu A&B/Covid-19' 
							// 									: `Standard PCR Test`
							// 						: isExpressSameDayTest
							// 							? `Express PCR Test`
							// 							: isRapidTest
							// 								? `Rapid PCR Test`
							// 								: isAntigen
							// 									? `Antigen Test`
							// 										: isPanel
							// 										? `Flu A&B/Covid-19`
							// 									   : `Standard PCR Test`}
							// 					<br />
							// 					{isExpressSameDayTest
							// 						? `Results within 12 hours`
							// 						: isRapidTest
							// 							? ``
							// 							: isAntigen
							// 								? `Results within 30 minutes`
                            //                                 : isPanel
                            //                                 ? `Results within 30 minutes`
							// 								   : `Results within 24 hours`}
							// 				</TestingInfo>
							// 			</TestingInfoRow>
							// 		</TestingInfoContainer>
							// 	)
                                }
							{/* {(payingMethod === 'Insurance') && form.location?.qbenchCustomerId === config.qbenchCustomerId.SFO_Airport && (
                                <>
                                    <TestingInfoContainer>
                                        <TestingInfoRow
                                            style={{
                                                width: '75%',
                                                textAlign: 'center',
                                            }}
                                        >
                                            <TestingInfo
                                                style={{ flex: 1 }}
                                            >
                                                <PersonIcon /> 
                                                <strong>
                                                    Please select your
                                                    testing option
                                                </strong>
                                                <br />
                                            </TestingInfo>
                                            <TestTypeBox testType={standradTest} location={location} values={values} locationTestType={standradTest} title={'STANDARD TEST'} testDuration={includes(config.locationsfor36Hrs, location?.qbenchCustomerId) ? 'Results within 36 hours' : includes(config.locationsfor48Hrs, location?.qbenchCustomerId) ? 'Results within 48 hours' : 'Results within 24 hours'} isCostLabelVisibile={false} />
                                             <TestTypeBox testType={isExpressSameDayTest} location={location} values={values} locationTestType={!location?.hasVipSlots} title={'EXPRESS TEST'} testDuration={'Results within 12 hours'} isCostLabelVisibile={false} /> 
                                        </TestingInfoRow>
                                    </TestingInfoContainer>
                                </>
                            )}  */}

							{
                            // !rebooking.active && testSelection === 'community' &&
							// 	form.location?.qbenchCustomerId ===
							// 	config.qbenchCustomerId.Location57 && location?.partnerName !== 'goHealth' && (
							// 		<>
							// 			<TestingInfoContainer>
							// 				<TestingInfoRow>
							// 					<TestingInfo className='white'>
							// 						{/* <PersonIcon /> */}
							// 						<strong>Test Type</strong>
							// 						<br />
							// 						{form?.registeringFor === 'myselfAndMinor'
							// 							? isExpressSameDayTest
							// 								? `Express PCR Test`
							// 								: isRapidTest
							// 									? `Rapid PCR Test`
							// 									: isAntigen
							// 										? `Antigen Test`
							// 										: isPanel
                          	// 			? 'Flu A&B/Covid-19' 
							// 										: `Standard PCR Test`
							// 							: isExpressSameDayTest
							// 								? `Express PCR Test`
							// 								: isRapidTest
							// 									? `Rapid PCR Test`
							// 									: isAntigen
							// 										? `Antigen Test`
							// 										:
							// 										isPanel
                          	// 			? 'Flu A&B/Covid-19' 
							// 										: `Standard PCR Test`}
							// 					</TestingInfo>
							// 				</TestingInfoRow>
							// 			</TestingInfoContainer>
							// 			{location?.partnerName !== 'goHealth' &&
							// 			<TestingInfoContainer style={{ background: 'white' }}>
							// 				<TestingInfoRow
							// 					style={{ width: '100%', textAlign: 'center' }}
							// 				>
							// 					<TestingInfo style={{ flex: 1, color: '#000' }}>
							// 						{/* <PersonIcon /> */}
							// 						<strong className='black'>Please select your testing option</strong>
							// 						<br />
							// 					</TestingInfo>
							// 					<TestTypeBox
							// 						testType={standradTest}
							// 						location={location}
							// 						values={values}
							// 						locationTestType={standradTest}
							// 						title={'STANDARD PCR TEST'}
							// 						testDuration={
							// 							includes(
							// 								config.locationsfor36Hrs,
							// 								location?.qbenchCustomerId
							// 							)
							// 								? 'Results within 36 hours'
							// 								: includes(
							// 									config.locationsfor48Hrs,
							// 									location?.qbenchCustomerId
							// 								)
							// 									? 'Results within 48 hours'
							// 									: 'Results within 24 hours'
							// 						}
							// 						isCostLabelVisibile={false}
							// 					/>
							// 					{!!location?.isAntigen && (
							// 						<TestTypeBox
							// 							testType={isAntigen}
							// 							location={location}
							// 							values={values}
							// 							locationTestType={!location?.isAntigen}
							// 							title={'ANTIGEN TEST'}
							// 							testDuration={'Results within 30 minutes'}
							// 							isCostLabelVisibile={false}
							// 						/>
							// 					)}
							// 				</TestingInfoRow>
							// 			</TestingInfoContainer>}
							// 		</>
							// 	)
                                }
							{
                            // !rebooking.active && (testSelection === 'flight' ||
							// 	testSelection === 'general' ||
							// 	testSelection === 'school' ||
							// 	testSelection === 'sfoairport') &&
							// 	payingMethod === 'Insurance' && location?.partnerName !== 'goHealth' && (
							// 		<TestingInfoContainer style={{ background: 'white' }}>
							// 			<TestingInfoRow
							// 				style={{ width: '75%', textAlign: 'center' }}
							// 			>
							// 				<TestingInfo style={{ flex: 1, color: '#000' }}>
							// 					{/* <PersonIcon /> */}
							// 					<strong className='black'>Please select your testing option</strong>
							// 					<br />
							// 				</TestingInfo>
							// 				{
							// 					//   ( location?.qbenchCustomerId !==config.qbenchCustomerId.Brooklyn) && 
							// 					cPartnerID !== 'SCS001' && (location?.qbenchCustomerId !==
							// 						config.qbenchCustomerId.SFOAirport_BARTStation) &&  (

							// 						<TestTypeBox
							// 							testType={standradTest}
							// 							location={location}
							// 							values={values}
							// 							locationTestType={standradTest}
							// 							title={'STANDARD PCR TEST'}
							// 							testDuration={
							// 								includes(
							// 									config.locationsfor36Hrs,
							// 									location?.qbenchCustomerId
							// 								)
							// 									? 'Results within 36 hours'
							// 									: includes(
							// 										config.locationsfor48Hrs,
							// 										location?.qbenchCustomerId
							// 									)
							// 										? 'Results within 48 hours'
							// 										: 'Results within 24 hours'
							// 							}
							// 							isCostLabelVisibile={false}
							// 						/>
							// 					)}
							// 				{destination?.value !== 'JPN' &&
							// 					!!location?.isAntigen && (
							// 						<TestTypeBox
							// 							testType={isAntigen}
							// 							location={location}
							// 							values={values}
							// 							locationTestType={!location?.isAntigen}
							// 							title={'ANTIGEN TEST'}
							// 							testDuration={'Results within 30 minutes'}
							// 							isCostLabelVisibile={false}
							// 						/>
							// 					)}
							// 				{location?.qbenchCustomerId ===
							// 					config.qbenchCustomerId.SFOAirport_BARTStation && testSelection === 'sfoairport' && !rebooking.active &&
							// 					<TestTypeBox
							// 						testType={standradTest}
							// 						location={location}
							// 						values={values}
							// 						locationTestType={standradTest}
							// 						title={'STANDARD PCR TEST'}
							// 						testDuration={
							// 							includes(
							// 								config.locationsfor36Hrs,
							// 								location?.qbenchCustomerId
							// 							)
							// 								? 'Results within 36 hours'
							// 								: includes(
							// 									config.locationsfor48Hrs,
							// 									location?.qbenchCustomerId
							// 								)
							// 									? 'Results within 48 hours'
							// 									: 'Results within 24 hours'
							// 						}
							// 						isCostLabelVisibile={false}
							// 					/>
							// 				}

                            //               {(location?.isPanel === true) && (
                            //               <TestTypeBox
                            //                tooltip={location.panelTooltip} 
                            //                testType={isPanel} 
                            //                location={location}
                            //                 values={values}                                             
                            //                 locationTestType={!location?.isPanel} 
                            //                 title={'Flu A&B/Covid-19'}
                            //                  testDuration={'Results within 30 minutes'}
                            //                   isCostLabelVisibile={false} />
                            //                   )}
							// 			</TestingInfoRow>
							// 		</TestingInfoContainer>
							// 	)
                                }
							{ 
                            // !rebooking.active && location.qbenchCustomerId ===
							// 	config.qbenchCustomerId.PCMADestination &&
							// 	payingMethod === 'Insurance' && location?.partnerName !== 'goHealth'  &&  (
							// 		// <TestingInfoContainer style={{ background: '#FFFFFF' }}>
							// 		// 	<TestingInfoRow
							// 		// 		style={{ width: '75%', textAlign: 'center' }}
							// 		// 	>
							// 		// 		<TestingInfo style={{ flex: 1, color: '#000' }}>
							// 		// 			{/* <PersonIcon /> */}
							// 		// 			<strong className='black'>Please select your testing option</strong>
							// 		// 			<br />
							// 		// 		</TestingInfo>
							// 		// 		{/* {!!location?.isAntigen && (
							// 		// 			<TestTypeBox
							// 		// 				testType={isAntigen}
							// 		// 				location={location}
							// 		// 				values={values}
							// 		// 				locationTestType={!location?.isAntigen}
							// 		// 				title={'ANTIGEN TEST'}
							// 		// 				testDuration={'Results within 30 minutes'}
							// 		// 				isCostLabelVisibile={false}
							// 		// 			/>
							// 		// 		)} */}
							// 		// 	</TestingInfoRow>
							// 		// </TestingInfoContainer>
							// 	)
                                }
                             {/* creditcard */}
                             { !rebooking.active && (!(payingMethod === 'Insurance') && !(payingMethod === 'No Insurance') && (payingMethod === 'Credit card' || payingMethod === 'Credit Card') && location?.partnerName !== 'goHealth' &&(
                                    <>
                                        <TestingInfoContainer>
                                            <TestingInfoRow>
                                                <TestingInfo className='white'>
                                                    {/* <PersonIcon /> */}
                                                    <strong>Test type</strong>
                                                    <br />
                                                    {form?.registeringFor ===
                                                        'myselfAndMinor'
                                                        ? isExpressSameDayTest
                                                            ? `Express PCR Test`
                                                            : isRapidTest
                                                                ? `Rapid PCR Test`
                                                                : isAntigen
                                                                    ? `Antigen Test`
                                                                    : isPanel
                                                                       ? `Flu A&B/Covid-19`  
                                                                      : `Standard PCR Test`
                                                        : isExpressSameDayTest
                                                            ? `Express PCR Test`
                                                            : isRapidTest
                                                                ? `Rapid PCR Test`
                                                                : isAntigen
                                                                    ? `Antigen Test`
                                                                    : isPanel
                                                                       ? `Flu A&B/Covid-19`                                                                    
                                                                       : `Standard PCR Test`}
                                                        <br />
                                                {form.location?.qbenchCustomerId===261 && (isExpressSameDayTest
                                                        ? `Results within 12 hours`
                                                        : isRapidTest
                                                            ? `Results within 2 hours`
                                                            : isAntigen
                                                                ? `Results within 30 minutes`
                                                                : isPanel
                                                                  ? `Results within 30 minutes`
                                                                  : `Results within 24 hours`)
                                                    }
                                                </TestingInfo>
                                            </TestingInfoRow>
                                        </TestingInfoContainer>
                                        {location?.partnerName !== 'goHealth' &&
                                        <TestingInfoContainer style={{background:'white'}}>
                                            <TestingInfoRow
                                                style={{
                                                    width: noOfTestsCred === 4 ? '100%': noOfTestsCred === 3 ? '90%': noOfTestsCred === 2 ? '70%': '45%',
                                                    textAlign: 'center',
                                                }}
                                            >
                                                <TestingInfo
                                                    style={{ flex: 1 ,color: 'black'}}
                                                >
                                                    <strong className='black'>
                                                        Please select your
                                                        testing option
                                                    </strong>
                                                    <br />
                                                </TestingInfo>
                                                    {!!location?.rapidTest && !!location?.isCreditCard.rapid && <TestTypeBox tooltip={location.rapidTooltip} testType={isRapidTest} location={location} values={values} locationTestType={!location?.rapidTest || (isEmpEmail && testSelection === 'general' && (payingMethod === 'Credit card' || payingMethod === 'Credit Card'))} title={'RAPID PCR TEST'} testDuration={'Results within 2 hours'} isCostLabelVisibile={true} />}
                                                    {!!location?.isExpressSameDay && !!location?.isCreditCard.express &&<TestTypeBox tooltip={location.expressTooltip} testType={isExpressSameDayTest} location={location} values={values} locationTestType={!location?.isExpressSameDay || (isEmpEmail && (payingMethod === 'Credit card' || payingMethod === 'Credit Card'))} title={'EXPRESS PCR TEST'} testDuration={'Results within 12 hours'} isCostLabelVisibile={true} />}
                                                    { !!location?.isCreditCard.standard && <TestTypeBox tooltip={location.standardTooltip} testType={standradTest} location={location} values={values} locationTestType={!location?.standardTest  || (isEmpEmail && (payingMethod === 'Credit card' || payingMethod === 'Credit Card'))} title={'STANDARD PCR TEST'} testDuration={includes(config.locationsfor36Hrs, location?.qbenchCustomerId) ? 'Results within 36 hours' : includes(config.locationsfor48Hrs, location?.qbenchCustomerId) ? 'Results within 48 hours' : 'Results within 24 hours'} isCostLabelVisibile={true} />}
                                                    {(destination?.value!=='JPN') && !!location?.isAntigen && !!location?.isCreditCard.antigen && <TestTypeBox tooltip={location.antigenTooltip} testType={isAntigen} location={location} values={values} locationTestType={!location?.isAntigen || (isEmpEmail && testSelection === 'general' && (payingMethod === 'Credit card' || payingMethod === 'Credit Card'))} title={'ANTIGEN TEST'} testDuration={'Results within 30 minutes'} isCostLabelVisibile={true} />}
                                            </TestingInfoRow>
                                        </TestingInfoContainer>}
                                    </>
                                ))}
                               {/* insurance not bart */}
                                {!rebooking.active && ((payingMethod === 'Insurance' && hasInsurance === true) && location?.partnerName !== 'goHealth' &&(
                                    <>
                                     <TestingInfoContainer>
                                            <TestingInfoRow>
                                                <TestingInfo className='white'>
                                                    <strong>Test type</strong>
                                                    <br />
                                                    {form?.registeringFor ===
                                                        'myselfAndMinor'
                                                        ? isExpressSameDayTest
                                                            ? `Express PCR Test`
                                                            : isRapidTest
                                                                ? `Rapid PCR Test`
                                                                : isAntigen
                                                                    ? `Antigen Test`
                                                                    : isPanel
                                                                      ? `Flu A&B/Covid-19`
                                                                      : `Standard PCR Test`
                                                        : isExpressSameDayTest
                                                            ? `Express PCR Test`
                                                            : isRapidTest
                                                                ? `Rapid PCR Test`
                                                                : isAntigen
                                                                    ? `Antigen Test`
                                                                    : isPanel
                                                                      ? `Flu A&B/Covid-19`
                                                                     : `Standard PCR Test`}
                                                        <br />
                                                {form.location?.qbenchCustomerId==261 && (isExpressSameDayTest
                                                        ? `Results within 12 hours`
                                                        : isRapidTest
                                                            ? `Results within 2 hours`
                                                            : isAntigen
                                                                ? `Results within 30 minutes`
                                                                : isPanel
                                                                ? `Results within 30 minutes`
                                                                : `Results within 24 hours`)
                                                    }
                                                </TestingInfo>
                                            </TestingInfoRow>
                                        </TestingInfoContainer>
                                        {location?.partnerName !== 'goHealth'&&
                                        <TestingInfoContainer style={{background:'white'}}>
                                            <TestingInfoRow
                                                style={{
                                                    width: noOfTestsInsu === 4 ? '100%': noOfTestsInsu === 3 ? '90%': noOfTestsInsu === 2 ? '70%': '45%',
                                                    textAlign: 'center',
                                                }}
                                            >
                                                <TestingInfo
                                                    style={{ flex: 1,color: 'black' }}
                                                >
                                                    <strong className='black'>
                                                        Please select your
                                                        testing option2
                                                    </strong>
                                                    <br />
                                                </TestingInfo>
                                                {!!location?.rapidTest && !!location?.isInsurance.rapid  && <TestTypeBox tooltip={location.rapidTooltip} testType={isRapidTest} location={location} values={values} locationTestType={!location?.rapidTest} title={'RAPID PCR TEST'} testDuration={'Results within 2 hours'} isCostLabelVisibile={false} />}
                                                {!!location?.isExpressSameDay && !!location?.isInsurance.express &&<TestTypeBox tooltip={location.expressTooltip} testType={isExpressSameDayTest} location={location} values={values} locationTestType={!location?.isExpressSameDay} title={'EXPRESS PCR TEST'} testDuration={'Results within 12 hours'} isCostLabelVisibile={false} />}
                                                { !!location?.isInsurance.standard && <TestTypeBox tooltip={location.standardTooltip} testType={standradTest} location={location} values={values} locationTestType={!location?.standardTest} title={'STANDARD PCR TEST'} testDuration={includes(config.locationsfor36Hrs, location?.qbenchCustomerId) ? 'Results within 36 hours' : includes(config.locationsfor48Hrs, location?.qbenchCustomerId) ? 'Results within 48 hours' : 'Results within 24 hours'} isCostLabelVisibile={false}/>}
                                                {(destination?.value!=='JPN') && !!location?.isAntigen && !!location?.isInsurance.antigen  && <TestTypeBox tooltip={location.antigenTooltip} testType={isAntigen} location={location} values={values} locationTestType={!location?.isAntigen} title={'ANTIGEN TEST'} testDuration={'Results within 30 minutes'} isCostLabelVisibile={false} />}
                                                {(location?.isPanel === true) && (<TestTypeBox tooltip={location.panelTooltip} testType={isPanel} location={location} values={values} locationTestType={!location?.isPanel} title={'Flu A&B/Covid-19'} testDuration={'Results within 30 minutes'} isCostLabelVisibile={false} />)}
                                            </TestingInfoRow>
                                        </TestingInfoContainer>}
                                    </>
                                ))}
                                {/* noinsurance  */}
                                { !rebooking.active && payingMethod === 'No Insurance'&& location?.partnerName !== 'goHealth' && (
                                    <>
                                     <TestingInfoContainer>
                                            <TestingInfoRow>
                                                <TestingInfo className='white'>
                                                    <strong>Test type</strong>
                                                    <br />
                                                    {form?.registeringFor ===
                                                        'myselfAndMinor'
                                                        ? isExpressSameDayTest
                                                            ? `Express PCR Test`
                                                            : isRapidTest
                                                                ? `Rapid PCR Test`
                                                                : isAntigen
                                                                    ? `Antigen Test`
                                                                    : `Standard PCR Test`
                                                        : isExpressSameDayTest
                                                            ? `Express PCR Test`
                                                            : isRapidTest
                                                                ? `Rapid PCR Test`
                                                                : isAntigen
                                                                    ? `Antigen Test`
                                                                    : `Standard PCR Test`}
                                                        <br />
                                                {form.location?.qbenchCustomerId==261 && (isExpressSameDayTest
                                                        ? `Results within 12 hours`
                                                        : isRapidTest
                                                            ? `Results within 2 hours`
                                                            : isAntigen
                                                                ? `Results within 30 minutes`
                                                                : `Results within 24 hours`)
                                                    }
                                                </TestingInfo>
                                            </TestingInfoRow>
                                        </TestingInfoContainer>
                                        {location?.partnerName !== 'goHealth'&&
                                        <TestingInfoContainer style={{background:'white'}}>
                                            <TestingInfoRow
                                               style={{
                                                width: noOfTestsNoInsu === 4 ? '100%': noOfTestsNoInsu === 3 ? '90%': noOfTestsNoInsu === 2 ? '70%': '45%',
                                                textAlign: 'center',
                                            }}
                                            >
                                                <TestingInfo 
                                                    style={{ flex: 1,color: 'black' }}
                                                >
                                                    <strong className='black'>
                                                        Please select your
                                                        testing option3
                                                    </strong>
                                                    <br />
                                                </TestingInfo>
                                                {!!location?.rapidTest && !!location?.isNoInsurance.rapid && <TestTypeBox tooltip={location.rapidTooltip} testType={isRapidTest} location={location} values={values} locationTestType={!location?.rapidTest} title={'RAPID PCR TEST'} testDuration={'Results within 2 hours'} isCostLabelVisibile={false} />}
                                                {!!location?.isExpressSameDay && !!location?.isNoInsurance.express &&<TestTypeBox tooltip={location.expressTooltip} testType={isExpressSameDayTest} location={location} values={values} locationTestType={!location?.isExpressSameDay} title={'EXPRESS PCR TEST'} testDuration={'Results within 12 hours'} isCostLabelVisibile={false} />}
                                                {!!location?.standardTest && !!location?.isNoInsurance.standard && <TestTypeBox testType={standradTest} locationTestType={!location?.standardTest} location={location} values={values} title={'STANDARD PCR TEST'} testDuration={includes(config.locationsfor36Hrs, location?.qbenchCustomerId) ? 'Results within 36 hours' : includes(config.locationsfor48Hrs, location?.qbenchCustomerId) ? 'Results within 48 hours' : 'Results within 24 hours'} isCostLabelVisibile={false}/>}
                                                {(destination?.value!=='JPN') && !!location?.isAntigen && !!location?.isNoInsurance.antigen && <TestTypeBox tooltip={location.antigenTooltip} testType={isAntigen} location={location} values={values} locationTestType={!location?.isAntigen} title={'ANTIGEN TEST'} testDuration={'Results within 30 minutes'} isCostLabelVisibile={false} />}
                                            </TestingInfoRow>
                                        </TestingInfoContainer>}
                                    </>
                                )}    
							{/* No payment  */}
							{(form.location.qbenchCustomerId ===
								config.qbenchCustomerId.KernHealthSystems ||
								payingMethod === 'No payment' ||
								testSelection === 'kern') && location?.partnerName !== 'goHealth' && (
									<>
										<TestingInfoContainer>
											<TestingInfoRow>
												<TestingInfo>
													<strong>Test Type</strong>
													<br />
													{form?.registeringFor === 'myselfAndMinor'
														? isExpressSameDayTest
															? `Express PCR Test`
															: isRapidTest
																? `Rapid PCR Test`
																: isAntigen
																	? `Antigen Test`
																	: isPanel
                          				? 'Flu A&B/Covid-19'  
																	: `Standard PCR Test`
														: isExpressSameDayTest
															? `Express PCR Test`
															: isRapidTest
																? `Rapid PCR Test`
																: isAntigen
																	? `Antigen Test`
																	:
																	isPanel
                          				? 'Flu A&B/Covid-19'
																	: `Standard PCR Test`}
													<br />
													{form.location?.qbenchCustomerId ===
														config.qbenchCustomerId.KernHealthSystems &&
														(isExpressSameDayTest
															? `Results within 12 hours`
															: isRapidTest
																? `Results within 2 hours`
																: (isAntigen || isPanel)
																	? `Results within 30 minutes`
																	: `Results within 24 hours`)}
												</TestingInfo>
											</TestingInfoRow>
										</TestingInfoContainer>
									</>
								)}

								{ rebooking.active && (
									<TestingInfoContainer>
									<TestingInfoRow>
										<TestingInfo className='white'>
											{/* <PersonIcon /> */}
											<strong>Test Type</strong>
											<br />
											{form?.registeringFor === 'myselfAndMinor'
												? isExpressSameDayTest
													? `Express PCR Test`
													: isRapidTest
														? `Rapid PCR Test`
														: isAntigen
															? `Antigen Test`
															: isPanel
								  ? 'Flu A&B/Covid-19' 
															: `Standard PCR Test`
												: isExpressSameDayTest
													? `Express PCR Test`
													: isRapidTest
														? `Rapid PCR Test`
														: isAntigen
															? `Antigen Test`
															:
															isPanel
								  ? 'Flu A&B/Covid-19' 
															: `Standard PCR Test`}
										</TestingInfo>
									</TestingInfoRow>
								</TestingInfoContainer>
								)}
							<Subcontent style={{pointerEvents:rebooking.active ? 'none' :'auto'}}>
								<Cardtitle onClick={() => {
									 if(location?.partnerName !== 'goHealth'){
										handleNameOpen()
									 }
								}}>
									<EditIcon>
										{/* <PencilLogo /> */}
										{location?.partnerName !== 'goHealth' && !rebooking.active && <EditOutlined />}
									</EditIcon>

									<TitleKey>Name</TitleKey>
									<TitleValue>
										{form?.firstName} {form?.middleName} {form?.lastName}
									</TitleValue>
									{/* {form?.guardian?.firstName && <TitleValue>

                    {form?.guardian?.firstName} {form?.guardian?.lastName}
                  </TitleValue>} */}
								</Cardtitle>
								{/* Popup for Change Name */}
								{/* <Dialog
                    fullScreen={fullScreen}
                    open={openNameDialog}
                    onClose={handleNameClose}
                  > */}
								<ChangeNameModal
									open={openNameDialog}
									handleNameClose={handleNameClose}
									isProfile={false}
									setErrormsg={handleSetError}
								/>
								{/* </Dialog> */}

								<Cardtitle onClick={() => {
									if(location?.partnerName !== 'goHealth'){
                                        handleDobOpen()
                                    }
									}}>
									{/* <div style={{ width: '75px' }}> */}
									{/* <PencilLogo /> */}
									<EditIcon>
										{location?.partnerName !== 'goHealth' && !rebooking.active && <EditOutlined />}
									</EditIcon>
									{/* </div> */}

									<TitleKey>Date of Birth</TitleKey>
									<TitleValue>{form?.birthDate}</TitleValue>
								</Cardtitle>
								{/* <Dialog
                                        fullScreen={fullScreen}
                                        open={openDobDialog}
                                        onClose={handleDobClose}
                                    > */}
								<ChangeDobModal
									open={openDobDialog}
									handleDobClose={handleDobClose}
									isProfile={false}
									setErrormsg={handleSetError}
								/>
								{/* </Dialog> */}

								<Cardtitle onClick={() => {
									 if(location?.partnerName !== 'goHealth'){
                                        handlePhoneOpen()
                                    }
								}}>
									{/* <div style={{ width: '75px' }}> */}
									{/* <PencilLogo /> */}
									<EditIcon>
										{location?.partnerName !== 'goHealth' && !rebooking.active && <EditOutlined />}
									</EditIcon>
									{/* </div> */}
									<TitleKey>Phone Number</TitleKey>
									<TitleValue>{form?.phone}</TitleValue>
								</Cardtitle>
								{/* Popup for Change Phone */}
								{/* <Dialog
                                        fullScreen={fullScreen}
                                        open={openPhoneDialog}
                                        onClose={handlePhoneClose}
                                    > */}
								<ChangePhoneModal
									open={openPhoneDialog}
									handlePhoneClose={handlePhoneClose}
									isProfile={false}
								/>
								{/* </Dialog> */}
								{location?.partnerName !== 'goHealth' && (
								<Cardtitle onClick={handleAddressOpen}>
									<EditIcon>
										{/* <PencilLogo /> */}
										{location?.partnerName !== 'goHealth' && !rebooking.active && <EditOutlined />}
									</EditIcon>
									<TitleKey>
										Home Address
									</TitleKey>
									<TitleValue>
										<strong>{form?.address?.address}</strong>
										<br />
										{form?.address?.city}, {form?.address?.state} ,
										{form?.address?.country} ,{form?.address?.zipCode}
										<br />
									</TitleValue>
								</Cardtitle>)}

								<ChangeAddressModal
									open={openAddressDialog}
									handleAddressClose={handleAddressClose}
									isProfile={false}
								/>
								{tenant?.toLowerCase() === 'kern' && <>
									<Cardtitle onClick={handleEmployeeIdOpen}>
										{/* <div style={{ width: '75px' }}>
                            <PencilLogo />
                        </div> */}
										<EditIcon>
											<EditOutlined />
										</EditIcon>
										<TitleKey>Employee ID</TitleKey>
										<TitleValue>{form?.employeeId}</TitleValue>
									</Cardtitle>
									<ChangeEmployeeIdModal
										open={openEmployeeIDdialog}
										handleEmployeeIdClose={handleEmployeeIdClose}
										isProfile={false}
									/>
								</>}
								{/* </Dialog> */}
							</Subcontent>
							{location?.partnerName !== 'goHealth' && registeringFor !== 'myself' && minors.length > 0 && (
								<PageSubTitle>
									<b>Minors</b>
								</PageSubTitle>
							)}
							{location?.partnerName !== 'goHealth' && registeringFor !== 'myself' && minors.length > 0 && (
								<>
									{minors.map(
										(
											{ firstName, lastName, middleName, birthDate }: any,
											i: any
										) => (
											<WrapContainer key={i} style={{pointerEvents:rebooking.active ? 'none' :'auto'}}>
												<WrapContent>
													<Flexbox>
														<FlexboxIcon>
															<PersonIcon />
														</FlexboxIcon>
														<FlexboxText>
															<b>{'Name : '}</b>
															{firstName} {middleName} {lastName}
														</FlexboxText>
														<Vl></Vl>
													</Flexbox>
													<Flexbox>
														<FlexboxIcon>
															<CalendarIcon />
														</FlexboxIcon>
														<FlexboxText>
															<b>{'Date of Birth : '}</b>
															{birthDate}
														</FlexboxText>
													</Flexbox>
													<Flexbox>
														<Button
															type="button"
															onClick={() => {
																handleMinorOpen();
																setEditKey({
																	key: editKey.key === i ? -1 : i,
																});
															}}
															size="sm"
															libraryType="default"
															style={{ border: 'none', boxShadow: 'none', textDecorationLine: 'underline' }}
														>
															Edit
														</Button>
														{location?.partnerName !== 'goHealth' && editKey.key === i && (
															<ChangeMinorModal
																isConfirmBooking={true}
																isRegister={false}
																close={handleMinorClose}
																open={openMinorDialog}
																index={i}
																isProfile={true}
															/>
														)}
													</Flexbox>
												</WrapContent>
											</WrapContainer>
										)
									)}
								</>
							)}
							{error && <Error>{error}</Error>}

							{testSelection !== 'school' &&
								(
									testSelection === 'flight' ||
									testSelection === 'community' || 
									testSelection === 'sfoairport' || 
									(testSelection === 'general' && !freeCode)
								) &&  !rebooking.active &&
								!hasInsurance && location?.partnerName !== 'goHealth' && (
									<CouponContainer>
										<FormLabel label="Coupon Code">
											<Inputs
												name="promoCode"
												placeholder="Enter The Coupon Code"
												onChange={(value) => {
													updateFormValues({ promoCode: value });
												}}
												value={promoCode}
											// validate={(value) => {
											//   if (!value) {
											//     return 'Required field';
											//   }
											// }}
											/>
										</FormLabel>
										<FormLabel label="">
											<Button
												libraryType="primary"
												type="button"
												onClick={() => promoCodeValidation('')}
												className='btn fill-btn'
											>
												{btnloading ? 'Processing...' : 'Apply'}
											</Button>
										</FormLabel>
									</CouponContainer>
								)}
							{testSelection === 'general' && freeCode && !hasInsurance && (
								<CouponContainer>
									<FormLabel label="Coupon Code">
										<Inputs
											disabled
											name="promoCode"
											defaultValue={employeePromocode?.toString()}
											value={employeePromocode?.toString()}
											 // defaultValue='EMPLOYEE'
                                                // value='EMPLOYEE'
										//  onChange={(value) => {
										//     updateFormValues({
										//         promoCode: value,
										//     });
										// setPromoCode(value)
										// }}
										/>
									</FormLabel>
									<FormLabel label="">
										<Button
											disabled
											libraryType="primary"
											type="button"
											onClick={() => promoCodeValidation('')}
											className='btn fill-btn'
										>
											{btnloading ? 'Processing...' : 'Apply'}
										</Button>
									</FormLabel>
								</CouponContainer>
							)}
							{message && (
								<Error style={{ color: discountAmount > 0 ? 'green' : isAmountReduce ? 'green': 'red' }}>
									{message}
								</Error>
							)}

							{location?.partnerName !== 'goHealth' && <NavigationButtons
								confirmBtnText={
									rebooking.active || (payingMethod !== 'Credit card' &&
										(testSelection === 'school' ||
											testSelection === 'community')) ||
										(!!isEmpEmail &&
											testSelection === 'general' &&
											(payingMethod === 'Credit Card' ||
												payingMethod === 'Credit card')) ||
										(cPartnerID === 'KEN001' && testSelection === 'kern') ? (
										//  'Confirm1'
										<> {loading ? 'Processing...' : 'Confirm'}</>
									) :  !npData ? (payingMethod === "Credit card" || payingMethod === "Credit Card") && form?.promoCode !== ''
									&& form?.amount !== form?.discountAmount || (employeePromocode?.includes(form?.promoCode) && form?.minors?.length)
									? 'Confirm & Pay' :
									includes(allPromocodes, form?.promoCode) ?
										'Confirm' : 'Confirm & Pay' : fulloffCoupon?.includes(form?.promoCode) ? 'Confirm' : 'Confirm & Pay'
								}
								isValid={slot !== null && slot !== undefined}
								loading={loading}
								onReturnButtonClick={() => {
									goToPrevStep();
								}}
								isdisable={rebooking.active}
								disable={promoCode.length > 0 ? isPromoCodeValid:false}
								isPromo = {promoCode.length && apply === false}
							/>}
						</Form>
					)}
				</Formik>
				{location?.partnerName === 'goHealth' && <PageHeader>
                    <PageTitle>What is a Partner Site?</PageTitle>
                    <PageSubTitle>
                        {/* A partnerered site is an affiliated health lab that will provide the test and services in their location. */}
                        A Partner Site is an affiliate of Worksite Labs that will provide sample collection services so we can process your test.
                    </PageSubTitle>
                </PageHeader>}
			</Content>
			<ChangeLocationModal openModal={() => setShowChangeDatetimeModal(true)}  payment={payingMethod} />
			<ChangeDateTimeModal
				showModal={showChangeDatetimeModal}
				closeModal={() => setShowChangeDatetimeModal(false)}
			/>
			<PaymentModal
				showModal={showPaymentModal}
				closeModal={() => setShowPaymentModal(false)}
			/>
			<ChangeApptAttendeeModal
				showModal={showChangeApptAttendeeModal}
				closeModal={() => setShowChangeApptAttendeeModal(false)}
			/>
		</Container>
	);
};

export default ConfirmBooking;
