import React from 'react';
import { Formik, Form } from 'formik';

import { Container, Content, PageHeader, PageTitle, SubHeaderDescription } from '../../shared/components/styled';
import { useStepsDispatch, useStepsState } from '../provider';

import YesNoButtonGroup from '../../shared/components/form/YesNoButtonGroup';
import NavigationButtons from '../../shared/components/NavigationButtons';
import mixpanel from 'mixpanel-browser';

const CovidContact: React.FC = () => {
  const {
    form: { hadContact },
  } = useStepsState();
  const { updateFormValues, goToNextStep } = useStepsDispatch();

  return (
    <Container size="xl">
      <Content>
        <PageHeader>
          <PageTitle>
            In The Past 14 Days, Have You Had Contact With Someone Who Has
            Confirmed Case Of COVID-19? Count Any Contact That Lasted Longer
            Than 15 Miutes, Closer Than 6 Feet or 1.8 Meters Away?
          </PageTitle>
        </PageHeader>
        <Container noPadding>
          <SubHeaderDescription>
            Please see the{' '}
            <a
              href="https://www.cdc.gov/coronavirus/2019-ncov/if-you-are-sick/quarantine.html"
              target="_blank"
              rel="noreferrer noopener"
            >
              CDC Guidance on When to Quarantine
            </a>
            .
          </SubHeaderDescription>
          <Formik
            initialValues={{
              hadContact,
            }}
            onSubmit={(values) => {
              updateFormValues(values);
              mixpanel.track("covid contact page");
              goToNextStep();
            }}
          >
            {() => (
              <Form>
                <YesNoButtonGroup
                  name="hadContact"
                  onChange={(answer) =>{
                    updateFormValues({ hadContact: answer })
                    goToNextStep();
                  }}
                  isRequired
                />
                <NavigationButtons isValid={hadContact !== null ? true : false}/>
              </Form>
            )}
          </Formik>
        </Container>
      </Content>
    </Container>
  );
};

export default CovidContact;
