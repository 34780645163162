
import React, { useState, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { useFormikContext } from 'formik';
import { useAsync } from 'react-async-hook';
import { Popconfirm } from 'antd';
import { Breakpoints } from '../../../../dictionaries';
  import { isValidZIP } from '../../../../validations';

import Input from '../../../shared/components/form/Input';
import FormLabel from '../../../shared/components/form/FormLabel';
import { AnyObject } from '../../../shared/models';
import { colors } from '../../../../styles/colors';
import { getPostalCodeDetails } from '../../api';
import { PostalCodes } from '../PostalCode';
import {  useStepsState } from '../../../schedule/provider';
const Container = styled.div`
  position: relative;
  margin-bottom: 35px;
 
`;

const Address = styled.div`
  position: absolute;
  bottom: -30px;
  font-size: 14px;
  line-height: 22px;
  opacity: ${({ show }: { show: boolean }) => (show ? 1 : 0)};
  transition: opacity 0.15s ease-in-out;
  margin-top: -10px;
`;

const PopConfirmStyle = createGlobalStyle`
  .customized-popover-confirm {
    .ant-popover-content {
      .ant-popover-inner {
        border-radius: 10px;
        .ant-popover-inner-content {
          .ant-popover-message-title {
            font-size: 14px;
          }
          .ant-popover-buttons {
            .ant-btn {
              box-sizing: content-box;
              font-size: 14px;
              line-height: 1.6;
              border-radius: 5px;
              padding: 4px 15px;
              font-weight: normal;
              cursor: pointer;
              &.ant-btn-sm{
                border: 1px solid ${colors.darkBlue};
                color: ${colors.darkBlue};
                background: ${colors.white};
              }
              &.ant-btn-primary.ant-btn-sm {
                border: 1px solid ${colors.darkBlue};
                color: ${colors.white};
                background: ${colors.darkBlue};
              }
            }
          }
        }
      }
    }
  }
`;

interface Props {
  updateAddressValue: (newValue: any) => void;
  updateCountyValue: (newValue: any) => void;
  disabled?: boolean;
  country?:any;
  state?:any;
  //  updateFormValues: (newValue: any) => void;
}

const fetchAddressByZipCode = async (zipCode: string) =>
  // (
  //   await fetch(
  //     `https://maps.googleapis.com/maps/api/geocode/json?components=postal_code:${zipCode}|country:US&key=${process.env.REACT_APP_GEOCODE_API_KEY}`
  //   )
  // ).json();
  await getPostalCodeDetails(zipCode)

const ZipCodeInput: React.FC<Props> = ({
  updateAddressValue,
  // updateFormValues,
  updateCountyValue,
  country,
  state,
  disabled
}) => {
  const {
    values,
    errors,
  } = useFormikContext<{ address: AnyObject }>();
  const {
    form
  } = useStepsState();
  
  let zipCode:any =form.address.zipCode
   let address:any =values.address;
  //   // const address:any=values.address;
  
  const [popupVisible, setPopupVisible] = useState(false);
  const [city, setCity] = useState('');
  // const [country, setCountry] = useState('');
  // const [state, setState] = useState({
  //   longName: '',
  //   shortName: '',
  // });

  const [county, setCounty] = useState(String);


  const { result }: any = useAsync(fetchAddressByZipCode, [address.zipCode]);

  // const hasResult =
  //   address.zipCode &&
  //   result?.status === 'OK' &&
  //   Array.isArray(result?.results) &&
  //   result?.results.length > 0;
// console.log("addresszip",address)
  const hasResult =
  address.zipCode && result !== undefined &&
  result!.length > 0 &&
  Array.isArray(result)

  const handleUpdate = () => {
    updateAddressValue({
      city: city,
      state: state.shortName,
    });
    // updateFormValues({
    //   country:country
    // })
  };



  const handleCancel = () => {
    setPopupVisible(false);
  };

  useEffect(() => {
    if (hasResult) {
      // const { results } = result;
      for ( const component of result) {
        if (component.ZipCode && component.County) {
          updateCountyValue({ county: component.County })
        }
      }

      // const { results } = result;
      // for (const component of results[0].address_components) {
      //   if (component.types.includes('sublocality') || component.types.includes('locality')) {
      //     setCity(component.long_name);
      //   } else {
      //     if (component.types.includes('administrative_area_level_1')) {
      //       const {
      //         long_name: longName,
      //         short_name: shortName,
      //       } = component;
      //       setState({ longName, shortName });
      //     }
          
      //   }

      //   if (component.types.includes('administrative_area_level_2')) {
      //     const {
      //       long_name: longName,
      //     } = component;
      //     // setCounty(longName)
      //     updateCountyValue({ county: longName })
      //   }
      // };
    }
  }, [hasResult, result]);

  // useEffect(() => {
  //   if (
  //     hasResult &&
  //     isValidZIP.test(address.zipCode)  &&
  //     city &&
  //     state.shortName &&
  //     !(city === address.city &&
  //       state.shortName === address?.state?.value
  //     )
  //   ) {
  //       setPopupVisible(true);
  //   } else {
  //     setPopupVisible(false);
  //   }
  // }, [address, city, hasResult, state.shortName]);

  return (
    <Container>
      
      { country==="United States"?
      <FormLabel label="ZIP Code" isRequired>
        <PopConfirmStyle />
          <Input
            name="address.zipCode"
            value={address.zipCode}
            onChange={(value) => {
              updateAddressValue({zipCode: value});
            }}
            disabled={disabled}
            validate={(value) => {
              let error;
 
              let InterisValidZIP:any;
            PostalCodes.map((codes)=>{
               
              if(codes.Country==country){
                InterisValidZIP=new RegExp(codes.Regex)
              }
            })

              if (isValidZIP && !InterisValidZIP.test(value)) {
                error = `Please enter valid ${country} postal code`;
              }
              if (!value) {
                error = 'Required field';
              }

              return error;
            }}
          />
      </FormLabel>
      :
      <FormLabel label="Zip/Postal Code" isRequired>
      <PopConfirmStyle />
        <Input
          name="address.zipCode"
          value={address.zipCode}
          onChange={(value) => {
            updateAddressValue({zipCode: value});
          }}
          disabled={disabled}
          validate={(value) => {
            let error;
             
            let InterisValidZIP:any;
            PostalCodes.map((codes)=>{
               
              if(codes.Country==country){
                InterisValidZIP=new RegExp(codes.Regex)
              }
            })

            if (!InterisValidZIP?.test(value) ) {
              error = `Please enter valid ${country} postal code`;
            }

            if (!value) {
              error = 'Required field';
            }

             return error;
          }}
        />
      {/* </Popconfirm> */}
    </FormLabel>
    }
      {/* <Address show={hasResult && errors?.address?.zipCode === undefined}>
        {result?.results?.[0]?.formatted_address}
      </Address> */}
    </Container>
  );
};

export default ZipCodeInput;