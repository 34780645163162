import React, {useState} from 'react';
import { Formik, Form } from 'formik';

import {
    Container,
    Content,
    PageHeader,

} from '../../shared/components/styled';
// import { useStepsDispatch } from '../provider';
import { useStepsState, useStepsDispatch } from '../../schedule/provider'
import NavigationButtons from '../../shared/components/NavigationButtons';
import LocationButtonGroup from '../../shared/components/form/LocationButtonGroup';
import styled from 'styled-components';
import { colors } from '../../../styles/colors';
import { Breakpoints } from '../../../dictionaries';
import { useHistory } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import { useSharedDispatch, useSharedState } from '../../shared/provider';
import GoogleAnalytics from '../../../GoogleAnalytics';

export const PageTitle = styled.h2`
  color: ${colors.fullBlack};
  font-size:  28px ;
  font-weight: 700;
  margin: 0;
  text-align: left;

  @media (max-width: ${Breakpoints.sm}px) {
    font-size: 25px;
    text-align: center;
    margin-top: 30%;
  }

`;

const Location: React.FC = () => {
    const {
        form: { location, payingMethod, testSelection },form
    } = useStepsState();
    const [Location, setLocation] = useState<{isActive:boolean, location:any,loading:boolean}>({isActive:false, location:'',loading:false})
    React.useEffect(()=>{
        GoogleAnalytics(form,'/Select Testing Location','Select Testing Location');
        handlePaymentMethod()
        if(!location)
            updateFormValues({payingMethod : ''})
    },[])
    const history = useHistory();
    const { goToNextStep, updateFormValues, goToPrevStep, toggleShowAlertModal  } = useStepsDispatch();
    const { apptFromSchdule, flow, testSelectFlow,isWalkUp,locations } = useSharedState();
    const [insurance, setInsurance] = useState(false);
    const [noInsurance, setNoInsurance] = useState(false);
    const [creditCard, setCreditCard] =useState(false);
    const { updateDestination, updateTravelType, updateAirlineType, updateReasonType, updateVaccinated, updateVaccineType, updateapptFromSchdule } = useSharedDispatch();
    const redirect = () => {
        console.log("check1 ")
        history.push('/user/profile');
    }

    const handlePaymentMethod = () => {
        if(location?.partnerName === "goHealth"){
          setCreditCard(false)
          setInsurance(false)
          setNoInsurance(false)
      }else { 
          if (location?.isCreditCard.standard || location?.isCreditCard.antigen || location?.isCreditCard.rapid || location?.isCreditCard.express) {
            setCreditCard(true);
          } else {
            setCreditCard(false);
          }
          if (location?.isInsurance.standard || location?.isInsurance.antigen || location?.isInsurance.rapid || location?.isInsurance.express) {
            setInsurance(true);
          } else {
            setInsurance(false);
          }
          if (location?.isNoInsurance.standard || location?.isNoInsurance.antigen || location?.isNoInsurance.rapid || location?.isNoInsurance.express) {
            setNoInsurance(true);
          } else {
            setNoInsurance(false);
          }
        }
      }
    const loginRedirect = () => {
        updateDestination('');
        updateTravelType('');
        updateAirlineType('');
        updateReasonType('');
        updateVaccinated('');
        updateVaccineType('');
        updateapptFromSchdule(false);
        const link = localStorage.getItem('link');
        localStorage.clear();
        if(link)
            history.push(`/${link}`)
        else
        history.push('/?tenant=kern');
    }
    return (
        <Container>
            <Content>
                <Formik
                    initialValues={{
                        location,
                    }}
                    onSubmit={(values) => {
                        updateFormValues(values);
                        mixpanel.track("Select a testing location");
                        if (
                            values?.location?.partnerName === 'goHealth' &&
                            values?.location?.locationUrl
                          ) {
                            //   toggleShowAlertModal(true);
                              setLocation({isActive:true, location:values,loading:false});
                                // goToNextStep(); 
                          }
                          else {
                            if (insurance && !creditCard) {
                              updateFormValues({ payingMethod: 'Insurance' });
                            } else if (!insurance && creditCard) {
                              updateFormValues({ payingMethod: 'Credit Card' });
                            }
                            if (
                              !(
                                testSelection === 'general' ||
                                testSelection === 'flight'
                              ) &&
                              noInsurance
                            ) {
                              updateFormValues({ payingMethod: '' });
                            }
                            goToNextStep();
                        }
                    }}
                >
                    {() => {
                        if(isWalkUp.active){
                            const data = locations?.some(e=>(e.qbenchCustomerId===isWalkUp.locationId && !e.isWalkUpTest) )
                            if(data){
    
                                return <div style={{margin:"25px 0px",color:'red',textAlign:'center',fontSize:'20px'}}>
                                    Walkup functionality is currently disabled
                                </div>;
                            }
                        }
                        return (
                            <Form>
                                <PageHeader>
                                    <PageTitle>Select a testing location</PageTitle>
                                </PageHeader>
                                <LocationButtonGroup />
                                <NavigationButtons
                                    onReturnButtonClick={() => {
                                        if(testSelection === 'kern'){
                                            if(localStorage.getItem('user')){
                                                redirect();
                                            }else{
                                                loginRedirect()
                                            }                                          
                                                                                       
                                        }
                                       else{
                                        const link = localStorage.getItem('link');
                                        apptFromSchdule && !testSelectFlow ?  link?.includes('_ga') ? loginRedirect() : redirect() :
                                        (apptFromSchdule || testSelectFlow) ? goToPrevStep() : flow ? goToPrevStep() : redirect()
                                       }
                                            
                                    }}
                                    //isValid={location !== null ? true : false}
                                    isValid={form.location ? true : false}
                                />
                            </Form>
                        )}
                    }
                </Formik>
            </Content>
            {/* <AlertModal
              confirmAndPay={true}
              title={''}
              okBtn={Location.loading ?  'Redirecting...' : 'Ok'}
              content={
                "This location is operated by GoHealth. You will be redirected to their website to complete booking your appointment."
              }
              onConfirm={() => {
                if(Location.isActive)
                {
                    setLocation({...Location, loading:true})
                    GoogleAnalytics(form, 'GoHealth')
                    window.location.href =
                        Location?.location?.location?.locationUrl;
                }
              }}
              onCancel={() => {
                toggleShowAlertModal(false)                
              }}
            /> */}
        </Container>
    );
};

export default Location;
