import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import mixpanel from 'mixpanel-browser';

import { ReactComponent as LogoIcon } from '../../../../../assets/logo-icon.svg';
import { colors } from '../../../../../styles/colors';
import { config } from '../../../../../config';
import { Breakpoints } from '../../../../../dictionaries';
import {
    createPaymentIntent,
    createAppointment,
    releaseSlot,
    reserveSlot,
    updatePayment,
    getNpDestionation
} from '../../../../../modules/schedule/api';
import { ReactComponent as X } from '../../../../../assets/x.svg';

import Modal from '../../../../shared/components/Modal';
import PaymentDataInput from '../../../../schedule/components/ConfirmAndPay/PaymentDataInput';
import { useSharedState } from '../../../../shared/provider';
import { convertPricing } from '../../../../../utils';
import { updateAppointmentdata } from '../../../../admin/api';
import { useHistory } from 'react-router';
import { addStripeRefundCollection, getStripeRefund, rescheduleAppointment } from '../../../../manage/api';
import PaymentLogo from '../../../../../assets/WSL_KO_Vertical.png'
import { Image } from 'semantic-ui-react';


const Container = styled.div`
  background: ${colors.darkBlue};
  padding: 30px 30px 80px;
  width: 100%;

  @media (min-width: ${Breakpoints.sm}px) {
    width: 500px;
    padding: 45px 30px 100px;
  }

  @media (min-width: ${Breakpoints.md}px) {
    width: 740px;
  }
`;

const Logo = styled(LogoIcon)`
  display: block;
  margin: 0 auto 35px;
`;

const InputGroup = styled.div`
  background: ${colors.white};
  padding: 14px 22px;
  border-radius: 5px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
  margin-bottom: 16px;
`;

const Button = styled.button`
  background: ${colors.orange};
  width: 100%;
  border: 0;
  padding: 10px;
  font-size: 17px;
  line-height: 21px;
  letter-spacing: 1.7px;
  color: ${colors.white};
  font-weight: bold;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 32px;
  text-align: center;
`;

const CardElementWrapper = styled.div`
  margin-bottom: 20px;
  padding: 9px 20px;
  border-radius: 5px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
  background: ${colors.white};
`;

const Content = styled.div`
  position: relative;
  max-width: 470px;
  margin: 0 auto;
`;

const PaymentError = styled.div`
  position: absolute;
  font-size: 14px;
  color: ${colors.red};
  text-align: center;
  left: 0;
  width: 100%;
  opacity: ${({ error }: { error: string | null }) => (error ? 1 : 0)};
  transition: opacity 0.15s ease-in-out;
`;

const CloseModalButton = styled.button`
  position: absolute;
  right: 15px;
  top: 15px;
  width: 24px;
  height: 24px;
  background: none;
  border: 0;
  cursor: pointer;

  svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 22px;
    height: 22px;

    path {
      stroke: ${colors.white};
    }
  }
`;
const LogoImage = `
.image{
      @media (min-width: 320px) and (max-width: 480px) {
height:2rem;
}
}
`

interface Props {
    showModal: boolean;
    closeModal: () => void;
    prices:any;
    form:any;
    setEditformdata?:any;
    onsubmitform?:any;
    reason?:any;
}

const PaymentModal: React.FC<Props> = ({ showModal, closeModal, form, prices, setEditformdata, onsubmitform,reason }) => {
    const {
        firstName,
        lastName,
        email,
        phone,
        registeringFor,
        guardian,
        testSelection,
    } = form;
    
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState<string | null>(null);
    const [npArray, setNpArray] = useState([]);
    const [processing, setProcessing] = useState(false);
    const [paymentIntent, setPaymentIntent] = useState<{
        id: string;
        client_secret: string;
    } | null>(null);
    const history = useHistory()
    //The flag is to release and unrelease the slot as payment failure
    const [isSlotReleased, slotReleased] = useState<boolean>(false);

    const price = convertPricing(
        form.isExpressSameDayTest,
        form.isRapidTest,
        prices,
        form.travelType,
        form.destination,
        form.location?.destinations,
        npArray,
        form?.location?.isNpDestination                
    ); //form.isExpressSameDayTest ? prices.expedited : form.isRapidTest? prices.rapid : prices.standard;

    var amount: number = 0;

    if (registeringFor === 'minorOnly') {
        amount = price * form.minors.length;
    } else if (registeringFor === 'myself') {
        amount = price;
    } else {
        amount = price + price * form.minors.length;
    }

    useEffect(() => {
        getDestination();
        async function createIntent() {
            try {
                const result = await createPaymentIntent({
                    amount: amount * 100,
                    currency: config.currency,
                    metadata: {
                        location: form.location?.name,
                        location_Id: form.location?.qbenchCustomerId,
                    },
                    description: 'COVID-19 Test',
                    customer : form?.customerId ? form?.customerId : ""
                });

                setPaymentIntent({
                    id: result.data.id,
                    client_secret: result.data.client_secret,
                });
            } catch (e) {
                setError('Payment failed');

                console.error('[Payment Intent API error]', e);
            }
        }

        if (showModal) {
            createIntent();
        }
    }, [showModal, amount]);

    const getDestination = async () =>{
        const arr:any = [];
        const response = await getNpDestionation({}).then((result:any)=>{            
                const data = result.data.data;
                data.forEach((element: any) => {
                    arr.push(element.value) 
                });     
                console.log("values",arr)       
        });
        setNpArray(arr)
    }


    return (
        <Modal open={showModal} onClose={closeModal} noPadding maxWidth={740}>
            <Container>
                <CloseModalButton type="button" onClick={() => closeModal()}>
                    <X />
                </CloseModalButton>
                <Content>
                    {/* <Logo /> */}
                    <Image
                        style={{
                            display: 'initial',
                        }}
                        className={LogoImage}
                        src={PaymentLogo} />
                    <Formik
                        initialValues={{
                            name: `${firstName ? firstName : guardian?.firstName} ${lastName ? lastName : guardian?.lastName
                                }`,
                            email,
                            phone,
                        }}
                        onSubmit={async (values: any) => {
                            if (!stripe || !elements) {
                                return;
                            }
                            console.log('values', values);
                            
                            setProcessing(true);

                            const cardElement = elements.getElement(CardElement);

                            try {
                                if (!cardElement || !paymentIntent) {
                                    throw new Error('Payment failed');
                                }

                                const {
                                    confirmationId,
                                    agreeToCancel,
                                    commitToAttend,
                                    consentForTesting,
                                    hipaaConfirmed,
                                    confirmEmail,
                                    ...appointment
                                } = form;

                                const payload = await stripe.confirmCardPayment(
                                    paymentIntent.client_secret,
                                    {
                                        payment_method: {
                                            card: cardElement,
                                            billing_details: {
                                                name: values.name,
                                                phone: values.phone,
                                                email: values.email,
                                            },
                                        },
                                        receipt_email: values.email,
                                    }
                                );
                                console.log('payment ',payload);
                                
                                if (!payload.error) {
                                    setError(null);
                                    let reasonForRescheduling = 'Changing Test Types';
                                    if(reason?.change)
                                    {
                                        reasonForRescheduling='';
                                        if(reason?.upgrade)
                                            reasonForRescheduling+='Upgrading from ';
                                        else
                                            reasonForRescheduling+='Downgrading from ';
                                        reasonForRescheduling+=reason?.previous+' Test to '+reason?.present+' Test';
                                    }
                                    const initiateRefund = await getStripeRefund({
                                        paymentIntentId: form?.paymentIntentId,
                                        reason: reasonForRescheduling
                                    })

                                    console.log("initiateRefund", initiateRefund.data);

                                    const addRefund = await addStripeRefundCollection({
                                        confirmationId: form?.confirmationId,
                                        reason: reasonForRescheduling,
                                        stripeObject: initiateRefund.data,
                                        paymentIntentId: form?.paymentIntentId,
                                    })

                                    let appointmentData: any = {
                                        ...form,
                                        paymentIntentId: paymentIntent.id,
                                    }

                                    delete appointmentData?.qbenchAcknowledgement;
                                    await rescheduleAppointment(appointmentData);

                                    mixpanel.track('Successful payment');
                                    // await updateAppointmentdata(form)
                                    setProcessing(false);
                                    history.push('/user/profile');
                                } else {
                                    throw new Error(`Payment failed: ${payload.error.message}`);
                                }
                            } catch (e) {
                                let s:any=e;
                                setError(s.message);
                            }

                            setProcessing(false);
                        }}
                    >
                        {() => (
                            <Form>
                                <InputGroup style={{color: colors.Matterhorn}}> 
                                    <PaymentDataInput label="Name" name="name" />
                                    <PaymentDataInput label="Email" name="email" />
                                    <PaymentDataInput label="Phone" name="phone" />
                                </InputGroup>
                                <CardElementWrapper>
                                    <CardElement
                                        options={{
                                            style: {
                                                base: {
                                                    fontSize: '16px',
                                                    fontWeight:'400',
                                                    lineHeight:'22px',
                                                    color: colors.black,
                                                    '::placeholder': {
                                                        color: colors.Matterhorn,
                                                    },
                                                    backgroundColor: colors.white,
                                                    fontFamily: 'Inter, sand-serif',
                                                },
                                                invalid: {
                                                    color: colors.red,
                                                },
                                            },
                                        }}
                                    />
                                </CardElementWrapper>
                                <Button type="submit" disabled={processing} className='btn fill-btn'>
                                    {processing ? 'Processing...' : `Pay $${amount}`}
                                </Button>
                                <PaymentError error={error}>{error}</PaymentError>
                            </Form>
                        )}
                    </Formik>
                </Content>
            </Container>
        </Modal>
    );
};

export default PaymentModal;