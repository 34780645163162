import { BaseService } from "./BaseService";
import { API_ENDPOINTS } from "./constant";
import { OTPDetails, ValidateOTP } from "./models/multitenancy.interface";

export class MultiTenancyService extends BaseService {
    public async intiateOTP(otpContactInformation: OTPDetails) {
        try {
            const { data } = await this.httpClient.post(API_ENDPOINTS.INITIATE_OTP, {data: otpContactInformation});
            return data;
        } catch (error) {
            return error;
        }
    }

    public async validateOTP(otpValidationData: ValidateOTP) {
        try {
            const { data } = await this.httpClient.post(API_ENDPOINTS.VALIDATE_OTP, {data: otpValidationData});
            return data;
        } catch (error) {
            return error;
        }
    }

}