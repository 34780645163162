export const TravellingType = [
    {
      label: 'Domestically',
      value: 'DOM',
    },
    {
      label: 'Internationally',
      value: 'INT',
    }
];
export const TravellingTypeOnlyDOM = [
    {
      label: 'Domestically',
      value: 'DOM',
    }
];