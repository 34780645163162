import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { format } from 'date-fns';
import { config } from '../../../../config';

import InsertInvitation from "@material-ui/icons/InsertInvitation";
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  Row,
  Space,
  Select,
  Typography,
} from 'antd';
import GridItem from "../AppointmentList/GridItem";
import GridContainer from "../AppointmentList/GridContainer";

import CustomTabs from "../AppointmentList/CustomTabs_Slot";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import AntDDatePicker from '../../../shared/components/AntDDatePicker';
import { has } from 'lodash'
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
  }),
);

function SlotOccupancy() {
  const classes = useStyles();
  
  const [selectedDate, setSelectedDate] = useState( format(new Date(),'LL/dd/yyyy') );
  const [slotoccupancyresult, setslotoccupancyresult] = useState([]);

function getslotoccupany()
{
  axios({
    method: 'post',
    url: 'https://us-central1-wsl-multitenancy-dev-ac13b.cloudfunctions.net/getslotcapacity',
    data: { data: { runDate: selectedDate } }
  })
    .then(function (response) {
      if (response.status == 200) {
      const  items = response.data.result.slotcapacity.filter(function(item:any) {
          return item.locationId !== undefined; 
        });
        
        setslotoccupancyresult(items)
        return true;

      }

    });
}


const handleSearch = () => {
  getslotoccupany()
};

  useEffect(() => {
    getslotoccupany()
  }, []);

  return (
    <div>
      {/* <form className={classes.container} noValidate>
      <TextField
        id="date"
        label="Birthday"
        type="date"
        value={selectedDate}
        
          onChange={handleDateChange}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </form> */}
      <Form style={{ marginLeft: "5%" }}>
        <Form.Item label="Slot Date" name="date">
          <AntDDatePicker
            style={{ width: '20%' }}
            disabled={false}
            defaultValue={new Date(selectedDate)}
            showToday
            onChange={(newDate:any) =>

              setSelectedDate(format(newDate,'LL/dd/yyyy'))

            }
            allowClear={false}
          />
          <span style={{marginLeft:"5%"}}><Button
                        type="primary"
                        //loading={loading}
                        onClick={handleSearch}
                      >
                        Search
                      </Button> </span> 
        </Form.Item>
       
      </Form>

      <GridContainer>
        <GridItem>
          <CustomTabs
            headerColor="primary"
            tabs={[
              {
                tabName: `Slot Date : ${selectedDate}`,
                tabIcon: InsertInvitation,
                tabContent: (
                  <Table >
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Booked</TableCell>
                        <TableCell>Capacity</TableCell>
                      </TableRow>
                    </TableHead>
                    {(slotoccupancyresult).map((model: any) => (
                      <TableBody>
                        {(has(model.appointmentsPerPeriod, '0') && model.appointmentsPerPeriod["0"] > 0)
                        || (has(model.appointmentsPerPeriod, '1') && model.appointmentsPerPeriod["1"] > 0)
                        ||(has(model.appointmentsPerPeriod, '2') && model.appointmentsPerPeriod["2"] > 0)
                        ||(has(model.appointmentsPerPeriod, '3') && model.appointmentsPerPeriod["3"] > 0)
                        ||(has(model.appointmentsPerPeriod, '4') && model.appointmentsPerPeriod["4"] > 0)
                        ||(has(model.appointmentsPerPeriod, '5') && model.appointmentsPerPeriod["5"] > 0)
                        ||(has(model.appointmentsPerPeriod, '6') && model.appointmentsPerPeriod["6"] > 0)
                        ||(has(model.appointmentsPerPeriod, '7') && model.appointmentsPerPeriod["7"] > 0)
                        ||(has(model.appointmentsPerPeriod, '8') && model.appointmentsPerPeriod["8"] > 0)
                        ||(has(model.appointmentsPerPeriod, '9') && model.appointmentsPerPeriod["9"] > 0)
                        ||(has(model.appointmentsPerPeriod, '10') && model.appointmentsPerPeriod["10"] > 0)
                        ||(has(model.appointmentsPerPeriod, '11') && model.appointmentsPerPeriod["11"] > 0)
                        ||(has(model.appointmentsPerPeriod, '12') && model.appointmentsPerPeriod["12"] > 0)
                        ||(has(model.appointmentsPerPeriod, '13') && model.appointmentsPerPeriod["13"] > 0)
                        ||(has(model.appointmentsPerPeriod, '14') && model.appointmentsPerPeriod["14"] > 0)
                        ||(has(model.appointmentsPerPeriod, '15') && model.appointmentsPerPeriod["15"] > 0)?
                        <TableRow>Location: {model.name} </TableRow> :false }
                        <div style={{marginTop:"2%"}}></div>
                        {has(model.appointmentsPerPeriod, '0') && model.appointmentsPerPeriod["0"] > 0 ? <TableRow>
                          <TableCell>6:00 AM - 6:30 AM</TableCell>
                          <TableCell>{model.appointmentsPerPeriod["0"]}</TableCell>
                          <TableCell>16</TableCell>


                        </TableRow> : false}
                        {has(model.appointmentsPerPeriod, '1') && model.appointmentsPerPeriod["1"] > 0 ? <TableRow>
                          <TableCell>6:30 AM - 7:00 AM</TableCell>
                          <TableCell>{model.appointmentsPerPeriod["1"]}</TableCell>
                          <TableCell>16</TableCell>


                        </TableRow> : false}
                        {has(model.appointmentsPerPeriod, '2') && model.appointmentsPerPeriod["2"] > 0 ? <TableRow>
                          <TableCell>7:00 AM - 7:30 AM</TableCell>
                          <TableCell>{model.appointmentsPerPeriod["2"]}</TableCell>
                          <TableCell>16</TableCell>


                        </TableRow> : false}
                        {has(model.appointmentsPerPeriod, '3') && model.appointmentsPerPeriod["3"] > 0 ? <TableRow>
                          <TableCell>7:30 AM - 8:00 AM</TableCell>
                          <TableCell>{model.appointmentsPerPeriod["3"]}</TableCell>
                          <TableCell>16</TableCell>


                        </TableRow> : false}
                        {has(model.appointmentsPerPeriod, '4') && model.appointmentsPerPeriod["4"] > 0 ? <TableRow>
                          <TableCell>8:00 AM - 8:30 AM</TableCell>
                          <TableCell>{model.appointmentsPerPeriod["4"]}</TableCell>
                          <TableCell>16</TableCell>


                        </TableRow> : false}
                        {has(model.appointmentsPerPeriod, '5') && model.appointmentsPerPeriod["5"] > 0 ? <TableRow>
                          <TableCell>8:30 AM - 9:00 AM</TableCell>
                          <TableCell>{model.appointmentsPerPeriod["5"]}</TableCell>
                          <TableCell>16</TableCell>


                        </TableRow> : false}

                        {has(model.appointmentsPerPeriod, '6') && model.appointmentsPerPeriod["6"] > 0 ? <TableRow>
                          <TableCell>9:00 AM - 9:30 AM</TableCell>
                          <TableCell>{model.appointmentsPerPeriod["6"]}</TableCell>
                          <TableCell>16</TableCell>
                        </TableRow> : false}

                        {has(model.appointmentsPerPeriod, '7') && model.appointmentsPerPeriod["7"] > 0 ? <TableRow>
                          <TableCell>9:30 AM - 10:00 AM</TableCell>
                          <TableCell>{model.appointmentsPerPeriod["7"]}</TableCell>
                          <TableCell>16</TableCell>


                        </TableRow> : false}
                        {has(model.appointmentsPerPeriod, '8') && model.appointmentsPerPeriod["8"] > 0 ? <TableRow>
                          <TableCell>10:00 AM - 10:30 AM</TableCell>
                          <TableCell>{model.appointmentsPerPeriod["8"]}</TableCell>
                          <TableCell>16</TableCell>


                        </TableRow> : false}
                        {has(model.appointmentsPerPeriod, '9') && model.appointmentsPerPeriod["9"] > 0 ? <TableRow>
                          <TableCell>10:30 AM - 11:00 AM</TableCell>
                          <TableCell>{model.appointmentsPerPeriod["9"]}</TableCell>
                          <TableCell>16</TableCell>


                        </TableRow> : false}
                        {has(model.appointmentsPerPeriod, '10') && model.appointmentsPerPeriod["10"] > 0 ? <TableRow>
                          <TableCell>11:00 AM - 11:30 AM</TableCell>
                          <TableCell>{model.appointmentsPerPeriod["10"]}</TableCell>
                          <TableCell>16</TableCell>


                        </TableRow> : false}
                        {has(model.appointmentsPerPeriod, '11') && model.appointmentsPerPeriod["11"] > 0 ? <TableRow>
                          <TableCell>11:30 AM - 12:00 PM</TableCell>
                          <TableCell>{model.appointmentsPerPeriod["11"]}</TableCell>
                          <TableCell>16</TableCell>


                        </TableRow> : false}
                        {has(model.appointmentsPerPeriod, '12') && model.appointmentsPerPeriod["12"] > 0 ? <TableRow>
                          <TableCell>12:00 PM - 12:30 PM</TableCell>
                          <TableCell>{model.appointmentsPerPeriod["12"]}</TableCell>
                          <TableCell>16</TableCell>


                        </TableRow> : false}

                        {has(model.appointmentsPerPeriod, '13') && model.appointmentsPerPeriod["13"] > 0 ? <TableRow>
                          <TableCell>12:30 PM - 1:00 PM</TableCell>
                          <TableCell>{model.appointmentsPerPeriod["13"]}</TableCell>
                          <TableCell>16</TableCell>


                        </TableRow> : false}
                        {has(model.appointmentsPerPeriod, '14') && model.appointmentsPerPeriod["14"] > 0 ? <TableRow>
                          <TableCell>1:00 PM - 1:30 PM</TableCell>
                          <TableCell>{model.appointmentsPerPeriod["14"]}</TableCell>
                          <TableCell>16</TableCell>


                        </TableRow> : false}
                        {has(model.appointmentsPerPeriod, '15') && model.appointmentsPerPeriod["15"] > 0 ? <TableRow>
                          <TableCell>1:30 PM - 2:00 PM</TableCell>
                          <TableCell>{model.appointmentsPerPeriod["15"]}</TableCell>
                          <TableCell>16</TableCell>


                        </TableRow> : false}





                      </TableBody>
                    ))}
                  </Table>
                )
              }
            ]}
          />
        </GridItem>

      </GridContainer>
    </div>
  );
}

export default SlotOccupancy;