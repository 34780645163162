import React, { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { Breakpoints } from '../../../dictionaries';
import { Form, Formik } from 'formik';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { useSharedDispatch, useSharedState } from '../../shared/provider';
import { useRegisterStepsDispatch, useStepsState } from '../provider';
import Modal from '../../shared/components/Modal';
import { colors } from '../../../styles/colors';
import { ReactComponent as X } from '../../../assets/x.svg';


const Title = styled.h2`
  color: ${colors.fullBlack};
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 25px;
  text-align: center;
  @media (max-width: ${Breakpoints.sm}px) {
    font-size: 24px;
  }
`;
const Container = styled.div`
  padding: 30px;
  @media (max-width: ${Breakpoints.sm}px) {
    padding: 20px 0px;
}
`;
const CloseModalButton = styled.button`
  position: absolute;
  right: 15px;
  top: 15px;
  width: 24px;
  height: 24px;
  background: none;
  border: 0;
  cursor: pointer;
  svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 22px;
    height: 22px;

    path {
      stroke: ${colors.fullBlack};
    }
  }
`;
const StepContent = styled.div`
  text-align: center;
`;
const Heading = styled.h2`
  color: ${colors.fullBlack};
  font-size: 20px;
  font-weight: bold;
  @media (min-width: 450px) {
    font-size: 18px;
  }
`;

type Props = {
    open: boolean;
    handleClose: ()=> void,
}
const NoInsuranceModal: React.FC<Props>= ( { handleClose, open }) => {
    const { goToSkipStep } = useRegisterStepsDispatch();
  
  
    return (
        <Modal open={open} onClose={handleClose} maxWidth={740} blockScroll={false}>
        <Container>
            {/* <CloseModalButton type="button" onClick={() => handleClose()}>
                <X />
            </CloseModalButton> */}
            <Title>YOUR DETAILS HAVE BEEN SAVED</Title>
            <StepContent>
                <Heading>
                    Thank you.<br />We are redirecting you to your appointment confirmation page now.
                </Heading>
            </StepContent>
       </Container>
        </Modal>
    );
  };
  
  export default NoInsuranceModal;
  