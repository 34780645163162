import { useState, useEffect } from 'react';
import { addMinutes, format, isBefore, parse, differenceInDays, isEqual } from 'date-fns';
import { includes } from "lodash"
import { config } from './config';
import { Period } from './modules/schedule/models';
import moment from 'moment';

export function hexToRGB(hex: string, alpha?: number) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}${alpha !== undefined ? `, ${alpha}` : ''})`;
}

interface WindowDimensions {
  width: number;
  height: number;
}

export function useWindowSize(): WindowDimensions {
  function getSize(): WindowDimensions {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
}

export const pluralize = (count: number, noun: string, suffix = 's') =>
  `${noun}${count !== 1 ? suffix : ''} `;

function getPeriodsLabelsFromSchedule() {
    const startTime = parse('6:00a', 'h:mmaaaaa', new Date());
    const endTime = parse('10:00p', 'h:mmaaaaa', new Date());
    let time = startTime;
    const labels = [];
    while (isBefore(time, endTime)) {
        const next = addMinutes(time, 30);
        labels.push(`${format(time, 'hh:mm a')} - ${format(next, 'hh:mm a')}`);
        time = next;
    }
    return labels;
}

export function getPeriodsFromSchedule(schedule: {
  startTime: string;
  endTime: string;
  periodDuration: number;
  availability?: any;
}): Period[] {
    const startTime = parse(schedule.startTime, 'h:mmaaaaa', new Date());
    const endTime = parse(schedule.endTime, 'h:mmaaaaa', new Date());
    let time = startTime;
    const periods:any[] = [];
    let index = 0;
    index = parseInt(getPeriodsLabelsFromSchedule().map((e,i)=>{return e.split('-')[0]+'-'+i}).filter((e)=>isEqual(new Date(startTime) , new Date(parse(e.split('-')[0], 'hh:mm a', new Date()))))[0].split("-")[1]);
    while (isBefore(time, endTime)) {
        const next = addMinutes(time, schedule.periodDuration);
        periods.push({
            label: `${format(time, 'hh:mm a')} - ${format(next, 'hh:mm a')}`,
            startTime: time,
            available: schedule.availability !== undefined ? schedule.availability : 0,
            index,
            endTime: next
        });
        index += 1;
        time = next;
    }    
    return periods;
}

// this function is for Rapid slots
export function getPeriodsFromScheduleForRapid(schedule: {
    startTime: string;
    endTime: string;
    periodDuration: number;
    availability?: any;
    lablePeriod?: any
}): Period[] {
    if (schedule.lablePeriod !== 30)
        schedule.lablePeriod = 60
    const startTime = parse(schedule.startTime, 'h:mmaaaaa', new Date());
    const endTime = parse(schedule.endTime, 'h:mmaaaaa', new Date());

    let time = startTime;
    const periods = [];
    let index = 0;

    while (isBefore(time, endTime)) {
        const next = addMinutes(time, schedule.periodDuration);

        periods.push({
            label: `${format(time, 'hh:mm a')} - ${format(addMinutes(new Date(time), schedule.lablePeriod), 'hh:mm a')}`,
            startTime: time,
            available: schedule.availability !== undefined ? schedule.availability : 0,
            index,
        });

        index += 1;
        time = next;
    }

    return periods;
}
export const timeToPeriod = (time: String, isRapid: boolean) => {
    let period = getPeriodsFromSchedule({startTime: '6:00a',endTime: '6:00p',periodDuration: isRapid?60:30,});
    try {
        time = time.toLocaleLowerCase()
        let splitedTime =time.split(":") 
        let hour = parseInt(splitedTime[0])
        let minutes = parseInt(splitedTime[1].split(' ')[0])
        let count = 0;
        hour = ((time.includes('p') || time.includes('pm') ) && hour != 12) ? hour+12 : hour;
        count = (minutes >= 30 || isRapid)? isRapid?hour-6:((hour-6)*2)+1 : (hour - 6) * 2
        return period[count<=period.length?count>=0?count:0:0]||period[0]   
    } catch (e) {
        return period[0]
    }
}

export const isMobileAndTablet = () => {
  let check = false;

  (function (a) {
    if (
      // eslint-disable-next-line
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      // eslint-disable-next-line
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
    // @ts-ignore
  })(navigator.userAgent || navigator.vendor || window.opera);

  return check;
};

export function isObject(value: any) {
  return typeof value === 'object' && value !== null;
}

export function parseSnapshot(snapshot: any) {
  return snapshot.docs[0]
    ? {
      id: snapshot.docs[0].id,
      data: snapshot.docs[0].data(),
    }
    : null;
}

export const compareStrings = (a: string, b: string) => {
  const leftStr = a ? a.toLowerCase() : '';
  const rightStr = b.toLowerCase();
  return leftStr.includes(rightStr);
};


export function Last7Days() {
  var result = [];
  for (var i = 0; i < 7; i++) {
    var newDate = new Date();
    newDate.setDate(newDate.getDate() - i);
    result.push(`${format(newDate, 'LL/dd/yyyy')}`);
  }
  return result;
}

export const ageCalculator = (date: any) => {

  const dob: any = new Date(date);
  const now: any = new Date();

  const dobYear = dob.getYear();
  const dobMonth = dob.getMonth();
  const dobDate = dob.getDate();

  const currentYear = now.getYear();
  const currentMonth = now.getMonth();
  const currentDate = now.getDate();

  let yearAge: any = '';

  //get years
  yearAge = currentYear - dobYear;

  //get months
  if (currentMonth >= dobMonth)
    //get months when current month is greater
    var monthAge = currentMonth - dobMonth;
  else {
    yearAge--;
    monthAge = 12 + currentMonth - dobMonth;
  }
  //get days
  if (currentDate >= dobDate)
    //get days when the current date is greater
    var dateAge = currentDate - dobDate;
  else {
    monthAge--;
    dateAge = 31 + currentDate - dobDate;

    if (monthAge < 0) {
      monthAge = 11;
      yearAge--;
    }
  }

  return {
    years: yearAge,
    months: monthAge,
    days: dateAge,
  };
};


export const getSiteHostName = () => {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let HostName = params.get('airline') || localStorage.getItem('airline') || '';

  localStorage.setItem('isAirline', 
    (HostName !== null && HostName?.toUpperCase() !== '') ? 'false' : 'true')
  // let HostName: String = window.location.hostname.split('.')[0]
  //   ? window.location.hostname.split('.')[0]
  //   : '';
  if (HostName !== null && HostName?.toUpperCase() !== '') {
    return false;
  }
  else {
    return true;
  }
};

export const validateHostName = () => {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let HostName = params.get('airline');
  let FlowName = params.get('general');
  let sfoFlow = params.get('sfo');
  let DistrictName = params.get('district') || params.get('DISTRICT');
  let eventFlow = params.get('event') || params.get('EVENT');
  let Comm = params.get('community');
  let tenant = params.get('tenant')?.toLocaleLowerCase()

  if (HostName !== null) {
    localStorage.setItem('reason', '')
    localStorage.setItem('vactype', '')
    localStorage.setItem('isvac', '')
    localStorage.setItem('dist', '')
    localStorage.setItem('fromURL', 'true')
    return false;
  } else if(sfoFlow === ''){
    localStorage.setItem('tenant', 'sfoairport')
    localStorage.setItem('tenent', '')
    localStorage.setItem('airline', '')
    localStorage.setItem('destination', '')
    localStorage.setItem('travelType', 'DOM')
    localStorage.setItem('dist', '')
    localStorage.setItem('fromURL', 'true')
    return false;
  }  else if(tenant){
    localStorage.setItem('tenant', 'kern')
    localStorage.setItem('airline', '')
    localStorage.setItem('destination', '')
    localStorage.setItem('travelType', '')
    localStorage.setItem('dist', '')
    localStorage.setItem('fromURL', '')
    return false;
  } else if (FlowName === ''){
    localStorage.setItem('tenant', 'general')
    localStorage.setItem('airline', '')
    localStorage.setItem('destination', '')
    localStorage.setItem('travelType', 'DOM')
    localStorage.setItem('dist', '')
    localStorage.setItem('fromURL', 'false')
    localStorage.setItem('tenant','')
    return false;
  } else if (DistrictName || Comm === ''){
    localStorage.setItem('airline', '')
    localStorage.setItem('destination', '')
    localStorage.setItem('reason', '')
    localStorage.setItem('vactype', '')
    localStorage.setItem('isvac', '')
    localStorage.setItem('travelType', 'DOM')
    localStorage.setItem('fromURL', 'true')
    localStorage.setItem('tenant','')
    return false;
  } else if(eventFlow){
    localStorage.setItem('airline', '')
    localStorage.setItem('destination', '')
    localStorage.setItem('reason', '')
    localStorage.setItem('vactype', '')
    localStorage.setItem('isvac', '')
    localStorage.setItem('travelType', 'DOM')
    localStorage.setItem('fromURL', 'true')
    localStorage.setItem('tenant','')
    return false
  } else {
    localStorage.setItem('airline', '')
    localStorage.setItem('destination', '')
    localStorage.setItem('reason', '')
    localStorage.setItem('vactype', '')
    localStorage.setItem('isvac', '')
    localStorage.setItem('travelType', '')
    localStorage.setItem('dist', '')
    localStorage.setItem('fromURL', 'false')
    localStorage.setItem('tenant','')
    return true;
  }
};

export const getHostName = () => {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let HostName = params.get('airline') || localStorage.getItem('airline') || '';
  localStorage.setItem('airline', HostName)
  return HostName;
};

export const gettraveltype = () => {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let traveltype = params.get('travel') || localStorage.getItem('travelType') || '';
  localStorage.setItem('travelType', traveltype)
  return traveltype;

};

export const getdestination = () => {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let dest = params.get('dest') || localStorage.getItem('destination') || '';
  localStorage.setItem('destination', dest)
  return dest;

};

export const getAddress = () => {
  let user =  localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') || '') : '';
  return user?.address;
};

export const getapptFromSchdule = () => {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let apptFromSchdule: boolean = !!(params.get('travel') ||  params.get('dest') ||  params.get('airline') ||
   params.get('reason') || params.get('vac') || params.get('type') || params.get('district') || params.get('DISTRICT'));
  return apptFromSchdule;
};

export const getreasontype = () => {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let reasontype = params.get('reason') || localStorage.getItem('reason') || '';
  localStorage.setItem('reason', reasontype)
  return reasontype;

};

export const getvaccinetype = () => {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let vaccinetype = params.get('type') || localStorage.getItem('vactype') || '';
  localStorage.setItem('vactype', vaccinetype)
  return vaccinetype;
};

export const getvaccinated = () => {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let data = params.get('vac') || localStorage.getItem('isvac') || '';
  localStorage.setItem('isvac', data)
  return data;

};

export const getdistrict = () => {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let dist: any = params.get('district') || localStorage.getItem('dist') || params.get('DISTRICT') || '';
  let comm: any = params.get('community') ;
  let event: any = params.get('event') ;
  dist = event?event:comm==='' ? 'COM001' : dist;
  localStorage.setItem('dist', dist)
  return dist;

};

export const gettenet = () => {
  let dist = localStorage.getItem('tenant') || '';
  localStorage.setItem('tenant', dist)
  return dist;
};

export const getpath = ()=>{
    let pathname = window.location.pathname.split('/')[3];
    return pathname;
}
export const gettenant = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let tenant: any = params.get('tenant')|| localStorage.getItem('tenant') ||  params.get('tenant') || '';
    let comm: any = params.get('community') ;
    let event: any = params.get('event') ;
    tenant = event?event:comm==='' ? 'COM001' : tenant;
    localStorage.setItem('tenant', tenant)
    return tenant;
  };

export const getRefersion = () => {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let data = params.get('refersion') || localStorage.getItem('refersion') || '';
  localStorage.setItem('refersion', data)
  return data;
};

export const isFromUrl = () => {
  let isFromUrl: boolean = localStorage.getItem('fromURL') === 'true' ? true : false;
  return isFromUrl;
}

export const timeDifferenceFilter = (
  parsedDeparture: any, 
  startTime: any, 
  i: any,
  minTime?: number | null,
  maxTime?: any,
) =>{
  const expressPeriodes: any = [12]
  const expressPeriodesOdd: any = [13, 15]

  const departure: any = new Date(parsedDeparture).getHours()
  const departureInMinutes: any = new Date(parsedDeparture).getMinutes()

  const diff: any = differenceInDays(new Date(parsedDeparture), new Date(startTime),)


    let dateDiff: any =
      diff === 3 ?
          departure + (includes(expressPeriodes, departure)
              ? (departureInMinutes == 30 ? 1 : 0)
              : (includes(expressPeriodesOdd, departure)
                  ? (departureInMinutes == 30 ? 2 : 1)
                  : departure == 11 ? (departureInMinutes == 30 ? 0 : 1)
                      : 2))
          : minTime;

  
  return i < dateDiff || (i > maxTime && i <= 15)
} 

const periods = getPeriodsFromSchedule(config.schedule);
const rapidPeriods = getPeriodsFromSchedule(config.rapidSchedule);

export const slotsListPeriod = (testType: any, slot: any ) => {
  return testType == "rapid" ? rapidPeriods[slot.period].label :  periods[slot.period].label
} 

export const convertPricing = (
	isExpressSameDayTest: any,
	isRapidTest: any,
	prices: {
		expedited: number;
		rapid: number;
		standard: number;
		standard_INT: number,
		expedited_INT: number,
        rapid_INT: number
        antigen: number,
        antigen_INT:number
	},
	travelType: any,
    destination: any,
    isAntigen:any,
    intDestinations:string[] | undefined, 
    isDestinations : boolean | undefined,   
) => {
	//Add the filter destination and location for prices diffrence.


  const travelType_filter: any = travelType !== null &&travelType !== undefined && travelType.value !== undefined&& travelType.value !== null ? travelType.value : travelType

	//Express test validation for both INT and DOM
	if (isExpressSameDayTest) {

		if (travelType_filter == "DOM") {
			return prices.expedited
		} else if (travelType_filter == "INT") {

      if (!!intDestinations?.length && (isDestinations === true) && includes(intDestinations, destination?.value ? destination?.value : destination)) {  
                return prices.expedited_INT
			} else {
				return prices.expedited
			}

		} else {
			return prices.expedited
		}

	//Rapid test validation for both INT and DOM
	} else if (isRapidTest) {

		if (travelType_filter == "DOM") {
				return prices.rapid
		} else if (travelType_filter == "INT") {

      if (!!intDestinations?.length && (isDestinations === true) && includes(intDestinations, destination?.value ? destination?.value : destination)) {        
				return prices.rapid_INT
			} else {
				return prices.rapid
			}
			
		} else {
			return prices.rapid
		}

	//Default standard test validation for both INT and DOM
    } else if (isAntigen) {
        if (travelType_filter == "DOM") {
            return prices.antigen
        } else if (travelType_filter == "INT") {

        if (!!intDestinations?.length && (isDestinations === true) && includes(intDestinations, destination?.value ? destination?.value : destination)) {               
            return prices.antigen
                } else {
                    return prices.antigen
                }
                
            } else {
                return prices.antigen
            }
    } else {

		if (travelType_filter == "DOM") {
				return prices.standard
		} else if (travelType_filter == "INT") {

      if (!!intDestinations?.length && (isDestinations === true) && includes(intDestinations, destination?.value ? destination?.value : destination)) {               
        return prices.standard_INT
			} else {
				return prices.standard
			}
			
		} else {
			return prices.standard
		}

	}}

export const weekAndWeekDayConvertion = (params: any) => {

  const Weekday = params.weeklyVisible.weeklyVisibleNumber.weekDay;
  const weekNum = params.weeklyVisible.weeklyVisibleNumber.weekNumber;
  const currentDate = moment(params.date)
      .startOf("month")
      .format("YYYY-MM-DD hh:mm");
  let addDay: any = new Date(currentDate);
  let firstDay = new Date(currentDate).getDay();

  const add = Weekday < firstDay ? 0 : -1;
  if (firstDay === 0) {
      addDay = addDay.setDate(addDay.getDate() + ((weekNum - 1) * 7 + Weekday));
  } else if (firstDay === 1) {
      addDay = addDay.setDate(
          addDay.getDate() + ((weekNum + add) * 7 + (Weekday - 1))
      );
  } else if (firstDay === 2) {
      addDay = addDay.setDate(
          addDay.getDate() + ((weekNum + add) * 7 + (Weekday - 2))
      );
  } else if (firstDay === 3) {
      addDay = addDay.setDate(
          addDay.getDate() + ((weekNum + add) * 7 + (Weekday - 3))
      );
  } else if (firstDay === 4) {
      addDay = addDay.setDate(
          addDay.getDate() + ((weekNum + add) * 7 + (Weekday - 4))
      );
  } else if (firstDay === 5) {
      addDay = addDay.setDate(
          addDay.getDate() + ((weekNum + add) * 7 + (Weekday - 5))
      );
  } else if (firstDay === 6) {
      addDay = addDay.setDate(
          addDay.getDate() + ((weekNum + add) * 7 + (Weekday - 6))
      );
  }

  return addDay
}

export const manageNegativeValue = (params: number) => {
    if(params < 0){
        return 0;
    }else{
        return params;
    }
}

export const SetPrices = (prices:any,result:any) =>{
    const newPrices = {
      ...prices,
    };
    console.log('result.data.data ::', result.data.data);
    
    result.data.data.filter((e: any) => e.id !='price_1HYfj8F82xNHmh6PBsAsdgBb')?.forEach((price: any) => {      
        if (price.product === config.products.standard) {
            newPrices.standard = price.unit_amount / 100;
        }
        if (price.product === config.products.expedited) {
            newPrices.expedited = price.unit_amount / 100;
        }
  
        if (price.product === config.products.rapid) {
            newPrices.rapid = price.unit_amount / 100;
        }
        if (price.product === config.products.standard_INT) {
            newPrices.standard_INT = price.unit_amount / 100;
        }
  
        if (price.product === config.products.expedited_INT) {
            newPrices.expedited_INT = price.unit_amount / 100;
        }
  
        if (price.product === config.products.rapid_INT) {
            newPrices.rapid_INT = price.unit_amount / 100;
        }
        if (price.product === config.products.antigen) {
            newPrices.antigen = price.unit_amount / 100;
        }
  
        if (price.product === config.products.antigen_INT) {
            newPrices.antigen_INT = price.unit_amount / 100;
        }
    });
    console.log('newPrices ::', newPrices);
    
    return newPrices;
  }