import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { Breakpoints } from '../../../../dictionaries';
import { Form, Formik } from 'formik';
import Input from '../../../shared/components/form/Input';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { useSharedDispatch, useSharedState } from '../../../shared/provider';
import { useRegisterStepsDispatch, useStepsState } from '../../provider';
import Modal from './../../../shared/components/Modal';
import { Button } from './../../../shared/components/styled';
import { colors } from '../../../../styles/colors';
import { updateRegister } from '../../api';
import FormLabel from '../../../shared/components/form/FormLabel';
import { config } from '../../../../config';
import { DatepickerField } from '../../../shared/components/Datepicker';
import { differenceInYears, format, parse } from 'date-fns';

const Content = styled.div`
  width: 100%;

  @media (min-width: ${Breakpoints.md}px) {
    width: 540px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 240px;
  margin: 0 -5px;

  & > * {
    margin: 0 5px;
  }
  @media (max-width: ${Breakpoints.sm}px) {
    display: grid;
    row-gap: 10px;
    margin-top: 30px;
  }
`;

const Title = styled.h2`
  color: ${colors.fullBlack};
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 25px;
`;
const Container = styled.div``;
const InputRow = styled.div`
  display: flex;
  flex-direction: column;
  margin: -8px 0;

  @media (min-width: ${Breakpoints.sm}px) {
    flex-direction: row;
    margin: 0 -8px;
  }

  @media (min-width: ${Breakpoints.lg}px) {
    margin: 0 -15px;
  }

  & > * {
    flex-basis: 100%;
    margin: 8px 0;

    @media (min-width: ${Breakpoints.sm}px) {
      margin: 0 8px;
    }

    @media (min-width: ${Breakpoints.lg}px) {
      margin: 0 15px;
    }
  }
`;
type Props = {
  open: boolean;
  handlePhoneClose: () => void;
  isProfile: boolean;
};
const ChangePhoneModal: React.FC<Props> = ({
  handlePhoneClose,
  open,
  isProfile,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { userData } = useSharedState();
  const { setloggedUserData } = useSharedDispatch();
  const {
    updateFormValues,
    goToNextStep,
    goToPrevStep,
  } = useRegisterStepsDispatch();

  const {
    form: { email, phone },
  } = useStepsState();
  const phoneNumber: any = phone === "" ? "+1" : phone;

  const updateChangeOnClick = async (values: any) => {
    setLoading(true);
    if (isProfile) {
      let resultdata = await updateRegister({
        email: userData?.email,
        phone: values?.phone,
      });
      if (resultdata?.data?.status === 200) {
        setloggedUserData(resultdata?.data?.data);
        localStorage.setItem('user', JSON.stringify(userData));
      }
    }
    handlePhoneClose();
    setLoading(false);
  };

  return (
    <Modal open={open} onClose={handlePhoneClose} maxWidth={400}>
      <Container>
        <Title>Enter your information</Title>
        <Formik
          initialValues={{
            phone: isProfile ? userData?.phone : phone,
          }}
          onSubmit={(values) => {
            updateFormValues(values);
            updateChangeOnClick(values);
          }}
        >
          {({ setFieldValue }) => {
            return (
              <Form>
                <Input
                  name="phone"
                  value={phoneNumber}
                  onChange={(value) => {
                    updateFormValues({ phone: value });
                  }}
                  validate={(value) => {
                    let error;

                    if (value === '') {
                      error = 'Required field';
                    }
                     let valueArr=value.split("")
                     if(!valueArr.includes("+")){
                      error="Please include country code"
                    }

                    if(valueArr.includes(" ") || valueArr.includes("-") || valueArr.includes("(") || valueArr.includes(")") ){
                      error = "Please avoid special characters and spaces.(Valid format is:3239205839)"
                    }

                    return error;
                  }}
                />
                <ButtonsContainer>
                  <Button
                    libraryType="default"
                    size="sm"
                    type="button"
                    onClick={() => {
                      handlePhoneClose();
                    }}
                    className='btn elevated-btn'
                  >
                    Cancel
                  </Button>
                  <Button libraryType="primary" size="sm" type="submit" className='btn fill-btn'>
                    {loading ? 'Processing...' : 'Confirm'}{' '}
                  </Button>
                </ButtonsContainer>
              </Form>
            );
          }}
        </Formik>
      </Container>
    </Modal>
  );
};

export default ChangePhoneModal;
