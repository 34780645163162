import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Form, Formik } from 'formik';
import hallogo from '../../../assets/hal_logo.png';
import {
  PageHeader,
  SubHeaderDescription, LogoCard, LogoImage
} from '../../shared/components/styled';
import { colors } from '../../../styles/colors';
import Input from '../../shared/components/form/Input';
import { Breakpoints } from '../../../dictionaries';
import wsllogo from '../../../assets/WLABS_CMYK.png';
import { useHistory, useParams } from 'react-router-dom';
  import { Button } from '../../shared/components/styled';
import Header from '../../shared/components/Header';
import { useStepsDispatch, useStepsState } from '../../schedule/provider';
import FooterRegister from './FooterRegister';
import { registerLogin, adminLogin, getCpartnerLogo } from '../api';
import { useSharedDispatch, useSharedState } from '../../shared/provider';
import { useRegisterStepsDispatch } from '../provider';
import Loader from '../../shared/components/Loader';
import Footer from '../../shared/components/Footer';
import ConfirmAccount from './ConfirmAccount';
import mixpanel from 'mixpanel-browser';
import { config } from '../../../config';
import circleRectAngle from '../../../assets/circle-rectangle-dark-blue.png';
import circleRectAngleBottom from '../../../assets/circle-rectangle-dark-blue-bottom.png';
import wslLogo from '../../../assets/WSL_Color_Horizontal.jpg';
import wLogo from '../../../assets/wsl-w-only.png';
export const WLLogo = styled.div`
  height: 60px;
  width: 270px;
  background: url(${wsllogo}) no-repeat center center;
  background-size: cover;
  margin-bottom: 15px;
  display: inline-block;

  @media (max-width: ${Breakpoints.sm}px) {
    margin-top: 20px;
  }
`;

const HALLogo = styled.div`
  height: 75px;
  width: 275px;
  background: url(${hallogo}) no-repeat center center;
  background-size: cover;
  display: inline-block;
  margin: 0px 0px 10px 0px;
  cursor: pointer;
  @media (max-width: ${Breakpoints.sm}px) {
    height: 70px;
    width: 220px;
    margin-top: 5%;
  }
`;

const SubText = styled.div`
  font-family: Open Sans;
  margin: 5px 0px 15px 0px;
  font-size: 16px;
  font-weight: 700;
  color: #243D4D;
  cursor: pointer;
`;
const Space = styled.div`
  @media (max-width: ${Breakpoints.sm}px) {
    margin-top: 30px;
  }
`;

const Error = styled.div`
  color: ${colors.red};
  margin-top: 15px;
  text-align: center;
`;
export const PageTitle = styled.h2`
  color:  ${colors.fullBlack};
  font-size: '28px'} ;
  font-weight: 700;
  margin: 0;
  text-align: center;
  @media (max-width: ${Breakpoints.sm}px) {
    font-size: 21px;
    text-align: center;
  }

`;
export const MainContainer = styled.div`
    position: absolute;
    margin-top: 4%;
    z-index: 6;
    @media (max-width: ${Breakpoints.sm}px) {
        margin-top: 0%;
    }
`;
const Content = styled.div`
  @media (min-width: ${Breakpoints.md}) {
    padding: 88px 0;
  }
  @media (max-width: ${Breakpoints.lg}px) {
    padding: 0px 0px 10px 0px !important;
    
  }
`;
export const Div = styled.div`
    position: absolute;
    bottom: 0px;
    width: 100vw;
    height: 92.2vh;
    overflow-x: hidden;
    overflow-y: scroll;
    @media (max-width: ${Breakpoints.xl}px) {
        overflow-x: hidden;
        overflow-y: scroll;
    }
`
export const FooterDiv = styled.footer`
    position: fixed;
    width: 100vw;
    z-index: 11111;
    bottom: 0px;
    background-color: rgb(36, 61, 77);
    height: 40px;
    @media (max-width: 960px) {
        height: 40px;
        position: fixed;
    }
    @media (max-width: 600px) {
        height: 70px;
        position: fixed;
    }
`
export const Stripe = styled.div`
    position: absolute;
    width: 100vw;
    height: 6.5%;
    background-color: rgb(97, 156, 138);
`;
interface ContainerProps {
    size?: 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
    noPadding?: boolean;
    marginTop?: boolean;
    insurance?:boolean;
  }
export const Container = styled.div`
    margin: 0 auto;
    min-height: 350px;
    background-color: rgb(255, 255, 255);
    border: 0.5px solid rgba(36, 61, 77, 0.14);
    box-shadow: rgb(153 153 153 / 5%) -4px -4px 8px, rgb(0 0 0 / 15%) 4px 4px 8px;
    border-radius: 12px;
    max-width: ${({ size }: ContainerProps) => {
        if (size === 'md') {
        return '760px';
        }

        if (size === 'lg') {
        return '920px';
        }

        if (size === 'xl') {
        return '1110px';
        }

        if (size === 'xxl') {
        return '1400px';
        }

        return '650px';
    }};
    margin-top: ${({ marginTop }: ContainerProps) => (marginTop ? '40px' : '80px')};
    padding: ${({ noPadding }: ContainerProps) => (noPadding ? '0px' : '0 15px')};
    @media (max-width: ${Breakpoints.xl}px) {
        border:none;
        box-shadow:none;
        border-radius:none;
    }
    @media (min-width: ${Breakpoints.sm}px) and (max-width: ${Breakpoints.md}px)  {
        margin-left:${({ insurance }: ContainerProps) => (insurance ? '5%' : '')};
    }
`

export const Background = styled.div`
    background-image: url(${circleRectAngle});
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: 1;
    background-repeat: no-repeat;
    background-size: 55% 140%;
    background-position: right;
    // background-attachment: fixed;

    @media (max-width: ${Breakpoints.xl}px) {
        background-position: bottom;
        background-image: url(${circleRectAngleBottom});
        background-size: 100% 15%;
        overflow-x: hidden;
        overflow-y: scroll;
    }
    @media (max-width: ${Breakpoints.md}px) {
        background-size: 107% 15%;
        height:130vh;
    }
    @media (max-width: ${Breakpoints.sm}px) {
        background-size: 130% 15%
        height:130vh;
    }
`;
export  const WLogoImage = styled.img`
    @media (max-width: ${Breakpoints.md}px) {
        display: none
    }
`;
const SignIn: React.FC = () => {
  let history = useHistory();
  const {
    airline,
    airlineDetails,
    travellType,
    destination,
    vaccineType,
    isVaccinate,
    reasonType,
    partnerLogo:{logo},
    tenant,
    isWalkUp,
    locations,
    isUpgrade
  } = useSharedState();
  const [error, setError] = useState<string | null>(null);
  const [upgradeParams, setUpgradeParams] = useState<any>();

  const {
    setloggedUserData,
    updateAirlineDetails,
    setIsVerifyFlow,
    updateapptFromSchdule,
    setTestSelectionFlow,
    updateUpgradeTest,
    updatePartnerLogo
  } = useSharedDispatch();
  const [isVerifyScreenMsg, setVerifyScreenMsg] = useState(false);
  const { updateFormValues, goToNextStep } = useRegisterStepsDispatch();
  const params = useParams<any>();
  const updateParams = async () => {
    localStorage.setItem('appointmentId', upgradeParams?.appointmentId);
    localStorage.setItem('upgradeTestType', upgradeParams?.testType);
    localStorage.setItem('docId', upgradeParams?.docId);
  };

  const redirect = async() => {
    if (isUpgrade) {
        await updateParams();
        history.push('/reconfirmation');
    }
    else if(isWalkUp.active)
        history.push('/bookappointment/walkup/'+isWalkUp.locationId)
    else
        history.push('/user/profile')
  };
//   sfo flow goes fixed
  const sfoTenant: boolean = tenant  ? false : true;
  const withoutLoginRedirect = () => {
    if (
      (airline && destination) ||
      (isVaccinate && reasonType) ||
      vaccineType
    ) {
      updateapptFromSchdule(true);
      setTestSelectionFlow(false);
    } else {
      updateapptFromSchdule(true);
      setTestSelectionFlow(true);
    }
    if(isWalkUp.active)
        history.push('/bookappointment/walkup/'+isWalkUp.locationId);
    else
        history.push('/bookappointment');
  };

  const { userData } = useSharedState();

  if (userData?.isVerified) {
    redirect();
  }

  const [loading, setLoading] = useState(false);
  const [accloading, setAccLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [lowerCase, setLowerCase] = useState(false)

  let {
    form,
  } = useStepsState();
  let {email, password, cPartnerID} = form
  const fetchCpartnerlogo = async (params: string ) => {
    try {
      //const input = params?.length ? params : 'WSL001'
      // const input =  params === 'SSFSD' ? 'SSF001' : params === 'BRSSD' ? 'BEL001' : params === 'SUHSD' ? 'SEQ001' : params === 'COM001' ? 'COM001':'WSL001' ;
      const data = await getCpartnerLogo(params);
      if (data?.data?.status === 200) {
          updatePartnerLogo({
            logo: data?.data?.data.logo,
            logoHeader: data?.data?.data.logoHeader 
          })
        }
    } catch (e) {
      console.log(e);
    }
  };

  const handleSignIn = async (params: { email: string; password: string }) => {
    setLoading(true);

    try {
      const loggedUser = await registerLogin(params);
      if (loggedUser?.data?.status == 200) {
        setloggedUserData(loggedUser?.data.data);
        localStorage.setItem('user', JSON.stringify(loggedUser?.data.data));
        redirect();
      } else {
        if (
          loggedUser?.data?.message === 'Please verify your account to login.'
        ) {
            let email:any = loggedUser?.data?.data?.email;
            if (loggedUser?.data?.data?.newEmail){
                email = loggedUser?.data?.data?.newEmail ==='' ? loggedUser?.data?.data?.email : loggedUser?.data?.data?.newEmail;
            }
            updateFormValues({
                email: email,
                phone: loggedUser?.data?.data?.phone,
            });
          setError(loggedUser?.data?.message);
          setVerifyScreenMsg(true);
          setIsVerifyFlow(true);
        } else {
          setError(loggedUser?.data?.message);
        }
      }
      setLoading(false);
    } catch (e) {
      setError('Incorrect email or password');
      setLoading(false);
    }
  };

  //const params = useParams<any>();

  const check = async () => {
    setLoading(true);
    if (params.data) {
      const data = String(params.data).split(':');
      if (data.length === 3) {
        updateUpgradeTest(true);
        setUpgradeParams({
          appointmentId: data[0].replace('+', ' '),
          testType: data[1],
          docId: data[2],
        });
        //console.log(data);
      }
      setLoading(false);
    } else {
      localStorage.setItem('user', '');
      const data = String(params.mail).split('-');
      if (params) {
        const param = {
          id: data[0],
        };
        const loggedUser = await adminLogin(param);
        if (loggedUser?.data?.status == 200) {
          console.log('record', loggedUser?.data?.data);
          setloggedUserData(loggedUser?.data.data);
          localStorage.setItem('user', JSON.stringify(loggedUser?.data?.data));
          localStorage.setItem('isAdmin', JSON.stringify(true));
          redirect();
          setLoading(false);
        } else {
          if (isWalkUp.active)
            history.push('/bookappointment/walkup/' + isWalkUp.locationId);
          else history.push('/signin');
        }
      }
    }
    setLoading(false);
  };
  useEffect(() => {
      if (!!upgradeParams) updateParams();
  }, [upgradeParams]);

  useEffect( () => {
    if(isWalkUp.active ||(params.mail !== undefined && params.mail !== null) || params.data !== undefined)
    check();
    if(params.locationId===((config.qbenchCustomerId.KernHealthSystems).toString())){
       fetchCpartnerlogo('KEN001');
              updateFormValues({cPartnerID : 'KEN001',testSelection: 'kern'})
          }
      },[])


  if (loader) {
    return <Loader />;
  }
  return (
    <>
    <Header withoutBg isHelp />
    <Div>
    <Stripe />
    <Background>
        <div style={{display:'flex', justifyContent:'center'}}>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    height: '100vh',
                    width: '100vh',
                    transform: 'translate(-46%)',
                    zIndex: 0,
                }}
                >
                <WLogoImage src={wLogo} alt="" />
            </div>
            <MainContainer>
                <Container>
                    <Content
                    style={{ textAlign: 'center', padding: '10px 30px 30px 30px' }}
                    >
                    <>
                        <LogoCard>
                        <LogoImage
                            src={wslLogo.length ? wslLogo : ''}
                            height={'90px'}
                        />
                        </LogoCard>
                        <PageHeader>
                        <PageTitle>Let’s Get You Booked!</PageTitle>
                        </PageHeader>
                        <SubHeaderDescription style={{ padding: '0px 54px' }}>
                        You can login if you already have a Worksite Labs account to
                        help expedite the booking process. If you don’t have an
                        account, select “I don’t have an account” to book your
                        appointment.
                        </SubHeaderDescription>

                        <Formik
                        initialValues={{
                            email: email,
                            password: password,
                        }}
                        onSubmit={(values) => {
                            setVerifyScreenMsg(false);
                            handleSignIn({
                            email: values.email.toLowerCase().trim(),
                            password: values.password,
                            });
                            updateFormValues({
                            email: values.email.toLowerCase().trim(),
                            password: values.password,
                            });
                            // handleAppointmentList();
                        }}
                        enableReinitialize={false}
                        >
                        {() => (
                            <Form style={{ width: '80%', display: 'inline-block' }}>
                            <Input
                                name="email"
                                lowerCase={lowerCase}
                                placeholder="Email Address"
                                isRequired
                                onChange={(value) => {
                                setLowerCase(value !== '' ? true : false);
                                updateFormValues({
                                    email: value.trim().toLowerCase(),
                                });
                                }}
                            />
                            <Space />
                            <Input
                                name="password"
                                placeholder="Password"
                                type="password"
                                isRequired
                                onChange={(value) => (password = value)}
                            />
                            <Space />
                            <SubText
                                onClick={() => {
                                history.push('/forgotpassword');
                                }}
                                className='underline'
                            >
                                Forgot Email or Password?
                            </SubText>
                            <Button
                                libraryType="primary"
                                type="submit"
                                disabled={false}
                                className="btn fill-btn"
                            >
                                {loading ? 'Processing...' : 'LOGIN'}
                            </Button>
                            <Space />
                            {/* {(airline !== "" && destination !== "" && travelType !== "") && */}
                            <Button
                                libraryType="primary"
                                type="button"
                                disabled={false}
                                className="outline1-btn"
                                onClick={() => {
                                if (!(isWalkUp.active && !locations)) {
                                    setAccLoading(true);
                                    withoutLoginRedirect();
                                }
                                }}
                            >
                                {accloading || (isWalkUp.active && !locations)
                                ? 'Processing...'
                                : "I DON'T HAVE AN ACCOUNT"}
                            </Button>
                            {/* } */}

                            {error && (
                                <Error>
                                {error}
                                <br />
                                {isVerifyScreenMsg && (
                                    <a
                                    onClick={() => {
                                        goToNextStep();
                                    }}
                                    >
                                    Click here
                                    </a>
                                )}{' '}
                                {isVerifyScreenMsg && 'to verify your account.'}
                                </Error>
                            )}
                            </Form>
                        )}
                        </Formik>

                        <SubHeaderDescription
                        style={{ fontSize: '12px', marginTop: '1px' }}
                        >
                        New to Worksite Labs? Sign up for your account below.
                        </SubHeaderDescription>

                        <div>
                        <SubText
                            style={{ marginBottom: '20px' }}
                            className='underline'
                            onClick={() => {
                            history.push('/register');
                            }}
                        >
                            SIGN UP
                        </SubText>
                        </div>
                    </>
                    </Content>
                </Container>
            </MainContainer>
        </div>
    </Background>
    <FooterDiv>
        <Footer />
    </FooterDiv>
</Div>
    </>
  );
};

export default SignIn;

//ltst
