import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { Breakpoints } from '../../../../dictionaries';
import { Form, Formik } from 'formik';
import Input from '../../../shared/components/form/Input';
import { useSharedDispatch, useSharedState } from '../../../shared/provider';
import { useStepsDispatch, useStepsState } from '../../provider';
import Modal from './../../../shared/components/Modal';
import { Button } from './../../../shared/components/styled';
import { colors } from '../../../../styles/colors';
import { updateRegister } from '../../../register/api';
import FormLabel from '../../../shared/components/form/FormLabel';
import ZipCodeInput from '../../../register/components/Address/ZipCodeInput';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { makeStyles } from '@material-ui/core/styles';
import Select from '../../../shared/components/form/Select';
import { states } from '../Address/states';
import { Grid } from '@material-ui/core';

const Content = styled.div`
  width: 100%;

  @media (min-width: ${Breakpoints.md}px) {
    width: 540px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 240px;
  margin: 0 -5px;

  & > * {
    margin: 0 5px;
  }
  @media (max-width: ${Breakpoints.sm}px) {
      display: grid;
      row-gap: 10px;
  }
    `;

const Title = styled.h2`
  color: ${colors.fullBlack};
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 25px;
`;
const Container = styled.div`
`;

const CityStateRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > *:first-child {
	width: 100%;
  }

  & > *:last-child {
	width: 100%;
  }
  // @media (max-width: ${Breakpoints.sm}px) {
	// & > *:last-child {
	//   margin-top: 35px;
  // }
  @media (max-width: ${Breakpoints.lg}px) {
    & > *:last-child {
      width: 100%;
    }
    & > *:first-child {
      width: 100%;
      }
    }
`;
const Confirmbtn = styled.button`
  width: 25%;
  border: 0;
  align-items: center;
  text-align: center;
  border: 1px solid #2a5f87;
  line-height: 2.5;
  padding: 5px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  display: inline-block;
  background-color: #2a5f87;
  color: #ffff;
  @media (max-width: ${Breakpoints.sm}px) {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: auto;
    padding: 5px;
    margin: 0;
  }
`;
const Returnbtn = styled.div`
  width: 25%;
  border: 0;
  align-items: center;
  text-align: center;
  border: 1px solid #2a5f87;
  line-height: 2.5;
  padding: 5px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  display: inline-block;
  background-color: #fff;
  color: #2a5f87;
  @media (max-width: ${Breakpoints.sm}px) {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: auto;
    padding: 5px;
    margin: 0;
  }
`;
const Buttons = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: flex-end;
`;

const useStyles = makeStyles({
  countryDropDown:{
    orderRadius: '10px',
    width: '100%',
    border: "1px solid #B4B4BB",
    boxShadow: "0px 0px 4px 0px #4749a0c7",
    fontSize: "16px",
    lineHeight: "24px",
    padding: "15px 28px",
    webkitTransition: "all 0.15s ease-in-out",
    transition: "all 0.15s ease-in-out",
  },
  regionDropDown: {
    borderRadius: '10px',
    width: '100%',
    border: "2px solid #619c8a",
    boxShadow: "0px 0px 4px 0px #4749a0c7",
    fontSize: "16px",
    lineHeight: "24px",
    padding: "13px 20px",
    webkitTransition: "all 0.15s ease-in-out",
    transition: "all 0.15s ease-in-out"
  }

})
type Props = {
    open: boolean;
    handleAddressClose: ()=> void,
    isProfile: boolean;
}
const ChangeAddressModal: React.FC<Props>= ( { handleAddressClose, open, isProfile }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    let {
      form,
      form:{
        address
      }
    } = useStepsState();
    // const address:any =orm.address;
    const {userData} = useSharedState();
    const {setloggedUserData} = useSharedDispatch();
    const { updateFormValues, goToNextStep, goToPrevStep } = useStepsDispatch();
  
    const updateAddressValue = (newValue: any) => {
        updateFormValues({
          address: {
            ...address,
            ...newValue,
          },
        });
      }
    
      const updateChangeOnClick = async(values: any) => {
        setLoading(true);
        if (isProfile) {
          let resultdata = await updateRegister({email: userData?.email, address: values?.address});
          if(resultdata?.data?.status === 200) {
            setloggedUserData(resultdata?.data?.data);
            localStorage.setItem('user', JSON.stringify(userData));
          }
        }
        handleAddressClose();
        setLoading(false);
      }
      const classes = useStyles();
    return (
        <Modal open={open} onClose={handleAddressClose} maxWidth={800}>
            <Container>
            <Title>Enter your information</Title>
            <Formik
          enableReinitialize
          initialValues={{
            address
          }}
          onSubmit={(values) => {

            updateFormValues({
              address: {
                ...values.address,
                state: values.address.state || null,
                country: values.address.country || null,
              },
            });

            updateChangeOnClick({
              address: {
                ...values.address,
                state: values.address.state || null,
                country: values.address.country || null,
              },
            });
            
          }}
        >
      
          {() => (
            <Form>
               <ZipCodeInput
                updateCountyValue={updateAddressValue}
                updateAddressValue={updateAddressValue}
                country={address.country}
                state={address.state}
              />
              <FormLabel label="Country" isRequired>
                
                <CountryDropdown
                 classes={classes.countryDropDown}
                  value={address.country}
                  name="address.country"  
                    // defaultOptionLabel="United States"
                  onChange={(value) => updateAddressValue({ country:value, state: '' })}
                />
                
              </FormLabel>

              <FormLabel label="Street address" isRequired>
                <Input
                value={address.address}
                  onChange={(value) => updateAddressValue({ address: value })}
                  name="address.address"
                  isRequired
                />
              </FormLabel>
              {console.log('address', address.country)}
              {address.country == 'United States' ? (
                                <CityStateRow>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} md={7} >
                                            <FormLabel label="City">
                                                <Input
                                                    value={address.city}
                                                    onChange={(value) => updateAddressValue({ city: value })}
                                                    name="address.city"
                                                    isRequired
                                                />
                                            </FormLabel>
                                        </Grid>

                                        <Grid item xs={12} md={5}>
                                            <FormLabel label="State" isRequired>
                                                <RegionDropdown
                                                    classes={classes.regionDropDown}
                                                    name="address.state"
                                                    country={address.country}
                                                    value={address.state}
                                                    valueType="short"
                                                    onChange={(value) => updateAddressValue({ state: value })}
                                                />
                                                {/* {!form.address.state && (<span style={{color:"red", fontSize:"13px"}}>Required field</span>)} */}
                                            </FormLabel>
                                        </Grid>
                                    </Grid>
                                </CityStateRow>
                            ) : (
                                <CityStateRow>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} md={7} >
                                            <FormLabel label="City/Town" isRequired>
                                                <Input
                                                    value={address.city}
                                                    onChange={(value) => updateAddressValue({ city: value })}
                                                    name="address.city"
                                                    isRequired
                                                />
                                            </FormLabel>
                                        </Grid>

                                        <Grid item xs={12} md={5}>
                                            <FormLabel label="State/Province/Region" >
                                                <div style={{ width: '50px', height: '80px' }}>
                                                    <RegionDropdown
                                                        classes={classes.regionDropDown}
                                                        name="address.state"
                                                        country={address.country}
                                                        value={address.state}
                                                        valueType="short"
                                                        onChange={(value) => updateAddressValue({ state: value })}
                                                    />
                                                    {!form.address.state && (<span style={{ color: "red", fontSize: "13px" }}>Required field</span>)}
                                                </div>
                                            </FormLabel>
                                        </Grid>
                                    </Grid>
                                </CityStateRow>
              )}

              <ButtonsContainer>
                <Button
                  libraryType="default"
                  size="sm"
                  type="button"
                  onClick={() => {
                    handleAddressClose();
                    }}
                    className='btn elevated-btn'
                >
                  Cancel
                </Button>
                <Button libraryType="primary" size="sm" type="submit"  disabled={
                    form.address.country == 'United States'
                        ? form.address.state !== undefined && form.address.state !== null && form.address.state !== ""
                            ? false
                            : true
                        : false
                } className='btn fill-btn'>
                {loading ? 'Processing...' : 'Confirm'}                </Button>
              </ButtonsContainer>
            </Form>
          )}
        </Formik>
          </Container>
        </Modal>
    );
  };
  
  export default ChangeAddressModal;
  