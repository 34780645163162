import React, { useState } from 'react';
import styled from 'styled-components';
import { Form, Formik } from 'formik';

import {
  Container,
  Content,
  PageHeader,
  SubHeaderDescription,
  PageTitle,
} from '../../shared/components/styled';
import { colors } from '../../../styles/colors';
import { useHistory } from 'react-router-dom';

import Input from '../../shared/components/form/Input';
import Footer from '../../shared/components/Footer';
import { Breakpoints } from '../../../dictionaries';
import wsllogo from '../../../assets/WLABS_CMYK.png';
import hallogo from '../../../assets/hal_logo.png';
import orglogo from '../../../assets/org_logo.png';
import Publiclogo from '../../../assets/public_logo.png';
import groublogo from '../../../assets/groub_logo.png';
import { NonFormikCheckbox as Checkbox } from '../../shared/components/form/Checkbox';
import { initiateOTP } from '../../manage/api';
import { MultiTenancyService } from '../../../services/mutitenancy.service';
import { updateRegister } from '../api';
import { PrimeContainer } from './ConfirmAccount';
import { useStepsState, useStepsDispatch } from '../../schedule/provider';
import { useSharedDispatch, useSharedState } from '../../shared/provider';

const Error = styled.div`
  color: ${colors.red};
  margin-top: 15px;
  text-align: center;
`;

export const Logos = styled.div`
  display: flex;
`;

export const WLLogo = styled.div`
  height: 18px;
  width: 118px;
  background: url(${wsllogo}) no-repeat center center;
  background-size: cover;
  margin-right: 20px;
  display: inline-block;

  @media (min-width: ${Breakpoints.sm}px) {
    height: 70px;
    width: 300px;
  }
`;

export const Logos1 = styled.div`
  position: absolute;
  width: 601px;
  height: 161px;
  right: 432px;
  bottom: 518px;

  border: 1px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15));
  border-radius: 10px;
`;

export const Haldiv = styled.div`
  position: absolute;
  width: 120px;
  height: 120px;
  left: 616px;
  top: 408px;

  border: 1px solid rgb(235 227 227 / 50%);
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
  border-radius: 10px;
`;

export const HALLogo = styled.div`
  height: 18px;
  width: 118px;
  background: url(${hallogo}) no-repeat center center;
  background-size: cover;
  display: inline-block;
  margin: -20px 0px 20px 0px;

  @media (min-width: ${Breakpoints.sm}px) {
    height: 100px;
    width: 300px;
  }
`;
export const Text = styled.div`
  position: absolute;
  width: 521px;
  height: 44px;
  left: 447px;
  top: 180px;

  font-family: Open Sans;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 44px;
  text-align: center;
  padding-right: 68px;
  color: #000000;
`;

export const SubText = styled.div`
  position: absolute;
  width: 460px;
  height: 39px;
  left: 472px;
  top: 220px;

  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  /* or 137% */
  text-align: center;
  padding-right: 30px;

  color: ${colors.black};
`;

export const Orglogo = styled.div`
  height: 18px;
  width: 118px;
  background: url(${orglogo}) no-repeat center center;
  background-size: cover;
  display: inline-block;
  @media (min-width: ${Breakpoints.sm}px) {
    height: 80px;
    width: 85px;
  }
`;

export const CategoryContentBox = styled.div`
  flex: 0.3 1 0%;
  justify-content: center;
  border-radius: 10px;
  border: 2px solid ${colors.lightGrey};
  box-sizing: border-box;
  padding: 10px 0px 10px 0px;
  margin: 0px 5px 0px 5px;
  cursor: pointer;
`;

export const PublicLogo = styled.div`
  height: 18px;
  width: 118px;
  background: url(${Publiclogo}) no-repeat center center;
  background-size: cover;
  display: inline-block;
  @media (min-width: ${Breakpoints.sm}px) {
    height: 80px;
    width: 85px;
  }
`;

const CheckboxWrapper = styled.div`
  text-align: left;
`;

export const CategoyTitle = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 18px;

  color: #2193d2;
  margin-top: 3px;
`;

const CTAButton = styled.button`
  display: block;
  width: 100%;
  color: ${colors.white};
  text-align: center;
  padding: 15px;
  background: ${colors.orange};
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  border: 0;
  font-size: 20px;
  line-height: 36px;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
`;

const ContinueButton = styled.button`
  width: 40%;
  color: ${colors.white};
  text-align: center;
  padding: 15px;
  background: ${colors.orange};
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  border: 0;
  font-size: 20px;
  line-height: 15px;
  border-radius: 32px;
  font-weight: bold;
  cursor: pointer;
`;

const Hide = styled.div`
  color: ${colors.green};
  margin-top: 5px;
  font-size: 13px;
  line-height: 15px;

  font-weight: bold;
  cursor: none;
`;

const VerifyPhoneWthOutReg: React.FC = () => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const {
    form: { phone, email, firstName },
  } = useStepsState();
  let history = useHistory();
  const { updateFormValues, goToNextStep, goToPrevStep } = useStepsDispatch();
  const [isLoginScreen, setLoginScreen] = useState(false);
  const multiTenancyService: MultiTenancyService = new MultiTenancyService();
  const { setloggedUserData, updateUserVerify } = useSharedDispatch();
  const { userData } = useSharedState();

  const [res, setRes] = useState(false);
  const hide = () => {
    setTimeout(() => {
      setRes(false);
    }, 5000);
  };

  const splitPhoneNumber = () => {
    return phone.substring(0, 2) + 'xxxxxxx' + phone.slice(-2);
  };

  const validateOTP = async (otp: string) => {
    try {
      setLoading(true);
      const verifyOTP = await multiTenancyService.validateOTP({
        otp,
        email: '',
        phone,
      });
      if (verifyOTP.result.status === 200) {
       let resultdata = await updateRegister({ email, isVerified: true });
        if (resultdata?.data?.status === 200) {
          updateUserVerify(true);
          setloggedUserData(resultdata?.data?.data);
          localStorage.setItem('user', JSON.stringify(resultdata?.data?.data));
        goToNextStep();
        }
      } else {
        setLoading(false);
        setError('Incorrect OTP');
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError('Server Error');
    }
  };

  const otpResend = () => {
    const otpResponse = multiTenancyService.intiateOTP({
      email: '',
      phone: phone,
      isUserOTP: true,
    }).then((res) => {
      setRes(res?.data?.appointments === 1 ? true : false);
      hide();
    });
  }

  return (
    <div>
      {/* <Header isAdmin /> */}
      <PrimeContainer>
        <Container style={{ minHeight: '350px', backgroundColor: '#fff' }}>
          <Content style={{ padding: '30px 50px' }}>
            <>
              <PageHeader style={{ fontSize: '8px' }}>
                <PageTitle
                  color={colors.fullBlack}
                  textAlign="left"
                  fontsize={'28px'}
                >
                  {`A text message with your
                            code has been sent to the number ` +
                    splitPhoneNumber()}
                </PageTitle>
              </PageHeader>
              <SubHeaderDescription
                style={{ fontSize: '14px', marginBottom: '20px' }}
              >
                <span style={{ textTransform: 'capitalize' }}>{firstName}</span>, please enter the verification code below to confirm your account. Your verification code
                will expire in <strong>20 minutes</strong>
              </SubHeaderDescription>

              <Formik
                initialValues={{
                  otp: '',
                }}
                onSubmit={(values) => {
                  validateOTP(values.otp);
                }}
                enableReinitialize={false}
              >
                {() => (
                  <Form>
                    <Input
                      name="otp"
                      placeholder="OTP"
                      validate={(value: string) => {
                        let error;
                        if (!value) {
                          error = "OTP field can't be empty";
                        }
                        return error;
                      }}
                      isRequired
                    />
                    <CheckboxWrapper style={{ fontSize: '13px' }}>
                      <Checkbox name={''} checked={true}>
                        Remember this device? Please do not check this box if
                        you are using a public computer.
                      </Checkbox>
                    </CheckboxWrapper>
                    {error && <Error>{error}</Error>}
                    <div style={{ textAlign: 'center' }}>
                      <ContinueButton type="submit" className='btn fill-btn'>
                        {loading ? 'Processing...' : 'Verify'}
                      </ContinueButton>
                    </div>
                  </Form>
                )}
              </Formik>
              <SubHeaderDescription
                style={{ margin: '20px 0px 0px 10px', fontSize: '14px' }}
              >
                Haven't received your verification code?
                <br></br>
                <br></br>
                We send verification codes immediately, but many factors
                influence how fast you receive them. If you've waited more than
                20 minutes
                <a
                  onClick={() => otpResend()}
                >
                  {' '}
                  please request a new identification code{' '}
                </a>
                .
                {res && <Hide>Check your SMS for the verification code.</Hide>}
              </SubHeaderDescription>
            </>
          </Content>
        </Container>
        <div style={{ marginTop: '40px' }}>
          <Footer />
        </div>
      </PrimeContainer>
    </div>
  );
};

export default VerifyPhoneWthOutReg;
