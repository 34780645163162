import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  SubHeaderDescription,
  ErrorText,
  Button,
} from '../../../shared/components/styled';
import { Breakpoints } from '../../../../dictionaries';
import { useStepsDispatch, useStepsState } from '../../provider';
import { useSharedDispatch, useSharedState } from '../../../shared/provider';
import { TravellingType, TravellingTypeOnlyDOM } from './TravellingType';
import { Formik, Form } from 'formik';
import FormLabel from '../../../shared/components/form/FormLabel';
import Select from '../../../shared/components/form/Select';
import Checkbox from '../../../shared/components/form/Checkbox';
import { getAirlines, getCountry, getNpDestionation, getstate } from '../../api';
import { getCpartnerLogo } from '../../../register/api';
import { config } from '../../../../config';

const Text = styled.div`
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  margin-bottom: 5px;
`;

const SubContent = styled.p`
  text-align: left;
  @media (max-width: ${Breakpoints.sm}px) {
    padding: 0 !important;
  }
`;
const Disclaimer = styled.div`
font-size: 10px;
font-weight: 500;
text-align: left;
margin-bottom: 10px;
}
`;

const BtnContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  flex-wrap: nowrap;
  padding-top: 20px;

  @media (min-width: 450px) {
    flex-direction: row;
  }
`;
const Space = styled.div`
  height: 10px;
  width: 20px;
`;
const returnBtnStyle = {
  backgroundColor: '#FFFFFF',
  color: '#101010',
  border: '0.5px solid #FFFFFF',
  fontWeight: '600',
  fontSize: '14px',
};
const saveBtnStyle = {
  backgroundColor: '#FF802E',
  border: '1px solid #FF802E',
  fontWeight: '600',
  fontSize: '14px',
};
const FlightSelection: React.FC = () => {
  const { updateFormValues, goToNextStep, goToPrevStep } = useStepsDispatch();
  const { form } = useStepsState();
  const {
    testSelection,
    destination,
    commitToConfirm,
    airlineCode,
    travelType,
  } = form;
  const {travellType, district, isWalkUp, locations, testFlowSelectionDatas} = useSharedState();
  const [Airline, setAirline] = useState<{ value: string; label: string }[]>(
    testFlowSelectionDatas?.airLineData ??[]
  );
  const [country, setcountry] = useState<{ value: string; label: string }[]>(
    testFlowSelectionDatas?.country ?? []
  );
  const [state, setstate] = useState<{ value: string; label: string }[]>(testFlowSelectionDatas?.state ?? []);
  const {
    updateAirlineType,
    updateTravelType,
    updateDestination,
    updatePartnerLogo,
  } = useSharedDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [errormsg, setErrormsg] = useState<boolean>(false);
  const [npArray, setNpArray] = useState([]);

  let arrays: any = npArray;

  useEffect(() => {
    // fetchAirlines();
    // fetchcountry();
    // fetchstate();
    getDestination();
  }, []);

  const fetchAirlines = async () => {
    try {
      const data = await getAirlines();
      if (data?.data?.status === 200) {
        setAirline(data?.data?.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchcountry = async () => {
    try {
      const data = await getCountry();
      if (data?.data?.status === 200) {
        setcountry(
          data?.data?.data.filter((item: any) => item.value !== 'BRB')
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchstate = async () => {
    try {
      const data = await getstate();
      console.log('getCountry', data);
      if (data?.data?.status === 200) {
        setstate(data?.data?.data);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const getDestination = async () => {
    const arr: any = [];
    const response = await getNpDestionation({}).then((result: any) => {
      const data = result?.data?.data;
      data.forEach((element: any) => {
        arr.push(element.value);
      });
    });
    setNpArray(arr);
  };
  const [countries, setcountries] = useState(false);
  const [newprice, setnewprice] = useState(false);
  const fetchCpartnerlogo = async () => {
    try {
      setLoading(true);
      let params = testSelection === 'school' ?  district === 'SSFSD' ? 'SSF001' : district === 'BRSSD' ? 'BEL001' : district === 'SCSD' ? 'SCS001':'SEQ001' : 'WSL001'
      const data = await getCpartnerLogo(params);
      if (data?.data?.status === 200) {
        updatePartnerLogo({
          logo: data?.data?.data.logo,
          logoHeader: data?.data?.data.logoHeader,
        });
        setLoading(false);
        goToNextStep();
      }
    } catch (e) {
      console.log(e);
    }
  };
  const checkInternationalOrNot = React.useMemo(() => {
        
    if(isWalkUp.active ) {
        const location:any = locations?.filter(e => e.qbenchCustomerId === isWalkUp.locationId);
        return  location[0]?.isInternational?true:false
    } else {
        return true
    }
  }, [isWalkUp.active, isWalkUp.locationId, locations]);
  return (
    <SubContent>
      <Formik
        initialValues={{
          travelType: travelType,
          destination: destination,
          airlineCode: airlineCode,
          commitToConfirm,
        }}
        onSubmit={(values) => {
          updateFormValues({
           ...values,
            cPartnerID: testSelection === 'school' ? district === 'SSFSD' ? 'SSF001' : district === 'BRSSD' ? 'BEL001' : district === 'SCSD' ? 'SCS001' : 'SEQ001' : 'WSL001',
          });
          if (commitToConfirm) {
            fetchCpartnerlogo();
          } else {
            setErrormsg(true);
          }
        }}
      >
        {() => (
          <Form>
            <SubHeaderDescription style={{ marginBottom: '5px' }}>
              Are you traveling internationally or domestically?
              <span style={{ color: 'red' }}>*</span>
            </SubHeaderDescription>
            <FormLabel label="">
              <Select<string>
                name="travelType"
                options={checkInternationalOrNot?TravellingType:TravellingTypeOnlyDOM}
                onChange={(value: any) => {
                  updateFormValues({ travelType: value });
                  updateTravelType(value);
                  localStorage.setItem('travelType', value);
                }}
                validate={(value) => {
                  if (!value) {
                    return 'Required field';
                  }
                }}
              />
            </FormLabel>

            {travellType == 'DOM' && (
              <>
                <SubHeaderDescription style={{ marginBottom: '5px' }}>
                  What is your destination state?
                  <span style={{ color: 'red' }}>*</span>
                </SubHeaderDescription>
                <FormLabel label="">
                  <Select<string>
                    name="destination"
                    options={state}
                    onChange={(value: any) => {
                      updateFormValues({ destination: value });
                      updateDestination(value);
                    }}
                    validate={(value) => {
                      if (!value) {
                        return 'Required field';
                      }
                    }}
                  />
                </FormLabel>
              </>
            )}
            {travellType == 'INT' && (
              <>
                <SubHeaderDescription style={{ marginBottom: '5px' }}>
                  What is your destination country?
                  <span style={{ color: 'red' }}>*</span>
                </SubHeaderDescription>
                <FormLabel label="">
                  <Select<string>
                    name="destination"
                    options={country}
                    onChange={(value: any) => {
                      updateFormValues({ destination: value });
                      localStorage.setItem('destination', value);
                      updateDestination(value);
                      setcountries(arrays.includes(value));
                    }}
                    validate={(value) => {
                      if (!value) {
                        return 'Required field';
                      }
                    }}
                  />
                </FormLabel>
                {countries && (
                  <>
                    <Checkbox
                      name="otherPrices"
                      onChange={(checked) => {
                        updateFormValues({ higherCost: checked });
                        setnewprice(checked);
                      }}
                    >
                      <Text>
                        I understand that I have selected a country that
                        requires a nasopharyngeal collection for entry and the
                        pricing for this collection type is as follows:
                        Standard: $150; Express: $225; Rapid: $300.
                      </Text>
                    </Checkbox>
                  </>
                )}
              </>
            )}

            <SubHeaderDescription style={{ marginBottom: '5px' }}>
              What airline are you flying on?
              <span style={{ color: 'red' }}>*</span>
            </SubHeaderDescription>
            <FormLabel label="">
              <Select<string>
                name="airlineCode"
                options={Airline}
                onChange={(value: any) => {
                  updateAirlineType(value);
                  updateFormValues({ airlineCode: value });
                  localStorage.setItem('airline', value);
                }}
                validate={(value) => {
                  if (!value) {
                    return 'Required field';
                  }
                }}
              />
            </FormLabel>

            <Checkbox
              name="commitToConfirm"
              onChange={(checked) => {
                updateFormValues({ commitToConfirm: checked });
              }}
              children={
                <Text>
                  I understand that COVID-19 travel requirements and
                  restrictions change frequently and have confirmed my
                  destination state or country that travel is allowed and want
                  to continue with booking an appointment.
                  <span style={{ color: 'red' }}>*</span>
                </Text>
              }
            />
            {errormsg && <ErrorText hasError>{'Required Field'}</ErrorText>}
            
            <BtnContainer>
              <Button
                libraryType="default"
                type="button"
                style={returnBtnStyle}
               className='btn elevated-btn'
                onClick={() => goToPrevStep()}
              >
                Return
              </Button>
              <Space />

              <Button
                libraryType="primary"
                type="submit"
                style={saveBtnStyle}
                className='btn fill-btn'
                disabled={(countries && !newprice) || loading}
              >
                {loading ? 'Processing...' : 'Save & Continue'}
              </Button>
            </BtnContainer>
          </Form>
        )}
      </Formik>
    </SubContent>
  );
};

export default FlightSelection;
