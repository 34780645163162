import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FieldArray, useFormikContext } from 'formik';

import { Minor as IMinor } from '../../../shared/models';
import { colors } from '../../../../styles/colors';
import { ReactComponent as PlusIcon } from '../../../../assets/plus.svg';
import {
  useRegisterStepsDispatch,
  useStepsState as useRegisterStepsState,
} from '../../provider';
import { Breakpoints } from '../../../../dictionaries';

import { DatepickerField } from '../../../shared/components/Datepicker';
import Input from '../../../shared/components/form/Input';
import FormLabel from '../../../shared/components/form/FormLabel';
// import { differenceInMonths, differenceInYears } from 'date-fns';
import { ageCalculator } from '../../../../utils';
import { country } from '../../../schedule/components/PersonalInformation/PassportCountry';
import Select from '../../../shared/components/form/Select';
import { useSharedState } from '../../../shared/provider';
import { useStepsState } from '../../../schedule/provider';
import { CustomSwitch, SubHeaderDescription } from '../../../shared/components/styled';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from '@material-ui/core/styles';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import clsx from 'clsx';
import { IDropDown } from '../../models';
import { Switch } from '@material-ui/core';

const Container = styled.div`
  margin-top: 40px;
`;
export const ErrorText = styled.div`
  font-size: 13px;
  color: ${colors.red};
  opacity: ${({ hasError }: { hasError: boolean }) => (hasError ? 1 : 0)};
  transition: opacity 0.15s ease-in-out;
`;

const Title = styled.h4`
  color: rgba(38, 38, 38, 0.8);
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  margin: 0 0 23px 0;
`;

const MinorsListWrapper = styled.div``;

const MinorsList = styled.div`
  display: inline-grid;
  grid-template-columns: ${({ isProfileCss }: Props) =>
    !!isProfileCss && isProfileCss ? 'auto auto auto' : 'auto auto auto auto'};
  flex-direction: column;
  margin-bottom: 5px;
  align-items: center;
  @media (min-width: ${Breakpoints.sm}px) {
    flex-direction: row;
    margin: ${({ isProfile }: Props) => (isProfile ? '0' : '0 -8px')};
    padding: 25px 15px 5px;

    & > * {
      margin: ${({ isProfile }: Props) => (isProfile ? '0' : '0 8px')};

      &:nth-child(1),
      &:nth-child(2) {
        flex-basis: 27%;
      }

      &:nth-child(3) {
        flex-basis: 24%;
      }

      &:nth-child(4) {
        flex-basis: 20%;
      }

      &:nth-child(5) {
        flex-basis: 3%;
      }
    }
  }

  @media (min-width: ${Breakpoints.md}px) {
    padding: 25px 40px 5px;
    margin: ${({ isProfile }: Props) => (isProfile ? '0' : '0 -15px')};

    & > * {
      margin: 0 15px;
    }
  }
  @media (max-width: ${Breakpoints.sm}px) {
    grid-template-columns: none;
    gap: 20px;
    width: 100%;
  }
`;

const Minor = styled.div`
 padding: 25px 15px 20px;
  border: 0.5px solid rgba(36, 61, 77, 0.14);
  border-radius: 12px;
`;

const RemoveMinor = styled.button`
background: #243D4D;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  font-weight: 600;
  font-size: 21px;
  line-height: 20px;
  color: #ffffff;
  border: 0;
  float: right;
`;

const AddMinor = styled.button`
 display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: none;
  padding: 20px;
  margin-top: 30px;
  border: 2px dashed ${colors.green};
  border-radius: 10px;
  color: ${colors.black};
  margin-bottom: 20px;

  & > * {
    margin: 0 5px;
  }
`;
const MinorRadioBox = styled.div`
  padding: ${({ isProfileCss }: Props) =>
    !!isProfileCss && isProfileCss ? '0px' : '25px 15px 5px'};
  margin: ${({ isProfileCss }: Props) =>
    !!isProfileCss && isProfileCss ? '0px' : '0px 30px'};
  @media (max-width: ${Breakpoints.sm}px) {
    padding: 0px 0px 15px 0px;
    margin: 0px 10px;
  }
`;
const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow:
      'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
});

const Gap = styled.div`
  width: 90px;
  @media  (min-width: ${Breakpoints.sm}px) and (max-width: ${Breakpoints.xl}px) {
    width: 0px;
  }
`;
function StyledRadio(props: RadioProps) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      // checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      // icon={<span className={classes.icon} />}
      {...props}
      style={{
        color: '#DADADA'
      }}
    />
  );
}
type Props = {
  setAddMinor: (p: boolean) => void;
  isRegister: boolean;
  isProfile: boolean;
  schoolDropDown: IDropDown[];
  isProfileCss?: boolean;
};
const Minors: React.FC<Props> = ({
  isRegister,
  isProfile,
  setAddMinor,
  schoolDropDown,
  isProfileCss,
}) => {
  const { values }:any = useFormikContext();
  const { updateFormValues } = useRegisterStepsDispatch();
  const {
    form: { minors, isINTNameFormat },
  } = useRegisterStepsState();
  const {
    form: { testSelection, cPartnerID },form,
  } = useStepsState();
  const [errormsg, setErrormsg] = useState<string>('');
  const [validEmail, setValidEmail] = useState<boolean>(true);
  let { travellType } = useSharedState();
  const [schoolShowList, setSchoolShowList] = useState<string>('');
  const [schoolValidationMethod, setSchoolValidationMethod] = useState<string>(
    ''
  );

  const saveMinorDetails = (
    fieldName: string,
    index: number,
    newValue: string | Date | null
  ) => {
    const newMinors: any = [...values.minors];
    if(newMinors[index] !== undefined) 
        newMinors[index][fieldName] = newValue;

    updateFormValues({
      minors: newMinors,
    });
    setErrormsg('');
  };

  React.useEffect(() => {
    if(cPartnerID === 'LLT001') {
        setSchoolShowList('student')
        setSchoolValidationMethod('student')
        saveMinorDetails('schoolTestFor', 0,'student');
    }
    if (values.minors.length === 0) {
      setAddMinor(false);
      values.minors.push({
        firstName: '',
        lastName: '',
        middleName: '',
        passportNo: '',
        passportCountry: '',
        birthDate: null,
        relationship: '',
        email:'',
        school: '',
        studentID: '',
        schoolTestFor: cPartnerID === 'LLT001' ? 'student' : '',
        notification:false,
      });
    }
  }, [values]);

  const confirmationSchoolSelectionHandler = (method: string) => {
    setSchoolValidationMethod(method);

    if (method === 'student') {
      setSchoolShowList('student');
    } else if (method === 'parentOrGuardian') {
      setSchoolShowList('parentOrGuardian');
    } else if (method === 'staff') {
      setSchoolShowList('staff');
    } else if (method === 'faculty') {
      setSchoolShowList('faculty');
    } else{
      setSchoolShowList(method)
    }
  };

  return (
    <Container>
      {!!isProfileCss && isProfileCss && <Title>Minor Information</Title>}
      <FieldArray
        name="minors"
        render={(arrayHelpers) => (
          <MinorsListWrapper>
            {values.minors.map((minor: IMinor, index: number) => (
              <Minor key={index}>
                <MinorRadioBox
                  key={index}
                  isRegister={isRegister}
                  isProfile={isProfile}
                  isProfileCss={isProfileCss}
                  schoolDropDown={schoolDropDown}
                  setAddMinor={(p: boolean) => {
                    setAddMinor(p);
                  }}
                >
                  {testSelection === 'school' && cPartnerID !== 'POR001' && (
                    <>
                      <SubHeaderDescription style={{ marginBottom: '5px' }}>
                        I am booking for a{' '}
                        <span style={{ color: 'red' }}>*</span>
                      </SubHeaderDescription>
                      <FormControl component="fieldset" variant="standard">
                        <RadioGroup
                          row
                          value={schoolValidationMethod}
                          name={`minors.${index}.schoolTestFor`}
                          onChange={(event) => {
                            confirmationSchoolSelectionHandler(
                              event?.target.value
                            );
                            saveMinorDetails(
                              'schoolTestFor',
                              index,
                              event?.target.value
                            );
                          }}
                        >
                          <FormControlLabel
                            value="student"
                            control={<StyledRadio />}
                            checked={schoolShowList === 'student'}
                            label={'Student'}
                          />
                          <Gap />
                          {form?.cPartnerID !== 'SCS001' &&
                            form?.cPartnerID !== 'LLT001' && (
                              <>
                                <FormControlLabel
                                  value="parentOrGuardian"
                                  control={<StyledRadio />}
                                  checked={
                                    schoolShowList === 'parentOrGuardian'
                                  }
                                  label={'Parent/Guardian of student'}
                                />
                                {/* <Gap /> */}
                              </>
                            )}
                          {form?.cPartnerID !== 'LLT001' && (
                            <>
                              <FormControlLabel
                                value="staff"
                                control={<StyledRadio />}
                                checked={schoolShowList === 'staff'}
                                label={'Staff'}
                              />
                              <Gap />
                            </>
                          )}
                          {form?.cPartnerID !== 'SCS001' &&
                            form?.cPartnerID !== 'LLT001' && (
                              <>
                                <FormControlLabel
                                  value={
                                    form?.cPartnerID === 'BEL001'
                                      ? 'communityMember'
                                      : 'faculty'
                                  }
                                  control={<StyledRadio />}
                                  checked={
                                    schoolShowList === 'faculty' ||
                                    schoolShowList === 'communityMember'
                                  }
                                  label={
                                    form?.cPartnerID === 'BEL001'
                                      ? 'Community Member'
                                      : 'Faculty'
                                  }
                                />
                              </>
                            )}
                        </RadioGroup>
                      </FormControl>
                    </>
                  )}
                </MinorRadioBox>
                <MinorsList
                  key={index}
                  isRegister={isRegister}
                  isProfile={isProfile}
                  isProfileCss={isProfileCss}
                  schoolDropDown={schoolDropDown}
                  setAddMinor={(p: boolean) => {
                    setAddMinor(p);
                  }}
                >
                  <FormLabel label="First Name">
                    <Input
                      value={values.minors[index].firstName}
                      name={`minors.${index}.firstName`}
                      isRequired
                      validate={(value) => {
                        let error;

                        if (
                          value != null &&
                          value != 'undefined' &&
                          value.trim() === ''
                        ) {
                          error = 'Required field';
                        }

                        return error;
                      }}
                      onChange={(value) =>
                        saveMinorDetails('firstName', index, value)
                      }
                    />
                  </FormLabel>
                  <FormLabel label="Middle Name">
                    <Input
                      value={values.minors[index].middleName}
                      name={`minors.${index}.middleName`}
                      isRequired
                      validate={(value) => {
                        if (
                          travellType == 'INT' &&
                          isINTNameFormat === false &&
                          (!value || !value.trim().length)
                        ) {
                          return 'Required field';
                        }
                      }}
                      onChange={(value) =>
                        saveMinorDetails('middleName', index, value)
                      }
                    />
                  </FormLabel>
                  <FormLabel label="Last Name">
                    <Input
                      value={values.minors[index].lastName}
                      name={`minors.${index}.lastName`}
                      isRequired
                      validate={(value) => {
                        let error;

                        if (
                          value != null &&
                          value != 'undefined' &&
                          value.trim() === ''
                        ) {
                          error = 'Required field';
                        }

                        return error;
                      }}
                      onChange={(value) =>
                        saveMinorDetails('lastName', index, value)
                      }
                    />
                  </FormLabel>
                  <FormLabel label="Email">
                    <Input
                      value={values.minors[index].email}
                      name={`minors.${index}.email`}
                      isRequired
                      validate={(value) => {
                        let error;
                        var pattern = new RegExp(
                          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i
                        );
                        if (value !== '' && value !== undefined) {
                          if (pattern.test(value)) {
                            setValidEmail(false);
                          } else {
                            error = 'Enter correct Email';
                            setValidEmail(true);
                            return error;
                          }
                        } else {
                          return;
                        }
                      }}
                      onChange={(value) =>
                        saveMinorDetails('email', index, value)
                      }
                    />
                  </FormLabel>
                  <FormLabel label="Date of Birth">
                    <DatepickerField
                      name={`minors.${index}.birthDate`}
                      inputPlaceholder="MM/DD/YYYY"
                      maxDate={new Date()}
                      isRequired
                      validate={(value) => {
                        let error;

                        let date = ageCalculator(value);
                        if (date.years >= 18 && value !== null) {
                          if (
                            (date.years == 18 && date.months !== 0) ||
                            (date.months == 0 && date.days !== 0)
                          ) {
                            error = 'Minor must be under 18 years old';
                          } else if (date.years > 18) {
                            error = 'Minor must be under 18 years old';
                          }
                        }
                        // Validate less then 5 years old
                        // if (date.years <= 5) {
                        //   error = 'Children under 5 years of age from the date of the appointment do not need a Covid test.';
                        // }
                        return error;
                      }}
                      onChange={(value: Date | null) =>
                        saveMinorDetails('birthDate', index, value)
                      }
                    />
                  </FormLabel>
                  <FormLabel label="Relationship">
                    <Input
                      value={values.minors[index].relationship}
                      name={`minors.${index}.relationship`}
                      isRequired
                      validate={(value) => {
                        let error;

                        if (
                          value != null &&
                          value != 'undefined' &&
                          value.trim() === ''
                        ) {
                          error = 'Required field';
                        }

                        return error;
                      }}
                      onChange={(value) =>
                        saveMinorDetails('relationship', index, value)
                      }
                    />
                  </FormLabel>
                  {!isRegister && travellType === 'INT' && (
                    <>
                      <FormLabel label="Passport Country">
                        <Select<string>
                          name={`minors.${index}.passportCountry`}
                          options={country}
                          validate={(value) => {
                            if (travellType == 'INT' && !value) {
                              return 'Required field';
                            }
                          }}
                          onChange={(value) =>
                            saveMinorDetails('passportCountry', index, value)
                          }
                        />
                      </FormLabel>
                      <FormLabel label="Passport #">
                        <Input
                          name={`minors.${index}.passportNo`}
                          isRequired
                          value={values?.minors[index]?.passportNo}
                          validate={(value) => {
                            if (
                              travellType == 'INT' &&
                              (!value || !value.trim().length)
                            ) {
                              return 'Required field';
                            }
                          }}
                          onChange={(value) =>
                            saveMinorDetails('passportNo', index, value)
                          }
                        />
                      </FormLabel>
                    </>
                  )}
                  {!isRegister &&
                    testSelection === 'school' &&
                    cPartnerID !== 'POR001' && (
                      <>
                        <FormLabel label="School">
                          <Select<string>
                            name={`minors.${index}.school`}
                            options={schoolDropDown}
                            defaultValue={
                              schoolDropDown.length === 1
                                ? schoolDropDown[0]
                                : null
                            }
                            validate={(value) => {
                              if (
                                testSelection === 'school' &&
                                !value &&
                                !form.minors[index]?.school
                              ) {
                                return 'Required field';
                              }
                            }}
                            onChange={(value) =>
                              saveMinorDetails('school', index, value)
                            }
                          />
                        </FormLabel>
                        {minor.schoolTestFor === 'student' &&
                          cPartnerID !== 'BEL001' && (
                            <FormLabel
                              label={
                                form?.cPartnerID === 'LLT001' ||
                                form.cPartnerID === 'SCS001'
                                  ? 'Student ID # (optional)'
                                  : 'Student ID #'
                              }
                            >
                              <Input
                                name={`minors.${index}.studentID`}
                                validate={(value) => {
                                  if (
                                    testSelection === 'school' &&
                                    (!value || !value.trim().length) &&
                                    form?.cPartnerID !== 'LLT001' &&
                                    form.cPartnerID !== 'SCS001'
                                  ) {
                                    return 'Required field';
                                  }
                                }}
                                isRequired
                                onChange={(value) =>
                                  saveMinorDetails('studentID', index, value)
                                }
                              />
                            </FormLabel>
                          )}

                        {minor.schoolTestFor === 'staff' && (
                          <FormLabel
                            label={
                              form?.cPartnerID === 'SCS001'
                                ? 'Staff ID # (optional)'
                                : 'Staff ID #'
                            }
                          >
                            <Input
                              name={`minors.${index}.studentID`}
                              validate={(value) => {
                                if (
                                  testSelection === 'school' &&
                                  (!value || !value.trim().length) &&
                                  form?.cPartnerID !== 'SCS001'
                                ) {
                                  return 'Required field';
                                }
                              }}
                              isRequired
                              onChange={(value) =>
                                saveMinorDetails('studentID', index, value)
                              }
                            />
                          </FormLabel>
                        )}
                        {(minor.schoolTestFor === 'faculty' ||
                          minor.schoolTestFor === 'communityMember') && (
                          <FormLabel
                            label={
                              minor.schoolTestFor === 'faculty'
                                ? 'Faculty ID #'
                                : 'Community Member ID #'
                            }
                          >
                            <Input
                              name={`minors.${index}.studentID`}
                              validate={(value) => {
                                if (
                                  testSelection === 'school' &&
                                  (!value || !value.trim().length)
                                ) {
                                  return 'Required field';
                                }
                              }}
                              isRequired
                              onChange={(value) =>
                                saveMinorDetails('studentID', index, value)
                              }
                            />
                          </FormLabel>
                        )}
                      </>
                    )}
                  <FormControlLabel
                    control={
                      <CustomSwitch
                        disabled={validEmail}
                        onChange={(e: any) => {
                          saveMinorDetails(
                            'notification',
                            index,
                            e.target.checked
                          );
                        }}
                        color="primary"
                      />
                    }
                    label="Allow minor to receive test results"
                    labelPlacement="end"
                  />{' '}
                  <br />
                  {index !== 0 && !isRegister && !isProfile && (
                    <div>
                      <RemoveMinor
                        type="button"
                        onClick={() => {
                          if (index === 0) {
                            setErrormsg(
                              'Please enter atleast one minor detail or Select another scheduling method'
                            );
                          } else {
                            arrayHelpers.remove(index);
                            updateFormValues({
                              minors: values.minors.filter(
                                (minor: IMinor, i: number) => i !== index
                              ),
                            });
                          }
                        }}
                      >
                        -
                      </RemoveMinor>
                    </div>
                  )}
                  {(isRegister || isProfile) && (
                    <div>
                      <RemoveMinor
                        type="button"
                        onClick={() => {
                          arrayHelpers.remove(index);
                          updateFormValues({
                            minors: values.minors.filter(
                              (minor: IMinor, i: number) => i !== index
                            ),
                          });
                        }}
                      >
                        -
                      </RemoveMinor>
                    </div>
                  )}
                </MinorsList>
              </Minor>
            ))}
            {cPartnerID !== 'LLT001' && (
              <AddMinor
                type="button"
                onClick={() => {
                  arrayHelpers.push({
                    firstName: '',
                    lastName: '',
                    middleName: '',
                    birthDate: null,
                    relationship: '',
                    email: '',
                    school:
                      schoolDropDown.length === 1
                        ? schoolDropDown[0].value
                        : '',
                    studentID: '',
                    schoolTestFor: '',
                  });
                  setErrormsg('');
                }}
              >
                <PlusIcon className="PlusIcon" />
                Add another minor
              </AddMinor>
            )}
          </MinorsListWrapper>
        )}
      />
      <ErrorText hasError> {errormsg} </ErrorText>
    </Container>
  );
};

export default Minors;
