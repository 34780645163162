import React, { useEffect, useState } from 'react';

import { useStepsState, useStepsDispatch } from '../../schedule/provider';
import { useSharedState, useSharedDispatch } from '../../shared/provider';
import Header from '../../shared/components/Header';

import Location from './Location';
import DateAndTime from './DateAndTime';
import Symptoms from './Symptoms/Symptoms';
import Conditions from './Conditions';
import Confirmation from './Confirmation/Confirmation';
import ConfirmBooking from './ConfirmBooking';
import CovidContact from './CovidContact';
import Consent from './Consent/Consent';
import FooterRegister from './FooterRegister';
// import NewInsurance2 from './NewInsuranceScreen/NewInsurance2';
import SchedulingInfo from './PersonalInformation/SchedulingInfo';
import PhoneWthOutReg from './PhoneWthOutReg';
import DemographicsWthOutReg from './DemographicsWthOutReg';
import AddressWthOutReg from './Address/AddressWthOutReg';
import RegisterWthOutReg from './RegisterWthOutReg';
import NewInsurance3 from './NewInsuranceScreen/NewInsurance3';
import SchedulinginfoWthOutReg from './PersonalInformation/SchedulinginfoWthOutReg';
import ConfirmAccountWthOutReg from './ConfirmAccountWthOutReg';
import NewStartAppt from '../../schedule/components/NewStartScreen/NewStartAppt';
import PaymentModal from '../../schedule/components/ConfirmAndPay/PaymentModal';

import styled from 'styled-components';
import { colors } from '../../../styles/colors';
import PayingMethod from './PayingMethod';
import PaymentType from './PaymentType';
import TestSelection from './TestSelection';
import InsuranceYorN from './NewInsuranceScreen/InsuranceYorN';
import NoInsuranceDetails from './NewInsuranceScreen/NoInsuranceDetails';
import { config } from '../../../config';
import Loader from '../../shared/components/Loader';

interface WrapperProps {
    backgroundColor: number;
    apptFromSchdule: Boolean;
    signinStep: Boolean;
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: auto;
  background: ${({
}: WrapperProps) =>
        '#FFFFFF'}
  & > * {
    &:nth-child(2) {
      flex-grow: 1;
    }
  }
`;

export const steps = [
    TestSelection,
    NewStartAppt,
    Location,
    SchedulingInfo,
    InsuranceYorN,
    PaymentType,
    NoInsuranceDetails,
    NewInsurance3,
    DateAndTime,
    Symptoms,
    Conditions,
    CovidContact,
    Consent,
    ConfirmBooking,
    PayingMethod,
    NewInsurance3,
    Confirmation,
];

export const signInSteps = [
    Location,
    SchedulingInfo,
    InsuranceYorN,
    PaymentType,
    NoInsuranceDetails,
    NewInsurance3,
    DateAndTime,
    Symptoms,
    Conditions,
    CovidContact,
    Consent,
    ConfirmBooking,
    PayingMethod,
    NewInsurance3,
    Confirmation,
];

export const newschdulerstepsForAllPaymentOption = [
    TestSelection,
    Location,
    SchedulinginfoWthOutReg,
    PhoneWthOutReg,
    AddressWthOutReg,
    PaymentType,
    InsuranceYorN,
    NoInsuranceDetails,
    NewInsurance3,
    DateAndTime,
    Symptoms,
    Conditions,
    CovidContact,
    DemographicsWthOutReg,
    Consent,
    RegisterWthOutReg,
    ConfirmAccountWthOutReg,
    ConfirmBooking,
    PayingMethod,
    NewInsurance3,
    Confirmation,
  ];

export const schdulersteps = [
    Location,
    SchedulinginfoWthOutReg,
    PhoneWthOutReg,
    AddressWthOutReg,
    InsuranceYorN,
    PaymentType,
    NoInsuranceDetails,
    NewInsurance3,
    DateAndTime,
    Symptoms,
    Conditions,
    CovidContact,
    DemographicsWthOutReg,
    Consent,
    // registration steps
    RegisterWthOutReg,
    ConfirmAccountWthOutReg,
    ConfirmBooking,
    PayingMethod,
    NewInsurance3,
    Confirmation,
];

export const testSelectionSteps = [
    TestSelection,
    NewStartAppt,
    Location,
    SchedulinginfoWthOutReg,
    PhoneWthOutReg,
    AddressWthOutReg,
    InsuranceYorN,
    PaymentType,
    NoInsuranceDetails,
    NewInsurance3,
    DateAndTime,
    Symptoms,
    Conditions,
    CovidContact,
    DemographicsWthOutReg,
    Consent,
    // registration steps
    RegisterWthOutReg,
    ConfirmAccountWthOutReg,
    ConfirmBooking,
    PayingMethod,
    NewInsurance3,
    Confirmation,
];
export const signInStepsForAllPaymentOption = [
    //BookNowForm,
    Location,
    SchedulingInfo,
    PaymentType,
    InsuranceYorN,
    NoInsuranceDetails,
    NewInsurance3,
    DateAndTime,
    Symptoms,
    Conditions,
    CovidContact,
    Consent,
    ConfirmBooking,
    PayingMethod,
    NewInsurance3,
    Confirmation,
];
export const schdulerstepsForAllPaymentOption = [
    Location,
    SchedulinginfoWthOutReg,
    PhoneWthOutReg,
    AddressWthOutReg,
    PaymentType,
    InsuranceYorN,
    NoInsuranceDetails,
    NewInsurance3,
    DateAndTime,
    Symptoms,
    Conditions,
    CovidContact,
    DemographicsWthOutReg,
    Consent,
    RegisterWthOutReg,
    ConfirmAccountWthOutReg,
    ConfirmBooking,
    PayingMethod,
    NewInsurance3,
    Confirmation,
];

export const kernSteps = [

    // BookNowForm,
    // TestSelection,
    Location,
    SchedulingInfo,
    // InsuranceYorN,
    // PaymentType,
    // NoInsuranceDetails,
    // NewInsurance3,
    DateAndTime,
    Symptoms,
    Conditions,
    CovidContact,
    Consent,
    ConfirmBooking,
    // PayingMethod,
    // NewInsurance3,
    Confirmation,
  ];
  export const goHealthSteps = [
    TestSelection,
    NewStartAppt,
    Location,
    SchedulingInfo,
    DateAndTime,
    ConfirmBooking,
  ];
  export const goHealthSignInSteps = [
    TestSelection,
    NewStartAppt,
    Location,
    SchedulinginfoWthOutReg,
    DateAndTime,
    ConfirmBooking,
  ];

  export const rebookingStep =[
    ConfirmBooking,
    Confirmation
  ]
//DateAndTime,   comes after location, Consent, comes after covidcontact, ConfirmAccount after address
function AppointmentSteps() {
    const { step, form ,rebooking} = useStepsState();
    const { updateFormValues,rebookingLoader } = useStepsDispatch();
    const {
        apptFromSchdule,
        airline,
        destination,
        flow,
        userData,
        toNextStep,
        isVaccinate,
        reasonType,
        vaccineType,
        testSelectFlow,
        district,
        tenant,
        isWalkUp
    } = useSharedState();
    const [signinStep, setSigninStep] = useState<boolean>(false);

    const sfoTenant: boolean = tenant === 'sfoairport' ? true : false;
    const generalTenet: boolean = tenant === 'general' ? true : false;
    useEffect(() => {
        if (isWalkUp.active && isWalkUp.locationId === config.qbenchCustomerId.PCMADestination) {
            setTimeout(() => {
                updateFormValues({ cPartnerID: 'PCMA001', testSelection: 'general', travelType: 'DOM', district: 'pcma' })
            }, 100);
        }
        if (tenant === 'kern') {
            updateFormValues({ cPartnerID: 'KEN001' })
          }
        setSigninStep(
            (airline != '' && destination != '') ||
            (isVaccinate != '' && reasonType != '') ||
            vaccineType != '' ||
            district != ''
        );
    }, [apptFromSchdule]);

    let CurrentStep = (form?.location?.partnerName === 'goHealth') 
    ? (apptFromSchdule ? goHealthSignInSteps[step] : goHealthSteps[step]) :(rebooking?.active && rebooking.isRebook===false) ? rebookingStep[step] : apptFromSchdule && !testSelectFlow && (!userData?.isVerified || toNextStep) && ((airline && destination) || (isVaccinate && reasonType) || vaccineType || district || tenant|| sfoTenant)
        ?
        district?.toLocaleLowerCase() === 'pvsd'
        ?
        schdulerstepsForAllPaymentOption[step]
        :
        tenant?.toLocaleLowerCase() === 'kern' ?
        newschdulerstepsForAllPaymentOption[step]
          :
          schdulersteps[step]
      :
      apptFromSchdule && testSelectFlow && (!userData?.isVerified || toNextStep)
        ?
        testSelectionSteps[step]
        :   
        !testSelectFlow && ((airline && destination) || (isVaccinate && reasonType) || vaccineType || district || sfoTenant) && !flow
          ?
          district?.toLocaleLowerCase() === 'pvsd'
            ?
            signInStepsForAllPaymentOption[step]
            :
              signInSteps[step]              
          :
          steps[step];
    if (isWalkUp.active && isWalkUp.locationId === config.qbenchCustomerId.PCMADestination) {
        if (!userData?.isVerified) {
            CurrentStep = schdulerstepsForAllPaymentOption[step]
        } else {
            CurrentStep = signInStepsForAllPaymentOption[step]
        }
    }

    //  if (isWalkUp.active && isWalkUp.locationId === config.qbenchCustomerId.KernHealthSystems) {
    //     if (!userData?.isVerified) {
    //       CurrentStep = newschdulerstepsForAllPaymentOption[step]
    //     } 
    //     // else {
    //     //     CurrentStep = schdulersteps[step]
    //     // }
    //   }

    useEffect(() => {
        rebookingLoader(false)
    }, [form.location])

    return (
        rebooking?.isRebook ? (
            <div style={{justifyContent: 'center',alignItems: 'center',display: 'flex',height: '100%',fontSize: '5vw'}} >
              Free Test Already Booked</div>
            
          ) :(rebooking.active && form.location ===null) ? <Loader /> 
          :
        <Wrapper
            backgroundColor={rebooking.active ? 2: step}
            apptFromSchdule={apptFromSchdule || (isWalkUp.active && userData?.isVerified &&
                isWalkUp.locationId === config.qbenchCustomerId.PCMADestination) ? true : false}
            signinStep={signinStep}
        >
            {apptFromSchdule && !userData?.isVerified && <Header isHelp />}
            {(!apptFromSchdule || userData?.isVerified) && (
                <Header isHelp isMyAcc withoutBg />
            )}
            <div>
                <CurrentStep />
            </div>
            {/* {step != 0 && step != 13 && <FooterRegister />} */}
        </Wrapper>
    );
}

export default AppointmentSteps;
