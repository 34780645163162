import _ from 'lodash';
import { config } from '../../config';
import { firebase } from '../../firebase';
import { Appointment, Register } from '../shared/models';

export const createRegister = async (register: Register) => {
return firebase
  .functions()
  .httpsCallable('createRegister')(register);
}

export const getAllClientPartnerSchools = async () => {
  return firebase
    .functions()
    .httpsCallable('getAllClientPartnerSchools')();
}
export const getAppointmentId = async (appointment: Appointment) => {
  return firebase
    .functions()
    .httpsCallable('getAppointmentId')(appointment);
};
export const checkemail = async (register: any) => {
  return firebase
    .functions()
    .httpsCallable('checkemail')(register);
  }

export const registerLogin = async (params: any) => {
  return firebase
    .functions()
    .httpsCallable('registerLogin')(params);
  }

//logAsCustomer 
export const adminLogin = async (params: any) => 
  firebase
    .functions()
    .httpsCallable('adminLogin')(params);

export const validateOTP = async (params: any) => {
  return firebase
    .functions()
    .httpsCallable('validateOTP')(params);
  }
  
export const forgetPasswordUrl = async (params: any) => {
  return firebase.functions().httpsCallable('forgetPasswordUrl')(params)
}

export const resetPassword = async (params: any) => {
  return firebase.functions().httpsCallable('resetPassword')(params)
}

export const changEmailPassword = async (params: any) => {
  return firebase.functions().httpsCallable('changEmailPassword')(params)
}

export const AddGovtId = async (params: any) => {
  return firebase
    .functions()
    .httpsCallable('AddGovtId')(params);
}

export const getGovtId = async (govtId: any) => {
  return firebase
    .functions()
    .httpsCallable('getGovtId')(govtId);
}

export const deleteUserId = async (params: any) => {
  return firebase
    .functions()
    .httpsCallable('deleteUserId')(params);
}

export const createAppointment = async (appointment: Appointment) => {

  /* Creating appointment based on the location type */

  if(appointment.location && appointment.location?.limsinfo?.type === "qbench") {
    return firebase.functions().httpsCallable('createAppointmentDatailswithTask')(appointment);
  } else {
    /* location type based on the crelio */
    return firebase.functions().httpsCallable('createAppointmentdata')(appointment);
  }
}

export const getAppointmentResults = async (params: {email: string}) => {
  return firebase.functions().httpsCallable('getAppointmentResults')(params);
}
export const getAllClientPartner = async (param: any) => {
    return firebase
        .functions()
        .httpsCallable('getAllClientPartner')(param);
}
export const updateRegister = async (register: any) => {
  return firebase
    .functions()
    .httpsCallable('updateRegister')(register);
}

export const findEmail = async (params: any) => {
  return firebase
    .functions()
    .httpsCallable('findEmail')(params);
}

export const createInsurance = async (insurance: any) => {
  return firebase
    .functions()
    .httpsCallable('createInsurance')(insurance);
}

export const updateUserInsurance = async (insurance: any) => {
  return firebase
    .functions()
    .httpsCallable('updateUserInsurance')(insurance);
}

export const getUserInsurance = async (params: any) => {
  return firebase
    .functions()
    .httpsCallable('getUserInsurance')(params);
}

export const getAirlineLogo = async (params: any) => {
  return firebase
    .functions()
    .httpsCallable('getAirlineLogo')(params);
}

export const getPostalCodeDetails = async (postalCode: string) => {
  try {
    const SnapShot = await firebase
      .firestore()
      .collection(config.firestoreCollections.postalcodes)
      .where('ZipCode', '==', _.toInteger(postalCode))
      .get();
    const postalCodeDetails = SnapShot.docs.map((doc: any) => doc.data());
    if (postalCodeDetails.length > 0) {
      return postalCodeDetails;
    } else {
      return [];
    }
  } catch (error) {
    console.log('firebase reset schedule error: ', error);
  }
};

export const checkEmailAddress = async (register: any) => {
  return firebase
    .functions()
    .httpsCallable('checkEmailAddress')(register);
  }

export const deleteUserInsurance = async (insurance: any) => {
  return firebase
    .functions()
    .httpsCallable('deleteUserInsurance')(insurance);
}

export const verifyAccountURL = async (params: any) => {
  return firebase.functions().httpsCallable('verifyAccountURL')(params)
}

export const getDistrict = async () => {
    return firebase
        .functions()
        .httpsCallable('getDistrict')(null);
}
export const getVaccines = async () => {
    return firebase
        .functions()
        .httpsCallable('getVaccines')(null);
}
export const getTestReasons = async () => {
    return firebase
        .functions()
        .httpsCallable('getTestReasons')(null);
}
export const getCpartnerLogo = async (params: string) => {
  return firebase
    .functions()
    .httpsCallable('getCpartnerLogo')(params);
}

export const getInsuranceById = async (params: any) => {
  return firebase
    .functions()
    .httpsCallable('getInsuranceById')(params);
}

export const getRegisteredUser = async (register: any) => {
    return firebase
      .functions()
      .httpsCallable('getRegisteredUser')(register);
}

export const UpdateGovtId = async (params: any) => {
    return firebase
      .functions()
      .httpsCallable('UpdateGovtId')(params);
  }

export const getLosLomitasDetails = async (params: any) => {
    return firebase
      .functions()
      .httpsCallable('getLosLomitasDetails')(params);
  }