import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';

import { Container, Content, PageTitle, PageHeader } from '../../shared/components/styled';

import NavigationButtons from '../../shared/components/NavigationButtons';
import Input from '../../shared/components/form/Input';
import Checkbox from '../../shared/components/form/Checkbox';
import { useStepsDispatch, useStepsState } from '../../schedule/provider';
import GoogleAnalytics from '../../../GoogleAnalytics';

const Disclaimer = styled.p`
  font-size: 14px;
  margin: 30px 0 0;
  text-align: center;
`;

const PhoneWthOutReg: React.FC = () => {
  const {
    form: { phone, sendMessagesAboutTestResults },form
  } = useStepsState();
  useEffect(() => {
    GoogleAnalytics(form,'/Phone Number');
  window.scrollTo(0, 0)
  }, [])
  const { updateFormValues, goToNextStep, goToPrevStep } = useStepsDispatch();
  const [dirty, setDirty] = useState(!!phone && sendMessagesAboutTestResults);

  const phoneNumber: any = phone === "" ? "+1" : phone;

  return (
    <Container>
      <Content>
        <PageHeader>
          <PageTitle>What is your phone number?</PageTitle>
          <p>If you have an international number, please include the country code <br/>(e.g. +1)</p>
        </PageHeader>
        <Formik
          initialValues={{
            phone,
            sendMessagesAboutTestResults,
          }}
          onSubmit={(values) => {
            updateFormValues(values);
            goToNextStep();
          }}
          enableReinitialize={false}
        >
          {({ isValid }) => (
            <Form>
              <Input
                value={phoneNumber}
                name="phone"
                // defaultValue="+1"
                onChange={(value) => {
                  updateFormValues({ phone: value });             

                  if (!dirty) {
                    setDirty(true);
                  }
                }}
                validate={(value) => {
                  let error;

                  if (value === '') {
                    error = 'Required field';
                  }

                  let valueArr=value.split("")
                  if(!valueArr.includes("+")){
                    error="Please include country code"
                  }

                  if(valueArr.includes(" ") || valueArr.includes("-") || valueArr.includes("(") || valueArr.includes(")") ){
                    error = "Please avoid special characters and spaces.(Valid format is:3239205839)"
                  }

                  return error;
                }}
              />
              <Checkbox
                name="sendMessagesAboutTestResults"
                onChange={(checked) => {
                  updateFormValues({ sendMessagesAboutTestResults: checked });

                  if (!dirty) {
                    setDirty(true);
                  }
                }}
                isRequired
              >
                Please check this box to consent to receiving text messages about your test results.
              </Checkbox>
              <NavigationButtons isValid={dirty && isValid} onReturnButtonClick={()=>{ goToPrevStep(); }}  />
            </Form>
          )}
        </Formik>
        <Disclaimer>
          Text message frequency varies. Message and data rates may apply.
        </Disclaimer>
      </Content>
    </Container>
  );
};

export default PhoneWthOutReg;