import React from 'react';
import { Field, FieldProps } from 'formik';
import styled from 'styled-components';

import { colors } from '../../../../styles/colors';
import { ErrorText } from '../styled';
import { useStepsDispatch, useStepsState } from '../../../schedule/provider';
import { Breakpoints } from '../../../../dictionaries';

interface ButtonProps {
  selected: boolean;
  disabled?: boolean;
}

const Container = styled.div`
  position: relative;
  padding-bottom: 10px;
`;

const Error = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
`;

const Button = styled.button`
  border: 1px solid ${colors.rebrandBlack};
  background-color: ${({ selected }: ButtonProps) =>
    selected ? colors.rebrandBlack : colors.white};
  color: ${({ selected }: ButtonProps) =>
    selected ? colors.white : colors.rebrandBlack};
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  display: block;
  width: 100%;
  padding: 10px 20px;
  margin-bottom: 15px;
  border-radius: 6px;
  transition: all 0.2s ease-in-out;
  @media (min-width: ${Breakpoints.sm}px) {
    :hover {
      background-color: ${colors.rebrandBlack};
      color: white;
    }
  }
`;

interface Props {
  name: string;
  onChange?: (answer: string) => void;
  isRequired?: boolean;
  additionalContent?: React.ReactNode;
  checkTest?: boolean;
  insurance: boolean
  noInsurance: boolean;
}

const CreditcardInsurancebutton: React.FC<Props> = ({
  name,
  onChange,
  isRequired,
  additionalContent,
  checkTest,
  insurance,
  noInsurance
}) => {
    const { updateFormValues } = useStepsDispatch();
    const {
      form: { testSelection, location, payingMethod },
    } = useStepsState();

    const handlePaymentMethod = () => {
        if((!location?.standardTest || !location?.isCreditCard.standard) && location?.isExpressSameDay && location?.isCreditCard.express){
          updateFormValues({isExpressSameDayTest: true})
      }else if((!location?.standardTest || !location?.isCreditCard.standard) && (!location?.isExpressSameDay || !location?.isCreditCard.express) && location?.rapidTest && location?.isCreditCard.rapid){
        updateFormValues({isRapidTest: true})
      }else if((!location?.standardTest || !location?.isCreditCard.standard) && (!location?.isExpressSameDay || !location?.isCreditCard.express) && (!location?.rapidTest || !location?.isCreditCard.rapid) && location?.isAntigen && location?.isCreditCard.antigen) {
        updateFormValues({isAntigen : true})
      }
      }
  
  return (
    <Field
      name={name}
      validate={(value: boolean | null) => {
        let error;

        if (isRequired && value === null) {
          error = 'Please choose one of the answers';
        }

        return error;
      }}
    >
      {({
        field: { value },
        form: { setFieldValue },
        meta: { touched, error },
      }: FieldProps) => (
          <Container>
              
          {/* <Button
            type="button"
            disabled={checkTest ? checkTest : false}
            selected={value === true}
            onClick={() => {
              setFieldValue(name, true);

              if (onChange) {
                onChange(true);
              }
            }}
          >
            Insurance
          </Button>
          {additionalContent || null}
          <Button
            type="button"
            selected={value === false}
            onClick={() => {
              setFieldValue(name, false);

              if (onChange) {
                onChange(false);
              }
            }}
          >
          Credit card
          </Button> */}
           {(insurance && <Button
            type="button"
            selected={payingMethod === 'Insurance'}
            onClick={() => {
              setFieldValue(name, true);

              if (onChange) {
                onChange('Insurance');
              }
            }}
          >
            Insurance
          </Button>)}
          {(noInsurance && <Button
            type="button"
            selected={payingMethod === 'No Insurance'}
            onClick={() => {
              setFieldValue(name, true);

              if (onChange) {
                onChange('No Insurance');
              }
            }}
          >
           No Insurance
          </Button>)}
          {additionalContent || null}
          <Button
            type="button"
            selected={(payingMethod === 'Credit Card' || payingMethod === 'Credit card')}
            onClick={() => {
              setFieldValue(name, false);
              handlePaymentMethod()
              if (onChange) {
                onChange('Credit Card');
              }
            }}
          >
          Credit card
          </Button>
          <Error>
            <ErrorText hasError={touched && error !== undefined}>
              {error}
            </ErrorText>
          </Error>
        </Container>
      )}
    </Field>
  );
};

CreditcardInsurancebutton.defaultProps = {
  isRequired: true,
};

export default CreditcardInsurancebutton;