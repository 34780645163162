export const isUSPhone = /^\(?\d{3}\)?-? *\d{3}-? *-?\d{4}$/;

export const isValidZIP = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

export const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const isValidDateFormat = /^\d{2}\/\d{2}\/\d{4}$/;

export const walkUpUrlValidation  = /^(?:\/bookappointment\b)(?:\/walkup+)(?:\/\d{0,4}.)$/
export const minoremailValidate = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;

export const validateComma = /^[^\,]*$/;