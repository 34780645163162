import React, { useState } from 'react';
import styled from 'styled-components';
import { Breakpoints } from '../../../../../dictionaries';
import { IoPersonOutline } from 'react-icons/io5';
import {
    cancelAppointment,
    cancelCrelioAppointment,
} from '../../../../schedule/api';
import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import { colors } from '../../../../../styles/colors';
import { useSharedDispatch, useSharedState } from '../../../../shared/provider';
import {
    Button,
    PageHeader,
    PageTitle,
} from '../../../../shared/components/styled';
import { Form } from '../../../../shared/models';
import { useHistory, useParams } from 'react-router-dom';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import Modal from '../../../../shared/components/Modal';
import Loader from '../../../../shared/components/Loader';

const Container = styled.div`
    text-align: left;
    @media (max-width: ${Breakpoints.sm}px) {
        padding: 0px;
    }
`;

const Confirmbtn = styled.button`
    width: 20%;
    border: 0;
    align-items: center;
    text-align: center;
    border: 1px solid #2a5f87;
    line-height: 1.6;
    padding: 5px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    display: inline-block;
    background-color: #2a5f87;
    color: #ffff;
    @media (max-width: ${Breakpoints.sm}px) {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 30%;
        padding: 5px;
        margin: 0;
    }
`;
const Returnbtn = styled.div`
    width: 20%;
    border: 0;
    align-items: center;
    text-align: center;
    border: 1px solid #2a5f87;
    line-height: 1.6;
    padding: 5px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    display: inline-block;
    background-color: #fff;
    color: #2a5f87;
    @media (max-width: ${Breakpoints.sm}px) {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 30%;
        padding: 5px;
        margin: 0;
    }
`;
const Buttons = styled.div`
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: flex-end;
`;
export const Input:any = styled.input`
    height: 19px;
    width: 19px;
    border: 1px solid ${colors.blueLight};
    border-radius: 0px;
    outline: none;
    transition-duration: 0.3s;
    cursor: pointer;
    background-color: ${colors.white};
`;

const Flexbox = styled.div`
    display: flex;
    align-items: center;
    margin-top: -23px;
    width: 100%;
    &:nth-child(1) {
        width: max-content;
        margin-right: 5px;
    }
    &:nth-child(2) {
        border: none;

        margin-right: 0px;
        padding-right: 0px;
    }
    &:nth-child(3) {
        margin-left: 50px;
    }
    &:nth-child(4) {
        margin-left: 50px;
    }
    @media (max-width: ${Breakpoints.sm}px) {
        margin-bottom: 20px;
    }
`;

const FlexboxIcon = styled.div`
    position: relative;
    color: ${colors.grey};
    display: inline-flex;
`;
const FlexboxText = styled.div`
    display: block;
    font-size: 14px;
    font-weight: 500;
    padding-left: 0px;
    word-break: break-all;
`;

const Text = styled.p`
    margin-bottom: 45px;
`;

const Error = styled.div`
    color: ${colors.red};
    margin-top: 15px;
    text-align: left;
`;

type Props = {
    open: boolean;
    handleCancelApptClose: () => void;
    form: Form;
    handleCancelReload: () => void;
    setCancel?: any;
};

const CancelApptModal: React.FC<Props> = ({
    open,
    handleCancelApptClose,
    form,
    handleCancelReload,
    setCancel,
}) => {
    const [loading, setLoading] = useState(false);
    const [isCancelScreen, setCancelScreen] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [checked, setChecked] = useState(true);
    const [partChecked, setPartChecked] = useState(false);
    
    
    const getallData = () => {
        let index = [];
        if (form.registeringFor === 'myself') {
            index.push(-1);
        } else if (form.registeringFor === 'myselfAndMinor') {
            index.push(-1);
            form.minors.map((minorindex: any, i: number) => {
                if(!minorindex.isCanceled){    
                    index.push(i);
                    }
            });
        } else if (form.registeringFor === 'minorOnly') {
            
            form.minors.map((minorindex: any, i: number) => {
                if(!minorindex.isCanceled){    
                index.push(i);
                }
            });
        }
        return index;
    };

  
    const [param, setParam] = useState(getallData());
    console.log('param', param);
    const { userData } = useSharedState();
    let history = useHistory();
    //console.log('apoinment data', form);

    const cancelAppointmentHandler = async () => {
        setLoading(true);
        setError(null);
        try {
            if (form?.qbenchId) {
                //await checkLength()
                let params: any = { id:form.id};
                if (param.length === getallData().length) {
                    console.log('ok param', param);
                    setParam([]);
                    params['index'] = [];
                } else {
                    params['index'] = param;
                }
               

                console.log('appointment Data before apiCall', params);
                const response = await cancelAppointment(params);
                console.log("response",response)
                if(response.data.status==="Appointment cancelled"){  
                    setTimeout(() => {
                        setCancelScreen(true);
                    }, 2000);
                }
               
                if (response.data?.status === 400) {
                    setError('Apponitment not cancelled');
                    console.log('error');
                    setCancelScreen(false);
                }
            } else if (
                form?.crelioId ||
                form?.location?.limsinfo?.type === 'crelio'
            ) {
                console.log('appointment Data', form);
                await cancelCrelioAppointment(form.id);
                setCancelScreen(true);
            } else {
                // @ts-ignore
                throw new Error('Appointment data is incomplete');
            }
            setTimeout(() => {
                setLoading(false);
            }, 1800);
        } catch (e) {
            setLoading(false);
            // setError(e.message);
            setError('Error found');
            console.error(e);
        }
    };

    const spliceIndex = (type: boolean, method: any) => {
        let array: any[] = param;
        if (type === true) {
            array.splice(1, 0, method);
        } else if (type === false) {
            let splice: any;
            if (array.includes(method)) {
                splice = array.indexOf(method);
            }
            array.splice(splice, 1);
        }
        return array;
    };

    return (
        <Modal
            open={open}
            onClose={handleCancelApptClose}
            maxWidth={600}
            blockScroll={false}
        >
            <Container>
                {!isCancelScreen ? (
                    <>
                        <DialogTitle>
                            <AiOutlineQuestionCircle
                                size={22}
                                color={'#000'}
                            />
                            {' Cancel your appointment '}
                        </DialogTitle>

                        {getallData().length > 1 ? (
                            <DialogContent>
                                <DialogContentText>
                                    <Text>
                                        Are you sure, you want to cancel your
                                        Appointment?
                                    </Text>
                                </DialogContentText>
                                <div style={{ display: 'flex' }}>
                                    <Input
                                    style={{ accentColor: '#243D4D' }}
                                        defaultChecked={checked}
                                        onChange={(value:any) => {
                                            console.log(
                                                'value',
                                                value.target.checked
                                            );
                                            setPartChecked(
                                                !value.target.checked
                                            );
                                            setChecked(value.target.checked);
                                            if (!value.target.checked) {
                                                setParam([]);
                                            } else {
                                                setParam(getallData());
                                            }
                                        }}
                                        type="checkbox"
                                    />
                                    <Text>
                                        click here to cancel complete
                                        appointment
                                    </Text>
                                </div>
                                {(form.registeringFor === 'myself' ||
                                    form.registeringFor ===
                                        'myselfAndMinor') && (
                                    <div style={{ display: 'flex' }}>
                                        <Input
                                        style={{ accentColor: '#243D4D' }}
                                            disabled={checked}
                                            type="checkbox"
                                            onChange={(value:any) => {
                                                console.log(
                                                    'value.target.checked',
                                                    value.target.checked
                                                );
                                                setParam(
                                                    spliceIndex(
                                                        value.target.checked,
                                                        -1
                                                    )
                                                );
                                            }}
                                        />
                                        &nbsp; &nbsp;
                                        <Flexbox>
                                            <FlexboxIcon>
                                                <IoPersonOutline size={25} />
                                            </FlexboxIcon>
                                            <FlexboxText>
                                                <b>{'Name : '}</b>
                                                {form?.firstName}{' '}
                                                {form?.middleName}{' '}
                                                {form?.lastName}
                                            </FlexboxText>
                                        </Flexbox>
                                    </div>
                                )}
                                <br />
                                {form.minors.length>0 &&
                                    form.minors.map(
                                        (minorindex: any, i: number) => {
                                            return (
                                                !minorindex.isCanceled && <>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                        }}
                                                    >
                                                        <Flexbox>
                                                            <Input
                                                            style={{ accentColor: '#243D4D' }}
                                                                disabled={
                                                                    checked
                                                                }
                                                                onChange={(
                                                                    value: any
                                                                ) => {
                                                                    console.log(
                                                                        'value.target.checked',
                                                                        value
                                                                            .target
                                                                            .checked,
                                                                        i
                                                                    );
                                                                    setParam(
                                                                        spliceIndex(
                                                                            value
                                                                                .target
                                                                                .checked,
                                                                            i
                                                                        )
                                                                    );
                                                                }}
                                                                type="checkbox"
                                                            />{' '}
                                                            &nbsp; &nbsp;
                                                        </Flexbox>
                                                        <Flexbox>
                                                            <FlexboxIcon>
                                                                <IoPersonOutline
                                                                    size={25}
                                                                />
                                                            </FlexboxIcon>
                                                            <FlexboxText>
                                                                <b>
                                                                    {'Name : '}
                                                                </b>
                                                                {
                                                                    minorindex?.firstName
                                                                }{' '}
                                                                {
                                                                    minorindex?.middleName
                                                                }{' '}
                                                                {
                                                                    minorindex?.lastName
                                                                }
                                                            </FlexboxText>
                                                        </Flexbox>
                                                    </div>
                                                    <br />
                                                </>
                                            );
                                        }
                                    )}
                            </DialogContent>
                        ) : (
                            <DialogContent>
                                <DialogContentText>
                                    <Text>
                                        Are you sure, you want to cancel your
                                        Appointment?
                                    </Text>
                                </DialogContentText>
                            </DialogContent>
                        )}

                        <Buttons>
                            <Returnbtn
                                onClick={() => {
                                    setCancel({ key: -1 });
                                    handleCancelApptClose();
                                }}
                                className='btn elevated-btn'
                            >
                                Cancel
                            </Returnbtn>
                            <Confirmbtn
                                type="submit"
                                onClick={async () => {
                                    setLoading(false);
                                    setError(null);
                                        cancelAppointmentHandler();
                                    
                                }}
                                className='btn fill-btn'
                            >
                                {loading ? 'Processing...' : 'OK'}
                            </Confirmbtn>
                        </Buttons>

                        {error && <Error>{error}</Error>}
                    </>
                ) : (
                    <>
                        <DialogTitle>
                            <AiOutlineQuestionCircle
                                size={22}
                                color={colors.rebrandBlack}
                            />
                            {' Your appointment has been cancelled. '}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                <Text>
                                    You will receive an email notification
                                    shortly confirming the cancellation.
                                </Text>
                            </DialogContentText>
                        </DialogContent>

                        <Buttons>
                            <Confirmbtn
                                type="submit"
                                onClick={() => {
                                    // getAppointmentsData();
                                    handleCancelReload();
                                }}
                                className='btn fill-btn'
                            >
                                {' '}
                                OK
                            </Confirmbtn>
                        </Buttons>
                    </>
                )}
            </Container>
            {/* {minorModal && <SignIn />} */}
        </Modal>
    );
};

export default CancelApptModal;
