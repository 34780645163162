import React, { useState } from 'react';
import styled from 'styled-components';
import { Form, Formik, FormikErrors } from 'formik';
import {
    Button,
    Container,
    Content
} from '../../../../shared/components/styled';
import { isUSPhone, isValidEmail } from '../../../../../validations';
import { AdminManagePage } from '../dictionaries';
import {
    getAppointmentByPhoneorEmail
} from '../../../../manage/api';
import { colors } from '../../../../../styles/colors';
import Input from '../../../../shared/components/form/Input';
import FormLabel from '../../../../shared/components/form/FormLabel';
import { useAdminDispatch } from '../../../provider';


interface NoticeProps {
    margin?: string;
}

const Notice = styled.p`
  margin: ${({ margin }: NoticeProps) => margin || '27px 0 65px'};
  font-size: 14px;
`;


const Error = styled.div`
  color: ${colors.red};
  margin-top: 15px;
  text-align: center;
`;

const Divider = styled.h2`
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #1a96db;
  line-height: 0.1em;
  margin: 30px 0 20px 0;
  color: #2a5f87;
  font-size: 16px;
  font-weight: 700;
`;

const DividerSpan = styled.span`
  background: #fff;
  padding: 0 10px;
`;


const ManageScreen: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const {  updateResultsScreen, goToPage } = useAdminDispatch();

    interface FormValues {
        phone: string;
        email: string;
    }

    const validateForm = (values: any) => {

        let errors: FormikErrors<FormValues> = {};

        if (values.email == "" && values.phone == "") {
            errors.phone = 'Required field';
            errors.email = 'Required field';
        }

        if (values.email !== "" && !isValidEmail.test(values.email)) {
            errors.email = 'Please enter valid email address';
        }

        if (values.phone !== '' && !isUSPhone.test(values.phone)) {
            errors.phone = 'Please enter valid US phone number';
        }
        if (values.email !== "") {
            if (!isValidEmail.test(values.email)) {
                errors.email = 'Please enter valid email address';
            }
        }

        if (values.email !== '' && values.phone !== '') {
            setError('Please use any one of the method to continue.');

        }

        return errors;
    };


    async function getAppointments( phone: string, email: string) {
        return new Promise(async (resolve, reject) => {
                let result: any = [];
                if ((email === '' && phone !== '') || (email !== '' && phone === '')) {
                    setLoading(true);
                    setError(null);

                    if (email) {
                        result = await getAppointmentByPhoneorEmail("", email.trim());
                    } else if (phone) {
                        result = await getAppointmentByPhoneorEmail(phone.trim(), "");
                    }
                    setLoading(false);

                    if (result.length > 0) {
                        setLoading(false);
                        updateResultsScreen(result);
                        goToPage(AdminManagePage.MangeResults);
                    } else {
                        setError('Appointment is not found');
                    }

                } else {
                    setError('Pleasse use any one of the method to continue.');
                }
        })
    }

    return (
        <Container>
            <Content>
                <Formik
                    initialValues={{
                        email: "",
                        phone: "",
                    }}
                    onSubmit={async ({
                        phone,
                        email,
                    }: {
                        phone: string;
                        email: string;
                    }) => {

                        if ((email === '' && phone !== '') || (email !== '' && phone === '')) {
                            getAppointments(phone, email);
                        } else {
                            setError('Pleasse use any one of the method to continue.');
                        }
                    }}
                    enableReinitialize={false}
                    validate={validateForm}
                >
                    {({ values }) => (
                        <Form>
                            <FormLabel label="email">
                                <Input
                                    name="email"
                                    noBottomMargin
                                />
                            </FormLabel>
                            <Divider>
                                <DividerSpan>OR</DividerSpan>
                            </Divider>
                            <FormLabel label="Mobile Number">
                                <Input
                                    name="phone"
                                    noBottomMargin
                                />
                                <Notice margin={"27px 0 35px"} >
                                    The same 10-digit number you used when scheduling.
                                    </Notice>
                            </FormLabel>
                            <Button libraryType="primary" disabled={loading}>
                                {loading ? 'Processing...' : 'Verify Identity'}
                            </Button>
                            {error && <Error>{error}</Error>}
                        </Form>
                    )}
                </Formik>
            </Content>
        </Container>
    );

};

export default ManageScreen;
