import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import styled from 'styled-components';
import { format, parse } from 'date-fns';

import pin from '../../../../../assets/pin-grey.svg';
import calendar from '../../../../../assets/calendar-grey.svg';
import clock from '../../../../../assets/clock.svg';
import person from '../../../../../assets/person-grey.svg';
import moment from 'moment';
import { differenceInHours } from 'date-fns';
import {
  Container,
  Content,
  PageHeader,
  PageTitle,
  PageSubTitle,
  Button,
} from '../../../../shared/components/styled';
import { colors } from '../../../../../styles/colors';
import { useStepsDispatch, useStepsState } from '../../../../schedule/provider';
import { Breakpoints } from '../../../../../../src/dictionaries';
import { config } from '../../../../../../src/config';
import { getPeriodsFromSchedule } from '../../../../../../src/utils';
import FormLabel from '../../../../shared/components/form/FormLabel';
import Datepicker, {
  DatepickerField,
} from '../../../../shared/components/Datepicker';
import Checkbox from '../../../../shared/components/form/Checkbox';
import NavigationButtons from '../../../../shared/components/NavigationButtons';
import ChangeLocationModal from '../../AppointmentList/components/ChangeLocationModel';
import ChangeDateTimeModal from '../../AppointmentList/components/ChangeDateTimeModal';
import EditAppointmentModal from '../../AppointmentList/components/EditAppointmentModal';
import PaymentModal from '../../../../schedule/components/ConfirmAndPay/PaymentModal';
import ChangeApptAttendeeModal from '../../../../schedule/components/ConfirmAndPay/ChangeApptAttendeeModal';
import { AppointmentListItem } from '../../../models';
import { useAdminDispatch, useAdminState } from '../../../provider';
import Loader from '../../../../shared/components/Loader';
// import EditableInfo from './EditableInfo';
import { isBefore, startOfDay, subHours } from 'date-fns';
import {
  checkInCrelioAppointment,
  getAppointmentdata,
  updateAppointmentdata,
} from '../../../api';
import { getSlotsByLocationId } from '../../../../shared/api';
import { TrademarkCircleOutlined } from '@ant-design/icons';

const Checkboxes = styled.div`
  margin-bottom: 50px;
`;

const Title = styled.h3`
  color: ${colors.fullBlack};
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  margin: 0 0 21px 0;
`;

export const TestingInfoContainer = styled.div`
  background: ${colors.blue8};
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 6px;

  & > * {
    &:first-child {
      margin-bottom: 20px;
    }
  }

  @media (min-width: ${Breakpoints.sm}px) {
    flex-direction: row;
    padding: 30px;
    justify-content: space-between;

    & > * {
      &:first-child {
        margin-bottom: 0;
      }
    }
  }
`;

export const Icon = styled.div`
  position: absolute;
  left: 0;
  top: 1px;
`;

export const PinIcon = styled(Icon)`
  left: 5px;
  width: 14px;
  height: 20px;
  background: url(${pin}) no-repeat center center;
`;

export const CalendarIcon = styled(Icon)`
  width: 24px;
  height: 24px;
  background: url(${calendar}) no-repeat center center;
`;

export const ClockIcon = styled(Icon)`
  width: 24px;
  height: 24px;
  background: url(${clock}) no-repeat center center;
`;

export const PersonIcon = styled(Icon)`
  width: 24px;
  height: 24px;
  background: url(${person}) no-repeat center center;
`;

export const TestingInfoRow = styled.div`
  display: flex;
  flex-direction: column;
  margin: -8px 0;

  & > * {
    margin: 8px 0;
  }

  @media (min-width: ${Breakpoints.md}px) {
    flex-direction: row;
    margin: 0 -30px;

    & > * {
      margin: 0 30px;
    }
  }
`;

export const TestingInfo = styled.div`
  position: relative;
  padding-left: 31px;

  svg {
    width: 24px;
    height: 24px;
  }
`;

const TestingInfoBtn = styled(Button)`
  max-width: 200px;
`;

interface ButtonProps {
  libraryType: 'primary' | 'default';
  accentColor?: string;
  size?: 'sm' | 'lg';
}

const ReturnButton = styled.button`
  border: 1px solid
    ${({ accentColor }: ButtonProps) => accentColor || colors.darkBlue};
  font-size: ${({ size }: ButtonProps) => (size === 'sm' ? '14px' : '20px')};
  line-height: 1.6;
  border-radius: 5px;
  padding: ${({ size }: ButtonProps) =>
    size === 'sm' ? '4px 15px' : '9px 20px'};
  font-weight: ${({ size }: ButtonProps) =>
    size === 'sm' ? 'normal' : 'bold'};
  width: 100%;
  text-align: center;
  color: ${({ libraryType, accentColor }: ButtonProps) => {
    return libraryType === 'primary'
      ? colors.white
      : accentColor || colors.darkBlue;
  }};
  background: ${({ libraryType, accentColor }: ButtonProps) =>
    libraryType === 'primary' ? accentColor || colors.darkBlue : colors.white};
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:disabled {
    background: ${({ libraryType, accentColor }: ButtonProps) =>
      libraryType === 'primary'
        ? accentColor || colors.darkBlue50
        : colors.white};
    border: 1px solid transparent;
    color: ${({ libraryType, accentColor }: ButtonProps) => {
      return libraryType === 'primary'
        ? colors.white
        : accentColor || colors.darkBlue50;
    }};
  }
`;

const ErrorInfoText = styled.div`
  color: red;
  font-weight: bold;
  font-size: 16px;
`;

// const EditableInfosWrapper = styled.div`
//   margin-top: 40px;
// `;
interface Props {
  handleDrawerToggle: () => void;
  appointment: any;
  apptType?: boolean;
}

const periods = getPeriodsFromSchedule(config.schedule);
const rapidPeriods = getPeriodsFromSchedule(config.rapidSchedule);

const EditAppoinment: React.FC<Props> = (appointment, handleDrawerToggle) => {
  const {
    form: {
      commitToAttend,
      agreeToCancel,
      location,
      phone,
      date,
      slot,
      firstName,
      lastName,
      minors,
    },
  } = useStepsState();
  const [showChangeDatetimeModal, setShowChangeDatetimeModal] = useState(false);
  const [showChangeLocationModal, setShowChangeLocationModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [
    showChangeApptAttendeeModal,
    setShowChangeApptAttendeeModal,
  ] = useState(false);
  const { params, appointments, Editappointments }: any = useAdminState();
  const {
    updateParams,
    setEditAppointment,
    updateAdminSlots,
  } = useAdminDispatch();

  const [buttonLoading, setButtonLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const selectedDate = date
    ? parse(date, config.dateFormat, new Date())
    : new Date();

  const checkinHandler = async (record: any) => {
    const checkInData = {
      phone: record.phone,
      bookingNo: record.acknowledgementId,
    };

    await checkInCrelioAppointment(checkInData)
      .then(() => {
        appointment.handleDrawerToggle();
        window.location.href = '/dashboard/checkin';
      })
      .catch((e) => {
        appointment.handleDrawerToggle();
        window.location.href = '/dashboard/checkin';
      });
  };

  const getHourDifference = (startDate: Date, endDate: Date) =>
    moment.duration(moment(endDate).diff(moment(startDate))).asHours();

  const handleEditAppointment = () => {
    if (appointment?.apptType) {
      if (
        getHourDifference(
          new Date(),
          new Date(Editappointments[0]?.slot?.date)
        ) > 72
      ) {
        checkinHandler(Editappointments[0]);
      } else {
        appointment.handleDrawerToggle();
        window.location.href = '/dashboard/checkin';
      }
    } else {
      appointment.handleDrawerToggle();
      window.location.href = '/dashboard/DayWiseAppointments';
    }
  };

  const locationStartDate = location
    ? parse(location.startDate, config.dateFormat, new Date())
    : null;

  // console.log("Editappointments[0].departureDateAndTime", Editappointments);

  let hourDiff: any = null;
  if (Editappointments !== undefined && Editappointments !== null && Editappointments[0].departureDateAndTime) {
    if (Editappointments[0].isRapidTest) {
      hourDiff = differenceInHours(
        new Date(Editappointments[0].departureDateAndTime),
        new Date(
          `${Editappointments[0].date} ${
            rapidPeriods[Editappointments[0].slot?.period]?.label.split('-')[0]
          }`
        )
      );
    } else if (Editappointments[0].slot !== undefined) {
      hourDiff = differenceInHours(
        new Date(Editappointments[0].departureDateAndTime),
        new Date(
          `${Editappointments[0].date} ${
            periods[Editappointments[0].slot?.period]?.label.split('-')[0]
          }`
        )
      );
    }
  }

  return (
    <div>
      {Editappointments !== null ? (
        <Container size="lg">
          <Content>
            <Formik
              initialValues={{
                commitToAttend,
                agreeToCancel,
                phone,
                departureDateAndTime: Editappointments[0].departureDateAndTime,
                name: `${firstName} ${lastName}`,
                isExpressSameDayTest: Editappointments[0]?.isExpressSameDayTest,
              }}
              onSubmit={() => {
                // setShowPaymentModal(true)
              }}
            >
              {({ dirty, isValid, values, setFieldValue }) => (
                <Form>
                  <Title>Testing Information</Title>
                 {Editappointments[0].departureDateAndTime && <>
                  <TestingInfoContainer>
                    <TestingInfo>
                      <PinIcon />
                      <strong>Departure date and time</strong>
                      <br />
                      {Editappointments[0].departureDateAndTime}
                    </TestingInfo>
                  </TestingInfoContainer>
                  <FormLabel label="Flight departure date & time (use the final leg of departure)">
                    <DatepickerField
                      dateAndTime={true}
                      name="departureDateAndTimedata"
                      showTimeSelect
                      minDate={
                        locationStartDate === null ||
                        isBefore(locationStartDate, new Date())
                          ? new Date()
                          : locationStartDate
                      }
                      onChange={(newDepartureDate: any) => {
                        //This time zone offset is for pacific daylight time
                        //Will need to add the other time zones offset later.
                        let getTimeZoneOffSet = new Date(
                          newDepartureDate
                        ).getTimezoneOffset();

                        const overTime = new Date(newDepartureDate).getHours();

                        let newDate = values.isExpressSameDayTest
                          ? subHours(
                              newDepartureDate,
                              overTime >= 12
                                ? 24
                                : config.vipSlots.minAdvanceHours
                            ) // startOfDay(newDepartureDate)
                          : // : subHours(newDepartureDate, config.maxAdvanceHours);
                            subHours(
                              newDepartureDate,
                              getTimeZoneOffSet == 420 ||
                                getTimeZoneOffSet == 300 ||
                                getTimeZoneOffSet == 240
                                ? 71
                                : config.maxAdvanceHours
                            );

                        if (isBefore(newDate, new Date())) {
                          newDate = startOfDay(new Date());
                        }

                        setFieldValue(
                          'departureDateAndTime',
                          format(newDepartureDate, config.dateTimeFormat)
                        );
                      }}
                    />
                  </FormLabel>
                  <div style={{ display: 'flex', margin: '0px 0px 25px 0px' }}>
                    <ReturnButton
                      onClick={async () => {
                        setButtonLoading(true);
                        setDisabled(true)
                        // format minors DOB
                        let localMinors = JSON.parse(
                          JSON.stringify(Editappointments[0]?.minors)
                        );
                        localMinors.map(
                          (e: any) =>
                            (e.birthDate = e.birthDate
                              ? format(new Date(e.birthDate), config.dateFormat)
                              : Editappointments[0]?.birthDate)
                        );

                        let params: any = {
                          ...Editappointments[0],
                          minors: localMinors,
                          departureDateAndTime: values.departureDateAndTime,
                        };
                        const udpateAppt = await updateAppointmentdata(params);

                        setButtonLoading(false);
                        if (udpateAppt) {
                          getAppointmentdata({
                            confirmationId: Editappointments[0]?.confirmationId,
                          }).then(async (response) => {
                            if (response.data.length > 0) {
                              // const periods: any = await getSlotsByLocationId(response.data[0].location?.qbenchCustomerId)
                              // updateAdminSlots(periods);

                              if (response.data && response.data.length) {
                                response.data[0]?.minors?.map((e: any) => {
                                  e.birthDate =
                                    e.birthDate !== null &&
                                    e.birthDate !== undefined &&
                                    Object.keys(e.birthDate).length > 0
                                      ? parse(
                                          e.birthDate,
                                          config.dateFormat,
                                          new Date()
                                        )
                                      : new Date();
                                });
                              }

                              setEditAppointment(response.data);
                              setButtonLoading(false);
                              setDisabled(false);
                              setShowChangeDatetimeModal(true);
                              return true;
                            } else {
                              return true;
                            }
                          });
                        }
                      }}
                      libraryType="primary"
                      disabled={buttonLoading}
                    >
                      {buttonLoading ? 'Processing...' : 'Update'}
                    </ReturnButton>
                  </div>
                  </>}
                  <TestingInfoContainer>
                    <TestingInfoRow>
                      <TestingInfo>
                        <PersonIcon />
                        <strong>Appointment for</strong>
                        <br />
                        {Editappointments[0]?.firstName}{' '}
                        {Editappointments[0]?.lastName}
                        <br />
                        {Editappointments[0]?.phone} ,{' '}
                        {Editappointments[0]?.email}
                        <br />
                        {Editappointments[0]?.birthDate}
                      </TestingInfo>
                    </TestingInfoRow>
                    <TestingInfoBtn
                      type="button"
                      onClick={() => setShowChangeApptAttendeeModal(true)}
                      size="sm"
                      libraryType="default"
                    >
                      Change Appointment Attendees
                    </TestingInfoBtn>
                  </TestingInfoContainer>
                  <TestingInfoContainer>
                    <TestingInfo>
                      <PinIcon />
                      <strong>{Editappointments[0]?.location?.name}</strong>
                      <br />
                      {Editappointments[0]?.location?.address1}{' '}
                      {Editappointments[0]?.location?.address2}
                    </TestingInfo>
                    <TestingInfoBtn
                      type="button"
                      onClick={() => setShowChangeLocationModal(true)}
                      size="sm"
                      libraryType="default"
                    >
                      Change Location
                    </TestingInfoBtn>
                  </TestingInfoContainer>
                  <TestingInfoContainer>
                    <TestingInfoRow>
                      <TestingInfo>
                        <CalendarIcon />
                        <strong>Date</strong>
                        <br />
                        {format(
                          Editappointments[0]?.date
                            ? parse(
                                Editappointments[0]?.date,
                                config.dateFormat,
                                new Date()
                              )
                            : new Date(),
                          config.weekDateFormat
                        )}
                      </TestingInfo>
                      <TestingInfo>
                        <ClockIcon />
                        <strong>Time</strong>
                        <br />
                        {Editappointments[0]?.slot &&
                          (Editappointments[0]?.isRapidTest
                            ? rapidPeriods[Editappointments[0]?.slot?.period]?.label
                            : periods[Editappointments[0]?.slot?.period]?.label)}
                      </TestingInfo>
                    </TestingInfoRow>
                    <TestingInfoBtn
                      type="button"
                      onClick={() => setShowChangeDatetimeModal(true)}
                      size="sm"
                      libraryType="default"
                    >
                      Change Date & Time
                    </TestingInfoBtn>
                  </TestingInfoContainer>

                  { !!Editappointments[0]?.testSelection && Editappointments[0]?.testSelection !== 'flight' ?
                    null :
                    Editappointments[0]?.slot !== undefined &&
                    Editappointments[0]?.isExpressSameDayTest == false &&
                    Editappointments[0]?.isRapidTest == false &&
                    hourDiff !== null &&
                    (hourDiff < 48 || hourDiff > 72) && (
                      <ErrorInfoText>
                        * The departure date and slot date and time must between
                        72 - 48 hrs.
                      </ErrorInfoText>
                    )}
                  { !!Editappointments[0]?.testSelection && Editappointments[0]?.testSelection !== 'flight' ?
                    null :
                    Editappointments[0]?.slot !== undefined &&
                    Editappointments[0]?.isExpressSameDayTest === true &&
                    Editappointments[0]?.isRapidTest == false &&
                    hourDiff !== null &&
                    (hourDiff < 12 || hourDiff > 72) && (
                      <ErrorInfoText>
                        * The departure date and slot date and time must between
                        72 - 12 hrs.
                      </ErrorInfoText>
                    )}
                  { !!Editappointments[0]?.testSelection && Editappointments[0]?.testSelection !== 'flight' ?
                    null :
                    Editappointments[0]?.slot !== undefined &&
                    Editappointments[0]?.isExpressSameDayTest === false &&
                    Editappointments[0]?.isRapidTest == true &&
                    hourDiff !== null &&
                    (hourDiff < 2 || hourDiff > 72) && (
                      <ErrorInfoText>
                        * The departure date and slot date and time must between
                        72 - 2 hrs.
                      </ErrorInfoText>
                    )}
                  {Editappointments[0]?.slot == undefined && (
                    <ErrorInfoText> Please Select the Slot time </ErrorInfoText>
                  )}
                </Form>
              )}
            </Formik>
          </Content>
          <ChangeLocationModal
            showModal={showChangeLocationModal}
            closeModal={() => {setShowChangeLocationModal(false); setShowChangeDatetimeModal(true)}}
          />
          <ChangeDateTimeModal
            showModal={showChangeDatetimeModal}
            closeModal={() => setShowChangeDatetimeModal(false)}
          />
          <PaymentModal
            showModal={showPaymentModal}
            closeModal={() => setShowPaymentModal(false)}
          />
          <EditAppointmentModal
            showModal={showChangeApptAttendeeModal}
            closeModal={() => setShowChangeApptAttendeeModal(false)}
          />

          <Button
            libraryType="primary"
            size="sm"
            type="submit"
            onClick={() => handleEditAppointment()}
            disabled={
              Editappointments[0]?.slot == undefined ||
              disabled ||
              showChangeDatetimeModal
            }
          >
            {appointment?.apptType ? 'Change & Checkin' : 'Change'}
          </Button>
        </Container>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default EditAppoinment;
