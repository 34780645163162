import React from 'react';

import { useStepsState } from '../provider';
import { useSharedState } from '../../shared/provider';
import { Wrapper } from '../../shared/components/styled';

import Loader from '../../shared/components/Loader';
import Header from '../../shared/components/Header';
import NewstartTwo from './NewStartScreen/NewStartTwo';
import Phone from './Phone';
import Email from './Email';
import Location from './Location';
import DateAndTime from './DateAndTime';
import Address from './Address/Address';
import PersonalInformation from './PersonalInformation/PersonalInformation';
import Symptoms from './Symptoms/Symptoms';
import Conditions from './Conditions';
import CovidContact from './CovidContact';
import Demographics from './Demographics';
import Consent from './Consent/Consent';
import ConfirmAndPay from './ConfirmAndPay/ConfirmAndPay';
import Confirmation from './Confirmation/Confirmation';
import { validateHostName } from '../../../utils';
import BookApptFromSchdule from './BookApptFromSchdule';
import TestSelection from '../../register/components/TestSelection';


export const steps = [
  TestSelection,
  // NewstartTwo,
  Phone,
  Email,
  Location,
  PersonalInformation,
  Address,
  DateAndTime,
  Symptoms,
  Conditions,
  CovidContact,
  Demographics,
  Consent,
  ConfirmAndPay,
  Confirmation,
];

export const skipsteps = [
  BookApptFromSchdule,
  Phone,
  Email,
  Location,
  PersonalInformation,
  Address,
  DateAndTime,
  Symptoms,
  Conditions,
  CovidContact,
  Demographics,
  Consent,
  ConfirmAndPay,
  Confirmation,
];


function Steps() {
  const { step, configured } = useStepsState();
  const { locations } = useSharedState();

  const Isvalid:boolean = validateHostName()

  const { form:{IsAirline} } = useStepsState();

  if (!configured || locations === null) {
    return <Loader />;
  }

  // const CurrentStep = steps[step];
  const CurrentStep = IsAirline && Isvalid ? steps[step] : skipsteps[step]

  const stepvalue = IsAirline && Isvalid ? 0 : -1;
  return (
    <Wrapper backgroundColor={step===0?'':'white'}>
      {step==0&&(<Header withoutBg isHelp isLanding />)}
      {step !== 0  && step !== stepvalue  && <Header />}
      <div style={{minHeight: "600px"}}>
        <CurrentStep />
      </div>
      {/* <Footer /> */}
    </Wrapper>
  );
}

export default Steps;
