import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import { cloneDeep, isEqual } from 'lodash';

import { useStepsState, useStepsDispatch } from '../../../../schedule/provider';
import { Slot } from '../../../../schedule/models';

import Modal from '../../../../shared/components/Modal';
import Slots from '../../../../shared/components/Slots';
import { colors } from '../../../../../styles/colors';
import { Button } from '../../../../shared/components/styled';
import { useAdminDispatch, useAdminState } from '../../../provider';
import { updateAppointmentdata } from '../../../api';
import { format, parse } from 'date-fns';
import { config } from '../../../../../config';
const Container = styled.div`
  max-width: 700px;
  min-height: 350px;
`;

const Title = styled.h2`
  color: ${colors.fullBlack};
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 13px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 240px;
  margin: 30px -5px 0;

  & > * {
    margin: 0 5px;
  }
`;

interface Props {
  showModal: boolean;
  closeModal: () => void;
}

const ChangeDateTimeModal: React.FC<Props> = ({ showModal, closeModal }) => {
  // const { form } = useStepsState();
  const { updateFormValues } = useStepsDispatch();

  const { Editappointments } = useAdminState();
  const locationdata =
    Editappointments !== null ? Editappointments[0].location : null;
  const appointmentdata: any =
    Editappointments !== null ? Editappointments[0] : null;
  const rollbackAppt = useMemo(() => cloneDeep(appointmentdata), [showModal]);
  let newSlot: Slot | null = appointmentdata.slot;
  localStorage.setItem('desiredSlot', '');
  const [loading, setLoading] = useState(false);
  return (
    <Modal open={showModal} onClose={closeModal}>
      <Container>
        <Title>Change date & time</Title>
        <Formik
          initialValues={{ slot: appointmentdata?.slot }}
          onSubmit={async () => {
            setLoading(true);
            if (
              localStorage.getItem('desiredSlot') !== '' &&
              JSON.parse(
                JSON.parse(JSON.stringify(localStorage.getItem('desiredSlot')))
              ) !== null
            ) {
              appointmentdata.slot = JSON.parse(
                JSON.parse(JSON.stringify(localStorage.getItem('desiredSlot')))
              );
              appointmentdata.date = appointmentdata.slot.date;
            }

            appointmentdata.minors.map(
              (e: any) =>
                (e.birthDate = e.birthDate
                  ? format(e.birthDate, config.dateFormat)
                  : appointmentdata.birthDate)
            );

            // console.log('appointmentdata', appointmentdata);

            // await updateAppointmentdata(appointmentdata);

            appointmentdata.minors.map(
              (e: any) =>
                (e.birthDate = e.birthDate
                  ? parse(e.birthDate, config.dateFormat, new Date())
                  : null)
            );

            //updateAppointmentdata(appointmentdata)
            setLoading(false);
            closeModal();
          }}
        >
          {() => (
            <Form>
              <Slots
                isAdmin={true}
                isNextStep={false}
                alwaysShowDatePicker
                appointment={appointmentdata}
                onFieldUpdate={(update) => {
                  newSlot = { ...update.slot } as Slot;
                  updateFormValues({ slot: update.slot });
                }}
                onChangeDate={(newDepartureDate) => {
                  updateFormValues({
                    slot: undefined,
                    reservationId: null,
                    date: format(newDepartureDate, config.dateFormat),
                  });
                }}
                isSlotsLoading={loading}
                setSlotsLoading={setLoading}
              />
              <ButtonsContainer>
                <Button
                  libraryType="default"
                  size="sm"
                  type="button"
                  onClick={() => {
                    closeModal();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  libraryType="primary"
                  size="sm"
                  type="submit"
                  disabled={loading}
                >
                  Change
                </Button>
              </ButtonsContainer>
            </Form>
          )}
        </Formik>
      </Container>
    </Modal>
  );
};

export default ChangeDateTimeModal;

//ltst