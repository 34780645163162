import React, { useMemo, useRef } from 'react';
import styled from 'styled-components';
import { useReactToPrint } from 'react-to-print';
import { PDFDownloadLink } from '@react-pdf/renderer';

import {
  Container,
  Content,
  PageHeader,
  PageTitle,
  Button,
} from '../../../shared/components/styled';
import { useStepsState } from '../../provider';
import { Breakpoints } from '../../../../dictionaries';
import { colors } from '../../../../styles/colors';
import PrintableConfirmation from './PrintableConfirmation';
import DownloadableConfirmation from './DownloadableConfirmation';
import { getPeriodsFromSchedule } from '../../../../utils';
import { config } from '../../../../config';

const Wrap = styled.div`
  background: ${colors.blue8};
  margin-bottom: -15px 0 33px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  & > * {
    margin: 15px 0;
  }

  @media (min-width: ${Breakpoints.sm}px) {
    flex-direction: row;
    margin: -10px 0 30px;

    & > * {
      margin: 0 10px;
    }
  }

  @media (min-width: ${Breakpoints.md}px) {
    padding: 46px 34px;
  }
`;

export const ConfirmationNumber = styled.div`
  text-align: center;

  @media (min-width: ${Breakpoints.sm}px) {
    text-align: left;
  }
  
  @media print {
    text-align: left;    
  }

  strong {
    display: block;
    font-size: 28px;
    font-weight: bold;
    margin-top: 6px;

    @media (min-width: ${Breakpoints.md}px) {
      font-size: 30px;
    }
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  width: 150px;
  margin: -5px 0;

  & > * {
    margin: 5px 0;
  }
`;

const Text = styled.p``;

const BoldedText = styled.p`
  font-weight:bold;
  line-height: 2;
`;
const UnorderedList = styled.ul`
  line-height: 2;
  margin-left:15px;
`;
const ListItem = styled.li`
&:before {
  content: "-";
  position: absolute;
  margin-left: -1em;
}
`;

const Confirmation: React.FC = () => {
  const { form, slotsList} = useStepsState();
  localStorage.setItem('testSelection','false');
  const printableRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => printableRef.current,
  });
  // const periods = useMemo(() => getPeriodsFromSchedule(config.schedule), []);

  return (
    <Container size="xl">
      <Content>
        <PageHeader>
          <PageTitle>
            {/* Your Appointment Is Confirmed. */}
            Thank you for your order!
            <br />
            {/* Don’t forget to download your appointment details. */}
            We are verifying your appointment details. You will receive a confirmation email shortly.
          </PageTitle>
        </PageHeader>
        <Wrap>
          <PrintableConfirmation
            ref={printableRef}
            form={form}
            // periods={periods}
            periods={slotsList}
          />
          <Buttons>
            {/* <PDFDownloadLink
              document={
                <DownloadableConfirmation form={form} periods={periods} />
              }
              fileName="worksite-labs-confirmation.pdf"
            >
              {({ loading }) => (
                <Button
                  type="button"
                  size="sm"
                  libraryType="default"
                  accentColor={colors.darkBlue}
                  disabled={loading}
                >
                  Download
                </Button>
              )}
            </PDFDownloadLink> */}
            {/* <Button
              type="button"
              onClick={handlePrint}
              size="sm"
              libraryType="default"
              accentColor={colors.darkBlue}
            >
              Print
            </Button> */}
          </Buttons>
        </Wrap>
        <Text>
          {/* Please remember to bring your ID and your confirmation number to your
          appointment. */}
          You should receive a confirmation email along with your confirmation number within 15 minutes of booking your appointment. If you do not receive a confirmation email, please make sure to check your spam/junk folder, then contact <a href={`mailto:support@worksitelabs.com`}> support@worksitelabs.com.</a>
        </Text>
        <BoldedText>
          Additional Information:
        </BoldedText>
        <UnorderedList>
          <ListItem>
            Please bring your ID and confirmation email/number to your appointment
          </ListItem>
          <ListItem>
            To make changes to your appointment and to view your test results when they are ready, visit <a href={"https://www.schedulecovidtesting.com/manage"}> https://www.schedulecovidtesting.com/manage </a>
          </ListItem>
          <ListItem>
            If you have any questions or need assistance, contact support@worksitelabs.com or call +1 213-948-2461. Live agents are available from 5AM-12AM PT,  7 days a week.
          </ListItem>
        </UnorderedList>
      </Content>
    </Container>
  );
};

export default Confirmation;
