// const projectId = 'wsl-multitenancy';   // UAT
const projectId = 'worksite-labs-ccb7d'; // Production
// const projectId = 'wsl-multitenancy-dev-ac13b'; // Development

const config = {
  firestoreCollections: {
    schedules: 'schedules',
    appointments: 'appointments',
    locations: 'locations',
    onetimepassword: 'onetimepassword',
    qbenchacknowledgement: 'qbenchacknowledgement',
    crelioacknowledgement: 'crelioacknowledgement',
    postalcodes: 'postalcodes',
    reschedulerefunds: 'reschedulerefunds',
    schedulesrapid: 'schedulesrapid',
    schedulewalkup: 'schedulewalkup',
    userdetails: 'userdetails',
  },
  firebase: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: 'worksite-labs-ccb7d.appspot.com',
//     apiKey: "AIzaSyDJ741TrCzKjfIZycryx1dX5s7pc6pTYr4",
// authDomain: "worksite-labs-ccb7d.firebaseapp.com",
// projectId: "worksite-labs-ccb7d",
// storageBucket: 'worksite-labs-ccb7d.appspot.com'
  },
  weekDateFormat: 'EEEE, MMM d, yyyy',
  dateTimeFormat: 'LL/dd/yyyy hh:mm a',
  dateFormat: 'LL/dd/yyyy',
  fileDateFormat: 'yyyyLLdd',
  maxSlotsByPeriod: 16,
  schedule: {
    startTime: '6:00a',
    endTime: '10:00p',
    periodDuration: 30,
  },
  rapidSchedule: {
    startTime: '6:00a',
    endTime: '10:00p',
    periodDuration: 60,
  },
  Reportschedule: {
    startTime: '6:00a',
    endTime: '10:00p',
    periodDuration: 30,
  },
  products: {
    standard: process.env.REACT_APP_STANDARD_TEST_ID,
    expedited: process.env.REACT_APP_EXPEDITED_TEST_ID,
    rapid: process.env.REACT_APP_RAPID_TEST_ID,
    antigen: process.env.REACT_APP_ANTIGEN_TEST_ID,
    standard_INT: process.env.REACT_APP_INT_STANDARD_TEST_ID,
    expedited_INT: process.env.REACT_APP_INT_EXPEDITED_TEST_ID,
    rapid_INT: process.env.REACT_APP_INT_RAPID_TEST_ID,
    antigen_INT: process.env.REACT_APP_INT_ANTIGEN_TEST_ID,
  },
  currency: 'usd',
  maxAdvanceHours: 72,
  minAdvanceHours: 48,
  vipSlots: {
    maxAdvanceHours: 72,
    minAdvanceHours: 12,
    lastExpressSlotIndex: 12,
  },
  rapidSlots: {
    maxAdvanceHours: 72,
    minAdvanceHours: 3,
    lastExpressSlotIndex: 12,
  },
  lastExpressSlotIndex: 1,
  reportData: {
    location: '450 Bauchet Street, Los Angeles CA 90012',
    reviewedBy: 'Anthony Victorio, MD',
    technician: 'Cherisse Heirs',
  },
  manageScreen: {
    upcoming: 5,
    inProcess: 4,
    completed: 3,
    missed: 2,
  },
  locationsfor36Hrs: [140, 162, 161, 160, 148, 147],
  internationalPricing: {
    destinations: [
      'JPN',
      'KNA',
      'ATG',
      'BRB',
      'CHN',
      'MAF',
      'TCA',
      'JAM',
      'NAN',
      'TTO',
    ],
    locations: [10, 33, 23, 2, 22, 220],
  },
  pauseBooking: {
    destinations: ['JPN', 'KNA', 'ATG', 'BRB', 'CHN', 'MAF', 'TCA'],
    locations: [0],
  },
  pauseSameDayBooking: {
    locations: [145, 148, 147, 161, 160],
  },
  nameMaxLimit: 50,
  addressMaxLimit: 100,
  websiteURL: 'https://www.schedulecovidtesting.com',
  locationsfor48Hrs: [145],
  baseURL: `https://us-central1-${projectId}.cloudfunctions.net`,
  qbenchCustomerId: {
    LosAngeles: 2,
    LasVegas_TheOrleans: 5,
    SanJose: 12,
    Location15: 15,
    Location16: 16,
    LongBeach: 23,
    Location57: 57,
    Phoenix_BlueSky_Airport: 62,
    SFO_Airport: 119,
    Oakland_HolidayInnExpress: 132,
    WestPalmBeach_AmericanResearchLabs: 147,
    NewYork_Queens_JFK: 140,
    FortLauderdale_AmericanResearchLabs: 148,
    PalmBeachGardens_AmericanResearchLabs: 160,
    WestPalmBeach_CTRX: 161,
    WoodSideHighSchool: 173,
    MenloAthertonHighSchool: 174,
    CarlmontHighSchool: 175,
    SequoiaHighSchool: 176,
    RedwoodHighSchool: 177,
    MILLS_MaintainanceOperations: 178,
    EastPaloAltoAcademy: 179,
    TIDEAcademy_LowerTIDE: 180,
    RedwoodShoresElementary: 189,
    BadenAdultSchool: 192,
    ElCaminoHighSchool: 193,
    WestboroughMiddleSchool: 194,
    SouthLosAngeles: 214,
    Brooklyn: 215,
    ProudBird:220,
    ArroyoUpperSchool: 232,
    PostIsolationRapidAntigenTest: 235,
    SFOAirport_BARTStation: 237,
    BoysGirlsClub: 238,
    PCMADestination: 261,
    SACRAMENTOPARKINGLOT: 22,
    Atlanta: 145,
    KernHealthSystems: 281,
    LasVegasSpringValley: 284,
    Austin: 105,
    Portland:33,
    RalstonMiddleSchool:188,
    SanFransiscoDownTown:152,
    SanDiagoVallyPark:34,
    seattleAngleLake:10
  },
  telemedicine: {
    drChronoUrl:
      'https://drchrono.com/scheduling/offices/dGhpcyBpcyAxNiBjaGFyc1QIVizJBmdlM_0hD8KKREg=?',
  },
  GoHealthApptAlert:`It looks like you’ve already registered for this clinic today, 
  we do not allow multiple visits to be created online for one day`
};

export { config };
