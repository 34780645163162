import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import mixpanel from 'mixpanel-browser';

import { ReactComponent as LogoIcon } from '../../../../assets/logo-icon.svg';
import { useStepsState, useStepsDispatch } from '../../provider';
import { colors } from '../../../../styles/colors';
import { config } from '../../../../config';
import { Breakpoints } from '../../../../dictionaries';
import {
  createPaymentIntent,
  createAppointment,
  releaseSlot,
  reserveSlot,
  updatePayment,
  trackRefersion, stripeCustomerId,
  getNpDestionation,
  cancelAppointment,
  checkReservationValidity
} from '../../api';
import { ReactComponent as X } from '../../../../assets/x.svg';

import Modal from '../../../shared/components/Modal';
import PaymentDataInput from './PaymentDataInput';
import { useSharedState } from '../../../shared/provider';
import { convertPricing } from '../../../../utils';
import refersion from '../../../../refersion';
import { updateRegister } from '../../../register/api';
import PaymentLogo from '../../../../assets/WSL_KO_Vertical.png'
import { Image } from 'semantic-ui-react';

const Container = styled.div`
  background: ${colors.rebrandBlack};
  padding: 30px 30px 80px;
  width: 100%;

  @media (min-width: ${Breakpoints.sm}px) {
    width: 500px;
    padding: 45px 30px 100px;
  }

  @media (min-width: ${Breakpoints.md}px) {
    width: 740px;
  }
`;

const Logo = styled(LogoIcon)`
  display: block;
  margin: 0 auto 35px;
`;

const InputGroup = styled.div`
  background: ${colors.white};
  padding: 14px 22px;
  border-radius: 5px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
  margin-bottom: 16px;
`;

const Button = styled.button`
  background: ${colors.rebrandOrange};
  width: 100%;
  border: 0;
  padding: 10px;
  font-size: 17px;
  line-height: 21px;
  letter-spacing: 1.7px;
  color: ${colors.white};
  font-weight: bold;
  box-shadow: -4px -4px 8px rgba(153, 153, 153, 0.15), 4px 4px 8px rgba(0, 0, 0, 0.3);
  border-radius: 32px;
  text-align: center;
`;

const CardElementWrapper = styled.div`
  margin-bottom: 20px;
  padding: 9px 20px;
  border-radius: 5px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
  background: ${colors.white};
`;

const Content = styled.div`
  position: relative;
  max-width: 470px;
  margin: 0 auto;
`;

const PaymentError = styled.div`
  position: absolute;
  font-size: 14px;
  color: ${colors.red};
  text-align: center;
  left: 0;
  width: 100%;
  opacity: ${({ error }: { error: string | null }) => (error ? 1 : 0)};
  transition: opacity 0.15s ease-in-out;
`;

const CloseModalButton = styled.button`
  position: absolute;
  right: 15px;
  top: 15px;
  width: 24px;
  height: 24px;
  background: none;
  border: 0;
  cursor: pointer;

  svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 22px;
    height: 22px;

    path {
      stroke: ${colors.white};
    }
  }
`;
const LogoImage = `
.image{
      @media (min-width: 320px) and (max-width: 480px) {
height:2rem;
}
}
`

interface Props {
  showModal: boolean;
  closeModal: () => void;
}

const PaymentModal: React.FC<Props> = ({ showModal, closeModal }) => {
  const { form, prices } = useStepsState();
  const {
    firstName,
    lastName,
    email,
    phone,
    registeringFor,
    guardian,
    testSelection,
    discountAmount,
    isAntigen
  } = form;
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState<string | null>(null);
  const [processing, setProcessing] = useState(false);
  const [CheckReservation, setCheckReservation] = useState(false);
  const [customerId, setCustomerId] = useState<string>('');
  const { goToNextStep, goToSkipStep, updateFormValues, goToSkip2Step } = useStepsDispatch();
  const [paymentIntent, setPaymentIntent] = useState<{
    id: string;
    client_secret: string;
    customer_id: string;
  } | null>(null);

  const {
    apptFromSchdule,
    travellType,
    destination,
    airline,
    userData,
    isUpgrade,
    promos,
  } = useSharedState();

  // const allPromocodes = promos?.filter((e:any) => {
  //   if( e.isAmountReduce && e.code){
  //       return true
  //   }
  // }).map((ee:any) => ee.code);

  const employeePromocode:any = promos?.filter((e:any) => {
    if( e.iswslEmployee){
        return true
    }
  }).map((ee:any) => ee.code)

  const adminLogin = localStorage.getItem('isAdmin');

  //The flag is to release and unrelease the slot as payment failure
  const [isSlotReleased, slotReleased] = useState<boolean>(false);
  const [isValidEmail, setValidEmail] = useState(false);

  const [npArray, setNpArray] = useState([]);
  useEffect(() => {
      getDestination();

    },[])
    const getDestination = async () =>{
      const arr:any = [];
      const response = await getNpDestionation({}).then((result:any)=>{            
              const data = result.data.data;
              data.forEach((element: any) => {
                  arr.push(element.value) 
              });     
              console.log("values",arr)       
      });
      setNpArray(arr)
  }

  const price = convertPricing(
    form.isExpressSameDayTest,
    form.isRapidTest,
    prices,
    form.travelType,
    form.destination,
    isAntigen,
    npArray,
    form.location?.isNpDestination        
  ); //form.isExpressSameDayTest ? prices.expedited : form.isRapidTest? prices.rapid : prices.standard;

  var amount: number = 0;

  let rfsn_id = localStorage.getItem('rfsn_v4_id');
  let rfsn_aid = localStorage.getItem('rfsn_v4_aid')
  let rfsn_cs = localStorage.getItem('rfsn_v4_cs');

  if (registeringFor === 'minorOnly') {
    let count = 0;
    count = form.minors.filter((item: any) => item.isCanceled !== true).length;
    amount = price * count - discountAmount;
  } else if (registeringFor === 'myself') {
    amount = price - discountAmount;
  }else if(form.promoCode === employeePromocode?.toString() && registeringFor === 'myselfAndMinor') {
    let count = 0;
    count = form.minors.filter((item:any) => item.isCanceled !== true).length;
    amount = (price + price * count) - discountAmount
  }else {
    let count = 0;
    count = form.minors.filter((item: any) => item.isCanceled !== true).length;
    amount = price + price * count - discountAmount;
  }

  // if (
  //   discountAmount !== 0 &&
  //   form.promoCode !== 'EYEPROD' &&
  //   form.promoCode !== 'GRAVY' &&
  //   form.promoCode !== 'EMPLOYEE'
  // ) {
  //   amount = amount - discountAmount;
  // }

  useEffect(() => {
    if(rfsn_cs && rfsn_aid){
      if(!userData?.customerId){
        getStripeCutomerId({
          email: email,
        })
      }
      else
      {
        setCustomerId(userData?.customerId)
      }
    }
  }, [])

  useEffect(() => {
    async function createIntent() {
      try {
        const result = await createPaymentIntent({
          amount: amount * 100,
          currency: config.currency,
          metadata: {
            location: form.location?.name,
            location_Id: form.location?.qbenchCustomerId,
            coupon_Id: form?.couponId,
            discountAmount: form?.discountAmount
          },
          description: 'COVID-19 Test',
          customer: customerId ? customerId : ''
        });

        setPaymentIntent({
          id: result.data.id,
          client_secret: result.data.client_secret,
          customer_id: result.data.customer
        });
      } catch (e) {
        setError('Payment failed');

        console.error('[Payment Intent API error]', e);
      }
    }

    if (showModal) {
      createIntent();
    }
    verifyEmail(email);
  }, [showModal, amount, email]);

  const verifyEmail = async (email: string) => {
    if (email && email.trim() && typeof email !== 'undefined') {
      email = email.trim();
      var pattern = new RegExp(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i
      );

      if (!pattern.test(email)) {
        setValidEmail(false);
      } else {
        setValidEmail(true);
      }
    } else {
      setValidEmail(false);
    }
  };

  const getStripeCutomerId = async (data: any) => {
    let response = await stripeCustomerId(data);
    if(response.data.status == 200){
      setCustomerId(response.data.data.id)
    }
}

const postTrackRefersion = async (customer_id: string) => {
  let response = await trackRefersion({
    checkout_id: customer_id,
    id: rfsn_id,
    url: config.websiteURL,
    aid: rfsn_aid,
    cs: rfsn_cs,
    appointmentDetails : {
      shipping : amount ,
      customer: {
        first_name: form?.firstName,
        last_name: form?.lastName,
        email: form.email,
    },

      items:
        {
        price : amount,
        quantity : registeringFor === 'minorOnly' ? form.minors.length : registeringFor === 'myself' ? 1 : form.minors.length + 1,
        sku : "covid test"
      }
    }
  });
  if(response.data.status == 200){
    // console.log('refersion -->', response?.data.data)
  }
} 

  return (
    <Modal open={showModal} onClose={closeModal} noPadding maxWidth={740} paddingTop={0}>
      <Container>
        <CloseModalButton
          type="button"
          onClick={() => {
            setError('');
            closeModal();
          }}
        >
          <X />
        </CloseModalButton>
        <Content>
          {/* <Logo /> */}
          <Image
            style={{
              display:'initial',
            }}
            className={LogoImage}
            src={PaymentLogo}/>
          <Formik
            initialValues={{
              name: `${firstName ? firstName : guardian?.firstName} ${
                lastName ? lastName : guardian?.lastName
              }`,
              email,
              phone,
            }}
            onSubmit={async (values) => {
              setError('');
              setProcessing(true);
              if(isUpgrade !== true){
                const response: any = await checkReservationValidity(
                  form?.reservationId
                );
                if (response.data.status == 400) {
                  updateFormValues({
                    slot: undefined,
                  });
                  setCheckReservation(true);
                  return;
                } else {
                  setCheckReservation(false);
                }
              }
              await verifyEmail(values.email);

              if (!stripe || !elements) {
                return;
              }
              if(form.payingMethod === 'Credit card' || form.payingMethod === 'Credit Card'){
                  updateFormValues({amount})
              }
              setProcessing(true);

              const cardElement = elements.getElement(CardElement);
              try {
                if (!cardElement || !paymentIntent) {
                  throw new Error('Payment failed');
                }

                const {
                  confirmationId,
                  agreeToCancel,
                  commitToAttend,
                  consentForTesting,
                  hipaaConfirmed,
                  confirmEmail,
                  ...appointment
                } = form;

                if (!isValidEmail) {
                  setError('Please enter valid email address');
                  setProcessing(false);
                } else {
                  setError('');

                  if(customerId !== ''){
                    refersion(paymentIntent.customer_id)
                    postTrackRefersion(paymentIntent.customer_id);
                  }

                  const payload = await stripe.confirmCardPayment(
                    paymentIntent.client_secret,
                    {
                      payment_method: {
                        card: cardElement,
                        billing_details: {
                          name: values.name,
                          phone: values.phone,
                          email: values.email,
                        },
                      },
                      receipt_email: values.email,
                    }
                  );

                  if (!payload.error) {
                    setError(null);

                    const result = await createAppointment({
                      ...appointment,
                      firstName:
                        form?.registeringFor === 'minorOnly' &&
                        !!form?.guardian?.firstName
                          ? form?.guardian?.firstName
                          : form?.firstName,
                      lastName:
                        form?.registeringFor === 'minorOnly' &&
                        !!form?.guardian?.lastName
                          ? form?.guardian?.lastName
                          : form?.lastName,
                      paymentIntentId: paymentIntent.id,
                      guardian:
                        form?.registeringFor === 'minorOnly' && !!form?.guardian
                          ? form?.guardian
                          : null,
                      hasInsurance: false,
                      insurance: null,
                      travelType: travellType,
                      destination: destination,
                      registeringFor: form?.registeringFor,
                      socialSecurityNumber: '',
                      // licenseBackCard: null,
                      // licenseFrontCard: null,
                      isNotHavePermanentAddress: !!userData?.isNotHavePermanentAddress ? userData?.isNotHavePermanentAddress : form.isNotHavePermanentAddress,
                      adminLogin:adminLogin && adminLogin!==null && adminLogin!==undefined ? true : false,
                      
                    });
                    updateRegister({
                      email: userData?.email,
                      isHubSpotContact: appointment?.isHubSpotContact,
                      collectionSite:
                        appointment?.location?.hubSpotCollectionSite ?? '',
                    });
                    if(rfsn_cs && rfsn_aid)
                    {
                      if(!userData?.customerId){
                        await updateRegister({ email: userData?.email,customerId:customerId})
                      }else{
                        setCustomerId(userData?.customerId)
                      }
                    }
                    
                    mixpanel.track('Successful payment');

                    closeModal();

                    await new Promise<void>((resolve) => {
                      setTimeout(() => {
                        resolve();
                      }, 500);
                    });
                    updatePayment(form?.reservationId, 3, form);
                    updateFormValues({ confirmationId: result.data.confirmationId,acknowledgementId:result.data.acknowledgementId});
                    if (isUpgrade) {
                      const docId = localStorage.getItem('docId');
                      await cancelAppointment({ id: docId, index: [] });
                      goToNextStep();
                    } else if (testSelection !== 'school') {
                      goToSkip2Step();
                    } else {
                      goToSkipStep();
                    }
                    setProcessing(false);
                  } else {
                    throw new Error(`Payment failed: ${payload.error.message}`);
                  }
                }
              } catch (e:any) {
                setError(e.message);
              }

              setProcessing(false);
            }}
          >
            {() => (
              <Form>
                <InputGroup style={{color: colors.Matterhorn}}>
                  <PaymentDataInput label="Name" name="name" />
                  <PaymentDataInput label="Email" name="email" />
                  <PaymentDataInput label="Phone" name="phone" />
                </InputGroup>
                <CardElementWrapper>
                  <CardElement
                    options={{
                      style: {
                        base: {
                          fontSize: '16px',
                          fontWeight:'400',
                          lineHeight:'22px',
                          color: colors.black,
                          '::placeholder': {
                            color: colors.Matterhorn,
                          },
                          backgroundColor: colors.white,
                          fontFamily: 'Inter, sand-serif',
                        },
                        invalid: {
                          color: colors.red,
                        },
                      },
                    }}
                  />
                </CardElementWrapper>
                <Button type="submit" disabled={processing} className='btn fill-btn'>
                  {processing ? 'Processing...' : `Pay $${amount}`}
                </Button>
                <PaymentError error={error}>{error}</PaymentError>
              </Form>
            )}
          </Formik>
        </Content>
      </Container>
    </Modal>
  );
};

export default PaymentModal;

//ltst