import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { colors } from '../../../../../styles/colors';
import pencil from '../../../../../assets/pencil.png';
import { Breakpoints } from '../../../../../dictionaries';
import { BrowserRouter as Router, useHistory } from 'react-router-dom';
// import Button as from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { getQbenchOrderById } from '../../../../manage/api';
import { MdExpandMore, MdExpandLess, MdDateRange } from 'react-icons/md';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PrintableConfirmation from '../../../../schedule/components/Confirmation/PrintableConfirmation';
import DownloadableConfirmation from '../../../../schedule/components/Confirmation/DownloadableConfirmation';
import { useReactToPrint } from 'react-to-print';
import { Button } from '../../../../shared/components/styled';
import { BiTimeFive, BiIdCard, BiDollarCircle } from 'react-icons/bi';
import { AiOutlinePicture } from 'react-icons/ai';
import {
  IoLocationOutline,
  IoPersonOutline,
  IoPeopleOutline,
  IoPersonAddOutline,
} from 'react-icons/io5';
import { AppointmentListItem, GovtID } from '../../../../shared/models';
import { getPeriodsFromSchedule } from '../../../../../utils';
import { useSharedDispatch, useSharedState } from '../../../../shared/provider';
import { format, isAfter, isSameDay, parse } from 'date-fns';
import { config } from '../../../../../config';
import Loader from '../../../../shared/components/Loader';
import CancelAppointmentModal from './CancelAppointmentModal';
import ChangeNameModal from '../../ChangePersonalInfo/ChangeNameModal';
import ChangeDobModal from '../../ChangePersonalInfo/ChangeDobModal';
import ChangePhoneModal from '../../ChangePersonalInfo/ChangePhoneModal';
import ChangeAddressModal from '../../ChangePersonalInfo/ChangeAddressModal';
import ChangeEmailModal from './ChangeEmail/ChangeEmailModal';
import ChangePasswordModal from './ChangePassword/ChangePasswordModal';
import { merge, keyBy, values, sumBy, filter, orderBy, includes } from 'lodash';
import ResultTracker from './ResultTracker';
import ChangeInsuranceModal from '../../ChangePersonalInfo/ChangeInsuranceModal';
import ChangeMinorModal from '../../ChangePersonalInfo/ChangeMinorModal';
import Minors from '../../PersonalInformation/Minors';
import {
  useStepsState,
  useRegisterStepsDispatch,
  newMinor,
} from '../../../provider';
import { Form, Formik } from 'formik';
import { ReactComponent as PlusIcon } from '../../../../../assets/plus.svg';
import NavigationButtons from '../../../../shared/components/NavigationButtons';
import { getUserInsurance, updateRegister, getGovtId, getAllClientPartnerSchools } from '../../../api';
import CancelApptModal from './CancelApptModal';
import ChangePassportModal from '../../ChangePersonalInfo/ChangePassportModal';
import ChangeStudentModal from '../../ChangePersonalInfo/ChangeStudentModal';
import { IDropDown } from '../../../models';
import DrivingLicenseModel from '../../DrivingLicenseModel';
import ChangeRaceModal from '../../ChangePersonalInfo/ChangeRaceModal';
import ChangeEthnicityModal from '../../ChangePersonalInfo/ChangeEthnicityModal';
import ChangeGenderModal from '../../ChangePersonalInfo/ChangeGenderModal';
import { EditOutlined } from '@ant-design/icons';
import { makeStyles } from "@material-ui/core/styles"
import { getNpDestionation } from '../../../../schedule/api';
import ChangeHubSpotModal from '../../ChangePersonalInfo/ChangeHubSpotModal';
const useStyles = makeStyles({
    hideImage: {
     display:"none"
    },
    showImage:{
        display:"block"
    }
  })

const Mainpage = styled.div`
  margin: 6% 0 0px 9%;
  padding: 0 0 0 20px;
  @media (max-width: ${Breakpoints.sm}px) {
    margin-top: 25%;
    margin-left: 17%;
  }
  @media (max-width: ${Breakpoints.md}px) {
    margin-top: 25%;
    margin-left: 17%;
  }
  @media (min-width: ${Breakpoints.md}px) and (max-width: ${Breakpoints.lg}px)  {
    margin-top: 15%;
    margin-left: 14%;
  }
`;
const PageTitle = styled.div`
  color: #101010;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 42px;
  margin-bottom: 50px;
  text-align: left;
  margin-top: 20px;
  @media (max-width: ${Breakpoints.sm}px) {
    font-size: 24px;
    margin-bottom: 20px;
  }
`;
const Space = styled.div`
  margin-top: 40px;
  width: 10px;
  height: 10px;
`;
const Cardtitle = styled.div`
  display: flex;
  gap: 40px;
  margin-bottom: 15px;
  @media (max-width: ${Breakpoints.sm}px) {
    gap: 10px;
    margin-bottom: 0px;
  }
`;
const TitleKey = styled.h3`
  color: rgba(38, 38, 38, 0.8);
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  min-width: 175px;
  @media (max-width: ${Breakpoints.sm}px) {
    min-width: 120px;
    font-weight: 600;
    font-size: 14px;
  }
`;
const TitleValue = styled.h4`
  margin: 0 0 14px 0;
  color: rgba(38, 38, 38, 0.8);
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  @media (max-width: ${Breakpoints.sm}px) {
    overflow-wrap: anywhere;
    padding: 0px 5px 0px 0px;
    font-weight: 600;
    font-size: 14px;
  }
`;
const PencilLogo = styled.div`
  height: 100%;
  width: auto;
  background: url(${pencil}) no-repeat center center;
  @media (max-width: ${Breakpoints.sm}px) {
    height: 50px;
  }
`;
const NamePencilLogo = styled.div`
  height: 100%;
  width: auto;
  background: url(${pencil}) no-repeat center center;
  @media (max-width: ${Breakpoints.sm}px) {
    height: 50px;
    margin-top: -12px;
  }
`;
const InsurancePencilLogo = styled.div`
  height: 75%;
  width: auto;
  background: url(${pencil}) no-repeat center center;
  @media (max-width: ${Breakpoints.sm}px) {
    margin-top: 10px;
  }
`;
const BtnContainer = styled.div`
margin-top: 50px;
margin-bottom: 50px;
display: flex;
flex-direction: row;
gap: 50px;
@media (max-width: ${Breakpoints.sm}px) {
  margin-top: 30px;
  display: block;
  margin-left: 10%;
}
@media (min-width: ${Breakpoints.sm}px) and (max-width: ${Breakpoints.md}px)  {
  gap: 10px;
  width: 90%;
}
`;
const ChangeButton = styled.div`
  font-size: 16px;
  & > * {
    margin: 5px 0;
    background-color: white;
    border: 1px solid #ff802e;
    border-radius: 32px;
    color: #101010;
    padding: 3px;
    width:200px;
    height: 50px;
  }

`;
const WrapText = styled.div`
  color: black;
  font-size: 24px;
  font-weight: bold;
  text-align: left;
  @media (max-width: ${Breakpoints.sm}px) {
    font-size: 18px;
    text-align: left;
    line-height: 25px;
  }
`;
const TextWrapContainer = styled.div`
  background: ${colors.blue8};
  margin: 30px 10px;
  margin-bottom: 60px;
  width: 80%;
  height: 150px;
  padding: 40px 45px;
  @media (max-width: ${Breakpoints.sm}px) {
    width: 90%;
    height: 100px;
    padding: 20px;
  }
`;
const WrapButton = styled.div`
  white-space: nowrap;
  align-items: center;
  border: 1px solid #4ab175;
  border-radius: 5px;
  font-size: 18px;
  padding: 15px 40px;
  font-weight: bold;
  width: 23%;
  cursor: pointer;
  background-color: #4ab175;
  color: #fff;
  box-shadow: 2px 5px #cbd0cb;
  @media (max-width: ${Breakpoints.sm}px) {
    margin: 0;
    font-size: 18px;
    display: flex;
    justify-content: center;
    padding: 15px;
    width: 100%;
  }
`;
const Logotag = styled.div``;
const ListTag = styled.div``;
const ListRow = styled.div`
  display: flex;
  gap: 40px;
  align-items: center;
  @media (max-width: ${Breakpoints.sm}px) {
    gap: 0px;
  }
`;
const ListRowName = styled.div`
  display: grid;
  gap: 0px;
  align-items: flex-start;
  @media (max-width: ${Breakpoints.sm}px) {
  }
`;

const SubContainer = styled.div`
  margin-left: 42px;
  margin-top: 20px;
  width: 90%;
`;
const Text = styled.div`
  text-align: left;
  font-size: 15px;
  font-weight: 500;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 30px;
  display: flex;
  gap: 20px;
`;

const SubTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-style: italic;
  margin-bottom: 10px;
`;
const Confirmbtn = styled.div`
  width: 20%;
  border: 0;
  align-items: center;
  text-align: center;
  border: 1px solid #2a5f87;
  line-height: 1.6;
  padding: 5px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  display: inline-block;
  background-color: #2a5f87;
  color: #ffff;
`;
const Returnbtn = styled.div`
  width: 20%;
  border: 0;
  align-items: center;
  text-align: center;
  border: 1px solid #2a5f87;
  line-height: 1.6;
  padding: 5px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  display: inline-block;
  background-color: #fff;
  color: #2a5f87;
`;
const ExpandWrapContainer = styled.div`
  background: ${colors.rebrandBlack};
  margin: 30px 5px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  border-radius: 12px;
  @media (max-width: ${Breakpoints.sm}px) {
    padding: 40px 20px 40px 35px !important;
    margin: 0;
    margin-bottom: 25px;
    margin-right: 60px;
    left: 3%;
    position: relative;
    width: 90%;
  }
  @media (max-width: ${Breakpoints.lg}px) {
    padding: 40px 20px !important;
    width: 90%;
  }
`;
export const ConfirmationNumber = styled.div`
  display: block;
  font-size: 24px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 20px;
  @media (max-width: ${Breakpoints.sm}px) {
    font-size: 18px;
  }
`;
const FlexboxRight = styled.div`
  float: left;
`;
const Expandrow1 = styled.div`
  display: flex;
  gap: 20px;
`;
const ExpandIcon = styled.div`
  position: relative;
  color: ${colors.grey};
`;
const Expandtext = styled.div`
  font-size: 16px;
  font-weight: 500;
`;
const FlexboxCenter = styled.div`
  margin-right: 30%;
  margin-left: auto;
`;
const Expand = styled.div`
  color: ${colors.rebrandYellow};
  display: inline-flex;
  align-items: center;
  position: relative;
  font-size: 16px;
  margin-top: 40px;
  cursor: Pointer;
`;
const FlexboxLeft = styled.div`
  float: right;
  @media (max-width: ${Breakpoints.sm}px) {
    float: left;
    position: relative;
  }
`;
const ExpandWrapbutton = styled.div`
  display: flex;
  flex-direction: column;
  & > * {
    margin: 5px 0;
    background-color: white;
    border: 1px solid #2a5f87;
    border-radius: 5px;
    color: #2a5f87;
    padding: 6px 40px;
  }
`;
const WrapExpand = styled.div``;
const ButtonWrapExpand = styled.div`
  width: 60%;
  @media (max-width: ${Breakpoints.sm}px) {
    width: 100%;
  }
  @media (max-width: ${Breakpoints.md}px) and (min-width: ${Breakpoints.sm}px) {
    width: 80%;
  }
`;
const ExapndlistTag = styled.div`
  display: flex;
  gap: 30px;
  @media (max-width: ${Breakpoints.sm}px) {
    display: block;
  }
`;
const TimeAndTest = styled.div`
  display: grid;
  row-gap: 30px;
`;
const PaymentMethod = styled.div`
  display: grid;
  row-gap: 30px;
`;

const Timetag = styled.div`
  display: flex;
  gap: 20px;
  @media (max-width: ${Breakpoints.sm}px) {
    margin-bottom: 30px;
  }
`;
const Payment = styled.div`
  display: flex;
  gap: 20px;
  @media (max-width: ${Breakpoints.sm}px) {
    margin-bottom: 30px;
  }
`;
const Testtag = styled.div`
  display: flex;
  gap: 20px;
`;
const Datetag = styled.div`
  display: flex;
  gap: 20px;
`;
const Patientstag = styled.div`
  display: flex;
  gap: 20px;
  @media (max-width: ${Breakpoints.sm}px) {
  }
`;
const ExpandCenter = styled.div``;
const ExpandVl = styled.div`
  border-left: 1px solid white;
  height: 100%;
`;
const DateAndPatient = styled.div`
  display: grid;
  row-gap: 30px;
`;
const ExpandRight = styled.div`
  display: grid;
  width: fit-content;
  row-gap: 30px;
  position: relative;
  top: -50px;
  @media (max-width: ${Breakpoints.sm}px) {
    top: 30px;
  }
`;
const Hide = styled.div`
  margin-top: 20px;
  display: none;
`;
const RowList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: ${Breakpoints.sm}px) {
    display: grid;
  }
`;
const FirstNameTag = styled.div`
  display: flex;
  gap: 40px;
  width: 560px;
  overflow-wrap: anywhere;
  align-items: flex-start;
  @media (max-width: ${Breakpoints.sm}px) {
    width: 300px;
    gap: 0px;
  }
`;
const EmployeeIdTag = styled.div`
  display: flex;
  flex-direction: row;
  width: 500px;
  overflow-wrap: anywhere;
  align-items: flex-start;
  margin-left: 60px;
  gap: 40px;
  @media (max-width: ${Breakpoints.sm}px) {
    margin-left: 30px;
    gap: 0px;
    width: 270px;
  }
`;
const LastNameTag = styled.div`
  display: flex;
  flex-direction: row;
  width: 500px;
  overflow-wrap: anywhere;
  align-items: flex-start;
  margin-left: 60px;
  gap: 40px;
  @media (max-width: ${Breakpoints.sm}px) {
    margin-left: 30px;
    gap: 0px;
    width: 270px;
  }
`;
const InsuranceContainer = styled.div`
  background: ${colors.white};
  border: 0.5px solid rgba(36, 61, 77, 0.14);
  margin: 0px 5px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 80%;
  // Drop Shadow
  box-shadow: -4px -4px 8px rgba(153, 153, 153, 0.05),
    4px 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  padding: 30px 20px 30px 60px;
  &:hover {
    background: #FDD56B;
  }
  @media (max-width: ${Breakpoints.sm}px) {
    padding: 40px 30px 50px 20px !important;
    margin: 0;
    left: 3%;
    position: relative;
    width: 90%;
    &:hover {
      background: none;
    }
  }
  @media (max-width: ${Breakpoints.lg}px) and (min-width: ${Breakpoints.sm}px) {
    left: 3%;
    width: 95%;
  }
`;
const SubNameandDob = styled.div`
  display: grid;
  @media (max-width: ${Breakpoints.sm}px) {
    row-gap: 30px;
  }
`;
const InsurancelistTag = styled.div`
  display: flex;
  gap: 60px;
  @media (max-width: ${Breakpoints.sm}px) {
    display: block;
  }
`;
const Card = styled.div`
  margin-top: 20px;
  @media (max-width: ${Breakpoints.sm}px) {
  }
`;
const EditTag = styled.div`
  display: flex;
  gap: 30px;
  @media (max-width: ${Breakpoints.sm}px) {
    gap: 10px;
  }
`;
const TitleTag = styled.a`
  margin-bottom: 40px;
  text-align: left;
  margin-top: 30px;
  font-size: 18px;
  @media (max-width: ${Breakpoints.sm}px) {
  }
`;
const PageSubTitle = styled.h3`
  margin: 10px 0 10px 0;
`;
const WrapContainer = styled.div`
  background: ${colors.rebrandBlack};
  margin-bottom: 30px;
  align-items: center;
  padding: 20px;
  width: 55%;
  height: 85px;
  border: 0.5px solid rgba(36, 61, 77, 0.14);
  border-radius: 12px;
  @media (max-width: ${Breakpoints.sm}px) {
    position: relative;
    width: 95%;
    height:45%
  }
  @media (max-width: ${Breakpoints.md}px) {
    position: relative;
    width: 90%;
    left: 3%;
  }
  @media (min-width: ${Breakpoints.md}px) and (max-width: ${Breakpoints.lg}px)  {
    width: 90%;
  }
  @media (max-width: ${Breakpoints.md}px) {
    position: relative;
    width: 90%;
    left: 3%;
  }

`;
const WrapContent = styled.div`
display: flex;
position: relative;
justify-content: space-between;
gap: 0px;
@media (max-width: ${Breakpoints.sm}px) {
  display: grid;
  gap: 5px;
  padding: 0 8px 0 20px;
}
@media (max-width: ${Breakpoints.lg}px) and (min-width: ${Breakpoints.sm}px) {
  display: flex;
  position: relative;
  justify-content: space-between;
  gap: 0px;
}
`;
const Flexbox = styled.div`
display: flex;
align-items: center;
width: 33%;
&:nth-child(1) {
  border: none;
  border-right: 1px solid #2a5f87;
  margin-right: 15px;
}
&:nth-child(3) {
  width: 100px;
}
@media (max-width: ${Breakpoints.sm}px) {
  margin-bottom: 20px;
  &:nth-child(1),
  &:nth-child(2) {
    width: 100%;
    border-right: none;
  }
}
@media (max-width: ${Breakpoints.lg}px) and (min-width: ${Breakpoints.sm}px) {
  &:nth-child(1),
  &:nth-child(2),
  &:nth-child(3) {
    width: 33%;
    border-right: none;
  }
 }
`;

const FlexboxIcon = styled.div`
  position: relative;
  color: ${colors.grey};
  display: inline-flex;
`;
const FlexboxText = styled.div`
  color: ${colors.white};
  display: block;
  font-size: 14px;
  font-weight: 500;
  padding: 10px;
  word-break: break-all;
  width: 200px;
`;
const MinorContainer = styled.div`
width: 55%;
  @media (max-width: ${Breakpoints.md}px) {
    width: 90%;
  }

  @media (min-width: ${Breakpoints.md}px) and (max-width: ${Breakpoints.lg}px)  {
    width: 90%;
  }
`;
const AddMinor = styled.button`
  font-family:'Quicksand';
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: none;
  padding: 20px;
  margin-top: 30px;
  border: 2px dashed ${colors.green};
  border-radius: 10px;
  color: ${colors.rebrandBlack};
  margin-bottom: 20px;
  & > * {
    margin: 0 5px;
  }
  @media (max-width: ${Breakpoints.md}px) {
    margin-left: 18px !important;
  }
  @media (max-width: ${Breakpoints.sm}px) {
    margin-left: 12px !important;
  }
`;
const periods = getPeriodsFromSchedule(config.schedule);

function checkDisable(
  params: any,
  key: any,
  print: any,
  cancel: any,
  enableEditAppointment: (data: any) => void,
  handleCancelApptOpen: () => void,
  setCancel: (data: any) => void
) {
  let disable: any = params.isWalkUpTest ? true : false;
  params.qbenchAcknowledgement.map((res: any) => {
    if (res.results === '' || res.results === null) {
      disable = false;
    } else {
      disable = true;
    }
  });
  return (
    <>
      <Button
        type="button"
        size="sm"
        libraryType="default"
        accentColor={colors.darkBlue}
        disabled={disable}
        className='btn elevated-btn'
        onClick={() => {
          enableEditAppointment(params);
        }}
      >
        Edit{' '}
      </Button>
      <Button
        type="button"
        size="sm"
        libraryType="default"
        className='btn elevated-btn'
        accentColor={colors.darkBlue}
        disabled={disable}
        onClick={() => {
          setCancel({ key: cancel.key == key ? -1 : key });
          handleCancelApptOpen();
          // OpenCancelAppointment({ key: print.key == key ? -1 : key });
        }}
      >
        Cancel{' '}
      </Button>
    </>
  );
}

const Profile: React.FC = () => {
  let history = useHistory();
  const classes = useStyles()
  const redirect = () => {
    updateFormValues({ minors: [] });
    setApptFlow(true);
    updateapptFromSchdule(false);
    history.push('/bookappointment');
  };

  const {
    form: { minors },
    form,
  } = useStepsState();
  const { updateFormValues } = useRegisterStepsDispatch();
  const { userData, locations, validAddress, tenant, promos } = useSharedState();
  const {
    setloggedUserData,
    setApptFlow,
    updateEditConfirmationId,
    updateInsuranceList,
    updateGovtId,
    updateapptFromSchdule,
  } = useSharedDispatch();

  const [openEmailDialog, setOpenEmailDialog] = React.useState<boolean>(false);
  const [openHubSpot, setOpenHubSpot] = useState<boolean>(false);
  const [openPasswordDialog, setOpenPasswordDialog] =
    React.useState<boolean>(false);
  const [openVerifyEmailDialog, setOpenVerifyEmailDialog] =
    React.useState<boolean>(false);
  const [openVerifyPasswordDialog, setOpenVerifyPasswordDialog] =
    React.useState<boolean>(false);
  const [OpenCancelAppointment, setOpenCancelAppointment] =
    React.useState<boolean>(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [loading, setLoading] = useState(false);
  const [btnloading, setBtnLoading] = useState(false);
  const [expand, setExpand] = useState({ key: -1 });
  const [print, setPrint] = useState({ key: -1 });
  const [cancel, setCancel] = useState({ key: -1 });
  const [reload, setReload] = useState(false);

  const [flag, setflag] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const { userGovtId } = useSharedState();
  let govtIdData: any = userGovtId;
  const printableRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => printableRef.current,
  });
  const [printFunction, setPrintFunction] = useState(handlePrint);

  const [upcomingAppointment, setUpcomingAppointment] = useState<
    AppointmentListItem[]
  >([]);
  const [upcomingInsurance, setUpcomingInsurance] = useState<any>([]);
  const [governmentId, setGovernmentId] = useState<GovtID[]>([]);
  const [isInsurance, setIsInsurance] = useState(false);
  const [isGovtId, setIsGovtId] = useState(false);
  const [schoolDropDown, setSchoolDropDown] = useState<IDropDown[]>([]);
  const [npArray, setNpArray] = useState<any>([]);
  const getDestination = async () => {
    const arr:any = [];
    const response = await getNpDestionation({}).then((result: any) => {
      const data = result.data.data;
      data.forEach((element: any) => {
          arr.push(element.value)
      });
    });
    setNpArray(arr)
   }
  const getAppointmentsData = async () => {
    setLoading(true);
    const loggedUserData = JSON?.parse(localStorage?.getItem('user') || '');
    const resultData: any = await getQbenchOrderById({
      email:
        loggedUserData?.email !== undefined &&
        loggedUserData?.email !== null &&
        loggedUserData?.email !== ''
          ? loggedUserData.email
          : userData?.email,
    });
    const fetchedAppointment = resultData?.data?.['appointments'];
    let upcommingData: AppointmentListItem[] = [];
    fetchedAppointment.forEach((appt: AppointmentListItem) => {
      if (
        appt?.slot?.date &&
        (isAfter(
          parse(appt?.slot?.date, config.dateFormat, new Date()),
          new Date()
        ) ||
          isSameDay(
            parse(appt?.slot?.date, config.dateFormat, new Date()),
            new Date()
          ))
      ) {
        upcommingData.push(appt);
      }
    });
    upcommingData = await orderBy(upcommingData, ['slot.date'], ['asc']);

    setUpcomingAppointment(upcommingData);

    // get first insurance detail
    // if (upcomingInsurance.length == 0) {
    //   const insuranceval: any = [];
    //   upcommingData.map((value, index) => {
    //     if (value.hasInsurance) {
    //       if (
    //         value?.slot?.date &&
    //         (isAfter(
    //           parse(value?.slot?.date, config.dateFormat, new Date()),
    //           new Date()
    //         ) ||
    //           isSameDay(
    //             parse(value?.slot?.date, config.dateFormat, new Date()),
    //             new Date()
    //           ))
    //       ) {
    //         insuranceval.push(value);
    //       }
    //       setIsInsurance(true);
    //     }
    //   });
    //   console.log(insuranceval, '123');
    //   setUpcomingInsurance(insuranceval);
    // }
    setLoading(false);
  };

  const allPromocodes =  promos?.filter((e:any) =>  e.isAmountReduce).map((ee:any) => ee.code)
  const employeePromocode = promos?.filter((e:any) =>  e.iswslEmployee).map((ee:any) => ee.code)

  const getGovernmentId = async () => {
    try {
      // let IdData=await getGovtId(userData?.email);
      let IdData = await getGovtId({ email: userData?.email });
      if (IdData?.data.status === 200) {
        setGovernmentId(IdData.data.data);
        updateGovtId(IdData.data.data);
        setIsGovtId(true);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const getInsurance = async () => {
    try {
      let updatedData = await getUserInsurance(userData?.email);

      if (updatedData?.data?.status === 200) {
        let sortedInsurance = updatedData?.data?.data.sort((p1:any, p2:any) => (p1?.createdDate > p2?.createdDate) ? 1 : (p1?.createdDate < p2?.createdDate) ? -1 : 0);
        setUpcomingInsurance(sortedInsurance);
        updateInsuranceList(updatedData?.data?.data);
        setIsInsurance(true);
      }
    } catch (e) {
      setLoading(false);
    }
  };

  const updateUserDetails = async () => {
    try {
      let updatedData = await updateRegister({ email: userData?.email });

      if (updatedData?.data?.status === 200) {
        setloggedUserData(updatedData?.data?.data);
        localStorage.setItem('user', JSON.stringify(updatedData?.data?.data));
      }
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDestination()
    updateFormValues({ cPartnerID: 'WSL001' });
    if (validAddress === false) {
      setopenAddressDialog(true);
    }
  }, [validAddress]);

  useEffect(() => {
    let schoolList = async () => {
      getAppointmentsData();
      getInsurance();
      getGovernmentId();
      updateUserDetails();
      setApptFlow(true);
      var dropDown: any = [];
      var filtered = await getAllClientPartnerSchools();

      filtered.data.filter((val: any) => {
        if (val.qbenchCustomerId !== 63) {
          console.log('val', val);
          dropDown.push({ label: val.schools, value: val.schools });
          return val;
        }
      });
      console.log('dropDown', dropDown);
      setSchoolDropDown(dropDown);
    };
    schoolList();
  }, [userData?.email, isInsurance]);

  const callVerifyEmailToggle = () => {
    handleEmailClose();
    handleVerifyEmailClickOpen();
  };
  const callVerifyPasswordToggle = () => {
    handlePasswordClose();
    handleVerifyPasswordClickOpen();
  };
  const handleEmailClickOpen = () => {
    setOpenEmailDialog(true);
  };
  const handleVerifyEmailClickOpen = () => {
    setOpenVerifyEmailDialog(true);
  };
  const handlePasswordClickOpen = () => {
    setOpenPasswordDialog(true);
  };
  const handleVerifyPasswordClickOpen = () => {
    setOpenVerifyPasswordDialog(true);
  };
  const handleEmailClose = () => {
    setOpenEmailDialog(false);
  };
  const handleVerifyEmailClose = () => {
    setOpenVerifyEmailDialog(false);
  };
  const handlePasswordClose = () => {
    setOpenPasswordDialog(false);
  };
  const handleVerifyPasswordClose = () => {
    setOpenVerifyPasswordDialog(false);
  };
  const handleCancelApptOpen = () => {
    setOpenCancelAppointment(true);
  };
  const handleCancelApptClose = () => {
    setOpenCancelAppointment(false);
  };
  const handleCancelReload = () => {
    setOpenCancelAppointment(false);
    getAppointmentsData();
  };

  const enableEditAppointment = async (value: any) => {
    updateEditConfirmationId(value.confirmationId);
    localStorage.setItem('Appointmentdata', JSON.stringify(value));
    localStorage.setItem('confirmationId', value.confirmationId);
    history.push('/user/editAppointment');
  };
  const [openNameDialog, setopenNameDialog] = React.useState<boolean>(false);
  const handleNameOpen = () => {
    setopenNameDialog(true);
  };
  const handleNameClose = () => {
    setopenNameDialog(false);
  };
  const [openDobDialog, setopenDobDialog] = React.useState<boolean>(false);
  const handleDobOpen = () => {
    setopenDobDialog(true);
  };
  const handleDobClose = () => {
    setopenDobDialog(false);
  };
  const [openPhoneDialog, setopenPhoneDialog] = React.useState<boolean>(false);
  const handlePhoneOpen = () => {
    setopenPhoneDialog(true);
  };
  const handlePhoneClose = () => {
    setopenPhoneDialog(false);
  };
  const [openAddressDialog, setopenAddressDialog] =
    React.useState<boolean>(false);
  const handleAddressOpen = () => {
    setopenAddressDialog(true);
  };
  const handleAddressClose = () => {
    setopenAddressDialog(false);
  };
  const [openGenderDialog, setopenGenderDialog] =
    React.useState<boolean>(false);
  const handleGenderOpen = () => {
    setopenGenderDialog(true);
  };
  const handleGenderClose = () => {
    setopenGenderDialog(false);
  };

  const [openRaceDialog, setopenRaceDialog] = React.useState<boolean>(false);
  const handleRaceOpen = () => {
    setopenRaceDialog(true);
  };
  const handleRaceClose = () => {
    setopenRaceDialog(false);
  };

  const [openEthnicityDialog, setopenEthnicityDialog] =
    React.useState<boolean>(false);
  const handleEthnicityOpen = () => {
    setopenEthnicityDialog(true);
  };
  const handleEthnicityClose = () => {
    setopenEthnicityDialog(false);
  };
  const [openInsuranceDialog, setopenInsuranceDialog] =
    React.useState<boolean>(false);
  const [openGovtIdDialog, setopenGovtIdDialog] =
    React.useState<boolean>(false);
  const handleInsuranceOpen = () => {
    setopenInsuranceDialog(true);
  };
  const handleGovtIdDialogOpen = () => {
    setopenGovtIdDialog(true);
  };
  const handleInsuranceClose = () => {
    setopenInsuranceDialog(false);
    setLoading(true);
    getInsurance()
      .then(() => {
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  const handleGovtIdClose = () => {
    setopenGovtIdDialog(false);
    setLoading(true);
    getGovernmentId()
      .then(() => {
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  const handleInsuranceReload = () => {
    setopenInsuranceDialog(false);
    setUpcomingInsurance([]);
    reload ? setReload(false) : setReload(true);
  };
  const [openMinorDialog, setopenMinorDialog] = React.useState<boolean>(false);
  const handleMinorOpen = () => {
    setopenMinorDialog(true);
  };
  const handleMinorClose = () => {
    setopenMinorDialog(false);
  };
  const [editKey, setEditKey] = useState({ key: -1 });
  const [addMinor, setAddMinor] = useState(false);

  const [diableEditButton, setAllowEditButton] = useState(false);
  const [passportDialog, setPassportDialog] = useState(false);
  const [studentIdDialog, setStudentIdDialog] = useState(false);

  let userDataMinors: any =
    userData?.hasMinors !== undefined && userData?.hasMinors
      ? userData?.minors
      : [];
  let reult = [userDataMinors, minors];
  const updateChangeOnClick = async (values: any) => {
    setBtnLoading(true);
    const tmp = [...userDataMinors];
    values.minors.forEach((minor: any) => {
      tmp.push({
        ...minor,
        birthDate:
          minor.birthDate && format(minor.birthDate, config.dateFormat),
      });
    });
    try {
      let resultdata = await updateRegister({
        email: userData?.email,
        hasMinors: true,
        minors: [...tmp],
      });

      if (resultdata?.data?.status === 200) {
        setloggedUserData(resultdata?.data?.data);
        localStorage.setItem('user', JSON.stringify(userData));
        setAddMinor(false);
        setBtnLoading(false);
      }
      setBtnLoading(false);
    } catch (e) {
      console.error(e);
      setBtnLoading(false);
    }
  };
  if (loading) {
    return <Loader />;
  }
  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  return (
    <Mainpage>
      <PageTitle>My profile</PageTitle>
      <ListTag>
        <ListRowName>
          <LastNameTag>
            <Cardtitle>
              <TitleKey style={{ marginTop: '0px' }}>Email</TitleKey>
            </Cardtitle>
            <TitleValue>{userData?.email}</TitleValue>
          </LastNameTag>
        </ListRowName>
        <ListRowName>
          <FirstNameTag>
            <Cardtitle onClick={handleNameOpen}>
              <Logotag style={{ width: '20px' }}>
                {/* <NamePencilLogo /> */}
                <EditOutlined />
              </Logotag>
              <TitleKey style={{ marginTop: '0px' }}>First Name</TitleKey>
            </Cardtitle>
            <TitleValue>{userData?.firstName}</TitleValue>
          </FirstNameTag>
          <LastNameTag>
            <Cardtitle>
              <TitleKey style={{ marginTop: '0px' }}>Middle Name</TitleKey>
            </Cardtitle>
            <TitleValue>{userData?.middleName}</TitleValue>
          </LastNameTag>
          <LastNameTag>
            <Cardtitle>
              <TitleKey style={{ marginTop: '0px' }}>Last Name</TitleKey>
            </Cardtitle>
            <TitleValue>{userData?.lastName}</TitleValue>
          </LastNameTag>
          {tenant === 'kern' ? (
            <EmployeeIdTag>
              <Cardtitle>
                <TitleKey style={{ marginTop: '0px' }}>Employee Id</TitleKey>
              </Cardtitle>
              <TitleValue>{userData?.employeeId}</TitleValue>
            </EmployeeIdTag>
          ) : (
            ''
          )}
        </ListRowName>
        {/* Popup for Change name*/}
        <ChangeNameModal
          handleNameClose={handleNameClose}
          open={openNameDialog}
          isProfile={true}
        />
        <ListRow>
          <Cardtitle onClick={handleDobOpen}>
            <Logotag style={{ width: '20px' }}>
              {/* <PencilLogo /> */}
              <EditOutlined />
            </Logotag>
            <TitleKey>Date of Birth</TitleKey>{' '}
          </Cardtitle>
          <TitleValue>{userData?.birthDate}</TitleValue>
        </ListRow>
        {/* Popup for Change dob*/}
        <ChangeDobModal
          handleDobClose={handleDobClose}
          open={openDobDialog}
          isProfile={true}
        />
        <ListRow>
          <Cardtitle onClick={handlePhoneOpen}>
            <Logotag style={{ width: '20px' }}>
              {/* <PencilLogo /> */}
              <EditOutlined />
            </Logotag>
            <TitleKey>Phone Number</TitleKey>
          </Cardtitle>
          <TitleValue>{userData?.phone}</TitleValue>
        </ListRow>
        {/* Popup for Change phone*/}
        <ChangePhoneModal
          handlePhoneClose={handlePhoneClose}
          open={openPhoneDialog}
          isProfile={true}
        />
        <ListRow>
          <Cardtitle onClick={handleAddressOpen}>
            <Logotag style={{ width: '20px' }}>
              {/* <PencilLogo /> */}
              <EditOutlined />
            </Logotag>
            <TitleKey>Home Address</TitleKey>
          </Cardtitle>
          {userData?.isNotHavePermanentAddress ? (
            <TitleValue>
              {"I don't currently have a permanent address"}
            </TitleValue>
          ) : (
            <TitleValue>
              {userData?.address?.address}
              <br />
              {userData?.address?.city}, {userData?.address?.state} ,
              {userData?.address?.country} {userData?.address?.zipCode}
            </TitleValue>
          )}
        </ListRow>{' '}
        {/* Popup for Change Address*/}
        <ChangeAddressModal
          handleAddressClose={handleAddressClose}
          open={openAddressDialog}
          isProfile={true}
          validAddress={validAddress}
        />
        {userData?.passportNo && (
          <ListRow>
            <Cardtitle onClick={() => setPassportDialog(true)}>
              <Logotag style={{ width: '20px' }}>
                {/* <PencilLogo /> */}
                <EditOutlined />
              </Logotag>
              <TitleKey>Passport Number</TitleKey>
            </Cardtitle>
            <TitleValue>{userData?.passportNo}</TitleValue>
          </ListRow>
        )}
        {/* Popup for Change Passport*/}
        <ChangePassportModal
          open={passportDialog}
          close={() => setPassportDialog(false)}
        />
        {userData?.studentID && (
          <ListRow>
            <Cardtitle onClick={() => setStudentIdDialog(true)}>
              <Logotag style={{ width: '20px' }}>
                {/* <PencilLogo /> */}
                <EditOutlined />
              </Logotag>
              {userData?.schoolTestFor === 'student' && (
                <TitleKey>Student ID #</TitleKey>
              )}
              {userData?.schoolTestFor === 'staff' && (
                <TitleKey>Staff ID #</TitleKey>
              )}
              {userData?.schoolTestFor === 'faculty' && (
                <TitleKey>Faculty ID #</TitleKey>
              )}
              {userData?.schoolTestFor === 'communityMember' && (
                <TitleKey>Community Member ID #</TitleKey>
              )}
            </Cardtitle>
            <TitleValue>{userData?.studentID}</TitleValue>
          </ListRow>
        )}
        {/* Popup for Change Student ID*/}
        <ChangeStudentModal
          open={studentIdDialog}
          close={() => setStudentIdDialog(false)}
        />
        <ListRow>
          <Cardtitle onClick={handleGenderOpen}>
            <Logotag style={{ width: '20px' }}>
              <EditOutlined />
              {/* <PencilLogo /> */}
            </Logotag>
            <TitleKey>Sex</TitleKey>
          </Cardtitle>
          <TitleValue>{userData?.sex}</TitleValue>
        </ListRow>
        <ChangeGenderModal
          open={openGenderDialog}
          handleGenderClose={handleGenderClose}
          isProfile={true}
        />
        <ListRow>
          <Cardtitle onClick={handleRaceOpen}>
            <Logotag style={{ width: '20px' }}>
              {/* <PencilLogo /> */}
              <EditOutlined />
            </Logotag>
            <TitleKey>Race</TitleKey>
          </Cardtitle>
          <TitleValue>{userData?.race}</TitleValue>
        </ListRow>
        <ChangeRaceModal
          open={openRaceDialog}
          handleRaceClose={handleRaceClose}
          isProfile={true}
        />
        <ListRow>
          <Cardtitle onClick={handleEthnicityOpen}>
            <Logotag style={{ width: '20px' }}>
              {/* <PencilLogo /> */}
              <EditOutlined />
            </Logotag>
            <TitleKey>Ethnicity</TitleKey>
          </Cardtitle>
          <TitleValue>{userData?.ethnicity}</TitleValue>
        </ListRow>
        <ChangeEthnicityModal
          open={openEthnicityDialog}
          handleEthnicityClose={handleEthnicityClose}
          isProfile={true}
        />
      </ListTag>
      <ListRow>
        <Cardtitle onClick={() => setOpenHubSpot(true)}>
          <Logotag style={{ width: '20px' }}>
            <EditOutlined />
          </Logotag>
          <TitleKey>Communications</TitleKey>
        </Cardtitle>
        <TitleValue>
          {userData?.isHubSpotContact
            ? 'Opted in for marketing communications'
            : 'Opted out for marketing communications'}
        </TitleValue>
      </ListRow>
      {openHubSpot && (
        <ChangeHubSpotModal
          open={openHubSpot}
          setOpenHubSpot={setOpenHubSpot}
          isProfile={true}
        />
      )}
      {userData?.hasMinors && (
        <PageSubTitle>
          <b>Minors on account</b>
        </PageSubTitle>
      )}
      {userData?.hasMinors && (
        <>
          {userData?.minors.map(
            ({ firstName, lastName, middleName, birthDate, school }, i) => (
              <WrapContainer key={i}>
                <WrapContent>
                  <Flexbox>
                    <FlexboxIcon>
                      <IoPersonOutline size={25} />
                    </FlexboxIcon>
                    <FlexboxText>
                      <b>{'Name : '}</b>
                      <div>
                        {firstName} {middleName} {lastName}
                      </div>
                    </FlexboxText>
                  </Flexbox>
                  <Flexbox>
                    <FlexboxIcon>
                      <MdDateRange size={25} />
                    </FlexboxIcon>
                    <FlexboxText>
                      <b>{'Date of Birth : '}</b>
                      <div>{birthDate}</div>
                    </FlexboxText>
                  </Flexbox>
                  <Flexbox>
                    <Button
                      style={{
                        background: '#243D4D',
                        boxShadow: 'none',
                        border: 'none',
                        color: 'white',
                        textDecorationLine: 'underline',
                      }}
                      type="button"
                      onClick={() => {
                        handleMinorOpen();
                        setEditKey({ key: editKey.key === i ? -1 : i });
                      }}
                      size="sm"
                      libraryType="default"
                    >
                      Edit
                    </Button>
                    {editKey.key === i && (
                      <ChangeMinorModal
                        isRegister={false}
                        close={handleMinorClose}
                        open={openMinorDialog}
                        index={i}
                        isProfile={true}
                        schoolDropDown={schoolDropDown}
                        school={school}
                      />
                    )}
                  </Flexbox>
                </WrapContent>
              </WrapContainer>
            )
          )}
        </>
      )}

      <MinorContainer>
        <Formik
          initialValues={{
            minors: minors.map((minor: any) => ({
              ...minor,
              birthDate:
                minor.birthDate &&
                parse(minor.birthDate, config.dateFormat, new Date()),
            })),
          }}
          onSubmit={(values) => {
            updateFormValues({
              minors: values.minors
                ? values.minors.map((minor: any) => ({
                    ...minor,
                    birthDate:
                      minor.birthDate &&
                      format(minor.birthDate, config.dateFormat),
                  }))
                : [],
            });
            updateChangeOnClick(values);
          }}
        >
          {({ setFieldValue }) => {
            return (
              <Form>
                {!addMinor && (
                  <AddMinor
                    type="button"
                    onClick={() => {
                      setAddMinor(true);
                      const newMinors = [
                        {
                          firstName: '',
                          lastName: '',
                          birthDate: null,
                          relationship: '',
                          passportCountry: '',
                          passportNo: '',
                          school: '',
                          studentID: '',
                          schoolTestFor: '',
                        },
                      ];
                      setFieldValue('minors', newMinors);
                    }}
                  >
                    <PlusIcon />
                    <b> Add another minor </b>
                  </AddMinor>
                )}
                {addMinor && (
                  <>
                    <Minors
                      isRegister={false}
                      isProfile={true}
                      isProfileCss={true}
                      setAddMinor={(p: boolean) => {
                        setAddMinor(p);
                      }}
                      schoolDropDown={schoolDropDown}
                    />

                    <NavigationButtons
                      loading={btnloading}
                      onReturnButtonClick={() => setAddMinor(false)}
                    />
                  </>
                )}
              </Form>
            );
          }}
        </Formik>
      </MinorContainer>

      <BtnContainer>
        <ChangeButton>
          <Button
            // variant="outlined"
            libraryType="default"
            color="primary"
            size="lg"
            onClick={handleEmailClickOpen}
            className={`btn ${openEmailDialog ? '' : 'outline1-btn'} `}
          >
            Change Email
          </Button>
        </ChangeButton>
        <ChangeButton>
          <Button
            // variant="outlined"
            libraryType="default"
            color="primary"
            size="lg"
            onClick={handlePasswordClickOpen}
            className={`btn outline1-btn`}
          >
            Change Password
          </Button>{' '}
        </ChangeButton>
      </BtnContainer>
      {/* Popup for Change Email */}

      <ChangeEmailModal
        open={openEmailDialog}
        handleEmailClose={handleEmailClose}
      />

      {/* Popup for Change Password */}

      <ChangePasswordModal
        open={openPasswordDialog}
        handlePasswordClose={handlePasswordClose}
      />
      {/* {upcomingInsurance ? (
        <>  */}
      {/* {console.log('insurance', upcomingInsurance)} */}
      {/* <PageTitle>Result Tracker</PageTitle> */}
      {/* <ResultTracker /> */}
      <PageTitle>Insurance Details</PageTitle>

      {/* {(upcomingInsurance || []).map((model: any, key: number) => (
            <> */}
      {isInsurance && upcomingInsurance?.length ? (
        <>
          {upcomingInsurance.map((insurance: any, i: number) => (
            <>
              <EditTag>
                <Logotag
                  style={{ width: '20px' }}
                  onClick={() => {
                    handleInsuranceOpen();

                    setEditKey({ key: editKey.key === i ? -1 : i });
                  }}
                >
                  {/* <InsurancePencilLogo /> */}
                  <EditOutlined />
                </Logotag>

                <PageSubTitle>
                  <b>Insurance - {i + 1}</b>
                </PageSubTitle>
              </EditTag>
              <InsuranceContainer
                style={{
                  borderRadius: '12px',
                }}
              >
                <WrapExpand>
                  <ExpandRight style={{ top: '0px' }}>
                    <InsurancelistTag>
                      <DateAndPatient>
                        <Datetag>
                          <ExpandIcon>
                            <IoPersonOutline size={25} />
                          </ExpandIcon>
                          <Expandtext>
                            {' '}
                            <strong>Insurance Carrier Name</strong>
                            <br />
                            {insurance.payerList}
                            <br />
                          </Expandtext>
                        </Datetag>
                        <Datetag>
                          <ExpandIcon>
                            <BiIdCard size={25} />
                          </ExpandIcon>
                          <Expandtext>
                            {' '}
                            <strong>Insurance Member ID</strong>
                            <br />
                            {insurance.insuranceId}
                            <br />
                          </Expandtext>
                        </Datetag>
                        <Patientstag>
                          <ExpandIcon>
                            <IoPeopleOutline size={25} />
                          </ExpandIcon>
                          <Expandtext>
                            {' '}
                            <strong>Insurance Group</strong>
                            <br />
                            {insurance.group}
                            <br />
                          </Expandtext>
                        </Patientstag>
                        <Timetag>
                          <ExpandIcon>
                            {' '}
                            <IoPersonOutline size={25} />
                          </ExpandIcon>
                          <Expandtext>
                            {' '}
                            <strong> Subscriber Name </strong> <br />
                            {insurance.subscriberFirstName}{' '}
                            {insurance.subscriberLastName}
                            <br />
                          </Expandtext>
                        </Timetag>
                      </DateAndPatient>
                      <ExpandCenter>
                        <ExpandVl></ExpandVl>
                      </ExpandCenter>
                      <SubNameandDob>
                        <Testtag>
                          <ExpandIcon>
                            {' '}
                            <AiOutlinePicture size={25} />
                          </ExpandIcon>
                          <Expandtext>
                            {' '}
                            <strong>Front copy of the card</strong> <br />
                            <Card>
                              <img
                                width="100"
                                height="100"
                                src={
                                  insurance?.frontCard &&
                                  insurance?.frontCard !== null
                                    ? insurance?.frontCard
                                    : ''
                                }
                                className={
                                  insurance?.frontCard == null ||
                                  insurance?.frontCard == ''
                                    ? classes.hideImage
                                    : classes.showImage
                                }
                              />
                            </Card>
                          </Expandtext>
                        </Testtag>
                        <Testtag>
                          <ExpandIcon>
                            {' '}
                            <AiOutlinePicture size={25} />
                          </ExpandIcon>
                          <Expandtext>
                            {' '}
                            <strong>Back copy of the card</strong> <br />
                            <Card>
                              <img
                                width="100"
                                height="100"
                                src={
                                  insurance?.backCard &&
                                  insurance?.backCard !== null
                                    ? insurance?.backCard
                                    : ''
                                }
                                className={
                                  insurance?.backCard == null ||
                                  insurance?.backCard == ''
                                    ? classes.hideImage
                                    : classes.showImage
                                }
                              />
                            </Card>
                          </Expandtext>
                        </Testtag>
                      </SubNameandDob>
                    </InsurancelistTag>
                  </ExpandRight>
                </WrapExpand>
                {editKey.key === i && (
                  <ChangeInsuranceModal
                    close={handleInsuranceClose}
                    open={openInsuranceDialog}
                    insuranceList={{
                      ...insurance,
                      subscriberbirthDate:
                        insurance?.subscriberbirthDate ?? userData?.birthDate,
                    }}
                    isProfile={false}
                  />
                )}
              </InsuranceContainer>
            </>
          ))}
        </>
      ) : (
        <>
          {' '}
          <InsuranceContainer
            style={{
              height: '160px',
              left: '0px',
              top: '59px',
              borderRadius: '12px',
            }}
          >
            <WrapText>{'You have no insurance details'}</WrapText>
            <ButtonWrapExpand>
              <Button
                libraryType="primary"
                type="submit"
                className="btn fill-btn"
                disabled={false}
                onMouseOver={handleMouseEnter}
                onMouseOut={handleMouseLeave}
                onClick={() => history.push('/user/insurance')}
              >
                {'Add Insurance'}
              </Button>
            </ButtonWrapExpand>
          </InsuranceContainer>
          <Space />
        </>
      )}

      <PageTitle>Government ID Details</PageTitle>
      {isGovtId && governmentId?.length ? (
        <>
          {governmentId.map((govtIds, i: number) => (
            <>
              <EditTag>
                <Logotag
                  style={{ width: '20px' }}
                  onClick={() => {
                    handleGovtIdDialogOpen();

                    setEditKey({
                      key: editKey.key === i ? -1 : i,
                    });
                  }}
                >
                  {/* <InsurancePencilLogo /> */}
                  <EditOutlined />
                </Logotag>

                <PageSubTitle>
                  <b>Government Id - {i + 1}</b>
                </PageSubTitle>
              </EditTag>
              <InsuranceContainer
                style={{
                  border: '0.5px solid rgba(36, 61, 77, 0.14)',
                  /* Drop Shadow */
                  boxShadow:
                    '-4px -4px 8px rgba(153, 153, 153, 0.05), 4px 4px 8px rgba(0, 0, 0, 0.15)',
                  borderRadius: '12px',
                }}
              >
                <WrapExpand>
                  <ExpandRight style={{ top: '0px' }}>
                    <InsurancelistTag>
                      <SubNameandDob>
                        <Testtag>
                          <ExpandIcon>
                            {' '}
                            <AiOutlinePicture size={25} />
                          </ExpandIcon>
                          <Expandtext>
                            {' '}
                            <strong>Front copy of the card</strong> <br />
                            <Card>
                              <img
                                width="100"
                                height="100"
                                src={
                                  govtIds?.frontCard ? govtIds?.frontCard : ''
                                }
                              />
                            </Card>
                          </Expandtext>
                        </Testtag>
                        <Testtag>
                          <ExpandIcon>
                            {' '}
                            <AiOutlinePicture size={25} />
                          </ExpandIcon>
                          <Expandtext>
                            {' '}
                            <strong>Back copy of the card</strong> <br />
                            <Card>
                              <img
                                width="100"
                                height="100"
                                src={govtIds?.backCard ? govtIds?.backCard : ''}
                              />
                            </Card>
                          </Expandtext>
                        </Testtag>
                      </SubNameandDob>
                    </InsurancelistTag>
                  </ExpandRight>
                </WrapExpand>
                {editKey.key === i && (
                  <DrivingLicenseModel
                    handleModalClose={handleGovtIdClose}
                    open={openGovtIdDialog}
                    isEdit={true}
                    govtIds={govtIds}
                    isProfile={true}
                  />
                )}
              </InsuranceContainer>
            </>
          ))}
        </>
      ) : (
        <>
          {' '}
          <InsuranceContainer
            style={{
              height: '160px',
              left: '0px',
              border: '0.5px solid rgba(36, 61, 77, 0.14)',
              /* Drop Shadow */
              boxShadow:
                '-4px -4px 8px rgba(153, 153, 153, 0.05), 4px 4px 8px rgba(0, 0, 0, 0.15)',
              borderRadius: '12px',
            }}
          >
            <WrapText>{'You have no government id details'}</WrapText>
            <ButtonWrapExpand>
              <Button
                libraryType="primary"
                type="submit"
                disabled={false}
                className="btn fill-btn"
                onClick={() => history.push('/user/insurance')}
              >
                {'Add Government Id'}
              </Button>
            </ButtonWrapExpand>
          </InsuranceContainer>
        </>
      )}
      {/* </>
          ))} */}
      {/* </>
      ) : ("")} */}
      {upcomingAppointment && <PageTitle>Appointment Overview</PageTitle>}
      {upcomingAppointment.length ? (
        <>
          {/* {console.log('upcominglenght', upcomingAppointment)} */}

          {(upcomingAppointment || []).map((model: any, key: number) => (
            <>
              {/* {console.log("appoint data", model)} */}
              <ExpandWrapContainer
                key={key}
                style={{
                  padding:
                    expand.key == key
                      ? '40px 20px 10px 60px'
                      : '40px 20px 40px 60px',
                }}
              >
                <RowList>
                  <FlexboxRight style={{ color: '#FFFFFF' }}>
                    <Text>Confirmation #</Text>
                    <ConfirmationNumber>
                      {model?.confirmationId}
                    </ConfirmationNumber>
                    {!(expand.key == key) && (
                      <>
                        <Expandrow1>
                          <ExpandIcon>
                            <MdDateRange size={25} />
                          </ExpandIcon>
                          <Expandtext>
                            {' '}
                            <strong> Date</strong>
                            <br />
                            {model?.slot?.date
                              ? format(
                                  new Date(model?.slot?.date),
                                  'MMMM dd,yyyy'
                                )
                              : ''}
                            <br />
                          </Expandtext>
                        </Expandrow1>
                      </>
                    )}
                  </FlexboxRight>
                  <FlexboxCenter>
                    <Expand
                      key={key}
                      onClick={() => {
                        setExpand({ key: expand.key == key ? -1 : key });
                        setPrint({ key: print.key == key ? -1 : key });
                      }}
                    >
                      {expand.key == key ? (
                        <>
                          Hide details <MdExpandLess size={20} />
                        </>
                      ) : (
                        <>
                          Show details <MdExpandMore size={20} />
                        </>
                      )}
                    </Expand>
                  </FlexboxCenter>
                  <FlexboxLeft style={{ color: '#FFFFFF' }}>
                    <ExpandWrapbutton>
                      {flag && (
                        <PDFDownloadLink
                          document={
                            <DownloadableConfirmation
                              form={model}
                              periods={periods}
                            />
                          }
                          fileName="worksite-labs-confirmation.pdf"
                        >
                          {({ loading }) => <>Download</>}
                        </PDFDownloadLink>
                      )}

                      <Button
                        type="button"
                        size="sm"
                        libraryType="default"
                        accentColor={colors.darkBlue}
                        className="btn elevated-btn"
                        key={key}
                        onClick={() => {
                          setPrintFunction(handlePrint);
                          setPrint({ key: print.key == key ? -1 : key });
                        }}
                      >
                        Print{' '}
                      </Button>
                      {print.key == key && (
                        <>
                          <Hide>
                            {' '}
                            <PrintableConfirmation
                              ref={printableRef}
                              form={model}
                              periods={periods}
                            />{' '}
                          </Hide>
                        </>
                      )}
                      {checkDisable(
                        model,
                        key,
                        print,
                        cancel,
                        enableEditAppointment,
                        handleCancelApptOpen,
                        setCancel
                      )}
                      {cancel.key == key && (
                        // <Dialog
                        //   fullScreen={fullScreen}
                        //   open={OpenCancelAppointment}
                        //   onClose={handleCancelApptClose}
                        // >
                        //   {/* <Hide> */}
                        //   {/* <CancelApptModal  */}
                        //   <CancelAppointmentModal
                        //     // open={OpenCancelAppointment}
                        //     handleCancelApptClose={handleCancelApptClose}
                        //     form={model}
                        //   />
                        //   {/* </Hide> */}
                        // </Dialog>
                        <CancelApptModal
                          open={OpenCancelAppointment}
                          handleCancelApptClose={handleCancelApptClose}
                          form={model}
                          handleCancelReload={handleCancelReload}
                          setCancel={setCancel}
                        />
                      )}
                    </ExpandWrapbutton>
                  </FlexboxLeft>
                </RowList>
                {expand.key == key && (
                  <WrapExpand style={{ color: '#FFFFFF' }}>
                    <ExpandRight>
                      <Expandrow1>
                        <ExpandIcon>
                          <IoLocationOutline size={25} />
                        </ExpandIcon>
                        <Expandtext>
                          <strong>{model?.location?.name}</strong>
                          <br />
                          {model?.location?.address1}{' '}
                          {model?.location?.address2}
                          <br />
                          {model?.location?.room}
                        </Expandtext>
                      </Expandrow1>
                      <ExapndlistTag>
                        <DateAndPatient>
                          <Datetag>
                            <ExpandIcon>
                              <MdDateRange size={25} />
                            </ExpandIcon>
                            <Expandtext>
                              {' '}
                              <strong> Date</strong>
                              <br />
                              {model?.date
                                ? format(new Date(model?.date), 'MMMM dd,yyyy')
                                : ''}
                              <br />
                            </Expandtext>
                          </Datetag>
                          <Patientstag>
                            <ExpandIcon>
                              <IoPersonOutline size={25} />
                            </ExpandIcon>
                            <Expandtext>
                              {' '}
                              <strong>Patients</strong>
                              <br />
                              {model.registeringFor !== 'minorOnly' && (
                                <>
                                  {model?.firstName}{' '}
                                  {model?.middleName ? model?.middleName : ''}{' '}
                                  {model?.lastName}
                                </>
                              )}
                              {model.minors
                                .filter((minor: any) => !minor.isCanceled)
                                .map((res: any) => {
                                  return (
                                    <>
                                      <div>
                                        {res.firstName} {res.lastName}
                                      </div>
                                    </>
                                  );
                                })}
                            </Expandtext>
                          </Patientstag>
                        </DateAndPatient>
                        <ExpandCenter>
                          <ExpandVl></ExpandVl>
                        </ExpandCenter>
                        <TimeAndTest>
                          <Timetag>
                            <ExpandIcon>
                              {' '}
                              <BiTimeFive size={25} />
                            </ExpandIcon>
                            <Expandtext>
                              {' '}
                              <strong> Time</strong> <br />
                              {model?.slot &&
                                (model?.slot.label ||
                                  periods[model?.slot.period].label)}
                              <br />{' '}
                            </Expandtext>
                          </Timetag>
                          <Testtag>
                            <ExpandIcon>
                              {' '}
                              <IoPeopleOutline size={25} />
                            </ExpandIcon>
                            <Expandtext>
                              {' '}
                              <strong>Test</strong> <br />
                              {model.isAntigen || model.isPanel
                                ? 'Antigen'
                                : 'COVID-19 RT-PCR'}
                              <br />
                            </Expandtext>
                          </Testtag>
                        </TimeAndTest>
                        <ExpandCenter>
                          <ExpandVl></ExpandVl>
                        </ExpandCenter>
                        <TimeAndTest>
                          <PaymentMethod>
                            <Payment>
                              <ExpandIcon>
                                {' '}
                                <BiDollarCircle size={25} />
                              </ExpandIcon>
                              <Expandtext>
                                {' '}
                                <strong> Payment Method</strong> <br />
                                {console.log('paying method', model)}
                                <div>
                                  {' '}
                                  {model?.payingMethod === 'Out of pocket'
                                    ? model?.testSelection === 'school'
                                      ? 'No Insurance'
                                      : 'Credit card'
                                    : ((model?.payingMethod == 'Credit card' ||
                                        model?.payingMethod == 'Credit Card') &&
                                        model?.promoCode !== '' &&
                                        model?.amount !==
                                          model?.discountAmount) ||
                                      (employeePromocode?.includes(
                                        model?.promoCode
                                      ) &&
                                        model?.minors?.length > 0)
                                    ? `${model?.promoCode} VOUCHER and ${model?.payingMethod}`
                                    : (model?.payingMethod == 'Credit card' ||
                                        model?.payingMethod == 'Credit Card') &&
                                      includes(
                                        // ['EYEPROD', 'GRAVY', 'EMPLOYEE'],
                                        allPromocodes,
                                        model?.promoCode
                                      )
                                    ? `${model?.promoCode} VOUCHER`
                                    : model?.payingMethod}
                                </div>
                                <br />{' '}
                              </Expandtext>
                            </Payment>
                          </PaymentMethod>
                          <Testtag>
                            <ExpandIcon>
                              {' '}
                              <IoPersonAddOutline size={25} />
                            </ExpandIcon>
                            <Expandtext>
                              {' '}
                              <strong>Test Type</strong> <br />
                              {model.isRapidTest
                                ? 'Rapid PCR Test'
                                : model.isExpressSameDayTest
                                ? 'Express PCR Test'
                                : model.isAntigen
                                ? 'Antigen Test'
                                : model.isPanel
                                ? 'Flu A&B/Covid-19'
                                : 'Standard PCR Test'}
                              <br />{' '}
                            </Expandtext>
                          </Testtag>
                        </TimeAndTest>
                      </ExapndlistTag>
                    </ExpandRight>
                  </WrapExpand>
                )}
              </ExpandWrapContainer>
            </>
          ))}
        </>
      ) : (
        <>
          {' '}
          <InsuranceContainer
            style={{
              // height: '160px',
              left: '0px',
              border: '0.5px solid rgba(36, 61, 77, 0.14)',
              /* Drop Shadow */
              boxShadow:
                '-4px -4px 8px rgba(153, 153, 153, 0.05), 4px 4px 8px rgba(0, 0, 0, 0.15)',
              borderRadius: '12px',
            }}
          >
            <WrapText>{'You have no upcoming appointments'}</WrapText>
            <br />
            <ButtonWrapExpand>
              <Button
                libraryType="primary"
                type="submit"
                disabled={false}
                className="fill-bookAppt-btn"
                onClick={redirect}
              >
                {'Book An Appointment Now'}
              </Button>
            </ButtonWrapExpand>
          </InsuranceContainer>
          <Space />
        </>
      )}
    </Mainpage>
  );
};

export default Profile;
