/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useState, useMemo, useEffect, useRef } from 'react';
import styled from 'styled-components';
import {
    subHours,
    isBefore,
    startOfDay,
    parse,
    format,
    isAfter,
    addDays,
} from 'date-fns';
import { Formik, Form, FormikErrors, FormikValues } from 'formik';
import { Button } from './styled';
import { colors } from '../../../styles/colors';
import {
    Container,
    // PageHeader,
    PageTitle,
    PageSubTitle,
    Content,
} from './styled';
import pin from '../../../assets/pin.svg';
import { Breakpoints } from '../../../dictionaries';
import { config } from '../../../config';
import { AnyObject, Appointment, Location } from '../models';

import Inputs from '../../shared/components/form/Input';
import ChangeLocationModal from './ChangeLocationModal';
import Slots from './Slots';
import NavigationButtons from './NavigationButtons';
import Datepicker, { DatepickerField } from './Datepicker';
import FormLabel from './form/FormLabel';
import { cloneDeep, flatMap, includes, isEqual } from 'lodash';
import { useStepsDispatch } from '../../schedule/provider';
import { useStepsState } from '../../register/provider';
import { useSharedDispatch, useSharedState } from '../provider';
import { reserveSlot, releaseSlot, getNpDestionation, getEmployeeCoupon, getCoupon } from '../../schedule/api';
import { useManageDispatch, useManageState } from '../../manage/provider';
import LocationModal from '../../manage/components/LocationModal';
import { convertPricing, isFromUrl } from '../../../utils';
import RapidAlertModal from './RapidAlertModal';
import { useAdminDispatch } from '../../admin/provider';
import { useHistory } from 'react-router';
import { ConsoleSqlOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

const ChangeLocationButton = styled.button`
  display: inline-block;
  border: 0;
  background: none;
  color: ${colors.rebrandBlack};
  cursor: pointer;
  @media (max-width: ${Breakpoints.sm}px) {
    padding: 0px;
    // width: 50%;
    margin-left: 0px;
  }
`;

const PinIcon = styled.div`
  position: absolute;
  left: 0;
  top: 4px;
  display: inline-block;
  vertical-align: middle;
  margin: -2px 5px 0 0;
  width: 14px;
  height: 20px;
  background: url(${pin}) no-repeat;
  background-size: cover;
`;

const HeaderLocation = styled.div`
  position: relative;
  padding-left: 24px;
  text-align: left;
  @media (min-width: ${Breakpoints.sm}) {
    flex-direction: row;
  }
`;

const PageContent = styled.div`
  border-top: 1px solid ${colors.rebrandBlack};
  padding-top: 25px;
  @media (max-width: ${Breakpoints.sm}px) {
    display: flex;
    justify-content: center;
  }
  @media (max-width: ${Breakpoints.md}px) {
    display: flex;
    margin-top: 35px;
    justify-content: center;
    margin-right: 80px;
  }
  @media (min-width: ${Breakpoints.lg}px) {
    padding-top: 45px;
  }
`;

const AboveColumns = styled.div`
  max-width: 650px;
  margin-bottom: 20px;
`;

const Columns = styled.div`
  display: flex;
  margin: 0 -30px;
  & > * {
    margin: 0 30px;
  }
`;

const DatepickerWrapper = styled.div`
  display: none;
  @media (min-width: ${Breakpoints.md}px) {
    display: block;
    flex: 1 1 auto;
    position: relative;
    width: 43%;
  }
`;

const SlotsWrapper = styled.div`
  width: 100%;
  @media (min-width: ${Breakpoints.md}px) {
    margin-left: 10px;
    flex: 2 1 auto;
  }
`;

const LocationNameAndAddress = styled.div`
  margin-right: 10px;
`;

const PageHeader = styled.div`
  margin-bottom: 40px;
  flex: 1;
  @media (min-width: ${Breakpoints.sm}px) {
    margin-bottom: 50px;
    position: relative;
    top: 15px;
  }
  @media (min-width: ${Breakpoints.md}px) {
    margin-bottom: 50px;
    position: relative;
    top: 15px;
  }
`;
const GoHealthError = styled.div`
  color: ${colors.red};
  margin: 0px 0px 0px 200px;
  text-align: center;
`;
const Error = styled.div`
    margin-top: 15px;
`;
const HeadarContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  @media (max-width: ${Breakpoints.sm}px) {
    display: flex;
    justify-content: center;
    margin-right: 70px;
  }
  @media (max-width: ${Breakpoints.md}px) {
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;
    top: 24px;
  }
`;

const TestTypeContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 135px;
  width: 550px;
  margin: 10px;
  justify-content: center;
  text-align: center;
  align-content: center;
`;

const InnerBox = styled.div`
  border: 1px solid rgb(26, 150, 219);
  margin: 0px 5px;
  width: 40%;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  cursor: pointer;
  align-items: center;
  display: grid;
  background: ${({ selected }: { selected?: boolean }) =>
        selected ? colors.rebrandBlack : colors.white};
  border: 0.5px solid rgba(36, 61, 77, 0.14);
  border-radius: 16px;
`;

const TestTypeTitle = styled.p`
  font-size: medium;
  font-weight: bold;
  color: ${({ selected }: { selected?: boolean }) =>
        selected ? colors.white : colors.rebrandBlack};
  @media (max-width: ${Breakpoints.sm}px) {
    font-size: 15px;
    font-weight: bold;
    color: ${({ selected }: { selected: boolean }) =>
        selected ? colors.white : colors.rebrandBlack};
  }
`;

const TestCostLabel = styled.p`
  font-size: 40px;
  font-weight: bold;
  color: ${({ selected }: { selected: boolean }) =>
        selected ? colors.white : colors.rebrandBlack};
  @media (max-width: ${Breakpoints.sm}px) {
    font-size: 30px;
    font-weight: bold;
    color: ${({ selected }: { selected: boolean }) =>
        selected ? colors.white : colors.rebrandBlack};
  }
`;

const TestDurationInfoLabel = styled.p`
  font-size: 12px;
  font-weight: bold;
  color: ${({ selected }: { selected: boolean }) =>
        selected ? colors.white : colors.rebrandBlack};
  @media (max-width: ${Breakpoints.sm}px) {
    font-size: 9px;
    font-weight: bold;
    color: ${({ selected }: { selected?: boolean }) =>
        selected ? colors.white : colors.rebrandBlack};
  }
`;
const FormStyle = styled.div`
  @media (max-width: ${Breakpoints.sm}px) {
    width: 50%;
    margin-left: 123px;
  }
`;
export const PageTitleReg = styled.h2`
  color: ${colors.fullBlack};
  font-size: 28px;
  font-weight: 700;
  margin: 0;
  text-align: left;
  @media (max-width: ${Breakpoints.sm}px) {
    font-size: 25px;
    text-align: center;
  }
  @media (max-width: ${Breakpoints.md}px) {
    display: flex;
    // justify-content: center;
    margin-right: 70px;
  }
  @media (max-width: ${Breakpoints.lg}px) {
    margin: 15px;
  }
`;
const CouponContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
`;
const RefundInfoText = styled.div`
  font-weight: bold;
`;
interface Props {
    title?: string;
    onSchedule: (values: FormikValues) => void;
    onFieldUpdate: (update: AnyObject) => void;
    onLocationChange?: () => void;
    appointment: Appointment;
    confirmBtnText?: string;
    loading?: boolean;
    isManage?: boolean;
    isAdmin?: boolean;
    standardTest?: any;
    prices?: any;
    RescheduleTestType?: any;
    setIsEmployeeEmail?: (data: boolean) => void;
    isEmployeeEmail?: boolean;
    blockOutDays?: Number[];
    goHealthError?:boolean;
    isSlotsLoading?: boolean;
}

export function getMinAvailableDate(
    location: Location | null,
    isExpressSameDayTest: boolean,
    departureDateAndTime: Date,
    isRapidTest: boolean
): Date {
    let getTimeZoneOffSet = new Date(departureDateAndTime).getTimezoneOffset();
    if (isExpressSameDayTest && location && location.hasVipSlots) {
        if (
            isBefore(
                departureDateAndTime,
                parse(config.schedule.endTime, 'h:mmaaaaa', departureDateAndTime)
            )
        ) {
            return subHours(departureDateAndTime, config.vipSlots.maxAdvanceHours); //departureDateAndTime;
        }
    }

    if (isRapidTest && location && location.rapidTest) {
        if (
            isBefore(
                departureDateAndTime,
                parse(config.schedule.endTime, 'h:mmaaaaa', departureDateAndTime)
            )
        ) {
            return subHours(departureDateAndTime, config.vipSlots.maxAdvanceHours); //departureDateAndTime;
        }
    }

    if (
        isBefore(
            subHours(
                startOfDay(departureDateAndTime),
                getTimeZoneOffSet == 420 ||
                    getTimeZoneOffSet == 300 ||
                    getTimeZoneOffSet == 240
                    ? 71
                    : config.maxAdvanceHours
            ),
            new Date()
        )
    ) {
        return new Date();
    }

    // avoid to open up previous day without available slots in datepicker
    if (
        isAfter(
            departureDateAndTime,
            parse(config.schedule.endTime, 'h:mmaaaaa', departureDateAndTime)
        )
    ) {
        return subHours(departureDateAndTime, config.minAdvanceHours);
    }

    return subHours(
        departureDateAndTime,
        getTimeZoneOffSet == 420 ||
            getTimeZoneOffSet == 300 ||
            getTimeZoneOffSet == 240
            ? 71
            : config.maxAdvanceHours
    );
}

export function getMaxAvailableDate(
    location: Location | null,
    isExpressSameDayTest: boolean,
    departureDateAndTime: Date,
    isRapidTest: boolean
): Date {
    let getTimeZoneOffSet = new Date(departureDateAndTime).getTimezoneOffset();
    if (isExpressSameDayTest && location && location.hasVipSlots) {
        if (
            isBefore(
                departureDateAndTime,
                parse(config.schedule.startTime, 'h:mmaaaaa', departureDateAndTime)
            )
        ) {
        }

        const overTime = new Date(departureDateAndTime).getHours();
        return subHours(
            departureDateAndTime,
            overTime >= 12 ? 24 : config.vipSlots.minAdvanceHours
        );
    }
    if (isRapidTest && location && location.rapidTest) {
        const overTime = new Date(departureDateAndTime).getHours();
        return subHours(departureDateAndTime, 3);
    }
    // avoid to open up next day without available slots in datepicker
    if (
        isBefore(
            departureDateAndTime,
            parse(config.schedule.startTime, 'h:mmaaaaa', departureDateAndTime)
        )
    ) {
        return subHours(
            departureDateAndTime,
            getTimeZoneOffSet == 420 ||
                getTimeZoneOffSet == 300 ||
                getTimeZoneOffSet == 240
                ? 71
                : config.maxAdvanceHours
        );
    }

    return subHours(departureDateAndTime, config.minAdvanceHours);
}

export function disableFutureDt(): Date {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const disableFuture = new Date(year + 1, month, day); // PLUS 1 YEAR
    return disableFuture;
}

const Schedule: React.FC<Props> = ({
    title,
    appointment,
    onSchedule,
    onFieldUpdate,
    onLocationChange,
    confirmBtnText,
    loading,
    isManage,
    isAdmin,
    prices,
    standardTest,
    RescheduleTestType,
    setIsEmployeeEmail,
    isEmployeeEmail,
    blockOutDays,
    goHealthError,
}) => {
    let {
        location,
        slot,
        departureDateAndTime,
        isExpressSameDayTest,
        date,
        isRapidTest,
        travelType,
        destination,
        testSelection,
        promoCode,
        discountAmount,
        hasInsurance,
        payingMethod,
        address,
        isAntigen,
        isWalkUpTest,
        cPartnerID,
        isPanel,
    } = appointment;
    const { travellType } = useSharedState();
    const [btnloading, setBtnLoading] = useState<boolean>(false);
    const [message, setMessage] = useState('');
    const history = useHistory();
    const { showChangeLocationModal, form } = useStepsState();
    const { updateStandradTest } = useManageDispatch();
    const { upateTestType, setWalkUp } = useSharedDispatch();
    const { userData } = useSharedState();
    const { toggleChangeLocationModal, updateFormValues, } = useStepsDispatch();
    const [showChangeDatetimeModal, setShowChangeDatetimeModal] = useState(false);
    const [isSlotsLoading, setSlotsLoading] = useState(false)
    const [isEmpEmail, setIsEmpEmail] = useState(false);
    const [insurance, setInsurance] = useState(false);
    const [noInsurance, setNoInsurance] = useState(false);
    const [creditCard, setCreditCard] =useState(false);
    // const childRef: any = useRef();
    const [npArray, setNpArray] = useState([]);
    if (typeof (blockOutDays) !== 'object' && JSON.parse(JSON.parse(JSON.stringify(localStorage.getItem('blockOutDays')))) !== null && JSON.parse(JSON.parse(JSON.stringify(localStorage.getItem('blockOutDays')))) !== undefined) {
        blockOutDays = JSON.parse(JSON.parse(JSON.stringify(localStorage.getItem('blockOutDays'))))
    }
    const getDestination = async () => {
        const arr: any = [];
        const response = await getNpDestionation({}).then((result: any) => {
            const data = result.data.data;
            data.forEach((element: any) => {
                arr.push(element.value)
            });
            console.log("values", arr)
        });
        setNpArray(arr)
    }

    useEffect(() => {
        if (location?.qbenchCustomerId === config.qbenchCustomerId.ArroyoUpperSchool) {
            onFieldUpdate({ isAntigen: true, isExpressSameDayTest: false, isRapid: false })
            
        }
        // if (location?.isCreditCard){
        //     handlePaymentMethod()
        //     handleTesttype()
        // }
        updateStandradTest(
            isRapidTest ? false : isExpressSameDayTest ? false : isAntigen ? false  : isPanel ? false : true
        )
        upateTestType(isRapidTest ? false : isExpressSameDayTest ? false : isAntigen ? false  : isPanel ? false : true)
        if (appointment.isWalkUpTest && isManage)
            setWalkUp({
                active: true,
                locationId: appointment.location?.qbenchCustomerId,
            });
        if(form?.location?.qbenchCustomerId === config?.qbenchCustomerId?.SFOAirport_BARTStation && (form?.testSelection === 'flight'  || form?.testSelection === 'general')){
            if((form?.payingMethod === "Credit card" || form?.payingMethod === "Credit Card")){
                updateFormValues({ isRapidTest: form?.isAntigen ? false : true, isAntigen: form?.isAntigen, isExpressSameDayTest: false, })
            }else{
                updateFormValues({ isRapidTest: false, isAntigen: true , isExpressSameDayTest: false, })
            }
            updateStandradTest(false)
            upateTestType(false)
        }
    }, []);

    const handleTesttype = () => {
        if (!hasInsurance && payingMethod !== 'No Insurance') {

            if ((!location?.standardTest || !location?.isCreditCard?.standard) && location?.isExpressSameDay && location?.isCreditCard?.express) {
                onFieldUpdate({ isExpressSameDayTest: true })
            } else if ((!location?.standardTest || !location?.isCreditCard?.standard) && (!location?.isExpressSameDay || !location?.isCreditCard?.express) && location?.rapidTest && location?.isCreditCard?.rapid) {
                onFieldUpdate({ isRapidTest: true })
            } else if ((!location?.standardTest || !location?.isCreditCard?.standard) && (!location?.isExpressSameDay || !location?.isCreditCard?.express) && (!location?.rapidTest || !location?.isCreditCard?.rapid) && location?.isAntigen && location?.isCreditCard?.antigen) {
                onFieldUpdate({ isAntigen: true })
            } 

        } else if (hasInsurance) {

            if ((!location?.standardTest || !location?.isInsurance?.standard) && location?.isExpressSameDay && location?.isInsurance?.express) {
                onFieldUpdate({ isExpressSameDayTest: true })
            } else if ((!location?.standardTest || !location?.isInsurance?.standard) && (!location?.isExpressSameDay || !location?.isInsurance?.express) && location?.rapidTest && location?.isInsurance?.rapid) {
                onFieldUpdate({ isRapidTest: true })
            } else if ((!location?.standardTest || !location?.isInsurance?.standard) && (!location?.isExpressSameDay || !location?.isInsurance?.express) && (!location?.rapidTest || !location?.isInsurance?.rapid) && location?.isAntigen && location?.isInsurance?.antigen) {
                onFieldUpdate({ isAntigen: true })
            } else if ((!location?.standardTest || !location?.isInsurance?.standard) && (!location?.isExpressSameDay || !location?.isInsurance?.express) && (!location?.rapidTest || !location?.isInsurance?.rapid) && (!location?.isAntigen || !location?.isInsurance?.antigen) && location?.isPanel && location?.isInsurance?.panel) {
                onFieldUpdate({ isPanel: true })
            } 


        } else if (payingMethod === 'No Insurance') {

            if ((!location?.standardTest || !location?.isNoInsurance?.standard) && location?.isExpressSameDay && location?.isNoInsurance.express) {
                onFieldUpdate({ isExpressSameDayTest: true })
            } else if ((!location?.standardTest || !location?.isNoInsurance?.standard) && (!location?.isExpressSameDay || !location?.isNoInsurance?.express) && location?.rapidTest && location?.isNoInsurance?.rapid) {
                onFieldUpdate({ isRapidTest: true })
            } else if ((!location?.standardTest || !location?.isNoInsurance?.standard) && (!location?.isExpressSameDay || !location?.isNoInsurance?.express) && (!location?.rapidTest || !location?.isNoInsurance?.rapid) && location?.isAntigen && location?.isNoInsurance?.antigen) {
                onFieldUpdate({ isAntigen: true })
            } 


        }
}

const handlePaymentMethod = () => {

    if (location?.isCreditCard?.standard || location?.isCreditCard?.antigen || location?.isCreditCard?.rapid || location?.isCreditCard?.express) {
        setCreditCard(true);
    } else {
        setCreditCard(false);
    }
    if (location?.isInsurance?.standard || location?.isInsurance?.antigen || location?.isInsurance?.rapid || location?.isInsurance?.express) {
        setInsurance(true);
    } else {
        setInsurance(false);
    }
    if (location?.isNoInsurance?.standard || location?.isNoInsurance?.antigen || location?.isNoInsurance?.rapid || location?.isNoInsurance?.express) {
        setNoInsurance(true);
    } else {
        setNoInsurance(false);
    }
}
    const getEmployeeEmail = async () => {
        let empData = await getEmployeeCoupon(userData?.email)
        const dataEmail: any = userData?.email?.split('@');
        const worksiteEmail = dataEmail !== undefined && dataEmail !== null ? dataEmail[1] : '';
        if (
            (worksiteEmail === 'worksitelabs.com' || !!empData.data) &&
            testSelection === 'general' && !hasInsurance && (payingMethod === 'Credit Card' || payingMethod === 'Credit card')
        ) {
            setIsEmpEmail(true)
            !!setIsEmployeeEmail && setIsEmployeeEmail(true)
        }
    }

    useEffect(() => {
        if ((payingMethod === 'Credit Card' || payingMethod === 'Credit card') && testSelection === "general") {
            getEmployeeEmail()
        }
        getDestination();
        if (isExpressSameDayTest || isRapidTest || isAntigen) {
            setMessage('');
            onFieldUpdate({
                couponId: '',
                // discountAmount: 0,
            });
        }
    }, [isExpressSameDayTest, isRapidTest, isAntigen]);
    useEffect(() => {
        if (testSelection === 'flight' && destination === 'JPN' && location) {
            location.isAntigen = false
        }
    }, [location])

    const promoCodeValidation = async () => {
        try {
            setBtnLoading(true);
            const response = await getCoupon({
                code: promoCode,
                email: userData?.email,
                testSelection: testSelection,
            });

            if (response?.data?.status === 200) {
                onFieldUpdate({ couponId: response.data.couponId });
                if (isEmployeeEmail) {
                    setMessage(
                        `Coupon code applied succesfully, Please click continue.`
                    );
                } else if (isRapidTest) {
                    onFieldUpdate({
                        discountAmount: (response.data.amount / 100) * 3,
                    });
                    setMessage(
                        `Coupon code applied succesfully. You have got $${(response.data.amount / 100) * 3
                        } as discount. Please click pay now.`
                    );
                } else if (isExpressSameDayTest) {
                    onFieldUpdate({
                        discountAmount: (response.data.amount / 100) * 2,
                    });
                    setMessage(
                        `Coupon code applied succesfully. You have got $${(response.data.amount / 100) * 2
                        } as discount. Please click pay now.`
                    );
                } else {
                    onFieldUpdate({
                        discountAmount: response.data.amount / 100,
                    });
                    setMessage(
                        `Coupon code applied succesfully. You have got $${response.data.amount / 100
                        } as discount. Please click pay now.`
                    );
                }
            } else {
                setMessage(response?.data?.message);
                onFieldUpdate({ discountAmount: 0 });
            }
            setBtnLoading(false);
        } catch (e) {
            console.log('error', e);
        }
    };
    const locationStartDate = location
        ? parse(location.startDate, config.dateFormat, new Date())
        : null;

    const [showRapidAlertModal, setShowRapidAlertModal] = useState(false);
    const handleRapidModalClose = () => {
        setShowRapidAlertModal(false);
    };
    const pageTitle =
        standardTest || isExpressSameDayTest || isRapidTest || isPanel || isAntigen || (location?.partnerName == 'goHealth')
            ? 'Pick a date and time'
            : 'Select your test type';
    const { goToPrevStep, goToSkipPrevStep } = useStepsDispatch();

    const rollbackisExpressSameDayTest = useMemo(
        () => cloneDeep(isExpressSameDayTest),
        [isManage]
    );
    const rollbackisRapidTest = useMemo(() => cloneDeep(isRapidTest), [isManage]);

    const isURL: boolean = isFromUrl();
    // blocking next day after some time period
    let bufferDate: any[] = []
    let currentTime = new Date();
    let Hour = currentTime.getHours()
    let nextDay = format(addDays(currentTime, 1), config.dateFormat).toString()
    let blockOutDayTime: any = location?.blockNextDayAfter ? location?.blockNextDayAfter?.replace(/[^0-9]/g, '') : ''
    if (blockOutDayTime !== '' && location?.blockNextDayAfter?.endsWith('p')) {
        blockOutDayTime = parseInt(blockOutDayTime) + 12;
        if (Hour >= blockOutDayTime)
            if (!(location?.blockOutDays !== undefined && location?.blockOutDays?.length !== 0 && location?.blockOutDays.includes(nextDay))) {
                location?.blockOutDays.push(nextDay)
                let bufferdate = [];
                bufferdate.push(nextDay)
                bufferDate = bufferdate;
            }
    }
    else if (blockOutDayTime !== '' && location?.blockNextDayAfter?.endsWith('a')) {
        blockOutDayTime = parseInt(blockOutDayTime);
        if (Hour >= blockOutDayTime)
            if (!(location?.blockOutDays !== undefined && location?.blockOutDays?.length !== 0 && location?.blockOutDays.includes(nextDay))) {
                location?.blockOutDays.push(nextDay)
                let bufferdate = [];
                bufferdate.push(nextDay)
                bufferDate = bufferdate;
            }
    }
    else {
        bufferDate = [];
    }
    // ends blocking next day
    const TestTypeBox: React.FC<any> = ({ testType, location, values, locationTestType, title, testDuration,step, isCostLabelVisibile, tooltip='' }) => {
        let rapidTest = false, expressTest = false, standardTest = false, antigen = false, panel = false;
        if (title === 'RAPID PCR TEST') {
            rapidTest = true;
            expressTest = false;
            standardTest = false;
            antigen = false;
            panel = false;
        }
        else if (title === 'EXPRESS PCR TEST') {
            rapidTest = false;
            expressTest = true;
            standardTest = false;
            antigen = false;
            panel = false;
        }
        else if (title === 'STANDARD PCR TEST') {
            rapidTest = false;
            expressTest = false;
            standardTest = true;
            antigen = false;
            panel = false;
        }
        else if (title === 'ANTIGEN TEST') {
            rapidTest = false;
            expressTest = false;
            standardTest = false;
            antigen = true;
            panel = false;
        }
        else if (title === 'Flu A&B/Covid-19') {
            rapidTest = false;
            expressTest = false;
            standardTest = false;
            antigen = false;
            panel = true;
        }
        return (
            <Tooltip title={ tooltip ?? ''}  placement='bottom'>
            <InnerBox
                selected={testType}
                style={(standardTest && (location.qbenchCustomerId !== config.qbenchCustomerId.SFOAirport_BARTStation || (location.qbenchCustomerId == config.qbenchCustomerId.SFOAirport_BARTStation && testSelection == 'sfoairport'))) ? {} : {
                    border: locationTestType
                        ? '1px solid #D3D3D3'
                        : '0.5px solid rgba(36, 61, 77, 0.14)',
                    background: locationTestType
                        ? '#D3D3D3'
                        : testType
                            ? colors.rebrandBlack
                            : colors.white,
                    cursor: locationTestType ? 'not-allowed' : 'pointer',
                }}
                aria-disabled={location?.qbenchCustomerId === config.qbenchCustomerId.SFOAirport_BARTStation && testSelection !== 'sfoairport' ? true : standardTest}
                onClick={async () => {
                    if (!(standardTest && location.qbenchCustomerId !== config.qbenchCustomerId.SFOAirport_BARTStation)) {
                        if (locationTestType) {
                            return;
                        }
                    }
                    upateTestType(standardTest);
                    updateStandradTest(standardTest);
                    rapidTest && setShowRapidAlertModal(true);
                    appointment.isWalkUpTest
                        ? onFieldUpdate({
                            isExpressSameDayTest: expressTest,
                            isRapidTest: rapidTest,
                            isAntigen: antigen,
                            isPanel: panel
                        })
                        : onFieldUpdate({
                            slot: undefined,
                            isExpressSameDayTest: expressTest,
                            isRapidTest: rapidTest,
                            isAntigen: antigen,
                            isPanel: panel
                        });
                    // if (!values.departureDateAndTime) {
                    //     return;
                    // }
                    // const overTime = new Date(
                    //     values.departureDateAndTime
                    // ).getHours();
                    const overTime = new Date().getHours();
                    let newDate: any;
                    if (standardTest) {
                        newDate = new Date()
                        // subHours(
                        //     // startOfDay(values.departureDateAndTime),
                        //     new Date(),
                        //     config.maxAdvanceHours
                        // );
                        upateTestType(standardTest);
                        updateStandradTest(standardTest);
                    }
                    else {
                        newDate = new Date()
                        // subHours(
                        //     // new Date(),
                        //     values.departureDateAndTime,
                        //     // new Date(),
                        //     overTime >= 12 ? 24 : rapidTest ? config.rapidSlots.minAdvanceHours : config.vipSlots.minAdvanceHours
                        // );
                    }
                    appointment.isWalkUpTest
                        ? onFieldUpdate({
                            isRapidTest: rapidTest,
                            isAntigen: antigen,
                            isExpressSameDayTest: expressTest,
                            isPanel: panel,
                            date: format(
                                appointment.isWalkUpTest
                                    ? new Date()
                                    : newDate,
                                config.dateFormat
                            ),
                        })
                        : onFieldUpdate({
                            slot: undefined,
                            isRapidTest: rapidTest,
                            isAntigen: antigen,
                            isExpressSameDayTest: expressTest,
                            isPanel: panel,
                            date: format(newDate, config.dateFormat),
                            reservationId: null,
                        })
                }}
            >
                <TestTypeTitle
                    selected={testType}
                    style={(standardTest && (location.qbenchCustomerId !== config.qbenchCustomerId.SFOAirport_BARTStation || (location.qbenchCustomerId == config.qbenchCustomerId.SFOAirport_BARTStation && testSelection == 'sfoairport'))) ? {} : {
                        color: locationTestType
                            ? '#939393'
                            : testType
                                ? colors.white
                                : colors.rebrandBlack,
                    }}
                >
                    {title}
                </TestTypeTitle>
                {isCostLabelVisibile && (
                    <TestCostLabel
                        selected={testType}
                        style={(standardTest && (location.qbenchCustomerId !== config.qbenchCustomerId.SFOAirport_BARTStation || (location.qbenchCustomerId == config.qbenchCustomerId.SFOAirport_BARTStation && testSelection == 'sfoairport'))) ? {} : {
                            color: locationTestType
                                ? '#939393'
                                : testType
                                    ? colors.white
                                    : colors.rebrandBlack,
                        }}
                    >
                        $
                        {convertPricing(
                            expressTest,
                            rapidTest,
                            prices,
                            travelType,
                            destination,
                            antigen,
                            npArray,
                            location?.isNpDestination
                        )}
                    </TestCostLabel>
                )}

                <TestDurationInfoLabel
                    selected={testType}
                    style={(standardTest && (location.qbenchCustomerId !== config.qbenchCustomerId.SFOAirport_BARTStation || (location.qbenchCustomerId == config.qbenchCustomerId.SFOAirport_BARTStation && testSelection == 'sfoairport'))) ? {} : {
                        color: locationTestType
                            ? '#939393'
                            : testType
                                ? colors.white
                                : colors.rebrandBlack,
                    }}
                >
                    {testDuration}
                </TestDurationInfoLabel>
            </InnerBox>
            </Tooltip>
        )
    }
    return (
        <Formik
            enableReinitialize
            initialValues={{
                slot,
                departureDateAndTime: departureDateAndTime
                    ? parse(departureDateAndTime, config.dateTimeFormat, new Date())
                    : null,
                // :new Date(),
                isExpressSameDayTest,
                isRapidTest,
                isAntigen,
                isPanel
            }}
            onSubmit={onSchedule}
            validate={(values) => {
                const errors: FormikErrors<FormikValues> = {};
                if (!values.slot) {
                    errors.slot = 'Please select slot';
                }

                return errors;
            }}
        >
            {({ values }) => (
                <Container size="xl">
                    <Content
                        className={isManage ? 'edit-manage' : ""}
                    >
                        <HeadarContent>
                            <PageHeader>
                                <PageTitleReg>{pageTitle}</PageTitleReg>
                                <PageSubTitle>
                                    <HeaderLocation
                                    >
                                        <PinIcon />
                                        <LocationNameAndAddress>
                                            <span style={{ fontWeight: 'bold' }}>
                                                {appointment.location?.name}
                                            </span>
                                            ,
                                            <p>
                                                {appointment.location?.address1} <br></br>{' '}
                                                {appointment.location?.address2}
                                                {appointment.location
                                                    ?.isOnlyPaymentText && (
                                                        <>
                                                            <br />
                                                            <span
                                                                style={{
                                                                    color: 'red',
                                                                }}
                                                            >
                                                                {
                                                                    appointment
                                                                        .location
                                                                        ?.isOnlyPaymentText
                                                                }
                                                            </span>
                                                        </>
                                                    )}
                                            </p>
                                        </LocationNameAndAddress>
                                        {/* {onLocationChange && ( */}
                                        <ChangeLocationButton
                                            hidden={testSelection === 'kern' || isWalkUpTest ? true : false}
                                            type="button"
                                            onClick={() => {
                                                if(location?.partnerName === 'goHealth'){
                                                    goToSkipPrevStep()
                                                }else  
                                                if (isManage !== undefined && onLocationChange) {
                                                    console.log("pra1")
                                                    onLocationChange()
                                                } else {
                                                    console.log("pra2")
                                                    toggleChangeLocationModal(true)
                                                }
                                            }}
                                        >
                                            Change location
                                        </ChangeLocationButton>
                                        {/* )} */}
                                    </HeaderLocation>
                                </PageSubTitle>
                            </PageHeader>
                            {(testSelection !== 'school') && !(testSelection === 'kern') && !hasInsurance && location?.qbenchCustomerId !== config.qbenchCustomerId.PCMADestination && (isManage ? false : true) && (location?.partnerName !== 'goHealth') &&  (
                                <TestTypeContainer>
                                    {((payingMethod === 'Credit Card'|| payingMethod === 'Credit card')) &&
                                        (<TestTypeBox testType={isRapidTest} location={location} values={values} locationTestType={!location?.rapidTest || isEmpEmail} title={'RAPID PCR TEST'} testDuration={'Results within 2 hours'} isCostLabelVisibile={(appointment.testSelection === 'sfoairport' && appointment.hasInsurance === true) ? false : true}  />)}
                                    {location?.qbenchCustomerId !== config.qbenchCustomerId.SFOAirport_BARTStation && location?.qbenchCustomerId !== config.qbenchCustomerId.PCMADestination &&
                                        <><TestTypeBox testType={isExpressSameDayTest} location={location} values={values} locationTestType={!location?.hasVipSlots} title={'EXPRESS PCR TEST'} testDuration={'Results within 12 hours'} isCostLabelVisibile={(appointment.testSelection === 'sfoairport' && appointment.hasInsurance === true) ? false : true}/>
                                            <TestTypeBox testType={(location?.qbenchCustomerId === config.qbenchCustomerId.SFOAirport_BARTStation) ? false : (standardTest && !isRapidTest)} location={location} values={values} locationTestType={location?.qbenchCustomerId === config.qbenchCustomerId.SFOAirport_BARTStation ? true : (standardTest && !isRapidTest)} title={'STANDARD PCR TEST'} testDuration={includes(config.locationsfor36Hrs, location?.qbenchCustomerId) ? 'Results within 36 hours' : includes(config.locationsfor48Hrs, location?.qbenchCustomerId) ? 'Results within 48 hours' : 'Results within 24 hours'} isCostLabelVisibile={(appointment.testSelection === 'sfoairport' && appointment.hasInsurance === true) ? false : true}  /></>}
                                    {location?.qbenchCustomerId === config.qbenchCustomerId.SFOAirport_BARTStation && testSelection === 'sfoairport' && <TestTypeBox testType={standardTest} location={location} values={values} locationTestType={standardTest} title={'STANDARD PCR TEST'} testDuration={includes(config.locationsfor36Hrs, location?.qbenchCustomerId) ? 'Results within 36 hours' : includes(config.locationsfor48Hrs, location?.qbenchCustomerId) ? 'Results within 48 hours' : 'Results within 24 hours'} isCostLabelVisibile={(appointment.testSelection === 'sfoairport' && appointment.hasInsurance === true) ? false : true}  />}
                                    {(destination?.value !== 'JPN') && !!location?.isAntigen && <TestTypeBox testType={isAntigen} location={location} values={values} locationTestType={!location?.isAntigen || isEmpEmail} title={'ANTIGEN TEST'} testDuration={'Results within 30 minutes'} isCostLabelVisibile={(appointment.testSelection === 'sfoairport' && appointment.hasInsurance === true) ? false : true}/>}
                                </TestTypeContainer>
                            )}

                            {(location?.qbenchCustomerId === config.qbenchCustomerId.PCMADestination && (payingMethod === 'Credit Card'|| payingMethod === 'Credit card')) && (isManage ? false : true) && (location?.partnerName !== 'goHealth') && (
                                <TestTypeContainer>
                                    {(payingMethod === 'Credit Card'|| payingMethod === 'Credit card') &&
                                        (<TestTypeBox testType={isRapidTest} location={location} values={values} locationTestType={!location?.rapidTest || isEmpEmail} title={'RAPID PCR TEST'} testDuration={'Results within 2 hours'} isCostLabelVisibile={(appointment.testSelection === 'sfoairport' && appointment.hasInsurance === true) ? false : true}/>)}
                                    {(destination?.value !== 'JPN') && !!location?.isAntigen && <TestTypeBox testType={isAntigen} location={location} values={values} locationTestType={!location?.isAntigen || isEmpEmail} title={'ANTIGEN TEST'} testDuration={'Results within 30 minutes'} isCostLabelVisibile={(appointment.testSelection === 'sfoairport' && appointment.hasInsurance === true) ? false : true}  />}
                                </TestTypeContainer>)
                            }
                            {(payingMethod === 'Insurance' && testSelection === "sfoairport") && (isManage ? false : true) && (location?.partnerName !== 'goHealth') && 
                                (<TestTypeContainer>
                                    {/* {location?.qbenchCustomerId!== config.qbenchCustomerId.SFOAirport_BARTStation &&<TestTypeBox testType={isExpressSameDayTest} location={location} values={values} locationTestType={!location?.hasVipSlots} title={'EXPRESS TEST'} testDuration={'Results within 12 hours'} isCostLabelVisibile={ false} />} */}

                                    {<TestTypeBox testType={standardTest} location={location} values={values} locationTestType={standardTest} title={'STANDARD PCR TEST'} testDuration={includes(config.locationsfor36Hrs, location?.qbenchCustomerId) ? 'Results within 36 hours' : includes(config.locationsfor48Hrs, location?.qbenchCustomerId) ? 'Results within 48 hours' : 'Results within 24 hours'} isCostLabelVisibile={false}  />}
                                    {(destination?.value !== 'JPN') && (!!location?.isAntigen) && <TestTypeBox testType={isAntigen} location={location} values={values} locationTestType={!location?.isAntigen} title={'ANTIGEN TEST'} testDuration={'Results within 30 minutes'} isCostLabelVisibile={false}  />}
                                </TestTypeContainer>)}

                            {(payingMethod === 'Insurance' && testSelection !== 'community' && testSelection !== "sfoairport" && cPartnerID !== 'SCS001' && location?.qbenchCustomerId !== config.qbenchCustomerId.PCMADestination) && (isManage ? false : true) && (location?.partnerName !== 'goHealth') &&
                                (<TestTypeContainer>
                                    {location?.qbenchCustomerId !== config.qbenchCustomerId.SFOAirport_BARTStation && <TestTypeBox testType={standardTest} location={location} values={values} locationTestType={standardTest} title={'STANDARD PCR TEST'} testDuration={includes(config.locationsfor36Hrs, location?.qbenchCustomerId) ? 'Results within 36 hours' : includes(config.locationsfor48Hrs, location?.qbenchCustomerId) ? 'Results within 48 hours' : 'Results within 24 hours'} isCostLabelVisibile={false} />}
                                    {(payingMethod === 'Insurance' && (testSelection === 'flight')) &&
                                        (<>
                                            {(destination?.value !== 'JPN') && !!location?.isAntigen && <TestTypeBox testType={isAntigen} location={location} values={values} locationTestType={!location?.isAntigen} title={'ANTIGEN TEST'} testDuration={'Results within 30 minutes'} isCostLabelVisibile={false} />}
                                        </>)}
                                    {(payingMethod === 'Insurance' && testSelection === 'general'
                                        //(location?.qbenchCustomerId===152 || location?.qbenchCustomerId===config.qbenchCustomerId.SFOAirport_BARTStation)
                                    ) && (isManage ? false : true) &&
                                        (<>
                                            {!!location?.isAntigen && <TestTypeBox testType={isAntigen} location={location} values={values} locationTestType={!location?.isAntigen} title={'ANTIGEN TEST'} testDuration={'Results within 30 minutes'} isCostLabelVisibile={false} />}
                                        </>)}

                                {location?.isPanel === true &&
                                    (
                                        <TestTypeBox
                                            tooltip={location.panelTooltip}
                                            testType={isPanel}
                                            location={location}
                                            values={values}
                                            locationTestType={!location?.isPanel}
                                            title={'Flu A&B/Covid-19'}
                                            testDuration={'Results within 30 minutes'}
                                            isCostLabelVisibile={false}
                                        />
                                    )}
                                </TestTypeContainer>)}
                                {location?.partnerName === 'goHealth' && (
                                <TestTypeContainer>
                                    <TestTypeBox
                                    testType={standardTest}
                                    location={location}
                                    values={values}
                                    locationTestType={standardTest}
                                    title={'STANDARD PCR TEST'}
                                    testDuration={'Results within 24 hours'}
                                    isCostLabelVisibile={false}
                                    />
                                    {/* <TestTypeBox testType={standardTest} location={location} values={values} locationTestType={standardTest} title={'STANDARD PCR TEST'} testDuration={includes(config.locationsfor36Hrs, location?.qbenchCustomerId) ? 'Results within 36 hours' : includes(config.locationsfor48Hrs, location?.qbenchCustomerId) ? 'Results within 48 hours' : 'Results within 24 hours'} isCostLabelVisibile={false} /> */}
                                </TestTypeContainer>
                                )}

                            {/*Kern Flow*/} {
                                (location?.qbenchCustomerId === config.qbenchCustomerId.KernHealthSystems) &&
                                <TestTypeContainer>
                                    <TestTypeBox testType={isAntigen} location={location} values={values} locationTestType={!location?.isAntigen} title={'ANTIGEN TEST'} testDuration={'Results within 30 minutes'} isCostLabelVisibile={false} />
                                </TestTypeContainer>
                            }

                            {/* Show Test Type for the community Flow for South Los Angels Location */}
                            {(testSelection === 'community' && hasInsurance) && (isManage ? false : true) && (location?.partnerName !== 'goHealth') && (
                                <TestTypeContainer>
                                    {<TestTypeBox testType={standardTest} location={location} values={values} locationTestType={standardTest} title={'STANDARD PCR TEST'} testDuration={includes(config.locationsfor36Hrs, location?.qbenchCustomerId) ? 'Results within 36 hours' : includes(config.locationsfor48Hrs, location?.qbenchCustomerId) ? 'Results within 48 hours' : 'Results within 24 hours'} isCostLabelVisibile={false}/>}
                                    {!!location?.isAntigen && <TestTypeBox testType={isAntigen} location={location} values={values} locationTestType={!location?.isAntigen} title={'ANTIGEN TEST'} testDuration={'Results within 30 minutes'} isCostLabelVisibile={false} />}
                                </TestTypeContainer>
                            )}
                            {(cPartnerID === 'SCS001' || (location?.qbenchCustomerId === config.qbenchCustomerId.PCMADestination && (payingMethod.toLowerCase() !== 'credit card'))) && (isManage ? false : true) && (location?.partnerName !== 'goHealth') && (
                                <TestTypeContainer>
                                    {!!location?.isAntigen && <TestTypeBox testType={isAntigen} location={location} values={values} locationTestType={!location?.isAntigen} title={'ANTIGEN TEST'} testDuration={'Results within 30 minutes'} isCostLabelVisibile={false} />}
                                </TestTypeContainer>
                            )}
                        </HeadarContent>
                        <PageContent>
                            {(!standardTest && !isExpressSameDayTest && !isRapidTest && !isAntigen) || (!!isEmpEmail && location?.qbenchCustomerId === config.qbenchCustomerId.SFOAirport_BARTStation && (payingMethod === "Credit card" || payingMethod === "Credit Card")) && (
                                <div style={{ textAlign: 'center' }}>
                                    <Button
                                        accentColor="#f29a4e"
                                        onClick={() => {
                                            // if (!isManage)
                                            // 	goToPrevStep();
                                            // else
                                            // 	history.push('/user/profile')
                                            if (!isManage) {
                                                if (hasInsurance) {
                                                    goToPrevStep();
                                                } else if (payingMethod === "Credit card" || payingMethod === "Credit Card") {
                                                    goToSkipPrevStep();
                                                    // goToSkipPrevStep();
                                                    goToPrevStep();
                                                    if (testSelection !== 'sfoairport') {
                                                        goToPrevStep();
                                                    }
                                                } else goToSkipPrevStep();
                                            } else history.push('/user/profile');
                                        }}
                                        style={{
                                            width: '24%',
                                        }}
                                        libraryType="default"
                                        type="button"
                                    >
                                        Return
                                    </Button>
                                </div>
                            )}

                            <>
                                <Form>
                                    <FormStyle>
                                        {/* {(standardTest || isExpressSameDayTest || isRapidTest || isAntigen) &&
                                        (testSelection === 'flight') && (
                                            <>
                                                <AboveColumns>
                                                    <FormLabel label="Flight departure date & time (use the final leg of departure)">
                                                        <DatepickerField
                                                            dateAndTime={true}
                                                            name="departureDateAndTime"
                                                            showTimeSelect
                                                            // minDate={
                                                            //     (location?.qbenchCustomerId === config.qbenchCustomerId.BoysGirlsClub || 
                                                            // location?.qbenchCustomerId === config.qbenchCustomerId.SFOAirport_BARTStation) ? new Date(location.startDate) :locationStartDate === null ||
                                                            //         isBefore(locationStartDate, new Date())
                                                            //         ? new Date()
                                                            //         : locationStartDate
                                                            // }
                                                            minDate={
                                                                locationStartDate === null ||
                                                                    isBefore(locationStartDate, new Date())
                                                                    ? new Date()
                                                                    : locationStartDate
                                                            }
                                                            maxDate={disableFutureDt()}
                                                            onChange={(newDepartureDate) => {
                                                                //This time zone offset is for pacific daylight time
                                                                //Will need to add the other time zones offset later.
                                                                // let getTimeZoneOffSet = new Date(
                                                                // 	newDepartureDate
                                                                // ).getTimezoneOffset();

                                                                // const overTime = new Date(
                                                                // 	newDepartureDate
                                                                // ).getHours();

                                                                // let newDate = values.isExpressSameDayTest
                                                                // 	? subHours(
                                                                // 		newDepartureDate,
                                                                // 		overTime >= 12
                                                                // 			? 24
                                                                // 			: config.vipSlots.minAdvanceHours
                                                                // 	) // startOfDay(newDepartureDate)
                                                                // 	: // : subHours(newDepartureDate, config.maxAdvanceHours);
                                                                // 	subHours(
                                                                // 		newDepartureDate,
                                                                // 		getTimeZoneOffSet == 420 ||
                                                                // 			getTimeZoneOffSet == 300 ||
                                                                // 			getTimeZoneOffSet == 240
                                                                // 			? 71
                                                                // 			: config.maxAdvanceHours
                                                                // 	);

                                                                // if (isBefore(newDate, new Date())) {
                                                                // 	newDate = startOfDay(new Date());
                                                                // }

                                                                onFieldUpdate({
                                                                    slot: undefined,
                                                                    departureDateAndTime: format(
                                                                        newDepartureDate,
                                                                        config.dateTimeFormat
                                                                    ),
                                                                    date: appointment.isWalkUpTest
                                                                        ?
                                                                        format(new Date(), config.dateFormat)
                                                                        :
                                                                        format(newDepartureDate, config.dateFormat),
                                                                    reservationId: null,
                                                                });
                                                            }}
                                                        />
                                                    </FormLabel>
                                                </AboveColumns>
                                                {values.departureDateAndTime && (
                                                    <Columns>
                                                        <DatepickerWrapper>
                                                            <FormLabel label="Please select appointment date" children={undefined} />
                                                            <Datepicker
                                                                loading={isSlotsLoading}
                                                                value={
                                                                    appointment.isWalkUpTest ? new Date() : date
                                                                        ? parse(date, config.dateFormat, new Date())
                                                                        : new Date()
                                                                }
                                                                onChange={(newDate) => {
                                                                    setSlotsLoading(true);
                                                                    onFieldUpdate({
                                                                        slot: undefined,
                                                                        reservationId: null,
                                                                        date: format(newDate, config.dateFormat),
                                                                    })
                                                                    // if (childRef.current.InitiateSlots !== undefined) {
                                                                    //     setTimeout(() => {
                                                                    //         childRef.current.InitiateSlots();
                                                                    //     }, 2000)
                                                                    // }
                                                                }}
                                                                isDayFilter={
                                                                    appointment.testSelection !==
                                                                        'general'
                                                                        ? location?.isDayFilter !==
                                                                            undefined
                                                                            ? location?.isDayFilter
                                                                            : false
                                                                        : false
                                                                }
                                                                isWeeklyFlag={
                                                                    // location?.isWeeklyFlag !== undefined
                                                                    // ?
                                                                    blockOutDays !== undefined
                                                                        ?
                                                                        blockOutDays.length < 7
                                                                            ?
                                                                            true
                                                                            :
                                                                            location?.isWeeklyFlag
                                                                        :
                                                                        location?.isWeeklyFlag
                                                                    // :
                                                                    //     location?.isWeeklyFlag
                                                                }
                                                                weeklyVisible={{
                                                                    weeklyVisibleDays: blockOutDays,
                                                                    weeklyVisibleNumber:
                                                                        location?.weeklyVisibleNumber !==
                                                                            undefined
                                                                            ? location?.weeklyVisibleNumber
                                                                            : {
                                                                                weekDay: 0,
                                                                                weekNumber: 0,
                                                                            }
                                                                }
                                                                }
                                                                isblockOutDays={
                                                                    location?.blockOutDays !==
                                                                        undefined
                                                                        ? location?.blockOutDays
                                                                        : bufferDate
                                                                }
                                                                // minDate={(location?.qbenchCustomerId === config.qbenchCustomerId.BoysGirlsClub || 
                                                                // location?.qbenchCustomerId === config.qbenchCustomerId.SFOAirport_BARTStation) ? new Date(location.startDate) : new Date()}
                                                                minDate={new Date()}
                                                                maxDate={isWalkUpTest ? new Date() : disableFutureDt()}
                                                                inline
                                                                cPartnerID={location?.cPartnerID}
                                                                location={location}
                                                            />
                                                        </DatepickerWrapper>
                                                        <SlotsWrapper>
                                                            <Slots
                                                                isManage={isManage}
                                                                isAdmin={isAdmin}
                                                                appointment={appointment}
                                                                onFieldUpdate={onFieldUpdate}
                                                                setSlotsLoading={setSlotsLoading}
                                                                isSlotsLoading={isSlotsLoading}
                                                                isNextStep={true}
                                                                isWalkUpTest={isWalkUpTest}
                                                            // ref={childRef}
                                                            />
                                                            {isManage && RescheduleTestType.change && appointment.slot &&
                                                                (<CouponContainer>
                                                                    <FormLabel label="Coupon Code">
                                                                        <Inputs
                                                                            name="promoCode"
                                                                            placeholder="Enter The Coupon Code"
                                                                            onChange={(value: any) => {
                                                                                onFieldUpdate({ promoCode: value });
                                                                            }}
                                                                            value={promoCode}
                                                                        />
                                                                    </FormLabel>
                                                                    <FormLabel label="">
                                                                        <Button
                                                                            libraryType="primary"
                                                                            type="button"
                                                                            onClick={() => promoCodeValidation()}
                                                                        >
                                                                            {btnloading
                                                                                ? 'Processing...'
                                                                                : 'Apply'}
                                                                        </Button>
                                                                    </FormLabel>
                                                                </CouponContainer>
                                                                )}
                                                            {message && (
                                                                <Error
                                                                    style={{
                                                                        color: (discountAmount >
                                                                            0) || (isEmpEmail && message !== "Invalid coupon code")
                                                                            ? 'green'
                                                                            : 'red',
                                                                    }}
                                                                >
                                                                    {message}
                                                                </Error>
                                                            )}
                                                            {isManage && !isEmployeeEmail &&
                                                                RescheduleTestType.change &&
                                                                appointment.slot && (
                                                                    <div style={{ margin: '30px 0', fontWeight: 800 }}>
                                                                        Note*<br />
                                                                        If {RescheduleTestType.upgrade ? ' Upgrading ' : ' Degrading '} from a {RescheduleTestType.previous} Test to an {RescheduleTestType.present} Test, a full refund for your paid {RescheduleTestType.previous} Tests will be credited to the original credit card in 7-15business days. There will be a new chargefor the {RescheduleTestType.present} Tests.
                                                                        , a full refund for your paid{' '}
                                                                        {
                                                                            RescheduleTestType.previous
                                                                        }{' '}
                                                                        Tests will be credited to the original credit card in 7-15 business days.
                                                                        There will be a new charge for the{' '}
                                                                        {
                                                                            RescheduleTestType.present
                                                                        }{' '}Tests.
                                                                    </div>
                                                                )}
                                                            {(isManage && isAdmin) && !isEmployeeEmail &&
                                                                rollbackisRapidTest == false &&
                                                                isRapidTest == true &&
                                                                appointment.paymentIntentId != 'ins' &&
                                                                appointment.paymentIntentId != 'noins' && (
                                                                    <div style={{ margin: '20px 10px 0px 0px' }}>
                                                                        <RefundInfoText>*Note</RefundInfoText>
                                                                        {isRapidTest &&
                                                                            appointment.paymentIntentId != 'ins' &&
                                                                            appointment.paymentIntentId != 'noins' && (
                                                                                <RefundInfoText>
                                                                                    If upgrading from a Standard Test or Express
                                                                                    Test to a Rapid test, a full refund for your
                                                                                    paid Standard Tests or Express Tests will be
                                                                                    credited to the original credit card in 7-15
                                                                                    business days. There will be a new charge
                                                                                    for the Rapid Tests.
                                                                                </RefundInfoText>
                                                                            )}
                                                                    </div>
                                                                )}
                                                            {isManage ?
                                                                <NavigationButtons
                                                                    isValid={appointment.slot ? true : false}
                                                                    loading={isSlotsLoading}
                                                                    confirmBtnText={confirmBtnText}
                                                                    onReturnButtonClick={() => {
                                                                        if (!isManage) {
                                                                            if (hasInsurance) {
                                                                                goToPrevStep();
                                                                            } else if (((testSelection === 'flight' || testSelection === 'general') && form.address.country !== "United States") ||
                                                                                (location?.hasInsurance !== undefined &&
                                                                                    location.hasInsurance === true)) {
                                                                                goToSkipPrevStep();
                                                                                goToPrevStep();
                                                                                goToPrevStep();
                                                                            } else if (payingMethod === 'Credit card') {
                                                                                goToSkipPrevStep();
                                                                                goToSkipPrevStep();
                                                                                // if (testSelection === 'sfoairport') {
                                                                                // 	goToPrevStep();
                                                                                // }
                                                                            } else if (isURL) {
                                                                                goToPrevStep();
                                                                            } else goToSkipPrevStep();
                                                                        } else history.push('/user/profile');
                                                                    }}
                                                                />
                                                                :
                                                                <NavigationButtons
                                                                    loading={isSlotsLoading}
                                                                    confirmBtnText={confirmBtnText}
                                                                    onReturnButtonClick={() => {
                                                                        if (!isManage) {
                                                                            if (hasInsurance) {
                                                                                goToPrevStep();
                                                                            } else if (((testSelection === 'flight' || testSelection === 'general') && address.country !== "United States") ||
                                                                                (location?.hasInsurance !== undefined &&
                                                                                    location.hasInsurance === true)) {
                                                                                goToSkipPrevStep();
                                                                                goToSkipPrevStep();
                                                                                goToPrevStep();
                                                                            } else if (payingMethod === 'Credit card') {
                                                                                goToSkipPrevStep();
                                                                                goToSkipPrevStep();
                                                                                if (location?.qbenchCustomerId === config.qbenchCustomerId.SFOAirport_BARTStation && !(form.email)) {
                                                                                    goToPrevStep()
                                                                                }
                                                                            } else if (isURL) {
                                                                                goToPrevStep();
                                                                            } else goToSkipPrevStep();
                                                                            if (((testSelection === 'flight' || testSelection === 'general') && form.address.country === 'United States') &&
                                                                                ([config.qbenchCustomerId.WestPalmBeach_AmericanResearchLabs,
                                                                                config.qbenchCustomerId.FortLauderdale_AmericanResearchLabs,
                                                                                config.qbenchCustomerId.PalmBeachGardens_AmericanResearchLabs,
                                                                                config.qbenchCustomerId.WestPalmBeach_CTRX].includes(location?.qbenchCustomerId ? location?.qbenchCustomerId : 0))) {
                                                                                goToPrevStep();
                                                                            }
                                                                        } else history.push('/user/profile');
                                                                    }}
                                                                />
                                                            }
                                                        </SlotsWrapper>
                                                    </Columns>
                                                )}
                                            </>
                                        )} */}
                                        {goHealthError && <GoHealthError>{config?.GoHealthApptAlert}</GoHealthError>}
                                        {(standardTest || isExpressSameDayTest || isRapidTest || isAntigen ||isPanel) && !(!!isEmpEmail && location?.qbenchCustomerId === config.qbenchCustomerId.SFOAirport_BARTStation && (payingMethod === "Credit card" || payingMethod === "Credit Card")) &&
                                            // testSelection !== 'flight' && 
                                            (
                                                <Columns
                                                >
                                                    <DatepickerWrapper>
                                                        <Datepicker
                                                            includeDates={location?.includeDates}
                                                            loading={isSlotsLoading}
                                                            value={
                                                                isWalkUpTest ? new Date() : date
                                                                    ? parse(date, config.dateFormat, new Date())
                                                                    : new Date()
                                                            }
                                                            onChange={(newDate) => {
                                                                setSlotsLoading(true);
                                                                onFieldUpdate({
                                                                    slot: undefined,
                                                                    reservationId: null,
                                                                    date: format(newDate, config.dateFormat),
                                                                })
                                                                // if (childRef.current.InitiateSlots !== undefined) {
                                                                //     setTimeout(() => {
                                                                //         childRef.current.InitiateSlots();
                                                                //     }, 2000)
                                                                // }
                                                            }}
                                                            isDayFilter={
                                                                testSelection !==
                                                                    'general'
                                                                    ? location?.isDayFilter !==
                                                                        undefined
                                                                        ? location?.isDayFilter
                                                                        : false
                                                                    : false
                                                            }
                                                            isWeeklyFlag={
                                                                // testSelection !==
                                                                //     'general' ?
                                                                blockOutDays !== undefined
                                                                    ?
                                                                    blockOutDays.length < 7
                                                                        ?
                                                                        true
                                                                        :
                                                                        location?.isWeeklyFlag
                                                                    :
                                                                    location?.isWeeklyFlag
                                                                // : false
                                                            }
                                                            weeklyVisible={{
                                                                weeklyVisibleDays: blockOutDays,
                                                                weeklyVisibleNumber:
                                                                    location?.weeklyVisibleNumber !==
                                                                        undefined
                                                                        ? location?.weeklyVisibleNumber
                                                                        : {
                                                                            weekDay: 0,
                                                                            weekNumber: 0,
                                                                        },
                                                            }}
                                                            isblockOutDays={
                                                                location?.blockOutDays !== undefined
                                                                    ? location?.blockOutDays
                                                                    : bufferDate
                                                            }
                                                            minDate={new Date()}
                                                            maxDate={isWalkUpTest ? new Date() : disableFutureDt()}
                                                            inline
                                                            cPartnerID={location?.cPartnerID}
                                                            location={location}
                                                        />
                                                    </DatepickerWrapper>
                                                    <SlotsWrapper>
                                                        <Slots
                                                            isManage={isManage}
                                                            isAdmin={isAdmin}
                                                            appointment={appointment}
                                                            onFieldUpdate={onFieldUpdate}
                                                            setSlotsLoading={setSlotsLoading}
                                                            isSlotsLoading={isSlotsLoading}
                                                            isNextStep={true}
                                                            isWalkUpTest={isWalkUpTest}
                                                        // ref={childRef}
                                                        />
                                                        {/* {isManage && RescheduleTestType.change && appointment.slot &&
                                                        testSelection !== 'school' && testSelection !== 'community' &&
                                                        testSelection !== 'sfoairport' && (
                                                            <CouponContainer>
                                                                <FormLabel label="Coupon Code">
                                                                    <Inputs
                                                                        name="promoCode"
                                                                        placeholder="Enter The Coupon Code"
                                                                        onChange={(value: any) => {
                                                                            onFieldUpdate({ promoCode: value });
                                                                        }}
                                                                        value={promoCode}
                                                                    />
                                                                </FormLabel>
                                                                <FormLabel label="">
                                                                    <Button
                                                                        libraryType="primary"
                                                                        type="button"
                                                                        onClick={() => promoCodeValidation()}
                                                                    >{btnloading ? 'Processing...' : 'Apply'}
                                                                    </Button>
                                                                </FormLabel>
                                                            </CouponContainer>
                                                        )} */}
                                                        {message && (
                                                            <Error
                                                                style={{
                                                                    color: (discountAmount >
                                                                        0) || (isEmpEmail && message !== "Invalid coupon code")
                                                                        ? 'green'
                                                                        : 'red',
                                                                }}
                                                            >{message}</Error>
                                                        )}

                                                        {isManage && !isEmployeeEmail && !(testSelection === 'kern') &&
                                                            RescheduleTestType.change &&
                                                            appointment.slot && (
                                                                <div style={{ margin: '30px 0', fontWeight: 800 }}
                                                                > Note*<br />
                                                                    If{' '}{RescheduleTestType.upgrade ? ' Upgrading '
                                                                        : ' Downgrading '}{' '} from a{' '}
                                                                    {RescheduleTestType.previous}{' '}
                                                                    Test to an{' '}
                                                                    {RescheduleTestType.present}{' '}
                                                                    Test, a full refund for your
                                                                    paid{' '}{RescheduleTestType.previous}{' '}
                                                                    Tests will be
                                                                    credited to the original credit card in 7-15
                                                                    business days. There will be a new charge for
                                                                    the{' '}{RescheduleTestType.present}{' '}Tests.
                                                                </div>
                                                            )}
                                                        {(isManage && isAdmin) &&
                                                            rollbackisRapidTest == false &&
                                                            isRapidTest == true &&
                                                            (appointment.hasInsurance !== undefined ? appointment.hasInsurance == true ? false : true : true) &&
                                                            appointment.paymentIntentId != 'ins' &&
                                                            appointment.paymentIntentId != 'noins' && (
                                                                <div style={{ margin: '20px 10px 0px 0px' }}>
                                                                    <RefundInfoText>*Note</RefundInfoText>
                                                                    {isRapidTest &&
                                                                        appointment.paymentIntentId != 'ins' &&
                                                                        appointment.paymentIntentId != 'noins' && !isEmployeeEmail && (
                                                                            <RefundInfoText>
                                                                                If upgrading from a Standard PCR Test or
                                                                                Express PCR Test to a Rapid PCR test, a full
                                                                                refund for your paid Standard PCR Tests or
                                                                                Express PCR Tests will be credited to the
                                                                                original credit card in 7-15 business
                                                                                days. There will be a new charge for the
                                                                                Rapid PCR Tests.
                                                                            </RefundInfoText>
                                                                        )}
                                                                </div>
                                                            )}
                                                        {isManage ?
                                                            <NavigationButtons
                                                                isValid={appointment.slot ? true : false}
                                                                loading={isSlotsLoading}
                                                                confirmBtnText={confirmBtnText}
                                                                onReturnButtonClick={() => {
                                                                    if (!isManage) {
                                                                        if(location?.partnerName === 'goHealth'){
                                                                            goToPrevStep()
                                                                        } else if (hasInsurance) {
                                                                            goToPrevStep();
                                                                        } else if (((testSelection === 'flight' || testSelection === 'general') && form.address.country !== "United States") ||
                                                                            (location?.hasInsurance !== undefined &&
                                                                                location.hasInsurance === true)) {
                                                                            goToSkipPrevStep();
                                                                            goToPrevStep();
                                                                            goToPrevStep();
                                                                        } else if (payingMethod === "Credit card" || payingMethod === "Credit Card") {
                                                                            goToSkipPrevStep();
                                                                            goToSkipPrevStep();
                                                                            goToPrevStep();
                                                                        } else if (testSelection === 'kern' || cPartnerID === 'KEN001') {
                                                                            goToPrevStep();
                                                                        } else goToSkipPrevStep();
                                                                    } else history.push('/user/profile');
                                                                }}
                                                            />
                                                            :
                                                            <NavigationButtons
                                                                isValid={appointment.slot ? true : false}
                                                                loading={isSlotsLoading}
                                                                confirmBtnText={location?.partnerName=== 'goHealth' ? loading ? 'Processing...' : 'Book Appointment' : confirmBtnText}
                                                                onReturnButtonClick={() => {
                                                                    if (!isManage) {
                                                                        if(location?.qbenchCustomerId===config.qbenchCustomerId.SFOAirport_BARTStation && destination?.value=='JPN' && travellType=='INT' ){
                                                                            goToPrevStep()
                                                                            goToSkipPrevStep()
                                                                            goToSkipPrevStep()
                                                                        } else if(testSelection === "flight" && !hasInsurance ){                                     
                                                                            goToSkipPrevStep();
                                                                            goToSkipPrevStep();
                                                                            goToPrevStep(); 
                                                                          }else if(location?.partnerName === 'goHealth'){
                                                                            goToPrevStep()
                                                                        } else if (hasInsurance) {
                                                                            
                                                                            goToPrevStep();
                                                                            if(userData?.isVerified){
                                                                                updateFormValues({insurance:{}})
                                                                            }
                                                                        } else if (((testSelection === 'flight' || testSelection === 'general') && form.address.country !== "United States") ||
                                                                            (location?.hasInsurance !== undefined &&
                                                                                location.hasInsurance === true)) {
                                                                            goToSkipPrevStep();
                                                                            goToSkipPrevStep();
                                                                            goToPrevStep();
                                                                        } else if (payingMethod === "Credit card" || payingMethod === "Credit Card") {
                                                                            goToSkipPrevStep();
                                                                            goToSkipPrevStep();
                                                                            if (
                                                                                [
                                                                            config.qbenchCustomerId.WestPalmBeach_AmericanResearchLabs,
                                                                                     config.qbenchCustomerId.WestPalmBeach_CTRX,
                                                                            // config.qbenchCustomerId.SFOAirport_BARTStation,
                                                                             config.qbenchCustomerId.PalmBeachGardens_AmericanResearchLabs,
                                                                            config.qbenchCustomerId.FortLauderdale_AmericanResearchLabs
                                                                        ]
                                                                                .includes(location?.qbenchCustomerId ? location?.qbenchCustomerId : 0)) {
                                                                                goToPrevStep();
                                                                            }
                                                                            // if (location?.qbenchCustomerId === config.qbenchCustomerId.SFOAirport_BARTStation) {
                                                                            //     goToPrevStep()
                                                                            // }
                                                                            // if (testSelection !== 'sfoairport' || location?.qbenchCustomerId === config.qbenchCustomerId.SFOAirport_BARTStation) {
                                                                            //     goToPrevStep();
                                                                            // }
                                                                        }
                                                                        else if (testSelection === 'kern' || cPartnerID === 'KEN001') {
                                                                            goToSkipPrevStep();
                                                                            goToSkipPrevStep();
                                                                            goToPrevStep();
                                                                        } else goToSkipPrevStep();
                                                                        if (((testSelection === 'flight' || testSelection === 'general') && form.address.country === 'United States') &&
                                                                            ([config.qbenchCustomerId.WestPalmBeach_AmericanResearchLabs,
                                                                            config.qbenchCustomerId.FortLauderdale_AmericanResearchLabs,
                                                                            config.qbenchCustomerId.PalmBeachGardens_AmericanResearchLabs,
                                                                            config.qbenchCustomerId.WestPalmBeach_CTRX].includes(location?.qbenchCustomerId ? location?.qbenchCustomerId : 0))) {
                                                                        }
                                                                        // if (location?.qbenchCustomerId === config.qbenchCustomerId.LasVegasSpringValley) {
                                                                        //     goToPrevStep()
                                                                        // }
                                                                    } else history.push('/user/profile');
                                                                }}
                                                            />
                                                        }
                                                    </SlotsWrapper>
                                                </Columns>
                                            )}
                                    </FormStyle>
                                </Form>
                            </>
                        </PageContent>
                    </Content>
                    <ChangeLocationModal
                        isManage={isManage}
                        appointment={appointment}
                        showModal={showChangeLocationModal}
                        openModal={() => setShowChangeDatetimeModal(false)}
                        test={
                            isExpressSameDayTest
                              ? 'express'
                              : isRapidTest
                              ? 'rapid'
                              : isAntigen
                              ? 'antigen'
                              : standardTest
                              ? 'standard'
                              : location?.partnerName === 'goHealth' 
                              ? 'goHealth' 
                              : ''
                          }
                          payment={payingMethod}
                    />
                    <RapidAlertModal
                        handleModalClose={handleRapidModalClose}
                        open={showRapidAlertModal}
                    />
                    <LocationModal />
                </Container>
            )}
        </Formik>
    );
};

export default Schedule;