import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import styled from 'styled-components';
import { BiPlus } from 'react-icons/bi';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { isEmpty } from 'lodash';
import {
    Container,
    Content,
    PageHeader,
    PageTitle,
    ErrorText,
    Button
} from '../../../shared/components/styled';
import { Breakpoints } from '../../../../dictionaries';

import { useSharedDispatch, useSharedState } from '../../../shared/provider';
import FormLabel from '../../../shared/components/form/FormLabel';
import NavigationButtons from '../../../shared/components/NavigationButtons';
import Input from '../../../shared/components/form/Input';
import Select from '../../../shared/components/form/Select';
import { useStepsDispatch, useStepsState } from '../../../schedule/provider';
import { states } from '../Address/states';
import ZipCodeInput from '../Address/ZipCodeInput';
import { colors } from '../../../../styles/colors';
import Checkbox from '../../../shared/components/form/Checkbox';
import DrivingLicenseModel from '../DrivingLicenseModel';
import { makeStyles } from '@material-ui/core/styles';
import { updateRegister, getGovtId } from '../../api';
import { GovtID } from '../../../shared/models';
import { config } from '../../../../config';

const CityStateRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > *:first-child {
    width: 60%;
  }
  & > *:last-child {
    width: 38%;
  }
  @media (max-width: ${Breakpoints.sm}px) {
    & > *:last-child {
      margin-top: 35px;
    }
  }
`;

const Gap = styled.div`
  margin-top: 0px;
  @media (max-width: ${Breakpoints.sm}px) {
    margin-top: 35px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  flex-wrap: nowrap;
  padding-top: 20px;
  @media (min-width: 450px) {
    flex-direction: row;
  }
`;

const StyledButton = styled.button`
  border: 1px dashed ${colors.grey};
  border-radius: 10px;
  font-size: 16px;
  font-weight: 500;
  color: rgb(26 150 219);
  line-height: 24px;
  padding: 15px 28px;
  transition: all 0.15s ease-in-out;
  width: 100%;
  cursor: pointer;
  &:focus,
  &:active {
    border: 1px dotted ${colors.blueLight};
    box-shadow: 0 0 4px ${(colors.blueLight, 0.8)};
  }
  @media (max-width: ${Breakpoints.sm}px) {
    margin-top: 40px;
  }
`;

const ButtonText = styled.div`
  display: inline-flex;
  align-items: center;
  position: relative;
  text-align: center;
  @media (max-width: ${Breakpoints.sm}px) {
    margin-left: 0px;
  }
`;

const Img = styled.img`
  width: 130px;
  height: 130px;
  @media (max-width: ${Breakpoints.sm}px) {
    width: 130px;
    height: 130px;
  }
`;

const RadioBox = styled.input`
  height: 19px;
  width: 19px;
  border: 1px solid ${colors.blueLight};
  border-radius: 0px;
  outline: none;
  transition-duration: 0.3s;
  cursor: pointer;
  margin-left: 5px;
  margin-right: 10px;
  background-color: ${colors.white};
`;

const WebImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 10px;
  @media (max-width: ${Breakpoints.sm}px) {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
  }
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const Space = styled.div`
  height: 10px;
  width: 20px;
`;
const useStyles = makeStyles({
    countryDropDown: {
        borderRadius: '10px',
        width: '560px',
        padding: "20px",
        border: "1px solid #B4B4BB",
        margin: "0px 0px 25px 0px"
    },
    regionDropDown: {
        borderRadius: '10px',
        width: '210px',
        padding: "17.5px",
        border: "1px solid #B4B4BB",
        margin: "0px 0px 25px 0px"
    }

})




const NoInsuranceDetails: React.FC = () => {

    let {
        form,
        form: {
            address,
            location,
            govtId,
            email,
            cPartnerID,
            payingMethod
        }
    } = useStepsState();

    // const address:any=form.address;
    const licenseBackCard: any = form.licenseBackCard;
    const licenseFrontCard: any = form.licenseFrontCard;
    const govtIdFrontCard: any = form.govtId?.frontCard;
    const govtIdBackCard: any = form.govtId?.backCard;
    const testSelection: any = form.testSelection;
    const socialSecurityNumber: any = form.socialSecurityNumber
    const {
        updateFormValues,
        goToNextStep,
        goToSkip4Step,
        goToPrevStep,
        goToSkipStep,
        goToSkipPrevStep,
    } = useStepsDispatch();
    const { userData, userGovtId, isWalkUp } = useSharedState();
    const [ssn, setSsn] = useState(false);
    const [dl, setDl] = useState(false);
    const [checks, setChecks] = useState(false);
    const [values, setValues] = useState<any>();
    const [front, setfront] = useState('');
    const [back, setback] = useState('');
    const [err, setErr] = useState(false);
    const [time, setTime] = useState(false);
    const { setloggedUserData } = useSharedDispatch();
    const [governmentId, setGovernmentId] = useState<GovtID[]>([]);
    const [isGovtId, setIsGovtId] = useState(false)
    const [data, setData] = React.useState<GovtID>();
    const [openModal, setOpenModal] = React.useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const handleModalOpen = () => {
        setOpenModal(true);
    };
    React.useEffect(()=>{
        if(payingMethod === 'No Payment' || location?.cPartnerID==='KEN001'){
            // goToNextStep()
            updateFormValues({ cPartnerID: 'KEN001' })
            goToSkipStep()
        }
    },[])
    const handleModalClose = () => {
        setOpenModal(false);
    };
    const updateAddressValue = (newValue: any) => {
        updateFormValues({
            address: {
                ...address,
                ...newValue,
            },
        });
    };

    const updateChangeOnClick = async (values: any) => {
        setLoading(true);
        let resultdata = await updateRegister({ email: userData?.email, address: values?.address });
        if (resultdata?.data?.status === 200) {
            setloggedUserData(resultdata?.data?.data);
            localStorage.setItem('user', JSON.stringify(userData));
        }

        setLoading(false);
    }

    const handleGovtId = async () => {
        setLoading(true);
        if (!isEmpty(values)) {
            try {
                updateFormValues({
                    govtId: {
                        id: values.id,
                        frontCard: values.frontCard,
                        backCard: values.backCard,
                    },
                });

                // await new Promise<void>((resolve) => {
                //   setTimeout(() => {
                //     resolve();
                //   }, 500);
                // });

                // updateFormValues({ confirmationId: result.data.id });

                // goToSkipStep();
                // goToNextStep();
                setError(null);
                setLoading(false);
            } catch (e) {
                let ee: any = e;
                setError(ee?.message);
                setLoading(false);
            }
        } else {
            setError('Please select any of the govt Id.');
            setLoading(false);
        }
    };

    const getGovernmentId = async () => {
        try {
            // let IdData=await getGovtId(userData?.email);
            let IdData = await getGovtId({ email: userData?.email })
            if (IdData?.data.status === 200) {
                setGovernmentId(IdData.data.data);
                setIsGovtId(true)
            } else {
                // setGovernmentId(null)
            }
            setLoading(false);
        }
        catch (error) {
            setLoading(false);
        }
    }

    React.useEffect(() => {
        if (!userData?.email && govtIdFrontCard && govtIdBackCard) {
            setfront(govtIdFrontCard);
            setback(govtIdBackCard);
        }
        // if (!dl) {
        //   if (
        //     licenseBackCard != null &&
        //     licenseFrontCard != null &&
        //     licenseBackCard !== undefined &&
        //     licenseFrontCard !== undefined
        //   ) {
        //     setErr(false);
        //   } else {
        //     setErr(true);
        //   }
        // } else {
        //   setErr(false);
        // }
        getGovernmentId()
    }, [govtIdFrontCard, govtIdBackCard, licenseBackCard, licenseFrontCard, dl, socialSecurityNumber, values]);
    const classes = useStyles();


    return (
        <Container>
            <Content>
                <PageHeader>
                    <PageTitle>Please enter the following details</PageTitle>
                </PageHeader>
                <Formik
                    initialValues={{
                        address,
                        govtId,
                        email
                    }}
                    onSubmit={(values) => {
                        if (form.address.country === "United States") {
                            if (!dl && !ssn) {

                                if (
                                    licenseBackCard != null &&
                                    licenseFrontCard != null &&
                                    socialSecurityNumber != null &&
                                    licenseBackCard !== undefined &&
                                    licenseFrontCard !== undefined &&
                                    socialSecurityNumber !== undefined
                                ) {
                                    setErr(false);
                                    updateFormValues({
                                        address: {
                                            ...address,
                                            state: values.address.state || address.state || null,
                                            country: values.address.country || address.country || null,
                                        },
                                    });
                                    updateChangeOnClick({
                                        address: {
                                            ...address,
                                            state: values.address.state || address.state || null,
                                            country: values.address.country || address.country || null,
                                        },
                                    });

                                    if (cPartnerID === 'LLT001') {
                                        goToSkip4Step()
                                    } else {
                                        goToNextStep();
                                        goToNextStep();
                                    }
                                } else {
                                    setErr(true);
                                }

                            } else if (!dl) {
                                if (
                                    checks &&
                                    licenseBackCard != null &&
                                    licenseFrontCard != null &&
                                    licenseBackCard !== undefined &&
                                    licenseFrontCard !== undefined
                                ) {
                                    setErr(false);
                                    updateFormValues({
                                        address: {
                                            ...address,
                                            state: values.address.state || address.state || null,
                                            country: values.address.country || address.country || null,
                                        },
                                    });
                                    updateChangeOnClick({
                                        address: {
                                            ...address,
                                            state: values.address.state || address.state || null,
                                            country: values.address.country || address.country || null,
                                        },
                                    });

                                    if (cPartnerID === 'LLT001') {
                                        goToSkip4Step()
                                    } else {
                                        goToNextStep();
                                        goToNextStep();
                                    }
                                } else {
                                    setErr(true);
                                }

                            } else if (!ssn) {
                                if (
                                    socialSecurityNumber != null &&
                                    socialSecurityNumber !== undefined
                                ) {
                                    setErr(false);
                                    updateFormValues({
                                        address: {
                                            ...address,
                                            state: values.address.state || address.state || null,
                                            country: values.address.country || address.country || null,
                                        },
                                    });
                                    updateChangeOnClick({
                                        address: {
                                            ...address,
                                            state: values.address.state || address.state || null,
                                            country: values.address.country || address.country || null,
                                        },
                                    });

                                    if (cPartnerID === 'LLT001') {
                                        goToSkip4Step()
                                    } else {
                                        goToNextStep();
                                        goToNextStep();
                                    }
                                } else {
                                    setErr(true);
                                }
                            } else {
                                setErr(true)
                                if (cPartnerID === 'LLT001') {
                                    goToSkip4Step()
                                } else {
                                    goToNextStep();
                                    goToNextStep();
                                }

                            }

                        } else {
                            setErr(false);
                            updateFormValues({
                                address: {
                                    ...address,
                                    state: values.address.state || address.state || null,
                                    country: values.address.country || address.country || null,
                                },
                            });
                            updateChangeOnClick({
                                address: {
                                    ...address,
                                    state: values.address.state || address.state || null,
                                    country: values.address.country || address.country || null,
                                },
                            });

                            goToNextStep();
                            goToNextStep();

                        }

                    }}
                >
                    {() => (
                        <Form>
                            <FormLabel label="Country" isRequired>
                                {cPartnerID === 'LLT001' ? (
                                    <Input
                                        value="United States"
                                        onChange={(value) => updateAddressValue({ country: value })}
                                        name="address.country"
                                        disabled
                                    />
                                ) : (
                                    <CountryDropdown
                                        classes={classes.countryDropDown}
                                        value={address.country}
                                        name="address.country"
                                        // defaultOptionLabel="United States"
                                        onChange={(value) => updateAddressValue({ country: value })}
                                    // onChange={(value) => console.log("CountryDropdownvalue",value)}
                                    />

                                )}

                            </FormLabel>

                            <FormLabel label="Street address" isRequired>
                                <Input
                                    value={address.address}
                                    onChange={(value) => updateAddressValue({ address: value })}
                                    name="address.address"
                                    isRequired
                                />
                            </FormLabel>
                            <CityStateRow>
                                <FormLabel label="City" isRequired>
                                    <Input
                                        value={address.city}
                                        onChange={(value) => updateAddressValue({ city: value })}
                                        name="address.city"
                                        isRequired
                                    />
                                </FormLabel>
                                {address.country == 'United States' ? (
                                    <div style={{ marginTop: "5px" }} >
                                        <FormLabel label="State/Province/Region" isRequired>
                                            <div style={{ width: '50px', height: '80px' }}>
                                                {/* <Field> */}
                                                <RegionDropdown
                                                    classes={classes.regionDropDown}
                                                    name="address.state"
                                                    country={address.country}
                                                    value={address.state}
                                                    valueType="short"
                                                    onChange={(value) => updateAddressValue({ state: value })}
                                                />
                                                {/* </Field> */}
                                            </div>
                                            {!form.address.state && (<div style={{ color: "red", fontSize: "13px", marginLeft: "5px" }}>Required field</div>)}
                                        </FormLabel>
                                    </div>
                                )
                                    : (
                                        // <CityStateRow>
                                        //   <FormLabel label="City/Town">
                                        //     <Input
                                        //       onChange={(value) => updateAddressValue({ city: value })}
                                        //       name="address.city"
                                        //       isRequired
                                        //       value={address.City}
                                        //     />
                                        //   </FormLabel>
                                        <div style={{ marginBottom: "11px" }} >
                                            <FormLabel label="State/Province/Region" >
                                                <div style={{ width: '50px', height: '80px' }}>
                                                    {/* <Field> */}
                                                    <RegionDropdown
                                                        classes={classes.regionDropDown}
                                                        name="address.state"
                                                        country={address.country}
                                                        value={address.state}
                                                        valueType="short"
                                                        onChange={(value) => updateAddressValue({ state: value })}
                                                    />
                                                    {/* </Field> */}
                                                    {/* {!form.address.state && (<span style={{ color: "red", fontSize: "13px" }}>Required field</span>)} */}
                                                </div>
                                            </FormLabel>
                                        </div>

                                    )}
                            </CityStateRow>

                            {((testSelection === 'flight' || testSelection === 'general' || testSelection === 'school' || testSelection === 'community') && form.address.country === "United States") &&
                                <>
                                    <ZipCodeInput
                                        updateCountyValue={updateAddressValue}
                                        updateAddressValue={updateAddressValue}
                                        country={address.country}
                                        state={address.state}
                                    />
                                    <Gap />
                                    <Checkbox
                                        name="ssn"
                                        onChange={(checked) => {
                                            updateFormValues({
                                                socialSecurityNumber: null,
                                            });
                                            setSsn(checked);
                                        }}
                                    >
                                        I do not have a social security number
                                    </Checkbox>

                                    <Gap />
                                    {!ssn && (
                                        <FormLabel label="Social Security Number (If Applicable)">
                                            <Input
                                                name="socialSecurityNumber"

                                                onChange={(value) => {
                                                    updateFormValues({
                                                        socialSecurityNumber: value,
                                                    });

                                                    console.log('upd social', value);
                                                }}
                                            />
                                        </FormLabel>
                                    )}
                                    <Gap />

                                    {governmentId?.length && email ? (
                                        <>

                                            {governmentId?.map((govtIds, i: number) => (
                                                <>
                                                    <RadioBox
                                                        type="radio"
                                                        name="test"
                                                        onClick={() => {
                                                            console.log("clicked")
                                                            setChecks(true)
                                                            setValues(govtIds)
                                                            setDl(true);
                                                        }}
                                                    />
                                                    <WebImage key={i} >
                                                        <Flex>
                                                            <h4 style={{ marginBottom: '10px', fontSize: '14px' }}>
                                                                Front Card
                                                            </h4>
                                                            {/* <Img className="image" src={userGovtId[0]?.frontCard} /> */}
                                                            <Img className="image" src={govtIds?.frontCard ? govtIds?.frontCard : ''} />
                                                        </Flex>

                                                        <Flex>
                                                            <h4 style={{ marginBottom: '10px', fontSize: '14px' }}>
                                                                Back Card
                                                            </h4>
                                                            {/* <Img className="image" src={userGovtId[0]?.backCard} /> */}
                                                            <Img className="image" src={govtIds?.backCard ? govtIds?.backCard : ''} />
                                                        </Flex>
                                                    </WebImage>
                                                </>
                                            ))}
                                        </>)
                                        :
                                        (<>
                                            <Checkbox
                                                name="dl"
                                                defaultCheck={
                                                    (licenseFrontCard && licenseBackCard) ? false : true
                                                }
                                                onChange={(checked) => {
                                                    setfront('')
                                                    setback('')
                                                    updateFormValues({
                                                        govtId: {
                                                            frontCard: '',
                                                            backCard: '',
                                                        }
                                                    });
                                                    setDl(checked);
                                                }}
                                            >
                                                I do not have a government issued ID
                                            </Checkbox>
                                            <Gap />
                                            {!email && govtId.backCard && govtId.frontCard &&
                                                <div>
                                                    <WebImage  >
                                                        <Flex>
                                                            <h4 style={{ marginBottom: '10px', fontSize: '14px' }}>
                                                                Front Card
                                                            </h4>
                                                            {/* <Img className="image" src={userGovtId[0]?.frontCard} /> */}
                                                            <Img className="image" src={govtId?.frontCard ? govtId?.frontCard : ''} />
                                                        </Flex>

                                                        <Flex>
                                                            <h4 style={{ marginBottom: '10px', fontSize: '14px' }}>
                                                                Back Card
                                                            </h4>
                                                            {/* <Img className="image" src={userGovtId[0]?.backCard} /> */}
                                                            <Img className="image" src={govtId?.backCard ? govtId?.backCard : ''} />
                                                        </Flex>
                                                    </WebImage>
                                                </div>
                                            }
                                            <StyledButton
                                                type="button"
                                                onClick={() => {
                                                    setTime(true);
                                                    handleModalOpen();
                                                }}
                                            >
                                                <ButtonText>
                                                    <BiPlus size={25} /> <Space />
                                                    {!email && govtId.backCard && govtId.frontCard ? 'Re-upload Government ID' : 'Upload A Picture Of Your Government ID'}{' '}
                                                </ButtonText>
                                            </StyledButton>
                                            <DrivingLicenseModel
                                                handleModalClose={handleModalClose}
                                                open={openModal}
                                                // data={data}
                                                isEdit={!email && govtId.backCard && govtId.frontCard ? true : false}
                                            />
                                        </>)}

                                    {/* {!dl && (
										<>
										{front !== 'null' && front && back && back !== 'null' && (
											<WebImage>
											<Flex>
												<h4 style={{ marginBottom: '10px', fontSize: '14px' }}>
												Front Card
												</h4>
												<Img className="image" src={front} />
											</Flex>
											<Flex>
												<h4 style={{ marginBottom: '10px', fontSize: '14px' }}>
												Back Card
												</h4>
												<Img className="image" src={back} />
											</Flex>
											</WebImage>
										)} */}

                                    {/* </> */}
                                    {/* )} */}
                                    {/* <Checkbox
										name="dl"
										defaultCheck={
										(licenseFrontCard && licenseBackCard)? false : true
										}
										onChange={(checked) => {
										updateFormValues({
											licenseFrontCard: null,
											licenseBackCard: null,
										});
										setDl(checked);
										}}
									>
										I  have a government issued ID
									</Checkbox> */}
                                    {err && (
                                        <ErrorText hasError>
                                            {'Please choose one of the answers'}
                                        </ErrorText>
                                    )}
                                </>}
                            {userGovtId?.length ? (
                                <ButtonsContainer>
                                    <Button
                                        disabled={loading}
                                        onClick={() => {
                                            if (location?.qbenchCustomerId === config.qbenchCustomerId.BoysGirlsClub || form.cPartnerID === 'LLT001') {
                                                goToSkipPrevStep();
                                            }
                                            if (location?.qbenchCustomerId !== config.qbenchCustomerId.BoysGirlsClub && form.cPartnerID !== 'LLT001') {
                                                goToPrevStep();
                                                goToSkipPrevStep();
                                            }
                                        }}
                                        libraryType="default"
                                        type="button"
                                        style={{
                                            backgroundColor: '#FFFF',
                                            border: '1px solid #F29A4E',
                                            color: '#F29A4E',
                                        }}
                                    >
                                        Returns
                                    </Button>
                                    <Space />
                                    <Button
                                        disabled={!form.address.state}
                                        libraryType="primary"
                                        type="submit"
                                        style={{
                                            backgroundColor: '#F29A4E',
                                            border: '1px solid #F29A4E',
                                        }}
                                        onClick={() => handleGovtId()}
                                    >
                                        {'Save and Continue'}
                                    </Button>
                                </ButtonsContainer>
                            ) :
                                (
                                    <NavigationButtons
                                        onReturnButtonClick={() => {
                                            if (testSelection === 'school' || testSelection === 'community' || testSelection === 'sfoairport') goToSkipPrevStep();
                                            else if (((testSelection === 'flight' || testSelection === 'general') && form.address.country !== "United States") || (location?.hasInsurance !== undefined &&
                                                location.hasInsurance === true)) {
                                                goToSkipPrevStep();
                                                goToPrevStep();
                                            }
                                            else goToPrevStep();
                                            if (location.qbenchCustomerId === config.qbenchCustomerId.PCMADestination) {
                                                goToPrevStep();
                                                if (isWalkUp.active && isWalkUp.locationId === config.qbenchCustomerId.PCMADestination) {
                                                    goToPrevStep()
                                                }
                                            }
                                        }}
                                        isValid={
                                            !form.isNotHavePermanentAddress ?
                                                form.address.country == 'United States'
                                                    ? form.address.state !== undefined && form.address.state !== null && form.address.state !== ""
                                                        ? true
                                                        : false
                                                    : true
                                                : (form.address.state && form.address.country)}
                                    />)}
                        </Form>
                    )}
                </Formik>
            </Content>
        </Container>
    );
};

export default NoInsuranceDetails;