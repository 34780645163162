import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import {
  Container,
  Content,
  PageHeader,
  PageTitle,
  PageSubTitle,
  Button,
} from '../../shared/components/styled';
import { useRegisterStepsDispatch, useStepsState } from '../provider';
import styled from 'styled-components';
import pencil from '../../../assets/pencil.png';
import { Breakpoints } from '../../../dictionaries';
import Header from '../../shared/components/Header';
import { useSharedDispatch, useSharedState } from '../../shared/provider';
import { createRegister } from '../api';
import { Register } from '../../shared/models';
import { colors } from '../../../styles/colors';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import ChangeNameModal from './ChangePersonalInfo/ChangeNameModal';
import ChangeDobModal from './ChangePersonalInfo/ChangeDobModal';
import ChangePhoneModal from './ChangePersonalInfo/ChangePhoneModal';
import ChangeAddressModal from './ChangePersonalInfo/ChangeAddressModal';
import ChangeEthnicityModal from './ChangePersonalInfo/ChangeEthnicityModal';
import ChangeGenderModal from './ChangePersonalInfo/ChangeGenderModal';
import ChangeRaceModal from './ChangePersonalInfo/ChangeRaceModal';
import { MdDateRange } from 'react-icons/md';
import { IoPersonOutline } from 'react-icons/io5';
import ChangeMinorModal from './ChangePersonalInfo/ChangeMinorModal';
import ChangeEmplyeeIdModal from './ChangePersonalInfo/ChangeEmplyeeIdModal';
import { EditOutlined } from '@ant-design/icons';

export const tooltipText =
  'This information is required for result reporting to State and Local Department of Public Health to help track and trend the impact of COVID-19 on different communities. Your identity remains confidential as personally identifiable information is removed from data sets, so that the people whom the data describe remain anonymous.';

const TitleKey = styled.h3`
  margin: 0 0 14px 0;
  color: rgba(38, 38, 38, 0.8);
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  min-width: 175px;
  @media (max-width: ${Breakpoints.sm}px) {
    min-width: 160px;
    margin-left: 0px !important;
  }
`;

const TitleValue = styled.h4`
  margin: 0 0 14px 0;
  color: rgba(38, 38, 38, 0.8);
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  @media (max-width: ${Breakpoints.sm}px) {
    min-width: 150px;
    position: relative;
    left: -20px;
  }
`;

const Cardtitle = styled.div`
  display: flex;
  justify-content: flex-start;
  flex: 1 1 0%;

  margin-bottom: 15px;
  @media (max-width: ${Breakpoints.sm}px) {
    position: relative;
    left: -10px;
  }
`;

export const PencilLogo = styled.div`
  height: 18px;
  width: 118px;
  padding-left: 10%;

  background: url(${pencil}) no-repeat center center;

  @media (min-width: ${Breakpoints.sm}px) {
    height: 22px;
    width: 22px;
    border-radius: 50%;
    padding: 14px;
    background-color: rgb(240 248 255);
  }
`;

const BtnContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  flex-wrap: nowrap;
  padding-top: 20px;

  @media (min-width: 450px) {
    flex-direction: row;
  }
`;

const Space = styled.div`
  height: 10px;
  width: 20px;
`;

const Error = styled.div`
  color: ${colors.red};
  margin-top: 15px;
  text-align: center;
`;
const Icon = styled.div`
  width: 30px;
  @media (max-width: ${Breakpoints.sm}px) {
    width: 75px;
  }
`;
const WrapContainer = styled.div`
  height: 70px;
  border: 0.5px solid rgba(36, 61, 77, 0.14);
  border-radius: 12px;
  background: ${colors.rebrandBlack};
  margin-bottom: 30px;
  align-items: center;
  padding: 20px;
  width: 100%;
  @media (max-width: ${Breakpoints.sm}px) {
    position: relative;
    width: 100%;
    height:45%;
  }
`;

const WrapContent = styled.div`
  display: flex;
  position: relative;
  gap: 50px;
  @media (max-width: ${Breakpoints.sm}px) {
    display: grid;
    gap: 5px;
  }
`;
const Flexbox = styled.div`
  display: flex;
  gap: 10px;
  width: 33%;
  &:nth-child(1),
  &:nth-child(2) {
    width: 40%;
  }

  &:nth-child(3) {
    width: 20%;
  }
  @media (max-width: ${Breakpoints.sm}px) {
    margin-bottom: 20px;
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      width: 100%;
    }
  }
`;
const FlexboxIcon = styled.div`
  position: relative;
  color: ${colors.grey};
  display: inline-flex;
`;
const FlexboxText = styled.div`
  display: block;
  font-size: 14px;
  font-weight: 500;
  padding-left: 0px;
  word-break: break-all;
  color: #FFF;
`;
const Vl = styled.div`
  border-left: 1px solid #2a5f87;
  height: 100%;
  @media (max-width: ${Breakpoints.sm}px) {
    display: none;
  }
`;

const ConfirmAccountInfo: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const {
    form: {
      email,
      password,
      postCode,
      employeeId,
      firstName,
      birthDate,
      lastName,
      middleName,
      isINTNameFormat,
      phone,
      address,
      sex,
      race,
      ethnicity,
      passportCountry,
      passportNo,
      minors,
      hasMinors,
      destination
    },
  } = useStepsState();
  const { airline, travellType,tenant } = useSharedState();
  const { updateFormValues, goToNextStep, goToPrevStep } = useRegisterStepsDispatch();
  const { setloggedUserData } = useSharedDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [openNameDialog, setopenNameDialog] = React.useState<boolean>(false);
  const handleNameOpen = () => {
    setopenNameDialog(true);
  };
  const handleNameClose = () => {
    setopenNameDialog(false);
  };
  const [openDobDialog, setopenDobDialog] = React.useState<boolean>(false);
  const handleDobOpen = () => {
    setopenDobDialog(true);
  };
  const handleDobClose = () => {
    setopenDobDialog(false);
  };
  const [openPhoneDialog, setopenPhoneDialog] = React.useState<boolean>(false);
  const handlePhoneOpen = () => {
    setopenPhoneDialog(true);
  };
  const handlePhoneClose = () => {
    setopenPhoneDialog(false);
  };
  const [openAddressDialog, setopenAddressDialog] = React.useState<boolean>(
    false
  );
  const handleAddressOpen = () => {
    setopenAddressDialog(true);
  };
  const handleAddressClose = () => {
    setopenAddressDialog(false);
  };
  const [openGenderDialog, setopenGenderDialog] = React.useState<boolean>(
    false
  );
  const handleGenderOpen = () => {
    setopenGenderDialog(true);
  };
  const handleGenderClose = () => {
    setopenGenderDialog(false);
  };
  const [openRaceDialog, setopenRaceDialog] = React.useState<boolean>(false);
  const handleRaceOpen = () => {
    setopenRaceDialog(true);
  };
  const handleRaceClose = () => {
    setopenRaceDialog(false);
  };
  const [openEthnicityDialog, setopenEthnicityDialog] = React.useState<boolean>(
    false
  );
  const handleEthnicityOpen = () => {
    setopenEthnicityDialog(true);
  };
  const handleEthnicityClose = () => {
    setopenEthnicityDialog(false);
  };
  const [openEmployeeIDdialog, setopenEmployeeIdDialog] = React.useState<boolean>(false);
  const handleEmployeeIdOpen = () => {
    setopenEmployeeIdDialog(true);
  };
  const handleEmployeeIdClose = () => {
    setopenEmployeeIdDialog(false);
  };
  const [openMinorDialog, setopenMinorDialog] = React.useState<boolean>(false);
  const handleMinorOpen = () => {
    setopenMinorDialog(true);
  };
  const handleMinorClose = () => {
    setopenMinorDialog(false);
  };
  const handleCreateRegister = async () => {
    const data: Register = {
      email: email,
      password: password,
      postCode: postCode,
      employeeId:employeeId,
      firstName: firstName,
      lastName: lastName,
      middleName: middleName ? middleName : '',
      isINTNameFormat: isINTNameFormat,
      birthDate: birthDate,
      phone: phone,
      hasMinors: hasMinors,
      minors: minors,
      sex: sex,
      race: race,
      ethnicity: ethnicity,
      address: {
        zipCode: address.zipCode,
        state: address.state,
        address: address.address,
        county: address.county,
        city: address.city,
        country:address.country
      },
      passportCountry: passportCountry,
      passportNo: passportNo,
      destination: destination, // HI
      IsAirline: airline === '' ? true : airline === 'HAL' ? true : false,
      airlineCode: airline ? airline : '',
      // travelType: airline === '' ? 'DOM' : airline === 'HAL' ? 'DOM' : 'INT',
      travelType: travellType === '' || airline === 'HAL' ? 'DOM' : travellType,
      cPartnerID: '',
      isVerified: false,
      registeringFor: '',
      guardian: {
        firstName: '',
        lastName: '',
        relationship: '',
        phone: '',
        email: '',
      },
      isNotHavePermanentAddress: false
    };

    try {
      setLoading(true);
      const registerData = await createRegister(data);

      if (registerData.data.status === 200) {
        // localStorage.setItem('user', JSON.stringify(data));
        setloggedUserData(data);
        goToNextStep();
      } else {
        setError(registerData?.data?.message);
      }
      setLoading(false);
    } catch (e) {
      setError('Incorrect details');
      setLoading(false);
    }
  };

  return (
    <Container size="sm">
      <Content>
        <PageHeader>
          <PageTitle>Confirm your account info</PageTitle>
          <PageSubTitle>
            Please review and confirm the information below is correct by
            clicking the <strong>“Create Account”</strong> button.
          </PageSubTitle>
        </PageHeader>
        <Formik
          initialValues={{}}
          onSubmit={(values) => {
            updateFormValues(values);
            handleCreateRegister();
          }}
        >
          {() => (
            <Form>
              <Cardtitle onClick={handleNameOpen}>
                <Icon>
                  {/* <PencilLogo /> */}
                  <EditOutlined />
                </Icon>

                <TitleKey style={{ marginLeft: '45px' }}>Name</TitleKey>
                <TitleValue>
                  {firstName} {middleName} {lastName}
                </TitleValue>
              </Cardtitle>
              {/* Popup for Change Name */}
              {/* <Dialog
                    fullScreen={fullScreen}
                    open={openNameDialog}
                    onClose={handleNameClose}
                  > */}
              <ChangeNameModal
                handleNameClose={handleNameClose}
                open={openNameDialog}
                isProfile={false}
              />
              {/* </Dialog> */}

              <Cardtitle onClick={handleDobOpen}>
                <div style={{ width: '75px' }}>
                  {/* <PencilLogo /> */}
                 <EditOutlined />
                </div>

                <TitleKey>Date of Birth</TitleKey>
                <TitleValue>{birthDate}</TitleValue>
              </Cardtitle>
              {/* Popup for Change Dob */}
              {/* <Dialog
                    fullScreen={fullScreen}
                    open={openDobDialog}
                    onClose={handleDobClose}
                  > */}
              <ChangeDobModal
                handleDobClose={handleDobClose}
                open={openDobDialog}
                isProfile={false}
              />
              {/* </Dialog> */}

              <Cardtitle onClick={handlePhoneOpen}>
                <div style={{ width: '75px' }}>
                  {/* <PencilLogo /> */}
                  <EditOutlined />
                </div>
                <TitleKey>Phone Number</TitleKey>
                <TitleValue>{phone}</TitleValue>
              </Cardtitle>

              {/* Popup for Change Phone */}
              {/* <Dialog
                    fullScreen={fullScreen}
                    open={openPhoneDialog}
                    onClose={handlePhoneClose}
                  > */}
              <ChangePhoneModal
                handlePhoneClose={handlePhoneClose}
                open={openPhoneDialog}
                isProfile={false}
              />
              {/* </Dialog> */}

              <Cardtitle onClick={handleAddressOpen}>
                <Icon>
                  {/* <PencilLogo /> */}
                  <EditOutlined />
                </Icon>
                <TitleKey style={{ marginLeft: '45px' }}>Home Address</TitleKey>
                <TitleValue>
                  {address.address} {','} {address.city} , {address.state}{' '}
                  {address.zipCode}
                </TitleValue>
              </Cardtitle>

              {/* Popup for Change Address */}
              {/* <Dialog
                    fullScreen={fullScreen}
                    open={openAddressDialog}
                    onClose={handleAddressClose}
                  > */}
              <ChangeAddressModal
                handleAddressClose={handleAddressClose}
                open={openAddressDialog}
                isProfile={false}
              />
              {/* </Dialog> */}

              <Cardtitle onClick={handleGenderOpen}>
                <div style={{ width: '75px' }}>
                  {/* <PencilLogo /> */}
                  <EditOutlined />
                </div>
                <TitleKey>Sex (at birth)</TitleKey>
                <TitleValue>{sex}</TitleValue>
              </Cardtitle>

              {/* Popup for Change Gender */}
              {/* <Dialog
                    fullScreen={fullScreen}
                    open={openGenderDialog}
                    onClose={handleGenderClose}
                  > */}
              <ChangeGenderModal
                isProfile={false}
                open={openGenderDialog}
                handleGenderClose={handleGenderClose}
              />
              {/* </Dialog> */}

              <Cardtitle onClick={handleRaceOpen}>
                <div style={{ width: '75px' }}>
                  {/* <PencilLogo /> */}
                   <EditOutlined />
                </div>
                <TitleKey>Race</TitleKey>
                <TitleValue>{race}</TitleValue>
              </Cardtitle>

              {/* Popup for Change Race */}
              {/* <Dialog
                    fullScreen={fullScreen}
                    open={openRaceDialog}
                    onClose={handleRaceClose}
                  > */}
              <ChangeRaceModal
                isProfile={false}
                open={openRaceDialog}
                handleRaceClose={handleRaceClose}
              />
              {/* </Dialog> */}

              <Cardtitle onClick={handleEthnicityOpen}>
                <div style={{ width: '75px' }}>
                  {/* <PencilLogo /> */}
                  <EditOutlined />
                </div>
                <TitleKey>Ethnicity</TitleKey>
                <TitleValue>{ethnicity}</TitleValue>
              </Cardtitle>

              {/* Popup for Change Ethnicity */}
              {/* <Dialog
                    fullScreen={fullScreen}
                    open={openEthnicityDialog}
                    onClose={handleEthnicityClose}
                  > */}
              <ChangeEthnicityModal
                isProfile={false}
                handleEthnicityClose={handleEthnicityClose}
                open={openEthnicityDialog}
              />
              {tenant?.toLowerCase() === 'kern' && 
                <>
                    <Cardtitle onClick={handleEmployeeIdOpen}>
                        <div style={{ width: '75px' }}>
                        {/* <PencilLogo /> */}
                        <EditOutlined />
                        </div>
                        <TitleKey>Employee ID</TitleKey>
                        <TitleValue>{employeeId}</TitleValue>
                    </Cardtitle>

                    <ChangeEmplyeeIdModal
                        isProfile={false}
                        handleEmployeeIdClose={handleEmployeeIdClose}
                        open={openEmployeeIDdialog}
                    />
                </>
              }
              {/* </Dialog> */}
              {minors.length > 0 && (
                <PageSubTitle style={{ marginBottom: '10px' }}>
                  <b>Minors</b>
                </PageSubTitle>
              )}
              {minors.length > 0 && (
                <>
                  {minors.map(
                    ({ firstName, lastName, middleName, birthDate }: any, i: any) => (
                      <WrapContainer key={i}>
                        <WrapContent>
                          <Flexbox>
                            <FlexboxIcon>
                              <IoPersonOutline size={20} />
                            </FlexboxIcon>
                            <FlexboxText>
                              <b>{'Name : '}</b>
                              {firstName} {middleName} {lastName}
                            </FlexboxText>
                          </Flexbox>
                          <Flexbox>
                            <Vl></Vl>
                            <FlexboxIcon>
                              <MdDateRange size={20} />
                            </FlexboxIcon>
                            <FlexboxText>
                              <b>{'Date of Birth : '}</b>
                              {birthDate}
                            </FlexboxText>
                          </Flexbox>
                          <Flexbox>
                            <Vl></Vl>
                            <Button
                              type="button"
                              onClick={handleMinorOpen}
                              size="sm"
                              libraryType="default"
                              className="btn elevated-btn"
                            >
                              Edit
                            </Button>
                            <ChangeMinorModal
                              isRegister={true}
                              close={handleMinorClose}
                              open={openMinorDialog}
                              index={i}
                              isProfile={false}
                              schoolDropDown={[{ label: '', value: '' }]}
                            />
                          </Flexbox>
                        </WrapContent>
                      </WrapContainer>
                    )
                  )}
                </>
              )}

              {error && <Error>{error}</Error>}
              <BtnContainer>
                <Button
                  libraryType="default"
                  type="button"
                  onClick={() => goToPrevStep()}
                 className='btn elevated-btn'
                >
                  Return
                </Button>
                <Space />

                <Button
                  libraryType="primary"
                  type="submit"
                 className='btn fill-btn'
                >
                  {loading ? 'Processing...' : 'Create Account'}
                </Button>
              </BtnContainer>
            </Form>
          )}
        </Formik>
      </Content>
    </Container>
  );
};

export default ConfirmAccountInfo;

//ltst