import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors } from '../../../../styles/colors';
import { Breakpoints } from '../../../../dictionaries';
import { useSharedDispatch, useSharedState } from '../../../shared/provider';
import Loader from '../../../shared/components/Loader';

import {
    Container,
    PageHeader,
    Button,
    ErrorText,
} from '../../../shared/components/styled';
import { Formik, Form, FormikErrors, FormikValues } from 'formik';
import FormLabel from '../../../shared/components/form/FormLabel';
import { BiPlus } from 'react-icons/bi';
import Select from '../../../shared/components/form/Select';
import NewInsuranceModal from '../NewInsuranceScreen/NewInsuranceModal';
import { useStepsState, useStepsDispatch } from '../../../schedule/provider';
import { ReactComponent as PlusIcon } from '../../../../assets/plus.svg';
import { IoPersonOutline, IoPeopleOutline } from 'react-icons/io5';
import { BiIdCard } from 'react-icons/bi';
import { AiOutlinePicture } from 'react-icons/ai';
import ChangeInsuranceModal from '../ChangePersonalInfo/ChangeInsuranceModal';
import pencil from '../../../../assets/pencil.png';
import { getUserInsurance, updateRegister } from '../../api';
import AddInsuranceList from '../Appointment/Mainpage/AddInsuranceList';
import NavigationButtons from '../../../shared/components/NavigationButtons';
import Checkbox from '../../../shared/components/form/Checkbox';
import { createAppointment } from '../../../schedule/api';
import { isEmpty } from 'lodash';
import { RiDeleteBinLine } from 'react-icons/ri';
import CancelInsuranceModal from './CancelInsuranceModal';
import GoogleAnalytics from '../../../../GoogleAnalytics';
import firebase from 'firebase';
import { config } from '../../../../config';
import TestSelection from '../TestSelection';
import { useManageDispatch } from '../../../manage/provider';
import { makeStyles } from "@material-ui/core/styles"
const useStyles = makeStyles({
    hideImage: {
     display:"none"
    },
    showImage:{
        display:"block"
    }
  })

export const Content = styled.div`
  padding: 20px 20px;

  @media (min-width: ${Breakpoints.md}) {
    padding: 88px 0;
  }
  @media (max-width: ${Breakpoints.sm}px) {
    padding: 0px 0px 10px 0px !important;
  }
`;
const PageTitle = styled.div`
  color: ${colors.fullBlack};
  font-size: 28px;
  font-weight: 700;
  margin: 0;
  text-align: center;
  @media (max-width: ${Breakpoints.sm}px) {
    font-size: 24px;
    text-align: left;
  }
`;

const PageSubBody = styled.div`
  color: ${colors.fullBlack};
  font-size: 16px;
  font-weight: 500;
  margin: 10px 10px 10px 10px;
  text-align: center;
  @media (max-width: ${Breakpoints.sm}px) {
    font-size: 24px;
    text-align: left;
    margin-left: 5%;
  }
`;

const BtnContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  flex-wrap: nowrap;
  padding-top: 20px;

  @media (min-width: 450px) {
    flex-direction: row;
  }
`;
const Space = styled.div`
  height: 10px;
  width: 20px;
`;

export const StyledButton = styled.button`
  border: 1px dashed ${colors.grey};
  border-radius: 10px;
  font-size: 16px;
  font-weight: 500;
  color: rgb(26 150 219);
  line-height: 24px;
  padding: 15px 28px;
  transition: all 0.15s ease-in-out;
  width: 100%;
  cursor: pointer;

  &:focus,
  &:active {
    border: 1px dotted ${colors.blueLight};
    box-shadow: 0 0 4px ${(colors.blueLight, 0.8)};
  }
  @media (max-width: ${Breakpoints.sm}px) {
    margin-top: 40px;
  }
`;
const ButtonText = styled.div`
  display: inline-flex;
  align-items: center;
  position: relative;
  text-align: center;

  @media (max-width: ${Breakpoints.sm}px) {
    margin-left: 0px;
  }
`;
const MobileResponsiveGap = styled.div`
  margin-top: 0px;
  @media (max-width: ${Breakpoints.sm}px) {
    margin-top: 10px;
  }
`;

const InputRow = styled.div`
  display: flex;
  flex-direction: column;
  margin: -8px 0;

  @media (min-width: ${Breakpoints.sm}px) {
    flex-direction: row;
    margin: 0 -8px;
  }

  @media (min-width: ${Breakpoints.lg}px) {
    margin: 0 -15px;
  }

  & > * {
    flex-basis: 45%;
    margin: 8px 0;

    @media (min-width: ${Breakpoints.sm}px) {
      margin: 0 8px;
    }
    @media (min-width: ${Breakpoints.lg}px) {
      margin: 0 15px;
    }
  }
`;
const AddMinor = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: none;
  padding: 20px;
  margin-top: 30px;
  border: 2px dashed ${colors.grey};
  border-radius: 10px;
  color: ${colors.blueLight};
  margin-bottom: 20px;

  & > * {
    margin: 0 5px;
  }
`;
const InsuranceContainer = styled.div`
  background: ${colors.white};
  margin: 0px 5px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 0.5px solid rgba(36, 61, 77, 0.75);
  padding: 20px 20px;
  border-radius: 12px;
  &:hover {
    background: #FCCA46;
  }
  @media (max-width: ${Breakpoints.sm}px) {
    padding: 40px 20px 40px 20px !important;
    margin: 0;
    position: relative;
    &:hover {
      background: none;
    }
  }
`;
const ExpandRight = styled.div`
  display: grid;
  width: 100%;
  row-gap: 30px;
  position: relative;
  top: -50px;
  @media (max-width: ${Breakpoints.sm}px) {
    top: 30px;
  }
`;
const InsurancelistTag = styled.div`
  display: flex;
  gap: 50px;
  @media (max-width: ${Breakpoints.sm}px) {
    display: block;
  }
`;
const Datetag = styled.div`
  display: flex;
  gap: 20px;
`;
const ExpandIcon = styled.div`
  position: relative;
  color: ${colors.grey};
`;
const DateAndPatient = styled.div`
  display: grid;
  row-gap: 30px;
`;
const WrapExpand = styled.div``;
const Expandtext = styled.div`
  font-size: 16px;
  font-weight: 500;
`;
const Patientstag = styled.div`
  display: flex;
  gap: 20px;
  @media (max-width: ${Breakpoints.sm}px) {
  }
`;
const Timetag = styled.div`
  display: flex;
  gap: 20px;
  @media (max-width: ${Breakpoints.sm}px) {
    margin-bottom: 30px;
  }
`;
const Testtag = styled.div`
  display: flex;
  gap: 20px;
`;
const WrapText = styled.div`
  color: black;
  font-size: 24px;
  font-weight: bold;
  text-align: left;
  @media (max-width: ${Breakpoints.sm}px) {
    font-size: 18px;
    text-align: left;
    line-height: 25px;
  }
`;
const SubNameandDob = styled.div`
  display: grid;
  @media (max-width: ${Breakpoints.sm}px) {
    row-gap: 30px;
  }
`;
const Card = styled.div`
  margin-top: 20px;
  @media (max-width: ${Breakpoints.sm}px) {
  }
`;
const ExpandCenter = styled.div``;
const ExpandVl = styled.div`
  border-left: 1px solid #2a5f87;
  height: 100%;
`;
const PageSubTitle = styled.h3`
  margin: 10px 0 10px 0;
`;
const InsurancePencilLogo = styled.div`
  height: 100%;
  width: auto;

  background: url(${pencil}) no-repeat center center;
  @media (max-width: ${Breakpoints.sm}px) {
    margin-top: 10px;
  }
`;
const Buttons = styled(Button)`
  width: auto;
`;
const Logotag = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
  @media (max-width: ${Breakpoints.sm}px) {
    gap: 10px;
  }
`;
const EditTag = styled.div`
  display: flex;
  gap: 30px;
  justify-content: space-between;
  margin: 20px 0px;
  align-items: center;
  @media (max-width: ${Breakpoints.sm}px) {
    gap: 10px;
  }
`;
export const Input = styled.input`
  height: 19px;
  width: 19px;
  border: 1px solid ${colors.blueLight};
  border-radius: 0px;
  outline: none;
  transition-duration: 0.3s;
  cursor: pointer;
  margin-left: 5px;
  margin-right: 10px;
  background-color: ${colors.white};
`;
const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  flex-wrap: nowrap;
  padding-top: 20px;

  @media (min-width: 450px) {
    flex-direction: row;
  }
`;
const NewInsurance3: React.FC = () => {
    const db = firebase.firestore();
  const {
    userData,
    userInsuranceData,
    travellType,
    destination,
    apptFromSchdule,
    vaccineType,
    testSelectFlow,
    isWalkUp
  } = useSharedState();
  let sortedInsurance =userInsuranceData?.sort((p1:any, p2:any) => (p1?.createdDate > p2?.createdDate) ? 1 : (p1?.createdDate < p2?.createdDate) ? -1 : 0)
  const [loading, setLoading] = useState(false);
  const {
    updateFormValues,
    goToNextStep,
    goToSkip3Step,
    goToPrevStep,
    goToSkipPrevStep,
  } = useStepsDispatch();
  const [state, setState] = useState(false);

  const { form, prices } = useStepsState();
  const {
    confirmationId,
    agreeToCancel,
    commitToAttend,
    consentForTesting,
    hipaaConfirmed,
    confirmEmail,
    insurance,
    location,
    ...appointment
  } = form;
  // const {
  //   apptFromSchdule,
  //   airline,
  //   destination,
  //   flow,
  //   userData,
  //   toNextStep,
  //   isVaccinate,
  //   reasonType,
  //   vaccineType,
  //   testSelectFlow,
  //   district,
  // } = useSharedState();
  const [addInsurance, setAddInsurance] = useState(false);
  const { updateStandradTest } = useManageDispatch();
  const [sample, setSample] = useState(false);
  const [isInsurance, setInsurance] = useState(false);
  const [noInsurance, setNoInsurance] = useState(false);
  const [creditCard, setCreditCard] = useState(false);
  const handleAddInsuranceOpen = () => {
    setAddInsurance(true);
  };
  const handleAddInsuranceClose = () => {
    setAddInsurance(false);
  };
  const handleShowList = () => {
    setAddInsurance(false);
  };
  const [openInsuranceDialog, setopenInsuranceDialog] = React.useState<boolean>(
    false
  );
  const handleInsuranceOpen = () => {
    setopenInsuranceDialog(true);
  };
  const handleInsuranceClose = () => {
    setopenInsuranceDialog(false);
  };
  const [values, setValues] = useState<any>();

    const [isSlotReleased, slotReleased] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [editKey, setEditKey] = useState({ key: -1 });
    const [cancelKey, setCancelKey] = useState({ key: -1 });
    const [openCancelModal, setopenCancelModal] = React.useState<boolean>(false);
    const [openConfirmModal, setopenConfirmModal] = React.useState<boolean>(false);
    const handleCancelOpen = () => {
        setopenCancelModal(true);
    };
    const handleCancelClose = () => {
        setopenCancelModal(false);
    };
    
    const handleConfirmDialogClose = () => {
        setopenConfirmModal(false)
    }

    const handleConfirmDialogOpen = () => {
        setopenConfirmModal(true)
    }

    const handlePaymentMethod = () => {
        if (
            location?.isCreditCard.standard ||
            location?.isCreditCard.antigen ||
            location?.isCreditCard.rapid ||
            location?.isCreditCard.express
        ) {
            setCreditCard(true);
        } else {
            setCreditCard(false);
        }
        if (
            location?.isInsurance.standard ||
            location?.isInsurance.antigen ||
            location?.isInsurance.rapid ||
            location?.isInsurance.express
        ) {
            setInsurance(true);
        } else {
            setInsurance(false);
        }
        if (
            location?.isNoInsurance.standard ||
            location?.isNoInsurance.antigen ||
            location?.isNoInsurance.rapid ||
            location?.isNoInsurance.express
        ) {
            setNoInsurance(true);
        } else {
            setNoInsurance(false);
        }
    };
    useEffect(() => {
        if(form?.location?.cPartnerID === 'KEN001'){
            goToNextStep()
        }
        if (
            apptFromSchdule &&
            testSelectFlow &&
            (!userData?.isVerified)
        )
            setAddInsurance(true);
            handlePaymentMethod();
        getInsurance();
        if (form && form?.insurance && form?.insurance?.id) {
            console.log("insuranceValues", form?.insurance)
            setValues(form?.insurance);
        }
        GoogleAnalytics(form, '/New Insurance');
    }, []);
    const { updateInsuranceList } = useSharedDispatch();

    const [description, setDescription] = useState('Please use only United States based Insurance Providers')

    const fetchDistrict = async () => {
        const response = db.collection('district');
        const snapShotData = await response.get();
        const data = snapShotData.docs.map((doc: any) => doc.data());

        console.log('school data', form.district?.value);
        let districtData: any = data.filter(
            (item: any) => item.value === form?.district?.value
        )[0];
        console.log('school data', districtData);
        if (form.testSelection === 'school') {
            if (districtData?.description !== '') {
                setDescription(districtData.description);
            } else {
                let desc = `We accept insurance providers based in the United States.
    If you don't have insurance and need a test, please contact your
    ${districtData.value || ''} administration ${districtData.email ? ` - ` + districtData.email : ''
                    }`;
                setDescription(desc);
            }
        } else {
            setDescription('Please use only United States based Insurance Providers');
        }
    };
    useEffect(() => {
        fetchDistrict();
    }, []);

    const getInsurance = async () => {
        setLoading(true);
        try {
            let updatedData = await getUserInsurance(userData?.email);

            if (updatedData?.data?.status === 200) {
                updateInsuranceList(updatedData?.data?.data);
            }
            setLoading(false);
        } catch (e) {
            setLoading(false);
        }
    };
    const handleCreateAppointment = async () => {
        // console.log('createAPtt', values.length);
        setLoading(true);
        if (!isEmpty(values)) {
            try {
                console.log('createAPtt', values);
                console.log('createAPtt length', values.frontCard);
                updateFormValues({
                  insurance: {
                    id: values.id,
                    group: values.group,
                    subscriberFirstName: values.subscriberFirstName,
                    subscriberLastName: values.subscriberLastName,
                    insuranceId: values.insuranceId,
                    // payerList: "Anthem",
                    payerList: values.payerList,
                    newCarrierName: values.newCarrierName,
                    frontCard: values.frontCard,
                    backCard: values.backCard,
                    insuranceFor: values?.insuranceFor
                      ? values.insuranceFor
                      : '',
                    policyHolderFirstName: values.policyHolderFirstName
                      ? values.policyHolderFirstName
                      : userData?.firstName,
                    policyHolderLastName: values.policyHolderLastName
                      ? values.policyHolderLastName
                      : userData?.lastName,
                    policyHolderbirthDate: values.policyHolderbirthDate
                      ? values.policyHolderbirthDate
                      : userData?.birthDate
                      ? userData?.birthDate
                      : '',
                    pVerify: values.pVerify ?? false,
                    subscriberbirthDate: values.subscriberbirthDate,
                  },
                });

                // const result = await createAppointment({
                //   ...appointment,
                //   paymentIntentId: 'ins',
                //   insurance: {
                //     email: values.email,
                //     id: values.id,
                //     group: values.group,
                //     subscriberFirstName: values.subscriberFirstName,
                //     subscriberLastName: values.subscriberLastName,
                //     insuranceId: values.insuranceId,
                //     // payerList: "Anthem",
                //     payerList: values.payerList,
                //     frontCard: values.frontCard,
                //     backCard: values.backCard,
                //     insuranceFor: values?.insuranceFor ? values.insuranceFor : '',
                //     policyHolderFirstName: values.policyHolderFirstName
                //       ? values.policyHolderFirstName
                //       : '',
                //     policyHolderLastName: values.policyHolderLastName
                //       ? values.policyHolderLastName
                //       : '',
                //     policyHolderbirthDate: values.policyHolderbirthDate
                //       ? values.policyHolderbirthDate
                //       : '',
                //   },
                //   firstName:
                //     form?.registeringFor === 'minorOnly' && !!form?.guardian?.firstName
                //       ? form?.guardian?.firstName
                //       : form?.firstName,
                //   lastName:
                //     form?.registeringFor === 'minorOnly' && !!form?.guardian?.lastName
                //       ? form?.guardian?.lastName
                //       : form?.lastName,
                //   guardian:
                //     form?.registeringFor === 'minorOnly' && !!form?.guardian
                //       ? form?.guardian
                //       : null,
                //   travelType: travellType,
                //   destination: destination,
                //   hasInsurance: true,
                //   socialSecurityNumber: '',
                //   licenseBackCard: null,
                //   licenseFrontCard: null,
                //   isNotHavePermanentAddress: !!userData?.isNotHavePermanentAddress
                //     ? userData?.isNotHavePermanentAddress
                //     : form.isNotHavePermanentAddress,
                // });

                // await new Promise<void>((resolve) => {
                //   setTimeout(() => {
                //     resolve();
                //   }, 500);
                // });

                // updateFormValues({ confirmationId: result.data.id });
                if (form?.cPartnerID === 'LLT001') {
                    goToSkip3Step()
                }
                goToNextStep();
                setError(null);
                setLoading(false);
            } catch (e: any) {
                setError(e.message);
                setLoading(false);
            }
        } else {
            setError('Please select any of the insurance.');
            setLoading(false);
        }
    };
    let deleteFormFunction = () => {
        updateFormValues({
          insurance: {
            id: '',
            group: '',
            subscriberFirstName: '',
            subscriberLastName: '',
            insuranceId: '',
            // payerList: "Anthem",
            payerList: '',
            frontCard: '',
            backCard: '',
            insuranceFor: '',
            policyHolderFirstName: '',
            policyHolderLastName: "",
            policyHolderbirthDate:  '',
            pVerify: false,
            subscriberbirthDate:""
          },
        });
    }
    const classes = useStyles()
    if (loading) {
        return <Loader />;
    }

    return (
      <Container>
        <Content>
          <PageHeader>
            {addInsurance ? (
              <>
                <PageTitle>
                  Please enter your insurance billing information
                </PageTitle>
                {/* <PageSubBody>
              Please note that we currently do not accept insurance for school districts. <br/> Check back soon for using insurance for a general test. <br/> Insurance will not be valid for travel purposes.
            </PageSubBody> */}
              </>
            ) : (
              <PageTitle>Your insurance details</PageTitle>
            )}
          </PageHeader>
          <Container marginTop style={{ padding: '0px' }}>
            <h6 style={{ color: 'red', fontStyle: 'italic' }}>{description}</h6>
            {addInsurance ? (
              <AddInsuranceList
                close={handleAddInsuranceClose}
                showlist={handleShowList}
                cPartnerID={form?.cPartnerID}
              />
            ) : (
              <>
                {sortedInsurance?.length ? (
                  <>
                    {sortedInsurance.map((insurance, i: number) => (
                      <>
                        <EditTag>
                          <Logotag>
                            <Input
                              type="radio"
                              name="test"
                              style={{ accentColor: '#243D4D' }}
                              onClick={() => {
                                setValues(insurance);
                                setState(true)
                              }}
                              defaultChecked={
                                insurance.id === form?.insurance?.id
                                    ? true
                                    : false
                            }
                            />
                            <PageSubTitle>
                              <b>Insurance - {i + 1}</b>
                            </PageSubTitle>
                          </Logotag>
                          <Logotag>
                            <Buttons
                              type="button"
                              onClick={() => {
                                handleInsuranceOpen();
                                setEditKey({ key: editKey.key === i ? -1 : i });
                              }}
                              size="sm"
                              libraryType="default"
                              className='btn elevated-btn'
                            >
                              Edit
                            </Buttons>
                            {/* <div
                            style={{ width: '20px', cursor: 'pointer' }}
                            onClick={() => {
                              setCancelKey({
                                key: cancelKey.key === i ? -1 : i,
                              });
                              handleCancelOpen();
                            }}
                          >
                             <RiDeleteBinLine size={30} /> 
                          </div> */}
                          </Logotag>
                        </EditTag>
                        <InsuranceContainer
                        >
                          <WrapExpand>
                            <ExpandRight style={{ top: '0px' }}>
                              <InsurancelistTag>
                                <DateAndPatient>
                                  <Datetag>
                                    <ExpandIcon>
                                      <IoPersonOutline size={25} />
                                    </ExpandIcon>
                                    <Expandtext>
                                      {' '}
                                      <strong>Insurance Carrier Name</strong>
                                      <br />
                                      {insurance.payerList}
                                      <br />
                                    </Expandtext>
                                  </Datetag>
                                  <Datetag>
                                    <ExpandIcon>
                                      <BiIdCard size={25} />
                                    </ExpandIcon>
                                    <Expandtext>
                                      {' '}
                                      <strong>Insurance Member ID</strong>
                                      <br />
                                      {insurance.insuranceId}
                                      <br />
                                    </Expandtext>
                                  </Datetag>
                                  <Patientstag>
                                    <ExpandIcon>
                                      <IoPeopleOutline size={25} />
                                    </ExpandIcon>
                                    <Expandtext>
                                      {' '}
                                      <strong>Insurance Group</strong>
                                      <br />
                                      {insurance.group}
                                      <br />
                                    </Expandtext>
                                  </Patientstag>
                                  <Timetag>
                                    <ExpandIcon>
                                      {' '}
                                      <IoPersonOutline size={25} />
                                    </ExpandIcon>
                                    <Expandtext>
                                      {' '}
                                      <strong> Subscriber Name </strong> <br />
                                      {insurance.subscriberFirstName}{' '}
                                      {insurance.subscriberLastName}
                                      <br />
                                    </Expandtext>
                                  </Timetag>
                                </DateAndPatient>
                                <ExpandCenter>
                                  <ExpandVl></ExpandVl>
                                </ExpandCenter>
                                <SubNameandDob>
                                  <Testtag>
                                    <ExpandIcon>
                                      {' '}
                                      <AiOutlinePicture size={25} />
                                    </ExpandIcon>
                                    <Expandtext>
                                      {' '}
                                      <strong>
                                        Front copy of the card
                                      </strong>{' '}
                                      <br />
                                      <Card>
                                        <img
                                          width="100"
                                          height="100"
                                          src={insurance.frontCard}
                                          className={insurance?.frontCard == null || insurance?.frontCard == "" ? classes.hideImage: classes.showImage}
                                        />
                                      </Card>
                                    </Expandtext>
                                  </Testtag>
                                  <Testtag>
                                    <ExpandIcon>
                                      {' '}
                                      <AiOutlinePicture size={25} />
                                    </ExpandIcon>
                                    <Expandtext>
                                      {' '}
                                      <strong>
                                        Back copy of the card
                                      </strong>{' '}
                                      <br />
                                      <Card>
                                        <img
                                          width="100"
                                          height="100"
                                          src={insurance.backCard}
                                          className={insurance?.frontCard == null || insurance?.frontCard == "" ? classes.hideImage: classes.showImage}
                                        />
                                      </Card>
                                    </Expandtext>
                                  </Testtag>
                                </SubNameandDob>
                              </InsurancelistTag>
                            </ExpandRight>
                          </WrapExpand>
                          {editKey.key === i && (
                            <ChangeInsuranceModal
                              close={handleInsuranceClose}
                              open={openInsuranceDialog}
                              insuranceList={{...insurance,subscriberbirthDate:insurance?.subscriberbirthDate ?? userData?.birthDate}}
                              isProfile={false}
                            />
                          )}
                          {cancelKey.key === i && (
                            <CancelInsuranceModal
                              close={handleCancelClose}
                              open={openCancelModal}
                              insuranceList={insurance}
                            />
                          )}
                        </InsuranceContainer>
                      </>
                    ))}
                  </>
                ) : (
                  <>
                    {' '}
                    <InsuranceContainer>
                      <WrapText>{'You have no insurance details'}</WrapText>
                    </InsuranceContainer>
                    <Space />
                  </>
                )}
                {!addInsurance && (
                  <AddMinor
                    type="button"
                    style={{
                      background: '#FFFF',
                      border: '2px dashed #619C8A',
                      color: '#243d4d',
                      fontStyle: 'normal',
                      fontWeight: '700',
                      fontSize: '16px',
                      lineHeight: '24px',
                    }}
                    onClick={() => {
                      setError('');
                      setAddInsurance(true);
                      deleteFormFunction()
                    }}
                  >
                    <BiPlus size={25} />
                    Add Insurance
                  </AddMinor>
                )}
              </>
            )}
            <ErrorText hasError>{error}</ErrorText>

            {!addInsurance && (
              <ButtonsContainer>
                <Button
                  disabled={loading}
                  onClick={() => {
                    if (
                      form.location?.qbenchCustomerId ===
                      config.qbenchCustomerId.BoysGirlsClub
                    ) {
                      goToSkipPrevStep();
                      goToPrevStep();
                    } else if (form?.email === '' || form.email === undefined) {
                        if (isInsurance && !creditCard && !noInsurance) {
                            goToSkipPrevStep();
                            goToPrevStep();
                        } else if (isInsurance && creditCard && !noInsurance) {
                            goToSkipPrevStep();
                        } else if (isInsurance && !creditCard && noInsurance) {
                            goToSkipPrevStep();
                        } else if (isInsurance && creditCard && noInsurance) {
                            goToSkipPrevStep();
                        }
                    } else if (isInsurance && !creditCard && !noInsurance) {
                        goToSkipPrevStep();
                        goToPrevStep();
                    } else if (isInsurance && creditCard && !noInsurance) {
                        goToSkipPrevStep();
                    } else if (isInsurance && !creditCard && noInsurance) {
                        goToSkipPrevStep();
                    } else if (isInsurance && creditCard && noInsurance) {
                        goToSkipPrevStep();
                    }
                      if (
                        form.location?.qbenchCustomerId ===
                          config.qbenchCustomerId.BoysGirlsClub ||
                        form?.cPartnerID === 'LLT001'
                      ) {
                        goToPrevStep();
                      }
                      // goToPrevStep();
                    //   if (
                    //     isWalkUp.active &&
                    //     isWalkUp.locationId ===
                    //       config.qbenchCustomerId.PCMADestination
                    //   ) {
                    //     goToPrevStep();
                    //   }
                    // // } else if (form.testSelection === 'school') {
                    // //   goToPrevStep();
                    // } else if (
                    //   form?.cPartnerID === 'POR001'
                    // ) {
                    //   console.log('123');
                    //   goToPrevStep();
                    //   goToSkipPrevStep();
                    // } else if (
                    //   form?.cPartnerID === 'LLT001' &&
                    //   userData !== null
                    // ) {
                    //   goToPrevStep();
                    // }
                    // if (form.testSelection === 'school') {
                    //   goToPrevStep();
                    // }
                    // if (
                    //   form?.location?.qbenchCustomerId ===
                    //   config.qbenchCustomerId.ArroyoUpperSchool
                    // ) {
                    //   updateFormValues({
                    //     isAntigen: true,
                    //     isExpressSameDayTest: false,
                    //     isRapid: false,
                    //   });
                    // }

                    // if (form.location?.qbenchCustomerId===66) goToPrevStep();
                    // if (
                    //   form.location?.qbenchCustomerId !==
                    //     config.qbenchCustomerId.BoysGirlsClub &&
                    //   form?.cPartnerID !== 'LLT001'
                    // ) {
                    //   goToSkipPrevStep();
                    //   goToPrevStep();
                    // }
                    if (
                      form.location?.qbenchCustomerId ===
                        config.qbenchCustomerId.SFOAirport_BARTStation &&
                      form.testSelection === 'sfoairport'
                    )
                      goToPrevStep();
                    // goToSkipPrevStep();
                  }}
                  libraryType="default"
                  type="button"
                  className='btn elevated-btn'
                >
                  Return
                </Button>
                <Space />
                <Button
                  disabled={loading}
                  libraryType="primary"
                  type="submit"
                  className='btn fill-btn'
                  onClick={() => handleCreateAppointment()}
                >
                  {'Confirm'}
                </Button>
              </ButtonsContainer>
            )}
          </Container>
        </Content>
      </Container>
    );
};

export default NewInsurance3;
