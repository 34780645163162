import React, { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';

import { RegisterProvider } from '../provider';
import Steps from './Steps';
import { useSharedState } from '../../shared/provider';
import { useHistory } from 'react-router-dom';

function Register() {
  useEffect(() => {
    mixpanel.track('Page View');
  }, []);

  const { userData } = useSharedState();
  let history = useHistory();
  if (userData?.isVerified) {
    history.push('/user/profile')
  }
  
  return (
    <RegisterProvider>
      <Steps />
    </RegisterProvider>
  );
}

export default Register;