import React, { useState, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import routes from './routes';
import Steps from './modules/schedule/components/Schedule';
import ReqisterSetps from './modules/register/components/Register';
import VerifyAccount from './modules/register/components/VerifyAccount';
import Manage from './modules/manage/components/Manage';
import Admin from './modules/admin/components/layouts/Admin';
import ForgotPassword from './modules/schedule/components/ForgotPassword';
import { AdminProvider } from './modules/admin/provider';
import Layout from './modules/register/components/Appointment/Layout';
import Header from './modules/shared/components/Header';
import BookAppointment from './modules/register/components/BookAppointment';
import ResetPassword from './modules/register/components/ResetPassword';
import FindEmail from './modules/register/components/FindEmail';
import { RegisterProvider } from './modules/register/provider';
import SigninProvider from './modules/register/components/SigninProvider';
import { useSharedDispatch, useSharedState } from './modules/shared/provider';
import { useIdleTimer } from 'react-idle-timer'
import { walkUpUrlValidation } from './validations';
import GoogleAnalytics from './GoogleAnalytics';
import { ScheduleProvider } from './modules/schedule/provider';
import UpgradeSteps from './modules/register/components/UpgradeSteps';
import { autoTriggerWhenDataChanges, getUserDataWhenChangesHappens } from './modules/admin/api';
function App() {
    const { userData, tenant } = useSharedState();
    const [called, setCalled] = useState<boolean>(true);
    const { updateReasonType, updateVaccineType, updateVaccinated, updateDestination, updateTravelType, updateAirlineType, updateapptFromSchdule, setWalkUp} = useSharedDispatch();
    function redirect(){
        localStorage.clear();
        updateDestination('');
        updateTravelType('');
        updateAirlineType('');
        updateReasonType('');
        updateVaccinated('');
        updateVaccineType('');
        updateapptFromSchdule(false);
        if(tenant?.toLocaleLowerCase() === 'kern')
            window.location.href=window.location.origin+'/?tenant=kern';
        else
            window.location.href=window.location.origin+'/signin';
    };
    
    const handleOnIdle = (event:any) => {
        if(called)
            redirect();
      }    
      useIdleTimer({
        timeout: 1000*60*5,
        onIdle: handleOnIdle,
        debounce: 100
      })
        const { setloggedUserData } = useSharedDispatch();
        const changeEmailTrigger = React.useCallback(() => {
          autoTriggerWhenDataChanges(userData?.id, () => {
            if (userData?.id)
              getUserDataWhenChangesHappens(userData?.id).then((response) => {
                if (
                  response?.data?.email &&
                  response.data.email !== userData.email
                ) {
                    localStorage.clear();
                    setloggedUserData(null);
                    if(tenant?.toLocaleLowerCase() === 'kern')
                        window.location.href=window.location.origin+'/?tenant=kern';
                    else
                        window.location.href=window.location.origin+'/signin';
                }
              });
          });
        }, [userData]);
    useEffect(()=>{
        GoogleAnalytics({},'/', 'Home Screen');
        userData === null ? setCalled(false) : setCalled(true);
        if (!walkUpUrlValidation.test(window.location.pathname))
        setWalkUp({ locationId: undefined, cPartnerIdArray: [], active: false })
      changeEmailTrigger();
    },[userData])
  return (
    <Switch>
      <Route path={routes.schedule} exact>
        <Steps />
      </Route>
      <Route
        path={routes.admin}
        component={() => {
          window.location.href = 'https://admin.schedulecovidtesting.com';
          return null;
        }}
      />
      <Route path={routes.layout}>
        <Header isUser isHelp />
        <RegisterProvider>
          <Layout />
        </RegisterProvider>
      </Route>
      <Route path={routes.walkUpTest}>
        <BookAppointment />
      </Route>
      <Route path={routes.register}>
        <ReqisterSetps />
      </Route>
      <Route path={routes.verify}>
        <VerifyAccount />
      </Route>
      <Route path={routes.bookappointment}>
        <BookAppointment />
      </Route>

      {/* <Route path={routes.confirmaccount}>
        <ConfirmAccount />
      </Route>
     */}
      <Route path={routes.dashboard}>
        <AdminProvider>
          <Admin />
        </AdminProvider>
      </Route>

      <Route
        path={routes.manage}
        component={() => {
          window.location.href = 'https://manage.schedulecovidtesting.com';
          return null;
        }}
      />
      <Route path={routes.manage}>
        <Manage />
      </Route>
      {/* <Route path={routes.verifyemail}>
        <VerifyEmail />
      </Route>
      <Route path={routes.verifyphone}>
        <VerifyPhone />
      </Route>
      
      
      <Route path={routes.confirmaccountinfo}>
      <ConfirmAccountInfo />
      </Route>
      <Route path={routes.successfulaccountcreation}>
        <SuccessfulAccountCreation/>
      </Route> */}
      <Route path={routes.findemail}>
        <FindEmail />
      </Route>
      <Route path={routes.signin} exact>
        <SigninProvider />
      </Route>
      <Route path={routes.forgotpassword}>
        <ResetPassword />
      </Route>
      <Route path="/resetpassword/:email">
        <ForgotPassword />
      </Route>
      <Route path="/signin/:mail">
        <SigninProvider />
      </Route>
      <Route path="/upgradeTest/:data">
        <SigninProvider />
      </Route>
      <Route path="/confirmation/:confirmationId">
        <BookAppointment />
      </Route>
      <Route path="/reconfirmation">
        <ScheduleProvider>
          <UpgradeSteps />
        </ScheduleProvider>
      </Route>
    </Switch>
  );
}

export default App;
