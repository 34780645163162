import AppointmentList from "../AppointmentList/AppointmentList";
import AppointmentsCheckin from "../../Pages/AppointmentsCheckin";
import QbenchResultsList from "../AppointmentList/QbenchResultsList";

import SlotOccupany from "../AppointmentList/slotoccupany";
import CountyReport from "../AppointmentList/CountyReport";
import WizardReport from "../AppointmentList/WizardReport";

import Dashboard from "../AppointmentList/Dashboard";
import KeyMetrics from "../AppointmentList/KeyMetrics";

import Report from "../../../shared/components/Report";
import ConsolidationReport from "../../../shared/components/ConsolidationReport";
import InsertInvitation from "@material-ui/icons/InsertInvitation";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ReportIcon from "@material-ui/icons/InsertChart";

//Manage screen
import AdminManage from "../../Pages/Manage/index";

const dashboardRoutes = [
  {
    path: "/Home",
    name: "Dashboard",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/dashboard",
    display:true
  },
  {
    path: "/KeyMetrics",
    name: "Key Metrics",
    icon: ReportIcon,
    component: KeyMetrics,
    layout: "/dashboard",
    display:true
  },
  {
    path: "/DayWiseAppointments",
    name: "Day Wise Appointments",
    icon: InsertInvitation,
    component: AppointmentList,
    layout: "/dashboard",
    display:true
  },
  {
    path: "/checkin",
    name: "Appointments Checkin",
    icon: InsertInvitation,
    component: AppointmentsCheckin,
    layout: "/dashboard",
    display:false
  },
  {
    path: "/manage",
    name: "Manage",
    icon: InsertInvitation,
    component: AdminManage,
    layout: "/dashboard",
    display:true
  },
  {
    path: "/weeklyreport",
    name: "Weekly Report",
    icon: InsertInvitation,
    component: Report,
    layout: "/dashboard",
    display:false
  },
  {
    path: "/result",
    name: "Results",
    icon: InsertInvitation,
    component: QbenchResultsList,
    layout: "/dashboard",
    display:true
  },
  {
    path: "/slotoccupany",
    name: "Slot Occupancy",
    icon: InsertInvitation,
    component: SlotOccupany,
    layout: "/dashboard",
    display:true
  },
  {
    path: "/consolidationreport",
    name: "Consolidation Report",
    icon: InsertInvitation,
    component: ConsolidationReport,
    layout: "/dashboard",
    display:false
  },
  {
    path: "/countyreport",
    name: "County Report",
    icon: InsertInvitation,
    component: CountyReport,
    layout: "/dashboard",
    display:false
  },
  {
    path: "/report",
    name: "Report",
    icon: InsertInvitation,
    component: WizardReport,
    layout: "/dashboard",
    display:true
  }

  
];

export default dashboardRoutes;
