import React, { useEffect, useMemo, useState } from 'react';
import { useSharedState } from '../../../../shared/provider';
import Loader from '../../../../shared/components/Loader';
import { getAppointmentdata, updateAppointmentdata } from '../../../../admin/api';
import { useRegisterStepsDispatch, useStepsState } from "../../../provider";
import Schedule from '../../../../shared/components/Schedule';
import { useHistory } from 'react-router';
import { getEmployeeCoupon } from '../../../../schedule/api';
import PaymentModel from './Payment';
import { config } from '../../../../../config';
const EditAppointmentFromList: React.FC = () => {
    const history = useHistory()
    const [loading, setLoading] = useState(true);
    const { confirmationId } = useSharedState();
    const { rescheuleAppoitments, apptPricesAdmin } = useStepsState();
    const { locations } = useSharedState();
    const { updateRescheduleAppoitment, toggleLocationModal } = useRegisterStepsDispatch();
    const [checkappointment, setcheckappointment] = useState<{ change: boolean, upgrade: boolean, previous: any, present: any }>({ change: false, upgrade: false, previous: '', present: '' })
    const [paymentModel, setpaymentModel] = useState<boolean>(false)
    const [rollbackisExpressSameDayTest, setrollbackisExpressSameDayTest] = useState(false)
    const [rollbackisRapidTest, setrollbackisRapidTest] = useState(false)
    const [rollbackisAntigenTest, setrollbackisAntigenTest] = useState<boolean>(false);
    const [isEmployeeEmail, setIsEmployeeEmail] = useState(false);
    const [email, setEmail] = useState('')
    const [testSelection, setTestSelection] = useState();

    useEffect(() => {
        setLoading(true);
        let appointment: any = null;
        let modifiedappointment: any = null;
        async function getAppointments() {
            let result = await getAppointmentdata({ confirmationId });
            if (result !== undefined && result.data !== undefined && result.data.length > 0) {
                setLoading(true);
                appointment = result.data[0]
                setEmail(result.data[0]?.email);
                setTestSelection(result.data[0]?.testSelection);
                getEmployeeEmail()
                const location: any = locations?.filter((item: any) => {
                    if (item.qbenchCustomerId === appointment.location.qbenchCustomerId) {
                        return item;
                    }
                });

                modifiedappointment = {
                    ...appointment,
                }

                updateRescheduleAppoitment(modifiedappointment);
                setLoading(false);
            } else {
                setLoading(false);
            }
        }
        getAppointments().then((e: any) => {
            setrollbackisExpressSameDayTest((e: any) => appointment?.isExpressSameDayTest);
            setrollbackisRapidTest((e: any) => appointment?.isRapidTest);
            setrollbackisAntigenTest((e: any) => appointment?.isAntigen ? appointment?.isAntigen : false);
        });
    }, [confirmationId]);

    const getEmployeeEmail = async() =>{
        let empData =  await getEmployeeCoupon(email)
        const dataEmail: any = email?.split('@');
        const worksiteEmail = dataEmail[1];
        if (
            (worksiteEmail === 'worksitelabs.com' || !!empData.data ) &&
            testSelection === 'general'
        ) {
            setIsEmployeeEmail(true)
        }
        }

    const checkEveryTime = () => {
        let oldTest: any = rollbackisExpressSameDayTest === true ? 2 : rollbackisRapidTest === true ? 3 : rollbackisAntigenTest === true ? 0 : 1;
        let currentTest: any = rescheuleAppoitments?.isExpressSameDayTest === true ? 2 : rescheuleAppoitments.isRapidTest === true ? 3 : rescheuleAppoitments?.isAntigen === true ? 0 : 1;

        if (oldTest === currentTest) {
            setcheckappointment((previousstate) => {
                return {
                    change: false,
                    upgrade: false,
                    previous: '',
                    present: ''
                }
            })
        }
        else {
            // for LasVegas Sprint Vally Locaton Reschedule not Happens becasuse Since it has only one test types
            if(!rescheuleAppoitments?.isAntigen || !rescheuleAppoitments?.isExpressSameDayTest)
                setcheckappointment((e) => { return { change: false, upgrade: false, previous:'', present:'' } })
            else
            {
                let previous = oldTest === 3 ? 'Rapid' : oldTest === 2 ? 'Express' : oldTest === 0 ? 'Antigen' : 'Standard'
                let present = currentTest === 3 ? 'Rapid' : currentTest === 2 ? 'Express' : currentTest === 0 ? 'Antigen' : 'Standard'
                if (oldTest > currentTest)
                    setcheckappointment((e) => { return { change: true, upgrade: false, previous, present } })
                else
                    setcheckappointment((e) => { return { change: true, upgrade: true, previous, present } })            }
            }

    }
    const resheduleAppointmentHandler = async () => {
        let oldTest: any = rollbackisExpressSameDayTest === true ? 2 : rollbackisRapidTest === true ? 3 : rollbackisAntigenTest === true ? 0 : 1;
        let currentTest: any = rescheuleAppoitments?.isExpressSameDayTest === true ? 2 : rescheuleAppoitments.isRapidTest === true ? 3 : rescheuleAppoitments?.isAntigen === true ? 0 : 1;
        try {

            if (rescheuleAppoitments.hasInsurance) {

                setLoading(true);
                await updateAppointmentdata(rescheuleAppoitments).then((res: any) => {
                    history.push('/user/');
                    setLoading(false);
                });
                setLoading(false);

            } else {

                if (oldTest === currentTest || isEmployeeEmail) {
                    setLoading(true);
                    await updateAppointmentdata(rescheuleAppoitments).then((res: any) => {
                        history.push('/user/');
                        setLoading(false);
                    });
                    setLoading(false);
                } else {
                    setpaymentModel(true);
                }

            }

        } catch (error) {
            setLoading(false);
        }
    };
    const closeModel = () => {
        setpaymentModel(false)
    }
    if (loading) {
        return <Loader />;
    }
    return (
        <>
            <PaymentModel
                showModal={paymentModel}
                closeModal={closeModel}
                form={rescheuleAppoitments}
                prices={apptPricesAdmin}
                reason={checkappointment}
            />
            {!loading &&<>
                <Schedule
                    isManage={true}
                    title="Reschedule Your Appointment: Pick a date and time"
                    prices={apptPricesAdmin}
                    standardTest={rescheuleAppoitments.isExpressSameDayTest ? false : rescheuleAppoitments.isRapidTest ? false : rescheuleAppoitments?.isAntigen ? false : true}
                    onLocationChange={() => {
                        toggleLocationModal(true);
                    }}
                    onSchedule={async () => resheduleAppointmentHandler()}
                    onFieldUpdate={(update) => {
                        checkEveryTime();
                        updateRescheduleAppoitment({
                            ...rescheuleAppoitments,
                            ...update
                        })
                    }
                    }
                    appointment={rescheuleAppoitments}
                    confirmBtnText={checkappointment.change ? "Continue Reschedule" : 'Confirm Reschedule'}
                    loading={loading}
                    RescheduleTestType={checkappointment}
                    setIsEmployeeEmail = {setIsEmployeeEmail}
                    isEmployeeEmail = {isEmployeeEmail}
                />
                </>
            }
        </>
    );
};

export default EditAppointmentFromList;