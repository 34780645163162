import React, { useState } from 'react';
import styled from 'styled-components';
import { Breakpoints } from '../../../../../../dictionaries';
import { Form, Formik } from 'formik';
import Input from '../../../../../shared/components/form/Input';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import ConfirmationEmailModal from './ConfirmationEmailModal';
import {
  changEmailPassword,
  checkemail,
  getRegisteredUser,
} from '../../../../api';
import { useRegisterStepsDispatch, useStepsState } from '../../../../provider';
import { colors } from '../../../../../../styles/colors';
import {
  useSharedDispatch,
  useSharedState,
} from '../../../../../shared/provider';
import { Button } from './../../../../../shared/components/styled';
import Modal from './../../../../../shared/components/Modal';
import { useHistory } from 'react-router-dom';

const Container = styled.div``;
const Title = styled.h2`
  color: ${colors.fullBlack};
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 25px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 240px;
  margin: 0 -5px;

  & > * {
    margin: 0 5px;
  }
  @media (max-width: ${Breakpoints.sm}px) {
    display: grid;
    row-gap: 10px;
    margin-top: 30px;
  }
`;
const Text = styled.div`
  text-align: left;
  font-size: 15px;
  font-weight: 500;
  margin: 0px 25px;
`;
const SubTitle = styled.div`
  font-size: 16px;
  font-style: italic;
  margin-bottom: 10px;
  color: rgba(0, 0, 0, 0.54);
  font-weight: bold;
`;
const SubContainer = styled.div`
  margin: 20px 25px;
`;
const Error = styled.div`
  color: ${colors.red};
  margin-bottom: 30px;
  text-align: center;
`;
const Space = styled.div`
  @media (max-width: ${Breakpoints.sm}px) {
    margin-top: 35px;
  }
`;
type Props = {
  open: boolean;
  handleEmailClose: () => void;
};
const ChangeEmailModal: React.FC<Props> = ({ open, handleEmailClose }) => {
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const [isVerifyScreen, setVerifyScreen] = useState(false);
  const handleVerifyScreen = () => {
    setVerifyScreen(false);
    setloggedUserData(userData);
  };

  const [error, setError] = useState<string | null>(null);
  const [errorMsg, setErrorMsg] = useState<boolean>(false);
  const [email, setEmail] = useState('');
  const { userData, isVerifyFlow, isVerifyReq } = useSharedState();
  const { setloggedUserData } = useSharedDispatch();
  const [openConfirmEmailDialog, setOpenConfirmEmailDialog] = React.useState<
    boolean
  >(false);
  const handleConfirmEmailOpen = () => {
    setOpenConfirmEmailDialog(true);
  };
  const handleConfirmEmailClose = () => {
    setOpenConfirmEmailDialog(false);
  };
  const handleSubmit = async (data: any) => {
    setLoading(true);

    try {
      if (!(await verifyEmail(data.confirmEmail ? data.confirmEmail : ''))) {
        let emailPwdChange = await changEmailPassword(data);

        if (emailPwdChange?.data?.status === 200) {
          //   if (userData) userData['email'] = data.confirmEmail;
          setloggedUserData(userData);
          localStorage.setItem('user', JSON.stringify(userData));
          setErrorMsg(false);
          setVerifyScreen(true);
          handleConfirmEmailOpen();
        } else {
          setError(emailPwdChange?.data?.message);
          setErrorMsg(true);
          setVerifyScreen(false);
        }
      } else {
        setVerifyScreen(false);
      }
    } catch (e) {
      setError('Email already registered');
      setVerifyScreen(false);
    }

    setLoading(false);
  };

  const verifyEmail = async (email: string) => {
    if (email && typeof email !== 'undefined') {
      var pattern = new RegExp(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i
      );

      if (!pattern.test(email)) {
        setError('Please enter valid email address');
        setLoading(false);
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const signin = () => {
    localStorage.clear();
    setloggedUserData(null);
    history.push('/signin');
  };

  return (
    <div>
      {!isVerifyScreen ? (
        <>
          <Modal
            open={open}
            onClose={handleEmailClose}
            maxWidth={800}
            blockScroll={false}
          >
            <Container>
              <Title>
                <AiOutlineQuestionCircle size={22} color={'#000'} />
                Change your email
              </Title>
              <Text>
                Please enter an updated email address. We will send you an email
                to confirm your new address.
              </Text>
              <SubContainer>
                <SubTitle>Current Email</SubTitle>
                <Formik
                  initialValues={{
                    email: userData?.email,
                    newEmail: '',
                    confirmEmail: '',
                  }}
                  onSubmit={(values) => {
                    if (
                      values.newEmail === values.confirmEmail &&
                      values.email
                    ) {
                      if (values.newEmail === values.email) {
                        setError(
                          'Entered email and existing email both are same'
                        );
                      } else if (
                        userData?.secondaryEmail?.length &&
                        userData?.secondaryEmail?.length > 3
                      ) {
                        setError(
                          'You have exceeded the limit of changing email'
                        );
                      } else {
                        setEmail(values.newEmail);
                        console.log('values.email', values.newEmail);
                        let secondaryEmail: any = userData?.secondaryEmail
                          ? userData?.secondaryEmail
                          : [];
                        const email: any = userData?.email;
                        if (secondaryEmail.includes(email)) {
                          secondaryEmail.push(
                            values.confirmEmail.toLowerCase()
                          );
                        } else {
                          secondaryEmail.push(
                            email,
                            values.confirmEmail.toLowerCase()
                          );
                        }
                        secondaryEmail = secondaryEmail.filter(
                          (v: any, i: any, a: any) => a.indexOf(v) === i
                        );
                        console.log('secondaryEmail', email, secondaryEmail);
                        handleSubmit({
                          email: userData?.email,
                          secondaryEmail: secondaryEmail,
                          newEmail: values.newEmail.toLowerCase(),
                          confirmEmail: values.confirmEmail.toLowerCase(),
                        });
                      }
                    } else {
                      setError('New Email does not match with confirm email');
                    }
                  }}
                  enableReinitialize={false}
                >
                  {() => (
                    <Form>
                      <Input
                        name="email"
                        placeholder={userData?.email}
                        isRequired
                        disabled
                      />
                      <Space />
                      <SubTitle>New Email</SubTitle>
                      <Input
                        name="newEmail"
                        placeholder="New Email"
                        isRequired
                        // value={email}
                      />
                      <Space />
                      <Input
                        name="confirmEmail"
                        placeholder="Confirm New Email"
                        isRequired
                      />

                      {error && !errorMsg && <Error>{error}</Error>}
                      {errorMsg && (
                        <Error>
                          {error} <br />{' '}
                          {<a onClick={() => signin()}>Click here</a>}{' '}
                          {'to login with that address or try a new one.'}
                        </Error>
                      )}
                      <ButtonsContainer>
                        <Button
                          libraryType="default"
                          size="sm"
                          type="button"
                          onClick={() => {
                            setVerifyScreen(false);
                            setError('');
                            handleEmailClose();
                          }}
                          className='btn elevated-btn'
                        >
                          Cancel
                        </Button>
                        <Button
                          libraryType="primary"
                          size="sm"
                          type="submit"
                          onClick={() => {
                            setErrorMsg(false);
                            setError('');
                          }}
                          className='btn fill-btn'
                        >
                          {loading ? 'Processing...' : 'Confirm'}
                        </Button>
                      </ButtonsContainer>

                      {/* <ConfirmationEmailModal open={openConfirmEmailDialog} handleConfirmEmailClose= {handleConfirmEmailClose}/> */}
                    </Form>
                  )}
                </Formik>
              </SubContainer>
            </Container>
          </Modal>
        </>
      ) : (
        <ConfirmationEmailModal
          open={openConfirmEmailDialog}
          handleEmailClose={handleEmailClose}
          handleVerifyScreen={handleVerifyScreen}
          signin={signin}
          email={email}
        />
      )}
    </div>
  );
};

export default ChangeEmailModal;
