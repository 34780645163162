import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';

import {
  Container,
  Content,
  PageHeader,
  SubHeaderDescription,
  PageTitle,
  PageSubTitle,
} from '../../shared/components/styled';
import { colors } from '../../../styles/colors';

import Header from '../../shared/components/Header';
import Footer from '../../shared/components/Footer';
import { Breakpoints } from '../../../dictionaries';
import wsllogo from '../../../assets/WLABS_CMYK.png';
import orglogo from '../../../assets/org_logo.png';
import groublogo from '../../../assets/groub_logo.png';
import VerifyPhoneWthOutReg from './VerifyPhoneWthOutReg';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { initiateOTP } from '../../manage/api';
import { MultiTenancyService } from '../../../services/mutitenancy.service';
import { useStepsDispatch, useStepsState } from '../../schedule/provider';
import { useSharedState } from '../../shared/provider';
import VerifyEmailWthOutReg from './VerifyEmailWthOutReg';
import GoogleAnalytics from '../../../GoogleAnalytics';

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow:
      'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
});

function StyledRadio(props: RadioProps) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={
        <span
          className={clsx(classes.icon, classes.checkedIcon)}
          style={{ backgroundColor: '#101010' }}
        />
      }
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

const Error = styled.div`
  color: ${colors.red};
  margin-top: 15px;
  text-align: center;
`;

export const Logos = styled.div`
  display: flex;
`;

export const PrimeContainer = styled.div`
  min-height: 714px;
  margin-top: -5px;
`;

export const WLLogo = styled.div`
  height: 18px;
  width: 118px;
  background: url(${wsllogo}) no-repeat center center;
  background-size: cover;
  margin-right: 20px;
  display: inline-block;

  @media (min-width: ${Breakpoints.sm}px) {
    height: 70px;
    width: 300px;
  }
`;

export const Logos1 = styled.div`
  position: absolute;
  width: 601px;
  height: 161px;
  right: 432px;
  bottom: 518px;

  border: 1px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15));
  border-radius: 10px;
`;

export const Haldiv = styled.div`
  position: absolute;
  width: 120px;
  height: 120px;
  left: 616px;
  top: 408px;

  border: 1px solid rgb(235 227 227 / 50%);
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
  border-radius: 10px;
`;

export const Text = styled.div`
  position: absolute;
  width: 521px;
  height: 44px;
  left: 447px;
  top: 180px;

  font-family: Open Sans;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 44px;
  text-align: center;
  padding-right: 68px;
  color: #000000;
`;

export const SubText = styled.div`
  position: absolute;
  width: 460px;
  height: 39px;
  left: 472px;
  top: 220px;

  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  padding-right: 30px;

  color: ${colors.black};
`;
export const Orglogo = styled.div`
  height: 18px;
  width: 118px;
  background: url(${orglogo}) no-repeat center center;
  background-size: cover;
  display: inline-block;
  @media (min-width: ${Breakpoints.sm}px) {
    height: 80px;
    width: 85px;
  }
`;

export const CategoryContentBox = styled.div`
  flex: 0.3 1 0%;
  justify-content: center;
  border-radius: 10px;
  border: 2px solid ${colors.lightGrey};
  box-sizing: border-box;
  padding: 10px 0px 10px 0px;
  margin: 0px 5px 0px 5px;
  cursor: pointer;
`;

const CheckboxWrapper = styled.div`
  margin-left: 30px;
  text-align: left;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  @media (max-width: ${Breakpoints.sm}px) {
    padding: 0px;
  }
`;

export const GroupLogo = styled.div`
  height: 18px;
  width: 118px;
  background: url(${groublogo}) no-repeat center center;
  background-size: cover;
  display: inline-block;
  @media (min-width: ${Breakpoints.sm}px) {
    height: 79px;
    width: 97px;
  }
`;

const ContinueButton = styled.button`
  width: 50%;
  color: ${colors.white};
  text-align: center;
  padding: 15px;
  background: ${colors.orange};
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  border: 0;
  font-size: 20px;
  line-height: 18px;
  border-radius: 32px;
  font-weight: bold;
  cursor: pointer;
  margin: 15px 0px 0px 0px;
`;

const ConfirmAccountWthOutReg: React.FC = () => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [isVerifyScreen, setVerifyScreen] = useState(false);
  const [isPhoneScreen, setPhoneScreen] = useState(true);
  const {form} = useStepsState()
  const { phone,email}=form
  const { updateFormValues, goToNextStep, goToPrevStep } = useStepsDispatch();
  const [isOTP, setOTPState] = useState(false);
  const [validationMethod, setValidationMethod] = useState<string>('phone');
  const multiTenancyService: MultiTenancyService = new MultiTenancyService();

  useEffect(() => {
    GoogleAnalytics(form,'/Confirm Email');
    window.scrollTo(0, 0);
  }, []);
  const { apptFromSchdule } = useSharedState();

  const handleOTP = async () => {
    let verificationEmail = email.toLowerCase();
    let verificationMobileNumber = phone;

    if (validationMethod === 'phone') {
      verificationEmail = '';
    } else if (validationMethod === 'email') {
      verificationMobileNumber = '';
    }

    try {
      const otpResponse = await multiTenancyService.intiateOTP({
        email: verificationEmail.toLowerCase(),
        phone: verificationMobileNumber,
        isUserOTP: true,
        validationMethod:validationMethod,
        form:'',
        localStorageData:''
      });
      
    } catch (error) {
      console.log('failure to send OTP ', error);
    }
  };



  const confirmationSelectionHandler = (method: string) => {
    setValidationMethod(method);

    if (method === 'phone') {
      updateFormValues({ confirmPhone: true });
      setPhoneScreen(true);
    } else if (method === 'email') {
      updateFormValues({ confirmEmail: true });
      setPhoneScreen(false);
    }
  };

  const spitPhoneNumber = () => {
    return phone.substring(0, 2) + 'xxxxxxx' + phone.slice(-2);
  };

  const splitEmail = () => {
    const domain = email.split('@');
    return email.substring(0, 2) + 'xxxxxxx@' + domain[domain.length - 1];
  };
const borderStyle = {
  border: '0.5px solid rgba(36, 61, 77, 0.14)',
  boxShadow:
    '-4px -4px 8px rgba(153, 153, 153, 0.05),4px 4px 8px rgba(0, 0, 0, 0.15)',
  borderRadius: '12px',
  padding: '16px',
};
  return (
    <div>
      <Header isHelp isLogout />
      {!isVerifyScreen ? (
        <>
          <PrimeContainer>
            <Container
              style={{
                minHeight: '350px',
                backgroundColor: '#fff',
                ...borderStyle
              }}
            >
              <Content 
              style={{padding:'24px'}}
              >
                <>
                  {!apptFromSchdule && (
                    <PageHeader>
                      <PageTitle color={colors.fullBlack} textAlign="left">
                        To protect your security, We need to confirm it's really
                        you.
                      </PageTitle>
                    </PageHeader>
                  )}

                  {apptFromSchdule && (
                    <PageHeader>
                      <PageTitle color={colors.fullBlack} textAlign="left">
                        Your account has been created!.
                      </PageTitle>
                      <PageSubTitle>
                        To protect your security, we need to confirm it's really
                        you
                      </PageSubTitle>
                    </PageHeader>
                  )}

                  <SubHeaderDescription style={{ margin: '0px 0px 0px 20px' }}>
                    Please select an authentication method
                  </SubHeaderDescription>

                  <Formik
                    initialValues={{}}
                    onSubmit={async () => {
                      const result = await initiateOTP(
                        phone,
                        email.toLowerCase()
                      );

                      if (result) {
                        setOTPState(true);
                      } else {
                        setError(
                          'Please use any one of the method to continue.'
                        );
                      }
                      goToNextStep();
                    }}
                  >
                    <FormControl component="fieldset">
                      <CheckboxWrapper>
                        <RadioGroup
                          value={validationMethod}
                          onChange={(event) =>
                            confirmationSelectionHandler(event.target.value)
                          }
                        >
                          {/* <FormControlLabel
                            value="phone"
                            control={<StyledRadio />}
                            label={'Text Message to ' + spitPhoneNumber()}
                          /> */}
                          <FormControlLabel
                            value="email"
                            control={<StyledRadio />}
                            label={'Email to ' + splitEmail()}
                          />
                        </RadioGroup>
                      </CheckboxWrapper>
                    </FormControl>
                  </Formik>

                  <div style={{ textAlign: 'center' }}>
                    <ContinueButton
                      type="submit"
                      onClick={() => {
                        setVerifyScreen(true);
                        handleOTP();
                      }}
                      className="btn fill-btn"
                    >
                      Continue
                    </ContinueButton>
                  </div>
                </>
              </Content>
            </Container>
            <Footer />
          </PrimeContainer>
        </>
      ) : isPhoneScreen ? (
        <VerifyPhoneWthOutReg />
      ) : (
        <VerifyEmailWthOutReg />
      )}
    </div>
  );
};

export default ConfirmAccountWthOutReg;
