import React, { useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import styled, { keyframes } from 'styled-components';
import {
    format,
    startOfDay,
    isEqual,
    isBefore,
    isAfter,
    set,
    subHours,
    subMinutes,
    parse,
    isSameDay,
    differenceInDays,
    addMinutes,
    addDays,
    addYears
} from 'date-fns';
import { Field, FieldProps } from 'formik';

import { colors } from '../../../styles/colors';
import { ErrorText } from './styled';
import { Period } from '../../schedule/models';
import { AnyObject, Appointment } from '../models';
import { Breakpoints } from '../../../dictionaries';
import { config } from '../../../config';
import { subscribeOnAppointmentsUpdate, reserveSlot, getUpdatedSlots, getNewSlots, getGoHealthSlots } from '../../schedule/api';
import { pluralize, getPeriodsFromSchedule, weekAndWeekDayConvertion, manageNegativeValue, timeToPeriod, getPeriodsFromScheduleForRapid } from '../../../utils';
import { getMinAvailableDate, getMaxAvailableDate } from './Schedule';

import Datepicker from './Datepicker';
import { has, includes } from 'lodash';
import { useStepsState, useStepsDispatch } from '../../schedule/provider';
import { useAdminDispatch, useAdminState } from '../../admin/provider';
import { useManageDispatch, useManageState } from '../../manage/provider';
import { useSharedDispatch, useSharedState } from '../provider';
import moment from 'moment';


const periods = getPeriodsFromSchedule(config.schedule);


const Container = styled.div`
  position: relative;
  @media (min-width: ${Breakpoints.md}px) {
    width: 84%;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 23px;

  @media (min-width: ${Breakpoints.sm}px) {
    flex-direction: row;
    align-items: flex-end;
  }
`;

const SlotsContainer = styled.div`
  background: ${colors.white};
  padding: 30px;
  position: relative;
  border: 0.5px solid #ff802e;
  border-radius: 12px;
  @media (min-width: ${Breakpoints.lg}px) {
    padding: 80px 60px;
  }
  @media (min-width: ${Breakpoints.md}px) {
    width: 117%;
  }
`;

const Slot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: -5px 0 30px;

  & > * {
    margin: 5px 0;
  }

  @media (min-width: ${Breakpoints.lg}px) {
    flex-direction: row;
    margin: 0 -17px 20px;

    & > * {
      margin: 0 17px;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
  @media (max-width: ${Breakpoints.sm}px) {
    margin: 0px;
  }
`;

const ChooseSlotBtn = styled.button`
  background: ${({ selected }: { selected: boolean }) =>
    selected ? colors.rebrandBlack : colors.white};
  color: ${({ selected }: { selected: boolean }) =>
    selected ? colors.white : colors.rebrandBlack};
  border: 1px solid ${colors.rebrandBlack};
  text-transform: uppercase;
  font-size: 20px;
  transition: all 0.15s ease-in-out;
  padding: 10px 30px;
  border-radius: 10px;
  white-space: nowrap;
  cursor: ${({ selected }: { selected: boolean }) =>
    selected ? 'default' : 'pointer'};

  @media (min-width: ${Breakpoints.sm}px) {
    padding: 10px 50px;
  }

  @media (min-width: ${Breakpoints.md}px) {
    padding: 10px 80px;
    width: 102%;
    padding: 12px;
  }

  &:disabled {
    color: ${({ selected }: { selected: boolean }) =>
      selected ? colors.white : colors.grey};
    border: 1px solid
      ${({ selected }: { selected: boolean }) =>
        selected ? colors.rebrandBlack : colors.grey};
    cursor: default;
  }
  @media (min-width: ${Breakpoints.sm}px) {
    :hover {
      background-color: ${colors.rebrandBlack};
      color: white;
    }
  }
`;

const Error = styled.div`
  position: absolute;
  bottom: -120px;
  left: 50%;
  transform: translateX(-50%);
  @media (max-width: ${Breakpoints.sm}px) {
    bottom: -20px;
  }
`;

const DatePickerContainer = styled.div`
  display: block;
  line-height: 1.5;

  @media (min-width: ${Breakpoints.md}px) {
    display: ${({ alwaysShowDatePicker }: { alwaysShowDatePicker?: boolean }) =>
        alwaysShowDatePicker ? 'block' : 'none'};
  }
`;

const ChangeDateBtn = styled.div`
  display: inline-block;
  border: 0;
  background: none;
  color: ${colors.rebrandBlack};
  cursor: pointer;
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 2px;
`;

const SelectedDate = styled.h3`
  color: #262626;
  font-size: 22px;
  font-weight: 700;
  line-height: 1.5;
  margin-right: 10px;
`;

const shimmer = keyframes`
  0% {
    background-position: -400px 0;
  }
  100% {
    background-position: 400px 0;
  }
`;

const Shimmer = styled.div`
  height: 13px;
  width: 120px;
  border-radius: 2px;
  animation: ${shimmer} 2s infinite linear;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 400px 100%;
`;

interface Props {
    alwaysShowDatePicker?: boolean;
    appointment: Appointment;
    onFieldUpdate: (update: AnyObject) => void;
    onChangeDate?: (data: any) => void;
    isAdmin?: boolean;
    isManage?: any;
    setSlotsLoading?: any;
    isSlotsLoading?: any;
    isNextStep?: boolean;
    isWalkUpTest?: boolean;
    // ref?: any;
}

const Slots: React.FC<Props> = ({
    alwaysShowDatePicker,
    appointment,
    onFieldUpdate,
    onChangeDate,
    isAdmin,
    isManage,
    setSlotsLoading,
    isSlotsLoading,
    isNextStep,
    isWalkUpTest
}) => {

    const [slots, setSlots] = useState<any>(periods);

    const {
        date,
        slot,
        minors,
        departureDateAndTime,
        destination,
        isExpressSameDayTest,
        isRapidTest,
        location,
        reservationId,
        reservationCount,
        registeringFor,
        testSelection,
        cPartnerID,
        isAntigen,
        isPanel
    } = appointment;
    var numberOfGuests: number = 0;

    if (registeringFor === "minorOnly") {
        numberOfGuests = minors.length;
    } else if (appointment?.registeringFor === "myself") {
        numberOfGuests = 1;
    } else {
        numberOfGuests = minors.length + 1;
    }

    const { slotsList, blockOutDays,rebooking } = useStepsState();
    const { sharedSlots } = useSharedState();
    const { adminSlots } = useAdminState();
    const { updateFormValues, goToNextStep, updateSlotsList, updateBlockOutDays } = useStepsDispatch();
    const { rescheduleslots } = useManageState();
    const locationId = location?.qbenchCustomerId;
    const [selectedDate, setSelectedDate] = useState(isWalkUpTest ? new Date() : date ? parse(date, config.dateFormat, new Date()) : new Date());
    const { updateSharedSlots } = useSharedDispatch();
    const { updateAdminSlots } = useAdminDispatch();
    const [loading, setLoading]=useState(false)
    const [slotCount, setSlotCount] = useState<number>(0)

    function checkIsWeekDay(date: any) {
        let validDay: any = "";
        let day: any = new Date(date).getDay();
        let isaddOutDays: any =
        location !== undefined && location?.cPartnerID === 'SEQ001'
        ? includes([config.qbenchCustomerId.PostIsolationRapidAntigenTest], location?.qbenchCustomerId)
            ? ["02/25/2022"]
            : includes([config.qbenchCustomerId.SequoiaHighSchool], location?.qbenchCustomerId)
                ? [ "09/05/2022",'12/29/2022','01/05/2023','02/23/2023', '04/13/2023']                       
                : includes([config.qbenchCustomerId.TIDEAcademy_LowerTIDE], location?.qbenchCustomerId)
                    ? ["01/19/2022"]
                    : includes([config.qbenchCustomerId.WoodSideHighSchool], location?.qbenchCustomerId)
                        ? ['04/29/2022', '05/06/2022']
                        : includes([config.qbenchCustomerId.MenloAthertonHighSchool], location?.qbenchCustomerId)
                                    ? ['04/19/2023']
                                    : includes([config.qbenchCustomerId.CarlmontHighSchool], location?.qbenchCustomerId)
                                        ? ['04/19/2023']
                                        : []
        : 
        location?.cPartnerID === 'KEN001'
        ? includes([config.qbenchCustomerId.KernHealthSystems], location?.qbenchCustomerId)
        ? [ "09/06/2022"]
        :[]
        :[];
        let additionalOutDays: any = includes(isaddOutDays, format(new Date(date), config.dateFormat))
        // allowed same day booking for sfo airport
        // if (location?.qbenchCustomerId == config.qbenchCustomerId.SFO_Airport) {
        //     location?.blockOutDays.push(format(new Date(), config.dateFormat))
        // }
        let blockOutDays: any = location?.blockOutDays !== undefined ? includes(location?.blockOutDays, format(new Date(date), config.dateFormat)) : false;
        let isDayFilter: any = testSelection !== "general" ? location?.isDayFilter !== undefined ? location?.isDayFilter : false : false
        let isWeeklyFlag: any = testSelection !== "general" ? location?.isWeeklyFlag !== undefined ? location?.isWeeklyFlag : false : false
        let weeklyVisible: any = testSelection !== "general" ? {
            weeklyVisibleDays: location?.weeklyVisibleDays !== undefined ? location?.weeklyVisibleDays : [0, 1, 2, 3, 4, 5, 6],
            weeklyVisibleNumber: location?.weeklyVisibleNumber !== undefined ? location?.weeklyVisibleNumber : { weekDay: 0, weekNumber: 0 },
        }
            : {}

        if (isWeeklyFlag !== undefined && isWeeklyFlag) {
            let addDay: any = weekAndWeekDayConvertion({
                weeklyVisible: weeklyVisible,
                date: date,
            });
            if (isDayFilter) {
                if (blockOutDays !== undefined && blockOutDays) {
                    validDay = false;
                }
                else if (additionalOutDays) {
                    validDay = true;
                }
                else if (new Date(date).getDate() === new Date(addDay).getDate()) {
                    validDay = true;
                } else {
                    validDay = false;
                }
            } else {
                if (blockOutDays !== undefined && blockOutDays) {
                    validDay = false;
                }
                else if (additionalOutDays) {
                    validDay = true;
                }
                else if (includes(weeklyVisible.weeklyVisibleDays, day)) {
                    validDay = true;
                } else {
                    validDay = false;
                }
            }
        } else if (blockOutDays !== undefined && blockOutDays) {
            validDay = false;
        } else if (additionalOutDays) {
            validDay = true;
        } else {
            validDay = true
        }

        //Added the speical conditaional for SSF001 // can be removed in the feature 
        if (cPartnerID === "SSF001" &&
            includes([config.qbenchCustomerId.BadenAdultSchool,
            config.qbenchCustomerId.ElCaminoHighSchool,
            config.qbenchCustomerId.WestboroughMiddleSchool], location?.qbenchCustomerId) &&
            includes(["01/28/2022", "01/29/2022", "01/30/2022"], format(new Date(date), config.dateFormat))) {
            validDay = true;
        }

        return validDay
    }

    const goHealthSlot = async(param: any) => {
        setSlotsLoading(true);
        setLoading(true)
        try{
            let goHealthSlot = await getGoHealthSlots(param);
            if(goHealthSlot?.data?.length){
                setSlots(
                    goHealthSlot?.data[0]?.times.reduce((acc: any, period: any, i: any) => {
                        const a = acc;  
                        console.log("a",a, typeof(period.time))
                        
                        a.push({
                            label:period?.display_time,
                            startTime:period?.time,
                            endTime:"",
                            available: 1,
                            index: i,
                            time:period?.time,
                            appointment_queue_id: period?.appointment_queue_id,
                        });
                        return a;
                    }, [])
                );
                setTimeout(() => {
                    setSlotsLoading(false);
                    setLoading(false)
                }, 3000);
            }else{
                setSlots([]);
                setSlotsLoading(false);
                setLoading(false);
            }
        }catch (e) {
            setSlots([]);
            setSlotsLoading(false);
            setLoading(false);
        }
    }

    async function getSlots() {
        try {
            setSlotsLoading(true);
            if(location?.partnerName === 'goHealth'){
                let param = {
                    date: moment(date ? date : selectedDate).format("YYYY-MM-DD"),
                    hospitalId: location?.hospitalId
                }
                goHealthSlot(param)
            } else if (!!testSelection &&
                // testSelection !== 'flight' 
                date == null) {
                updateFormValues({ date: format(new Date(), config.dateFormat) })
            }
            if (date && locationId) {

                const todayDate = format(new Date(), config.dateFormat);
                const parsed = parse(date, config.dateFormat, new Date());
                const dayAsNumber = new Date(parsed).getDay();
                setSelectedDate(date ? parse(date, config.dateFormat, new Date()) : new Date());
                let testType: any = isWalkUpTest ? 'walkup' : isRapidTest ? "rapid" : (isExpressSameDayTest ? "express" : isAntigen ? "antigen" :  isPanel ? "panel" : "standard");

                let updatedPeriods: any = []
                if (!isAdmin && !isManage) {
                    //week 01/17 to 01/24
                    let Weeks: any = ["02/24/2022", "02/25/2022"]
                    //testType = isWalkUpTest ? 'walkup' : isRapidTest ? "rapid" : (isExpressSameDayTest ? "express" : isAntigen ? "antigen" : "standard");
                    let testingWeeks: any = ["01/17/2022", "01/18/2022", "01/19/2022", "01/20/2022", "01/21/2022", "01/22/2022", "01/23/2022"]
                    if (!isWalkUpTest && testSelection === "school" && cPartnerID === "SEQ001" && includes(testingWeeks, format(new Date(date), config.dateFormat).toString())) {
                        if (location?.qbenchCustomerId === config.qbenchCustomerId.WoodSideHighSchool) {
                            const periods = getPeriodsFromSchedule({
                                startTime: '6:00a',
                                endTime: '5:00p',
                                periodDuration: 30,
                                availability: 26
                            });
                            updatedPeriods = periods.slice(2, periods.length);
                        }
                        else if (location?.qbenchCustomerId === config.qbenchCustomerId.SanJose && (new Date(date) > new Date("04/23/2022"))) {
                            updatedPeriods = [];
                        }
                        else if (location?.qbenchCustomerId === config.qbenchCustomerId.seattleAngleLake && (new Date(date) > new Date("05/01/2023"))) {
                            updatedPeriods = [];
                        }
                        else if (location?.qbenchCustomerId === config.qbenchCustomerId.MenloAthertonHighSchool) {
                            const periods = getPeriodsFromSchedule({
                                startTime: '6:00a',
                                endTime: '5:00p',
                                periodDuration: 30,
                                availability: 26
                            });
                            updatedPeriods = periods.slice(2, periods.length);
                        }
                        else {
                            const periods = getPeriodsFromSchedule({
                                startTime: '6:00a',
                                endTime: '7:00p',
                                periodDuration: 30,
                                availability: 26
                            });

                            updatedPeriods = periods.slice(2, periods.length);
                        }


                    } 
                    else if (location?.qbenchCustomerId === config.qbenchCustomerId.SFO_Airport && (new Date(date) > new Date("04/30/2023"))) {
                        updatedPeriods = [];
                    }
                    else if(location?.qbenchCustomerId === config.qbenchCustomerId.SFOAirport_BARTStation && (new Date(date) > new Date("06/09/2023"))){
                        updatedPeriods=[];
                    }
                    else if (location?.qbenchCustomerId === config.qbenchCustomerId.CarlmontHighSchool && date === '04/19/2023') {
                        //Carlmont High School
                        const periods = getPeriodsFromSchedule({
                            startTime: '12:30p',
                            endTime: '4:30p',
                            periodDuration: 30,
                            availability: 13,
                        });

                        updatedPeriods = periods;
                    }
                    else if (location?.qbenchCustomerId === config.qbenchCustomerId.MenloAthertonHighSchool && date === '04/19/2023') {
                        //Carlmont High School
                        const periods = getPeriodsFromSchedule({
                            startTime: '8:30a',
                            endTime: '11:30a',
                            periodDuration: 30,
                            availability: 13,
                        });

                        updatedPeriods = periods;
                    }
                    else if (location.qbenchCustomerId === config.qbenchCustomerId.SequoiaHighSchool && ["02/23/2023", '04/13/2023'].includes(date)) {
                        const periods = getPeriodsFromSchedule({
                            startTime: '09:30a',
                            endTime: '3:00p',
                            periodDuration: 30,
                            availability: 13,
                        });

                        updatedPeriods = periods
                    }
                    else if(location.qbenchCustomerId === config.qbenchCustomerId.SequoiaHighSchool && (new Date(date).getDay() === 2) && ((new Date(date) > new Date("05/22/2023")) && (new Date(date) < new Date("05/31/2023")))){
                        const periods = getPeriodsFromSchedule({
                            startTime: '11:30a',
                            endTime: '04:30p',
                            periodDuration: 30,
                            availability: 13,
                        });
                    updatedPeriods = periods
                } else if(location.qbenchCustomerId === config.qbenchCustomerId.CarlmontHighSchool &&(new Date(date).getDay() === 1) && ((new Date(date) > new Date("05/22/2023")) && (new Date(date) < new Date("05/31/2023")))){
                    const periods = getPeriodsFromSchedule({
                        startTime: '11:30a',
                        endTime: '04:30p',
                        periodDuration: 30,
                        availability: 13,
                    });

                    updatedPeriods = periods
                } else if(location.qbenchCustomerId === config.qbenchCustomerId.MenloAthertonHighSchool && (new Date(date).getDay() === 5) && ((new Date(date) > new Date("05/22/2023")) && (new Date(date) < new Date("05/31/2023")))){
                    const periods = getPeriodsFromSchedule({
                        startTime: '11:30a',
                        endTime: '04:30p',
                        periodDuration: 30,
                        availability: 13,
                    });

                    updatedPeriods = periods
                } else if(location.qbenchCustomerId === config.qbenchCustomerId.WoodSideHighSchool &&(new Date(date).getDay() === 4) && ((new Date(date) > new Date("05/22/2023")) && (new Date(date) < new Date("05/31/2023")))){
                    const periods = getPeriodsFromSchedule({
                        startTime: '11:30a',
                        endTime: '04:30p',
                        periodDuration: 30,
                        availability: 13,
                    });

                    updatedPeriods = periods
                }
                    else if(location.qbenchCustomerId === config.qbenchCustomerId.SequoiaHighSchool && (new Date(date).getDay() === 2) && (new Date(date) > new Date("06/01/2023"))){
                        const periods = getPeriodsFromSchedule({
                            startTime: '10:00a',
                            endTime: '01:00p',
                            periodDuration: 30,
                            availability: 13,
                        });
                    updatedPeriods = periods
                } else if(location.qbenchCustomerId === config.qbenchCustomerId.CarlmontHighSchool && (new Date(date).getDay() === 1) && (new Date(date) > new Date("06/01/2023"))){
                    const periods = getPeriodsFromSchedule({
                        startTime: '10:00a',
                        endTime: '01:00p',
                        periodDuration: 30,
                        availability: 13,
                    });

                    updatedPeriods = periods
                } else if(location.qbenchCustomerId === config.qbenchCustomerId.MenloAthertonHighSchool && (new Date(date).getDay() === 5) && (new Date(date) > new Date("06/01/2023"))){
                    const periods = getPeriodsFromSchedule({
                        startTime: '10:00a',
                        endTime: '01:00p',
                        periodDuration: 30,
                        availability: 13,
                    });

                    updatedPeriods = periods
                } else if(location.qbenchCustomerId === config.qbenchCustomerId.WoodSideHighSchool && (new Date(date).getDay() === 4) && (new Date(date) > new Date("05/31/2023"))){
                    const periods = getPeriodsFromSchedule({
                        startTime: '10:00a',
                        endTime: '01:00p',
                        periodDuration: 30,
                        availability: 13,
                    });

                    updatedPeriods = periods
                }
                      else if (location?.qbenchCustomerId === config.qbenchCustomerId.Austin &&
                        (new Date(date) > new Date("12/30/2022"))) {
                        updatedPeriods = [];
                    }
                         else if (location?.qbenchCustomerId === config.qbenchCustomerId.LongBeach &&
                        (new Date(date) > new Date("01/13/2023"))) {
                        updatedPeriods = [];
                    }
                         else if (location?.qbenchCustomerId === config.qbenchCustomerId.Portland&&
                        (new Date(date) > new Date("01/13/2023"))) {
                        updatedPeriods = [];
                    }
                    else if (testSelection === 'flight' && testType === 'antigen' && (destination?.value === 'JPN')) {
                        updatedPeriods = [];
                    }
                    else if(location?.qbenchCustomerId === config.qbenchCustomerId.ProudBird && (new Date(date) >= new Date("11/20/2022"))){
                        updatedPeriods=[];
                    }
                    else if (location?.qbenchCustomerId === config.qbenchCustomerId.EastPaloAltoAcademy && date === '06/01/2022') {
                        const periods = getPeriodsFromSchedule({
                            startTime: '9:30a',
                            endTime: '5:30p',
                            periodDuration: 30,
                            availability: 22,
                        });
                        updatedPeriods = periods;
                    }
                    else if (location?.qbenchCustomerId === config.qbenchCustomerId.WoodSideHighSchool && date === '04/21/2022') {
                        //Woodside High School
                        const periods = getPeriodsFromSchedule({
                            startTime: '9:30a',
                            endTime: '5:30p',
                            periodDuration: 30,
                            availability: 50,
                        });

                        updatedPeriods = periods;
                    }
                    else if (location?.qbenchCustomerId === config.qbenchCustomerId.CarlmontHighSchool && date === '04/28/2022') {
                        //Carlmont High School
                        const periods = getPeriodsFromSchedule({
                            startTime: '9:30a',
                            endTime: '5:30p',
                            periodDuration: 30,
                            availability: 50,
                        });

                        updatedPeriods = periods;
                    }
                    else if (location?.qbenchCustomerId === config.qbenchCustomerId.SequoiaHighSchool && date === '05/11/2022') {
                        //Sequoia High School
                        const periods = getPeriodsFromSchedule({
                            startTime: '9:30a',
                            endTime: '5:30p',
                            periodDuration: 30,
                            availability: 50,
                        });

                        updatedPeriods = periods;
                    }
                    else if (!isWalkUpTest && location?.qbenchCustomerId === config.qbenchCustomerId.LasVegas_TheOrleans &&
                        includes(['02/12/2022', '02/13/2022'], format(new Date(date), config.dateFormat).toString())) {
                        const periods = getPeriodsFromSchedule({
                            startTime: '7:00a',
                            endTime: '7:00p',
                            periodDuration: 30,
                            availability: 26
                        });

                        updatedPeriods = periods.slice(2, periods.length);
                    }
                    // sequoia and woodside school start
                    else if (location?.qbenchCustomerId === config.qbenchCustomerId.SequoiaHighSchool && date === '04/26/2022') {
                        //Sequoia High School
                        const periods = getPeriodsFromSchedule({
                            startTime: '9:30a',
                            endTime: '2:30p',
                            periodDuration: 30,
                            availability: 13,
                        });

                        updatedPeriods = periods;
                    }
                    else if (location?.qbenchCustomerId === config.qbenchCustomerId.WoodSideHighSchool && date === '04/29/2022') {
                        //Woodside High School
                        const periods = getPeriodsFromSchedule({
                            startTime: '9:30a',
                            endTime: '3:30p',
                            periodDuration: 30,
                            availability: 13,
                        });

                        updatedPeriods = periods;
                    }
                    else if (location?.qbenchCustomerId === config.qbenchCustomerId.WoodSideHighSchool && date === '05/06/2022') {
                        //Woodside High School
                        const periods = getPeriodsFromSchedule({
                            startTime: '9:30a',
                            endTime: '5:30p',
                            periodDuration: 30,
                            availability: 13,
                        });
                        updatedPeriods = periods;
                    } // sequoia and woodside school end

                    //This condition is to add the SSF001 to available on 01/28/2022
                    else if (
                        cPartnerID === "SSF001"
                        && includes([config.qbenchCustomerId.BadenAdultSchool,
                        config.qbenchCustomerId.ElCaminoHighSchool,
                        config.qbenchCustomerId.WestboroughMiddleSchool], location?.qbenchCustomerId)
                        && includes(["01/28/2022", "01/29/2022", "01/30/2022"], format(new Date(date), config.dateFormat))
                    ) {
                        slotsList?.map((item: any) => {
                            let testType: any = isWalkUpTest ? 'walkup' : isRapidTest ? "rapid" : (isExpressSameDayTest ? "express" : isAntigen ? "antigen" : "standard");
                            if (item.testType == testType) {
                                if (isWalkUpTest) {
                                    updatedPeriods.push(item.slotsByDay[`${dayAsNumber}`])
                                } else {
                                    item.slotsByDay[`${dayAsNumber}`]
                                        .map((res: any) => {
                                            if (res.index < 20) {
                                                updatedPeriods.push({
                                                    ...res,
                                                    startTime: parse(res.startTime, 'h:mmaaaaa', new Date()),
                                                    endTime: parse(res.endTime, 'h:mmaaaaa', new Date()),
                                                })
                                            }
                                        })
                                }
                            }

                        })

                    }
                    else if(!appointment.isRapidTest && location.qbenchCustomerId === config.qbenchCustomerId.LasVegasSpringValley && (new Date(date) < new Date("12/21/2022"))){
                        updatedPeriods = [];
                    }
                    else if (location?.qbenchCustomerId === config.qbenchCustomerId.RalstonMiddleSchool &&  (new Date(date).getDay() === 1 || new Date(date).getDay() === 4)) {
                        // Ralston Middle School
                        const periods = getPeriodsFromSchedule({
                            startTime: '2:00p',
                            endTime: '5:00p',
                            periodDuration: 30,
                            availability: 13
                        })
                        updatedPeriods = periods
                    }
                    else if (testSelection === "community" && location?.qbenchCustomerId === config.qbenchCustomerId.Brooklyn &&
                        includes(Weeks, format(new Date(date), config.dateFormat).toString())) {
                        const periods = getPeriodsFromSchedule({
                            startTime: '6:00a',
                            endTime: '1:00p',
                            periodDuration: 30,
                            availability: 16
                        });
                        updatedPeriods = periods.slice(2, periods.length);
                    }
                    else if (testSelection !== "community" && testSelection !== "sfoairport" && testSelection !== "school" && location?.qbenchCustomerId === 140 && includes(Weeks, format(new Date(date), config.dateFormat).toString())) {
                        const periods = getPeriodsFromSchedule({
                            startTime: '6:00a',
                            endTime: '2:00p',
                            periodDuration: 30,
                            availability: 16
                        });
                        updatedPeriods = periods.slice(2, periods.length);
                    }

                    else if (location?.qbenchCustomerId == config.qbenchCustomerId.LongBeach &&
                        (["02/25/2022", "02/26/2022", "02/27/2022"].includes(date) && (testType === 'express' || testType === 'standard'))) {
                        const periods = getPeriodsFromSchedule({
                            startTime: '7:00a',
                            endTime: '7:00p',
                            periodDuration: 30,
                            availability: 16,
                        });
                        updatedPeriods = periods
                    } else if (location?.qbenchCustomerId == config.qbenchCustomerId.LongBeach &&
                        (["02/25/2022", "02/26/2022", "02/27/2022"].includes(date) && (testType === 'rapid'))) {
                        const periods = getPeriodsFromScheduleForRapid({
                            startTime: '7:00a',
                            endTime: '7:00p',
                            periodDuration: 60,
                            availability: 4,
                            lablePeriod: 30,
                        });
                        updatedPeriods = periods
                    }
                    else {
                        slotsList?.map((item: any) => {
                            let testType: any = isWalkUpTest ? 'walkup' : isRapidTest
                                ? 'rapid'
                                : isExpressSameDayTest
                                    ? 'express'
                                    : isAntigen ? 'antigen' : 'standard';

                            if (item.testType == testType) {
                                if (!isWalkUpTest && location?.isSameDay && !location?.isSameDay[testType] && date === todayDate) {
                                    updatedPeriods = [];
                                } else {
                                if (isWalkUpTest) {
                                    updatedPeriods.push(item.slotsByDay[`${dayAsNumber}`])
                                } else if (location?.qbenchCustomerId == config.qbenchCustomerId.SFO_Airport && testType === 'rapid' && date === '02/16/2022') {
                                    updatedPeriods = [];
                                } 
                                else if (location?.qbenchCustomerId === config.qbenchCustomerId.RalstonMiddleSchool &&  (new Date(date).getDay() === 1 || new Date(date).getDay() === 4)) {
                                    // Ralston Middle School
                                    const periods = getPeriodsFromSchedule({
                                        startTime: '2:00p',
                                        endTime: '5:00p',
                                        periodDuration: 30,
                                        availability: 13
                                    })
                                    updatedPeriods = periods
                                }else if (location?.qbenchCustomerId === config.qbenchCustomerId.RalstonMiddleSchool && date === '09/29/2022') {
                                    // Ralston Middle School
                                    const periods = getPeriodsFromSchedule({
                                        startTime: '9:30a',
                                        endTime: '5:30p',
                                        periodDuration: 30,
                                        availability: 13
                                    })
                                    updatedPeriods = periods
                                }else if (location?.qbenchCustomerId === config.qbenchCustomerId.RalstonMiddleSchool && date === '09/30/2022') {
                                    // Ralston Middle School
                                    const periods = getPeriodsFromSchedule({
                                        startTime: '9:30a',
                                        endTime: '5:30p',
                                        periodDuration: 30,
                                        availability: 13
                                    })
                                    updatedPeriods = periods
                                } else if (location?.qbenchCustomerId === config.qbenchCustomerId.RalstonMiddleSchool && date === '10/01/2022') {
                                    // Ralston Middle School
                                    const periods = getPeriodsFromSchedule({
                                        startTime: '9:30a',
                                        endTime: '5:30p',
                                        periodDuration: 30,
                                        availability: 13
                                    })
                                    updatedPeriods = periods
                                } else if (location?.qbenchCustomerId === config.qbenchCustomerId.RalstonMiddleSchool && date === '10/02/2022') {
                                    // Ralston Middle School
                                    const periods = getPeriodsFromSchedule({
                                        startTime: '9:30a',
                                        endTime: '5:30p',
                                        periodDuration: 30,
                                        availability: 13
                                    })
                                    updatedPeriods = periods
                                }//Redwood Shores Elementary Last Day of School 06/16/2022. Closed scheduling throughout the summer
                                else if(location?.qbenchCustomerId === config.qbenchCustomerId.RedwoodShoresElementary && (new Date(date) > new Date("06/16/2022"))){
                                           
                                    updatedPeriods=[];
                                    
                                }//South San Francisco School District, The last day of testing for all schools in the district will be 05/27/2022
                                else if(location?.qbenchCustomerId === config.qbenchCustomerId.WestboroughMiddleSchool && (new Date(date) > new Date("05/27/2022"))){
                                    updatedPeriods=[];
                                }
                                else if(location?.qbenchCustomerId === config.qbenchCustomerId.ElCaminoHighSchool && (new Date(date) > new Date("05/27/2022"))){
                                    updatedPeriods=[];
                                }
                                else if(location?.qbenchCustomerId === config.qbenchCustomerId.BadenAdultSchool && (new Date(date) > new Date("08/10/2022"))){
                                    const periods = getPeriodsFromSchedule({
                                        startTime: '7:30a',
                                        endTime: '5:00p',
                                        periodDuration: 30,
                                        availability: 13,
                                    });
                                    updatedPeriods = periods
                                }
                                //Arroyo Upper School,  Last day of testing will be 06/08/2022                           
                                else if (location?.qbenchCustomerId === config.qbenchCustomerId.ArroyoUpperSchool && (new Date(date) <= new Date("10/05/2022"))) {
                                    const periods = getPeriodsFromSchedule({
                                        startTime: '3:30p',
                                        endTime: '6:30p',
                                        periodDuration: 30,
                                        availability: 26,
                                    });

                                    updatedPeriods = periods
                                }
                                //Sequoia High School (Carrington Hall):
                                else if (location?.qbenchCustomerId === config.qbenchCustomerId.SequoiaHighSchool && (new Date(date) > new Date("06/06/2022") && (new Date(date) < new Date("08/15/2022")))) {
                                    const periods = getPeriodsFromSchedule({
                                        startTime: '10:00a',
                                        endTime: '4:00p',
                                        periodDuration: 30,
                                        availability: 13,
                                    });

                                    updatedPeriods = periods
                                }// // sequoia and woodside school start
                                else if (location.qbenchCustomerId === config.qbenchCustomerId.SequoiaHighSchool && ["12/29/2022", '01/05/2023'].includes(date)) {

                                    const periods = getPeriodsFromSchedule({
                                        startTime: '09:30a',
                                        endTime: '5:30p',
                                        periodDuration: 30,
                                        availability: 13,
                                    });

                                    updatedPeriods = periods
                                }//Kern Health systems
                                else if (location?.qbenchCustomerId === config.qbenchCustomerId.KernHealthSystems && date === '09/06/2022') {
                                    const periods = getPeriodsFromSchedule({
                                        startTime: '8:00a',
                                        endTime: '12:00p',
                                        periodDuration: 15,
                                        availability: 15,
                                    });

                                    updatedPeriods = periods;
                                }
                                //Menlo-Atherton High School
                            else if (location?.qbenchCustomerId === config.qbenchCustomerId.MenloAthertonHighSchool && (new Date(date) > new Date("06/06/2022") && (new Date(date) < new Date("08/15/2022")))) {
                                    const periods = getPeriodsFromSchedule({
                                    startTime: '10:00a',
                                    endTime: '4:00p',
                                    periodDuration: 30,
                                    availability: 26,
                                });

                                updatedPeriods = periods
                            }   
                            //Carlmont High School
                            else if(location?.qbenchCustomerId === config.qbenchCustomerId.CarlmontHighSchool && (new Date(date) > new Date("06/12/2022") && (new Date(date) < new Date("08/15/2022")))){
                                const periods = getPeriodsFromSchedule({
                                    startTime: '10:00a',
                                    endTime: '4:00p',
                                    periodDuration: 30,
                                    availability: 13,
                                });

                                updatedPeriods = periods
                            }//Woodside High School 
                            else if(location?.qbenchCustomerId === config.qbenchCustomerId.WoodSideHighSchool && (new Date(date) > new Date("06/12/2022") && (new Date(date) < new Date("08/15/2022")))){
                                const periods = getPeriodsFromSchedule({
                                    startTime: '10:00a',
                                    endTime: '4:00p',
                                    periodDuration: 30,
                                    availability: 13,
                                });

                                updatedPeriods = periods
                            }//EAPP
                            else if(location?.qbenchCustomerId === config.qbenchCustomerId.EastPaloAltoAcademy && (new Date(date) > new Date("06/03/2022") && (new Date(date) < new Date("08/08/2022")))){
                                   updatedPeriods=[];
                            }
                            else if(date ==="08/10/2022" && location?.qbenchCustomerId === config.qbenchCustomerId.EastPaloAltoAcademy){
                                    const periods = getPeriodsFromSchedule({
                                        startTime: '10:00a',
                                        endTime: '4:00p',
                                        periodDuration: 30,
                                        availability: 22,
                                    });
    
                                    updatedPeriods = periods

                                        updatedPeriods = periods
                                    }// sequoia 
                                    else if (location?.qbenchCustomerId === config.qbenchCustomerId.SequoiaHighSchool && date === '09/05/2022') {
                                        //Sequoia High School
                                        const periods = getPeriodsFromSchedule({
                                            startTime: '9:00a',
                                            endTime: '2:00p',
                                            periodDuration: 30,
                                            availability: 13,
                                        });

                                        updatedPeriods = periods;
                                    }//Kern Health systems
                                    else if (location?.qbenchCustomerId === config.qbenchCustomerId.KernHealthSystems && date === '09/06/2022') {
                                        const periods = getPeriodsFromSchedule({
                                            startTime: '8:00a',
                                            endTime: '12:00p',
                                            periodDuration: 15,
                                            availability: 15,
                                        });

                                        updatedPeriods = periods;
                                    }
                                    //Menlo-Atherton High School
                                    else if (location?.qbenchCustomerId === config.qbenchCustomerId.MenloAthertonHighSchool && (new Date(date) > new Date("06/06/2022") && (new Date(date) < new Date("08/15/2022")))) {
                                        const periods = getPeriodsFromSchedule({
                                            startTime: '10:00a',
                                            endTime: '4:00p',
                                            periodDuration: 30,
                                            availability: 26,
                                        });

                                        updatedPeriods = periods
                                    }
                                    //Carlmont High School
                                    else if (location?.qbenchCustomerId === config.qbenchCustomerId.CarlmontHighSchool && (new Date(date) > new Date("06/12/2022") && (new Date(date) < new Date("08/15/2022")))) {
                                        const periods = getPeriodsFromSchedule({
                                            startTime: '10:00a',
                                            endTime: '4:00p',
                                            periodDuration: 30,
                                            availability: 13,
                                        });

                                        updatedPeriods = periods
                                    }//Woodside High School 
                                    else if (location?.qbenchCustomerId === config.qbenchCustomerId.WoodSideHighSchool && (new Date(date) > new Date("06/12/2022") && (new Date(date) < new Date("08/15/2022")))) {
                                        const periods = getPeriodsFromSchedule({
                                            startTime: '10:00a',
                                            endTime: '4:00p',
                                            periodDuration: 30,
                                            availability: 13,
                                        });

                                        updatedPeriods = periods
                                    }//EAPP
                                    else if (location?.qbenchCustomerId === config.qbenchCustomerId.EastPaloAltoAcademy && (new Date(date) > new Date("06/03/2022") && (new Date(date) < new Date("08/08/2022")))) {
                                        updatedPeriods = [];
                                    }
                                    else if (date === "08/10/2022" && location?.qbenchCustomerId === config.qbenchCustomerId.EastPaloAltoAcademy) {
                                        const periods = getPeriodsFromSchedule({
                                            startTime: '10:00a',
                                            endTime: '4:00p',
                                            periodDuration: 30,
                                            availability: 22,
                                        });

                                        updatedPeriods = periods

                                    }//Redwood hs 
                                    else if (location?.qbenchCustomerId === config.qbenchCustomerId.RedwoodHighSchool && (new Date(date) > new Date("06/03/2022") && (new Date(date) < new Date("08/08/2022")))) {
                                        updatedPeriods = [];
                                    }
                                    else if (date === "08/10/2022" && location?.qbenchCustomerId === config.qbenchCustomerId.RedwoodHighSchool) {
                                        const periods = getPeriodsFromSchedule({
                                            startTime: '10:00a',
                                            endTime: '4:00p',
                                            periodDuration: 30,
                                            availability: 22,
                                        });

                                        updatedPeriods = periods

                                    }// MOT @ Mills
                                    else if (location?.qbenchCustomerId === config.qbenchCustomerId.MILLS_MaintainanceOperations && (new Date(date) > new Date("06/03/2022") && (new Date(date) < new Date("08/15/2022")))) {
                                        updatedPeriods = [];
                                    }//TIDE Academy (Lower TIDE)
                                    else if (location?.qbenchCustomerId === config.qbenchCustomerId.TIDEAcademy_LowerTIDE && (new Date(date) > new Date("06/03/2022") && (new Date(date) < new Date("09/05/2022")))) {
                                        updatedPeriods = [];
                                    }
                                    else {
                                        // item[`${location?.qbenchCustomerId}`].slotsByDay[`${dayAsNumber}`]
                                        item.slotsByDay[`${dayAsNumber}`]
                                            .map(
                                                (res: any) => {
                                                    updatedPeriods.push({
                                                        ...res,
                                                        startTime: parse(
                                                            res.startTime,
                                                            'h:mmaaaaa',
                                                            new Date()
                                                        ),
                                                        endTime: parse(res.endTime, 'h:mmaaaaa', new Date())
                                                    });
                                                }
                                            );
                                    }
                                }



                            }
                        });
                    }

                } else if (isManage) {
                  

                    //week 01/17 to 01/24
                    let testingWeeks: any = ["01/17/2022", "01/18/2022", "01/19/2022", "01/20/2022", "01/21/2022", "01/22/2022", "01/23/2022"]
                    if (!isWalkUpTest && testSelection === "school" && cPartnerID === "SEQ001" && includes(testingWeeks, format(new Date(date), config.dateFormat).toString())) {
                        if (location?.qbenchCustomerId === config.qbenchCustomerId.WoodSideHighSchool) {
                            const periods = getPeriodsFromSchedule({
                                startTime: '6:00a',
                                endTime: '5:00p',
                                periodDuration: 30,
                                availability: 26
                            });
                            updatedPeriods = periods.slice(2, periods.length);
                        }
                        else if (location?.qbenchCustomerId === config.qbenchCustomerId.MenloAthertonHighSchool) {
                            const periods = getPeriodsFromSchedule({
                                startTime: '6:00a',
                                endTime: '5:00p',
                                periodDuration: 30,
                                availability: 26
                            });
                            updatedPeriods = periods.slice(2, periods.length);
                        } else {
                            const periods = getPeriodsFromSchedule({
                                startTime: '6:00a',
                                endTime: '7:00p',
                                periodDuration: 30,
                                availability: 26
                            });

                            updatedPeriods = periods.slice(2, periods.length);
                        }

                    }
                    else if (location?.qbenchCustomerId === config.qbenchCustomerId.SFO_Airport && (new Date(date) > new Date("04/30/2023"))) {
                        updatedPeriods = [];
                    }
                    else if(location?.qbenchCustomerId === config.qbenchCustomerId.SFOAirport_BARTStation && (new Date(date) > new Date("06/09/2023"))){
                        updatedPeriods=[];
                    }
                    else if (location.qbenchCustomerId === config.qbenchCustomerId.CarlmontHighSchool && date === '04/19/2023') {
                        //Carlmont High School
                        const periods = getPeriodsFromSchedule({
                            startTime: '12:30p',
                            endTime: '4:30p',
                            periodDuration: 30,
                            availability: 13,
                        });

                        updatedPeriods = periods;
                    }
                    else if (location.qbenchCustomerId === config.qbenchCustomerId.MenloAthertonHighSchool && date === '04/19/2023') {
                        //MenloAtherton High School
                        const periods = getPeriodsFromSchedule({
                            startTime: '8:30a',
                            endTime: '11:30a',
                            periodDuration: 30,
                            availability: 13,
                        });

                        updatedPeriods = periods;
                    }
                    else if(!appointment?.isRapidTest && location?.qbenchCustomerId === config?.qbenchCustomerId?.LasVegasSpringValley && (new Date(date) < new Date("12/21/2022"))){
                        updatedPeriods = [];
                    }
                    else if(location?.qbenchCustomerId === config?.qbenchCustomerId?.LasVegas_TheOrleans && (new Date(date) > new Date("12/21/2022"))){
                        updatedPeriods = [];
                    }
                    else if (testSelection === 'flight' && testType === 'antigen' && (destination?.value === 'JPN')) {
                        updatedPeriods = [];
                    }
                    else if(location?.qbenchCustomerId === config.qbenchCustomerId.ProudBird && (new Date(date) >= new Date("11/20/2022"))){
                        updatedPeriods=[];
                    }
                    // sequoia and woodside school start
                    else if (location?.qbenchCustomerId === config.qbenchCustomerId.SequoiaHighSchool && date === '04/26/2022') {
                        //Sequoia High School
                        const periods = getPeriodsFromSchedule({
                            startTime: '9:30a',
                            endTime: '2:30p',
                            periodDuration: 30,
                            availability: 13,
                        });

                        updatedPeriods = periods;
                    } 
                    else if (location.qbenchCustomerId === config.qbenchCustomerId.SequoiaHighSchool && ["02/23/2023", '04/13/2023'].includes(date)) {
                        const periods = getPeriodsFromSchedule({
                            startTime: '09:30a',
                            endTime: '3:00p',
                            periodDuration: 30,
                            availability: 13,
                        });
                        updatedPeriods = periods
                    }

                    //Sequoia slots updated
                    else if(location.qbenchCustomerId === config.qbenchCustomerId.SequoiaHighSchool && (new Date(date).getDay() === 2) && ((new Date(date) > new Date("05/22/2023")) && (new Date(date) < new Date("05/31/2023")))){
                        const periods = getPeriodsFromSchedule({
                            startTime: '11:30a',
                            endTime: '04:30p',
                            periodDuration: 30,
                            availability: 13,
                        });
                    updatedPeriods = periods
                } else if(location.qbenchCustomerId === config.qbenchCustomerId.CarlmontHighSchool &&(new Date(date).getDay() === 1) && ((new Date(date) > new Date("05/22/2023")) && (new Date(date) < new Date("05/31/2023")))){
                    const periods = getPeriodsFromSchedule({
                        startTime: '11:30a',
                        endTime: '04:30p',
                        periodDuration: 30,
                        availability: 13,
                    });

                    updatedPeriods = periods
                } else if(location.qbenchCustomerId === config.qbenchCustomerId.MenloAthertonHighSchool && (new Date(date).getDay() === 5) && ((new Date(date) > new Date("05/22/2023")) && (new Date(date) < new Date("05/31/2023")))){
                    const periods = getPeriodsFromSchedule({
                        startTime: '11:30a',
                        endTime: '04:30p',
                        periodDuration: 30,
                        availability: 13,
                    });

                    updatedPeriods = periods
                } else if(location.qbenchCustomerId === config.qbenchCustomerId.WoodSideHighSchool &&(new Date(date).getDay() === 4) && ((new Date(date) > new Date("05/22/2023")) && (new Date(date) < new Date("05/31/2023")))){
                    const periods = getPeriodsFromSchedule({
                        startTime: '11:30a',
                        endTime: '04:30p',
                        periodDuration: 30,
                        availability: 13,
                    });

                    updatedPeriods = periods
                }
                     else if(location.qbenchCustomerId === config.qbenchCustomerId.SequoiaHighSchool && (new Date(date).getDay() === 2) && (new Date(date) > new Date("06/01/2023"))){
                        const periods = getPeriodsFromSchedule({
                            startTime: '10:00a',
                            endTime: '01:00p',
                            periodDuration: 30,
                            availability: 13,
                        });
                    updatedPeriods = periods
                } else if(location.qbenchCustomerId === config.qbenchCustomerId.CarlmontHighSchool && (new Date(date).getDay() === 1) && (new Date(date) > new Date("06/01/2023"))){
                    const periods = getPeriodsFromSchedule({
                        startTime: '10:00a',
                        endTime: '01:00p',
                        periodDuration: 30,
                        availability: 13,
                    });
                    updatedPeriods = periods
                } else if(location.qbenchCustomerId === config.qbenchCustomerId.MenloAthertonHighSchool && (new Date(date).getDay() === 5) && (new Date(date) > new Date("06/01/2023"))){
                    const periods = getPeriodsFromSchedule({
                        startTime: '10:00a',
                        endTime: '01:00p',
                        periodDuration: 30,
                        availability: 13,
                    });

                    updatedPeriods = periods
                } else if(location.qbenchCustomerId === config.qbenchCustomerId.WoodSideHighSchool && (new Date(date).getDay() === 4) && (new Date(date) > new Date("05/31/2023"))){
                    const periods = getPeriodsFromSchedule({
                        startTime: '10:00a',
                        endTime: '01:00p',
                        periodDuration: 30,
                        availability: 13,
                    });

                    updatedPeriods = periods
                }
                    else if (location?.qbenchCustomerId === config.qbenchCustomerId.WoodSideHighSchool && date === '04/29/2022') {
                        //Woodside High School
                        const periods = getPeriodsFromSchedule({
                            startTime: '9:30a',
                            endTime: '3:30p',
                            periodDuration: 30,
                            availability: 13,
                        });

                        updatedPeriods = periods;
                    }
                    else if (location?.qbenchCustomerId === config.qbenchCustomerId.WoodSideHighSchool && date === '05/06/2022') {
                        //Woodside High School
                        const periods = getPeriodsFromSchedule({
                            startTime: '9:30a',
                            endTime: '5:30p',
                            periodDuration: 30,
                            availability: 13,
                        });
                        updatedPeriods = periods;
                    } // sequoia and woodside school end
                    else if (location?.qbenchCustomerId === config.qbenchCustomerId.SanJose &&
                        (new Date(date) > new Date("04/23/2022"))) {
                        updatedPeriods = [];
                    }
                    else if (location?.qbenchCustomerId === config.qbenchCustomerId.RalstonMiddleSchool &&  (new Date(date).getDay() === 1 || new Date(date).getDay() === 4)) {
                        // Ralston Middle School
                        const periods = getPeriodsFromSchedule({
                            startTime: '2:00p',
                            endTime: '5:00p',
                            periodDuration: 30,
                            availability: 13
                        })
                        updatedPeriods = periods
                    }
                    else if (location?.qbenchCustomerId === config.qbenchCustomerId.WoodSideHighSchool &&
                        date === '04/21/2022') {
                        //Woodside High School
                        const periods = getPeriodsFromSchedule({
                            startTime: '9:30a',
                            endTime: '5:30p',
                            periodDuration: 30,
                            availability: 50,
                        });

                        updatedPeriods = periods;
                    }
                    else if (location?.qbenchCustomerId === config.qbenchCustomerId.CarlmontHighSchool && date === '04/28/2022') {
                        //Carlmont High School
                        const periods = getPeriodsFromSchedule({
                            startTime: '9:30a',
                            endTime: '5:30p',
                            periodDuration: 30,
                            availability: 50,
                        });

                        updatedPeriods = periods;
                    }
                    else if (location?.qbenchCustomerId === config.qbenchCustomerId.SequoiaHighSchool && date === '05/11/2022') {
                        //Sequoia High School
                        const periods = getPeriodsFromSchedule({
                            startTime: '9:30a',
                            endTime: '5:30p',
                            periodDuration: 30,
                            availability: 50,
                        });

                        updatedPeriods = periods;
                    }
                    else if (location?.qbenchCustomerId === config.qbenchCustomerId.EastPaloAltoAcademy &&
                        date === '06/01/2022') {
                        const periods = getPeriodsFromSchedule({
                            startTime: '9:30a',
                            endTime: '5:30p',
                            periodDuration: 30,
                            availability: 22,
                        });
                        updatedPeriods = periods;
                    } else {
                        sharedSlots.map((item: any) => {
                            let testType: any = isWalkUpTest ? 'walkup' : isRapidTest ? "rapid" : (isExpressSameDayTest ? "express" : isAntigen ? "antigen" : "standard");
                            if (item.testType == testType) {
                                if (isWalkUpTest) {
                                    updatedPeriods.push(item[`${location?.qbenchCustomerId}`].slotsByDay[`${dayAsNumber}`])
                                }  //Redwood Shores Elementary Last Day of School 06/16/2022. Closed scheduling throughout the summer
                                else if (location?.qbenchCustomerId === config.qbenchCustomerId.RedwoodShoresElementary && (new Date(date) > new Date("06/16/2022"))) {

                                    updatedPeriods = [];
                                }//South San Francisco School District, The last day of testing for all schools in the district will be 05/27/2022
                                else if (location?.qbenchCustomerId === config.qbenchCustomerId.WestboroughMiddleSchool && (new Date(date) > new Date("05/27/2022"))) {
                                    updatedPeriods = [];
                                }
                                else if (location?.qbenchCustomerId === config.qbenchCustomerId.ElCaminoHighSchool && (new Date(date) > new Date("05/27/2022"))) {
                                    updatedPeriods = [];
                                }
                                else if (location?.qbenchCustomerId === config.qbenchCustomerId.BadenAdultSchool && (new Date(date) > new Date("08/10/2022"))) {
                                    const periods = getPeriodsFromSchedule({
                                        startTime: '7:30a',
                                        endTime: '5:00p',
                                        periodDuration: 30,
                                        availability: 13,
                                    });
                                    updatedPeriods = periods
                                }
                                
                               //Sequoia High School (Carrington Hall):
                                else if (location?.qbenchCustomerId === config.qbenchCustomerId.SequoiaHighSchool && (new Date(date) > new Date("06/06/2022") && (new Date(date) < new Date("08/15/2022")))) {
                                    const periods = getPeriodsFromSchedule({
                                        startTime: '10:00a',
                                        endTime: '4:00p',
                                        periodDuration: 30,
                                        availability: 13,
                                    });

                                    updatedPeriods = periods
                                }// sequoia
                                else if (location.qbenchCustomerId === config.qbenchCustomerId.SequoiaHighSchool && ["12/29/2022", '01/05/2023'].includes(date)) {

                                    const periods = getPeriodsFromSchedule({
                                        startTime: '09:30a',
                                        endTime: '5:30p',
                                        periodDuration: 30,
                                        availability: 13,
                                    });

                                    updatedPeriods = periods
                                }//Kern Health systems
                                else if (location?.qbenchCustomerId === config.qbenchCustomerId.KernHealthSystems && date === '09/06/2022') {
                                    const periods = getPeriodsFromSchedule({
                                        startTime: '8:00a',
                                        endTime: '12:00p',
                                        periodDuration: 15,
                                        availability: 15,
                                    });

                                    updatedPeriods = periods;
                                }
                            //Menlo-Atherton High School
                            else if(location?.qbenchCustomerId === config.qbenchCustomerId.MenloAthertonHighSchool && (new Date(date) > new Date("06/06/2022") && (new Date(date) < new Date("08/15/2022")))){
                                const periods = getPeriodsFromSchedule({
                                    startTime: '10:00a',
                                    endTime: '4:00p',
                                    periodDuration: 30,
                                    availability: 26,
                                });

                                updatedPeriods = periods
                            }   
                            //Carlmont High School
                            else if(location?.qbenchCustomerId === config.qbenchCustomerId.CarlmontHighSchool && (new Date(date) > new Date("06/12/2022") && (new Date(date) < new Date("08/15/2022")))){
                                const periods = getPeriodsFromSchedule({
                                    startTime: '10:00a',
                                    endTime: '4:00p',
                                    periodDuration: 30,
                                    availability: 13,
                                });

                                updatedPeriods = periods
                            }//Woodside High School 
                            else if(location?.qbenchCustomerId === config.qbenchCustomerId.WoodSideHighSchool && (new Date(date) > new Date("06/12/2022") && (new Date(date) < new Date("08/15/2022")))){
                                const periods = getPeriodsFromSchedule({
                                    startTime: '10:00a',
                                    endTime: '4:00p',
                                    periodDuration: 30,
                                    availability: 13,
                                });

                                updatedPeriods = periods
                            }//EAPP
                            else if(location?.qbenchCustomerId === config.qbenchCustomerId.EastPaloAltoAcademy && (new Date(date) > new Date("06/03/2022") && (new Date(date) < new Date("08/08/2022")))){
                                   updatedPeriods=[];
                            }
                            else if(date ==="08/10/2022" && location?.qbenchCustomerId === config.qbenchCustomerId.EastPaloAltoAcademy){
                                    const periods = getPeriodsFromSchedule({
                                        startTime: '10:00a',
                                        endTime: '4:00p',
                                        periodDuration: 30,
                                        availability: 22,
                                    });

                                    updatedPeriods = periods

                                }//Redwood hs 
                                else if (location?.qbenchCustomerId === config.qbenchCustomerId.RedwoodHighSchool && (new Date(date) > new Date("06/03/2022") && (new Date(date) < new Date("08/08/2022")))) {
                                    updatedPeriods = [];
                                }
                                else if (date === "08/10/2022" && location?.qbenchCustomerId === config.qbenchCustomerId.RedwoodHighSchool) {
                                    const periods = getPeriodsFromSchedule({
                                        startTime: '10:00a',
                                        endTime: '4:00p',
                                        periodDuration: 30,
                                        availability: 22,
                                    });

                                    updatedPeriods = periods

                                }// MOT @ Mills
                                else if (location?.qbenchCustomerId === config.qbenchCustomerId.MILLS_MaintainanceOperations && (new Date(date) > new Date("06/03/2022") && (new Date(date) < new Date("08/15/2022")))) {
                                    updatedPeriods = [];
                                }//TIDE Academy (Lower TIDE)
                                else if (location?.qbenchCustomerId === config.qbenchCustomerId.TIDEAcademy_LowerTIDE && (new Date(date) > new Date("06/03/2022") && (new Date(date) < new Date("09/05/2022")))) {
                                    updatedPeriods = [];
                                }
                                else {
                                    item.slotsByDay[`${dayAsNumber}`]
                                        .map((res: any) => {
                                            updatedPeriods.push({
                                                ...res,
                                                startTime: parse(res.startTime, 'h:mmaaaaa', new Date()),
                                                endTime: parse(res.endTime, 'h:mmaaaaa', new Date())
                                            })
                                        })
                                }
                            }

                        })
                    }
                } else if (isAdmin) {

                    adminSlots.map((item: any) => {
                        let testType: any = isWalkUpTest ? 'walkup' : isRapidTest ? "rapid" : (isExpressSameDayTest ? "express" : isAntigen ? "antigen" : "standard")
                        if (item.testType == testType) {
                            if (isWalkUpTest) {
                                updatedPeriods.push(item.slotsByDay[`${dayAsNumber}`])
                            } else {
                                item.slotsByDay[`${dayAsNumber}`]
                                    .map((res: any) => {
                                        updatedPeriods.push({
                                            ...res,
                                            startTime: parse(res.startTime, 'h:mmaaaaa', new Date()),
                                            endTime: parse(res.endTime, 'h:mmaaaaa', new Date())
                                        })
                                    })
                            }
                        }
                    })

                } else {
                    updatedPeriods = []
                }


                try {
                    document?.getElementsByClassName('react-responsive-modal-overlay')?.[0]?.scroll(0,0);
                } catch (error) {
                }
                subscribeOnAppointmentsUpdate(
                    { date, locationId, isRapidTest, isWalkUpTest },
                    (result_1: any) =>
                        getUpdatedSlots(date, locationId, isRapidTest, isWalkUpTest)
                            .then((result: any) => {
                                setSlots(
                                    updatedPeriods.reduce((acc: any, period: any, i: any) => {
                                        const a = acc;
                                        if (isWalkUpTest)
                                            if (period?.available)
                                                return [{ available: parseInt(period?.available) - parseInt(result?.data?.appointmentsPerPeriod ? result?.data?.appointmentsPerPeriod : 0) }];
                                            else
                                                return []
                                        let starttime: any = period.startTime
                                        let endtime: any = period.endTime
                                        var dateNow = new Date()

                                        var startDate: any = location?.startDate


                                        const startTime = set(starttime, {
                                            year: parsed.getFullYear(),
                                            date: parsed.getDate(),
                                            month: parsed.getMonth(),
                                        });

                                        const endTime = set(endtime, {
                                            year: parsed.getFullYear(),
                                            date: parsed.getDate(),
                                            month: parsed.getMonth(),
                                        });

                                        //Check theweek day filter for slot days
                                        let filterWeekDay: any = checkIsWeekDay(startTime);

                                        //Stop Oakland booking
                                        let blockDate: any = new Date('12/24/2021');
                                        let blockLocation: any =
                                            isBefore(blockDate, startTime) &&
                                                location?.qbenchCustomerId === config.qbenchCustomerId.Oakland_HolidayInnExpress
                                                ? false
                                                : true;

                                        // if (!departureDateAndTime && testSelection === 'flight' && blockLocation) {
                                        //     return a;
                                        // }

                                        let DateValidation = location !== null && location?.qbenchCustomerId == config.qbenchCustomerId.SFO_Airport ?
                                            isSameDay(new Date("11/23/2021"), startTime) ? true : false
                                            : false;



                                        if (testSelection === 'general' && location !== null && location?.qbenchCustomerId == config.qbenchCustomerId.SFO_Airport && DateValidation) {
                                            return a;
                                        }

                                        let validateStandard: any = isAfter(new Date(format(new Date(date), config.dateFormat)), new Date(format(new Date(startDate), config.dateFormat))) || isEqual(new Date(format(new Date(date), config.dateFormat)), new Date(format(new Date(startDate), config.dateFormat)));
                                        if ((config.qbenchCustomerId.LasVegasSpringValley === location.qbenchCustomerId ? !validateStandard : false))
                                            return a;

                                        if (departureDateAndTime) {
                                            const parsedDeparture = parse(
                                                departureDateAndTime,
                                                config.dateTimeFormat,
                                                new Date()
                                            );

                                            // let slotIsOutOfAllowedPeriod: any = "";

                                            // if (isRapidTest) {

                                            //     slotIsOutOfAllowedPeriod =
                                            //         isBefore(
                                            //             startTime,
                                            //             subHours(parsedDeparture, 72)
                                            //         ) ||
                                            //         isAfter(
                                            //             startTime,
                                            //             subMinutes(parsedDeparture, 1)
                                            //         );

                                            // } else {

                                            //     slotIsOutOfAllowedPeriod =
                                            //         isBefore(
                                            //             startTime,
                                            //             subHours(parsedDeparture, isExpressSameDayTest ? config.vipSlots.maxAdvanceHours : config.maxAdvanceHours)
                                            //         ) ||
                                            //         isAfter(
                                            //             startTime,
                                            //             subHours(parsedDeparture, isExpressSameDayTest ? config.vipSlots.minAdvanceHours : config.minAdvanceHours)
                                            //         );

                                            // }

                                            // if (slotIsOutOfAllowedPeriod) {
                                            //     return a;
                                            // }

                                            //Same day booking validation
                                            let validateStandard: any = isBefore(dateNow, startTime);
                                            // if (location?.qbenchCustomerId === config.qbenchCustomerId.SFO_Airport) {
                                            //     validateStandard = (isBefore(dateNow, startTime) && !isSameDay(new Date(), startTime));
                                            // }
                                            // else if (!isExpressSameDayTest && !isRapidTest) {
                                            //     validateStandard = (isBefore(dateNow, startTime) && !isSameDay(new Date(), startTime));
                                            // }

                                            //Action for block out days
                                            let DateValidation = location?.blockOutDays !== undefined
                                                ? includes(location?.blockOutDays, format(startTime, config.dateFormat))
                                                    ? false
                                                    : true
                                                : true;

                                            if (validateStandard && isBefore(dateNow, addMinutes(startTime, 15)) && DateValidation && blockLocation) {

                                                if (isAfter(parsedDeparture, new Date(startDate)) && (isAfter(parsed, new Date(startDate)) || isEqual(parsed, new Date(startDate)))) {
                                                    //For SFO - airport change to 15 for only travel flow

                                                    // if (location?.qbenchCustomerId === config.qbenchCustomerId.SFO_Airport && isAfter(parsed, new Date("01/07/2022"))) {
                                                    //     a.push({
                                                    //         ...period,
                                                    //         startTime,
                                                    //         index: period.index,
                                                    //         available: manageNegativeValue(15 - ((result && result.data.appointmentsPerPeriod?.[period.index]) || 0))
                                                    //     });
                                                    // } else {
                                                    a.push({
                                                        ...period,
                                                        startTime,
                                                        index: period.index,
                                                        available: manageNegativeValue(period.available - ((result && result.data.appointmentsPerPeriod?.[period.index]) || 0))
                                                    });
                                                    // }
                                                }
                                            }
                                        } else {


                                            //Add the filter 
                                            let alloWDayFilter: any = testSelection !== "general" ? filterWeekDay : true
                                            let blockOutDaysValidation = location?.blockOutDays !== undefined
                                                ? cPartnerID === "SSF001" && includes([config.qbenchCustomerId.BadenAdultSchool,
                                                config.qbenchCustomerId.ElCaminoHighSchool,
                                                config.qbenchCustomerId.WestboroughMiddleSchool], location?.qbenchCustomerId) && includes(["01/28/2022", "01/29/2022", "01/30/2022"], format(new Date(startTime), config.dateFormat))
                                                    ? true
                                                    : includes(location?.blockOutDays, format(startTime, config.dateFormat))
                                                        ? false
                                                        : true
                                                : true;

                                            // Sameday booking removed for the location in general flow
                                            if (testSelection === 'general' && includes(config.pauseSameDayBooking.locations, locationId) && blockOutDaysValidation && blockLocation) {

                                                if (isBefore(new Date(), parsed)) {

                                                    a.push({
                                                        ...period,
                                                        startTime,
                                                        index: period.index,
                                                        available: manageNegativeValue(period.available - ((result && result.data.appointmentsPerPeriod?.[period.index]) || 0)),
                                                    });
                                                }

                                            } else if (
                                                ((testSelection === 'community' && (location.qbenchCustomerId === config.qbenchCustomerId.SouthLosAngeles || location.qbenchCustomerId === config.qbenchCustomerId.Brooklyn) && i === updatedPeriods.length - 1)
                                                    ? isBefore(dateNow, addMinutes(startTime, 29))
                                                    : location?.name === 'Boys and Girls Clubs of the Peninsula' && i === updatedPeriods.length - 1 && isBefore(new Date(location?.startDate), dateNow) || isEqual(new Date(location?.startDate), dateNow))
                                                    ? isBefore(dateNow, addMinutes(startTime, 29))
                                                    : cPartnerID === "PCMA001"
                                                        ? isBefore(dateNow, endTime) && alloWDayFilter && blockOutDaysValidation && blockLocation
                                                        : isBefore(dateNow, startTime) && alloWDayFilter && blockOutDaysValidation && blockLocation
                                            ) {

                                                if (testSelection !== 'flight' && (isAfter(parsed, new Date(startDate)) || isEqual(parsed, new Date(startDate)))) {
                                                    let datesforSFO = ['12/31/2021', '01/01/2022', '01/02/2022', '01/03/2022', '01/04/2022', '01/05/2022', '01/06/2022', '01/07/2022']
                                                    if (appointment.testSelection === "sfoairport" && datesforSFO.includes(appointment.date ? appointment.date : '')) {
                                                        a.push({
                                                            ...period,
                                                            startTime,
                                                            index: period.index,
                                                            available: manageNegativeValue(5 - ((result && result.data.appointmentsPerPeriod?.[period.index]) || 0)),
                                                        });
                                                    } else {
                                                        a.push({
                                                            ...period,
                                                            startTime,
                                                            index: period.index,
                                                            available: manageNegativeValue(period.available - ((result && result.data.appointmentsPerPeriod?.[period.index]) || 0)),
                                                        });
                                                    }
                                                } else {
                                                    a.push({
                                                        ...period,
                                                        startTime,
                                                        index: period.index,
                                                        available: manageNegativeValue(period.available - ((result && result.data.appointmentsPerPeriod?.[period.index]) || 0)),
                                                    });
                                                }
                                            }
                                            else {
                                                return a;
                                            }
                                        }
                                        return a;
                                    }, [])
                                );

                                setTimeout(() => {
                                    setSlotsLoading(false);
                                }, 2000);

                            }).catch((error: any) => {
                                setSlotsLoading(false);
                            })
                );
                // await new Promise<void>((resolve) => {
                //     setTimeout(() => {
                //         resolve();
                //     }, 1000);
                // });
            } else {
                // setSlots([]);
            }

            // setSlotsLoading(false);
        } catch (e) {
            console.error(e);
            setSlotsLoading(false);
        }
    }

    // useImperativeHandle(ref, () => ({
    //     InitiateSlots() {
    //         getSlots();
    //     }
    // }));

    const getNewAPI = async () => {
        updateBlockOutDays([])
        if (location?.partnerName !== 'goHealth' && appointment?.location?.qbenchCustomerId === config.qbenchCustomerId.SFOAirport_BARTStation && appointment?.payingMethod === 'Insurance' && (testSelection === 'flight' || testSelection === 'general')) {
            const response = await getNewSlots({
                testType: 'antigen',
                locationId: appointment.location?.qbenchCustomerId
            })
            updateSlotsList(response.data);
            updateSharedSlots(response.data);
            updateAdminSlots(response.data);
            let arr: Number[] = []
            const update = response.data ?? []
            for (let i = 0; i < 7; i++) {
                update[update.length - 1 ?? 0]?.slotsByDay[i]?.length !== 0 && arr.push(i)
            }
            localStorage.setItem('blockOutDays', JSON.stringify(arr))
            updateBlockOutDays(arr)
        } else {
            const response = await getNewSlots({
                testType: isWalkUpTest ? 'walkup' : isRapidTest ? 'rapid' : isExpressSameDayTest ? 'express' : isAntigen ? 'antigen' : 'standard',
                locationId:location?.partnerName === 'goHealth' ? appointment?.location?.hospitalId : appointment.location?.qbenchCustomerId
            })
            updateSlotsList(response.data);
            updateSharedSlots(response.data);
            updateAdminSlots(response.data);
            let arr: Number[] = []
            const update = response.data ?? []
            for (let i = 0; i < 7; i++) {
                update[update.length - 1 ?? 0]?.slotsByDay[i]?.length !== 0 && arr.push(i)
            }
            localStorage.setItem('blockOutDays', JSON.stringify(arr))
            updateBlockOutDays(arr)
        }
    }
    useEffect(() => {
        localStorage.setItem('blockOutDays', JSON.stringify([]))
        getNewAPI()
    }, [isExpressSameDayTest, location, locationId, isRapidTest, isAntigen])

    const emptySlots =() => {
        if(rebooking.active && slots.length===32){
            for (let i = 0; i < slots.length; i++) {
                const element = slots[i];
                if(element.available==0){
                    setSlotCount((prev:any)=> prev +1)
                }
            }
        }else {
            setSlotCount(0)
        }
    }
    useEffect(() => {
        emptySlots()
    }, [date,slots])

    useEffect(() => {
        let unsubscribe: any;

        if (!!testSelection &&
            // testSelection !== 'flight' &&
            date === null) {
            setSlotsLoading(true);
            onFieldUpdate({ date: format(new Date(), config.dateFormat) })
        }
        //Validate the persons count and make the customer select the slots again
        async function updateReserveData() {
            setSlotsLoading(true);
            updateFormValues({
                reservationId: "",
                slot: undefined
            })
            setSlotsLoading(false);
        }

        if (reservationId !== undefined && reservationId !== "" && reservationCount !== numberOfGuests) {
            updateReserveData();
        }

        setSelectedDate(isWalkUpTest ? new Date() : date ? parse(date, config.dateFormat, new Date()) : new Date());
        getSlots();

        // new Promise<void>((resolve) => {
        //     setTimeout(() => {
        //         getSlots();
        //     }, 2000);
        // });

        // return () => {
        //     if (unsubscribe) {
        //         getSlots();
        //     }
        // };
    }, [date, departureDateAndTime, isExpressSameDayTest, location, locationId, isRapidTest, isAntigen, reservationId, slotsList, sharedSlots, adminSlots, isWalkUpTest]);

    // blocking next day after some time period
    let bufferDate: any[] = []
    let currentTime = new Date();
    let Hour = currentTime.getHours()
    let nextDay = format(addDays(currentTime, 1), config.dateFormat).toString()
    let blockOutDayTime: any = location?.blockNextDayAfter ? location?.blockNextDayAfter?.replace(/[^0-9]/g, '') : ''
    if (blockOutDayTime !== '' && location?.blockNextDayAfter?.endsWith('p')) {
        blockOutDayTime = parseInt(blockOutDayTime) + 12;
        if (Hour >= blockOutDayTime)
            if (!(location?.blockOutDays !== undefined && location?.blockOutDays?.length !== 0 && location?.blockOutDays.includes(nextDay))) {
                location?.blockOutDays.push(nextDay)
                let bufferdate = [];
                bufferdate.push(nextDay)
                bufferDate = bufferdate;
            }
    }
    else if (blockOutDayTime !== '' && location?.blockNextDayAfter?.endsWith('a')) {
        blockOutDayTime = parseInt(blockOutDayTime);
        if (Hour >= blockOutDayTime)
            if (!(location?.blockOutDays !== undefined && location?.blockOutDays?.length !== 0 && location?.blockOutDays.includes(nextDay))) {
                location?.blockOutDays.push(nextDay)
                let bufferdate = [];
                bufferdate.push(nextDay)
                bufferDate = bufferdate;
            }
    }
    else {
        bufferDate = [];
    }
    // ends blocking next day
    const infiniteYear = () => addYears(new Date("01/01/2220"), 100)
    return (
        <Field name="slot">
            {({
                form: { setFieldValue, setFieldError, values },
                field: { value },
                meta: { error },
            }: FieldProps) => {
                const parsedDeparture = departureDateAndTime
                    ? parse(departureDateAndTime, config.dateTimeFormat, new Date())
                    : new Date();

                return (
                    <Container>
                        <Header>
                            <SelectedDate>
                                {format(selectedDate, config.weekDateFormat)}
                            </SelectedDate>
                            <DatePickerContainer alwaysShowDatePicker={alwaysShowDatePicker}>
                                {!!testSelection ?
                                    <Datepicker
                                        value={isWalkUpTest ? new Date() : selectedDate}
                                        minDate={new Date()}
                                        maxDate={isWalkUpTest ? new Date() : infiniteYear()}
                                        // minDate={(location?.qbenchCustomerId===238||location?.qbenchCustomerId===237)?new Date(location?.startDate):new Date()}
                                        onChange={(newDepartureDate) => {
                                            if (!isWalkUpTest) {
                                                setSelectedDate(newDepartureDate);
                                                updateFormValues({
                                                    date: format(newDepartureDate, config.dateFormat),
                                                });
                                                if (onChangeDate) {
                                                    onChangeDate(newDepartureDate);
                                                }
                                            }
                                        }}
                                        isDayFilter={
                                            testSelection !== 'general'
                                                ? location?.isDayFilter !== undefined
                                                    ? location?.isDayFilter
                                                    : false
                                                : false
                                        }
                                        isWeeklyFlag={
                                            location?.isWeeklyFlag !== undefined
                                                ?
                                                blockOutDays !== undefined
                                                    ?
                                                    blockOutDays.length < 7
                                                        ?
                                                        true
                                                        :
                                                        location?.isWeeklyFlag
                                                    :
                                                    location?.isWeeklyFlag
                                                :
                                                location?.isWeeklyFlag
                                        }
                                        weeklyVisible={{
                                            weeklyVisibleDays: blockOutDays,
                                            weeklyVisibleNumber:
                                                location?.weeklyVisibleNumber !==
                                                    undefined
                                                    ? location?.weeklyVisibleNumber
                                                    : {
                                                        weekDay: 0,
                                                        weekNumber: 0,
                                                    }
                                        }
                                        }
                                        isblockOutDays={
                                            location?.blockOutDays !==
                                                undefined
                                                ? location?.blockOutDays
                                                : bufferDate
                                        }
                                        cPartnerID={location?.cPartnerID}
                                        location={location}
                                        customInput={<ChangeDateBtn>Change date</ChangeDateBtn>}
                                    /> :
                                    <Datepicker
                                        value={selectedDate}
                                        maxDate={isWalkUpTest ? new Date() : getMaxAvailableDate(
                                            location,
                                            isExpressSameDayTest,
                                            parsedDeparture,
                                            isRapidTest
                                        )}
                                        // minDate={(location?.qbenchCustomerId===238||location?.qbenchCustomerId===237)?new Date(location?.startDate):getMinAvailableDate(
                                        //     location,
                                        //     isExpressSameDayTest,
                                        //     parsedDeparture,
                                        //     isRapidTest
                                        // )}
                                        minDate={getMinAvailableDate(
                                            location,
                                            isExpressSameDayTest,
                                            parsedDeparture,
                                            isRapidTest
                                        )}
                                        onChange={(newDepartureDate) => {
                                            if (!isWalkUpTest) {
                                                setSelectedDate(newDepartureDate);
                                                updateFormValues({
                                                    date: format(newDepartureDate, config.dateFormat),
                                                });
                                                if (onChangeDate) {
                                                    onChangeDate(newDepartureDate);
                                                }
                                            }
                                        }}
                                        cPartnerID={location?.cPartnerID}
                                        customInput={<ChangeDateBtn>Change date</ChangeDateBtn>}
                                        location={location}
                                    />}
                            </DatePickerContainer>
                        </Header>
                        <SlotsContainer>
                            {/* <div>{slots.length > 0 ? format(new Date(slots[0].startTime), config.dateFormat) : ""}</div> */}
                            {!isWalkUpTest ? slots.length > 0 && slotCount !==32 ? (
                                slots.map(({ label, startTime, available, index, time }: { label: any, startTime: any, available: any, index: any, time: any }, i: any) => {
                                    const preSelectedSlotDate = slot?.date
                                        ? parse(slot.date, config.dateFormat, new Date())
                                        : null;

                                    const isSelected =
                                        !!preSelectedSlotDate &&
                                        isEqual(selectedDate, preSelectedSlotDate) &&
                                        (location?.partnerName === 'goHealth' || locationId === slot?.locationId) &&
                                        index === value?.period;


                                    return (
                                        <Slot key={i}>

                                            <ChooseSlotBtn
                                                selected={isSelected}
                                                type={location?.partnerName === 'goHealth' ? "button" : "submit"}
                                                onClick={async () => {

                                                    if (isSelected || !location) {
                                                        return;
                                                    }
                                                    const desiredSlot = {
                                                        date: format(selectedDate, config.dateFormat),
                                                        period: index,
                                                        locationId: location?.qbenchCustomerId,
                                                        label: label,
                                                        time: time
                                                    };
                                                    if(location?.partnerName === 'goHealth'){
                                                        onFieldUpdate({ slot: desiredSlot });
                                                        setFieldValue('slot', desiredSlot);
                                                        setSlotsLoading(true);
                                                        setTimeout(() => {
                                                            setSlotsLoading(false);
                                                        }, 1000)
                                                    }

                                                    localStorage.setItem("desiredSlot", JSON.stringify(desiredSlot))

                                                    appointment.slot = desiredSlot;

                                                    onFieldUpdate({ slot: desiredSlot });
                                                    setFieldValue('slot', desiredSlot);

                                                    setSlotsLoading(true);
                                                    await reserveSlot(
                                                        desiredSlot,
                                                        slot,
                                                        numberOfGuests,
                                                        isRapidTest,
                                                        reservationId,
                                                        false,
                                                        appointment,
                                                        isWalkUpTest
                                                    ).then((res) => {
                                                        if (res.data.status == 200) {
                                                            updateFormValues({
                                                                reservationId: res.data.reservationId,
                                                                reservationCount: res.data.numberOfGuests
                                                            })
                                                        }
                                                        setSlotsLoading(false);
                                                        if (isNextStep) goToNextStep();
                                                    }).catch((e) => {
                                                        // setFieldError('slot', e.message) 
                                                    });
                                                }}
                                                disabled={
                                                    available === 0 ||
                                                    available < numberOfGuests ||
                                                    isSlotsLoading
                                                }
                                            >
                                                {label}
                                            </ChooseSlotBtn>
                                            {isSlotsLoading ? (
                                                <Shimmer />
                                            ) : (
                                                <><span className='slotAvailable' style={{ margin: '0px 5px' }}>{available}</span><span style={{ margin: '0px 0px' }}>{pluralize(available, ' slot')} available</span></>
                                            )}
                                        </Slot>
                                    );
                                })
                            ) : (
                                <>No available slots</>
                            ) : slots.length > 0 ? slots.map((availableSlots: any, key: any) => {
                                if (key > 0)
                                    return;
                                return (
                                    <Slot key={key}>
                                        <ChooseSlotBtn
                                            selected={slot ? true : false}
                                            type={location?.partnerName === 'goHealth'? "button" : isManage ? "button" : "submit"}
                                            onClick={async () => {
                                                if (slot || !location) {
                                                    return;
                                                }

                                                const desiredSlot = {
                                                    date: format(selectedDate, config.dateFormat),
                                                    locationId: location?.qbenchCustomerId,
                                                    label: format(new Date(), 'p'),
                                                    period: timeToPeriod(format(new Date(date ? date : ''), 'p'), appointment.isRapidTest).index
                                                };
                                                localStorage.setItem(
                                                    'desiredSlot',
                                                    JSON.stringify(desiredSlot)
                                                );

                                                appointment.slot = desiredSlot;

                                                onFieldUpdate({ slot: desiredSlot });
                                                setFieldValue('slot', desiredSlot);

                                                setSlotsLoading(true);
                                                await reserveSlot(
                                                    desiredSlot,
                                                    slot,
                                                    numberOfGuests,
                                                    isRapidTest,
                                                    reservationId,
                                                    false,
                                                    appointment,
                                                    isWalkUpTest
                                                )
                                                    .then((res) => {
                                                        if (res.data.status == 200) {
                                                            updateFormValues({
                                                                reservationId: res.data.reservationId,
                                                                reservationCount: res.data.numberOfGuests,
                                                            });
                                                            if (isManage) {
                                                                onFieldUpdate({
                                                                    reservationId: res.data.reservationId,
                                                                    reservationCount: res.data.numberOfGuests,
                                                                });
                                                            }
                                                        }
                                                        setSlotsLoading(false);
                                                        if (isNextStep) goToNextStep();
                                                    })
                                                    .catch((e) => {
                                                        // setFieldError('slot', e.message)
                                                    });
                                            }}
                                            disabled={isSlotsLoading}
                                        >
                                            Walk Up
                                        </ChooseSlotBtn>
                                        {isSlotsLoading ? (
                                            <Shimmer />
                                        ) : (
                                            <>
                                                <span><span className='slotsAvailable'>{availableSlots.available}</span>{pluralize(availableSlots.available, ' slot')} available</span>
                                            </>
                                        )}
                                    </Slot>
                                )
                            }) : (
                                <>No available slots</>
                            )}
                        </SlotsContainer>
                        <Error>
                            <ErrorText hasError={error !== undefined}>{error}</ErrorText>
                        </Error>
                    </Container>
                );
            }}
        </Field>
    );
};
export default Slots;