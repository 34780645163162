import React from 'react';
import styled from 'styled-components';
import { IoPersonOutline } from 'react-icons/io5';
import { FiLogOut } from 'react-icons/fi';
import { GoChecklist } from 'react-icons/go';
import { GiTestTubes, GiWireframeGlobe } from 'react-icons/gi';
import { BiSupport } from 'react-icons/bi';
import { RiBillLine } from 'react-icons/ri';
import { IoIosCompass } from 'react-icons/io';

import { FaFileInvoiceDollar, FaRegAddressBook, FaStethoscope } from 'react-icons/fa';
import { VscAccount } from 'react-icons/vsc';
import { colors } from '../../../../styles/colors';
import {
  BrowserRouter as Router,
  useHistory,
  Switch,
  Route,
  Link,
  NavLink,
} from 'react-router-dom';
import { Breakpoints } from '../../../../dictionaries';
import { useSharedDispatch, useSharedState } from '../../../shared/provider';
import { List } from '@material-ui/core';
import { MdDescription } from 'react-icons/md';
import {AiOutlineMedicineBox} from 'react-icons/ai';
import { TiPlusOutline } from 'react-icons/ti';

const SidenavContainer = styled.div`
  display: block;
  @media (max-width: ${Breakpoints.sm}px) {
    width: 100%;
  }
`;

const SideNavBar = styled.div`
  float: left;
  position: fixed;
  border: 1px solid;
  background-color: ${colors.rebrandBlack};
  width: 96px;
  height: 100%;
  transition: 0.4s ease;
  z-index: 2;

  .menu-txt {
    display: none;
  }

  .booking-txt {
    display: none;
    color:black;
  }

  .welcome-txt {
    display: none;
  }

  &:hover {
    width: 250px;
    white-space: nowrap;

    .menu-txt {
      display: block;
    }

    .welcome-ic {
      diplay: none;
    }

    .booking-txt {
      display: block;
      margin-left: 20px;
    }

    .welcome-txt {
      display: block;
      text-overflow: ellipsis;
      max-width: 220px;
      white-space: nowrap;
      overflow: hidden;
    }

    overflow: auto;
  }

  @media (max-width: ${Breakpoints.sm}px) {
    width: 15%;
    margin-top: 54px;
    font-size: 16px;
    height: 100%;
    overflow: hidden;
    &:hover {
      width: 60%;
      white-space: nowrap;
    }
  }
`;

const SideContent = styled.div`
  color: ${colors.white};
  margin: 80px 0px 0px 0px;
  padding: 6px 0px 0px 0px;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  ul {
    list-style-type: none;
  }
  @media (max-width: ${Breakpoints.sm}px) {
    margin: 10px 0px;
  }
`;
const Welcome = styled.div`
  margin-top: 0px;
  margin-bottom: 20px;
  text-align: center;
  padding: 0px 15px;
`;
const ButtonTag = styled.div`
  padding: 0px 15px;
  @media (max-width: ${Breakpoints.sm}px) {
    padding: 0px 5px;
  }
`;

const SideButton = styled.div`
  margin-top: 20px;
  margin-bottom: 42px;
  align-items: center;
  text-align: center;
  justify-content: center;
  border: 1px solid ${colors.rebrandOrange};
  border-radius: 32px;
  padding: 16px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  background-color: ${colors.rebrandOrange};
  color: ${colors.white};
  &:hover {
    background: #FAC21A;
  }
  &:focus {
    background: linear-gradient(0deg, rgba(250, 194, 26, 0.55), rgba(250, 194, 26, 0.55)), #FF802E;
  }
  &:active {
    background-color: #FF802E ;
  }
  @media (max-width: ${Breakpoints.sm}px) {
    padding: 15px 5px;
  }
`;

const Lists = styled.div`
  display: grid;
  gap: 42px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  @media (max-width: ${Breakpoints.sm}px) {
    gap: 10px;
  }
`;
const ListItems = styled.div`
  &:hover {
    a,
    div {
      color: ${colors.rebrandYellow};
    }
    .menu-txt {
      color: ${colors.rebrandYellow};
    }
  }
  a:hover {
    color: ${colors.rebrandYellow};
  }

`;
const LinkItems = styled(NavLink)`
  &:hover {
    div {
      color: ${colors.rebrandYellow};
    }
  }
  a:hover {
    color: ${colors.rebrandYellow};
  }
  &.selected {
    .menu-txt,
    .icon {
      color: ${colors.rebrandYellow};
    }
    color: ${colors.rebrandYellow};
  }

`;
const ListElement = styled.div`
  display: inline-flex;
  align-items: center;
  position: relative;
  text-align: center;
  color: ${colors.white};
  padding: 2px 50px;
  &:hover {
    color: ${colors.rebrandYellow};

    .menu-txt {
      color: ${colors.rebrandYellow}
    }

`;

const Support = styled.a`
  display: inline-flex;
  align-items: center;
  position: relative;
  text-align: center;
  padding: 4px 50px;
  color: ${colors.white};
  a:hover {
    color: ${colors.rebrandYellow};
    .menu-txt {
      color: ${colors.rebrandYellow};
    }
  }
`;
const Space = styled.div`
  margin-top: 80px;
  @media (max-width: ${Breakpoints.sm}px) {
    margin-top: 40px;
  }
`;

const Text = styled.div`
  color: white;
  font-size: 16px;
  font-weight: 500;
  margin-left: 20px;
`;
const Span = styled.div``;

const SidenavCopy: React.FC = () => {
  const { setloggedUserData, updateVaccinated, updateAirlineType, updateTravelType, updateVaccineType, updateDestination, updateReasonType,  setApptFlow, updateapptFromSchdule, setIsVerifyReq, updateAddress } = useSharedDispatch();
  let history = useHistory();
  const { userData, tenant } = useSharedState();
  const redirect = () => {
    setApptFlow(true);
    updateapptFromSchdule(false);
    history.push('/bookappointment');
  };
  const signin = () => {
    const Tenant = tenant;
    setIsVerifyReq(false);
    setloggedUserData(null);
    updateVaccinated('');
    updateAirlineType('');
    updateTravelType('');
    updateVaccineType('');
    updateDestination(null);
    updateReasonType('');
    localStorage.clear();
    if(Tenant?.toLocaleLowerCase() === 'kern')
    {
        history.push('/?tenant=kern');
    }
    else{
        history.push('/signin');
    }
  };

  return (
    <SidenavContainer>
      <SideNavBar>
        <SideContent>
          <Welcome>
            {' '}
            <VscAccount className="welcome-ic" size={22} color="white" />{' '}
            <span className="welcome-txt">
              Welcome Back, {userData?.firstName} !
            </span>
          </Welcome>
          <div style={{ borderTop: '1px solid #fff',margin:'auto 14px ' }}></div>
          <ButtonTag>
            <SideButton onClick={()=>{
              if(!userData?.address?.country || !userData?.address?.state){
                localStorage.setItem('isAddress',JSON.stringify(false))
                updateAddress(false)
              } else {
                localStorage.setItem('isAddress',JSON.stringify(true))
                updateAddress(true)
                redirect();
              }
            }}>
              {' '}
              <FaRegAddressBook
                className="booking-ic"
                size={22}
                color="white"
              />{' '}
              <span className="booking-txt">Book Appointment</span>{' '}
            </SideButton>
          </ButtonTag>
          <Lists>
            <LinkItems to="/user/profile" activeClassName="selected">
              <ListElement>
                <IoPersonOutline size={22} className="icon" />
                <Text className="menu-txt">My Profile</Text>
              </ListElement>
            </LinkItems>
            <LinkItems to="/user/appointments" activeClassName="selected">
              <ListElement>
                <FaStethoscope size={22} className="icon" />
                <Text className="menu-txt">Appointments</Text>
              </ListElement>
            </LinkItems>
            <LinkItems to="/user/results" activeClassName="selected">
              <ListElement>
                <GoChecklist size={22} className="icon" />
                <Text className="menu-txt">Results</Text>
              </ListElement>
            </LinkItems>
            <LinkItems to="/user/insurance" activeClassName="selected">
              <ListElement>
                <TiPlusOutline size={22} className="icon" />
                <Text className="menu-txt">Insurance</Text>
              </ListElement>
            </LinkItems>
            {/* <LinkItems to="/user/healthpassport" activeClassName="selected">
              <ListElement>
                <GiWireframeGlobe size={25} className="icon" />
                <Text className="menu-txt">Health Passports</Text>
              </ListElement>
            </LinkItems> */}
            {/* <LinkItems to="/user/destinationguide" activeClassName="selected">
              <ListElement>
                <IoIosCompass size={25} className="icon" />
                <Text className="menu-txt">Destination Guide</Text>
              </ListElement>
            </LinkItems> */}
            {/* <LinkItems to="/user/telemedicine" activeClassName="selected">
              <ListElement>
                <AiOutlineMedicineBox size={25} className="icon" />
                <Text className="menu-txt">Tele Medicine</Text>
              </ListElement>
            </LinkItems> */}
            <ListItems>
              <Support href="https://support.worksitelabs.com" target="_blank">
                <BiSupport size={22} />
                <Text className="menu-txt">Support</Text>
              </Support>
            </ListItems>
            <ListItems>
              <ListElement onClick={signin}>
                <FiLogOut size={22} />
                <Text className="menu-txt">Sign out</Text>
              </ListElement>
            </ListItems>
          </Lists>
        </SideContent>
      </SideNavBar>
    </SidenavContainer>
  );
};

export default SidenavCopy;
