import { ButtonProps } from 'antd';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import goHealthlogo from '../../../src/assets/goHealthlogo.png'
import { useStepsDispatch, useStepsState } from '../schedule/provider';
import { Breakpoints } from '../../dictionaries';
import GoogleAnalytics from '../../GoogleAnalytics';


const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding-top: 20px;
  @media (min-width: 450px) {
    flex-direction: column;
  }
`;
export const SubTitle = styled.div`
    color: '#FFF';
    font-size: 28px;
    font-weight: 700;
    margin: 0;
    text-align: left;
    width: 520px;

    @media (max-width: ${Breakpoints.sm}px) {
        font-size: 25px;
        margin-top: 30%;
    }
`;
export const Button = styled.button`
 display: flex;
 justify-content: center;
 align-items:center;
 background:#FF802E;
 border:none;
    width:200px;
    height:40px;
    font-size:14px ;
    line-height: 20px;
    border-radius: 32px;
    /* font-weight: 600; */
    color: #101010;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    @media (max-width: ${Breakpoints.sm}px) {
        display: flex;
        justify-content: start;
        align-items: center;
        text-align: center;
        font-size: 16px;}
`;

const Space = styled.div`
  height: 20px;
  width: 20px;
`;

interface Props {
    onReturnButtonClick?: () => void;
    isValid?: any;
    confirmBtnText?: string;
    loading?: boolean;
    onClick?: () => void;
    //   disabled?:boolean;
}

const GoHealthCompleteButton: React.FC<Props> = ({
    onReturnButtonClick,
    isValid,
    confirmBtnText,
    loading,
    onClick
}) => {
    const [Location, setLocation] = useState<{ isActive: boolean, location: any, loading: boolean }>({ isActive: false, location: '', loading: false })
    let history = useHistory()
    const {form} = useStepsState();
    const { goToPrevStep, toggleShowAlertModal } = useStepsDispatch();
    let handleGohealth = (data: any) => {
        if (data === 'Complete Registration') {
            GoogleAnalytics(form, 'GoHealth')
            // window.location.href = form?.GoHealthRegistration_link;
            window.open(form?.GoHealthRegistration_link, '_blank');
        }
    }

    return (
        <>
            <Container>
                <SubTitle>Finish Completing Registration</SubTitle>
                <Space />
                <img src={goHealthlogo} />
                <Space />
                <h3 style={{color:'#D85500'}}> Complete the registration on our <u>partner website</u>.</h3>
                <Space />
                <h3 style={{ fontWeight: '100' }}>Your test will be booked with Dignity GoHealth. Please be aware that the cost of this test may differ and is determined by Dignity GoHealth.</h3>
                <Space />
                <Button
                    style={{fontWeight:'600'}}
                    type="button"
                    onClick={() =>
                        handleGohealth('Complete Registration')
                    }
                >
                    Complete Registration
                </Button>
            </Container>
        </>
    );
};

export default GoHealthCompleteButton;
