
import React, { useEffect, useState } from 'react'
import {
    Alert,
    Button,
    Card,
    Col,
    Form,
    Input,
    Layout,
    Row,
    Space,
    Select,
    Table,
    Typography,
} from 'antd';
import { styles } from '../../components/AppointmentList/styles';
import {
    appointmentCheckingTable,
    formItemLayout,
    searchBarActionLayout,
} from '../../components/AppointmentList/constants';
import AntDDatePicker from '../../../shared/components/AntDDatePicker';
import { format } from 'date-fns';
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import { config } from '../../../../config';
import DownloadCSV from '../../components/AppointmentList/components/DownloadCSV';
import { useAdminDispatch, useAdminState } from '../../provider';
import './style.css';
import { useSharedState } from '../../../shared/provider';
import { checkInCrelioAppointment, getAppointmentCheckinList, getAppointmentdata } from '../../api';
import EditAppointment from '../../components/AppointmentList/components/EditAppointment';
import { AppointmentListItem } from '../../models';
import { getSlotsByLocationId } from '../../../shared/api';

const { Title } = Typography;
const { Content } = Layout;
const { Option } = Select;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({

        odd: {
            background: "#a6d0e373",
            "&:hover": {
                "& > td": {
                    backgroundColor: "#a6d0e373 !important",
                    "&:last-child": {
                        backgroundColor: "#f8f9fa !important",
                    }
                }
            },
        },

        even: {
            background: "#f8f9fa",
            "&:hover": {
                "& > td": {
                    backgroundColor: "#f8f9fa !important",
                }
            },

        },

    }),
);




const AppointmentsCheckin: React.FC = () => {

    const classes = useStyles();
    const { locations } = useSharedState();
    const [loading, setLoading] = useState(false);
    const [expand, setExpand] = useState(false);
    const { checkinParams } = useAdminState();
    const { updateCheckinParams } = useAdminDispatch();
    const [searchKeys, setSearchKeys] = useState({
        firstName: '',
        lastName: '',
        confirmationId: '',
        phone: '',
        birthDate: '',
        email: '',
        date: format(new Date(), config.dateFormat),
        locationQbenchId: null,
    });
    const [appointmentCheckinList, setAppointmentsCheckinList] = useState<any[]>([]);

    const {
      setEditAppointment,
      updateAdminSlots,
    } = useAdminDispatch();
    const [ShowEditAppointmentModal, setShowEditAppointmentModal] = useState(
      false
    );

    const handleEditAppointment1 = () => {
      setShowEditAppointmentModal(false);
    };

    const handleSearchKeysUpdate = (key: string) => (value: string | number) => {
        setSearchKeys({
            ...searchKeys,
            [key]: value,
        });
    };
    const handleSearch = () => {
        updateCheckinParams(searchKeys);
    };

    const renderCheckinActionButton = (text: any, record: any, index: any) => {
        return (
            <Button disabled={record.billId ? true : false} type="primary" onClick={() => handleEditAppointment(record)} >{record.billId ? 'Already Checked In' : 'Check In'}</Button>
        )
    }

    const handleEditAppointment = (record: any) => {
      setLoading(true);
      getAppointmentdata({ confirmationId: record.confirmationId })
        .then(async (response: any) => {
          if (response.data.length > 0) {
            const periods: any = await getSlotsByLocationId(
              response.data[0].location?.qbenchCustomerId
            );
            setEditAppointment(response.data);
            updateAdminSlots(periods);

            setShowEditAppointmentModal(true);
            setLoading(false);
            return true;
          } else {
            setLoading(false);
            return true;
          }
        })
        .catch(() => {});
    };

    useEffect(() => {
      if (searchKeys.confirmationId || searchKeys.locationQbenchId) {
        /* Location based filter & confirmation id based filter handled on the back end */
        setLoading(true);
        getAppointmentCheckinList(searchKeys).then(({ data }) => {
          let appointmentList: any = [];
          data.map((appointment: any, index: number) => {
            appointmentList.push({ ...appointment, key: index });
          });
          setAppointmentsCheckinList(appointmentList);
          setLoading(false);
        });
      }
    }, [checkinParams, setAppointmentsCheckinList]);


    return (
        <div className="Appoitments-checkin-page">{ShowEditAppointmentModal} {!ShowEditAppointmentModal ? 
            <Layout className="appointment-layout">
                <div className="appointment-checkin-title-wrapper">
                    <Title level={4} className="appointment-checkin-title">Appointments Check In</Title>
                </div>
                <Content style={styles.content}>
                    <Space direction="vertical" style={styles.spaceWidth} size="middle">
                        <Card bodyStyle={styles.card}>
                            <Form {...formItemLayout}>
                                <Row gutter={12}>
                                    <Col span={8}>
                                        <Form.Item label="Date" name="date">
                                            <AntDDatePicker
                                                style={styles.fullWidth}
                                                disabled={loading}
                                                defaultValue={new Date()}
                                                showToday
                                                onChange={(newDate) =>
                                                    handleSearchKeysUpdate('date')(
                                                        format(newDate!, config.dateFormat)
                                                    )
                                                }
                                                allowClear={false}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item label="Location" name="location">
                                            <Select
                                                disabled={loading}
                                                allowClear
                                                placeholder="Please select"
                                                showArrow
                                                onChange={handleSearchKeysUpdate('locationQbenchId')}
                                            >
                                                {
                                                    locations?.map((location) => {
                                                        if (location.limsinfo?.type === "crelio") {
                                                            return <Option
                                                                key={location.qbenchCustomerId}
                                                                value={location.qbenchCustomerId}
                                                            >
                                                                {location.name}
                                                            </Option>
                                                        }
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item label="Confirmation No" name="confirmationId">
                                            <Input
                                                allowClear
                                                disabled={loading}
                                                onChange={(event) =>
                                                    handleSearchKeysUpdate('confirmationId')(
                                                        event.target.value
                                                    )
                                                }
                                            />
                                        </Form.Item>
                                    </Col>
                                    {expand && (
                                        <>
                                            <Col span={8}>
                                                <Form.Item label="First Name" name="firstName">
                                                    <Input
                                                        disabled={loading}
                                                        allowClear
                                                        onChange={(event) =>
                                                            handleSearchKeysUpdate('firstName')(
                                                                event.target.value
                                                            )
                                                        }
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                                <Form.Item label="Last Name" name="lastName">
                                                    <Input
                                                        disabled={loading}
                                                        allowClear
                                                        onChange={(event) =>
                                                            handleSearchKeysUpdate('lastName')(
                                                                event.target.value
                                                            )
                                                        }
                                                    />
                                                </Form.Item>
                                            </Col>

                                            <Col span={8}>
                                                <Form.Item label="Email" name="email">
                                                    <Input
                                                        disabled={loading}
                                                        allowClear
                                                        onChange={(event) =>
                                                            handleSearchKeysUpdate('email')(event.target.value)
                                                        }
                                                    />
                                                </Form.Item>
                                            </Col>

                                            <Col span={8}>
                                                <Form.Item label="Phone" name="phone">
                                                    <Input
                                                        allowClear
                                                        disabled={loading}
                                                        onChange={(event) =>
                                                            handleSearchKeysUpdate('phone')(event.target.value)
                                                        }
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                                <Form.Item label="Date of birth" name="birthDate">
                                                    <AntDDatePicker
                                                        style={styles.fullWidth}
                                                        allowClear
                                                        disabled={loading}
                                                        showToday
                                                        onChange={(newDate) =>
                                                            handleSearchKeysUpdate('birthDate')(
                                                                newDate ? format(newDate!, config.dateFormat) : ''
                                                            )
                                                        }
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </>
                                    )}

                                    <Col span={8} style={{ marginLeft: expand ? "0%" : "65%" }}>
                                        <Form.Item {...searchBarActionLayout}>
                                            <Space size="small" style={styles.searchBarActions}>
                                                <Button
                                                    type="primary"
                                                    loading={loading}
                                                    onClick={handleSearch}
                                                >
                                                    Search
                      </Button>
                                                <Button
                                                    type="link"
                                                    onClick={() => {
                                                        setExpand(!expand);
                                                    }}
                                                >
                                                    {expand ? (
                                                        <>
                                                            Less <UpOutlined />
                                                        </>
                                                    ) : (
                                                        <>
                                                            More <DownOutlined />
                                                        </>
                                                    )}
                                                </Button>
                                            </Space>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Card>

                        <Card>
                        {(!checkinParams.confirmationId && !checkinParams.locationQbenchId) && (
                            <Alert
                                message="Please select a location or Enter ConfirmationNo"
                                type="warning"
                                showIcon
                                closable
                            />
                        )}
                            <DownloadCSV />
                            <Table
                                size="small"
                                columns={appointmentCheckingTable(renderCheckinActionButton) as any}
                                dataSource={(appointmentCheckinList as any) || []}
                                loading={loading}
                                rowKey={(record: any) => record.key}
                                rowClassName={(record: any) => record.billId ? classes.odd : classes.even}
                            />
                        </Card>
                    </Space>
                </Content>
            </Layout>
        :
        <div>
          <EditAppointment appointment={EditAppointment} handleDrawerToggle={handleEditAppointment1} apptType={true}/>
        </div>
      }
        </div>

    )
}

export default AppointmentsCheckin;
