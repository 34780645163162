import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Form, Formik } from 'formik';

import {
  Content,
  SubHeaderDescription,
  PageTitle,
  LogoCard, LogoImage
} from '../../shared/components/styled';
import { colors } from '../../../styles/colors';
import { useStepsState, useRegisterStepsDispatch } from '../provider';
import Input from '../../shared/components/form/Input';
import FormLabel from '../../shared/components/form/FormLabel';
import Header from '../../shared/components/Header';
import Loader from '../../shared/components/Loader';
import Footer from '../../shared/components/Footer';
import { Breakpoints } from '../../../dictionaries';
import wsllogo from '../../../assets/WLABS_CMYK.png';
import hallogo from '../../../assets/hal_logo.png';
import orglogo from '../../../assets/org_logo.png';
import Publiclogo from '../../../assets/public_logo.png';
import groublogo from '../../../assets/groub_logo.png';
import { Button } from '../../shared/components/styled';
import { checkEmailAddress, checkemail } from '../api';
import { useHistory } from 'react-router-dom';
import { useSharedDispatch, useSharedState } from '../../shared/provider';
import { registerLogin } from '../api';
import ConfirmAccount from './ConfirmAccount';
import { Background, Div, FooterDiv, MainContainer, Stripe,Container } from './SignIn';
import wslLogo from '../../../assets/WSL_Color_Horizontal.jpg';
import circleRectAngle from '../../../assets/circle-rectangle-dark-blue.png';
import circleRectAngleBottom from '../../../assets/circle-rectangle-dark-blue-bottom.png';
import wLogo from '../../../assets/wsl-w-only.png';
const Error = styled.div`
  color: ${colors.red};
  margin-top: 14px;
  text-align: center;
`;
const PageHeader = styled.div`
  text-align: center;
`;

export const Logos = styled.div`
  display: flex;
`;

export const WLLogo = styled.div`
  height: 60px;
  width: 270px;
  background: url(${wsllogo}) no-repeat center center;
  background-size: cover;
  margin-bottom: 15px;
  display: inline-block;

  @media (max-width: ${Breakpoints.sm}px) {
    margin-top: 20px;
  }
`;

export const Logos1 = styled.div`
  position: absolute;
  width: 601px;
  height: 161px;
  right: 432px;
  bottom: 518px;

  border: 1px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15));
  border-radius: 10px;
`;

export const Haldiv = styled.div`
  position: absolute;
  width: 120px;
  height: 120px;
  left: 616px;
  top: 408px;

  border: 1px solid rgb(235 227 227 / 50%);
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
  border-radius: 10px;
`;

export const HALLogo = styled.div`
  height: 18px;
  width: 118px;
  background: url(${hallogo}) no-repeat center center;
  background-size: cover;
  display: inline-block;
  margin: -20px 0px 20px 0px;

  @media (min-width: ${Breakpoints.sm}px) {
    height: 100px;
    width: 300px;
  }
`;
export const Text = styled.div`
  position: absolute;
  width: 521px;
  height: 44px;
  left: 447px;
  top: 180px;

  font-family: Open Sans;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 44px;
  text-align: center;
  padding-right: 68px;
  color: #000000;
`;

export const SubText = styled.div`
  font-family: Open Sans;
  margin: 5px 0px 0px 0px;
  font-size: 20px;
  color: ${colors.blueLight};
  cursor: pointer;
`;

const CatogryFlexContainer = styled.p`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
`;

export const Orglogo = styled.div`
  height: 18px;
  width: 118px;
  background: url(${orglogo}) no-repeat center center;
  background-size: cover;
  display: inline-block;
  @media (min-width: ${Breakpoints.sm}px) {
    height: 80px;
    width: 85px;
  }
`;

export const CategoryContentBox = styled.div`
  flex: 0.3 1 0%;
  justify-content: center;
  border-radius: 10px;
  border: 2px solid ${colors.lightGrey};
  box-sizing: border-box;
  padding: 10px 0px 10px 0px;
  margin: 0px 5px 0px 5px;
  cursor: pointer;
`;

export const PublicLogo = styled.div`
  height: 18px;
  width: 118px;
  background: url(${Publiclogo}) no-repeat center center;
  background-size: cover;
  display: inline-block;
  @media (min-width: ${Breakpoints.sm}px) {
    height: 80px;
    width: 85px;
  }
`;

export const GroupLogo = styled.div`
  height: 18px;
  width: 118px;
  background: url(${groublogo}) no-repeat center center;
  background-size: cover;
  display: inline-block;
  @media (min-width: ${Breakpoints.sm}px) {
    height: 79px;
    width: 97px;
  }
`;

export const CategoyTitle = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;

  color: ${colors.blueLight};
  margin-top: 3px;
`;

const CTAButton = styled.button`
  display: inline-block;
  width: 85%;
  color: ${colors.white};
  text-align: center;
  padding: 15px;
  background: ${colors.orange};
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  border: 0;
  font-size: 20px;
  line-height: 15px;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
`;

const Space = styled.div`
  margin-bottom: 26px;
  @media (max-width: ${Breakpoints.sm}px) {
    margin-bottom: 25px;
  }
`;
const WLogoImage = styled.img`
    @media (max-width: ${Breakpoints.md}px) {
        display: none
    }
`;
interface Props {
  isValid?: boolean;
  loading?: boolean;
  onClick?: () => void;
}

const Register: React.FC<Props> = ({ isValid, onClick }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const {
    form: { email, password, confirmPassword, postCode },
  } = useStepsState();
  const { updateFormValues, goToNextStep } = useRegisterStepsDispatch();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  const [login, setLogin] = useState(false);

  const [clicking, setClicking] = useState(false);
  localStorage.setItem('location', '');
  const [isLoginScreen, setLoginScreen] = useState(false);
  const [isVerifyScreen, setVerifyScreen] = useState(false);
  const [isVerifyScreenMsg, setVerifyScreenMsg] = useState(false);

  const isValidZIP = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

  const [validLength, setValidLength] = useState(false);
  const [upperCase, setUpperCase] = useState(false);
  const [lowerCase, setLowerCase] = useState(false);
  const [specialChar, setSpecialChar] = useState(false);
  const [requiredLength, setRequiredLength] = useState(8);
  const history = useHistory();
  const { setloggedUserData } = useSharedDispatch();
  const {
    userData,
    partnerLogo: { logo },
  } = useSharedState();

  const handleCreateRegister = async () => {
    if (!clicking) {
      const data: any = {
        email: email,
        password: password,
        postCode: postCode,
      };

      try {
        setLoading(true);
        const registerData = await checkEmailAddress(data);
        if (registerData.data.status === 200) {
          if (isLoginScreen) {
            goToNextStep();
          } else {
            setError('Incorrect Password Details');
          }
        } else {
          setClicking(false);
          setError(registerData?.data?.message);
        }
        setLoading(false);
      } catch (e) {
        setClicking(false);
        setError('Incorrect details');
        setLoading(false);
      }
    }
  };
  const verifyEmail = async (email: string) => {
    let error;
    if (email && email.trim() && typeof email !== 'undefined') {
      email = email.trim();
      var pattern = new RegExp(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i
      );

      if (!pattern.test(email)) {
        error = 'Please enter valid email address';
      }
    } else {
      error = 'Required field';
    }
    setLoading(false);
    return error;
  };

  const validatePassword = (password: string) => {
    let error: string = '';
    setError(error);
    if (password) {
      setValidLength(password.length >= requiredLength ? true : false);
      setUpperCase(password.toLowerCase() !== password);
      setLowerCase(password.toUpperCase() !== password);
      setSpecialChar(/[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(password));
      if (validLength && upperCase && lowerCase && specialChar) {
        setLoginScreen(true);
      } else {
        error =
          '8 character minimum, 1 uppercase, one lowercase, one special character';
        setLoginScreen(false);
      }
    } else {
      setLoginScreen(false);
    }

    return error;
  };
  const validateConfirmPassword = (
    password: string,
    confirmPassword: string
  ) => {
    let error: string = '';
    setError(error);

    if (password && confirmPassword) {
      if (password !== confirmPassword) {
        error = 'Password not matched';
        setLoginScreen(false);
      } else {
        validatePassword(confirmPassword);
      }
    } else {
      setLoginScreen(false);
    }
    return error;
  };
  const redirect = () => {
    history.push('/user/profile');
  };

  const handleSignIn = async (params: { email: string; password: string }) => {
    setLoginLoading(true);

    console.log('handleSignIn', params);

    try {
      const loggedUser = await registerLogin(params);
      if (loggedUser?.data?.status == 200) {
        setloggedUserData(loggedUser?.data.data);
        localStorage.setItem('user', JSON.stringify(userData));
        redirect();
      } else {
        if (
          loggedUser?.data?.message === 'Please verify your account to login.'
        ) {
          setError(loggedUser?.data?.message);
          updateFormValues({ phone: loggedUser?.data?.data?.phone });
          setVerifyScreenMsg(true);
        } else {
          setError(loggedUser?.data?.message);
        }
      }
      setLoginLoading(false);
    } catch (e) {
      setError('Incorrect email or password');
      setLoginLoading(false);
    }
  };

  return (
    <div>
      {!isVerifyScreen ? (
        <>
        <Header withoutBg isHelp />
        <Div>
        <Stripe />
        <Background>
            <div style={{display:'flex', justifyContent:'center'}}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'absolute',
                        height: '100vh',
                        width: '100vh',
                        transform: 'translate(-46%)',
                        zIndex: 0,
                    }}
                    >
                    <WLogoImage src={wLogo} alt="" />
                </div>
                <MainContainer>
                <Container
                >
                  <Content
                    style={{
                      textAlign: 'center',
                      padding: '10px 30px 50px 30px',
                    }}
                  >
                    <div style={{ width: '86%', display: 'inline-block' }}>
                      <LogoCard>
                        <LogoImage src={logo.length ? logo : ''} />
                      </LogoCard>
                      <PageHeader
                        className="page-title"
                        style={{ marginBottom: '1px' }}
                      >
                        <PageTitle color={colors.fullBlack} textAlign="center">
                          Create Your Account
                        </PageTitle>
                      </PageHeader>
                      <SubHeaderDescription>
                        Your one-stop shop to manage your COVID-19 testing. Book
                        and manage appointments. Track results. Pay online.
                      </SubHeaderDescription>
                      <Formik
                        initialValues={{
                          email,
                          password,
                          confirmPassword,
                          postCode,
                        }}
                        onSubmit={(values) => {
                          if (login) {
                            handleSignIn({
                              ...values,
                              email: values.email.toLowerCase().trim(),
                            });
                          } else {
                            updateFormValues({
                              ...values,
                              email: values.email.toLowerCase().trim(),
                            });
                            handleCreateRegister();
                            setClicking(true);
                          }
                        }}
                        enableReinitialize={false}
                      >
                        {() => (
                          <Form>
                            <Input
                              name="email"
                              placeholder="Email"
                              isRequired
                              onChange={(value) =>
                                updateFormValues({
                                  email: value.toLowerCase().trim(),
                                })
                              }
                              validate={(value) => verifyEmail(value)}
                              noBottomMargin
                            />
                            <Space />
                            <Input
                              name="password"
                              placeholder="Password"
                              isRequired
                              type="password"
                              onChange={(value) =>
                                updateFormValues({ password: value })
                              }
                              validate={(value) => {
                                let error;

                                if (!value) {
                                  return (error = 'Required field');
                                } else {
                                  return validatePassword(value);
                                }
                              }}
                              noBottomMargin
                            />
                            <br/>
                            <SubHeaderDescription style={{ fontSize: '12px' }}>
                              *8 character minimum, 1 uppercase, one lowercase,
                              one special character
                            </SubHeaderDescription>
                            <Input
                              name="confirmPassword"
                              placeholder="Confirm Password"
                              isRequired
                              type="password"
                              validate={(value) => {
                                let error;
                                if (!value && !login) {
                                  return (error = 'Required field');
                                } else {
                                  return validateConfirmPassword(
                                    password,
                                    value
                                  );
                                }
                              }}
                              onChange={(value) =>
                                updateFormValues({ confirmPassword: value })
                              }
                              noBottomMargin
                            />
                            <Space />
                            {error && (
                              <Error>
                                {error}
                                <br />
                                {isVerifyScreenMsg && (
                                  <a onClick={() => setVerifyScreen(true)}>
                                    Click here
                                  </a>
                                )}{' '}
                                {isVerifyScreenMsg && 'to verify your account.'}
                              </Error>
                            )}
                            <Button
                              libraryType="primary"
                              type="submit"
                              className="btn fill-btn"
                              onClick={() => {
                                setError('');
                                setLogin(false);
                                setVerifyScreenMsg(false);
                              }}
                            >
                              {loading ? 'Processing...' : 'Continue'}
                            </Button>
                            <Space />
                            <Button
                              libraryType="primary"
                              type="submit"
                              className="btn fill-btn"
                              onClick={() => {
                                setError('');
                                setLogin(true);
                                setVerifyScreenMsg(false);
                              }}
                            >
                              {loginLoading ? 'Processing...' : 'Sign In'}
                            </Button>
                            <Space />
                            <div style={{ margin: '15px 0px 0px 0px' }}>
                              <SubText
                                onClick={() => {
                                  history.push('/forgotpassword');
                                }}
                                style={{ color: '#243D4D' }}
                                className="underline"
                              >
                                Forgot Email or Password?
                              </SubText>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </Content>
                </Container>
                </MainContainer>
            </div>
        </Background>
        <FooterDiv>
            <Footer />
        </FooterDiv>
    </Div>
        </>
      ) : (
        <ConfirmAccount isVerfied={isVerifyScreen} />
      )}
    </div>
  );
};

export default Register;

//ltst