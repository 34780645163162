
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { Breakpoints } from '../../../dictionaries';
import { colors } from '../../../styles/colors';
import { useStepsState, useStepsDispatch } from '../../schedule/provider';
import { Button } from '../../shared/components/styled';
import { Formik, Form } from 'formik';
import background from '../../../assets/WSL-Business.jpg';
import { useSharedState, useSharedDispatch } from '../../shared/provider';
import flightLogo from '../../../assets/COVID-Testing-for-Air-Travelers.png';
import workLogo from '../../../assets/Covid-testing-for-work.png'
import cruiseLogo from '../../../assets/COVID-Testing-for-Cruises.png';
import eventLogo from '../../../assets/COVID-Testing-for-Events.png';
import testLogo from '../../../assets/COVID-Testing.png';
import clia from '../../../assets/CLIA-Certified.png';
import tickLogo from '../../../assets/Worksite-Labs-arrow.png';
import schoolLogo from '../../../assets/Testing-for-school.png';
import communityLogo from '../../../assets/Testing-for-community.png';
import { getCpartnerLogo } from './../../register/api';
import refersion from '../../../refersion';
import { config } from '../../../config';
import GoogleAnalytics from '../../../GoogleAnalytics';
import { getpath } from '../../../utils';
import circleRectAngleBottom from '../../../assets/circle+rectangle-teal-bottom.png';
import Footer from '../../shared/components/Footer';
import newFlightLogo from '../../../assets/newFlightLogo.png';
import newCommunityLogo from '../../../assets/newCommunityLogo.png';
import newGeneralLogo from '../../../assets/newGeneralLogo.png';
import newWorkFlowLogo from '../../../assets/newWorkFlowLogo.png';
import newSchoolLogo from '../../../assets/newSchoolLogo.png';
import circleRectAngle from '../../../assets/circle-rectangle-dark-blue.png';
import wLogo from '../../../assets/wsl-w-only.png';

export const Container = styled.div`
    // background-color: ${colors.blueLight};
    // background-image: url(${background});
    width: 100%;
    height: 100%;
    // margin-top: 56px;
    // background-size: cover;
    // background-position: top center;
    // background-attachment: fixed;
    // background-repeat: no-repeat;
    overflow: scroll;
    @media (max-width: ${Breakpoints.sm}px) {
        margin-top: 54px;
        overflow: auto;
      }
`;
const Background = styled.div`
    background-image: url(${circleRectAngle});
    position: absolute;
    width: 100vw;
    height: 92.2vh;
    z-index: 1;
    background-repeat: no-repeat;
    background-size: 55% 140%;
    background-position: right;
    // background-attachment: fixed;

    @media (max-width: 1280px) {
        background-position: bottom;
        background-image: url(${circleRectAngleBottom});
        background-size: 100% 12%;
        overflow-y: scroll;
        overflow-x: hidden;
        height: 120vh;
    }
    @media (max-width: 960px) {
        background-size: 100% 8%;
        height: 170vh;
    }
    @media (max-width: 600px) {
        background-size: 117% 7%;
        height: 300vh;
    }
`
const Div = styled.div`
    position: absolute;
    bottom: 0px;
    width: 100vw;
    height: 92.2vh;
    overflow:hidden;
    @media (max-width: 1280px) {
        overflow-y: scroll;
        overflow-x: hidden;
    }
`
export const Content = styled.div`
  padding: 40px 60px;
  padding-bottom: 0px;
  @media (max-width: ${Breakpoints.sm}px) {
    padding: 20px 30px;
  }
`;
export const PageHeader = styled.h2`
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  line-height: 50px;
  color: #000000;
  text-align: left;
  margin-right:32px;
  @media (max-width: ${Breakpoints.sm}px) {
    font-size: 26px;
    padding: 0px 10px;
    font-weight: 600 !important;
    line-height: 35px;
  }
`;
export const SubContent = styled.div`
  margin-top: 30px;
  display: grid;
  justify-items: center;
  align-items: center;
  grid-template-columns: auto auto auto auto auto;
  grid-gap: 1rem;
  @media (max-width: ${Breakpoints.xl}px) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
  }
  @media (max-width: ${Breakpoints.md}px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: ${Breakpoints.sm}px) {
    grid-template-columns: repeat(1, 1fr);
    padding: 2rem;
  }
`;
export const List = styled.div`
  display: flex;
  opacity: ${({ disabled }: { disabled?: boolean }) => (disabled ? 0.5 : 1)};
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  box-shadow: -4px -4px 8px rgb(153 153 153 / 5%), 4px 4px 8px rgb(0 0 0 / 15%);
  border-radius: 15px;
  padding: 14px;
  position: relative;
  :hover {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.45), rgba(255, 255, 255, 0.45)), #FAC21A;
  }
  @media (max-width: ${Breakpoints.lg}px) {
    margin: auto 0%;
  }
  @media (max-width: ${Breakpoints.sm}px) {
    :hover {
      background: none;
    }
  }
`;
export const SubHeader = styled.h4`
  color: black !important;
  font-weight: 600;
  text-align: center;
  font-size: 17px;
  padding: 20px;
`;
export const SubHeaders = styled.h4`
  color: black;
  font-weight: 600;
  text-align: center;
  font-size: 17px;
  padding: 20px;
  padding-bottom:9px;
  text-transform: uppercase;
  width:130%;
  @media (max-width: ${Breakpoints.sm}px) {
    padding: 20px;
  }
`;
export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Description = styled.div`
  width: 100%;
  color: black !important;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  margin: 20px 0px;
`;
export const Descriptions = styled.div`
  color: black;
  width: 100%;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  margin: 10px 0px;
  @media (max-width: ${Breakpoints.sm}px) {
    width:100%;
    margin: 20px 0px;
  }
`;
const FlightLogo = styled.div`
  height: 100px;
  width: 100px;
  background-color: #FCCA46 !important;
  border-radius: 999px;
  background: url(${newFlightLogo}) no-repeat center center;
  background-size: cover;
  @media (max-width: ${Breakpoints.sm}px) {
    margin: 0;
  }
`;
const WorkLogo = styled.div`
    height: 100px;
    width: 100px;
    background: url(${newWorkFlowLogo}) no-repeat center center;
    background-size: cover;
    background-color: #FCCA46 !important;
    border-radius: 999px;
    @media (max-width: ${Breakpoints.sm}px) {
        margin: 0;
    }
`;
const CuriseLogo = styled.div`
  height: 100px;
  width: 100px;
  background: url(${cruiseLogo}) no-repeat center center;
  background-size: cover;
  @media (max-width: ${Breakpoints.sm}px) {
    margin: 0;
  }
`;
const EventLogo = styled.div`
  height: 100px;
  width: 100px;
  background: url(${eventLogo}) no-repeat center center;
  background-size: cover;
  @media (max-width: ${Breakpoints.sm}px) {
    margin: 0;
  }
`;
const TestLogo = styled.div`
  height: 100px;
  width: 100px;
  background-color: #FCCA46 !important;
  border-radius: 999px;
  background: url(${newGeneralLogo}) no-repeat center center;
  background-size: cover;
  @media (max-width: ${Breakpoints.sm}px) {
    margin: 0;
  }
`;
const CliaLogo = styled.div`
  height: 44px;
  width: 100px;
  background: url(${clia}) no-repeat center center;
  background-size: cover;
  @media (max-width: ${Breakpoints.sm}px) {
    margin: 0;
  }
`;
const TickLogo = styled.div`
  height: 50px;
  width: 50px;
  background: url(${tickLogo}) no-repeat center center;
  background-size: cover;
  @media (max-width: ${Breakpoints.sm}px) {
    height: 30px;
    width: 100px;
  }
`;
const SchoolLogo = styled.div`
  height: 100px;
  width: 100px;
  background-color: #FCCA46 !important;
  border-radius: 999px;
  background: url(${newSchoolLogo}) no-repeat center center;
  background-size: cover;
  @media (max-width: ${Breakpoints.sm}px) {
    margin: 0;
  }
`;
const CommunityLogo = styled.div`
  height: 55px;
  width: 55px;
  background-color: #FCCA46 !important;
  border-radius: 999px;
  background: url(${newCommunityLogo}) no-repeat center center;
  //background-size: contain;
  @media (max-width: ${Breakpoints.sm}px) {
    margin: 0;
  }
`;

const Radius = styled.div`
  background-color:#FCCA46 !important;
  border: 6.7px solid #F7953B;
  border-radius:50%;
  padding:13px;
`;
export const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
`;
export const ListContent = styled.div`
  margin-top: 30px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding: 40px;
  height:12%;
  padding-bottom: 30px;
  gap: 20px;
  background-color: ${colors.white};
   position:absolute;
   bottom:0;
  width:100%;
  @media (max-width: ${Breakpoints.sm}px) {
    padding: 20px;
    position: relative;
  }
`;

export const ListTittle = styled.div`
  display: flex;
  justify-content:center;
  align-items: center;
  color: #ffff;
  font-size: 18px;
  font-weight: 500;
  color:${colors.black}
`;
export const ULPoint = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: column;
  @media (max-width: ${Breakpoints.sm}px) {
    gap: 20px;
  }
`;
export const ListPoint = styled.h2`
  display: flex;
  align-items: center;
  color: #ffff;
  font-size: 14px;
  font-weight: 500;
  gap: 30px;
  
  color:${colors.black};
  @media (max-width: ${Breakpoints.sm}px) {
    gap: 20px;
  }
`;
const Stripe = styled.div`
position: absolute;
width: 100vw;
height: 6.5%;
background-color: rgb(97, 156, 138);
`;
const LOGO = styled.img`
  width: auto;
  height: 6.5vh;
  @media (max-width: 1280px) {
    display: none;
  }
`                                             
const LOGO1 = styled.img`
display: none;
@media (max-width: 1280px) {
    display:block;
    width: auto;
    height: 6.5vh;
  }
`
const FooterDiv = styled.div`
    position: absolute;
    width: 100vw;
    z-index: 11111;
    bottom: 0px;
    background-color: rgb(36, 61, 77);
    height: 40px;
    @media (max-width: 960px) {
        height: 40px;
        position: fixed;
    }
    @media (max-width: 600px) {
        height: 70px;
        position: fixed;
    }
`    

const btnStyle = {
    background: '#FF802E',
      fontSize: "14px",
      fontWeight:'600',
      width: "auto",
      bottom:14,
      color:'white',
      border:'none'
  };
const TestSelection: React.FC = () => {

  const { form } = useStepsState();
  const { testSelection, cPartnerID, location } = form;
  const { goToNextStep, updateFormValues, clearFormValues } = useStepsDispatch();
  const [walkupLocation, setWalkupLocation] = useState<any>({});
  const { isRefersion, isWalkUp, locations, userData, tenant } = useSharedState();
  let history = useHistory();
  const { updateDestination, updateTravelType, updateAirlineType, updateReasonType, updateVaccinated, updateVaccineType, updateDistrict,updatePartnerLogo, updateTenant, setApptFlow } = useSharedDispatch();

  useEffect(()=>{
    const path = getpath()
    if(testSelection==='kern' || path ===config.qbenchCustomerId.KernHealthSystems.toString() || tenant==='kern'){
        goToNextStep();
        updateFormValues({cPartnerID:'KEN001'})
        console.log("testSelection",testSelection, path)
    }
    updateFormValues({ isHubSpotContact: userData?.isHubSpotContact });
   },[])
  useEffect(() => {
    let location: any = locations?.filter(
        (location: any) => location.qbenchCustomerId === isWalkUp.locationId
      )[0];
      setWalkupLocation(location);
      if(location?.cPartnerID==='KEN001'){
        goToNextStep()
    }

    if (isWalkUp.active && userData) {
        setApptFlow(true);
    }
    if(config.pauseBooking.locations.includes(isWalkUp?.locationId)){
        isWalkUp.cPartnerIdArray=[]
    }
    GoogleAnalytics(form,'/TestSelection');
    refersion('cus_KZgrO2Kefc6E1q');
  }, [isWalkUp, setApptFlow, userData])

   // logoupdate
   const fetchCpartnerlogo = async (params: string ) => {
    try {
      //const input = params?.length ? params : 'WSL001'
      // const input =  params === 'SSFSD' ? 'SSF001' : params === 'BRSSD' ? 'BEL001' : params === 'SUHSD' ? 'SEQ001' : params === 'COM001' ? 'COM001':'WSL001' ;
      const data = await getCpartnerLogo(params);
      if (data?.data?.status === 200) {
          updatePartnerLogo({
            logo: data?.data?.data.logo,
            logoHeader: data?.data?.data.logoHeader 
          })
        }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <>
      <Div>
        <Stripe />
        <Background>
         <div className='test' style={{marginTop:'6.5vh'}}>
         <Formik
            enableReinitialize
            initialValues={{
              testSelection,
            }}
            onSubmit={(values) => {
              updateFormValues({
                testSelection: values.testSelection,
                isWalkUpTest: isWalkUp.active,
              });
              if (history.location.pathname === '/') {
                let data = { testSelection: values.testSelection };
                localStorage.setItem('testSelectionData', JSON.stringify(data));
                localStorage.setItem('testSelection', 'true');
                history.push('/signin');
              } else {
                goToNextStep();
              }
            }}
          >
            {() => (
              <Form>
                <div style={{ display: 'flex', margin: '30px 0' }}>
                  <PageHeader>
                    {'HANDLE ALL YOUR COVID-19 TESTING NEEDS HERE'}
                  </PageHeader>
                  <LOGO src={wLogo} alt=""  />
                </div>
                <Grid
                  style={{padding:'0px 10px'}}
                  container
                  spacing={4}
                  alignItems="center"
                  justifyContent="space-evenly"
                >
                  {isRefersion ? (
                    <Grid item xs={12} lg={2} md={4} sm={6}>
                      <List
                        disabled={isWalkUp.active && isWalkUp.cPartnerIdArray !== null
                            ? !isWalkUp.cPartnerIdArray.includes(
                                  'WSL001'
                              )
                            : false}
                      >
                        <SubHeader>{'TRAVELING BY AIR'}</SubHeader>
                        <Icon>
                          <FlightLogo />
                        </Icon>
                        <Description>
                          {
                            'Pre-travel testing for international and domestic jet setters'
                          }
                        </Description>
                        <Button
                          disabled={isWalkUp.active &&
                            isWalkUp.cPartnerIdArray !==
                                null
                                ? !isWalkUp.cPartnerIdArray.includes(
                                      'WSL001'
                                  )
                                : false}
                          type="submit"
                          libraryType="default"
                          style={btnStyle}
                          onClick={() => {
                            clearFormValues();
                            updateFormValues({ testSelection: 'flight' });
                            localStorage.setItem('reason', '');
                            updateReasonType('');
                            localStorage.setItem('vactype', '');
                            updateVaccineType('');
                            localStorage.setItem('isvac', '');
                            updateVaccinated('');
                            localStorage.setItem('dist', '');
                            updateDistrict('');
                          }}
                          className="btn fill-btn"
                        >
                          {'BOOK NOW'}
                        </Button>
                      </List>
                    </Grid>
                  ) : (
                    <>
                      <Grid item xs={12} lg={2} md={4} sm={6}>
                        <List
                          disabled={isWalkUp.active &&
                            isWalkUp.cPartnerIdArray !==
                                null
                                ? !isWalkUp.cPartnerIdArray.includes(
                                        'WSL001'
                                    )
                                : false}
                        >
                          <SubHeader>{'TRAVELING BY AIR'}</SubHeader>
                          <Icon>
                            <FlightLogo />
                          </Icon>
                          <Description style={{ margin: '12px 0px 14px' }}>
                            {
                              'Pre-travel testing for international and domestic jet setters'
                            }
                          </Description>
                          <Button
                            type="submit"
                            libraryType="default"
                            style={btnStyle}
                            className="btn fill-btn"
                            disabled={
                                isWalkUp.active &&
                                isWalkUp.cPartnerIdArray !==
                                    null
                                    ? !isWalkUp.cPartnerIdArray.includes(
                                          'WSL001'
                                      )
                                    : false
                            }
                            onClick={() => {
                              // clearFormValues();
                              fetchCpartnerlogo('WSL001');
                              updateFormValues({ testSelection: 'flight' });
                              localStorage.setItem('reason', '');
                              updateReasonType('');
                              localStorage.setItem('vactype', '');
                              updateVaccineType('');
                              localStorage.setItem('isvac', '');
                              updateVaccinated('');
                              localStorage.setItem('dist', '');
                              updateDistrict('');
                              updateTenant('flight');
                            }}
                          >
                            {'BOOK NOW'}
                          </Button>
                        </List>
                      </Grid>
                      <Grid item xs={12} lg={2} md={4} sm={6}>
                        <List
                          disabled={
                            isWalkUp.active &&
                            isWalkUp.cPartnerIdArray !==
                                null
                                ? !(
                                    isWalkUp.cPartnerIdArray.includes(
                                        'WSL001'
                                    )
                                )
                                : false
                          }
                        >
                          <SubHeader>{'I JUST WANT A TEST'}</SubHeader>
                          <Icon>
                            <TestLogo />
                          </Icon>
                          <Description>
                            {'Get screened for COVID-19 (not valid for travel)'}
                          </Description>
                          <Button
                            disabled={
                                isWalkUp.active &&
                                isWalkUp.cPartnerIdArray !==
                                    null
                                    ? !(
                                          isWalkUp.cPartnerIdArray.includes(
                                              'WSL001'
                                          ) 
                                      )
                                    : false
                            }
                            type="submit"
                            libraryType="default"
                            style={btnStyle}
                            onClick={() => {
                              // clearFormValues();
                              updateFormValues({ testSelection: 'general' });
                              localStorage.setItem('travelType', 'DOM');
                              updateTravelType('DOM');
                              localStorage.setItem('destination', '');
                              updateDestination('');
                              localStorage.setItem('airline', '');
                              updateAirlineType('');
                              localStorage.setItem('dist', '');
                              updateDistrict('');
                              updateTenant('general');
                            }}
                            className="btn fill-btn"
                          >
                            {'BOOK NOW'}
                          </Button>
                        </List>
                      </Grid>
                      <Grid item xs={12} lg={2} md={4} sm={6}>
                        <List
                          disabled={
                            !isWalkUp.active
                            ? false
                            : isWalkUp.cPartnerIdArray !==
                                  null &&
                              (isWalkUp.cPartnerIdArray.includes(
                                  'SEQ001'
                              ) ||
                                  isWalkUp.cPartnerIdArray.includes(
                                      'BEL001'
                                  ) ||
                                  isWalkUp.cPartnerIdArray.includes(
                                      'SSF001'
                                  ) || 
                                  isWalkUp.cPartnerIdArray.includes(
                                    "SCS001"
                                )
                                  )
                            ? false
                            : true
                        }
                        >
                          <SubHeader>{'TESTING FOR SCHOOL'}</SubHeader>
                          <Icon>
                            <SchoolLogo />
                          </Icon>
                          <Description style={{ margin: '18px 0px' }}>
                            {'Test to keep you and your classmates safe.'}
                          </Description>
                          <Button
                            disabled={
                                !isWalkUp.active
                                ? false
                                : isWalkUp.cPartnerIdArray !==
                                      null &&
                                  (isWalkUp.cPartnerIdArray.includes(
                                      'SEQ001'
                                  ) ||
                                      isWalkUp.cPartnerIdArray.includes(
                                          'BEL001'
                                      ) ||
                                      isWalkUp.cPartnerIdArray.includes(
                                          'SSF001'
                                      ) || 
                                      isWalkUp.cPartnerIdArray.includes(
                                        "SCS001"
                                    ))
                                ? false
                                : true
                            }
                            type="submit"
                            libraryType="default"
                            style={btnStyle}
                            className="btn fill-btn"
                            onClick={() => {
                              // clearFormValues();
                              updateFormValues({ testSelection: 'school' });
                              localStorage.setItem('reason', '');
                              updateReasonType('');
                              localStorage.setItem('vactype', '');
                              updateVaccineType('');
                              localStorage.setItem('isvac', '');
                              updateVaccinated('');
                              localStorage.setItem('destination', '');
                              updateDestination('');
                              localStorage.setItem('airline', '');
                              updateAirlineType('');
                              localStorage.setItem('travelType', 'DOM');
                              updateTravelType('DOM');
                              updateTenant('school');
                            }}
                          >
                            {'BOOK NOW'}
                          </Button>
                        </List>
                      </Grid>
                      <Grid item xs={12} lg={2} md={6} sm={6}>
                        <List
                          disabled={
                            isWalkUp.active &&
                                isWalkUp.cPartnerIdArray !==
                                    null
                                    ? !isWalkUp.cPartnerIdArray.includes(
                                        'COM001'
                                    )
                                    : false
                          }
                        >
                          <SubHeaders>{'Visiting A Community Site'}</SubHeaders>
                          <Icon>
                            <Radius>
                              <CommunityLogo />
                            </Radius>
                          </Icon>
                          <Description style={{ margin: '11px -54px' }}>
                            {
                              'Come by one of our community sites to receive free testing, vaccinations and other health services.'
                            }
                          </Description>
                          <Button
                            disabled={
                                isWalkUp.active &&
                                isWalkUp.cPartnerIdArray !==
                                    null
                                    ? !isWalkUp.cPartnerIdArray.includes(
                                          'COM001'
                                      )
                                    : false
                            }
                            type="submit"
                            libraryType="default"
                            style={btnStyle}
                            className="btn fill-btn"
                            onClick={() => {
                              // clearFormValues();
                              fetchCpartnerlogo('COM001');
                              updateFormValues({ testSelection: 'community' });
                              localStorage.setItem('reason', '');
                              updateReasonType('');
                              localStorage.setItem('vactype', '');
                              updateVaccineType('');
                              localStorage.setItem('isvac', '');
                              updateVaccinated('');
                              localStorage.setItem('destination', '');
                              updateDestination('');
                              localStorage.setItem('airline', '');
                              updateAirlineType('');
                              localStorage.setItem('travelType', 'DOM');
                              updateTravelType('DOM');
                              localStorage.setItem('dist', 'COM001');
                              updateTenant('community');
                            }}
                          >
                            {'BOOK NOW'}
                          </Button>
                        </List>
                      </Grid>
                     
                      <Grid item xs={12} lg={12} md={12} sm={12} >
                      </Grid>
                    </>
                  )}
                </Grid>
              </Form>
            )}
          </Formik>
          <div style={{marginRight:10, display:'flex',justifyContent:'end'}}>
            <LOGO1 src={wLogo} alt=""  />
          </div>
        </div>
        </Background>
      </Div>
        <FooterDiv>
            <Footer />
        </FooterDiv>
    </>
  );
};
export default TestSelection;
