import React, { useState,useEffect } from 'react';
import styled from 'styled-components';
import { Form, Formik } from 'formik';

import {
  Content,
  PageHeader,
  SubHeaderDescription,
  PageTitle,
  LogoCard, LogoImage
} from '../../shared/components/styled';
import { colors } from '../../../styles/colors';
import { Button } from '../../shared/components/styled';
import axios from 'axios';
import Input from '../../shared/components/form/Input';
import FormLabel from '../../shared/components/form/FormLabel';
import Header from '../../shared/components/Header';
import Loader from '../../shared/components/Loader';
import Footer from '../../shared/components/Footer';
import { Breakpoints } from '../../../dictionaries';
import wsllogo from '../../../assets/WLABS_CMYK.png';
import hallogo from '../../../assets/hal_logo.png';
import orglogo from '../../../assets/org_logo.png';
import Publiclogo from '../../../assets/public_logo.png';
import { useHistory } from 'react-router-dom';
import { useStepsState, useRegisterStepsDispatch } from '../../register/provider';
import { registerLogin, verifyAccountURL } from '../../register/api';
import { useSharedDispatch, useSharedState } from '../../shared/provider';
import { resetPassword } from '../../register/api';
import { useParams } from 'react-router';
import circleRectAngle from '../../../assets/circle-rectangle-dark-blue.png';
import circleRectAngleBottom from '../../../assets/circle-rectangle-dark-blue-bottom.png';
import wLogo from '../../../assets/wsl-w-only.png';
import { Container } from '../../register/components/SignIn';
export const WLLogo = styled.div`
  height: 60px;
  width: 270px;
  background: url(${wsllogo}) no-repeat center center;
  background-size: cover;
  margin-bottom: 15px;
  display: inline-block;

  @media (max-width: ${Breakpoints.sm}px) {
  }
`;

const CTAButton = styled.button`
  display: inline-block;
  width: 60%;
  color: ${colors.white};
  text-align: center;
  padding: 15px;
  background: ${colors.orange};
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  border: 0;
  font-size: 18px;
  line-height: 12px;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 5%;
`;

export const SubText = styled.div`
  font-family: Open Sans;
  margin: 5px 0px 0px 0px;
  font-size: 16px;
  font-weight: 700 !important;
  color: ${colors.rebrandBlack};
  cursor: pointer;
`;

const Error = styled.div`
  color: ${colors.red};
  margin-top: 15px;
  text-align: center;
`;
const Space = styled.div`
  margin-bottom: 20px;
  @media (max-width: ${Breakpoints.sm}px) {
    margin-bottom: 30px;
  }
`;
const Div = styled.div`
    position: absolute;
    bottom: 0px;
    width: 100vw;
    height: 92.2vh;
    overflow-x: hidden;
    overflow-y: hidden;
    @media (max-width: ${Breakpoints.md}px) {
        overflow-x: hidden;
        overflow-y: auto;
    }
`
export const MainContainer = styled.div`
    position: absolute;
    margin-top: 4%;
    z-index: 6;
    @media (max-width: ${Breakpoints.sm}px) {
        margin-top: 0%;
    }
`;
const Background = styled.div`
    background-image: url(${circleRectAngle});
    position: absolute;
    width: 100vw;
    height: 92.2vh;
    z-index: 1;
    background-repeat: no-repeat;
    background-size: 55% 140%;
    background-position: right;
    // background-attachment: fixed;

    @media (max-width: ${Breakpoints.xl}px) {
        background-position: bottom;
        background-image: url(${circleRectAngleBottom});
        background-size: 100% 15%;
        overflow-x: hidden;
        overflow-y: hidden;
    }
    @media (max-width: ${Breakpoints.md}px) {
        background-size: 107% 15%;
        height:100%;
    }
    @media (max-width: ${Breakpoints.sm}px) {
        background-size: 130% 15%
        height:100%;
    }
`
const FooterDiv = styled.footer`
    position: fixed;
    width: 100vw;
    z-index: 11111;
    bottom: 0px;
    background-color: rgb(36, 61, 77);
    height: 40px;
    @media (max-width: 960px) {
        height: 40px;
        position: fixed;
    }
    @media (max-width: 600px) {
        height: 70px;
        position: fixed;
    }
`
const Stripe = styled.div`
    position: absolute;
    width: 100vw;
    height: 6.5%;
    background-color: rgb(97, 156, 138);
`;
const ForgotPassword: React.FC = () => {
  let history = useHistory();
  const redirect = () => {
    history.push('/user/profile');
  };
  const signinRedirect = () => {
    localStorage.clear();
    history.push('/signin');
  };
  const {
    form: { password, confirmPassword },
  } = useStepsState();

  const { email } = useParams<{ email: string }>();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const { updateFormValues, goToNextStep } = useRegisterStepsDispatch();
  const { setloggedUserData } = useSharedDispatch();
  const { partnerLogo:{logo} } = useSharedState();
  const [isLoginScreen, setLoginScreen] = useState(true);

  const [validLength, setValidLength] = useState(false);
  const [upperCase, setUpperCase] = useState(false);
  const [lowerCase, setLowerCase] = useState(false);
  const [specialChar, setSpecialChar] = useState(false);
  const [requiredLength, setRequiredLength] = useState(8);
  const [isVerified, setIsVerified] = useState(false);

  const handleSignIn = async (params: { email: string; password: string }) => {
    setLoading(true);

    try {
      const loggedUser = await registerLogin(params);
      if (loggedUser?.data?.status == 200) {
        localStorage.setItem('user', JSON.stringify(loggedUser?.data.data));
        setloggedUserData(loggedUser?.data.data);
        if (isLoginScreen) {
          redirect();
        } else {
          setError('Incorrect Password Details');
        }
      } else {
        if(loggedUser?.data?.message === "Please verify your account to login."){
          signinRedirect();
      }
      setLoading(false);
    }} catch (e) {
      setError('Incorrect email or password');
      setLoading(false);
    }
  };

  const handleReset = async (params: {
    email: string;
    password: string;
    confrmpwd: string;
  }) => {
    setLoading(true);
    setError('');
    try {
      params.email = window.location.pathname.replace('/resetpassword/', '');
      const loggedUser = await resetPassword(params);
      if (loggedUser?.data?.status == 200) {
        params['email'] = loggedUser?.data?.data;
        handleSignIn(params);
      } else {
        setError(loggedUser?.data?.message);
      }
      setLoading(false);
    } catch (e) {
      setError('Incorrect email or password');
      setLoading(false);
    }
  };
  const validatePassword = (password: string) => {
    let error: string = '';

    if (password) {
      setValidLength(password.length >= requiredLength ? true : false);
      setUpperCase(password.toLowerCase() !== password);
      setLowerCase(password.toUpperCase() !== password);
      setSpecialChar(/[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(password));
      if (validLength && upperCase && lowerCase && specialChar) {
        setLoginScreen(true);
      } else {
        error =
          ' 8 character minimum, 1 uppercase, one lowercase, one special character ';
        setLoginScreen(false);
      }
    }

    return error;
  };
  const verifyAccount = async() => {
    setLoading(true);
    setError('');
    try {
      let url: any = window.location.pathname.replace('/resetpassword/', '');
      const loggedUser = await verifyAccountURL({email: url});
      if (loggedUser?.data?.status == 200) {
        setIsVerified(loggedUser?.data?.isVerified)
      } else {
        setError(loggedUser?.data?.message);
        setIsVerified(false)
      }
      setLoading(false);
      setIsVerified(false)
    }
    catch (e) {
      setError('Incorrect email');
      setLoading(false);
      setIsVerified(false)
    }
  };

  useEffect(() => {
    verifyAccount();
  }, [])

  
  return (
    <>
    <Header withoutBg isHelp />
    <Div>
        <Stripe />
        <Background>
            <div style={{display:'flex', justifyContent:'center'}}>
                <div style={{display:'flex', justifyContent:'center',  alignItems:'center', position:'absolute', height:'100vh', width:'100vh',transform:'translate(-42%)', zIndex:0}}>
                    <img src={wLogo} alt="" />
                </div>
                <MainContainer>
                    <Container>
                        <Content
                            style={{
                                textAlign: 'center',
                                padding: '10px 30px 50px 30px',
                            }}
                        >
                            <>
                            <LogoCard>
                                <LogoImage src={logo.length ? logo : ''} />
                                </LogoCard>
                                <PageHeader>
                                    <PageTitle
                                    color={colors.fullBlack}
                                    textAlign="center"
                                    fontsize="20px"
                                    >
                                    Please reset your password
                                    </PageTitle>
                                </PageHeader>
                                <SubHeaderDescription style={{ fontSize: '13px' }}>
                                    *8 character minimum, 1 uppercase, one lowercase, one special
                                    character
                                </SubHeaderDescription>

                                <Formik
                                    initialValues={{
                                    email: email,
                                    password: password,
                                    confrmpwd: confirmPassword,
                                    }}
                                    onSubmit={(values) => {
                                    if (values.password === values.confrmpwd && values.email) {
                                        updateFormValues(values);
                                        setLoading(true);
                                        handleReset(values);
                                    } else {
                                        setError('Paasword not matching');
                                    }
                                    }}
                                    enableReinitialize={false}
                                >
                                    {() => (
                                    <Form style={{ width: '80%', display: 'inline-block' }}>
                                        <Input
                                        name="password"
                                        placeholder="New Password"
                                        isRequired
                                        type="password"
                                        onChange={(value) => updateFormValues({ password: value })}
                                        validate={(value) => validatePassword(value)}
                                        />
                                        <Space />

                                        <Input
                                        name="confrmpwd"
                                        placeholder="Confirm New Password"
                                        isRequired
                                        type="password"
                                        onChange={(value) => updateFormValues({ confrmpwd: value })}
                                        />
                                        <Space />
                                        {error && <Error>{error}</Error>}
                                        <div style={{ textAlign: 'center' }}>
                                        <Button
                                            libraryType="primary"
                                            type="submit"
                                            className='btn fill-btn'
                                        >
                                            {loading ? 'Processing...' : isVerified ? 'Change Password & Sign In' : 'Change Password'}
                                        </Button>
                                        </div>
                                    </Form>
                                    )}
                                </Formik>

                                <SubHeaderDescription
                                    style={{ fontSize: '12px', marginTop: '20px' }}
                                >
                                    New to Worksite Labs? Sign up for your account below.
                                </SubHeaderDescription>

                                <div style={{ margin: '15px 0px 0px 0px' }}>
                                    <SubText
                                    className='underline'
                                    onClick={() => {
                                        history.push('/register');
                                    }}
                                    >
                                    REGISTER
                                    </SubText>
                                </div>
                            </>
                        </Content>
                    </Container>
                </MainContainer>
            </div>
        </Background>
        <FooterDiv>
            <Footer />
        </FooterDiv>
    </Div>
    </>
  );
};

export default ForgotPassword;

//ltst