import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';

import {useStepsState,useStepsDispatch} from '../../schedule/provider'
import { config } from '../../../config';

import Schedule from '../../shared/components/Schedule';
import mixpanel from 'mixpanel-browser';
import { useSharedState } from '../../shared/provider';
import GoogleAnalytics from '../../../GoogleAnalytics';
import { useManageDispatch } from '../../manage/provider';
import { bookGoHealthAppt } from '../../schedule/api';

const DateAndTime: React.FC = () => {
  const { form, prices, blockOutDays} = useStepsState();
  const { testSelection } = form;
  const { standradTest } = useSharedState();
  const { updateStandradTest } = useManageDispatch();
  const [error, setError] = useState(false);
  const [isSlotsLoading, setSlotsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    toggleChangeLocationModal,
    updateFormValues,
    goToNextStep,
    goToPrevStep
  } = useStepsDispatch();
  React.useEffect(()=>{
    GoogleAnalytics(form,'/Pick Date & Time','Schedule')
    if(form?.location?.qbenchCustomerId===237 && form?.testSelection !=='sfoairport' && form?.hasInsurance){
        updateFormValues({isAntigen:true,isRapidTest:false})
        updateStandradTest(false)
    }else if(form?.location?.qbenchCustomerId===237){
        updateFormValues({isAntigen:false,isRapidTest:true})
        if(form.testSelection ==='sfoairport')
            updateFormValues({isRapidTest:form?.isRapidTest})
    }
    // if(form?.location?.qbenchCustomerId === config?.qbenchCustomerId?.LasVegasSpringValley){
    //     updateFormValues({ isRapidTest: true, isAntigenTest: false, isExpressSameDayTest: false, "hasInsurance": false,   "payingMethod": "Credit card" })
    //     updateStandradTest(false)
    // }
    if(form?.location?.partnerName === 'goHealth'){
      updateFormValues({isRapidTest: false, isAntigenTest: false, isExpressSameDayTest: false,"hasInsurance": false,   "payingMethod": "Credit card" })
      updateStandradTest(true)
    }
    if(form?.location?.qbenchCustomerId === config?.qbenchCustomerId?.SFOAirport_BARTStation && (form?.testSelection === 'flight'  || form?.testSelection === 'general')){
      if(form?.payingMethod === "Credit card"){
          updateFormValues({ isRapidTest: form?.isAntigen ? false : true, isAntigen: form?.isAntigen, isExpressSameDayTest: false, })
      }else{
          updateFormValues({ isRapidTest: false, isAntigen: true, isExpressSameDayTest: false, })
      }
    }
    // if(form?.location?.qbenchCustomerId === config?.qbenchCustomerId?.SFOAirport_BARTStation && form?.testSelection === 'sfoairport')
    //     updateFormValues({ isRapidTest: false, isAntigen: false, isExpressSameDayTest: false, })
},[])

useEffect(() => {
  if(form?.location?.partnerName === 'goHealth') {
    toggleChangeLocationModal(false)
    // updatesFormValues({minors:[]})
    updateFormValues({minors:[]})
    setError(false)
} 
},[form.slot])

  return (
    <Schedule
      appointment={form}
      prices={prices}
      standardTest={standradTest}
      title="Pick a date and time"
      onFieldUpdate={(update) => updateFormValues(update)}
      onSchedule={(values) => {
            updateFormValues({
          ...values,
        });
        mixpanel.track("Pick a date and time");
        if(form?.location?.partnerName === 'goHealth'){
          updateStandradTest(true)
          setLoading(true)
          setSlotsLoading(true)
          setError(false)
          bookGoHealthAppt(form)
          .then((res) => {
            if (res?.data?.success === true) {
              updateFormValues({GoHealthRegistration_link:res?.data?.registration_link, GoHealthRegistration_id:res?.data?.registration_id})
              goToNextStep();
              setSlotsLoading(false)
            setLoading(false)
            }
          }).catch((e) => {
            document.body.scrollTo(0, 0);
            console.log("gohealth",e)
            setError(true)
            window.scrollTo(0,0)
            setLoading(false)
            setSlotsLoading(false)
          })
        }else{
          goToNextStep();
        }
    }}
      onLocationChange={() => toggleChangeLocationModal(false)}
      blockOutDays={blockOutDays}
      isSlotsLoading={isSlotsLoading}
      loading={loading}
      goHealthError={error}
    />
  );
};

export default DateAndTime;
