import React,{useEffect} from 'react';
import { Formik, Form } from 'formik';

import {
  Container,
  Content,
  PageHeader,
  SubHeaderDescription,
} from '../../shared/components/styled';
// import { useStepsDispatch } from '../provider';
import { useStepsState, useStepsDispatch } from '../../schedule/provider';
import YesNoButtonGroup from '../../shared/components/form/YesNoButtonGroup';
import NavigationButtons from '../../shared/components/NavigationButtons';
import { colors } from '../../../styles/colors';
import { Breakpoints } from '../../../dictionaries';
import styled from 'styled-components';
import mixpanel from 'mixpanel-browser';
import GoogleAnalytics from '../../../GoogleAnalytics';

export const PageTitle = styled.h2`
  color: ${colors.fullBlack};
  font-size: 28px;
  font-weight: 700;
  margin: 0;
  text-align: left;

  @media (max-width: ${Breakpoints.sm}px) {
    font-size: 25px;
    margin-top: 30%;
  }
`;
const CovidContact: React.FC = () => {
  const {
    form: { hadContact },form
  } = useStepsState();
  const { updateFormValues, goToNextStep, goToPrevStep } = useStepsDispatch();
    useEffect(()=>{
      GoogleAnalytics(form,'/CovidContactDetails');
  },[])

  return (
    <Container size="xl">
      <Content>
        <PageHeader>
          <PageTitle>
            In the past 14 days, have you had contact with someone who has
            confirmed case of COVID-19? Count any contact that lasted longer
            than 15 minutes, closer than 6 feet or 1.8 meters away?
          </PageTitle>
        </PageHeader>
        <Container noPadding marginTop>
          {/* <SubHeaderDescription>
            Please see the{' '}
            <a
              href="https://www.cdc.gov/coronavirus/2019-ncov/if-you-are-sick/quarantine.html"
              target="_blank"
              rel="noreferrer noopener"
            >
              CDC Guidance on When to Quarantine
            </a>
            .
          </SubHeaderDescription> */}
          <Formik
            initialValues={{
              hadContact,
            }}
            onSubmit={(values) => {
              updateFormValues(values);
              mixpanel.track("covid contact page");
              goToNextStep();
              console.log('covid contact', values);
            }}
          >
            {() => (
              <Form>
                <YesNoButtonGroup
                  name="hadContact"
                  onChange={(answer) =>{
                    updateFormValues({ hadContact: answer })
                    goToNextStep();
                  }}
                  isRequired
                />
                <NavigationButtons
                  onReturnButtonClick={() => {
                    goToPrevStep();
                  }}
                  isValid={hadContact !== null ? true : false}
                />
              </Form>
            )}
          </Formik>
        </Container>
      </Content>
    </Container>
  );
};

export default CovidContact;
