import React, {
    useCallback,
    useState,
    createContext,
    useMemo,
    useEffect,
} from 'react';
import { AnyObject } from '../shared/models';
import { RegisterDispatch, RegisterState, userDetails } from './models';
import { steps } from './components/Steps';
import { getPrices } from '../schedule/api';
import {config} from '../../config';
import { getAddress, SetPrices } from '../../utils';

const initialFormState = {
  // id: `${nanoid()}-${Date.now()}`,
  email: '',
  password: '',
  confirmPassword: '',
  postCode: '',
  employeeId: '',
  firstName: '',
  lastName: '',
  middleName: '',
  isINTNameFormat: false,
  hasMinors: false,
  minors: [],
  sex: null,
  race: null,
  ethnicity: null,
  birthDate: null,
  sendMessagesAboutTestResults: false,
  phone: '',
  address: {
    country: 'United States',
    zipCode: '',
    address: '',
    county: '',
    city: '',
    state: '',
  },
  hasSypmtoms: '',
  hasConditions: '',
  cPartnerID: null,
  passportCountry: '',
  passportNo: '',
  destination: '',
  IsAirline: false,
  airlineCode: '',
  travelType: '',
  hasInsurance: false,
  insurance: {
    id: '',
    insuranceId: '',
    email: '',
    group: '',
    subscriberFirstName: '',
    subscriberLastName: '',
    frontCard: '',
    backCard: '',
    payerList: '',
    newCarrierName: '',
  },
  govtId: {
    id: '',
    email: '',
    frontCard: '',
    backCard: '',
  },
  higherCost: false,
  isAntigen: false,
  isHubSpotContact: undefined,
};

export const newMinor = {
    firstName: '',
    lastName: '',
    birthDate: null,
    relationship: '',
    sex: null,
    race: null,
    ethnicity: null,
    notification:false,
    email:''
};

export const StepsContext = createContext<RegisterState>({
    form: initialFormState,
    step: 0,
    showChangeLocationModal: false,
    configured: false,
    rescheuleAppoitments: null,
    apptPricesAdmin: {
        standard: 0,
        expedited: 0,
        rapid: 0,
        standard_INT: 0,
        expedited_INT: 0,
        rapid_INT: 0,
        antigen: 0,
        antigen_INT: 0
    },
});

export const StepsDispatchContext = createContext<RegisterDispatch>({
    goToNextStep() { },
    goToSkipStep() { },
    goToPrevStep() { },
    updateFormValues() { },
    toggleLocationModal() { },
    updateRescheduleAppoitment() { }
});

export const RegisterProvider = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    const [step, setStep] = useState(0);
    const [form, setForm] = useState(initialFormState);
    const [showChangeLocationModal, setShowChangeLocationModal] = useState(false);
    const [configured, setConfigured] = useState(false);
    const [rescheuleAppoitments, setRescheuleAppoitments] = useState<any>([]);
    const [apptPricesAdmin, setPrices] = useState({
        standard: 0,
        expedited: 0,
        rapid: 0,
        standard_INT: 0,
        expedited_INT: 0,
        rapid_INT: 0,
        antigen: 0,
        antigen_INT: 0
    });
    useEffect(() => {
        let address: any = getAddress() || form.address;
        updateFormValues({ address: address });
        getPrices().then((result) => {
            setConfigured(true);
            setPrices(SetPrices(apptPricesAdmin, result));
        });            
    }, []);

    const updateFormValues = useCallback(
        (update: AnyObject) => {
            setForm((f) => ({
                ...f,
                ...update,
            }));
        },
        [setForm]
    );

    const goToNextStep = useCallback(() => {
        setStep((s: number) => {
            if (s + 1 <= steps.length - 1) {
                return s + 1;
            }

            return s;
        });
    }, [setStep]);

    const goToSkipStep = useCallback(() => {
        setStep((s: number) => {
            s=6;
            if (s + 1 <= steps.length - 1) {
                return s + 1;
            }

            return s;
        });
    }, [setStep]);
    
    const goToPrevStep = useCallback(() => {
        setStep((s: number) => {
            if (s - 1 >= 0) {
                return s - 1;
            }

            return s;
        });
    }, [setStep]);

    const updateRescheduleAppoitment = useCallback(
        (update: any) => {
            setRescheuleAppoitments({
              ...rescheuleAppoitments,
              ...update
            });
          },
    [setRescheuleAppoitments]);
    const toggleLocationModal = useCallback(
        (show) => setShowChangeLocationModal(show),
        [setShowChangeLocationModal]
    );

    const store = useMemo(
        () => ({
            form,
            step,
            showChangeLocationModal,
            configured,
            rescheuleAppoitments,
            apptPricesAdmin
        }),
        [step, form, showChangeLocationModal, configured, rescheuleAppoitments]
    );

    const dispatch = useMemo(
        () => ({
            goToNextStep,
            goToSkipStep,
            goToPrevStep,
            updateFormValues,
            toggleLocationModal,
            updateRescheduleAppoitment
        }), []);

    return (
        <StepsContext.Provider value={store}>
            <StepsDispatchContext.Provider value={dispatch}>
                {children}
            </StepsDispatchContext.Provider>
        </StepsContext.Provider>
    );
};

export const useStepsState = () => {
    const context = React.useContext(StepsContext);

    if (typeof context === 'undefined') {
        throw new Error(
            '`useStepsState` hook must be used within a `Provider` component'
        );
    }

    return context;
};

export const useRegisterStepsDispatch = () => {
    const context = React.useContext(StepsDispatchContext);

    if (typeof context === 'undefined') {
        throw new Error(
            '`useRegisterStepsDispatch` hook must be used within a `Provider` component'
        );
    }

    return context;
};

//ltst