import React, {
  useEffect,
  useCallback,
  useState,
  createContext,
  useMemo,
} from 'react';

import {
  SharedState,
  SharedDispatch,
  Location,
  userDetails,
  AppointmentListItem,
  Form,
  Insurance,
  AirlineDetails,
  AnyObject,
  PartnerLogo,
  GovtID,
} from './models';
import { getOpenedLocations, getPromoCodes, payerList} from './api';
import { firebase } from '../../firebase';
import {
  getapptFromSchdule,
  getdestination,
  getHostName,
  gettraveltype,
  getreasontype,
  getvaccinetype,
  getvaccinated,
  getdistrict,
  gettenet,
  getRefersion,
  getpath
} from '../../utils';
import { config } from '../../config';
import { orderBy } from 'lodash';
import { getDistrict, getTestReasons, getVaccines } from '../register/api';
import { getAirlines, getCountry, getstate } from '../schedule/api';

const initialVerifyFormState = {
  verifyEmail: '',
  verifyPhone: '',
  verifyPassword: '',
};

const initPartnerLogo = {
  logo: '',
  logoHeader: '',
  locationImage: ''
};

export const SharedContext = createContext<SharedState>({
  locations: null,
  payer: null,
  standradTest: false,
  userData: null,
  validAddress: false,
  isAdmins: false,
  appointments: null,
  userInsuranceData: null,
  airlineDetails: null,
  formList: [],
  confirmationId: null,
  airline: null,
  travellType: '',
  apptFromSchdule: false,
  destination: '',
  flow: false,
  isVerifyReq: false,
  verifyForm: initialVerifyFormState,
  isVerifyFlow: false,
  toNextStep: false,
  reasonType: '',
  isVaccinate: '',
  vaccineType: '',
  testSelectFlow: false,
  district: '',
  partnerLogo: initPartnerLogo,
  sharedSlots: [],
  tenant: '',
  userGovtId: [],
  isRefersion: false,
  isWalkUp: {
    locationId: 0,
    cPartnerIdArray: [],
    active: false,
  },
  isUpgrade: false,
  testFlowSelectionDatas: {},
  promos: null,
});

export const SharedDispatchContext = createContext<SharedDispatch>({
  getLocations() {},
  getPromoCodes() {},
  getPayerList() {},
  upateTestType() {},
  setloggedUserData() {},
  updateAddress() {},
  setIsAdmin() {},
  updateInsuranceList() {},
  updateAppointmentList() {},
  updateFormList() {},
  updateGovtId() {},
  updateEditConfirmationId() {},
  updateAirlineType() {},
  updateTravelType() {},
  updateapptFromSchdule() {},
  updateAirlineDetails() {},
  setApptFlow() {},
  setIsVerifyReq() {},
  updateVerifyFormValues() {},
  updateDestination() {},
  setIsVerifyFlow() {},
  updateUserVerify() {},
  updateReasonType() {},
  updateVaccinated() {},
  updateVaccineType() {},
  setTestSelectionFlow() {},
  updateDistrict() {},
  updatePartnerLogo() {},
  updateSharedSlots() {},
  updateTenant() {},
  updateRefersion() {},
  setWalkUp() {},
  updateUpgradeTest() {},
  getTestFlowSelectionDatas() {},
  closedLocation() {}
});

export const SharedProvider = ({ children }: { children: React.ReactNode }) => {
  const [locations, setLocations] = useState<Location[] | null>(null);
  const [standradTest, setTestType] = useState<boolean>(false);
  const [isAdmins, setisAdmins] = useState<boolean>(false);
  const [userData, setUserData] = useState<userDetails | null>(null);
  const [validAddress, setValidAddress] = useState<boolean>(false);
  const [userGovtId, setUserGovtId] = useState<GovtID[] | null>(null);
  const [userInsuranceData, setUserInsuranceData] = useState<
    Insurance[] | null
  >(null);
  const [appointments, setAppointments] = useState<
    AppointmentListItem[] | null
  >(null);
  const [formList, setForm] = useState<[]>([]);
  const [confirmationId, setConfirmationId] = useState<string | null>(null);
  const [airline, setAirline] = useState<string | null>(null);
  const [travellType, setTravelType] = useState<string>('');
  const [destination, setDestination] = useState<string>('');
  const [apptFromSchdule, setapptFromSchdule] = useState<boolean>(false);
  const [airlineDetails, setAirlineDetails] = useState<AirlineDetails | null>(
    null
  );
  const [flow, setFlow] = useState<boolean>(false);
  const [isVerifyReq, setVerified] = useState(false);
  const [verifyForm, setVerifyForm] = useState(initialVerifyFormState);
  const [isVerifyFlow, setVerifiedFlow] = useState(false);
  const [toNextStep, setToNextStep] = useState(false);
  const [reasonType, setReasonType] = useState<string>('');
  const [vaccineType, setVaccineType] = useState<string>('');
  const [isVaccinate, setIsVaccinated] = useState<string>('');
  const [testSelectFlow, setTestSelectFlow] = useState<boolean>(false);
  const [district, setDistrict] = useState<string>('');
  const [partnerLogo, setPartnerLogo] = useState<PartnerLogo>(initPartnerLogo);
  const [tenant, setTenant] = useState<string>('');
  const [isRefersion, setIsRefersion] = useState<boolean>(false);
  const [sharedSlots, setSlots] = useState<any>([]);
  const [isWalkUp, setIsWalkUp] = useState<{
    locationId: number;
    cPartnerIdArray: string[];
    active: boolean;
  }>({ locationId: 0, cPartnerIdArray: [], active: false });
  const [isUpgrade, setIsUpgrade] = useState<boolean>(false);
    const [testFlowSelectionDatas, setTestFlowSelectionDatas] = useState<any>({})
    const [airLineData, setAirLineData] = useState<any>([]);
    const [state, setState] = useState<any>([]);
    const [country, setCountry] = useState<any>([]);
    const [reasonData, setReasonData] = useState<{ value: string; label: string }[]>([]);
    const [vaccineData, setVaccineData] = useState<{ value: string; label: string }[]>([]);
    const [districtData, setDistrictData] = useState<{ value: string; label: string }[]>([]);
    const [payer, setPayer] = useState<any>([]);
    const [isLocation, setisLocation] = useState(false)
  const [promos, setPromo] = useState([]);


  const getLocations = useCallback(async () => {
    try {
      const locations = await getOpenedLocations();
         setLocations(locations);

    //   if (tenant === 'general') {
    //     setLocations(
    //       locations?.filter(
    //         (place: any) => place.name !== 'San Francisco - SFO Airport'
    //       )
    //     );
    //   } else {
    //     setLocations(locations);
    //   }
    } catch (e) {
      console.error(e);
    }
  }, [tenant]);

  const Promo = useCallback(async () => {
    try {
        const promoCode = await getPromoCodes()
        setPromo(promoCode.data)
    } catch (e) {
        console.error('promocode error',e)
    }
  },[])

    const getPayerList = useCallback(async () => {
        try {
            const payer = await payerList();
            let payer1 = payer.data.map((e: any) => {
                return { label: e.payerName, value: e.payerCode }
            })
            setPayer([...payer1, { label: 'Other', value: 'Other' }]);

        } catch (e) {
            console.error(e);
        }
    }, []);
  const fetchAirlines = async () => {
    try {
      const data = await getAirlines();
      if (data?.data?.status === 200) {
        setAirLineData(data?.data?.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchcountry = async () => {
    try {
      const data = await getCountry();
      if (data?.data?.status === 200) {
        setCountry(
          data?.data?.data.filter((item: any) => item.value !== 'BRB')
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchstate = async () => {
    try {
      const data = await getstate();
      if (data?.data?.status === 200) {
        setState(data?.data?.data);
      }
    } catch (e) {
      console.log(e);
    }
  };
   const fetchReasons = async () => {
     const res = await getTestReasons();
     if (res.data.status === 200) {
       const data: any = res.data.data;
       var list: any[] = [];
       data.reduce((index: any, reason: any) => {
         list.push({
           value: reason.value,
           label: reason.name,
         });
       }, []);
       list = orderBy(list, ['label'], ['asc']);
       setReasonData(list);
     }
   };
   const fetchVaccines = async () => {
     const res = await getVaccines();
     if (res.data.status === 200) {
       const data: any = res.data.data;
       var list: any[] = [];
       data.reduce((index: any, vaccine: any) => {
         list.push({
           value: vaccine.value,
           label: vaccine.name,
         });
       }, []);
       list = orderBy(list, ['label'], ['asc']);
       setVaccineData(list);
     }
   };
   const fetchDistrict = async () => {
     const res = await getDistrict();
     if (res.data.status === 200) {
       const data: any = res.data.data;
       let list: any[] = [];

       if (isWalkUp.active) {
         data
           .filter((district: any) =>
             isWalkUp.cPartnerIdArray?.includes(district.cPartnerID)
           )
           .reduce((index: any, district: any) => {
             list.push({
               value: district.value,
               label: district.name,
             });
             return list;
           }, []);
       } else {
         data.reduce((index: any, vaccine: any) => {
           if (vaccine.isActive) {
             list.push({
               value: vaccine.value,
               label: vaccine.name,
             });
           }
           return list;
         }, []);
       }
       list = orderBy(list, ['label'], ['asc']);
       setDistrictData(list);
     }
   };
  useEffect(() => {
    firebase.auth().signInAnonymously();

    const unsubscribe = firebase
      .auth()
      .onAuthStateChanged(async (firebaseUser) => {
        if (firebaseUser) {
          getLocations();
          Promo();
          getPayerList();
        }
      });

    return () => unsubscribe();
  }, [getLocations, getPayerList]);


  useEffect(() => {
    if (!userData) {
      let user = localStorage.getItem('user') || null;
      setUserData(user ? JSON.parse(user) : null);
      let admin = localStorage.getItem('isAdmin') || null;
      setisAdmins(admin == 'true' ? true : false);
    }
    let isAddress: any = localStorage.getItem('isAddress') || null;
    setValidAddress(isAddress);
    //   if (!userInsuranceData) {
    //     let user = localStorage.getItem('insurance') || null;
    //     setUserInsuranceData(user ? JSON.parse(user): null)
    // }

    // getSlotsConfigurations().then((result) => {
    //   setSlots(result.data);
    // });

    if (!apptFromSchdule) {
      updateapptFromSchdule(getapptFromSchdule());
    }

    if (!confirmationId) {
      let confirmationNumber = localStorage.getItem('confirmationId') || null;
      setConfirmationId(confirmationNumber ? confirmationNumber : null);
    }

    if (!airline) {
      let airlineCode = getHostName() || null;
      updateAirlineType(airlineCode);
      setAirline(airlineCode ? airlineCode : null);
    }

    if (!travellType) {
      let travellingType = gettraveltype() || null;
      setTravelType(travellingType ? travellingType : '');
    }

    if (!destination) {
      let destinationCode = getdestination() || null;
      setDestination(destinationCode ? destinationCode : '');
    }

    if (!reasonType) {
      let data = getreasontype() || null;
      setReasonType(data ? data : '');
    }

    if (!vaccineType) {
      let data = getvaccinetype() || null;
      setVaccineType(data ? data : '');
    }

    if (!isVaccinate) {
      let data = getvaccinated() || null;
      setIsVaccinated(data ? data : '');
    }

    if (!district) {
      let data = getdistrict() || null;
      setDistrict(data ? data : '');
    }

    if (!tenant) {
      let data = gettenet() || null;
      setTenant(data ? data : '');
    }

    if (!tenant) {
        let data = getpath() || null;
        if(data === config.qbenchCustomerId.KernHealthSystems.toString()){
            setTenant('kern')
        }
       
      }
      
    if (!isRefersion) {
      let data = getRefersion() || null;
      setIsRefersion(Boolean(data));
  }

  }, [
    userData,
    validAddress,
    confirmationId,
    airline,
    travellType,
    destination,
    reasonType,
    vaccineType,
    isVaccinate,
    district,
    tenant,
    isRefersion,
    payer,
    promos,
  ]);
    useEffect(()=>{
        fetchAirlines();
        fetchcountry();
        fetchstate();
        fetchDistrict();
        fetchReasons();
        fetchVaccines();
    },[])
    useEffect(() => {    
        getTestFlowSelectionDatas({ ...testFlowSelectionDatas, airLineData,state,country,reasonData,vaccineData,districtData });
    }, [airLineData,state,country,reasonData,vaccineData,districtData]);
  const updateAirlineType = useCallback(
    (type: string | null) => {
      setAirline(type ? type : '');
    },
    [setAirline]
  );

  // check its coming from schduler or not
  const updateapptFromSchdule = useCallback(
    (type: boolean) => {
      setapptFromSchdule(type);
    },
    [setapptFromSchdule]
  );

  const updateTravelType = useCallback(
    (type: string | null) => {
      setTravelType(type ? type : '');
    },
    [setTravelType]
  );

  const setApptFlow = useCallback(
    (flow: boolean) => {
      setFlow(flow);
    },
    [setFlow]
  );

  const updateDestination = useCallback(
    (type: string | null) => {
      setDestination(type ? type : '');
    },
    [setDestination]
  );

  const setTestSelectionFlow = useCallback(
    (type: boolean) => {
      setTestSelectFlow(type);
    },
    [setTestSelectFlow]
  );
  const setWalkUp = useCallback(
    (type: AnyObject) => {
        setIsWalkUp({ ...isWalkUp, ...type });
    },[isWalkUp]
  );
  const store = useMemo(
    () => ({
      locations,
      standradTest,
      userData,
      validAddress,
      isAdmins,
      appointments,
      formList,
      confirmationId,
      airline,
      travellType,
      apptFromSchdule,
      userInsuranceData,
      airlineDetails,
      destination,
      flow,
      isVerifyReq,
      verifyForm,
      isVerifyFlow,
      toNextStep,
      reasonType,
      isVaccinate,
      vaccineType,
      testSelectFlow,
      district,
      partnerLogo,
      sharedSlots,
      tenant,
      userGovtId,
      isRefersion,
      isWalkUp,
      isUpgrade,
      payer,
      testFlowSelectionDatas,
      promos,
      isLocation
    }),
    [
      locations,
      standradTest,
      userData,
      validAddress,
      appointments,
      formList,
      confirmationId,
      airline,
      travellType,
      apptFromSchdule,
      userInsuranceData,
      airlineDetails,
      destination,
      flow,
      isVerifyReq,
      verifyForm,
      isVerifyFlow,
      toNextStep,
      reasonType,
      isVaccinate,
      vaccineType,
      testSelectFlow,
      district,
      partnerLogo,
      sharedSlots,
      tenant,
      userGovtId,
      isRefersion,
      isWalkUp,
      isUpgrade,
      payer,
      testFlowSelectionDatas,
      promos,
      isLocation
    ]
  );

  const upateTestType = useCallback(
    (update: any) => {
      setTestType(update);
    },
    [setTestType]
  );

  const setloggedUserData = useCallback(
    (userData: userDetails | null) => setUserData(userData),
    [setUserData]
  );
  const updateAddress = useCallback(
    (data: boolean) => setValidAddress(data),
    [setValidAddress]
  );
  const setIsAdmin = useCallback((isAdmins: any) => setisAdmins(isAdmins), [
    setisAdmins,
  ]);
  const updateInsuranceList = useCallback(
    (insurance: Insurance[] | null) => setUserInsuranceData(insurance),
    [setUserInsuranceData]
  );
  const updateGovtId = useCallback(
    (govtId: GovtID[] | null) => setUserGovtId(govtId),
    [setUserGovtId]
  );
  const updateAirlineDetails = useCallback(
    (airlineDetails: AirlineDetails | null) =>
      setAirlineDetails(airlineDetails),
    [setAirlineDetails]
  );
  const updateAppointmentList = useCallback(
    (appointments: AppointmentListItem[] | null) =>
      setAppointments(appointments),
    [setAppointments]
  );
  const updateFormList = useCallback(
    (formList: any) => setForm(formList),
    [setForm]
  );
  const updateEditConfirmationId = useCallback(
    (confirmationId: string | null) => setConfirmationId(confirmationId),
    [setConfirmationId]
  );
  const setIsVerifyReq = useCallback(
    (isVerifyReq: boolean) => setVerified(isVerifyReq),
    [setVerified]
  );
  const setIsVerifyFlow = useCallback(
    (isVerifyFlow: boolean) => setVerifiedFlow(isVerifyFlow),
    [setVerifiedFlow]
  );
  const updateVerifyFormValues = useCallback(
    (update: AnyObject) => {
      setVerifyForm((f) => ({
        ...f,
        ...update,
      }));
    },
    [setVerifyForm]
  );
  const updateUserVerify = useCallback(
    (toNextStep: boolean) => setToNextStep(toNextStep),
    [setToNextStep]
  );

  const updateReasonType = useCallback(
    (type: string | null) => {
      setReasonType(type ? type : '');
    },
    [setReasonType]
  );
  const updateVaccinated = useCallback(
    (type: string | null) => {
      setIsVaccinated(type ? type : '');
    },
    [setIsVaccinated]
  );
  const updateVaccineType = useCallback(
    (type: string | null) => {
      setVaccineType(type ? type : '');
    },
    [setVaccineType]
  );
  const updateDistrict = useCallback(
    (type: string | null) => {
      setDistrict(type ? type : '');
    },
    [setDistrict]
  );
  const updatePartnerLogo = useCallback(
    (partnerLogo: PartnerLogo) => {
      setPartnerLogo(partnerLogo);
    },
    [setPartnerLogo]
  );
  const updateTenant = useCallback(
    (type: string | null) => {
      setTenant(type ? type : '');
    },
    [setTenant]
  );

  const updateSharedSlots = useCallback(
    (update: any) => {
      setSlots(update);
    },
    [setSlots]
  );
  const updateUpgradeTest = useCallback(
    (type: boolean) => {
      setIsUpgrade(type);
    },
    [setIsUpgrade]
  );
  const updateRefersion = useCallback(
    (type: boolean) => {
        setIsRefersion(type);
    },
    [setIsRefersion]
);
const getTestFlowSelectionDatas = useCallback(
    (testFlowSelectionDatas: any) =>
      setTestFlowSelectionDatas(testFlowSelectionDatas),
    [setTestFlowSelectionDatas]
  );

  const closedLocation = useCallback(
    (type: boolean) => {
        setisLocation(type);
    },
    [setisLocation]
  );

  const dispatch = useMemo(
    () => ({
      getLocations,
      upateTestType,
      setloggedUserData,
      updateAddress,
      setIsAdmin,
      updateInsuranceList,
      updateAirlineDetails,
      updateAppointmentList,
      updateFormList,
      updateEditConfirmationId,
      updateAirlineType,
      updateTravelType,
      updateapptFromSchdule,
      setApptFlow,
      updateGovtId,
      setIsVerifyReq,
      updateVerifyFormValues,
      updateDestination,
      setIsVerifyFlow,
      updateUserVerify,
      updateReasonType,
      updateVaccinated,
      updateVaccineType,
      setTestSelectionFlow,
      updateDistrict,
      updatePartnerLogo,
      updateSharedSlots,
      updateTenant,
      updateRefersion,
      setWalkUp,
      updateUpgradeTest,
      getTestFlowSelectionDatas,
      getPayerList,
      getPromoCodes,
      closedLocation
    }),
    [standradTest] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <SharedContext.Provider value={store}>
      <SharedDispatchContext.Provider value={dispatch}>
        {children}
      </SharedDispatchContext.Provider>
    </SharedContext.Provider>
  );
};

export const useSharedState = () => {
  const context = React.useContext(SharedContext);

  if (typeof context === 'undefined') {
    throw new Error(
      '`useSharedState` hook must be used within a `SharedProvider` component'
    );
  }

  return context;
};

export const useSharedDispatch = () => {
  const context = React.useContext(SharedDispatchContext);

  if (typeof context === 'undefined') {
    throw new Error(
      '`useSharedDispatch` hook must be used within a `SharedProvider` component'
    );
  }

  return context;
};
