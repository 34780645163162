import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useReactToPrint } from 'react-to-print';
import { PDFDownloadLink } from '@react-pdf/renderer';

import { Content, PageHeader, Button } from '../../../shared/components/styled';
import { useStepsState, useStepsDispatch } from '../../../schedule/provider';
import { Breakpoints } from '../../../../dictionaries';
import { colors } from '../../../../styles/colors';
//import PrintableConfirmation from './PrintableConfirmation';
//import DownloadableConfirmation from './DownloadableConfirmation';
import {
  IoLocationOutline,
  IoPersonOutline,
  IoPeopleOutline,
} from 'react-icons/io5';
import { RiErrorWarningFill } from 'react-icons/ri';
import { MdExpandMore, MdExpandLess, MdDateRange } from 'react-icons/md';
import { BiTimeFive,BiDollarCircle } from 'react-icons/bi';
import Managebutton from '../Confirmation/Managebutton';
import ChangeLocationModal from '../../../shared/components/ChangeLocationModal';
import ChangeDateTimeModal from '../../../schedule/components/ConfirmAndPay/ChangeDateTimeModal';
import { useSharedState } from '../../../shared/provider';
import PrintableConfirmation from '../../../schedule/components/Confirmation/PrintableConfirmation';
import DownloadableConfirmatiom from '../../../schedule/components/Confirmation/DownloadableConfirmation';
import { useStepsState as useScheduleStepsState } from '../../../schedule/provider';
import {
    IoPersonAddOutline,
} from 'react-icons/io5';
import { includes } from 'lodash';
import {getAppointmentId} from '../../api'
import GoogleAnalytics from '../../../../GoogleAnalytics';
import { config } from '../../../../config';
import { getNpDestionation } from '../../../schedule/api';
const LinkButton = styled.a`
  align-items: center;
  border-radius: 5px;
  font-size: 20px;
  line-height: 15px;
  text-align: center;
  padding: 15px;
  font-weight: bold;
  cursor: pointer;
  background-color: ${colors.blueLight};
  color: #fff;
  display: inline-block;
  width: 45%;
  border: 0;
  margin-top: 12px;
  text-decoration: none;
  &:hover {
    color: #fff;
  }
  @media (max-width: ${Breakpoints.sm}px) {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 90%;
  }
`;
const Container = styled.div`
  margin: 0 auto;
  margin-top: 80px;
  max-width: 80%;
  padding: 0 15px;
  @media (max-width: ${Breakpoints.sm}px) {
    max-width: 100%;
  }
`;
const Space = styled.div`
  padding: 5px;
`;

const Payment = styled.div`
  display: flex;
  gap: 20px;
`;
const Expand = styled.div`
  color: ${colors.rebrandYellow};
  display: inline-flex;
  align-items: center;
  position: relative;
  font-size: 16px;
  margin-top: 40px;
  cursor: Pointer;
  @media (max-width: ${Breakpoints.md}px) {
    font-size: 18px;
  }
`;
export const ConfirmationNumber = styled.div`
  display: block;
  font-size: 24px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 30px;
  @media (max-width: ${Breakpoints.md}px) {
    font-size: 18px;
  }
  @media (max-width: ${Breakpoints.sm}px) {
    font-size: 18px;
  }
`;
const Expandendtext = styled.div`
  display: block;
  font-size: 21px;
  font-weight: bold;
  margin-left: 0px;
`;

const FlexboxRight = styled.div`
  float: left;
`;
const FlexboxCenter = styled.div`
  margin-right: auto;
  margin-left: 2%;
`;

const FlexboxLeft = styled.div`
  float: right;
  @media (max-width: ${Breakpoints.md}px) {
    float: left;
    position: relative;
  }
  @media (max-width: ${Breakpoints.sm}px) {
    float: left;
    position: relative;
  }
`;

const ExpandWrapbutton = styled.div`
  display: flex;
  flex-direction: column;

  & > * {
    margin: 5px 0;
    background-color: white;
    color: #2a5f87;
    padding: 6px 40px;
    background: '#FFFFFF';
    border: '1px solid #EE782B';
    border-radius: '1000px';
  }
  @media (max-width: ${Breakpoints.md}px) {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 6px 60px;
    font-size: 16px;
  }
  @media (max-width: ${Breakpoints.sm}px) {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 6px 60px;
    font-size: 16px;
  }
`;
const ExpandRight = styled.div`
  display: grid;
  width: 100%;
  row-gap: 30px;
  position: relative;
  top: -50px;
  @media (max-width: ${Breakpoints.sm}px) {
    top: 30px;
  }
`;
const ExpandLeft = styled.div``;
const ExpandCenter = styled.div``;
const ExpandVl = styled.div`
  border-left: 1px solid #2a5f87;
  height: 100%;
`;
const ExpandIcon = styled.div`
  position: relative;
  color: ${colors.grey};
`;
const Expandtext = styled.div`
  font-size: 16px;
  font-weight: 500;
`;
const LocationIcon = styled.div`
  color: #ea3ba3;
`;

const Textbox = styled.div`
  display: inline-flex;
  align-items: center;
  position: relative;
  top: 3px;
  @media (max-width: ${Breakpoints.sm}px) {
    min-width: 150px;
    size: 50;
  }
`;
const WrapExpand = styled.div`
  display: grid;
  width: 100%;
  row-gap: 30px;
  position: relative;
  top: 50px;
  @media (max-width: ${Breakpoints.sm}px) {
    top: 30px;
  }
`;
const Expandrow1 = styled.div`
  display: flex;
  gap: 20px;
`;
const Text = styled.div`
  font-size: 16px;
  font-weight: normal;
  @media (max-width: ${Breakpoints.md}px) {
    padding: 10px 0px !important;
  }
  @media (max-width: ${Breakpoints.sm}px) {
    padding: 10px 0px !important;
  }
`;
const ExpandWrapContainer = styled.div`
  background: ${colors.rebrandBlack};
  color: ${colors.white};
  margin: 30px 5px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 85vw;
  border-radius: 12px;
  padding: 40px 20px 40px 60px;
  @media (max-width: ${Breakpoints.md}px) {
    padding: 40px 20px 40px 35px !important;
    margin: 0;
    left: 3%;
    width: 84vw;
  }
  @media (max-width: ${Breakpoints.sm}px) {
    padding: 40px 20px 40px 35px !important;
    margin: 0;
    left: 3%;
    width: 84vw;
  }
`;
const Datetag = styled.div`
  display: flex;
  gap: 20px;
`;
const Patientstag = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
`;
const Timetag = styled.div`
  display: flex;
  gap: 20px;
`;
const Testtag = styled.div`
  display: flex;
  gap: 20px;
`;
const DateAndPatient = styled.div`
  display: grid;
  row-gap: 30px;
`;
const TimeAndTest = styled.div`
  display: grid;
  row-gap: 30px;
`;
const ExapndlistTag = styled.div`
  display: flex;
  gap: 20px;
  @media (max-width: ${Breakpoints.md}px) {
    display: block;
    gap: 10px;
  }
  @media (max-width: ${Breakpoints.sm}px) {
    display: block;
    gap: 10px;
  }
`;
const Warningicon = styled.div`
  @media (max-width: ${Breakpoints.sm}px) {
    size: 50;
  }
`;
export const PageTitle = styled.h2`
  color: ${colors.fullBlack};
  font-size: 28px;
  font-weight: 700;
  margin: 0;
  text-align: left;

  @media (max-width: ${Breakpoints.sm}px) {
    font-size: 25px;
    margin-top: 30%;
  }
`;
const TestingInfoBtn = styled(Button)`
  max-width: 215px;
`;
const Hide = styled.div`
  display: none;
`;
const RowList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: ${Breakpoints.sm}px) {
    display: grid;
  }
  @media (max-width: ${Breakpoints.md}px) {
    display: grid;
  }
`;
const Confirmation: React.FC = () => {
  const [expand, setExpand] = useState(false);
  const { isRefersion, isWalkUp,locations, userData, promos } = useSharedState();
  const [id, setId] = useState('');
  const [error,setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  localStorage.setItem('testSelection','false');
  const { form , form:{isExpressSameDayTest, isRapidTest, isAntigen, isPanel}} = useStepsState();
  const { updateFormValues } = useStepsDispatch();
  const [npArray, setNpArray] = useState<any>([]);
  const getDestination = async () => {
    const arr:any = [];
    const response = await getNpDestionation({}).then((result: any) => {
      const data = result.data.data;
      data.forEach((element: any) => {
          arr.push(element.value)
      });
    });
    setNpArray(arr)
   }
  React.useEffect(()=>{
    let amount = 0;
    if(form?.payingMethod !=='Credit card'){
        amount = form?.isAntigen ? 75 : form?.isRapidTest ? 250 : form?.isExpressSameDayTest ? 150 : 90; 
        GoogleAnalytics(form,'/Booking Confirmed','',{payingMethod:form?.payingMethod,amount:amount});
    }
    else {
        GoogleAnalytics(form,'/Booking Confirmed','',{payingMethod:form?.payingMethod,amount:form.amount ?? 0});
    }
  },[])
  React.useEffect(()=>{
    setError(false)
    if(form?.confirmationId===null || form?.confirmationId===undefined){
      getData()
    }
  },[])
 React.useEffect(() => {
  getDestination();
  updateFormValues({...form, testType: isExpressSameDayTest
    ? 'Express'
      : isRapidTest
      ? 'Rapid'
        : isAntigen
        ? 'Antigen'
          : isPanel
          ? 'Flu A&B/Covid-19'
            : 'Standard'});
 },[])
  const allPromocodes =  promos?.filter((e:any) =>  e.isAmountReduce).map((ee:any) => ee.code)
  const employeePromocode = promos?.filter((e:any) =>  e.iswslEmployee).map((ee:any) => ee.code)
const getData=async()=>{
  try {
    const result=await getAppointmentId(form)
    if(result.data.confirmationId===null ){
    setError(true)
    setErrorMessage('Error getting your confirmationId')
    }
    setId(result.data.confirmationId)

  } catch (error) {
    setErrorMessage(error.message)
  }
}
  useEffect(()=>{
    window.history.replaceState(null, "New Page Title", `/confirmation/${form?.confirmationId ? form?.confirmationId : id ? id : form?.acknowledgementId ? form?.acknowledgementId : null}`)
  },[form?.confirmationId || id])

  const [showChangeDatetimeModal, setShowChangeDatetimeModal] = useState(false);
  const { slotsList } = useScheduleStepsState();
  const printableRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => printableRef.current,
  });

  if(isWalkUp.active && isWalkUp?.locationId==config.qbenchCustomerId.KernHealthSystems && form?.cPartnerID === 'KEN001'){
    localStorage.setItem('tenant','kern')
}
  return (
    <Container>
      <Content>
        <PageHeader>
          <PageTitle>
            Your appointment is confirmed.
            <br />
            Don’t forget to download your appointment details.
          </PageTitle>
        </PageHeader>
        <Textbox>
          <Warningicon>
            <RiErrorWarningFill size={25} style={{ fill: '#000' }} />
          </Warningicon>
          <Space />
          <Text
            style={{
              lineHeight: '24px',
            }}
          >
            Please remember to bring your ID and your confirmation number to
            your appointment.
          </Text>
        </Textbox>
        <ExpandWrapContainer>
          <RowList>
            <FlexboxRight>
            <Text> 
                {form?.confirmationId ? 'Confirmation #':id ?'Confirmation #':error?'Acknowledgement #':'Loading...'}
            </Text>
            <ConfirmationNumber>
                {form.confirmationId ? form.confirmationId:id?id:error?form.acknowledgementId:'Loading...'}
            </ConfirmationNumber>
              {/* {!expand && <>
          <Expandrow1>
          <ExpandIcon>
            <MdDateRange size={25}/></ExpandIcon>
            <Expandtext>  <strong> Date</strong><br/>
            September 14, 2020<br/></Expandtext>
         </Expandrow1>
            </>} */}
            </FlexboxRight>
            <FlexboxCenter>
              <Expand
                onClick={() => {
                  setExpand(!expand);
                }}
              >
                {expand ? (
                  <>
                    Hide details <MdExpandLess size={20} />
                  </>
                ) : (
                  <>
                    Show details <MdExpandMore size={20} />
                  </>
                )}
              </Expand>
            </FlexboxCenter>
            <FlexboxLeft>
              <ExpandWrapbutton>
                {/* {expand ? (<>
        <TestingInfoBtn
                  type="button"
                  onClick={() => toggleChangeLocationModal(true)}
                  size="sm"
                  libraryType="default"
                >
                  Change Location
                </TestingInfoBtn>
                <TestingInfoBtn
                  type="button"
                  onClick={() => setShowChangeDatetimeModal(true)}
                  size="sm"
                  libraryType="default"
                >
                  Change date & time
                </TestingInfoBtn>
        </>) : (<>  */}
                <PDFDownloadLink
                className='btn elevated-btn'
                  document={
                    <DownloadableConfirmatiom form={form} periods={slotsList} />
                  }
                  fileName="worksite-labs-confirmation.pdf"
                >
                  {({ loading }) => <b>Download</b>}
                </PDFDownloadLink>

                <Button
                  className="btn elevated-btn"
                  type="button"
                  size="sm"
                  libraryType="default"
                  accentColor={colors.darkBlue}
                  onClick={handlePrint}
                >
                  <b> Print </b>
                </Button>
                {/* </>
        ) } */}
              </ExpandWrapbutton>
            </FlexboxLeft>
          </RowList>

          {expand && (
            <WrapExpand>
              <ExpandRight>
                <Expandrow1>
                  <ExpandIcon>
                    <LocationIcon>
                      <IoLocationOutline size={25} color='#FFFFFF' />
                    </LocationIcon>
                  </ExpandIcon>
                  <Expandtext>
                    <strong>{form?.location?.name}</strong> <br />
                    {form?.location?.address1}, {form?.location?.address2}
                    <br />
                    {form?.cPartnerID === 'LLT001' ? form?.location?.room : ''}
                  </Expandtext>
                </Expandrow1>

                <ExapndlistTag>
                  <DateAndPatient>
                    <Datetag>
                      <ExpandIcon>
                        <MdDateRange size={25} />
                      </ExpandIcon>
                      <Expandtext>
                        {' '}
                        <strong> Date</strong>
                        <br />
                        {form?.slot?.date}
                        <br />
                      </Expandtext>
                    </Datetag>
                    <Patientstag>
                      <ExpandIcon>
                        <IoPersonOutline size={25} />
                      </ExpandIcon>
                      <Expandtext>
                        {' '}
                        <strong> Patients</strong>
                        <br />
                        {form?.registeringFor !== 'minorOnly' && (
                          <>
                            {form?.firstName}{' '}
                            {form?.middleName ? form?.middleName : ''}{' '}
                            {form?.lastName}
                          </>
                        )}
                        {form.minors?.length > 0
                          ? form.minors?.filter((item: any) =>item?.isCanceled !== true).map(
                              (
                                { firstName, middleName, lastName }: any,
                                i: any
                              ) => (
                                <div key={i}>
                                  {firstName} {middleName} {lastName}
                                </div>
                              )
                            )
                          : ''}
                      </Expandtext>
                    </Patientstag>
                  </DateAndPatient>
                  <ExpandCenter>
                    <ExpandVl></ExpandVl>
                  </ExpandCenter>

                  <TimeAndTest>
                    <Timetag>
                      <ExpandIcon>
                        {' '}
                        <BiTimeFive size={25} />
                      </ExpandIcon>
                      <Expandtext>
                        {' '}
                        <strong> Time</strong> <br />
                        {form?.slot?.label}
                        <br />{' '}
                      </Expandtext>
                    </Timetag>
                    {/* <Testtag>
            <ExpandIcon> <IoPeopleOutline size={25}/></ExpandIcon>
            <Expandtext> <strong>Minors</strong> <br/>
            {minors?.length > 0
                      ? minors?.map(({ firstName, lastName }, i) => (
                        <div key={i}>
                          {firstName} {lastName}
                        </div>
                      ))
                      : ''}
            </Expandtext>
            </Testtag> */}
                    <Payment>
                      <ExpandIcon>
                        {' '}
                        <BiDollarCircle size={25} />
                      </ExpandIcon>
                      <Expandtext>
                        {' '}
                        <strong> Payment Method</strong> <br />
                        <div>
                          {' '}
                          {form?.payingMethod === 'No Payment'
                              ? 'No Payment'
                              : !form?.payingMethod
                                ? 'Credit card'
                                : (form?.payingMethod == 'Credit card' ||
                                  form?.payingMethod == 'Credit Card') && form?.promoCode !== ''
                                  && form?.amount !== form?.discountAmount || (employeePromocode?.includes(form?.promoCode) && form?.minors?.length > 0)
                                  ? `${form?.promoCode} VOUCHER and ${form?.payingMethod}` :
                                  (form?.payingMethod == 'Credit card' ||
                                    form?.payingMethod == 'Credit Card') &&
                                    includes(allPromocodes, form?.promoCode) ?
                                    `${form?.promoCode} VOUCHER `
                                    : form?.payingMethod
                          }
                        </div>
                        <br />{' '}
                      </Expandtext>
                    </Payment>
                  </TimeAndTest>

                  <ExpandCenter>
                    <ExpandVl></ExpandVl>
                  </ExpandCenter>

                  <TimeAndTest>
                    <Testtag>
                      <ExpandIcon>
                        {' '}
                        <IoPersonAddOutline size={25} />
                      </ExpandIcon>
                      <Expandtext>
                        {' '}
                        <strong>Test Type</strong> <br />
                        {form.isRapidTest
                          ? 'Rapid PCR Test'
                          : form.isExpressSameDayTest
                          ? 'Express PCR Test'  
                          : form.isAntigen
                          ? 'Antigen Test'
                          : form.isPanel
                          ? 'Flu A&B/Covid-19' 
                          : 'Standard PCR Test'}
                        <br />{' '}
                      </Expandtext>
                    </Testtag>
                  </TimeAndTest>
                </ExapndlistTag>
              </ExpandRight>
              <ExpandLeft></ExpandLeft>
            </WrapExpand>
          )}
        </ExpandWrapContainer>
        {/* {!expand && <>
          <Managebutton />
        </>}
        {expand && <>
          <Expandendtext>
          What to expect when you arrive at the testing center.
          </Expandendtext>
        </>}  */}
        <Managebutton />
        {includes(['SCT', 'ENG', 'GBR'], typeof(form.destination) === 'string' ? form.destination : form.destination?.value ?? '') && (
            <>
                <p style={{ margin: '25px 0px', fontWeight: 600 }}>
                    When you travel back to the United States by air, you
                    are required to show a negative <br />
                    COVID-19 test result or docuentation of recovery from
                    COVID-19 before you board <br /> your flight.
                </p>
                <img
                    src="https://firebasestorage.googleapis.com/v0/b/wsl-multitenancy-dev-ac13b.appspot.com/o/Randox%20Logo.png?alt=media&token=8cd10cf8-7ee3-4cdb-b784-1403f0b30fa6"
                    width={'40%'}
                    height={'30%'}
                ></img>
                <p style={{ paddingBottom: '16px', fontWeight: 600 }}>
                    Book your return covid test with our international
                    partner by clicking this link here:
                </p>
                <LinkButton className='btn fill-btn' style={{borderRadius:999}} href="https://staging-flyusatoukcovid19testing.kinsta.cloud" target="_blank">
                    Book Return Covid Test
                </LinkButton>
            </>
        )}
      </Content>
      <ChangeLocationModal
        openModal={() => setShowChangeDatetimeModal(false)}
      />
      <ChangeDateTimeModal
        showModal={showChangeDatetimeModal}
        closeModal={() => setShowChangeDatetimeModal(false)}
      />
      <Hide>
        <PrintableConfirmation
          ref={printableRef}
          form={form}
          // periods={periods}
          periods={slotsList}
        />
      </Hide>
    </Container>
  );
};
export default Confirmation;
