import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { format } from 'date-fns';
import { config } from '../../../../config';

import InsertInvitation from "@material-ui/icons/InsertInvitation";
import {
    Alert,
    Col,
    Form,
    Input,
    Layout,
    Row,
    Space,
    Select,
    Typography,
} from 'antd';
import GridItem from "./GridItem";
import GridContainer from "./GridContainer";

import CustomTabs from "./CustomTabs_Slot";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import AntDDatePicker from '../../../shared/components/AntDDatePicker';
import { has } from 'lodash'
import styled from 'styled-components';
import Button from "@material-ui/core/Button";
import CardHeader from "./Card/CardHeader";
import CardBody from "./Card/CardBody";
import Card from "./Card/Card.js";
import { Wrapper, Container, Content } from '../../../shared/components/styled';
import Loader from '../../../shared/components/Loader';

import { generatecountyReport,getCountyReport } from '../../api';

const ButtonWrapper = styled.div`
  margin-bottom: 15px;
  max-width: 1200px;
`;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: 200,
        },
        cardTitleWhite: {
            color: "#FFFFFF",
            marginTop: "0px",
            minHeight: "auto",
            fontWeight: "bold",
            fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
            marginBottom: "3px",
            textDecoration: "none"
        },
        typo: {
            paddingLeft: "25%",
            marginBottom: "40px",
            position: "relative"
        },
        note: {
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            bottom: "10px",
            color: "#c0c1c2",
            display: "block",
            fontWeight: "bold",
            fontSize: "13px",
            lineHeight: "13px",
            left: "0",
            marginLeft: "20px",
            position: "absolute",
            width: "260px"
        },
        cardCategoryWhite: {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
    }),
);

function WizardReport() {
    const classes = useStyles();

    const [selectedDate, setSelectedDate] = useState(format(new Date(), 'LL/dd/yyyy'));
    const [Allconsolidationdetails, setAllconsolidationdetails] = useState([]);
    const [Positiveconsolidationdetails, setPositiveconsolidationdetails] = useState([]);
    const [Negativeconsolidationdetails, setNegativeconsolidationdetails] = useState([]);
    const [loading, setLoading] = useState(false);

    // function getCountyReport() {

    //     setLoading(true);
    //     axios({
    //         method: 'post',
    //         url: 'https://us-central1-worksite-labs---dev.cloudfunctions.net/getCountyReport',
    //         data: { data: { runDate: selectedDate } }
    //     })
    //         .then(function (response) {
    //             if (response.status == 200) {
    //                 setLoading(false);
    //                 setAllconsolidationdetails(response.data.result.Allconsolidationdetails)
    //                 setPositiveconsolidationdetails(response.data.result.Positiveconsolidationdetails)
    //                 setNegativeconsolidationdetails(response.data.result.Negativeconsolidationdetails)
    //                 return true;

    //             }

    //         });
    // }


    const handleSearch = () => {
        setLoading(true);
        getCountyReport().then((response: any)=>{
            setLoading(false);
            setAllconsolidationdetails(response.data.Allconsolidationdetails)
            setPositiveconsolidationdetails(response.data.Positiveconsolidationdetails)
            setNegativeconsolidationdetails(response.data.Negativeconsolidationdetails)
        })
    };

    const onreportdetails = (data : any) => {
        console.log("datadata",data)
        generatecountyReport(data)
    };

    useEffect(() => {
        // getCountyReport()
    }, []);


    // if (loading) {
    //     return <Loader />;
    //   }

    return (
        <div>
            <Wrapper backgroundColor="#FFFFFF" >
                <Card>
                    {/* <CardHeader color="primary">
                        <h2 className={classes.cardTitleWhite}>REPORT</h2>
                    </CardHeader> */}
                   {!loading ? <CardBody>
                        <div style={{
                            paddingLeft: "20%",
                            marginBottom: "40px",
                            position: "relative",
                            marginTop:"30px"
                        }}>
                            <div className={classes.note}></div>

                            <div style={{ display: "flex", flex: 1,width:"80%",justifyContent:"space-between" }}>
                                <ButtonWrapper>
                                    <Button variant="contained"  onClick={() => window.location.href = '/dashboard/countyreport'}
                                    >
                                        County Report
                                 </Button>
                                </ButtonWrapper>
                                <ButtonWrapper>
                                    <Button variant="contained"  onClick={() => window.location.href = '/dashboard/weeklyreport'}
                                    >
                                        Weekly Report
                                 </Button>
                                </ButtonWrapper>
                                <ButtonWrapper>
                                    <Button variant="contained"  onClick={() => window.location.href = '/dashboard/consolidationreport'}
                                    >
                                        Consolidation Report
                                 </Button>
                                </ButtonWrapper>
                            </div>
                        </div>



                    </CardBody> :<Loader/>}
                    </Card>

            </Wrapper>

        </div>
    );
}

export default WizardReport;