import React, { useState } from 'react';
import Modal from './Modal';
import Webcam from 'react-webcam';
import { firebase } from '../../../firebase';
import styled from 'styled-components';
import { colors } from '../../../styles/colors';
import { Breakpoints } from '../../../dictionaries';
import { RiCameraSwitchLine } from 'react-icons/ri';
interface btnColor{
    color?:string;
    disabled?:boolean
}
export const UploadPhoto = styled.button`
border: 1px solid;
border-radius: 32px;
font-size: 20px;
font-weight: 500;
color: #ffff;
line-height: 24px;
padding: 10px 20px;
align-items: center;
justify-content: center;
transition: all 0.15s ease-in-out;
width: auto;
cursor: pointer;
display: inline-flex;
position: relative;
background-color:
${({ color,disabled }: btnColor) =>
    disabled?"#5287af":color ? color : colors.darkBlue};
&:focus,
&:active {
    border: 1px solid;
    box-shadow: 0 0 4px ${(colors.darkBlue, 0.8)};
}

@media (max-width: ${Breakpoints.sm}px) {
}
`;
interface Props {
open: boolean;
firstName:string;
onClose: () => void;
onChange:(url:string)=>void;
}
const WebCam: React.FC<Props> = ({ open,firstName, onClose,onChange }) => {
const [URL, setURL] = useState('');
const [loading, setLoading] = useState(false);
const [switchCamra, setSwitchCamra] = useState<boolean>(false);
const switchCam = () =>setSwitchCamra(!switchCamra); 
const [devices, setDevices] = React.useState<boolean>(true);
const dataURLtoFile = (dataurl: any, filename: any) =>{
    var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

    while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
}
const handleChangeFront1 = async (e: any) => {
    try {
    const fileName: any = `B-insurance-${firstName}-${Date.now()}`;
    var file = dataURLtoFile(e, fileName);
    const storageref = firebase.storage().ref();
    const   fileref = storageref.child(file.name.toString());
    await fileref.put(file).then(() => {
    });
    if (
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/tiff-fx' ||
        file.type === 'image/tiff'
    ) {
        const url: string = await fileref.getDownloadURL();
        onChange(url)
        onClose();
        setLoading(false);
        setURL('');
    }
    } catch (e) {
        console.log('Camera Error',e);
    }
};
const webcamRef: any = React.useRef(null);
const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    if(imageSrc!==null)
        setURL(imageSrc);
}, [webcamRef]);
const saveImage=()=>{
    setLoading(true);
    handleChangeFront1(URL);
}
const backCamera = {
    facingMode: { exact: "environment" }
};
const frontCamera = {
    facingMode: "user"
  };

return (
    <Modal
    open={open}
    onClose={onClose}
    maxWidth={800}
    blockScroll={false}
    closeOnOverlayClick={true}
    closeOnEsc={true}
    >
    <div style={{ textAlign: 'center' }}>
        {URL!==''?
        (<>
            <img src={URL} style={{display:"block",marginLeft:"auto",marginRight:"auto"}}/>
            <br /><br />
            <UploadPhoto  className='btn elevated-btn' onClick={()=>{setURL('')}}>Retake photo</UploadPhoto>&nbsp;&nbsp;<UploadPhoto className='btn fill-btn' onClick={saveImage} disabled={loading}>{loading?"Uploading...":"Upload"}</UploadPhoto>
        </>)
            :
        (
        <>
            {
                devices&&
                <div onClick={switchCam} style={{textAlign:'right',paddingRight:'4.5%',cursor:'pointer'}}>
                    <RiCameraSwitchLine size={30} />
                </div>
            }
            <Webcam
                videoConstraints={devices?switchCamra?frontCamera:backCamera:frontCamera}
                width={'90%'}
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                marginHeight={5}
                marginWidth={5}
                screenshotQuality={1}         
                onUserMediaError={(err:any)=>{
                    console.log('Camera Error',err)
                    setDevices(false);
                }}
            />
            <br /><br />
            <UploadPhoto color='#f29a4e' className='btn elevated-btn' onClick={onClose}>Return</UploadPhoto>&nbsp;&nbsp;
            <UploadPhoto onClick={capture} className='btn fill-btn'>Capture photo</UploadPhoto>
        </>
        )}
    </div>
    </Modal>
);
};
export default WebCam;