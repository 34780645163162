export default {
  schedule: '/',
  register: '/register',
  verify: '/verifymail/:mail',
  layout: '/user',
  bookappointment: '/bookappointment',
  // appointments: '/register/appointments',
  // results: '/register/results',
  // location: '/register/location',
  confirmaccount: '/confirmaccount',
  admin: '/admin',
  manage: '/manage',
  report: '/report',
  dashboard: '/dashboard',
  verifyemail: '/verifyemail',
  verifyphone: '/verifyphone',
  resetpassword: '/resetpassword/',
  findemail:'/findemail',
  confirmaccountinfo:'/confirmaccountinfo',
  successfulaccountcreation:'/successfulaccountcreation',
  signin:'/signin',
  forgotpassword:'/forgotpassword',
  upgradeTest:'/upgradeTest/:data',
  reConfirmation: '/reconfirmation',
  walkUpTest:'/bookappointment/walkup/:locationId'
}