import React, { useState, useMemo } from 'react';

import { useManageDispatch, useManageState } from '../provider';
import { rescheduleAppointment, rescheduleCrelioAppointment, updateMangeReservation } from '../api';

import Schedule from '../../shared/components/Schedule';
import { ManagePage } from '../dictionaries';
import { useStepsDispatch } from '../../schedule/provider';
import ReschedulePaymentModal from './ReschedulePaymentModal';
import { cloneDeep } from 'lodash';
import DownGradeModal from './DownGradeModal';

const Reschedule: React.FC = () => {
	const { appointment, showLocation, showStandradTest, apptPrices } = useManageState();
	const { toggleChangeLocationModal } = useStepsDispatch();
	const { updateAppointment, goToPage, toggleLocation } = useManageDispatch();
	const [loading, setLoading] = useState<boolean>();

	const [showPaymentModal, setShowPaymentModal] = useState(false);
	const [showModal, setShowModal] = useState(false);

	const rollbackisExpressSameDayTest = useMemo(() => cloneDeep(appointment?.isExpressSameDayTest), [true]);
	const rollbackisRapidTest = useMemo(() => cloneDeep(appointment?.isRapidTest), [true]);
	const rollbackisStandard = useMemo(() => cloneDeep(appointment?.isRapidTest == false && appointment?.isExpressSameDayTest === false ? true : false), [true]);

	if (!appointment) {
		return null;
	}

	// console.log("appointment", appointment);

	// console.log("appointment", appointment);

	// const AmoutPaid = rollbackisExpressSameDayTest ? (apptPrices.expedited * (minors.length + 1)): (apptPrices.standard * (minors.length + 1))
	// const refundableAmount = rollbackisExpressSameDayTest ? standard ? (AmoutPaid - (60 * (minors.length + 1))): 0 : 0;
	// const AdditonalCharge = !rollbackisExpressSameDayTest ? express ? (60 * (minors.length + 1)): 0 : 0;


	const resheduleAppointmentHandler = async () => {

		let oldTest: any = rollbackisExpressSameDayTest == true ? 2 : rollbackisRapidTest == true ? 3 : 1
		let currentTest = appointment?.isExpressSameDayTest == true ? 2 : appointment.isRapidTest == true ? 3 : 1

		setLoading(true);
		try {
			if (oldTest > currentTest) {
				setShowModal(true);
			} else if (oldTest < currentTest) {
				setShowPaymentModal(true);
			} else {
				let appointmentData: any = appointment;
				delete appointmentData?.qbenchAcknowledgement;
				if (appointmentData?.rescheduleReservationId !== undefined && appointmentData?.reservationId !== undefined) {
					updateMangeReservation(appointment.reservationId, appointment.rescheduleReservationId);
					appointmentData.reservationId = appointment.rescheduleReservationId
					delete appointmentData?.rescheduleReservationId
				}
				if (appointmentData?.location?.limsinfo?.type === 'crelio' || appointmentData?.crelioId) {
					await rescheduleCrelioAppointment(appointmentData);
					goToPage(ManagePage.RescheduleConfirm);
				} else if (appointmentData?.qbenchId) {
					await rescheduleAppointment(appointmentData);
					goToPage(ManagePage.RescheduleConfirm);
				}
			}	
		} catch (error) {
			setLoading(false);
		}
	};


	return (
		<>
			<Schedule
				isManage={true}
				standardTest={showStandradTest}
				prices={apptPrices}
                title="Reschedule Your Appointment: Pick a date and time"
				onLocationChange={() => {
					toggleLocation(true);
				}}
				onSchedule={() => resheduleAppointmentHandler()}
				onFieldUpdate={(update) =>
					updateAppointment({ ...appointment, ...update })
				}
				appointment={appointment}
				confirmBtnText="Confirm Reschedule"
				loading={loading}
			/>
			<ReschedulePaymentModal
				showModal={showPaymentModal}
				closeModal={() => {
					setLoading(false);
					setShowPaymentModal(false);
				}}
			/>
			<DownGradeModal
				showModal={showModal}
				onCancel={() => {
					setShowModal(false);
					setLoading(false);
				}}
				title={"Note"}
				content={"Please call or email customer service at 213-948-2461 or support@worksitelabs.com to request for a refund for your downgrade to a Standard PCR Test"}
				onConfirm={async () => {
					setShowModal(false);
					let appointmentData: any = appointment;
					delete appointmentData?.qbenchAcknowledgement;
					if (appointmentData?.rescheduleReservationId !== undefined && appointmentData?.reservationId !== undefined) {
						updateMangeReservation(appointment.reservationId, appointment.rescheduleReservationId);
						appointmentData.reservationId = appointment.rescheduleReservationId
						delete appointmentData?.rescheduleReservationId
					}
					await rescheduleAppointment(appointmentData);
					goToPage(ManagePage.RescheduleConfirm);
				}}
			/>
		</>
	);
};

export default Reschedule;
