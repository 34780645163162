import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Formik, Form, FormikErrors, FormikValues } from 'formik';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { differenceInYears, format, parse } from 'date-fns';

import { colors } from '../../../../../styles/colors';
import { Breakpoints } from '../../../../../dictionaries';
import { useSharedDispatch, useSharedState } from '../../../../shared/provider';
import {
    Container,
    Content,
    PageHeader,
    Button,
    ErrorText,
    SubHeaderDescription,
} from '../../../../shared/components/styled';
import Select from '../../../../shared/components/form/Select';
import NewInsuranceModal from '../../NewInsuranceScreen/NewInsuranceModal';
import FormLabel from '../../../../shared/components/form/FormLabel';
import Input from '../../../../shared/components/form/Input';
import { DatepickerField } from '../../../../shared/components/Datepicker';

import { BiPlus } from 'react-icons/bi';
// import { useRegisterStepsDispatch, useStepsState } from '../../../provider';
import { useStepsState, useStepsDispatch } from './../../../../schedule/provider';
// import { useStepsDispatch } from './../../../../schedule/provider';
// import { InsuranceCarrierTwo } from '../../NewInsuranceScreen/insuranceCarrierTwo';
import { createInsurance, getUserInsurance, updateRegister } from '../../../api';
import AddInsuranceModal from '../../NewInsuranceScreen/AddInsuranceModal';
import { Insurance } from '../../../../shared/models';
import { config } from '../../../../../config';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { validateComma } from '../../../../../validations';
import { pVerifyEligibilityInquiry } from '../../../../shared/api';
import Alert from '@material-ui/lab/Alert';
import { Tooltip } from 'antd';
import moment from 'moment';
// import ConfirmDialog from '../../NewInsuranceScreen/ConfirmDialog';
import { cloneDeep } from 'lodash';
import ConfirmDialog from '../../NewInsuranceScreen/ConfirmDailog';

const WrapContainer = styled.div`
  margin-bottom: 30px;
  align-items: center;
  padding: 20px;
  width: 100%;
  border: 0.5px solid rgba(36, 61, 77, 0.14);
  box-shadow: -4px -4px 8px rgba(153, 153, 153, 0.05),
    4px 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  @media (max-width: ${Breakpoints.sm}px) {
    position: relative;
    width: 100%;
  }
`;

const BtnContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  flex-wrap: nowrap;
  padding-top: 20px;

  @media (min-width: 450px) {
    flex-direction: row;
  }
`;
const Space = styled.div`
  height: 10px;
  width: 20px;
`;

export const StyledButton = styled.button`
  border: 1px dashed ${colors.grey};
  border-radius: 10px;
  background:white;
  font-size: 16px;
  font-weight: 500;
  color: ${colors.rebrandBlack};
  line-height: 24px;
  padding: 15px 28px;
  transition: all 0.15s ease-in-out;
  width: 100%;
  cursor: pointer;

  &:focus,
  &:active {
    border: 1px dotted ${colors.rebrandBlack};
    box-shadow: 0 0 4px ${(colors.white, 0.8)};
  }
  @media (max-width: ${Breakpoints.sm}px) {
    margin-top: 40px;
  }
`;
const ButtonText = styled.div`
  display: inline-flex;
  align-items: center;
  position: relative;
  text-align: center;

  @media (max-width: ${Breakpoints.sm}px) {
    margin-left: 0px;
  }
`;
const MobileResponsiveGap = styled.div`
  margin-top: 0px;
  @media (max-width: ${Breakpoints.sm}px) {
    margin-top: 10px;
  }
`;
const InputRow = styled.div`
  display: flex;
  flex-direction: column;
  margin: -8px 0;

  @media (min-width: ${Breakpoints.sm}px) {
    flex-direction: row;
    margin: 0 -8px;
  }

  @media (min-width: ${Breakpoints.lg}px) {
    margin: 0 -15px;
  }

  & > * {
    flex-basis: 45%;
    margin: 8px 0;

    @media (min-width: ${Breakpoints.sm}px) {
      margin: 0 8px;
    }
    @media (min-width: ${Breakpoints.lg}px) {
      margin: 0 15px;
    }
  }
`;

const Gap = styled.div`
  width: 120px;
  @media (max-width: ${Breakpoints.sm}px) {
    width: 0px;
  }
`;

const useStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow:
            'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage:
            'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#137cbd',
        backgroundImage:
            'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    },
});

function StyledRadio(props: RadioProps) {
    const classes = useStyles();

    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            // checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            // icon={<span className={classes.icon} />}
            {...props}
            style={{ color: '#243D4D' }}
        />
    );
}

type Props = {
    close: () => void;
    showlist: () => void;
    cPartnerID?: string;
    location?: any
};

const AddInsuranceList: React.FC<Props> = ({ close, showlist, cPartnerID, location }) => {
    const {
        form: { firstName, lastName, birthDate, hasInsurance, insurance, registeringFor, govtId },
        form
    } = useStepsState();
    const {
        userData,

        apptFromSchdule,
        testSelectFlow, isWalkUp, payer
    } = useSharedState();
    let payerType: any = payer ?? [];
    const { goToPrevStep, updateFormValues, goToNextStep } = useStepsDispatch();
    // const { updateFormValues } = useRegisterStepsDispatch();
    const [openModal, setOpenModal] = React.useState<boolean>(false);
    const [errormsg, setErrorMsg] = React.useState<string>('');
    const [isInsurance, setInsurance] = useState(false);
    const [count, setCount] = useState(0);
    const [noInsurance, setNoInsurance] = useState(false);
    const [creditCard, setCreditCard] = useState(false);
    const [state, setState] = useState<string>(insurance.policyHolderbirthDate);
    const [data, setData] = React.useState<Insurance>();
    const { updateInsuranceList } = useSharedDispatch();
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false)
    const [schoolValidationMethod, setSchoolValidationMethod] =
        useState<string>(userData?.insuranceFor || insurance.insuranceFor);

    const [openConfirmModal, setopenConfirmModal] = React.useState<boolean>(false);
    const [schoolShowList, setSchoolShowList] = useState<string>(
        userData?.insuranceFor || insurance.insuranceFor);
    // const location = JSON.parse(JSON.parse(JSON.stringify(localStorage.getItem('location'))));
    const [preInsuranceFor, setpreInsuranceFor] = useState('')
    const handleModalOpen = () => {
        setOpenModal(true);
    };
    const handleModalClose = () => {
        setOpenModal(false);
    };


    const handleConfirmDialogClose = () => {
        setopenConfirmModal(false)
    }

    const handleConfirmDialogOpen = () => {
        setopenConfirmModal(true)
    }


    useEffect(() => {
        setopenConfirmModal(true)
        scrollTOTop()
        handlePaymentMethod()
    }, [])
    const scrollTOTop = () => {

        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    const handlePaymentMethod = () => {

        if (location?.isCreditCard.standard || location?.isCreditCard.antigen || location?.isCreditCard.rapid || location?.isCreditCard.express) {
            setCreditCard(true);
        } else {
            setCreditCard(false);
        }
        if (location?.isInsurance.standard || location?.isInsurance.antigen || location?.isInsurance.rapid || location?.isInsurance.express) {
            setInsurance(true);
        } else {
            setInsurance(false);
        }
        if (location?.isNoInsurance.standard || location?.isNoInsurance.antigen || location?.isNoInsurance.rapid || location?.isNoInsurance.express) {
            setNoInsurance(true);
        } else {
            setNoInsurance(false);
        }
    }

    const confirmationSchoolSelectionHandler = (method: string) => {
        setSchoolValidationMethod(method);
        setSchoolShowList(method);
        setState(method)


    };
    const getInsuranceData = async () => {
        try {
            setLoading(true);

            let resultData = await getUserInsurance(userData?.email);
            if (resultData?.data?.status === 200) {
                setInsurance(resultData?.data?.data);
                updateInsuranceList(resultData?.data?.data)
                close()
            } else {
                updateInsuranceList(null);
            }
            setLoading(false);
        } catch (err: any) {
            setError(err);
            setLoading(false);
        }
    };
    const InitialValues: any = {
      hasInsurance,
      insurance: {
        newCarrierName:
          insurance.newCarrierName !== 'Other' ? '' : insurance?.payerList,
        insuranceId: insurance.insuranceId,
        group: insurance.group,
        subscriberFirstName:
          userData?.isVerified && schoolShowList === "i'm"
            ? insurance.subscriberFirstName
              ? insurance.subscriberFirstName
              : userData?.firstName
            : insurance.subscriberFirstName
            ? insurance.subscriberFirstName
            : firstName,
        subscriberLastName:
          userData?.isVerified && schoolShowList === "i'm"
            ? insurance.subscriberLastName
              ? insurance.subscriberLastName
              : userData?.lastName
            : insurance.subscriberLastName
            ? insurance.subscriberLastName
            : lastName,
        payerList: payerType.find(
          (name: any) =>
            name.label ==
            (insurance?.newCarrierName === 'Other'
              ? insurance?.newCarrierName
              : insurance?.payerList)
        ),
        insuranceFor: insurance.insuranceFor,
        policyHolderFirstName: insurance.policyHolderFirstName,
        policyHolderLastName: insurance.policyHolderLastName,
        policyHolderbirthDate:
          insurance.policyHolderbirthDate &&
          parse(insurance.policyHolderbirthDate, config.dateFormat, new Date()),
        subscriberbirthDate:
          schoolShowList === "i'm" && userData?.birthDate !== null
            ? parse(
                userData?.isVerified
                  ? insurance?.subscriberbirthDate
                    ? insurance?.subscriberbirthDate
                    : userData?.birthDate
                  : insurance?.subscriberbirthDate
                  ? insurance?.subscriberbirthDate
                  : birthDate,
                config.dateFormat,
                new Date()
              )
            : '',
      },
    };
    if (count >2) {
        InitialValues.insurance = { ...InitialValues.insurance, frontCard: insurance?.frontCard ?? '', backCard: insurance?.backCard ?? '' }
    }
    console.log("valuess",InitialValues)
    return (
        <Formik
            enableReinitialize
            initialValues={InitialValues}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              let verifyCount: any = count;
              if (preInsuranceFor != schoolValidationMethod) {
                verifyCount = 0;
              }
              if (!userData?.isVerified)
                updateFormValues({ isIdntHaveAccnt: true });
              // to swap the values for other Option
              let payer = values?.insurance?.payerList?.label;
              let swap1: any;
              let swap2: any;
              if (payer === 'Other') {
                swap1 =
                  values?.insurance?.payerList.label &&
                  values?.insurance?.payerList.value;
                swap2 = values?.insurance?.newCarrierName;
                let temp;

                temp = swap1;
                swap1 = swap2;
                swap2 = temp;
              }
              if (
                verifyCount < 2 &&
                values?.insurance?.payerList?.label !== 'Other'
              ) {
                setLoading(true);
                let insurance = values?.insurance;

                let data: any = {
                  payerCode: insurance?.payerList?.value ?? '',
                  provider: {
                    firstName: '',
                    middleName: '',
                    lastName: insurance?.payerList?.label ?? '',
                    npi: '1467030536',
                  },
                  subscriber: {
                    firstName: insurance?.subscriberFirstName
                      ? insurance?.subscriberFirstName
                      : '',
                    middleName: '',
                    lastName: insurance?.subscriberLastName
                      ? insurance?.subscriberLastName
                      : '',
                    dob:
                      schoolShowList == "i'm"
                        ? format(
                            new Date(insurance?.subscriberbirthDate),
                            config.dateFormat
                          )
                        : '',
                    memberID: insurance.insuranceId ?? '',
                  },
                  dependent: {
                    patient: {
                      firstName: insurance.policyHolderFirstName
                        ? insurance.policyHolderFirstName
                        : '',
                      middleName: '',
                      lastName: insurance.policyHolderLastName
                        ? insurance.policyHolderLastName
                        : '',
                      dob: moment(
                        new Date(insurance?.policyHolderbirthDate ?? null)
                      ).format('MM/DD/YYYY'),
                    },
                  },
                  isSubscriberPatient: 'False',
                  doS_StartDate: moment().format('MM/DD/YYYY'),
                  doS_EndDate: moment().format('MM/DD/YYYY'),
                  requestSource: 'RestAPI',
                  PracticeTypeCode: '74',
                  referenceId: '',
                  Location: '',
                  IncludeTextResponse: false,
                };
                console.log('Data', data);
                if (schoolShowList === "i'm") {
                  data.isSubscriberPatient = 'True';
                  delete data.dependent;
                  const result: any = await pVerifyEligibilityInquiry(data);
                  if (result?.data?.status === 'Active') {
                    setError('');
                    //create Insurance
                    if (userData?.isVerified) {
                      let resultdata = await createInsurance({
                        email: userData?.email ? userData?.email : '',
                        insuranceId: !!data ? insurance.insuranceId : '',
                        group: !!data ? values.insurance.group : '',
                        subscriberFirstName:
                          values.insurance.subscriberFirstName,
                        subscriberLastName: values.insurance.subscriberLastName,
                        subscriberbirthDate: format(
                          new Date(values.insurance.subscriberbirthDate),
                          config.dateFormat
                        ),
                        frontCard: '',
                        backCard: '',
                        payerList: values.insurance.payerList?.label,
                        insuranceFor: !!data ? schoolShowList : '',
                        policyHolderFirstName: '',
                        policyHolderLastName: '',
                        policyHolderbirthDate: '',
                        pVerify: true,
                        createdDate: moment(new Date()).format(
                          'YYYY-MM-DD HH:mm:ss'
                        ),
                      });

                      if (resultdata?.data?.status === 200) {
                        getInsuranceData();
                      }
                    } else {
                      updateFormValues({
                        ...values,
                        insurance: {
                          insuranceId: values.insurance.insuranceId,
                          group: values.insurance.group,
                          subscriberFirstName: values.insurance
                            .subscriberFirstName
                            ? values.insurance.subscriberFirstName
                            : '',
                          subscriberLastName: values.insurance
                            .subscriberLastName
                            ? values.insurance.subscriberLastName
                            : '',
                          frontCard: values.insurance.frontCard,
                          backCard: values.insurance.backCard,
                          payerList: values.insurance.payerList?.label,
                          insuranceFor: schoolShowList,
                          policyHolderFirstName: values.insurance
                            .policyHolderFirstName
                            ? values.insurance.policyHolderFirstName
                            : '',
                          policyHolderLastName: values.insurance
                            .policyHolderLastName
                            ? values.insurance.policyHolderLastName
                            : '',
                          policyHolderbirthDate: '',
                          pVerify: true,
                          subscriberbirthDate: values.insurance
                            .subscriberbirthDate
                            ? format(
                                new Date(values.insurance.subscriberbirthDate),
                                config.dateFormat
                              )
                            : '',
                        },
                      });

                      setData({
                        insuranceId: values.insurance.insuranceId,
                        group: values.insurance.group,
                        subscriberFirstName: values.insurance
                          .subscriberFirstName
                          ? values.insurance.subscriberFirstName
                          : '',
                        subscriberLastName: values.insurance.subscriberLastName
                          ? values.insurance.subscriberLastName
                          : '',
                        frontCard: values.insurance.frontCard,
                        backCard: values.insurance.backCard,
                        payerList: !!values.insurance.payerList?.label
                          ? values.insurance.payerList?.label
                          : '',
                        newCarrierName: '',
                        id: '',
                        email: '',
                        insuranceFor: schoolShowList,
                        policyHolderFirstName:
                          values.insurance.policyHolderFirstName ?? '',
                        policyHolderLastName:
                          values.insurance.policyHolderLastName ?? '',
                        policyHolderbirthDate: '',
                        pVerify: true,
                        createdDate: moment(new Date()).format(
                          'YYYY-MM-DD HH:mm:ss'
                        ),
                        subscriberbirthDate: values.insurance
                          .subscriberbirthDate
                          ? format(
                              new Date(values.insurance.subscriberbirthDate),
                              config.dateFormat
                            )
                          : '',
                      });
                      goToNextStep();
                    }
                  } else {
                    setError(
                      result?.data?.errorDescription ??
                        'Something wents wrong please try again'
                    );
                    scrollTOTop();
                    setCount(verifyCount + 1);
                    updateFormValues({
                      insurance: {
                        insuranceId: '',
                        group: '',
                        subscriberFirstName: '',
                        subscriberLastName: '',
                        payerList: '',
                        insuranceFor: '',
                        policyHolderFirstName: '',
                        policyHolderLastName: '',
                        policyHolderbirthDate: '',
                        subscriberbirthDate: '',
                      },
                    });
                  }
                } else {
                  const result: any = await pVerifyEligibilityInquiry(data);
                  // if (schoolShowList !== "i'm" && userData?.isVerified) {
                  insurance = {
                    ...insurance,
                    subscriberFirstName: insurance?.policyHolderFirstName,
                    subscriberLastName: insurance?.policyHolderLastName,
                    subscriberbirthDate: insurance?.policyHolderbirthDate,
                  };
                  // }
                  // if (schoolShowList !== "i'm" && !userData?.isVerified) {
                  //     insurance = { ...insurance, subscriberFirstName: insurance?.policyHolderFirstName, subscriberLastName: insurance?.policyHolderLastName, policyHolderbirthDate: birthDate }
                  // }
                  if (result?.data?.status === 'Active') {
                    setError('');
                    //create Insurance
                    if (userData?.isVerified) {
                      const d = {
                        email: userData?.email ? userData?.email : '',
                        insuranceId: !!data ? insurance.insuranceId : '',
                        group: !!data ? values.insurance.group : '',
                        subscriberFirstName: !!data
                          ? values.insurance.policyHolderFirstName
                          : '',
                        subscriberLastName: !!data
                          ? values.insurance.policyHolderLastName
                          : '',
                        frontCard: '',
                        backCard: '',
                        payerList: values.insurance.payerList?.label,
                        insuranceFor: !!data ? schoolShowList : '',
                        policyHolderFirstName: !!data
                          ? insurance.policyHolderFirstName
                          : '',
                        policyHolderLastName: !!data
                          ? insurance.policyHolderLastName
                          : '',
                        policyHolderbirthDate: !!data
                          ? format(
                              new Date(insurance?.policyHolderbirthDate),
                              config.dateFormat
                            )
                          : '',
                        pVerify: true,
                        createdDate: moment(new Date()).format(
                          'YYYY-MM-DD HH:mm:ss'
                        ),
                      };
                      console.log('d', d);
                      let resultdata = await createInsurance(d);

                      if (resultdata?.data?.status === 200) {
                        getInsuranceData();
                      }
                    } else {
                      updateFormValues({
                        ...values,
                        insurance: {
                          insuranceId: values.insurance.insuranceId,
                          group: values.insurance.group,
                          subscriberFirstName: values.insurance
                            .policyHolderFirstName
                            ? values.insurance.policyHolderFirstName
                            : values.insurance.subscriberFirstName
                            ? values.insurance.subscriberFirstName
                            : '',
                          subscriberLastName: values.insurance
                            .policyHolderLastName
                            ? values.insurance.policyHolderLastName
                            : values.insurance.subscriberLastName
                            ? values.insurance.subscriberLastName
                            : '',
                          frontCard: values.insurance.frontCard,
                          backCard: values.insurance.backCard,
                          payerList: values.insurance.payerList?.label,
                          insuranceFor: schoolShowList,
                          policyHolderFirstName: values.insurance
                            .policyHolderFirstName
                            ? values.insurance.policyHolderFirstName
                            : '',
                          policyHolderLastName: values.insurance
                            .policyHolderLastName
                            ? values.insurance.policyHolderLastName
                            : '',
                          // policyHolderbirthDate: userData?.birthDate
                          policyHolderbirthDate:
                            values.insurance.policyHolderbirthDate &&
                            format(
                              values.insurance.policyHolderbirthDate,
                              config.dateFormat
                            ),
                          pVerify: true,
                          createdDate: moment(new Date()).format(
                            'YYYY-MM-DD HH:mm:ss'
                          ),
                          subscriberbirthDate: values.insurance
                            .subscriberbirthDate
                            ? format(
                                new Date(values.insurance.subscriberbirthDate),
                                config.dateFormat
                              )
                            : '',
                        },
                      });
                      setData({
                        insuranceId: values.insurance.insuranceId,
                        group: values.insurance.group,
                        subscriberFirstName: values.insurance
                          .policyHolderFirstName
                          ? values.insurance.policyHolderFirstName
                          : values.insurance.subscriberFirstName,
                        subscriberLastName: values.insurance
                          .policyHolderLastName
                          ? values.insurance.policyHolderLastName
                          : values.insurance.subscriberLastName,
                        frontCard: values.insurance.frontCard,
                        backCard: values.insurance.backCard,
                        payerList: !!values.insurance.payerList?.label
                          ? values.insurance.payerList?.label
                          : '',
                        newCarrierName: '',
                        id: '',
                        email: '',
                        insuranceFor: schoolShowList,
                        policyHolderFirstName: values.insurance
                          .policyHolderFirstName
                          ? values.insurance.policyHolderFirstName
                          : '',
                        policyHolderLastName: values.insurance
                          .policyHolderLastName
                          ? values.insurance.policyHolderLastName
                          : '',
                        policyHolderbirthDate:
                          values.insurance.policyHolderbirthDate &&
                          format(
                            values.insurance.policyHolderbirthDate,
                            config.dateFormat
                          ),
                        //: userData?.birthDate ? userData?.birthDate : ''
                        pVerify: true,
                        createdDate: moment(new Date()).format(
                          'YYYY-MM-DD HH:mm:ss'
                        ),
                        subscriberbirthDate: values.insurance
                          .subscriberbirthDate
                          ? format(
                              new Date(values.insurance.subscriberbirthDate),
                              config.dateFormat
                            )
                          : '',
                      });
                      goToNextStep();
                    }
                  } else {
                    setError(
                      result?.data?.errorDescription ??
                        'Something wents wrong please try again'
                    );
                    scrollTOTop();
                    setCount(verifyCount + 1);
                    updateFormValues({
                      insurance: {
                        insuranceId: '',
                        group: '',
                        subscriberFirstName: '',
                        subscriberLastName: '',
                        payerList: '',
                        insuranceFor: '',
                        policyHolderFirstName: '',
                        policyHolderLastName: '',
                        policyHolderbirthDate: '',
                        subscriberbirthDate: '',
                      },
                    });
                  }
                }
                setLoading(false);
              } else if (schoolShowList.length == 0)
                setErrorMsg('Pease choose any of the option');
              else {
                if (schoolShowList === "i'm") {
                  console.log('enters');
                  setErrorMsg('');
                  updateFormValues({
                    ...values,
                    insurance: {
                      insuranceId: values.insurance.insuranceId,
                      group: values.insurance.group,
                      subscriberFirstName: values.insurance.subscriberFirstName,
                      subscriberLastName: values.insurance.subscriberLastName,
                      frontCard: values.insurance.frontCard,
                      backCard: values.insurance.backCard,
                      payerList:
                        payer === 'Other'
                          ? swap1
                          : values.insurance.payerList?.label,

                      newCarrierName: payer === 'Other' ? swap2 : '',
                      insuranceFor: schoolShowList,
                      policyHolderFirstName:
                        values.insurance.policyHolderFirstName,
                      policyHolderLastName:
                        values.insurance.policyHolderLastName,
                      policyHolderbirthDate: '',
                      pVerify: false,
                      createdDate: moment(new Date()).format(
                        'YYYY-MM-DD HH:mm:ss'
                      ),
                      subscriberbirthDate: values.insurance.subscriberbirthDate
                        ? format(
                            new Date(values.insurance.subscriberbirthDate),
                            config.dateFormat
                          )
                        : '',
                    },
                  });
                  setData({
                    insuranceId: values.insurance.insuranceId,
                    group: values.insurance.group,
                    subscriberFirstName: values.insurance.subscriberFirstName,
                    subscriberLastName: values.insurance.subscriberLastName,
                    frontCard: values.insurance.frontCard,
                    backCard: values.insurance.backCard,
                    payerList:
                      payer === 'Other'
                        ? swap1
                        : values.insurance.payerList?.label,

                    newCarrierName: payer === 'Other' ? swap2 : '',
                    id: '',
                    email: '',
                    insuranceFor: schoolShowList,
                    policyHolderFirstName: values.insurance
                      .policyHolderFirstName
                      ? values.insurance.policyHolderFirstName
                      : '',
                    policyHolderLastName: values.insurance.policyHolderLastName
                      ? values.insurance.policyHolderLastName
                      : '',
                    policyHolderbirthDate: '',
                    pVerify: false,
                    createdDate: moment(new Date()).format(
                      'YYYY-MM-DD HH:mm:ss'
                    ),
                    subscriberbirthDate: values.insurance.subscriberbirthDate
                      ? format(
                          new Date(values.insurance.subscriberbirthDate),
                          config.dateFormat
                        )
                      : '',
                  });
                  setOpenModal(true);
                  // updateFormValues({
                  //     insurance: {
                  //         insuranceId: "",
                  //         group: "",
                  //         subscriberFirstName: "",
                  //         subscriberLastName: "",
                  //         payerList: "",
                  //         insuranceFor: "",
                  //         policyHolderFirstName: "",
                  //         policyHolderLastName: "",
                  //         policyHolderbirthDate: "",
                  //         subscriberbirthDate: ''
                  //     }
                  // })
                } else if (schoolShowList != "i'm") {
                  setErrorMsg('');
                  updateFormValues({
                    ...values,
                    insurance: {
                      insuranceId: values.insurance.insuranceId,
                      group: values.insurance.group,
                      subscriberFirstName: values.insurance
                        .policyHolderFirstName
                        ? values.insurance.policyHolderFirstName
                        : '',
                      subscriberLastName: values.insurance.policyHolderLastName
                        ? values.insurance.policyHolderLastName
                        : '',
                      frontCard: values.insurance.frontCard,
                      backCard: values.insurance.backCard,
                      payerList:
                        payer === 'Other'
                          ? swap1
                          : values.insurance.payerList?.label,

                      newCarrierName: payer === 'Other' ? swap2 : '',
                      insuranceFor: schoolShowList,
                      policyHolderFirstName:
                        values.insurance.policyHolderFirstName,
                      policyHolderLastName:
                        values.insurance.policyHolderLastName,
                      policyHolderbirthDate:
                        values.insurance.policyHolderbirthDate &&
                        format(
                          values.insurance.policyHolderbirthDate,
                          config.dateFormat
                        ),
                      pVerify: false,
                      createdDate: moment(new Date()).format(
                        'YYYY-MM-DD HH:mm:ss'
                      ),
                      subscriberbirthDate: values.insurance.subscriberbirthDate
                        ? format(
                            new Date(values.insurance.subscriberbirthDate),
                            config.dateFormat
                          )
                        : '',
                    },
                  });
                  //Setting values so that these values can be pass to AddInsuranceModal
                  setData({
                    insuranceId: values.insurance.insuranceId,
                    group: values.insurance.group,
                    subscriberFirstName: values.insurance.policyHolderFirstName
                      ? values.insurance.policyHolderFirstName
                      : '',
                    subscriberLastName: values.insurance.policyHolderLastName
                      ? values.insurance.policyHolderLastName
                      : '',
                    frontCard: values.insurance.frontCard,
                    backCard: values.insurance.backCard,
                    payerList:
                      payer === 'Other'
                        ? swap1
                        : values.insurance.payerList?.label,
                    newCarrierName: payer === 'Other' ? swap2 : '',
                    id: '',
                    email: '',
                    insuranceFor: schoolShowList,
                    policyHolderFirstName: values.insurance
                      .policyHolderFirstName
                      ? values.insurance.policyHolderFirstName
                      : '',
                    policyHolderLastName: values.insurance.policyHolderLastName
                      ? values.insurance.policyHolderLastName
                      : '',
                    policyHolderbirthDate:
                      values.insurance.policyHolderbirthDate &&
                      format(
                        values.insurance.policyHolderbirthDate,
                        config.dateFormat
                      ),
                    pVerify: false,
                    createdDate: moment(new Date()).format(
                      'YYYY-MM-DD HH:mm:ss'
                    ),
                    subscriberbirthDate: values.insurance.subscriberbirthDate
                      ? format(
                          new Date(values.insurance.subscriberbirthDate),
                          config.dateFormat
                        )
                      : '',
                  });
                  setOpenModal(true);
                  // updateFormValues({
                  //     insurance: {
                  //         insuranceId: "",
                  //         group: "",
                  //         subscriberFirstName: "",
                  //         subscriberLastName: "",
                  //         payerList: "",
                  //         insuranceFor: "",
                  //         policyHolderFirstName: "",
                  //         policyHolderLastName: "",
                  //         policyHolderbirthDate: "",
                  //         subscriberbirthDate: ''
                  //     }
                  // })
                }
              }
              setpreInsuranceFor(schoolValidationMethod);
              // resetForm({ values: "" });
              updateRegister({
                insurance: {
                  insuranceId: '',
                  group: '',
                  subscriberFirstName: '',
                  subscriberLastName: '',
                  payerList: '',
                  insuranceFor: '',
                  policyHolderFirstName: '',
                  policyHolderLastName: '',
                  policyHolderbirthDate: '',
                  subscriberbirthDate: '',
                },
              });
              // setSubmitting(false);
            }}
        >
            {({ values, resetForm }) => {
                return (
                  <>
                    <Form>
                      <>
                        {error && (
                          <Alert severity="warning">
                            The insurance information that you provided was not
                            able to be verified. Please double check the
                            information on your card.{' '}
                          </Alert>
                        )}
                        <SubHeaderDescription
                          style={{ marginBottom: '5px', fontSize: '18px' }}
                        >
                          <b>Who is the policy holder?</b>
                        </SubHeaderDescription>
                        <WrapContainer>
                          <FormControl component="fieldset" variant="standard">
                            <RadioGroup
                              name={'insuranceFor'}
                              value={schoolValidationMethod}
                              onChange={(event) => {
                                confirmationSchoolSelectionHandler(
                                  event.target.value
                                );
                                updateRegister({
                                  insuranceFor: event?.target?.value,
                                });
                                // resetForm({ values: '' })
                                updateFormValues({
                                  insurance: {
                                    insuranceId: '',
                                    group: '',
                                    subscriberFirstName: '',
                                    subscriberLastName: '',
                                    payerList: '',
                                    insuranceFor: '',
                                    policyHolderFirstName: '',
                                    policyHolderLastName: '',
                                    policyHolderbirthDate: '',
                                    subscriberbirthDate: '',
                                  },
                                });
                              }}
                            >
                              <FormControlLabel
                                value="i'm"
                                control={<StyledRadio />}
                                // checked={schoolShowList === 'student'}
                                label={'I am'}
                              />
                              <Gap />
                              <FormControlLabel
                                value="my spouse or partner"
                                control={<StyledRadio />}
                                // checked={schoolShowList === 'parentOrGuardian'}
                                label={'My spouse or partner'}
                              />
                              <Gap />
                              <FormControlLabel
                                value="my Parents"
                                control={<StyledRadio />}
                                // checked={schoolShowList === 'staff'}
                                label={'My Parents'}
                              />
                              <Gap />
                              <FormControlLabel
                                value="someone else"
                                control={<StyledRadio />}
                                // checked={schoolShowList === 'faculty'}
                                label={'Someone else'}
                              />
                            </RadioGroup>
                          </FormControl>
                        </WrapContainer>
                      </>
                      <FormLabel label="Insurance Carrier Name">
                        <Select<string>
                          name="insurance.payerList"
                          options={payerType}
                          defaultValue={insurance.payerList}
                          onChange={(value) => {
                            updateFormValues({ payerList: value });
                          }}
                          validate={(value) => {
                            if (!value) {
                              return 'Required field';
                            }
                          }}
                        />
                      </FormLabel>
                      {values?.insurance?.payerList?.label == 'Other' ? (
                        <FormLabel label="New Insurance Carrier Name">
                          <Tooltip title="Please input the Other Carrier Name which is not in the payerlist.">
                            <span></span>
                            <Input
                              name="insurance.newCarrierName"
                              value={values.insurance.newCarrierName}
                              isRequired
                              onChange={(value) => {
                                console.log(value);
                                updateFormValues({
                                  newCarrierName: value ? value.trim() : '',
                                });
                              }}
                              validate={(value) => {
                                if (!value || !value.trim().length) {
                                  return 'Required field';
                                }
                                if (!validateComma.test(value)) {
                                  return 'Commas Not Allowed';
                                }
                              }}
                            />
                          </Tooltip>
                        </FormLabel>
                      ) : (
                        ''
                      )}

                      <FormLabel label="Insurance Member ID">
                        <Tooltip title="Please input the Member ID exactly how it is displayed on the insurance card.">
                          <span></span>
                          <Input
                            name="insurance.insuranceId"
                            value={values.insurance.insuranceId}
                            isRequired
                            onChange={(value) => {
                              updateFormValues({
                                insuranceId: value ? value.trim() : '',
                              });
                            }}
                            validate={(value) => {
                              if (!value || !value.trim().length) {
                                return 'Required field';
                              }
                              if (!validateComma.test(value)) {
                                return 'Commas Not Allowed';
                              }
                            }}
                          />
                        </Tooltip>
                      </FormLabel>

                      <MobileResponsiveGap />
                      <FormLabel label="Insurance Group (optional)">
                        <Tooltip title="Please input the Member Group # exactly how it is displayed on the insurance card">
                          <span></span>
                          <Input
                            name="insurance.group"
                            value={values.insurance.group}
                            // isRequired
                            onChange={(value) => {
                              updateFormValues({
                                group: value ? value.trim() : '',
                              });
                            }}
                            validate={(value) => {
                              //   if (!value || !value.trim().length) {
                              //     return 'Required field';
                              //   }
                              if (!validateComma.test(value)) {
                                return 'Commas Not Allowed';
                              }
                            }}
                          />
                        </Tooltip>
                      </FormLabel>
                      <MobileResponsiveGap />
                      {schoolShowList !== "i'm" && (
                        <>
                          <InputRow>
                            <FormLabel label="Policy Holder First Name">
                              <Tooltip title="This is the first name of the primary person. Please input the first name exactly how it is displayed on the insurance card.">
                                <span></span>
                                <Input
                                  name="insurance.policyHolderFirstName"
                                  value={
                                    values.insurance.policyHolderFirstName ?? ''
                                  }
                                  
                                  onChange={(value) => {
                                    if (/^[a-zA-Z]+$/.test(value)) {
                                      updateFormValues({
                                        policyHolderFirstName: value
                                          ? value.trim()
                                          : '',
                                        subscriberFirstName: value
                                          ? value.trim()
                                          : '',
                                      });
                                    }
                                      
                                  }}
                                  isRequired
                                  validate={(value) => {
                                    if (!/^[a-zA-Z]+$/.test(value)) {
                                      return 'Only text is allowed';
                                    }
                                    if (!value || !value.trim().length) {
                                      return 'Required field';
                                    } else if (
                                      value.length > config.nameMaxLimit
                                    ) {
                                      return 'Name length must below 50 characters';
                                    }
                                  }}
                                />
                              </Tooltip>
                            </FormLabel>

                            <FormLabel label="Policy Holder Last Name">
                              <Tooltip title="This is the last name of the primary person. Please input the last name exactly how it is displayed on the insurance card.">
                                <span></span>
                                <Input
                                  name="insurance.policyHolderLastName"
                                  value={
                                    values.insurance.policyHolderLastName ?? ''
                                  }
                                  onChange={(value) => {
                                    if (/^[a-zA-Z]+$/.test(value)) {
                                      updateFormValues({
                                        policyHolderLastName: value
                                          ? value.trim()
                                          : '',
                                      });
                                    }
                                  }}
                                  isRequired
                                  validate={(value) => {
                                    if (!/^[a-zA-Z]+$/.test(value)) {
                                      return 'Only text is allowed';
                                    }
                                    if (!value || !value.trim().length) {
                                      return 'Required field';
                                    } else if (
                                      value.length > config.nameMaxLimit
                                    ) {
                                      return 'Name length must below 50 characters';
                                    }
                                  }}
                                />
                              </Tooltip>
                            </FormLabel>
                          </InputRow>
                          <InputRow>
                            <FormLabel label="Policy Holder Date of Birth">
                              <DatepickerField
                                name="insurance.policyHolderbirthDate"
                                isRequired
                                inputPlaceholder="MM/DD/YYYY"
                                maxDate={new Date()}
                                value={values.insurance.policyHolderbirthDate}
                                validate={(value) => {
                                  let error;

                                  if (
                                    differenceInYears(new Date(), value) < 18
                                  ) {
                                    error = 'You must be 18 years old or above';
                                  }

                                  return error;
                                }}
                                onChange={(value) => {
                                  updateFormValues({
                                    policyHolderbirthDate: value,
                                  });
                                }}
                                tooltip="This is the DOB of the primary person. Please input the DOB exactly how it is displayed on the insurance card."
                              />
                            </FormLabel>
                          </InputRow>
                        </>
                      )}
                      {}

                      {schoolShowList == "i'm" && userData?.isVerified && (
                        <>
                          <InputRow>
                            <Tooltip title="Please input the subscriber first name exactly how it is displayed on the insurance card">
                              <span></span>
                              <FormLabel label="Subscriber First Name">
                                <Input
                                  name="insurance.subscriberFirstName"
                                  // value={userData?.firstName}
                                  value={values.insurance.subscriberFirstName}
                                  onChange={(value) => {
                                    if (/^[a-zA-Z]+$/.test(value)) {
                                      updateFormValues({
                                        subscriberFirstName: value
                                          ? value.trim()
                                          : '',
                                      });
                                    }
                                  }}
                                  validate={(value) => {
                                    if (!/^[a-zA-Z]+$/.test(value)) {
                                      return 'Only text is allowed';
                                    }
                                    if (!value || !value.trim().length) {
                                      return 'Required field';
                                    } else if (
                                      value.length > config.nameMaxLimit
                                    ) {
                                      return 'Name length must below 50 characters';
                                    }
                                  }}
                                />
                              </FormLabel>
                            </Tooltip>
                            <FormLabel label="Subscriber Last Name">
                              <Tooltip title="Please input the subscriber last name exactly how it is displayed on the insurance card.">
                                <span></span>
                                <Input
                                  name="insurance.subscriberLastName"
                                  //value={userData?.lastName}
                                  value={values.insurance.subscriberLastName}
                                  onChange={(value) => {
                                    if (/^[a-zA-Z]+$/.test(value)) {
                                      updateFormValues({
                                        subscriberLastName: value
                                          ? value.trim()
                                          : '',
                                      });
                                    }
                                  }}
                                  isRequired
                                  validate={(value) => {
                                    if (!/^[a-zA-Z]+$/.test(value)) {
                                      return 'Only text is allowed';
                                    }
                                    if (!value || !value.trim().length) {
                                      return 'Required field';
                                    } else if (
                                      value.length > config.nameMaxLimit
                                    ) {
                                      return 'Name length must below 50 characters';
                                    }
                                    
                                  }}
                                />
                              </Tooltip>
                            </FormLabel>
                          </InputRow>
                          <FormLabel label="Subscriber Date of Birth">
                            <DatepickerField
                              name="insurance.subscriberbirthDate"
                              isRequired
                              value={values.insurance.subscriberbirthDate}
                              inputPlaceholder="MM/DD/YYYY"
                              validate={(value) => {
                                let error;

                                if (differenceInYears(new Date(), value) < 18) {
                                  error = 'You must be 18 years old or above';
                                }

                                return error;
                              }}
                              tooltip="Please input the subscriber DOB exactly how it is displayed on the insurance card."
                            />
                          </FormLabel>
                        </>
                      )}
                      {schoolShowList == "i'm" && !userData?.isVerified && (
                        <>
                          <InputRow>
                            <Tooltip title="Please input the subscriber first name exactly how it is displayed on the insurance card">
                              <span></span>
                              <FormLabel label="Subscriber First Name">
                                <Input
                                  name="insurance.subscriberFirstName"
                                  value={values.insurance.subscriberFirstName}
                                  onChange={(value) => {
                                    updateFormValues({
                                      subscriberFirstName: value
                                        ? value.trim()
                                        : '',
                                    });
                                  }}
                                  validate={(value) => {
                                    if (!value || !value.trim().length) {
                                      return 'Required field';
                                    } else if (
                                      value.length > config.nameMaxLimit
                                    ) {
                                      return 'Name length must below 50 characters';
                                    }
                                  }}
                                />
                              </FormLabel>
                            </Tooltip>
                            <FormLabel label="Subscriber Last Name">
                              <Tooltip title="Please input the subscriber last name exactly how it is displayed on the insurance card.">
                                <span></span>
                                <Input
                                  name="insurance.subscriberLastName"
                                  value={values.insurance.subscriberLastName}
                                  onChange={(value) => {
                                    updateFormValues({
                                      subscriberLastName: value
                                        ? value.trim()
                                        : '',
                                    });
                                  }}
                                  isRequired
                                  validate={(value) => {
                                    if (!value || !value.trim().length) {
                                      return 'Required field';
                                    } else if (
                                      value.length > config.nameMaxLimit
                                    ) {
                                      return 'Name length must below 50 characters';
                                    }
                                  }}
                                />
                              </Tooltip>
                            </FormLabel>
                          </InputRow>
                          <FormLabel label="Subscriber Date of Birth">
                            <DatepickerField
                              name="insurance.subscriberbirthDate"
                              isRequired
                              value={values.insurance.subscriberbirthDate}
                              inputPlaceholder="MM/DD/YYYY"
                              validate={(value) => {
                                let error;

                                if (differenceInYears(new Date(), value) < 18) {
                                  error = 'You must be 18 years old or above';
                                }

                                return error;
                              }}
                              tooltip="Please input the subscriber DOB exactly how it is displayed on the insurance card."
                            />
                          </FormLabel>
                        </>
                      )}

                      {/* {schoolShowList !== "i'm" && (
                            <>
                                <InputRow>
                                    <Tooltip title="Please input the subscriber first name exactly how it is displayed on the insurance card">
                                        <span></span>
                                        <FormLabel label="Subscriber First Name">
                                            <Input
                                                name="insurance.subscriberFirstName"
                                                value={values.insurance.policyHolderFirstName}
                                                disabled={true}
                                            />
                                        </FormLabel>
                                    </Tooltip>
                                    <FormLabel label="Subscriber Last Name">
                                        <Tooltip title="Please input the subscriber first name exactly how it is displayed on the insurance card">
                                            <span></span>
                                            <Input
                                                name="insurance.subscriberLastName"
                                                value={values.insurance.policyHolderLastName}
                                                disabled={true}

                                            />
                                        </Tooltip>
                                    </FormLabel>
                                </InputRow>
                            </>
                        )} */}
                      {count > 2 && (
                        <StyledButton type="submit">
                          <ButtonText>
                            <BiPlus size={25} /> <Space />{' '}
                            {'Upload A Picture Of Your Insurance Card'}
                          </ButtonText>
                        </StyledButton>
                      )}
                      <AddInsuranceModal
                        handleModalClose={handleModalClose}
                        open={openModal}
                        showlist={showlist}
                        data={data}
                        cPartnerID={cPartnerID}
                      />

                      {errormsg && <ErrorText hasError>{errormsg}</ErrorText>}
                      <BtnContainer>
                        <Button
                          libraryType="default"
                          type="button"
                          onClick={() => {
                            if (
                              apptFromSchdule &&
                              testSelectFlow &&
                              !userData?.isVerified
                            ) {
                              try {
                                if (
                                  location?.qbenchCustomerId ===
                                    config.qbenchCustomerId.BoysGirlsClub ||
                                  (location?.qbenchCustomerId ===
                                    config.qbenchCustomerId
                                      .SFOAirport_BARTStation &&
                                    location?.cPartnerID === 'SFO001')
                                )
                                  goToPrevStep();
                              } catch (e) {}
                              if (
                                isWalkUp.active &&
                                isWalkUp.locationId ===
                                  config.qbenchCustomerId.PCMADestination
                              ) {
                                goToPrevStep();
                              }
                              goToPrevStep();
                              goToPrevStep();
                              goToPrevStep();
                              if (!creditCard && isInsurance && !noInsurance) {
                                goToPrevStep();
                              }
                              // goToPrevStep();
                            } else close();
                          }}
                          className="btn elevated-btn"
                        >
                          {apptFromSchdule &&
                          testSelectFlow &&
                          !userData?.isVerified
                            ? 'Return'
                            : 'Cancel'}
                        </Button>
                        <Space />
                        <Button
                          libraryType="primary"
                          type="submit"
                          className={
                            schoolShowList === '' ||
                            schoolShowList === undefined ||
                            loading
                              ? 'btn fill-btn-d'
                              : 'btn fill-btn'
                          }
                          disabled={
                            schoolShowList === '' ||
                            schoolShowList === undefined ||
                            loading
                          }
                        >
                          {loading ? 'Loading' : 'Save & Continue'}
                        </Button>
                      </BtnContainer>
                    </Form>
                    <ConfirmDialog
                      close={handleConfirmDialogClose}
                      open={openConfirmModal}
                    />
                  </>
                );
            }}
        </Formik>
    );
};

export default AddInsuranceList;
