import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import logo from '../../../assets/logo_2.png';
import logo1 from '../../../assets/WLABS_KO.png';
import haLogo from '../../../assets/ha-logo.png';
import pwLogo from '../../../assets/pw-header.png';
import stack from '../../../assets/stack.png';
import { Breakpoints } from '../../../dictionaries';
import { signOut } from '../../admin/api';
import { colors } from '../../../styles/colors';
import { useAdminDispatch, useAdminState } from '../../admin/provider';
import routes from '../../../routes';
import { resetSchedules } from '../../schedule/api';
import { HALLogo } from '../../register/components/RegisterStart';
import { ImLab } from 'react-icons/im';
import { useStepsState } from '../../schedule/provider';
import { useSharedDispatch, useSharedState } from '../provider';
import { getCpartnerLogo } from '../../register/api';
import newColorMainLogo from '../../../assets/color-horizontal-worksitelabs.png';
// import SideMenu from '../../admin/components/AppointmentList/components/SideMenu';

interface Props {
    withoutBg?: boolean;
    isAdmin?: boolean;
    isManage?: boolean;
    isPWLogo?: boolean;
    isHALogo?: boolean;
    isLogout?: boolean;
    isMyAcc?: boolean;
    isHelp?: boolean;
    isLanding?: boolean;
    isUser?: boolean;
}

// background: ${({ withoutBg }: Props) =>
//   withoutBg
//     ? 'none'
//     : `linear-gradient(90.13deg, #227EB3 0.18%, #55BAF3 99.96%)`};

const Container = styled.header`
  border-bottom: 5px solid #243D4D;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  position: fixed;
  z-index: 14000;
  width: 100%;
  top: 0;
  color:black;
  background: white;
  height:7.8vh;
  @media (max-width: ${Breakpoints.sm}px) {
    background: white;
    padding: 10px;
  }
  @media (min-width: ${Breakpoints.md}px) {
    padding: ${({ withoutBg }: Props) => (withoutBg ? ' 0px' : '20px 40px')};
  }

  @media (min-width: ${Breakpoints.lg}px) {
    padding: ${({ withoutBg }: Props) =>
        withoutBg ? ' 10px 25px' : '15px 30px'};
  }
`;

export const WLLogo = styled.div`
  height: 20px;
  width: 160px;
  background: url(${newColorMainLogo}) no-repeat center center;
  background-size: cover;
  margin-right: 50px;
  margin-top: 13px;
  @media (max-width: ${Breakpoints.sm}px) {
    height: 11px;
    width: 70px;
    align-items: center;
    margin-right: 10px;
  }
`;

export const HALogo = styled.div`
  height: 24px;
  width: 95px;
  background: url(${haLogo}) no-repeat center center;
  background-size: cover;
  margin-top: 15px;

  @media (max-width: ${Breakpoints.sm}px) {
    align-items: center;
    height: 16px;
    width: 62px;
    margin-right: 10px;
  }
`;

export const PWLogo = styled.div`
  height: 50px;
  width: 100px;
  background: url(${pwLogo}) no-repeat center center;
  background-size: cover;
  margin-left: 20px;
  @media (max-width: ${Breakpoints.sm}px) {
    margin: 0;
  }
`;

export const Logos = styled.div`
  display: flex;
  justify-content: flex-start;
  @media (max-width: ${Breakpoints.sm}px) {
    align-items: center;
  }
`;

export const Logout = styled.button`
  background: none;
  padding: 0;
  color: ${colors.white};
  border: 0;
  cursor: pointer;
`;

export const BtnGroup = styled.div`
  display: flex;
  align-items: center;
`;

export const Button = styled.button`
  background: none;
  border: 0;
  display: flex;
  align-items: center;
  padding: 4px;
  color: ${colors.black};
  margin-top: 5px;
  &:before {
    display: block;
    content: '';
    width: 18px;
    height: 12px;
    background: url(${stack}) no-repeat center center;
    background-size: cover;
  }

  span {
    display: none;

    @media (min-width: 576px) {
      display: block;
      padding-left: 15px;
    }
  }
  @media (max-width: ${Breakpoints.sm}px) {
    margin: 0px !important;
  }
`;

export const Button1 = styled.button`
  background: none;
  border: 0;
  display: flex;
  gap: 10px;
  margin-top: 10px;
  color: ${colors.white};
  @media (max-width: ${Breakpoints.sm}px) {
    gap: 0px;
  }
`;

export const ButtonName = styled.button`
  background: none;
  border: 0;
  margin-top: 10px;
  color: ${colors.black};
  max-width: 125px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  @media (max-width: ${Breakpoints.sm}px) {
    gap: 0px;
    max-width: 90px !important;
  }
`;

const HeaderLink = styled.a`
  color: ${colors.black};
  &:hover {
    color: ${colors.black};
  }
  @media (min-width: ${Breakpoints.sm}px) {
    margin-left: 0;
  }

  @media (min-width: ${Breakpoints.lg}px) {
    margin-left: 0;
  }
`;
const Flexstart = styled.div`
  display: flex;
  @media (max-width: ${Breakpoints.sm}px) {
    margin-left: 0;
  }
`;
const Flexmid = styled.div`
  margin:0 20px;
  text-align:center;
  font-size: 16px;
  font-weight: bold;
  background-color:#E9A2AD;
  padding: 5px 20px;
  @media (max-width: ${Breakpoints.sm}px) {
    margin-left: 5px;
    font-size: 9px;
    
  }
`;
const Flexend = styled.div`
  display: flex;
  gap: 30px;
  font-size: 16px;
  font-weight: 500;

  @media (max-width: ${Breakpoints.sm}px) {
    margin-left: 0;
    gap: 10px;
    font-size: 9px;
    align-items: center;
  }
`;

const Contact = styled.div`
  color: ${colors.black};
  margin-top: 10px;
  @media (max-width: ${Breakpoints.sm}px) {
    font-size: 9px;
  }
`;

const Card = styled.div`
  display: flex;
  justify-content: center;
`;

const CardImage = styled.img`
height: ${({ height }: any) =>  {if (height) return height + 'px'; else return '75px'}};
width: ${({ width }: any) =>  {if (width) return width + 'px'; else return 'auto'}};
margin: 10px 0px;
  @media (max-width: 580px) {
    height: ${({className}:any)=>{if(className) return className; else return '40px'}};
    width:auto;
  }
`;
const AdminHeaderContent: React.FC = () => {
    const { user } = useAdminState();
    const { setUser } = useAdminDispatch();
    const [leftMenu, setleftMenu] = React.useState(false);

    const toggleDrawer = (event: any) => {
        console.log('eventevent', event);
        setleftMenu(event);
    };

    const handleResetSchedules = () => {
        resetSchedules();
    };

    return (
        <>
            <Logos>
                <WLLogo />
            </Logos>
            {user && (
                <BtnGroup>
                    {/* <Button type="button" onClick={handleResetSchedules}>
            <span>Reset Schedules</span>
          </Button> */}
                    <Logout
                        type="button"
                        onClick={() => signOut().then(() => setUser(null))}
                    >
                        Logout
                    </Logout>
                </BtnGroup>
            )}
        </>
    );
};

const Header: React.FC<Props> = ({
    withoutBg,
    isAdmin,
    isManage,
    isLogout,
    isMyAcc,
    isHelp,
    isLanding,
    isUser
}) => {
    const history = useHistory();
    const { airline, tenant, userData, partnerLogo: { logoHeader,locationImage } } = useSharedState();
    const { form ,rebooking } = useStepsState();
    const { setIsVerifyReq, updatePartnerLogo } = useSharedDispatch();
    // const cPartnerID = localStorage.getItem('dist');
    const adminLogin = localStorage.getItem('isAdmin');
    const fetchCpartnerlogo = async (params: any) => {
        try {
            //const input = params?.length ? params : 'WSL001'
            // const tenant = localStorage.getItem('tenant');
            // const input =  params === 'SSFSD' ? 'SSF001' : params === 'BRSSD' ? 'BEL001' : params === 'SUHSD' ? 'SEQ001' : params === 'COM001' ? 'COM001': tenant=='sfoairport'?'SFO001':'WSL001' ;
            const data = await getCpartnerLogo((params && isUser !== true) ? params : tenant==='kern'?'KEN001': 'WSL001');
            if (data?.data?.status === 200) {
                updatePartnerLogo({
                    logo: data?.data?.data.logo,
                    logoHeader: data?.data?.data.logoHeader
                })
            }
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        if(tenant==="kern"||form.testSelection==='kern'||form.location?.cPartnerID==='KEN001' ){
        
            fetchCpartnerlogo(form?.location?.cPartnerID);
          }else{
            
          fetchCpartnerlogo(form?.cPartnerID);
          }
    }, [form?.cPartnerID]);

    return (
        <Container withoutBg={withoutBg} isAdmin={isAdmin} isManage={isManage} isMyAcc={isMyAcc} isHelp={isHelp} isLanding={isLanding} isUser={isUser}>
            {isAdmin ? (
                <>
                    <AdminHeaderContent />
                </>
            ) : (
                false
            )}
            {!isAdmin ? (
                <Flexstart>
                    {/* <> */}
                    {/* <WLLogo />
                    {(!isLanding && !isUser && airline === "HAL") ? <HALogo/> : '' } */}
                    {locationImage?.length === 2 ? (
                        <>
                            <Card>
                                {logoHeader.length ? <CardImage height={42} className={'30px'} width={160} src={locationImage[0]} /> : ''}
                            </Card>&nbsp;
                            <Card>
                                {logoHeader.length ? <CardImage height={42} className={'35px'} width={90} src={locationImage[1]} /> : ''}
                            </Card>
                        </>
                    ) : (
                        <Card>
                           {logoHeader.length ? <CardImage height={50} src={logoHeader} /> : ''}
                        </Card>
                    )} 
                    {/* </> */}
                </Flexstart>
            ) : (
                ''
            )}
            {adminLogin ? (
                <Flexmid>Logged in as customer</Flexmid>
            ) : ''}
            <Flexend>
                <>
                    <Contact>+1 833-747-1122</Contact>
                    {isHelp ? (
                        <Button1 type="button" style={{ backgroundColor: 'transparent' }}>
                            <HeaderLink
                                href="https://support.worksitelabs.com"
                                target="_blank"
                            >
                                Help Center
                            </HeaderLink>
                        </Button1>
                    ) : (
                        false
                    )}
                    {isMyAcc ? (
                        <>
                            <ButtonName
                                type="button"
                                //style={{ backgroundColor: 'transparent' }}
                                onClick={() => {
                                  if(rebooking.active ){
                                    history.push(`/bookappointment?rebooking=${rebooking.sampleId}`)
                                  } else  history.push('/user/profile')
                                }}
                            >
                                <span style={{ marginLeft: '5px' }}>
                                    Hi, {userData?.firstName}
                                </span>
                            </ButtonName>{' '}
                        </>
                    ) : (
                        false
                    )}

                    {isManage ? (
                        <Button type="button" onClick={() => history.push(routes.manage)}>
                            <span>Manage Appointment</span>
                        </Button>
                    ) : (
                        false
                    )}
                    {isLogout ? (
                        <BtnGroup>
                            <Button
                                style={{ marginTop: '5px' }}
                                type="button"
                                onClick={() => {
                                    setIsVerifyReq(false);
                                    history.push('/signin');
                                }}
                            >
                                <span>Sign Out</span>
                            </Button>
                        </BtnGroup>
                    ) : (
                        false
                    )}
                </>
            </Flexend>
        </Container>
    );
};

export default Header;