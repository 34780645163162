import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { AppointmentListItem } from '../../../models';
import { cancelAppointment } from '../../../../schedule/api';

const Text = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  white-space: nowrap;
  display: block;
  width: 100%;
  min-width: 1px;
`;

interface IProps {
  appt: AppointmentListItem;
}

const Cancel = ({
  appt
}: IProps) => {
  const [cancelText, setCancelText] = useState('Cancel');

  const handleCancel = async () => {
    setCancelText('Cancel');
    try {
      if (appt.appointmentId) {
        setCancelText('Canceling ...');
        await cancelAppointment(appt.appointmentId);
        setCancelText('Canceled');
      }
    } catch (e) {
      setCancelText('Error');
      console.error(e);
    }
  }

  if (cancelText === 'Cancel') {
    return (
      <Button
        type="link"
        disabled={cancelText !== 'Cancel'}
        block
        onClick={handleCancel}
      >
        {cancelText}
      </Button>
    );
  } else {
    return <Text>{cancelText}</Text>;
  }
};

export default Cancel;
