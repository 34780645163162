

export const filterRebookLocations = (form:any,locations:any) => {
    let filterRebookLocations: any = []
    locations?.forEach((loc:any) => {
      if (form?.location?.cPartnerID == loc?.cPartnerID) {
        if (form?.isExpressSameDayTest && (loc?.hasVipSlots == form?.isExpressSameDayTest)) {
          filterRebookLocations.push(loc)
        }
        if (form?.isRapidTest && (loc?.rapidTest == form?.isRapidTest)) {
          filterRebookLocations.push(loc)
        }
        if (form?.isAntigen && (loc?.isAntigen == form?.isAntigen)) {
          filterRebookLocations.push(loc)
        }
        if (form?.isPanel && (loc?.isPanel == form?.isPanel)) {
          filterRebookLocations.push(loc)
        }

        if ((!form?.isExpressSameDayTest &&
          !form?.isRapidTest &&
          !form?.isAntigen &&
          !form?.isPanel)) {
          filterRebookLocations.push(loc)

        }
      }
    })
    return filterRebookLocations
  }