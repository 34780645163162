import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Form, Formik } from 'formik';

import { Container, Content, SubHeaderDescription, PageTitle, PageSubTitle } from '../../shared/components/styled';
import { colors } from '../../../styles/colors';
import Input from '../../shared/components/form/Input';
import { Breakpoints } from '../../../dictionaries';
import wsllogo from '../../../assets/WLABS_CMYK.png';
import hallogo from '../../../assets/hal_logo.png';
import orglogo from '../../../assets/org_logo.png';
import Publiclogo from '../../../assets/public_logo.png';
import groublogo from '../../../assets/groub_logo.png';
import { checkEmailAddress, createRegister, checkemail, createInsurance, getInsuranceById, AddGovtId } from '../api';
import { useHistory } from 'react-router-dom';
import { useSharedDispatch, useSharedState } from '../../shared/provider';
import { registerLogin } from '../api';
import { useStepsDispatch, useStepsState } from '../../schedule/provider';
import Checkbox from '../../shared/components/form/Checkbox';
import NavigationButtons from '../../shared/components/NavigationButtons';
import { Register } from '../../shared/models';
import GoogleAnalytics from '../../../GoogleAnalytics';

const Error = styled.div`
  color: ${colors.red};
  margin-top: 15px;
  text-align: center;
`;
const PageHeader = styled.div`
  text-align: center;
`;

export const Logos = styled.div`
  display: flex;
`;

export const WLLogo = styled.div`
  height: 60px;
  width: 270px;
  background: url(${wsllogo}) no-repeat center center;
  background-size: cover;
  margin-bottom: 15px;
  display: inline-block;

  @media (max-width: ${Breakpoints.sm}px) {
    margin-top: 20px;
  }
`;

export const Logos1 = styled.div`
  position: absolute;
  width: 601px;
  height: 161px;
  right: 432px;
  bottom: 518px;

  border: 1px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15));
  border-radius: 10px;
`;

export const Haldiv = styled.div`
  position: absolute;
  width: 120px;
  height: 120px;
  left: 616px;
  top: 408px;

  border: 1px solid rgb(235 227 227 / 50%);
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
  border-radius: 10px;
`;

export const HALLogo = styled.div`
  height: 18px;
  width: 118px;
  background: url(${hallogo}) no-repeat center center;
  background-size: cover;
  display: inline-block;
  margin: -20px 0px 20px 0px;

  @media (min-width: ${Breakpoints.sm}px) {
    height: 100px;
    width: 300px;
  }
`;
export const Text = styled.div`
  position: absolute;
  width: 521px;
  height: 44px;
  left: 447px;
  top: 180px;

  font-family: Open Sans;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 44px;
  text-align: center;
  padding-right: 68px;
  color: #000000;
`;

export const SubText = styled.div`
  font-family: Open Sans;
  margin: 5px 0px 0px 0px;
  font-size: 20px;
  color: ${colors.blueLight};
  cursor: pointer;
`;

export const Orglogo = styled.div`
  height: 18px;
  width: 118px;
  background: url(${orglogo}) no-repeat center center;
  background-size: cover;
  display: inline-block;
  @media (min-width: ${Breakpoints.sm}px) {
    height: 80px;
    width: 85px;
  }
`;

export const CategoryContentBox = styled.div`
  flex: 0.3 1 0%;
  justify-content: center;
  border-radius: 10px;
  border: 2px solid ${colors.lightGrey};
  box-sizing: border-box;
  padding: 10px 0px 10px 0px;
  margin: 0px 5px 0px 5px;
  cursor: pointer;
`;

export const PublicLogo = styled.div`
  height: 18px;
  width: 118px;
  background: url(${Publiclogo}) no-repeat center center;
  background-size: cover;
  display: inline-block;
  @media (min-width: ${Breakpoints.sm}px) {
    height: 80px;
    width: 85px;
  }
`;

export const GroupLogo = styled.div`
  height: 18px;
  width: 118px;
  background: url(${groublogo}) no-repeat center center;
  background-size: cover;
  display: inline-block;
  @media (min-width: ${Breakpoints.sm}px) {
    height: 79px;
    width: 97px;
  }
`;

export const CategoyTitle = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;

  color: ${colors.blueLight};
  margin-top: 3px;
`;

const Space = styled.div`
  margin-bottom: 20px;
  @media (max-width: ${Breakpoints.sm}px) {
    margin-bottom: 30px;
  }
`;
interface Props {
  isValid?: boolean;
  loading?: boolean;
  onClick?: () => void;
}

const RegisterWthOutReg: React.FC<Props> = ({ isValid, onClick }) => {
  const { form } = useStepsState();
  useEffect(() => {
    GoogleAnalytics(form,'/Account creation');
    window.scrollTo(0, 0);
  }, []);


  const {
    email,
    password,
    confirmPassword,
    postCode,
    // hasMarketCommuni,
    hasAgreement,
    hasInsurance,
    insurance,
    govtId
  } = form;

  const { airline, travellType, destination ,tenant } = useSharedState();

  const {
    updateFormValues,
    goToNextStep,
    goToPrevStep,
    goToSkipStep,
  } = useStepsDispatch();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);

  const [clicking, setClicking] = useState(false);
  localStorage.setItem('location', '');
  const [isLoginScreen, setLoginScreen] = useState(false);

  const [validLength, setValidLength] = useState(false);
  const [upperCase, setUpperCase] = useState(false);
  const [lowerCase, setLowerCase] = useState(false);
  const [specialChar, setSpecialChar] = useState(false);
  const [requiredLength, setRequiredLength] = useState(8);
  const [accloading, setaccloading] = useState(false);
  const history = useHistory();
  const { setloggedUserData, updateUserVerify } = useSharedDispatch();
  const { userData } = useSharedState();

  const [isLogin, setIsLogin] = useState(false);
  const [isVerifyScreenMsg, setVerifyScreenMsg] = useState(false);

  const checkAndAddFunctions = async() => {
    if(form.email){
        if(form.govtId.frontCard && form.govtId.backCard){
            let data = {...form.govtId, email: form.email.toLowerCase()}
            await AddGovtId(data);
        }
        // if(form.insurance?.frontCard && form.insurance.backCard){
        //     let data = {...form.insurance, email: form.email.toLowerCase()}
            // await createInsurance(data);
        // }
    }
  }

  const addRegister = async () => {
    const data: Register = {
      email: email,
      password: password,
      postCode: form.postCode,
      firstName: form?.registeringFor === 'minorOnly' && !!form?.guardian?.firstName ? form?.guardian?.firstName : form?.firstName,
      lastName: form?.registeringFor === 'minorOnly' && !!form?.guardian?.lastName ? form?.guardian?.lastName : form?.lastName,
      employeeId: tenant === 'kern' ? form?.employeeId :'',
      middleName: form?.middleName ? form?.middleName : '',
      isINTNameFormat: form?.isINTNameFormat,
      birthDate: form?.birthDate,
      phone: form?.phone,
      hasMinors: form?.hasMinors,
      minors: form?.minors,
      sex: form?.sex,
      race: form?.race,
      ethnicity: form?.ethnicity,
      address: {
        zipCode: form?.address.zipCode,
        state: form?.address.state,
        address: form?.address.address,
        county: form?.address.county,
        city: form?.address.city,
        country:form?.address.country,
      },
      passportCountry: form?.passportCountry,
      passportNo: form?.passportNo ? form?.passportNo : '',
      destination: destination ? destination : '',
      IsAirline: airline === '' ? true : airline === 'HAL' ? true : false,
      airlineCode: airline ? airline : '',
      travelType: travellType,
      cPartnerID: '',
      isVerified: false,
      registeringFor: form?.registeringFor,
      guardian: {
        firstName: form?.guardian?.firstName ? form?.guardian?.firstName : '',
        lastName: form?.guardian?.lastName ? form?.guardian?.lastName : '',
        relationship: form?.guardian?.relationship
          ? form?.guardian?.relationship
          : '',
        phone: form?.guardian?.phone ? form?.guardian?.phone : '',
        email: form?.guardian?.email ? form?.guardian?.email : '',
      },
      school: form.school ? form.school : '',
      studentID: form.studentID ? form.studentID : '',
      isNotHavePermanentAddress: form.isNotHavePermanentAddress,
      schoolTestFor: form.schoolTestFor ? form.schoolTestFor : '',
    };

    try {
      setLoading(true);
      const registerData = await createRegister(data);

      if (registerData.data.status === 200) {
        await checkAndAddFunctions();
        goToNextStep();
      } else {
        setError(registerData?.data?.message);
      }
      setLoading(false);
    } catch (e) {
      setError('Incorrect details');
      setLoading(false);
    }
  };

  const handleCreateRegister = async () => {
    if (!clicking) {
      const data: any = {
        email: email.toLowerCase(),
        password: password,
        postCode: postCode,
      };

      try {
        setLoading(true);
        const registerData = await checkemail(data);
        if (registerData.data.status === 200) {
          if (isLoginScreen) {
            await addRegister();
          } else {
            setError('Incorrect Password Details');
          }
        } else {
          setClicking(false);
          setError(registerData?.data?.message);
          setIsLogin(true);
        }
        setLoading(false);
      } catch (e) {
        setClicking(false);
        setError('Incorrect details');
        setLoading(false);
      }
    }
  };
  const verifyEmail = async (email: string) => {
    let error;
    if (email && email.trim() && typeof email !== 'undefined') {
      email = email.trim();
      var pattern = new RegExp(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i
      );

      if (!pattern.test(email)) {
        error = 'Please enter valid email address';
      }
    } else {
      error = 'Required field';
    }
    setLoading(false);
    return error;
  };

  const validatePassword = (password: string) => {
    let error: string = '';
    setError(error);
    if (password) {
      setValidLength(password.length >= requiredLength ? true : false);
      setUpperCase(password.toLowerCase() !== password);
      setLowerCase(password.toUpperCase() !== password);
      setSpecialChar(/[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(password));
      if (validLength && upperCase && lowerCase && specialChar) {
        setLoginScreen(true);
      } else {
        error =
          '8 character minimum, 1 uppercase, one lowercase, one special character';
        setLoginScreen(false);
      }
    } else {
      setLoginScreen(false);
    }

    return error;
  };
  const validateConfirmPassword = (
    password: string,
    confirmPassword: string
  ) => {
    let error: string = '';
    setError(error);

    if (password && confirmPassword) {
      if (password !== confirmPassword) {
        error = 'Password not matched';
        setLoginScreen(false);
      } else {
        validatePassword(confirmPassword);
      }
    } else {
      setLoginScreen(false);
    }
    return error;
  };
  const redirect = () => {
    history.push('/user/profile');
  };

  const redirectToSignin = () => {
    history.push('/signin');
  };

  const handleSignIn = async (params: { email: string; password: string }) => {
    setLoginLoading(true);
    try {
      //Verify the email already exists
      const registerData = await checkemail({ email: email });
      if (registerData.data.status === 400) {
        const loggedUser = await registerLogin(params);
        //Continue on the user's login
        checkAndAddFunctions();
        if (loggedUser?.data?.status == 200) {
          updateUserVerify(true);
          setloggedUserData(loggedUser?.data.data);
          localStorage.setItem('user', JSON.stringify(userData));
          if(hasInsurance) {
            insurance.email = email;
            const insuranceData = await getInsuranceById( {email: email, insuranceId: insurance?.insuranceId});
            if(insuranceData?.data?.status == 200){
              updateFormValues({insurance: insurance});
            }
          //   else{ 
          //     const result =  await createInsurance({
          //     ...insurance,
          //     email: userData?.email ? userData?.email : email,
          //   });
          //   if(result.data.status == 200) updateFormValues({insurance: insurance});
          // }
          }else if(govtId.frontCard !== undefined && govtId.frontCard !== null && govtId.frontCard !== ""){
						await AddGovtId({
							...govtId,
							email: userData?.email ? userData?.email : email,
						})
					}
          goToSkipStep();
          // goToNextStep();
        } else {
          //Continue to the profile verification
          if (loggedUser?.data?.message === 'Please verify your account to login.') {
            updateFormValues({
              email: loggedUser?.data?.data?.email,
              phone: loggedUser?.data?.data?.phone,
            });
            setError(loggedUser?.data?.message);
            setLoading(false);
            setVerifyScreenMsg(true);
          } else {
            //Invalid user
            setError(loggedUser?.data?.message);
            setLoading(false);
            setLoginLoading(false);
            setaccloading(false);
          }
        }
      } else {
        setIsLogin(false);
        setLoginScreen(false);
        setLoading(false);
        // if(hasInsurance){
        // const result =  await createInsurance({
        //   ...insurance,
        //   email: userData?.email ? userData?.email : email,
        // });
        // if(result.data.status == 200) updateFormValues({insurance: insurance});
        // }else 
        if(govtId.frontCard !== undefined && govtId.frontCard !== null && govtId.frontCard !== ""){
					await AddGovtId({
						...govtId,
						email: userData?.email ? userData?.email : email,
					})
				}
      }
    } catch (e) {
      setError('Incorrect email or password');
      setLoginLoading(false);
      setaccloading(false);
    }
  };

  return (
    <Container size="md">
      <Content>
        <PageHeader>
          <PageTitle>
            In order to finish booking your appointment, you need to create an
            account
          </PageTitle>
          <PageSubTitle style={{ textAlign: 'left' }}>
            An account is required to schedule an appointment. Your account will
            allow you to manage your appointments, access your results, and
            update your personal information.
          </PageSubTitle>
        </PageHeader>
        <Formik
          initialValues={{
            email,
            password,
            confirmPassword,
            postCode,
            hasAgreement,
          }}
          onSubmit={(values) => {
            if (isLogin) {
              setLoading(true);
              handleSignIn({
                email: values.email.toLowerCase().trim(),
                password: values.password
              });
            } else {
              updateFormValues({
                email: values.email.toLowerCase().trim(),
                password: values.password
              });
              handleCreateRegister();
              setClicking(true);
            }
          }}
        >
          {({ values, isValid }) => (
            <Form>
              <Space />
              <Input
                name="email"
                placeholder="Email"
                isRequired
                onChange={(value) =>
                  updateFormValues({ email: value.toLowerCase().trim() })
                }
                validate={(value) => verifyEmail(value)}
                noBottomMargin
              />
              <Space />
              <Input
                name="password"
                placeholder="Password"
                isRequired
                type="password"
                onChange={(value) => updateFormValues({ password: value })}
                validate={(value) => {
                  let error;

                  if (!value) {
                    return (error = 'Required field');
                  } else {
                    return validatePassword(value);
                  }
                }}
                noBottomMargin
              />
              <Space />
              <SubHeaderDescription style={{ fontSize: '13px' }}>
                *8 character minimum, 1 uppercase, one lowercase, one special
                character
              </SubHeaderDescription>
              {!isLogin && (
                <Input
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  isRequired
                  type="password"
                  validate={(value) => {
                    let error;
                    if (!value) {
                      return (error = 'Required field');
                    } else {
                      return validateConfirmPassword(password, value);
                    }
                  }}
                  onChange={(value) =>
                    updateFormValues({ confirmPassword: value })
                  }
                  noBottomMargin
                />
              )}
              <Space />
              {error && (
                <Error>
                  {error}
                  <br />
                  {isVerifyScreenMsg && (
                    <a
                      onClick={() => {
                        goToNextStep();
                      }}
                    >
                      Click here
                    </a>
                  )}{' '}
                  {isVerifyScreenMsg && 'to verify your account.'}
                </Error>
              )}
              <Space />
              <Checkbox
                name="hasAgreement"
                isRequired
                onChange={(checked) =>
                  updateFormValues({ hasAgreement: checked })
                }
              >
                I acknowledge that I have read the{' '}
                <a
                  style={{color:colors.rebrandBlack}}
                  href="https://worksitelabs.com/terms-conditions/"
                  target="_blank"
                  rel="noreferrer noopener"
                  className='underline f-700'
                >
                  terms & conditions&nbsp;
                </a>
                and&nbsp;
                <a
                  style={{color:colors.rebrandBlack}}
                  href="https://www.worksitelabs.com/privacy"
                  target="_blank"
                  rel="noreferrer noopener"
                  className='underline f-700'
                >
                  privacy policy
                </a>
              </Checkbox>
              <Space />
              <NavigationButtons
                isValid={hasAgreement}
                confirmBtnText={loading ? 'Processing...' : isLogin ? 'Login' : 'Create Account'}
                onReturnButtonClick={() => {
                  goToPrevStep();
                }}
              />
            </Form>
          )}
        </Formik>
      </Content>
    </Container>
  );
};

export default RegisterWthOutReg;

//ltst