import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Breakpoints } from '../../../../dictionaries';
import axios from 'axios';
import { colors } from '../../../../styles/colors';
import { useSharedDispatch ,useSharedState } from '../../../shared/provider';
import { useStepsState } from '../../../schedule/provider';

const Button = styled.div`
align-items: center;
border-radius: 32px;
font-size: 20px;
line-height: 15px;
text-align: center;
padding: 15px;
font-weight: bold;
cursor: pointer;
background-color: ${colors.rebrandOrange};
color: #fff;
display: inline-block;
width: 45%;
/* border: 0; */
margin-top: 20px;
box-shadow: -4px -4px 8px rgba(153, 153, 153, 0.15), 4px 4px 8px rgba(0, 0, 0, 0.3);
border-radius: 1000px;
@media (max-width: ${Breakpoints.sm}px) {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 90%
  }
`;


const Managebutton: React.FC =() => {

  let history = useHistory();
  const { userData } = useSharedState();
  const { rebooking} = useStepsState();
  const { updateapptFromSchdule } = useSharedDispatch();

  
return(
        <Button 
    onClick={() => {
      if(rebooking.active ){
        history.push(`/bookappointment?rebooking=${rebooking.sampleId}`)
      }
      localStorage.setItem('user', JSON.stringify(userData));
       updateapptFromSchdule(false); history.push('/user/profile'); 
      }}
    className='btn fill-btn'
        >
        Manage Account
        </Button>
        
)
}

export default Managebutton;
