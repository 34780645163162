import React from 'react';
import ReactSelect from 'react-select';
import styled from 'styled-components';
import { Field, FieldProps, FieldValidator } from 'formik';

import { ErrorText } from '../styled';
import { colors } from '../../../../styles/colors';

const Container = styled.div`
  position: relative;
`;

const Error = styled.div`
  position: absolute;
  top: 58px;
  left: 6px;
`;

const styles = {
  menu: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected ? colors.rebrandYellow : '',
  }),
  selectElement: {
    option: (provided: any, state: any) => ({
      ...provided,
      borderRadius: '6px',
      backgroundColor: state.isSelected
        ? colors.rebrandYellow
        : state.isFocused
        ? colors.rebrandGrey
        : undefined,
      color: '#101010',
      ':active': {
        backgroundColor: !state.isDisabled
          ? state.isSelected
            ? colors.rebrandYellow
            : colors.rebrandYellow
          : undefined,
      },
    }),

    container: (provided: any) => ({
      ...provided,
      width: '100%',
      marginBottom: '22px',
    }),
    control: (_: any, { selectProps: { width } }: any) => ({
      width: width,
    }),
    valueContainer: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: '#FFF',
      borderRadius: '10px',
      padding: '8px 22px',
      boxShadow:
        state.isFocused || state.isSelected ? '0px 0px 4px 0px #4749A0C7' : '',
      border: state.isFocused || state.isSelected ? '1px solid '+colors.rebrandGreen :'1px solid black',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    indicatorsContainer: (provided: any) => ({
      ...provided,
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      right: '1px',
      backgroundColor: 'white',
    }),
  },
};

interface Props<T> {
  name: string;
  disabled?: boolean;
  options: { label: string; value: T }[];
  styles?: {
    [key: string]: string | number;
  },
  validate?: FieldValidator;
  onChange?: (newValue: T) => void;
  isRequired?:boolean;
  defaultValue?: any;
}

function Select<T>({
  name,
  disabled,
  options,
  styles: wrapperStyles,
  validate,
  onChange,
  isRequired,
  defaultValue
}: Props<T>) {
  return (
    <Field
      name={name}
      validate={validate}
    >
      {({
        field: { value },
        form: { setFieldValue },
        meta: { touched, error },
      }: FieldProps) => {
        return (
          <Container style={wrapperStyles}>
          <ReactSelect
            hasError={touched && !!error}
            isRequired={isRequired}
            value={value || defaultValue}
            isDisabled={disabled}
            onChange={(selectedValue) => {
              setFieldValue(name, selectedValue);
              //onChange && onChange(selectedValue?.value);

              onChange && onChange(selectedValue!.value);
        }}      
            options={options}
            width="100%"
            styles={styles.selectElement}
          />
          <Error>
            <ErrorText hasError={touched && !!error}>
              {error}
            </ErrorText>
          </Error>
        </Container>
        )
      }}
    </Field>
  );
};

export default Select;

//ltst