import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import styled from 'styled-components';
import { differenceInYears, format, parse } from 'date-fns';

import {
  Container,
  Content,
  PageHeader,
  PageTitle,
  ErrorText,
  ErrorTextLarge,
  SubHeaderDescription,
} from '../../../shared/components/styled';
import { useStepsState, useStepsDispatch } from '../../../schedule/provider';
import { Breakpoints } from '../../../../dictionaries';
import { config } from '../../../../config';
import { colors } from '../../../../styles/colors';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import NavigationButtons from '../../../shared/components/NavigationButtons';
import FormLabel from '../../../shared/components/form/FormLabel';
import Input from '../../../shared/components/form/Input';
import { DatepickerField } from '../../../shared/components/Datepicker';
import Checkbox from '../../../shared/components/form/Checkbox';
import { country } from '../../../schedule/components/PersonalInformation/PassportCountry';
import Select from '../../../shared/components/form/Select';
import { useSharedState, useSharedDispatch } from '../../../shared/provider';
import { getAllClientPartner, getLosLomitasDetails, updateRegister } from '../../api';
import MinorsWthOutReg from './MinorsWthOutReg';
import { filter, values } from 'lodash';
import { IDropDown } from '../../models';
import mixpanel from 'mixpanel-browser';
import { isUSPhone } from '../../../../validations';
import { firebase } from '../../../../firebase';
import GoogleAnalytics from '../../../../GoogleAnalytics';
import { useHistory } from 'react-router-dom';
import { useManageDispatch } from '../../../manage/provider';

const InputRow = styled.div`
  display: flex;
  flex-direction: column;
  margin: -8px 0;

  @media (min-width: ${Breakpoints.sm}px) {
    flex-direction: row;
    margin: 0 -8px;
  }

  @media (min-width: ${Breakpoints.lg}px) {
    margin: 0 -15px;
  }

  & > * {
    flex-basis: 33%;
    margin: 8px 0;

    @media (min-width: ${Breakpoints.sm}px) {
      margin: 0 8px;
    }

    @media (min-width: ${Breakpoints.lg}px) {
      margin: 0 15px;
    }
  }
`;
const PassportRow = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;

  & > *:first-child {
    width: 32%;
  }

  & > *:last-child {
    width: 32%;
  }
  @media (max-width: ${Breakpoints.sm}px) {
    gap: 5px;

    & > *:first-child {
      width: 50%;
    }

    & > *:last-child {
      width: 50%;
      margin-top: -35px;
    }
  }
`;
const WrapContainer = styled.div`
  border: 0.5px solid rgba(36, 61, 77, 0.14);
  border-radius: 12px;
  display: flex;
  padding: 20px 40px;
  margin-bottom: 20px;
  @media (max-width: ${Breakpoints.sm}px) {
  }
`;
const Title = styled.h4`
  color: rgba(38, 38, 38, 0.8);
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  margin: 0 0 23px 0;
`;
const GuardianWrapContainer = styled.div`
  padding: 25px 15px 20px;
  border: 0.5px solid rgba(36, 61, 77, 0.14);
  border-radius: 12px;
  align-items: center;
  margin-bottom: 5px;
`;
const GuardianDetailsRow = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;

  & > *:first-child {
    width: 32%;
  }

  & > *:last-child {
    width: 32%;
  }
  @media (max-width: ${Breakpoints.sm}px) {
    gap: 5px;
    flex-direction: column;
    & > *:first-child {
      width: 100%;
      margin-top: 20px;
    }
    & > *:last-child {
      width: 100%;
      margin-top: 20px;
    }
  }
`;
const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow:
      'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
});

const Gap = styled.div`
  width: 90px;
  @media  (min-width: ${Breakpoints.sm}px) and (max-width: ${Breakpoints.xl}px) {
    width: 0px;
  }
`;

function StyledRadio(props: RadioProps) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      // checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      // icon={<span className={classes.icon} />}
      {...props}
      style={{
        color: '#243D4D'
      }}
    />
  );
}

const SchedulinginfoWthOutReg: React.FC = () => {
  const db = firebase.firestore();
  const [hasSchoolList, setHasSchoolList] = useState<boolean>(false);
  const {
    form: {
      registeringFor,
      guardian,
      minors,
      employeeId,
      firstName,
      lastName,
      birthDate,
      isINTNameFormat,
      middleName,
      passportCountry,
      passportNo,
      phone,
      hasMinors,
      email,
      school,
      studentID,
      testSelection,
      cPartnerID,
      schoolTestFor,
      payingMethod,
      location
    },
    form
  } = useStepsState();
  const getSchoolList = async () => {
    // const response = db
    //   .collection('clientpartner')
    //   .where('cPartnerID', '==', cPartnerID);
    // const snapShotData = await response.get();
    // const data = snapShotData.docs.map((doc: any) => doc.data());
const res = await getAllClientPartner({ cPartnerID });
if (res.data.status === 200) {
  const data: any = res.data.data;
  const schools = data[0]?.Schools;
  if (schools && schools !== undefined && schools !== null) {
    var dropDown: any = [];
    schools.forEach((school: any) => {
      dropDown.push({ label: school, value: school });
    });
    if (dropDown.length === 1) {
      updateFormValues({ school: dropDown[0].value });
    }

    setSchoolDropDown(dropDown);
    setHasSchoolList(true);
  }
}
    
  }

  const [showList, setShowList] = useState<string>(registeringFor);
  React.useEffect(() => {
    window.scrollTo(0, 0);
    console.log(schoolDropDown);
    GoogleAnalytics(form,'/Who are you scheduling this test for?');
    if(form.cPartnerID ==="KEN001"){
        updateFormValues({registeringFor:'myself'})
        setShowList('myself')
    }
    // getSchoolList();
    if (!hasSchoolList) {
      var filteredlocation = filter(locations, function (data: any) {
        return data.cPartnerID == cPartnerID;
      });
      var dropDown: any = [];
      filteredlocation.forEach((location) => {
        if(cPartnerID == 'LLT001')
            dropDown.push({ label: location.name, value: location.name, qbenchCustomerId: +location.qbenchCustomerId });
        else
            dropDown.push({ label: location.name, value: location.name });
      });
      if(dropDown.length === 1){
        updateFormValues({school: dropDown[0].value})
      }
      setSchoolDropDown(dropDown);
    }
  }, [cPartnerID]);
  
  const {
    userData,
    apptFromSchdule,
    travellType,
    locations,
    testSelectFlow,
    tenant
  } = useSharedState();
  const { setloggedUserData } = useSharedDispatch();
  const [disable, setDisable] = useState(false);
  const { updateFormValues, goToNextStep, goToPrevStep,toggleChangeLocationModal } = useStepsDispatch();
  const [validationMethod, setValidationMethod] = useState<string>('');
  const [errormsg, setErrormsg] = useState<boolean>(false);
  const [errormsgs, setErrormsgs] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [INTNameFormat, setINTNameFormat] = useState(isINTNameFormat);
  const [schoolDropDown, setSchoolDropDown] = useState<IDropDown[]>([]);
  const [schoolShowList, setSchoolShowList] = useState<string>(cPartnerID === 'BEL001' ? schoolTestFor==='faculty' ? 'communityMember' : schoolTestFor : schoolTestFor==='communityMember' ? 'faculty' : schoolTestFor);
  const [schoolValidationMethod, setSchoolValidationMethod] = useState<string>(
    ''
  );
  const { updateStandradTest } = useManageDispatch();
  const confirmationSelectionHandler = (method: string) => {
    setValidationMethod(method);

    if (method === 'myself') {
      setShowList('myself');
    } else if (method === 'minorOnly') {
      setShowList('minorOnly');
    } else if (method === 'myselfAndMinor') {
      setShowList('myselfAndMinor');
    }
  };

  const confirmationSchoolSelectionHandler = (method: string) => {
    setSchoolValidationMethod(method);

    if (method === 'student') {
      setSchoolShowList('student');
    } else if (method === 'parentOrGuardian') {
      setSchoolShowList('parentOrGuardian');
    } else if (method === 'staff') {
      setSchoolShowList('staff');
    } else if (method === 'faculty') {
      setSchoolShowList('faculty');
    } else{
        setSchoolShowList(method);  
    }
  };

  const updateGuardianValue = (newValue: any) => {
    updateFormValues({
      guardian: {
        ...guardian,
        ...newValue,
      },
    });
  };
  const updateChangeOnClick = async (values: any,studentInfo?: any) => {
    setLoading(true);

    if (apptFromSchdule) {
        if(cPartnerID === 'LLT001' && studentInfo && values === null){
            console.log("detail1",studentInfo[0])
            const detail = await getLosLomitasDetails(studentInfo[0]);
            if(detail?.data?.status === 200){ 
                updateFormValues({
                    location: detail?.data?.location,
                    slot: detail?.data?.slot,
                    date: detail?.data?.slot?.date
                })
                goToNextStep();
            } else if (detail?.data?.messages !== undefined && detail?.data?.messages !== null){
                setErrormsgs(detail?.data?.messages !== undefined && detail?.data?.messages !== null ? 
                    detail?.data?.messages : 
                    'Invalid Student ID. Please contact your school administrator for your correct ID.');
            }else {
                console.log("detail?.data?.message",detail?.data?.message)
                studentInfo[0]?.schoolTestFor === 'student' 
                ? setErrormsgs('Invalid Student ID. Please contact your school administrator for your correct ID.')
                : setErrormsgs('Invalid Last Name. Please contact your school administrator.')
            }

        } else goToNextStep();
    } else {
      await updateUserDataWhileRegister(values);
    }

    setLoading(false);
  };

  async function updateUserDataWhileRegister(values: any) {
    let minorsData =
      showList === 'myself'
        ? userData?.minors
        : values.minors.map((minor: any) => ({
          ...minor,
          birthDate:
            minor.birthDate && format(minor.birthDate, config.dateFormat),
        }));
    let resultdata = await updateRegister({
      email: userData?.email,
      firstName: values.firstName,
      lastName: values.lastName,
      middleName: userData?.isINTNameFormat ? '' : values.middleName,
      passportNo: values.passportNo,
      passportCountry: values.passportCountry,
      birthDate:
        values.birthDate && format(values.birthDate, config.dateFormat),
      minors: minorsData,
      hasMinors: minorsData?.length ? true : false,
    });
    console.log('result', resultdata);

    if (resultdata?.data?.status === 200) {
      mixpanel.track('user details page');
      goToNextStep();
      setloggedUserData(resultdata?.data?.data);
      localStorage.setItem('user', JSON.stringify(resultdata?.data?.data));
    }
  }
  const verifyEmail = async (email: string) => {
    let error;
    if (email && email.trim() && typeof email !== 'undefined') {
      email = email.trim();
      var pattern = new RegExp(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i
      );

      if (!pattern.test(email)) {
        error = 'Please enter valid email address';
      }
    } else {
      error = 'Required field';
    }
    setLoading(false);
    return error;
  };
  useEffect(() => {
    if(location?.partnerName === 'goHealth'){
      updateStandradTest(true)
      toggleChangeLocationModal(false)
      updateFormValues({minors:[],isRapidTest: false, isAntigenTest: false, isExpressSameDayTest: false})
    }
  },[])

  
  const history = useHistory();
  const { updateDestination, updateTravelType, updateAirlineType, updateReasonType, updateVaccinated, updateVaccineType, updateapptFromSchdule, updateTenent } = useSharedDispatch();
  const loginRedirect = () => {
    updateDestination('');
    updateTravelType('');
    updateAirlineType('');
    updateReasonType('');
    updateVaccinated('');
    updateVaccineType('');
    updateapptFromSchdule(false);
    localStorage.clear();
    history.push('/signin');
}

  return (
    <Container size="xl">
      <Content>
        <PageHeader>
          <PageTitle className="title-wrap">
            Who are you scheduling the test for?
          </PageTitle>
        </PageHeader>
        <Formik
          initialValues={{
            employeeId:employeeId,
            firstName: firstName,
            lastName: lastName,
            isINTNameFormat,
            middleName: middleName,
            birthDate:
              birthDate && parse(birthDate, config.dateFormat, new Date()),
            passportNo: passportNo !== null ? passportNo : passportNo,
            passportCountry: passportCountry,
            minors: minors.map((minor: any) => ({
              ...minor,
              birthDate:
                minor.birthDate &&
                parse(minor.birthDate, config.dateFormat, new Date()),
            })),
            goHealth : {
              email:email,
              phone:phone,
            },
            registeringFor,
            guardian: {
              firstName: guardian?.firstName,
              lastName: guardian?.lastName,
              relationship: guardian?.relationship,
              phone: guardian?.phone,
              email: guardian?.email,
              birthDate:
                birthDate && parse(birthDate, config.dateFormat, new Date()),
            },
            school: school,
            studentID: studentID,
            schoolTestFor: schoolTestFor,
          }}

          onSubmit={(values) => {
            updateFormValues({
              ...values,
              birthDate:
                values.birthDate && format(values.birthDate, config.dateFormat),
              minors: values.minors
                ? values.minors.map((minor: any) => ({
                  ...minor,
                  birthDate:
                    minor.birthDate &&
                    format(minor.birthDate, config.dateFormat),
                }))
                : [],
              registeringFor: showList,
              hasMinors: values.minors.length ? true : false,
              guardian:
                registeringFor === 'minorOnly'
                  ? {
                    ...values.guardian,
                  }
                  : null,
              schoolTestFor: schoolShowList,
            });
            console.log('values', values);
            if (testSelection !== 'school' || cPartnerID === 'POR001' ) {
              updateChangeOnClick(values);
            } else {
              if (showList === '') {
                setErrormsg(true);
            } else if(testSelection === 'school' && cPartnerID === 'LLT001') {
                    if(cPartnerID === 'LLT001') {
                        let studentInfo: any[] = [];
                        if (registeringFor === 'myself'){
                            let data = { 
                                school : values.school,
                                id: +values.studentID,
                                schoolTestFor:schoolTestFor,
                                lastName: values.lastName,
                                firstName: values.firstName,
                            }
                            studentInfo.push(data);
                        } else{
                            if(values.minors.length !== 0){
                                values.minors.map((minor: any) => {
                                    let data = { 
                                        school : minor?.school,
                                        id: +minor?.studentID,
                                        schoolTestFor:minor?.schoolTestFor,
                                        lastName:minor?.lastName,
                                        firstName: minor?.firstName,
                                    }
                                    studentInfo.push(data);
                                })
                            }
                        }
                        console.log('studentInfo',studentInfo);
                        if (
                            registeringFor !== 'minorOnly' &&
                            registeringFor !== 'myselfAndMinor'
                        ) {
                            if (schoolShowList === '') {
                            setErrormsg(true);
                            } else {
                                updateChangeOnClick(null,studentInfo);
                            }
                        } else {
                            let checks: boolean = true;
                            minors.map((minor: any) => {
                            if (minor?.schoolTestFor?.length === 0) {
                                checks = false;
                                setErrormsg(true);
                            }
                            });
                            if (checks) updateChangeOnClick(null,studentInfo);
                        }
                    } 
            } else {
                if (testSelection === 'school' && cPartnerID !== 'POR001') {
                  if (
                    registeringFor !== 'minorOnly' &&
                    registeringFor !== 'myselfAndMinor'
                  ) {
                    if (schoolShowList === '') {
                      setErrormsg(true);
                    } else {
                      updateChangeOnClick(values);
                    }
                  } else {
                    let checks: boolean = true;
                    minors.map((minor: any) => {
                      if (minor?.schoolTestFor?.length === 0) {
                        checks = false;
                        setErrormsg(true);
                      }
                    });
                    if (checks) updateChangeOnClick(values);
                  }
                }
              }
            }
            if(payingMethod==='No Payment' || cPartnerID === 'KEN001' || testSelection === 'kern') {
                updateFormValues({ hasInsurance: false,payingMethod:'No Payment', isAntigen: true, testSelection: 'kern' });
 
            }
          }}
        >
          {({ values, setFieldValue }) => {
            return (
              <Form>
                {/* {travelType === "INT" &&
                    <Checkbox
                      name="isINTNameFormat"
                      onChange={(checked) => {
                        setFieldValue('isINTNameFormat', checked);
                        updateFormValues({ isINTNameFormat: checked });
                        setINTNameFormat(checked);
                      }}
                    >
                      Select here if you do not have middle name.
                    </Checkbox>} */}
                {location?.partnerName !== 'goHealth' && (
                <WrapContainer>
                  <FormControl component="fieldset" variant="standard">
                    <RadioGroup
                      row
                      value={validationMethod}
                      onChange={(event) => {
                        if(cPartnerID === 'LLT001' && event.target.value === "myself"){
                            confirmationSchoolSelectionHandler(
                                "staff"
                              );
                              setErrormsg(false);
                              updateFormValues({
                                schoolTestFor: "staff",
                              });
                        }
                        confirmationSelectionHandler(event.target.value);
                        setErrormsg(false);
                        updateFormValues({
                          registeringFor: event.target.value,
                        });
                        const newMinors =
                          (event.target.value === 'minorOnly' ||
                            event.target.value === 'myselfAndMinor') &&
                            hasMinors
                            ? minors.map((minor) => ({
                              ...minor,
                              birthDate:
                                minor.birthDate &&
                                parse(
                                  minor.birthDate,
                                  config.dateFormat,
                                  new Date()
                                ),
                            }))
                            : [];
                        setFieldValue('minors', newMinors);
                        setDisable(true)
                      }}
                    >
                      <FormControlLabel
                        value="myself"
                        control={<StyledRadio />}
                        checked={registeringFor === 'myself'}
                        label={cPartnerID === 'LLT001' ? 'Staff/Teacher' : 'Myself'}
                      />
                      {form?.location?.partnerName !== 'goHealth' && form.cPartnerID !=="KEN001" && <>
                      <Gap />
                        <FormControlLabel
                            value="minorOnly"
                            control={<StyledRadio />}
                            checked={registeringFor === 'minorOnly'}
                            label={cPartnerID === 'LLT001' ? 'Minor/Student' : 'Minor(s) only'}
                        />
                        <Gap />
                        {form?.location?.partnerName !== 'goHealth' && cPartnerID !== 'LLT001' && (
                            <FormControlLabel
                            value="myselfAndMinor"
                            control={<StyledRadio />}
                            checked={registeringFor === 'myselfAndMinor'}
                            label={'Myself and Minor(s)'}
                            />
                        )}
                      </>}
                      
                    </RadioGroup>
                  </FormControl>
                </WrapContainer>
                )}
                {testSelection === 'school' && showList !== 'minorOnly' && cPartnerID !== 'POR001' &&  (
                  <>
                    <SubHeaderDescription style={{ marginBottom: '5px' }}>
                      I am booking for a <span style={{ color: 'red' }}>*</span>
                    </SubHeaderDescription>
                    <WrapContainer>
                      <FormControl component="fieldset" variant="standard">
                        <RadioGroup
                          row
                          value={schoolValidationMethod}
                          onChange={(event) => {
                            confirmationSchoolSelectionHandler(
                              event.target.value
                            );
                            setErrormsg(false);
                            updateFormValues({
                              schoolTestFor: event.target.value,
                            });
                          }}
                        >
                          { cPartnerID !== 'LLT001' && <>
                          <FormControlLabel
                            value="student"
                            control={<StyledRadio />}
                            checked={schoolTestFor === 'student'}
                            label={'Student'}
                          />
                          <Gap />
                           </>}
                          { form?.cPartnerID !== 'SCS001' && cPartnerID !== 'LLT001' && <>
                          <FormControlLabel
                            value="parentOrGuardian"
                            control={<StyledRadio />}
                            checked={schoolTestFor === 'parentOrGuardian'}
                            label={'Parent/Guardian of student'}
                          />
                          {/* <Gap /> */}
                           </>}
                          <FormControlLabel
                            value="staff"
                            control={<StyledRadio />}
                            checked={schoolTestFor === 'staff'}
                            label={cPartnerID === 'LLT001' ? 'Staff/Teacher' : 'Staff'}
                          />
                          <Gap />
                          { form?.cPartnerID !== 'SCS001' && cPartnerID !== 'LLT001' && <>
                          <FormControlLabel
                            value={ form?.cPartnerID === 'BEL001' ? 'communityMember' : 'faculty'}
                            control={<StyledRadio />}
                            checked={schoolTestFor === 'faculty' || schoolTestFor === 'communityMember'}
                            label={ form?.cPartnerID === 'BEL001' ? 'Community Member' : 'Faculty'}
                          /></> }
                        </RadioGroup>
                      </FormControl>
                    </WrapContainer>
                  </>
                )}
                {(showList === 'myself' || location?.partnerName === 'goHealth') && (
                  <>
                    {travellType === 'INT' && (
                      <Checkbox
                        name="isINTNameFormat"
                        onChange={(checked) => {
                          setFieldValue('isINTNameFormat', checked);
                          updateFormValues({ isINTNameFormat: checked });
                          setINTNameFormat(checked);
                        }}
                      >
                        I don't have a middle name.
                      </Checkbox>
                    )}
                    <InputRow>
                      {
                       cPartnerID === 'KEN001' ?  <FormLabel label="Employee ID">
                        <Input
                          value={values.employeeId}
                          name="employeeId"
                          onChange={(value) =>
                            updateFormValues({
                              employeeId: value ? value.trim() : '',
                            })
                          }
                        />
                      </FormLabel> : ''
                      }
                      <FormLabel label="First Name">
                        <Input
                          value={values.firstName}
                          name="firstName"
                          isRequired
                          validate={(value) => {
                            if (!value || !value.trim().length) {
                              return 'Required field';
                            }
                          }}
                          onChange={(value) =>
                            updateFormValues({
                              firstName: value ? value.trim() : '',
                            })
                          }
                        />
                      </FormLabel>

                      <FormLabel label="Middle Name">
                        <Input
                          value={values.middleName}
                          name="middleName"
                          isRequired
                          validate={(value) => {
                            let error;

                            if (
                              travellType !== undefined &&
                              travellType !== null &&
                              travellType === 'INT' &&
                              !values?.isINTNameFormat &&
                              value != null &&
                              value != 'undefined' &&
                              value.trim() === ''
                            ) {
                              error = 'Required field';
                            }
                            return error;
                          }}
                          onChange={(value) =>
                            updateFormValues({
                              middleName: value ? value.trim() : '',
                            })
                          }
                        />
                      </FormLabel>

                      <FormLabel label="Last Name">
                        <Input
                          value={lastName}
                          name="lastName"
                          isRequired
                          validate={(value) => {
                            if (!value || !value.trim().length) {
                              return 'Required field';
                            }
                          }}
                          onChange={(value) =>
                            updateFormValues({
                              lastName: value ? value.trim() : '',
                            })
                          }
                        />
                      </FormLabel>
                      <FormLabel label="Date of Birth">
                        <DatepickerField
                          name="birthDate"
                          maxDate={new Date()}
                          isRequired
                          inputPlaceholder="MM/DD/YYYY"
                          validate={(value) => {
                            let error;

                            if (differenceInYears(new Date(), value) < 18) {
                              error = 'You must be 18 years old or above';
                            }

                            return error;
                          }}
                        />
                      </FormLabel>
                    </InputRow>
                    {form?.location?.partnerName === 'goHealth' ?
                      <InputRow>
                        <FormLabel label="Phone Number">
                          <Input
                            value={phone}
                            name="goHealth.phone"
                            isRequired
                            validate={(value) => {
                              if (!value || !value.trim().length) {
                                return 'Required field';
                              }
                            }}
                            onChange={(value) =>
                              updateFormValues({
                                phone: value ? value.trim() : '',
                              })
                            }
                          />
                        </FormLabel>

                        <FormLabel label="Email">
                          <Input
                            value={email}
                            name="goHealth.email"
                            isRequired
                            validate={(value) => verifyEmail(value)}
                            onChange={(value) =>
                              updateFormValues({
                                email: value ? value.trim() : '',
                              })
                            }
                          />
                        </FormLabel></InputRow> : ''}
                    {travellType === 'INT' && (
                      <PassportRow>
                        <FormLabel label="Passport Country">
                          <Select<string>
                            name="passportCountry"
                            options={country}
                            onChange={(value: any) =>
                              updateFormValues({ passportCountry: value })
                            }
                            validate={(value) => {
                              if (travellType == 'INT' && !value) {
                                return 'Required field';
                              }
                            }}
                            isRequired
                          />
                        </FormLabel>
                        <FormLabel label="Passport #">
                          <Input
                          value={passportNo}
                            onChange={(value) =>
                              updateFormValues({ passportNo: value })
                            }
                            name="passportNo"
                            validate={(value) => {
                              if (
                                travellType == 'INT' &&
                                (!value || !value.trim().length)
                              ) {
                                return 'Required field';
                              }
                            }}
                            isRequired
                          />
                        </FormLabel>
                      </PassportRow>
                    )}
                    {testSelection === 'school' && cPartnerID !== 'POR001' && (
                      <PassportRow>
                        <FormLabel label="School">
                          <Select<string>
                            name="school"
                            options={schoolDropDown}
                            defaultValue={schoolDropDown.length === 1 ? schoolDropDown[0]: null}
                            onChange={(value: any) =>
                              updateFormValues({ school: value })
                            }
                            validate={(value) => {
                              if (testSelection === 'school' && !value && !form.school ) {
                                return 'Required field';
                              }
                            }}
                          />
                        </FormLabel>
                        {schoolShowList === 'student' && (
                          <FormLabel label={`Student ID # ${form.cPartnerID==='SCS001'?`(Optional)`:``}`}>
                            <Input
                              value={studentID}
                              onChange={(value) => {
                                updateFormValues({ studentID: value });
                              }}
                              name="studentID"
                              validate={(value) => {
                                if (
                                  testSelection === 'school' &&
                                  (!value || !value.trim().length) &&  form.cPartnerID !== 'SCS001'
                                ) {
                                  return 'Required field';
                                }
                              }}
                              isRequired
                            />
                          </FormLabel>
                        )}
                        {schoolShowList === 'staff' && (
                          <FormLabel label= {form?.cPartnerID === 'SCS001' || cPartnerID === 'LLT001' ? "Staff ID # (optional)" : "Staff ID #"}>
                            <Input
                            value={studentID}
                              onChange={(value) => {
                                updateFormValues({ studentID: value });
                              }}
                              name="studentID"
                              validate={(value) => {
                                if (
                                  testSelection === 'school' &&
                                  (!value || !value.trim().length) &&
                                  form?.cPartnerID !== 'SCS001' &&
                                  cPartnerID !== 'LLT001' && 
                                  schoolTestFor !== 'staff'
                                ) {
                                  return 'Required field';
                                }
                              }}
                              isRequired = {cPartnerID === 'LLT001' && schoolTestFor === 'staff' ? false : true }
                            />
                          </FormLabel>
                        )}
                        {(schoolShowList === 'faculty' || schoolShowList === 'communityMember')  && (
                          <FormLabel label={schoolShowList === 'faculty' ? "Faculty ID #" : "Community Member ID #" }>
                            <Input
                              onChange={(value) => {
                                updateFormValues({ studentID: value });
                              }}
                              name="studentID"
                              validate={(value) => {
                                if (
                                  testSelection === 'school' &&
                                  (!value || !value.trim().length)
                                ) {
                                  return 'Required field';
                                }
                              }}
                              isRequired
                            />
                          </FormLabel>
                        )}
                      </PassportRow>
                    )}
                  </>
                )}
                {location?.partnerName !== 'goHealth' && showList === 'minorOnly' && (
                  <>
                    <Title>Guardian Information</Title>
                    <GuardianWrapContainer>
                      <InputRow>
                        <FormLabel label="First Name">
                          <Input
                          value={values.firstName}
                            name="firstName"
                            isRequired
                            onChange={(value) =>
                              updateGuardianValue({
                                firstName: value ? value.trim() : '',
                              })
                            }
                          />
                        </FormLabel>
                        <FormLabel label="Last Name">
                          <Input
                            name="lastName"
                            value={values.lastName}
                            isRequired
                            onChange={(value) =>
                              updateGuardianValue({
                                lastName: value ? value.trim() : '',
                              })
                            }
                          />
                        </FormLabel>
                        <FormLabel label="Date of Birth">
                          <DatepickerField
                            name="birthDate"
                            maxDate={new Date()}
                            isRequired
                            inputPlaceholder="MM/DD/YYYY"
                            validate={(value) => {
                              let error;

                              if (differenceInYears(new Date(), value) < 18) {
                                error = 'You must be 18 years old or above';
                              }

                              return error;
                            }}
                          />
                        </FormLabel>

                        <FormLabel label="Relationship to minor">
                          <Input
                          value={values.guardian.relationship}
                            name="guardian.relationship"
                            isRequired
                            onChange={(value) =>
                              updateGuardianValue({
                                relationship: value ? value.trim() : '',
                              })
                            }
                          />
                        </FormLabel>
                      </InputRow>
                      {location?.partnerName !== 'goHealth' && cPartnerID !== 'LLT001' && (
                        <GuardianDetailsRow>
                            <FormLabel label="Phone Number">
                                <Input
                                value={values.guardian.phone}
                                name="guardian.phone"
                                isRequired
                                onChange={(value) =>
                                    updateGuardianValue({
                                    phone: value ? value.trim() : '',
                                    })
                                }
                                validate={(value) => {
                                    let error;
    
                                    if (!isUSPhone.test(value)) {
                                    error = 'Please enter valid US phone number';
                                    }
    
                                    if (value === '') {
                                    error = 'Required field';
                                    }
    
                                    return error;
                                }}
                                />
                            </FormLabel>
                            <FormLabel label="Email Address">
                                <Input
                                value={values.guardian.email}
                                name="guardian.email"
                                onChange={(value) =>
                                    updateGuardianValue({
                                    email: value ? value.trim() : '',
                                    })
                                }
                                validate={(value) => verifyEmail(value)}
                                isRequired
                                />
                            </FormLabel>
                        </GuardianDetailsRow>
                      )}
                    </GuardianWrapContainer>
                    {location?.partnerName !== 'goHealth' && (
                    <MinorsWthOutReg
                      isRegister={false}
                      isProfile={false}
                      setAddMinor={(p: boolean) => {
                        return;
                      }}
                      schoolDropDown={schoolDropDown}
                    />)}
                  </>
                )}
                {location?.partnerName !== 'goHealth' && showList === 'myselfAndMinor' && (
                  <>
                    {travellType === 'INT' && (
                      <Checkbox
                        name="isINTNameFormat"
                        onChange={(checked) => {
                          setFieldValue('isINTNameFormat', checked);
                          updateFormValues({ isINTNameFormat: checked });
                          setINTNameFormat(checked);
                        }}
                      >
                        I don't have a middle name.
                      </Checkbox>
                    )}
                    <InputRow>
                      <FormLabel label="First Name">
                        <Input
                        value={values.firstName}
                          name="firstName"
                          isRequired
                          validate={(value) => {
                            if (!value || !value.trim().length) {
                              return 'Required field';
                            }
                          }}
                          onChange={(value) =>
                            updateFormValues({
                              firstName: value ? value.trim() : '',
                            })
                          }
                        />
                      </FormLabel>

                      <FormLabel label="Middle Name">
                        <Input
                          name="middleName"
                          value={values.middleName}
                          isRequired
                          validate={(value) => {
                            let error;

                            if (
                              travellType !== undefined &&
                              travellType !== null &&
                              travellType === 'INT' &&
                              !values?.isINTNameFormat &&
                              value != null &&
                              value != 'undefined' &&
                              value.trim() === ''
                            ) {
                              error = 'Required field';
                            }

                            return error;
                          }}
                          onChange={(value) =>
                            updateFormValues({
                              middleName: value ? value.trim() : '',
                            })
                          }
                        />
                      </FormLabel>

                      <FormLabel label="Last Name">
                        <Input
                        value={values.lastName}
                          name="lastName"
                          isRequired
                          validate={(value) => {
                            if (!value || !value.trim().length) {
                              return 'Required field';
                            }
                          }}
                          onChange={(value) =>
                            updateFormValues({
                              lastName: value ? value.trim() : '',
                            })
                          }
                        />
                      </FormLabel>
                      <FormLabel label="Date of Birth">
                        <DatepickerField
                          name="birthDate"
                          maxDate={new Date()}
                          isRequired
                          inputPlaceholder="MM/DD/YYYY"
                          validate={(value) => {
                            let error;

                            if (differenceInYears(new Date(), value) < 18) {
                              error = 'You must be 18 years old or above';
                            }

                            return error;
                          }}
                        />
                      </FormLabel>
                    </InputRow>
                    {travellType === 'INT' && (
                      <PassportRow>
                        <FormLabel label="Passport Country">
                          <Select<string>
                            name="passportCountry"
                            options={country}
                            onChange={(value: any) =>
                              updateFormValues({ passportCountry: value })
                            }
                            validate={(value) => {
                              if (travellType == 'INT' && !value) {
                                return 'Required field';
                              }
                            }}
                          />
                        </FormLabel>
                        <FormLabel label="Passport #">
                          <Input
                          value={values?.passportNo}
                            onChange={(value) =>
                              updateFormValues({ passportNo: value })
                            }
                            name="passportNo"
                            validate={(value) => {
                              if (
                                travellType == 'INT' &&
                                (!value || !value.trim().length)
                              ) {
                                return 'Required field';
                              }
                            }}
                            isRequired
                          />
                        </FormLabel>
                      </PassportRow>
                    )}

                    {testSelection === 'school' && cPartnerID !== 'POR001' && (
                      <PassportRow>
                        <FormLabel label="School">
                          <Select<string>
                            name="school"
                            options={schoolDropDown}
                            defaultValue={schoolDropDown.length === 1 ? schoolDropDown[0]: null}
                            onChange={(value: any) =>
                              updateFormValues({ school: value })
                            }
                            validate={(value) => {
                              if (testSelection === 'school' && !value && !form.school ) {
                                return 'Required field';
                              }
                            }}
                          />
                        </FormLabel>
                        {schoolShowList === 'student' && (
                          <FormLabel label="Student ID #">
                            <Input
                            value={values.studentID}
                              onChange={(value) => {
                                updateFormValues({ studentID: value });
                              }}
                              name="studentID"
                              validate={(value) => {
                                if (
                                  testSelection === 'school' &&
                                  (!value || !value.trim().length)
                                ) {
                                  return 'Required field';
                                }
                              }}
                              isRequired
                            />
                          </FormLabel>
                        )}
                        {schoolShowList === 'staff' && (
                           <FormLabel label= {form?.cPartnerID === 'SCS001' ? "Staff ID # (optional)" : "Staff ID #"}>
                            <Input
                            value={values.studentID}
                              onChange={(value) => {
                                updateFormValues({ studentID: value });
                              }}
                              name="studentID"
                              validate={(value) => {
                                if (
                                  testSelection === 'school' &&
                                  (!value || !value.trim().length) &&
                                  form?.cPartnerID !== 'SCS001'
                                ) {
                                  return 'Required field';
                                }
                              }}
                              isRequired
                            />
                          </FormLabel>
                        )}
                        {schoolShowList === 'faculty' && cPartnerID !== 'BEL001' && (
                          <FormLabel label="Faculty ID #">
                            <Input
                              onChange={(value) => {
                                updateFormValues({ studentID: value });
                              }}
                              name="studentID"
                              validate={(value) => {
                                if (
                                  testSelection === 'school' &&
                                  (!value || !value.trim().length)
                                ) {
                                  return 'Required field';
                                }
                              }}
                              isRequired
                            />
                          </FormLabel>
                        )}
                      </PassportRow>
                    )}
                    {location?.partnerName !== 'goHealth' &&
                    <MinorsWthOutReg
                      isRegister={false}
                      isProfile={false}
                      setAddMinor={(p: boolean) => {
                        return;
                      }}
                      schoolDropDown={schoolDropDown}
                    />}
                  </>
                )}

                {errormsg && (
                  <ErrorText hasError>
                    {'Please choose one of the answers'}
                  </ErrorText>
                )}
                {errormsgs && (
                  <ErrorTextLarge hasError>
                    {errormsgs}
                  </ErrorTextLarge>
                )}
                <NavigationButtons
                  loading={loading}
                  onReturnButtonClick={() => {
                    const link = localStorage.getItem('link');
                    if(apptFromSchdule && !testSelectFlow && cPartnerID!=='KEN001') {
                        if(link?.includes('_ga'))
                            goToPrevStep();
                        else
                            loginRedirect();
                    }
                    else if(cPartnerID === 'LLT001'){
                        goToPrevStep();
                    }
                     goToPrevStep();
                  }}
                  isValid={true}
                />
              </Form>
            );
          }}
        </Formik>
      </Content>
    </Container>
  );
};

export default SchedulinginfoWthOutReg;
