import { Button } from "../../../shared/components/styled";

const formItemLayout = {
  labelCol: {
    md: {
      span: 24,
    },
    lg: {
      span: 8,
    },
  },
  wrapperCol: {
    md: {
      span: 24,
    },
    lg: {
      span: 16,
    },
  },
};

const searchBarActionLayout = {
  wrapperCol: {
    md: {
      span: 24,
    },
    lg: {
      offset: 8,
      span: 16,
    },
  },
};

const formatTableColumn = (
  renderSymptoms: any,
  renderResults: any,
  renderActionsMenuTitle: any,
  renderActionsMenu: any
) => [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    width: 150,
    align: 'center',
  },
  {
    title: 'Appt Time',
    dataIndex: 'period',
    key: 'period',
    width: 90,
    align: 'center',
  },
  {
    title: 'Status',
    dataIndex: 'isCanceled',
    key: 'isCanceled',
    width: 90,
    align: 'center',
  },
  {
    title: 'Airline',
    dataIndex: 'airlineCode',
    key: 'airlineCode',
    width: 90,
    align: 'center',
  },
  {
    title: 'Travel Type',
    dataIndex: 'travelType',
    key: 'travelType',
    width: 90,
    align: 'center',
  },
  {
    title: 'Destination',
    dataIndex: 'destination',
    key: 'destination',
    width: 100,
    align: 'center',
  },
  {
    title: 'VIP Slot',
    dataIndex: 'isExpressSameDayTest',
    key: 'isExpressSameDayTest',
    width: 90,
    align: 'center',
    
  },
  {
    title: 'Rapid',
    dataIndex: 'isRapidTest',
    key: 'isRapidTest',
    width: 90,
    align: 'center',
    
  },
  {
    title: 'Confirmation No',
    dataIndex: 'confirmationId',
    key: 'confirmationId',
    width: 195,
    align: 'center',
    sorter: (a: any, b: any) => a.confirmationId > b.confirmationId,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Sample ID',
    dataIndex: 'sampleId',
    key: 'sampleId',
    width: 90,
    align: 'center',
  },
  {
    title: 'First Name',
    dataIndex: 'firstName',
    key: 'firstName',
    width: 120,
    align: 'center',
    sorter: (a: any, b: any) => a.firstName > b.firstName,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Last Name',
    dataIndex: 'lastName',
    key: 'lastName',
    width: 120,
    align: 'center',
    sorter: (a: any, b: any) => a.lastName > b.lastName,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    width: 254,
    align: 'center',
    sorter: (a: any, b: any) => a.email > b.email,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'DOB',
    dataIndex: 'birthDate',
    key: 'birthDate',
    width: 124,
    align: 'center',
  },
  {
    title: 'Phone Number',
    dataIndex: 'phone',
    key: 'phone',
    width: 140,
    align: 'center',
    sorter: (a: any, b: any) => a.phone > b.phone,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Flight Date and Time',
    dataIndex: 'departureDateAndTime',
    key: 'departureDateAndTime',
    width: 100,
    align: 'center',
  },
  // {
  //   title: 'Symptoms Reported?',
  //   dataIndex: 'symptoms',
  //   key: 'symptoms',
  //   width: 100,
  //   align: 'center',
  //   render: renderSymptoms,
  // },
  // {
  //   title: 'Contact in last 14 days?',
  //   dataIndex: 'hadContact',
  //   key: 'hadContact',
  //   width: 120,
  //   align: 'center',
  //   filters: [
  //     {
  //       text: 'Yes',
  //       value: 'Yes',
  //     },
  //     {
  //       text: 'No',
  //       value: 'No',
  //     },
  //   ],
  //   filterMultiple: false,
  //   onFilter: (value: string, record: any) =>
  //     record.hadContact.indexOf(value) === 0,
  // },
  {
    title: 'Results',
    dataIndex: 'results',
    key: 'results',
    render: renderResults,
    width: 150,
    align: 'center',
  },
  {
    title: renderActionsMenuTitle,
    key: 'actions',
    width: 48,
    align: 'center',
    fixed: 'right',
    render: renderActionsMenu,
  },
 
];

let sameKey: any;

const appointmentCheckingTable = (appointmentCheckingTable: any) =>  [
{
  title: 'Confirmation No',
  dataIndex: 'confirmationId',
  key: 'confirmationId',
  width: 195,
  align: 'center',
  sorter: (a: any, b: any) => a.confirmationId > b.confirmationId,
  sortDirections: ['descend', 'ascend'],
},
{
  title: 'First Name',
  dataIndex: 'firstName',
  key: 'firstName',
  width: 120,
  align: 'center',
  sorter: (a: any, b: any) => a.firstName > b.firstName,
  sortDirections: ['descend', 'ascend'],
},
{
  title: 'Last Name',
  dataIndex: 'lastName',
  key: 'lastName',
  width: 120,
  align: 'center',
  sorter: (a: any, b: any) => a.lastName > b.lastName,
  sortDirections: ['descend', 'ascend'],
},
{
  title: 'DOB',
  dataIndex: 'birthDate',
  key: 'birthDate',
  width: 124,
  align: 'center',
},
{
  title: 'Phone Number',
  dataIndex: 'phone',
  key: 'phone',
  width: 140,
  align: 'center',
  sorter: (a: any, b: any) => a.phone > b.phone,
  sortDirections: ['descend', 'ascend'],
},
{
  title: 'Email',
  dataIndex: 'email',
  key: 'email',
  width: 254,
  align: 'center',
  sorter: (a: any, b: any) => a.email > b.email,
  sortDirections: ['descend', 'ascend'],
},
{
  title: 'Action',
  width: 75,
  align: 'center',
  render: appointmentCheckingTable
}]

export { formItemLayout, searchBarActionLayout, formatTableColumn, appointmentCheckingTable };
