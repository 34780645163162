import React from 'react';
import styled from 'styled-components';

import { colors } from '../../../styles/colors';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 12px 0;
  font-size: 14px;
  color: ${colors.white};
  & > * {
    display: block;
    margin: 0 15px;
  }

  button {
    background: none;
    border: 0;
    color: ${colors.white};
    cursor: pointer;
  }
  
  
`;

const FooterRegister: React.FC = () => {
  return (
    <div>
      <Container>
        <a href="https://worksitelabs.com/terms-conditions/"
          target="_blank"
          rel="noreferrer noopener"
          style={{ color: "#1E93D1" }}
        >Terms & Conditions</a>
        <a
          href="https://www.worksitelabs.com/privacy"
          target="_blank"
          rel="noreferrer noopener"
          style={{ color: "#1E93D1" }}
        >
          Privacy Policy
        </a>
        {/* {window.location.pathname !== '/manage' &&
          window.location.pathname !== '/admin' && (
            <button
              type="button"
              onClick={() => (window.location.pathname = '/manage')}
            >
              Manage Appointment
            </button>
          )} */}
        {/* <div>
         
        </div> */}
      </Container>
    </div>
  );
};

export default FooterRegister;
