import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { Breakpoints } from '../../../../dictionaries';
import { Form, Formik } from 'formik';
import Input from '../../../shared/components/form/Input';
import { useSharedDispatch, useSharedState } from '../../../shared/provider';
import { useStepsDispatch, useStepsState } from '../../provider';
import Modal from './../../../shared/components/Modal';
import { Button } from './../../../shared/components/styled';
import { colors } from '../../../../styles/colors';
import { updateRegister } from '../../../register/api';
import FormLabel from '../../../shared/components/form/FormLabel';
import { config } from '../../../../config';

const Content = styled.div`
  width: 100%;

  @media (min-width: ${Breakpoints.md}px) {
    width: 540px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 240px;
  margin: 0 -5px;

  & > * {
    margin: 0 5px;
  }
  @media (max-width: ${Breakpoints.sm}px) {
      display: grid;
      row-gap: 10px;
      margin-top: 30px;
  }
    `;

const Title = styled.h2`
  color: ${colors.fullBlack};
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 25px;
`;
const Container = styled.div`
`;
const InputRow = styled.div`
  display: flex;
  flex-direction: column;
  margin: -8px 0;

  @media (min-width: ${Breakpoints.sm}px) {
    flex-direction: row;
    margin: 0 -8px;
  }

  @media (min-width: ${Breakpoints.lg}px) {
    margin: 0 -15px;
  }

  & > * {
    flex-basis: 33%;
    margin: 8px 0;

    @media (min-width: ${Breakpoints.sm}px) {
      margin: 0 8px;
    }

    @media (min-width: ${Breakpoints.lg}px) {
      margin: 0 15px;
    }
  }
`;
type Props = {
    open: boolean;
    handleNameClose: ()=> void,
    isProfile: boolean,
    setErrormsg?: ()=> void,
}
const ChangeNameModal: React.FC<Props>= ( { handleNameClose, open, isProfile, setErrormsg }) => {
    const [loading, setLoading] = useState(false);
    const {
      form: { email, firstName, lastName, middleName, isINTNameFormat },
    } = useStepsState();
    const {userData} = useSharedState();
    const {setloggedUserData} = useSharedDispatch();
    const { updateFormValues, goToNextStep, goToPrevStep } = useStepsDispatch();
     const [newFirstName, setNewFirstName] = useState(userData?.firstName);
     const [newMiddleName, setNewMiddleName] = useState(userData?.middleName);
     const [newLastName, setNewLastName] = useState(userData?.lastName);
    const updateChangeOnClick = async(values: any) => {
      setLoading(true);
      if(isProfile) {
          let resultdata = await updateRegister({email: userData?.email, firstName: values?.firstName, middleName: values?.middleName, lastName: values?.lastName});
          if(resultdata?.data?.status === 200) {
            setloggedUserData(resultdata?.data?.data);
            localStorage.setItem('user', JSON.stringify(userData));
            !!setErrormsg && setErrormsg();
          }
      }
      handleNameClose();
      setLoading(false);
    }
  
    return (
        <Modal open={open} onClose={handleNameClose} maxWidth={800}>
            <Container>
            <Title>Enter your information</Title>
                  <Formik
                  enableReinitialize
                  initialValues={{
                      firstName: isProfile ? userData?.firstName: firstName,
                      lastName: isProfile ? userData?.lastName: lastName,
                      middleName: isProfile ? userData?.middleName: middleName,
                  }}
                  onSubmit={(values) => {
                    updateFormValues({
                      ...values,
                      });
                      updateChangeOnClick(values);
                  }}
                  >
                  {({ setFieldValue }) => {
              return (
            <Form>
              <InputRow>
                <FormLabel label="First Name">
                  <Input
                    name="firstName"
                    isRequired
                    value={newFirstName}
                     onChange={(value) => {
                         updateFormValues({ firstName: value })
                                    setNewFirstName(value);
                                    return value;
                                }}
                    validate={(value) => {
                      if (!value || !value.trim().length) {
                        return 'Required field';
                      } else if(value.length > config.nameMaxLimit){
                        return  'Name length must below 50 characters'
                      }
                    }}
                    // onChange={(value) => updateFormValues({ firstName: value ? value.trim() : ''})}
                  />
                </FormLabel>
                {!isINTNameFormat &&
                  <FormLabel label="Middle Name">
                    <Input
                      name="middleName"
                      value={newMiddleName}
                      onChange={(value) => {
                          updateFormValues({ middleName: value })
                                        setNewMiddleName(value);
                                        return value;
                                    }}
                      validate={(value) => {
                        // if (!value || !value.trim().length) {
                        //   return 'Required field';
                        // } else
                         if(value.length > config.nameMaxLimit){
                          return  'Name length must below 50 characters'
                        }
                      }}
                      // onChange={(value) => updateFormValues({ firstName: value ? value.trim() : ''})}
                    />
                  </FormLabel>}
                <FormLabel label="Last Name">
                  <Input
                    name="lastName"
                    isRequired
                    value={newLastName}
                    onChange={(value) => {
                        updateFormValues({ lastName: value })
                                    setNewLastName(value);
                                    return value;
                                }}
                    validate={(value) => {
                      if (!value || !value.trim().length) {
                        return 'Required field';
                      } else if(value.length > config.nameMaxLimit){
                        return  'Name length must below 50 characters'
                      }
                    }}
                    // onChange={(value) => updateFormValues({ lastName: value ? value.trim() : ''})}
                  />
                </FormLabel>
              </InputRow>
              <ButtonsContainer>
                <Button
                  libraryType="default"
                  size="sm"
                  type="button"
                  onClick={() => {
                    handleNameClose();
                      }}
                    className='btn elevated-btn'
                >
                  Cancel
                </Button>
                <Button libraryType="primary" size="sm" type="submit" className='btn fill-btn'>
                {loading ? 'Processing...' : 'Confirm'}                </Button>
              </ButtonsContainer>
                </Form>
              );
            }}
          </Formik>
          </Container>
        </Modal>
    );
  };
  
  export default ChangeNameModal;
  