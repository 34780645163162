import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import styled from 'styled-components';
import { differenceInYears, format, parse } from 'date-fns';
import moment from 'moment';
import {
  Container,
  PageHeader,
  Button,
  ErrorText,
} from '../../../shared/components/styled';
import { useStepsState, useStepsDispatch } from '../../../schedule/provider';
import { Breakpoints } from '../../../../dictionaries';

import FormLabel from '../../../shared/components/form/FormLabel';
import Input from '../../../shared/components/form/Input';
import { DatepickerField } from '../../../shared/components/Datepicker';

import { colors } from '../../../../styles/colors';
import { makeStyles } from '@material-ui/core/styles';
import Select from '../../../shared/components/form/Select';
// import { PayerList } from '../NewInsuranceScreen/PayerList';
import { InsuranceCarrierTwo } from '../NewInsuranceScreen/insuranceCarrierTwo';
import Modal from './../../../shared/components/Modal';
import { AppointmentListItem } from '../../../shared/models';
import { updateInsurance } from '../../../schedule/api';
import { AiOutlinePicture } from 'react-icons/ai';
import { firebase } from './../../../../firebase';
import pencil from '../../../../assets/pencil.png';
import { useSharedDispatch, useSharedState } from '../../../shared/provider';
import {
  getUserInsurance,
  updateRegister,
  updateUserInsurance,
} from '../../api';
import { config } from '../../../../config';
import { validateComma } from '../../../../validations';
import { EditOutlined } from '@ant-design/icons';
import { pVerifyEligibilityInquiry } from '../../../shared/api';
import Alert from '@material-ui/lab/Alert';
  {/* Pencilogo*/}
const useStyles = makeStyles({
    hideImage: {
      display: "none"
    },
    showImage: {
      display: "block"
    }
  })
export const PageTitle = styled.h2`
  color: ${colors.fullBlack};
  font-size: 28px;
  font-weight: 700;
  margin-top:10px;
  text-align: center;

  @media (max-width: ${Breakpoints.sm}px) {
    font-size: 24px;
    margin-top:10px;
    padding: 20px;
  }
  @media (max-width: ${Breakpoints.md}px) {
    margin-top:10px;
    padding: 20px;
  }
`;
const InputRow = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${Breakpoints.sm}px) {
    flex-direction: row;
  }

  @media (min-width: ${Breakpoints.lg}px) {
    margin: 0 -15px;
  }

  & > * {
    flex-basis: 50%;
    margin: 8px 0;

    @media (min-width: ${Breakpoints.sm}px) {
      margin: 0 8px;
    }
    @media (min-width: ${Breakpoints.lg}px) {
      margin: 0 15px;
    }
  }
`;
export const Content = styled.div`
  padding: 10px;

  @media (min-width: ${Breakpoints.md}) {
    padding: 88px 0;
  }
  @media (max-width: ${Breakpoints.sm}px) {
    padding: 0px !important;
  }
`;
const BtnContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  flex-wrap: nowrap;
  padding-top: 20px;

  @media (min-width: 450px) {
    flex-direction: row;
  }
`;
const Space = styled.div`
  height: 10px;
  width: 20px;
`;

const MobileResponsiveGap = styled.div`
  margin-top: 0px;
  @media (max-width: ${Breakpoints.sm}px) {
    margin-top: 10px;
  }
`;

export const StyledButton = styled.button`
  border: 1px dashed ${colors.grey};
  border-radius: 10px;
  font-size: 16px;
  font-weight: 500;
  color: rgb(26 150 219);
  line-height: 24px;
  padding: 15px 28px;
  transition: all 0.15s ease-in-out;
  width: 100%;
  cursor: pointer;

  &:focus,
  &:active {
    border: 1px dotted ${colors.blueLight};
    box-shadow: 0 0 4px ${(colors.blueLight, 0.8)};
  }
  @media (max-width: ${Breakpoints.sm}px) {
    margin-top: 40px;
  }
`;

const SubNameandDob = styled.div`
  display: grid;
  @media (max-width: ${Breakpoints.sm}px) {
    row-gap: 30px;
  }
`;
const Testtag = styled.div`
  display: flex;
  gap: 20px;
  @media (max-width: ${Breakpoints.sm}px) {
    gap: 10px;
  }
`;
const ExpandIcon = styled.div`
  width: 20px;
  position: relative;
`;
const Expandtext = styled.div`
  font-size: 16px;
  font-weight: 500;
`;
const Card = styled.div`
  margin-top: 20px;
  @media (max-width: ${Breakpoints.sm}px) {
  }
`;
const EditTag = styled.div`
  display: flex;
  gap: 30px;
  @media (max-width: ${Breakpoints.sm}px) {
    gap: 10px;
  }
`;
const PencilLogo = styled.div`
  height: 20%;
  width: auto;

  background: url(${pencil}) no-repeat center center;
  @media (max-width: ${Breakpoints.sm}px) {
    height: 25px;
  }
`;
export interface Insurance {
  id: string;
  insuranceId: string;
  email: string;
  group: string;
  subscriberFirstName: string;
  subscriberLastName: string;
  frontCard: string;
  backCard: string;
  payerList: string;
  newCarrierName: string;
  policyHolderFirstName: string;
  policyHolderLastName: string;
  policyHolderbirthDate: string;
  insuranceFor: string;
  subscriberbirthDate: string;
}
type Props = {
  open: boolean;
  close: () => void;
  insuranceList: Insurance;
  isProfile: boolean;
  apptId?: string;
  handleInsuranceReload?: () => void;
};
const ChangeInsuranceModal: React.FC<Props> = ({
  open,
  close,
  insuranceList,
  isProfile,
  apptId,
  handleInsuranceReload,
}) => {
    const classes = useStyles()
  useEffect(() => {
   
    window.scrollTo(0, 0);
  }, []);
  const {
    form: { hasInsurance },
  } = useStepsState();

  const [errormsg2, setErrormsg2] = useState(false);
  const { updateFormValues } = useStepsDispatch();
  const [loading, setLoading] = useState(false);
  const [fileFrontURL, setFileFrontURL] = useState(insuranceList?.frontCard);
  const [fileBackURL, setFileBackURL] = useState(insuranceList?.backCard);
  const [isFrontCard, setFrontCard] = useState(false);
  const [isbackCard, setBackCard] = useState(false);
  const [insuranceFor, setInsuranceFor] = useState(insuranceList?.insuranceFor)
  const { userData, userInsuranceData, payer } = useSharedState();
  const { setloggedUserData, updateInsuranceList } = useSharedDispatch();
  const [error, setError] = useState("");
  const [count, setCount] = useState(0);
  const [info, setInfo] = useState(false)
  let payerType: any = payer ?? [];

  const updateChangeOnClick = async (values: any) => {
    setLoading(true);
    const d = {
      email: userData?.email,
      id: insuranceList.id,
      group: values.insurance?.group,
      subscriberFirstName:
        insuranceFor !== "i'm"
          ? values.insurance?.policyHolderFirstName
          : values.insurance?.subscriberFirstName,
      subscriberLastName:
        insuranceFor !== "i'm"
          ? values.insurance?.policyHolderLastName
          : values.insurance?.subscriberLastName,
      insuranceId: values.insurance?.insuranceId,
      payerList:
        values.insurance.payerList?.value === 'Other'
          ? values.insurance.newCarrierName
          : values.insurance.payerList?.label,
      newCarrierName:
        values.insurance.payerList?.value === 'Other'
          ? values.insurance.payerList?.label
          : '',
      frontCard: values.insurance.payerList?.label === 'Other'||count >= 2 ? fileFrontURL : '',
      backCard:values.insurance.payerList?.label === 'Other'|| count >= 2 ? fileBackURL : '',
      policyHolderFirstName: values.insurance?.policyHolderFirstName ?? '',
      policyHolderLastName: values.insurance?.policyHolderLastName ?? '',
      policyHolderbirthDate:
        values.insurance.policyHolderbirthDate &&
        format(values.insurance.policyHolderbirthDate, config.dateFormat),
      subscriberbirthDate:
        values.insurance?.subscriberbirthDate &&
        format(
          new Date(values.insurance?.subscriberbirthDate),
          config.dateFormat
        ),
      pVerify: count >= 2 ? false : true,
      // createdDate:moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
    };
      let resultdata = await updateUserInsurance(d);
  
      updateFormValues({
        insurance: {
          insuranceId: "",
          group: "",
          subscriberFirstName: "",
          subscriberLastName: "",
          payerList: "",
          insuranceFor: "",
          policyHolderFirstName: "",
          policyHolderLastName: "",
          policyHolderbirthDate: "",
          subscriberbirthDate: ""
        }
      })

    if (resultdata?.data?.status === 200) {
      try {
        setLoading(true);
        let updatedData = await getUserInsurance(userData?.email);
        if (updatedData?.data?.status === 200) {
          updateInsuranceList(updatedData?.data?.data);
        }
        setLoading(false);
        !!handleInsuranceReload ? handleInsuranceReload() : close();
      } catch (e) {
        setLoading(false);
        close();
      }
    }
    setLoading(false);
  };

  const handleChangeFront = async (e: any) => {
    try {
      setLoading(true);
      const file = e.target.files[0];
      const fileName: any = `F-insurance-${Date.now()}`
      const storageref = firebase.storage().ref();
      const fileref = storageref.child(fileName);
      await fileref.put(file).then(() => {
        console.log('filename', file.type);
      });
      if (
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/tiff-fx' ||
        file.type === 'image/tiff'
      ) {
        const url: string = await fileref.getDownloadURL();

        setFileFrontURL(url);
        updateFormValues({ frontCard: url });
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch {
      setFrontCard(false);
    }
  };

  const handleChangeBack = async (e: any) => {
    try {
      setLoading(true);
      const file = e.target.files[0];
      const fileName: any = `B-insurance-${Date.now()}`
      const storageref = firebase.storage().ref();
      const fileref = storageref.child(fileName);
      await fileref.put(file).then(() => {
        // console.log("filename", file?.type)
      });
      if (
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/tiff-fx' ||
        file.type === 'image/tiff'
      ) {
        const url: string = await fileref.getDownloadURL();

        setFileBackURL(url);
        updateFormValues({ backCard: url });
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch {
      setBackCard(false);
    }
  };

  const validateSubmit = (initial: any, Secondary: any) => {
    if (initial.policyHolderFirstName !== Secondary.insurance.policyHolderFirstName ||
      initial.policyHolderLastName !== Secondary.insurance.policyHolderLastName ||
      (initial.insuranceFor !== "i'm" && initial.policyHolderbirthDate !== format(new Date(Secondary.insurance.policyHolderbirthDate), 'MM/dd/yyyy')) ||
      initial.subscriberFirstName !== Secondary.insurance.subscriberFirstName ||
      initial.subscriberLastName !== Secondary.insurance.subscriberLastName ||
      (initial.insuranceFor === "i'm" &&
        initial.subscriberbirthDate !== format(new Date(Secondary.insurance.subscriberbirthDate), 'MM/dd/yyyy')) ||
      initial.payerList !== Secondary.insurance.payerList?.label ||
      initial.group !== Secondary.insurance.group
    ) {
      return true
    }
    return false
  }

  const scrollTOTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  return (
    <Modal open={open} onClose={close} maxWidth={800} blockScroll={false}>
      <Content>
        <PageHeader>
          <PageTitle>Edit your insurance billing information</PageTitle>
        </PageHeader>
        <Container marginTop>
          <Formik
            enableReinitialize
            initialValues={{
              insurance: {
                group: insuranceList?.group,
                subscriberFirstName: insuranceList?.subscriberFirstName,
                subscriberLastName: insuranceList?.subscriberLastName,
                insuranceId: insuranceList?.insuranceId,
                payerList: payerType.find(
                  (name: any) =>
                    name.label ==
                    (insuranceList?.newCarrierName === 'Other'
                      ? insuranceList?.newCarrierName
                      : insuranceList?.payerList)
                ),
                newCarrierName:
                  insuranceList?.newCarrierName !== 'Other'
                    ? ''
                    : insuranceList?.payerList,
                frontCard: insuranceList?.frontCard,
                backCard: insuranceList?.backCard,
                policyHolderFirstName: insuranceList?.policyHolderFirstName,
                policyHolderLastName: insuranceList?.policyHolderLastName,
                policyHolderbirthDate:
                  insuranceList?.policyHolderbirthDate != null &&
                  typeof insuranceList?.policyHolderbirthDate == 'string'
                    ? insuranceList?.policyHolderbirthDate &&
                      parse(
                        insuranceList?.policyHolderbirthDate,
                        config.dateFormat,
                        new Date()
                      )
                    : '',
                subscriberbirthDate:
                  insuranceFor === "i'm" && userData?.birthDate !== null
                    ? parse(
                        insuranceList?.subscriberbirthDate ??
                          userData?.birthDate,
                        config.dateFormat,
                        new Date()
                      )
                    : '',
              },
            }}
            onSubmit={async (values) => {
              // const validate: any = await validateSubmit(insuranceList, values)
              // if (validate) {
              setInfo(false);
              if (
                count < 2 &&
                values?.insurance?.payerList?.label !== 'Other'
              ) {
                let data: any = {
                  payerCode: values.insurance.payerList?.value ?? '',
                  provider: {
                    firstName: '',
                    middleName: '',
                    lastName: values.insurance?.payerList?.label ?? '',
                    npi: '1467030536',
                  },
                  subscriber: {
                    firstName:
                      insuranceFor !== "i'm"
                        ? values.insurance.policyHolderFirstName
                        : values.insurance?.subscriberFirstName,
                    middleName: '',
                    lastName:
                      insuranceFor !== "i'm"
                        ? values.insurance.policyHolderLastName
                        : values.insurance?.subscriberLastName,
                    dob:
                      insuranceFor === "i'm"
                        ? values?.insurance.subscriberbirthDate &&
                          format(
                            new Date(values?.insurance.subscriberbirthDate),
                            config.dateFormat
                          )
                        : values.insurance.policyHolderbirthDate &&
                          format(
                            values.insurance.policyHolderbirthDate,
                            config.dateFormat
                          ),
                    memberID: values.insurance.insuranceId ?? '',
                  },
                  dependent: {
                    patient: {
                      firstName: values.insurance.policyHolderFirstName
                        ? values.insurance.policyHolderFirstName
                        : '',
                      middleName: '',
                      lastName: values.insurance.policyHolderLastName
                        ? values.insurance.policyHolderLastName
                        : '',
                      dob: moment(
                        new Date(
                          values.insurance?.policyHolderbirthDate ?? null
                        )
                      ).format('MM/DD/YYYY'),
                    },
                  },
                  isSubscriberPatient: 'False',
                  doS_StartDate: moment().format('MM/DD/YYYY'),
                  doS_EndDate: moment().format('MM/DD/YYYY'),
                  requestSource: 'RestAPI',
                  PracticeTypeCode: '74',
                  referenceId: '',
                  Location: '',
                  IncludeTextResponse: false,
                };
                if (insuranceFor === "i'm") {
                  data.isSubscriberPatient = 'True';
                  delete data.dependent;
                }

                updateFormValues({
                  ...values,
                  insurance: {
                    group: values.insurance?.group,
                    subscriberFirstName:
                      insuranceFor !== "i'm"
                        ? values.insurance.policyHolderFirstName
                        : values.insurance?.subscriberFirstName,
                    subscriberLastName:
                      insuranceFor !== "i'm"
                        ? values.insurance.policyHolderLastName
                        : values.insurance?.subscriberLastName,
                    insuranceId: values.insurance?.insuranceId,
                    payerList: values.insurance.payerList?.label || null,
                    frontCard: '',
                    backCard: '',
                    policyHolderFirstName: values.insurance
                      .policyHolderFirstName
                      ? values.insurance.policyHolderFirstName
                      : '',
                    policyHolderLastName: values.insurance.policyHolderLastName
                      ? values.insurance.policyHolderLastName
                      : '',
                    policyHolderbirthDate:
                      values.insurance.policyHolderbirthDate &&
                      format(
                        values.insurance.policyHolderbirthDate,
                        config.dateFormat
                      ),
                    subscriberbirthDate:
                      values.insurance.subscriberbirthDate &&
                      format(
                        new Date(values.insurance.subscriberbirthDate),
                        config.dateFormat
                      ),
                    pVerify: true,
                  },
                });
                console.log('values', hasInsurance);

                if (values) {
                  setErrormsg2(false);
                  setLoading(true);
                  const result: any = await pVerifyEligibilityInquiry(data);
                  if (result?.data?.status === 'Active') {
                    setError('');

                    updateChangeOnClick(values);
                  } else {
                    setCount(count + 1);
                    setLoading(false);
                    scrollTOTop();
                    setError(
                      result?.data?.errorDescription ??
                        'Something wents wrong please try again'
                    );
                    try {
                      document
                        ?.getElementsByClassName(
                          'react-responsive-modal-overlay'
                        )?.[0]
                        ?.scroll(0, 0);
                    } catch (error) {
                      console.log(error);
                    }
                  }
                } else {
                  if (hasInsurance != undefined) {
                    setErrormsg2(true);
                  }
                }
              } else {
                updateFormValues({
                  ...values,
                  insurance: {
                    group: values.insurance?.group,
                    subscriberFirstName:
                      insuranceFor !== "i'm"
                        ? values.insurance.policyHolderFirstName
                        : values.insurance?.subscriberFirstName,
                    subscriberLastName:
                      insuranceFor !== "i'm"
                        ? values.insurance.policyHolderLastName
                        : values.insurance?.subscriberLastName,
                    insuranceId: values.insurance?.insuranceId,
                    payerList: values.insurance.payerList?.label || null,
                    newCarrierName: values.insurance?.newCarrierName,
                    frontCard: fileFrontURL,
                    backCard: fileBackURL,
                    policyHolderFirstName: values.insurance
                      .policyHolderFirstName
                      ? values.insurance.policyHolderFirstName
                      : '',
                    policyHolderLastName: values.insurance.policyHolderLastName
                      ? values.insurance.policyHolderLastName
                      : '',
                    policyHolderbirthDate:
                      values.insurance.policyHolderbirthDate &&
                      format(
                        values.insurance.policyHolderbirthDate,
                        config.dateFormat
                      ),
                    subscriberbirthDate:
                      values.insurance.subscriberbirthDate &&
                      format(
                        new Date(values.insurance.subscriberbirthDate),
                        config.dateFormat
                      ),
                    pVerify: false,
                  },
                });
                if (values) {
                  setErrormsg2(false);
                  setError('');
                  if (fileFrontURL && fileBackURL) {
                    setInfo(false);
                    setLoading(true);
                    updateChangeOnClick(values);
                  } else {
                    setInfo(true);
                    try {
                      document
                        ?.getElementsByClassName(
                          'react-responsive-modal-overlay'
                        )?.[0]
                        ?.scroll(0, 0);
                    } catch (error) {
                      console.log(error);
                    }
                  }
                } else {
                  if (hasInsurance != undefined) {
                    setErrormsg2(true);
                  }
                }
                
              }
              // }else{
              //   setInfo(true)
              //   scrollTOTop()
              // }
            }}
          >
            {(values) => {
              let date = new Date(
                values.values.insurance.policyHolderbirthDate
              );
              let date1 = new Date(values.values.insurance.subscriberbirthDate);
              let policyHolderbirthDate = date
                .toLocaleDateString()
                .slice(0, 10);
              let subscriberbirthDate = date1.toLocaleDateString().slice(0, 10);
              return (
                <>
                  {error && (
                    <Alert severity="warning">
                      The insurance information that you provided was not able
                      to be verified. Please double check the information on
                      your card.{' '}
                    </Alert>
                  )}
                  {info && (
                    <Alert severity="warning">
                      Please add front and back Card of Insurance.
                    </Alert>
                  )}
                  <Form>
                    <FormLabel label="Insurance Carrier Name">
                      <Select<string>
                        name="insurance.payerList"
                        options={payerType}
                        onChange={(value) => {
                          updateFormValues({ payerList: value });
                          // console.log('upd payer', value);
                          //console.log('upd payer',  payerType.filter((e: any) => e.value === value)?.[0]?.label ?? '');
                        }}
                        validate={(value) => {
                          if (!value) {
                            return 'Required field';
                          }
                        }}
                      />
                    </FormLabel>
                    {values?.values?.insurance?.payerList?.label == 'Other' ? (
                      <FormLabel label="New Insurance Carrier Name">
                        <span></span>
                        <Input
                          name="insurance.newCarrierName"
                          value={values.values.insurance.newCarrierName}
                          isRequired
                          onChange={(value) => {
                            console.log(value);
                            updateFormValues({
                              newCarrierName: value ? value.trim() : '',
                            });
                          }}
                          validate={(value) => {
                            if (!value || !value.trim().length) {
                              return 'Required field';
                            }
                            if (!validateComma.test(value)) {
                              return 'Commas Not Allowed';
                            }
                          }}
                        />
                      </FormLabel>
                    ) : (
                      ''
                    )}
                    <FormLabel label="Insurance Member ID">
                      <Input
                        name="insurance.insuranceId"
                        value={values.values.insurance.insuranceId}
                        isRequired
                        onChange={(value) => {
                          updateFormValues({
                            insuranceId: value ? value.trim() : '',
                          });
                        }}
                        validate={(value) => {
                          if (!value || !value.trim().length) {
                            return 'Required field';
                          }
                          if (!validateComma.test(value)) {
                            return 'Commas Not Allowed';
                          }
                        }}
                      />
                    </FormLabel>
                    <MobileResponsiveGap />
                    <FormLabel label="Insurance Group">
                      <Input
                        name="insurance.group"
                        value={values.values.insurance.group}
                        //   isRequired
                        onChange={(value) => {
                          updateFormValues({
                            group: value ? value.trim() : '',
                          });
                        }}
                        validate={(value) => {
                          // if (!value || !value.trim().length) {
                          //   return 'Required field';
                          // }
                          if (!validateComma.test(value)) {
                            return 'Commas Not Allowed';
                          }
                        }}
                      />
                    </FormLabel>
                    <MobileResponsiveGap />
                    {insuranceFor !== "i'm" && (
                      <>
                        <InputRow>
                          <FormLabel label="Policy Holder First Name">
                            <Input
                              name="insurance.policyHolderFirstName"
                              value={
                                values.values.insurance.policyHolderFirstName
                              }
                              onChange={(value) => {
                                updateFormValues({
                                  policyHolderFirstName: value
                                    ? value.trim()
                                    : '',
                                });
                              }}
                              isRequired
                              validate={(value) => {
                                if (!value || !value.trim().length) {
                                  return 'Required field';
                                } else if (value.length > config.nameMaxLimit) {
                                  return 'Name length must below 50 characters';
                                }
                              }}
                            />
                          </FormLabel>
                          <FormLabel label="Policy Holder Last Name">
                            <Input
                              name="insurance.policyHolderLastName"
                              value={
                                values.values.insurance.policyHolderLastName
                              }
                              onChange={(value) => {
                                updateFormValues({
                                  policyHolderLastName: value
                                    ? value.trim()
                                    : '',
                                });
                              }}
                              isRequired
                              validate={(value) => {
                                if (!value || !value.trim().length) {
                                  return 'Required field';
                                } else if (value.length > config.nameMaxLimit) {
                                  return 'Name length must below 50 characters';
                                }
                              }}
                            />
                          </FormLabel>
                        </InputRow>
                        <FormLabel label="Policy Holder Date of Birth">
                          <DatepickerField
                            name="insurance.policyHolderbirthDate"
                            isRequired
                            value={policyHolderbirthDate}
                            maxDate={new Date()}
                            inputPlaceholder="MM/DD/YYYY"
                            validate={(value) => {
                              let error;

                              if (differenceInYears(new Date(), value) < 18) {
                                error = 'You must be 18 years old or above';
                              }

                              return error;
                            }}
                          />
                        </FormLabel>
                      </>
                    )}

                    {insuranceFor == "i'm" && (
                      <>
                        <InputRow>
                          <FormLabel label="Subscriber First Name">
                            <Input
                              name="insurance.subscriberFirstName"
                              value={
                                values.values.insurance.subscriberFirstName
                              }
                              onChange={(value) => {
                                updateFormValues({
                                  subscriberFirstName: value
                                    ? value.trim()
                                    : '',
                                });
                              }}
                              isRequired
                              validate={(value) => {
                                if (!value || !value.trim().length) {
                                  return 'Required field';
                                } else if (value.length > config.nameMaxLimit) {
                                  return 'Name length must below 50 characters';
                                }
                              }}
                            />
                          </FormLabel>
                          <FormLabel label="Subscriber Last Name">
                            <Input
                              name="insurance.subscriberLastName"
                              value={values.values.insurance.subscriberLastName}
                              onChange={(value) => {
                                updateFormValues({
                                  subscriberLastName: value ? value.trim() : '',
                                });
                              }}
                              isRequired
                              validate={(value) => {
                                if (!value || !value.trim().length) {
                                  return 'Required field';
                                } else if (value.length > config.nameMaxLimit) {
                                  return 'Name length must below 50 characters';
                                }
                              }}
                            />
                          </FormLabel>
                        </InputRow>
                        <FormLabel label="Subscriber Date of Birth">
                          <DatepickerField
                            name="insurance.subscriberbirthDate"
                            isRequired
                            value={subscriberbirthDate}
                            maxDate={new Date()}
                            inputPlaceholder="MM/DD/YYYY"
                            validate={(value) => {
                              let error;

                              if (differenceInYears(new Date(), value) < 18) {
                                error = 'You must be 18 years old or above';
                              }

                              return error;
                            }}
                          />
                        </FormLabel>
                      </>
                    )}

                    {/* birthDate */}

                    {/* {insuranceFor !== "i'm" &&
                      <FormLabel label="Policy Holder Date of Birth">
                      <DatepickerField
                        name="insurance.policyHolderbirthDate"
                        isRequired
                        value={policyHolderbirthDate}
                        inputPlaceholder="MM/DD/YYYY"
                        validate={(value) => {
                          let error;
    
                          if (differenceInYears(new Date(), value) < 18) {
                            error = 'You must be 18 years old or above';
                          }
    
                          return error;
                        }}
                      />
                    </FormLabel>
                      } */}

                    <SubNameandDob>
                      <Testtag>
                        <ExpandIcon>
                          {' '}
                          {/* <AiOutlinePicture size={25} /> */}
                          <input
                            type="file"
                            id="front-file"
                            accept="image/*"
                            name="frontCard"
                            onChange={handleChangeFront}
                            style={{ display: 'none' }}
                          />
                          <label
                            htmlFor="front-file"
                            style={{ cursor: 'pointer' }}
                          >
                            {/* <PencilLogo /> */}
                            <EditOutlined /> {/* Pencil   */}
                          </label>
                        </ExpandIcon>
                        <Expandtext>
                          {' '}
                          <EditTag>
                            <strong>Front copy of the card</strong>
                          </EditTag>
                          <Card>
                            <img
                              width="100"
                              height="100"
                              src={fileFrontURL}
                              className={
                                fileFrontURL == null || fileFrontURL == ''
                                  ? classes.hideImage
                                  : classes.showImage
                              }
                            />
                          </Card>
                        </Expandtext>
                      </Testtag>
                      <Testtag>
                        <ExpandIcon>
                          {' '}
                          {/* <AiOutlinePicture size={25} /> */}
                          <input
                            type="file"
                            id="back-file"
                            accept="image/*"
                            name="backCard"
                            onChange={handleChangeBack}
                            style={{ display: 'none' }}
                          />
                          <label
                            htmlFor="back-file"
                            style={{ cursor: 'pointer' }}
                          >
                            {/* <PencilLogo /> */}
                            <EditOutlined /> {/* Pencil   */}
                          </label>
                        </ExpandIcon>
                        <Expandtext>
                          {' '}
                          <EditTag>
                            <strong>Back copy of the card</strong>
                          </EditTag>
                          <Card>
                            <img
                              width="100"
                              height="100"
                              src={fileBackURL}
                              className={
                                fileBackURL == null || fileBackURL == ''
                                  ? classes.hideImage
                                  : classes.showImage
                              }
                            />
                          </Card>
                        </Expandtext>
                      </Testtag>
                    </SubNameandDob>

                    {errormsg2 && (
                      <ErrorText hasError>
                        {'Please Enter required details'}
                      </ErrorText>
                    )}

                    <BtnContainer>
                      <Button
                        libraryType="default"
                        type="button"
                        className="btn elevated-btn"
                        onClick={() => {
                          updateFormValues({
                            insurance: {
                              insuranceId: '',
                              group: '',
                              subscriberFirstName: '',
                              subscriberLastName: '',
                              payerList: '',
                              insuranceFor: '',
                              policyHolderFirstName: '',
                              policyHolderLastName: '',
                              policyHolderbirthDate: '',
                            },
                          });
                          close();
                        }}
                      >
                        Return
                      </Button>
                      <Space />

                      <Button
                        libraryType="primary"
                        type="submit"
                        className="btn fill-btn"
                        disabled={loading}
                      >
                        {loading ? 'Processing...' : 'Confirm'}{' '}
                      </Button>
                    </BtnContainer>
                  </Form>
                </>
              );
            }}
          </Formik>
        </Container>
      </Content>
    </Modal>
  );
};

export default ChangeInsuranceModal;
