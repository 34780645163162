import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import styled from 'styled-components';
import { differenceInYears, format, parse } from 'date-fns';

import {
  Container,
  Content,
  PageHeader,
  PageTitle,
} from '../../../shared/components/styled';
import {
  useRegisterStepsDispatch,
  useStepsState,
  newMinor,
} from '../../provider';
import { Breakpoints } from '../../../../dictionaries';
import { config } from '../../../../config';

import NavigationButtons from '../../../shared/components/NavigationButtons';
import FormLabel from '../../../shared/components/form/FormLabel';
import Input from '../../../shared/components/form/Input';
import { DatepickerField } from '../../../shared/components/Datepicker';
import Checkbox from '../../../shared/components/form/Checkbox';
import { country } from '../../../schedule/components/PersonalInformation/PassportCountry';
import Select from '../../../shared/components/form/Select';
import Minors from './Minors';
import { useSharedState } from '../../../shared/provider';
import { values } from 'lodash';

const InputRow = styled.div`
  display: flex;
  flex-direction: column;
  margin: -8px 0;

  @media (min-width: ${Breakpoints.sm}px) {
    flex-direction: row;
    margin: 0 -8px;
  }

  @media (min-width: ${Breakpoints.lg}px) {
    margin: 0 -15px;
  }

  & > * {
    flex-basis: 33%;
    margin: 8px 0;

    @media (min-width: ${Breakpoints.sm}px) {
      margin: 0 8px;
    }

    @media (min-width: ${Breakpoints.lg}px) {
      margin: 0 15px;
    }
  }
`;
const PassportRow = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;

  & > *:first-child {
    width: 32%;
  }

  & > *:last-child {
    width: 32%;
  }
`;

const Error = styled.div`
  position: absolute;
  left: 6px;
  top: 58px;
`;

const NewPersonalInformation: React.FC = () => {
  const {
    form: {
      hasMinors,
      minors,
      employeeId,
      firstName,
      lastName,
      birthDate,
      middleName,
      isINTNameFormat,
    },
  } = useStepsState();
  const { updateFormValues, goToNextStep, goToPrevStep } = useRegisterStepsDispatch();
  let { travellType, tenant } = useSharedState();
  const [disable, setDisable] = useState(false);

  return (
    <Container size="xl">
      <Content>
        <PageHeader>
          <PageTitle>What is your name and date of birth?</PageTitle>
        </PageHeader>
        <Formik
          initialValues={{
            minors: minors.map((minor: any) => ({
              ...minor,
              birthDate:
                minor.birthDate &&
                parse(minor.birthDate, config.dateFormat, new Date()),
            })),
            employeeId:employeeId,
            firstName: firstName,
            lastName: lastName,
            middleName: middleName,
            birthDate:
              birthDate && parse(birthDate, config.dateFormat, new Date()),
            hasMinors,
            isINTNameFormat: travellType === 'INT' ? false : isINTNameFormat,
          }}
          onSubmit={(values: any) => {
            updateFormValues({
              ...values,
              middleName: isINTNameFormat ? '' : values.middleName,
              birthDate:
                values.birthDate && format(values.birthDate, config.dateFormat),
              minors: values.minors.map((minor: any) => ({
                ...minor,
                birthDate:
                  minor.birthDate && format(minor.birthDate, config.dateFormat),
              })),
            });
            goToNextStep();
            // }
          }}
        >
          {({ setFieldValue, errors, values }) => {
            return (
              <Form>
                {travellType !== 'INT' && (
                  <Checkbox
                    name="isINTNameFormat"
                    onChange={(checked) => {
                      updateFormValues({ isINTNameFormat: checked });
                      setDisable(true);
                    }}
                    style={{ accentColor: '#243D4D' }}
                  >
                    Select here if you do not have middle name.
                  </Checkbox>
                )}
                <InputRow>
                  {tenant === 'kern' ? (
                    <FormLabel label="Employee ID">
                      <Input
                        value={values.employeeId}
                        name="employeeId"
                        onChange={(value) =>
                          updateFormValues({
                            employeeId: value ? value.trim() : '',
                          })
                        }
                      />
                    </FormLabel>
                  ) : (
                    ''
                  )}
                  <FormLabel label="First Name">
                    <Input
                      value={values.firstName}
                      name="firstName"
                      isRequired
                      validate={(value) => {
                        let error;

                        if (
                          value != null &&
                          value != 'undefined' &&
                          value.trim() === ''
                        ) {
                          error = 'Required field';
                        }

                        return error;
                      }}
                      onChange={(value) =>
                        updateFormValues({
                          firstName: value ? value.trim() : '',
                        })
                      }
                    />
                  </FormLabel>
                  {(travellType === 'INT' || !isINTNameFormat) && (
                    <FormLabel label="Middle Name">
                      <Input
                        value={values.middleName}
                        name="middleName"
                        isRequired
                        validate={(value) => {
                          let error;

                          if (
                            value != null &&
                            value != 'undefined' &&
                            value.trim() === ''
                          ) {
                            error = 'Required field';
                          }

                          return error;
                        }}
                        onChange={(value) =>
                          updateFormValues({
                            middleName: value ? value.trim() : '',
                          })
                        }
                      />
                    </FormLabel>
                  )}
                  <FormLabel label="Last Name">
                    <Input
                      value={values.lastName}
                      name="lastName"
                      isRequired
                      validate={(value) => {
                        let error;

                        if (
                          value != null &&
                          value != 'undefined' &&
                          value.trim() === ''
                        ) {
                          error = 'Required field';
                        }

                        return error;
                      }}
                      onChange={(value) =>
                        updateFormValues({
                          lastName: value ? value.trim() : '',
                        })
                      }
                    />
                  </FormLabel>
                  <FormLabel label="Date of Birth">
                    <DatepickerField
                      name="birthDate"
                      isRequired
                      maxDate={new Date()}
                      inputPlaceholder="MM/DD/YYYY"
                      validate={(value) => {
                        let error;

                        if (differenceInYears(new Date(), value) < 18) {
                          error = 'You must be 18 years old or above';
                        }

                        return error;
                      }}
                    />
                  </FormLabel>
                </InputRow>
                {tenant?.toLowerCase() !== 'kern' && (
                  <Checkbox
                    name="hasMinors"
                    onChange={(checked) => {
                      const newMinors = checked ? [newMinor] : [];
                      setFieldValue('minors', newMinors);
                      updateFormValues({
                        minors: newMinors,
                        hasMinors: checked,
                      });
                      setDisable(true);
                      // setHaveMinors(checked);
                    }}
                  >
                    I am registering for minors.
                  </Checkbox>
                )}
                {hasMinors && (
                  <Minors
                    isRegister={true}
                    isProfile={false}
                    setAddMinor={(p: boolean) => {
                      return;
                    }}
                    schoolDropDown={[{ label: '', value: '' }]}
                  />
                )}
                <NavigationButtons
                  onReturnButtonClick={() => {
                    goToPrevStep();
                  }}
                  isValid={true}
                />
              </Form>
            );
          }}
        </Formik>
      </Content>
    </Container>
  );
};

export default NewPersonalInformation;

//ltst