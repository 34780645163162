import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, FieldProps } from 'formik';
import { BiPlus } from 'react-icons/bi';

import {
  Container,
  Content,
  PageHeader,
  SubHeaderDescription,
  ErrorText,
} from '../../shared/components/styled';
// import { useStepsDispatch } from '../provider';
import { useStepsState, useStepsDispatch } from '../../schedule/provider';
import NavigationButtons from '../../shared/components/NavigationButtons';
import { colors } from '../../../styles/colors';
import { Breakpoints } from '../../../dictionaries';
import styled from 'styled-components';
import PaymentModal from '../../schedule/components/ConfirmAndPay/PaymentModal';
import FormLabel from '../../shared/components/form/FormLabel';
import Input from '../../shared/components/form/Input';
import NoInsuranceModal from './NoInsuranceModal';
import Checkbox from '../../shared/components/form/Checkbox';

import {
  createPaymentIntent,
  createAppointment,
  releaseSlot,
  reserveSlot,
} from '../../schedule/api';
import ZipCodeInput from './Address/ZipCodeInput';
import Select from '../../shared/components/form/Select';
import { states } from './Address/states';
import { useSharedState, useSharedDispatch } from '../../shared/provider';
import { updateRegister } from '../api';
import { country } from '../../schedule/components/PersonalInformation/PassportCountry';
import DrivingLicenseModel from './DrivingLicenseModel';
import { config } from '../../../config';
import GoogleAnalytics from '../../../GoogleAnalytics';

export const PageTitle = styled.h2`
  color: ${colors.fullBlack};
  font-size: 28px;
  font-weight: 700;
  margin: 0;
  text-align: left;

  @media (max-width: ${Breakpoints.sm}px) {
    font-size: 25px;
    margin-top: 30%;
  }
`;
const Space = styled.div`
  height: 10px;
  width: 20px;
`;

interface ButtonProps {
  selected: boolean;
}

const BtnContainer = styled.div`
  position: relative;
  padding-bottom: 10px;
`;

const Error = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
`;

const Button = styled.button`
  border: 1px solid ${colors.blueLight};
  background-color: ${({ selected }: ButtonProps) =>
    selected ? colors.blueLight : colors.white};
  color: ${({ selected }: ButtonProps) =>
    selected ? colors.white : colors.blueLight};
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  display: block;
  width: 100%;
  padding: 10px 20px;
  margin-bottom: 15px;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
`;
const Info = styled.div`
  background: ${colors.blue8};
  padding: 20px 30px;
  margin-bottom: 20px;

  @media (min-width: ${Breakpoints.md}px) {
    padding: 30px 40px;
  }
`;

const InfoTitle = styled.h3`
  color: rgba(38, 38, 38, 0.8);
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  margin: 0 0 20px 0;
  color: ${colors.blueLight};
`;

const InfoContent = styled.p`
  margin: 0;
`;
const CityStateRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > *:first-child {
    width: 48%;
  }

  & > *:last-child {
    width: 48%;
  }
  @media (max-width: ${Breakpoints.sm}px) {
    & > *:last-child {
      margin-top: 35px;
    }
  }
`;
const PassportRow = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;

  & > *:first-child {
    width: 50%;
  }

  & > *:last-child {
    width: 50%;
  }
  @media (max-width: ${Breakpoints.sm}px) {
    gap: 5px;
    flex-direction: column;

    & > *:first-child {
      width: 100%;
    }

    & > *:last-child {
      width: 100%;
    }
  }
`;

const WebImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 10px;

  @media (max-width: ${Breakpoints.sm}px) {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
  }
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const Img = styled.img`
  width: 130px;
  height: 130px;
  @media (max-width: ${Breakpoints.sm}px) {
    width: 130px;
    height: 130px;
  }
`;
const SubTitle = styled.h2`
  color: ${colors.fullBlack};
  text-align: left;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
`;
const Gap = styled.div`
  margin-top: 0px;
  @media (max-width: ${Breakpoints.sm}px) {
    margin-top: 35px;
  }
`;

export const StyledButton = styled.button`
  border: 1px dashed ${colors.grey};
  border-radius: 10px;
  font-size: 16px;
  font-weight: 500;
  color: rgb(26 150 219);
  line-height: 24px;
  padding: 15px 28px;
  transition: all 0.15s ease-in-out;
  width: 100%;
  cursor: pointer;

  &:focus,
  &:active {
    border: 1px dotted ${colors.blueLight};
    box-shadow: 0 0 4px ${(colors.blueLight, 0.8)};
  }
  @media (max-width: ${Breakpoints.sm}px) {
    margin-top: 40px;
  }
`;

const ButtonText = styled.div`
  display: inline-flex;
  align-items: center;
  position: relative;
  text-align: center;

  @media (max-width: ${Breakpoints.sm}px) {
    margin-left: 0px;
  }
`;

const   PaymentType: React.FC = () => {
  const { form } = useStepsState();
    useEffect(()=>{
      GoogleAnalytics(form,'/PaymentType');
  },[])
  const {
    payingMethod,
    socialSecurityNumber,
    hasInsurance,
    address,
    passportCountry,
    passportNo,
    licenseBackCard,
    licenseFrontCard,
    location
  } = form;
  const {
    updateFormValues,
    goToNextStep,
    goToSkipStep,
    goToPrevStep,
    goToSkip2Step,
  } = useStepsDispatch();
  let { travellType, destination } = useSharedState();
  const [errormsg, setErrormsg] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showNoInsuranceModal, setShowNoInsuranceModal] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isSlotReleased, slotReleased] = useState<boolean>(false);
  const [disabled, setDisabled] = useState(false);
  const { userData } = useSharedState();
  const { setloggedUserData } = useSharedDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  const [time, setTime] = useState(false);
  const [ssn, setSsn] = useState(false);
  const [dl, setDl] = useState(false);
  const [add, setAdd] = useState(false);
  const [ins, setIns] = useState(false);
  const [check, setCheck] = useState('');
  const [front, setfront] = useState('');
  const [back, setback] = useState('');

  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const handleModalOpen = () => {
    setOpenModal(true);
  };
  const handleModalClose = () => {
    setOpenModal(false);
  };

  React.useEffect(() => {
    if (payingMethod==='No Payment' && location?.cPartnerID==='KEN001') {
      
      updateFormValues({ cPartnerID: 'KEN001' })
      goToSkip2Step()

    }
  }, []);

  React.useEffect(() => {
    if (form.licenseFrontCard && form.licenseBackCard) {
      setfront(form.licenseFrontCard);
      setback(form.licenseBackCard);

      setTime(false);
    }
  }, [form]);
  const [insurance, setInsurance] = useState(false);

  interface Props {
    name: string;
    onChange?: (answer: string) => void;
    isRequired?: boolean;
    additionalContent?: React.ReactNode;
  }

  const ButtonGroup: React.FC<Props> = ({
    name,
    onChange,
    isRequired,
    additionalContent,
  }) => {
    return (
      <Field
        name={name}
        validate={(value: boolean | null) => {
          let error;

          if (isRequired && value === null) {
            error = 'Please choose one of the answers';
          }

          return error;
        }}
      >
        {({
          field: { value },
          form: { setFieldValue },
          meta: { touched, error },
        }: FieldProps) => (
          <BtnContainer>
            <Button
              type="button"
              selected={value === 'Out of pocket'}
              onClick={() => {
                setFieldValue(name, 'Out of pocket');
                setIns(false);
                if (onChange) {
                  onChange('Out of pocket');
                }
              }}
            >
              Out of pocket
            </Button>
            
            <Button
              type="button"
              selected={value === 'No Insurance'}
              onClick={() => {
                setFieldValue(name, 'No Insurance');
                setIns(true);
                if (onChange) {
                  onChange('No Insurance');
                }
              }}
            >
              I don't have Insurance
            </Button>
          </BtnContainer>
        )}
      </Field>
    );
  };

  ButtonGroup.defaultProps = {
    isRequired: true,
  };

  var onShowAlert = () => {
    if (!time) {
      setVisible(true);
      window.setTimeout(() => {
        setVisible(false);
        goToSkipStep();
      }, 5000);
    }
  };
  const updateAddressValue = (newValue: any) => {
    updateFormValues({
      address: {
        ...address,
        ...newValue,
      },
    });
  };
  const updateChangeOnClick = async (values: any) => {
    setDisabled(true);
    let resultdata = await updateRegister({
      email: userData?.email,
      address: {
        ...values.address,
        state: values.address.state?.value,
      },
      passportNo: values.passportNo,
      passportCountry: values.passportCountry,
    });

    if (resultdata?.data?.status === 200) {
      setloggedUserData(resultdata?.data?.data);
      localStorage.setItem('user', JSON.stringify(userData));
      const {
        socialSecurityNumber,
        driverLicenseNumber,
        licenseFrontCard,
        licenseBackCard,
        hasInsurance,
        ...appointment
      } = form;
      const result = await createAppointment({
        ...appointment,
        firstName: (form?.registeringFor === "minorOnly" && !!form?.guardian?.firstName) ? form?.guardian?.firstName : form?.firstName,
        lastName: (form?.registeringFor === "minorOnly" && !!form?.guardian?.lastName)  ? form?.guardian?.lastName : form?.lastName,              
        guardian: (form?.registeringFor === "minorOnly" && !!form?.guardian) ? form?.guardian : null,
        socialSecurityNumber,
        licenseFrontCard,
        licenseBackCard,
        hasInsurance,
        insurance: null,
        paymentIntentId: 'noins',
        travelType: travellType,
        destination: destination,
        isNotHavePermanentAddress: !!userData?.isNotHavePermanentAddress ? userData?.isNotHavePermanentAddress : form.isNotHavePermanentAddress
      });

      await new Promise<void>((resolve) => {
        setTimeout(() => {
          resolve();
        }, 500);
      });

      // if (isSlotReleased) {
      //   //reserve slot
      //   const updateslot: any = appointment.slot;
      //   let numberOfGuests: number = form.minors?.length + 1;
      //   reserveSlot(updateslot, null, numberOfGuests);
      // }

      updateFormValues({
        confirmationId: result.data.id,
        licenseFrontCard: front,
        licenseBackCard: back,
      });
      if (!dl && front !== 'null' && back !== 'null') {
        onShowAlert();
      } else {
        setErrormsg(true);
      }
      if (ssn && dl) {
        onShowAlert();
      } else {
        setErrormsg(true);
      }
      if (!ssn && dl) {
        onShowAlert();
      } else {
        setErrormsg(true);
      }
    }

    setDisabled(false);
  };

  return (
    <Container>
      <Content>
        <PageHeader>
          <PageTitle>How are you paying for this?</PageTitle>
        </PageHeader>
        <Container noPadding marginTop>
          <Formik
            initialValues={{
              payingMethod,
              socialSecurityNumber,
              licenseFrontCard,
              licenseBackCard,
              hasInsurance,
              address: {
                ...userData?.address,
                state: states.find(
                  (state) => state.value === userData?.address.state
                ),
              },
              passportNo: userData?.passportNo,
              passportCountry: userData?.passportCountry,
            }}
            onSubmit={async (values) => {
              updateFormValues({
                ...values,
                payingMethod: values?.payingMethod,
                socialSecurityNumber: values?.socialSecurityNumber,
                hasInsurance: payingMethod === 'Insurance' ? true : false,
                address: {
                  ...values.address,
                  state: values.address.state?.value || null,
                },
              });
              if (payingMethod === 'Out of pocket') {
                updateFormValues({ payingMethod: 'Credit card'});
                setErrormsg(false);
                // setShowPaymentModal(true);
                goToSkip2Step();
              } else if (payingMethod === 'No Insurance') {
                updateFormValues({ payingMethod: 'No Insurance'});
                console.log('inside values');
                goToNextStep();
              
              } else {
                setErrormsg(true);
              }
              console.log('paying method', values);
            }}
          >
            {(handleSubmit) => (
              <Form>
                <ButtonGroup
                  name="payingMethod"
                  onChange={(answer) => {
                    updateFormValues({
                      payingMethod: answer,
                    });
                    setErrormsg(false);
                    if (answer === 'Out of pocket') goToSkip2Step();
                     else if (answer === 'No Insurance') goToNextStep();
                  }}
                  isRequired
                />
               

                {errormsg && (
                  <ErrorText hasError>
                    {'Please choose one of the answers'}
                  </ErrorText>
                )}

                <>
                  <NavigationButtons
                    confirmBtnText="Confirm"
                    onReturnButtonClick={() => {
                      goToPrevStep();
                    }}
                    loading={disabled}
                  />
                  <PaymentModal
                    showModal={showPaymentModal}
                    closeModal={() => setShowPaymentModal(false)}
                  />

                  <NoInsuranceModal
                    open={visible}
                    handleClose={() => setShowNoInsuranceModal(false)}
                  />
                </>
              </Form>
            )}
          </Formik>
        </Container>
      </Content>
    </Container>
  );
};

export default PaymentType;
