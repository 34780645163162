import React from 'react';
import styled from 'styled-components';

import { ConfirmationNumber } from './Confirmation';
import { Form } from '../../../shared/models';
import { Period } from '../../models';
import { useSharedState } from '../../../shared/provider';

const Info = styled.div`
  margin-top: 20px;
`;

const InfoBox = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 20px;

  & > * {
    &:first-child {
      font-weight: bold;
      width: 25%;
    }
  }
`;

interface Props {
  form: Form;
  periods: Period[];
}

const PrintableConfirmation = React.forwardRef<HTMLDivElement, Props>(
  ({ form, periods }, ref) => {
    const { userData } = useSharedState();
    return (
      <ConfirmationNumber ref={ref}>
        {form?.confirmationId
                ? 'Confirmation #'
                : ' Acknowledgement # '}
        <strong>{form?.confirmationId ? form.confirmationId : form.acknowledgementId }</strong>
        <Info className="print">
          <InfoBox>
            <div>Location</div>
            <div>
              {form.location?.name}
              <br />
              {form.location?.address1}
              <br />
              {form.location?.address2}
              <br />
              {form.location?.room}
            </div>
          </InfoBox>
          {/* {form.slot?.period && ( */}
          <InfoBox>
            <div>Appointment Date and Time</div>
            <div>
              {form.date} 
              <br /> 
              {
                form.slot?.label ? form.slot.label : form.slot?.period !== undefined
                ? 
                    periods[form.slot?.period]?.label
                : 
                    ''
              }
            </div>
          </InfoBox>
          {/* // )} */}
          <InfoBox>
            <div>Customer name</div>
            <div>
              {form?.firstName ? form?.firstName : userData?.firstName}{' '}
              {form?.middleName ? form?.middleName : userData?.middleName}{' '}
              {form?.lastName ? form?.lastName : userData?.lastName}
            </div>
          </InfoBox>
          <InfoBox>
            {/* Please remember to bring your ID and your confirmation number to
            your appointment. */}
            Please bring your ID and your confirmation number from email to your
            appointment.
          </InfoBox>
        </Info>
      </ConfirmationNumber>
    );
  }
);

export default PrintableConfirmation;
