import React, { useState } from 'react';
import styled from 'styled-components';
import { Breakpoints } from '../../../../dictionaries';
import { Form, Formik } from 'formik';
import { useSharedDispatch, useSharedState } from '../../../shared/provider';
import { useStepsState, useRegisterStepsDispatch } from '../../provider';
import { useStepsDispatch } from '../../../schedule/provider';
import Modal from '../../../shared/components/Modal';
import { Button, ErrorText } from '../../../shared/components/styled';
import { colors } from '../../../../styles/colors';
import WebCam from '../../../shared/components/WebCam';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
// import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { AiOutlineInsurance } from 'react-icons/ai';
import { BiCamera } from 'react-icons/bi';
import { FiUpload } from 'react-icons/fi';
import { FaPlusCircle } from 'react-icons/fa';
import { AiOutlineClose } from 'react-icons/ai';
import { firebase } from './../../../../firebase';
import Loader from '../../../shared/components/Loader';
import { createInsurance, getUserInsurance } from '../../api';
import moment from 'moment';

const Content = styled.div`
  width: 100%;

  @media (min-width: ${Breakpoints.md}px) {
    width: 540px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  flex-wrap: nowrap;
  padding-top: 20px;

  @media (min-width: 450px) {
    flex-direction: row;
  }
`;

const Title = styled.h2`
  color: ${colors.fullBlack};
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  margin-top: 40px;
  padding-top: 40px;
  @media (min-width: 450px) {
    font-size: 22px;
  }
`;
const Container = styled.div``;

const Heading = styled.h2`
  color: ${colors.fullBlack};
  font-size: 20px;
  font-weight: bold;
  @media (min-width: 450px) {
    font-size: 18px;
  }
`;
const SubHeading = styled.h3`
  margin-top: 15px;
  color: ${colors.fullBlack};
  font-size: 18px;
  @media (min-width: 450px) {
    font-size: 14px;
  }
`;
const SubContent = styled.div`
  margin-top: 15px;
  font-size: 16px;
`;
const StepContent = styled.div`
  text-align: center;
`;
const WrapContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 140px;
  margin-top: 10px;
  margin-bottom: 10px;
  @media (max-width: ${Breakpoints.sm}px) {
    padding: 10px 15px;
  }
`;
const WrapContainerUpload = styled.div`
  display: block;
  padding: 30px 0;
  margin: 20px 0;
  @media (max-width: ${Breakpoints.sm}px) {
  }
`;
const InsuranceCard = styled.div`
  background: ${colors.white};
  width: 456px;
  height: 220px;
  border-radius: 25px;
  padding: 20px;
  position: relative;

  @media (max-width: ${Breakpoints.sm}px) {
    padding: 5px 30px;
    width: fit-content;
    height: fit-content;
  }
`;
const InsuranceCardUpload = styled.div`
  background: ${colors.white};
  width: 450px;
  height: 220px;
  border-radius: 25px;
  padding: 0;
  border: 1px solid rgb(42, 95, 135);
  margin: 0px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: ${Breakpoints.sm}px) {
    width: 100%;
  }
`;
const InsuranceCardName = styled.h2`
  font-size: 16px;
  font-weight: 500;
  display: inline-flex;
  position: relative;
  gap: 20px;
  @media (max-width: ${Breakpoints.sm}px) {
    font-size: 14px;
    gap: 5px;
  }
`;
const InsuranceCardRow1 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  font-size: 16px;
  @media (max-width: ${Breakpoints.sm}px) {
    font-size: 14px;
  }
`;
const InsuranceCardRow2 = styled.div`
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 16px;
  @media (max-width: ${Breakpoints.sm}px) {
    font-size: 14px;
  }
`;
const InsuranceCardRow3 = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 67px;
  @media (max-width: ${Breakpoints.sm}px) {
    margin-top: 80px;
  }
`;
const InsuranceCardSubName = styled.div`
  text-align: left;
  font-size: 16px;
  @media (max-width: ${Breakpoints.sm}px) {
    font-size: 14px;
  }
`;
const InsuranceCardSubGroup = styled.div`
  font-size: 16px;
  @media (max-width: ${Breakpoints.sm}px) {
    font-size: 14px;
  }
`;
export const UploadOptionPhone = styled.div`
  border: 1px solid;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 500;
  color: #ffff;
  line-height: 24px;
  padding: 10px 20px;
  align-items: center;
  justify-content: center;
  transition: all 0.15s ease-in-out;
  width: 60%;
  cursor: pointer;
  display: inline-flex;
  position: relative;
  gap: 20px;
  background-color: ${colors.fullBlack};
  &:focus,
  &:active {
    border: 1px solid;
    box-shadow: 0 0 4px ${(colors.darkBlue, 0.8)};
  }
  @media (max-width: ${Breakpoints.sm}px) {
    padding: 2px 5px;
    width: 100%;
  }
`;
export const UploadOptionComputer = styled.div`
  border: 1px solid ${colors.rebrandBlack};
  border-radius: 32px;
  font-size: 18px;
  font-weight: 500;
  color: ${colors.rebrandBlack};
  line-height: 24px;
  padding: 10px 20px;
  align-items: center;
  justify-content: center;
  transition: all 0.15s ease-in-out;
  width: 60%;
  cursor: pointer;
  display: inline-flex;
  position: relative;
  gap: 20px;
  background-color: ${colors.white};
  margin-top: 10px;
  &:focus,
  &:active {
    border: 1px solid rgb(42, 95, 135);
  }
  @media (max-width: ${Breakpoints.sm}px) {
    padding: 2px 5px;
    width: 100%;
  }
`;
const Space = styled.div`
  height: 10px;
  width: 20px;
`;
const OrTag = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  &: before {
    content: '';
    display: block;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    background-color: rgb(42, 95, 135);
    height: 2px;
    top: 50%;
    bottom: 0;
  }
`;
const StepTwoLineTag = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  margin: 20px 0px;
  &: before {
    content: '';
    display: block;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    background-color: rgb(42, 95, 135);
    height: 2px;
    top: 50%;
    bottom: 0;
  }
`;
const PlusIcon = styled.div`
  position: absolute;
  right: -20px;
  bottom: -10px;
  z-index: 9;
  cursor: pointer;
  @media (max-width: ${Breakpoints.sm}px) {
    background-color: ${colors.white};
    border-radius: 50%;
  }
`;
const CloseIcon = styled.div`
  position: absolute;
  right: 20px;
  z-index: 9;
  cursor: pointer;
  @media (max-width: ${Breakpoints.sm}px) {
  }
`;

const OrText = styled.h1`
  font-weight: 600;
  display: flex;
  position: relative;
  background-color: #fff;
  padding: 0 10px;
`;
export const UploadPhoto = styled.div`
  border: 1px solid;
  border-radius: 32px;
  font-size: 20px;
  font-weight: 500;
  color: #ffff;
  line-height: 24px;
  padding: 10px 20px;
  align-items: center;
  justify-content: center;
  transition: all 0.15s ease-in-out;
  width: auto;
  cursor: pointer;
  display: inline-flex;
  position: relative;
  background-color: ${colors.darkBlue};
  &:focus,
  &:active {
    border: 1px solid;
    box-shadow: 0 0 4px ${(colors.darkBlue, 0.8)};
  }

  @media (max-width: ${Breakpoints.sm}px) {
  }
`;
const UploadInstructionsHeading = styled.h3`
  margin-top: 20px;
  text-align: left;
  font-weight: 600;
  font-size: 16px;
`;
const ChoosePhoto = styled.h3`
  margin-top: 20px;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
`;
const UploadInstructions = styled.div`
  text-align: left;
  font-weight: 500;
  font-size: 16px;
`;
const HrLineTag = styled.div`
  display: grid;
`;
const HrLines = styled.hr`
  display: flex;
  background: ${colors.darkBlue};
  height: 2px;
  width: 250px;
  @media (max-width: ${Breakpoints.sm}px) {
    width: 150px;
  }
`;
export const ReturnButton = styled.button`
  border: 1px solid #2a5f87;
  font-size: 20px;
  line-height: 1.6;
  border-radius: 32px;
  padding: 9px 20px;
  font-weight: bold;
  width: 50%;
  margin-left: 25%;
  color: #2a5f87;
  background: #ffffff;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  margin-top: 10px;
  @media (max-width: ${Breakpoints.sm}px) {
  }
`;
const WebImage = styled.div`
  display: block;
  @media (max-width: ${Breakpoints.sm}px) {
    display: none;
  }
`;
const MobileImage = styled.div`
  display: none;
  @media (max-width: ${Breakpoints.sm}px) {
    display: block;
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      '& .MuiStepIcon-active': { color: `${colors.rebrandBlack}` },
    },
    button: {
      marginRight: theme.spacing(1),
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    completed: {
      display: 'inline-block',
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  })
);

type Props = {
  open: boolean;
  handleModalClose: () => void;
  showlist: () => void;
  data:
    | {
        insuranceId: string;
        group: string;
        subscriberFirstName: string;
        subscriberLastName: string;
        frontCard: string;
        backCard: string;
        payerList: string;
        newCarrierName: string;
        id: string;
        email: string;
        insuranceFor: string;
        policyHolderFirstName: string;
        policyHolderLastName: string;
        policyHolderbirthDate: string;
        subscriberbirthDate?: string;
        pVerify?: boolean | false;
      }
    | undefined;
  cPartnerID?: string;
};

const AddInsuranceModal: React.FC<Props> = ({
  handleModalClose,
  open,
  showlist,
  data,
  cPartnerID
}) => {
  const { updateFormValues } = useRegisterStepsDispatch();
  const { goToNextStep,goToSkip3Step, updatesFormValues } = useStepsDispatch();
  const { form } = useStepsState();
  const { insurance, hasInsurance } = form;
  const [loading, setLoading] = useState(false);
  const [isFrontCardUpload, setFrontCardUpload] = useState(false);
  const [isBackCardUpload, setBackCardUpload] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [fileFrontURL, setFileFrontURL] = useState('');
  const [fileBackURL, setFileBackURL] = useState('');
  const [errormsg, setErrormsg] = useState(false);
  const [isFrontCard, setFrontCard] = useState(false);
  const [isbackCard, setBackCard] = useState(false);
  const [isFrontImageShow, setFrontImageShow] = useState<boolean>(false);
  const [isBackImageShow, setBackImageShow] = useState<boolean>(false);
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState(new Set<number>());
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const steps = getSteps();
  const { updateInsuranceList } = useSharedDispatch();
  const { userData } = useSharedState();
  const [allow, setAllow] = useState(false);
  const [takePhoto, setTakePhoto] = useState<boolean>(false);
  const [webCam, setWebCam] = useState<boolean>(false);
  const [handleFrontBackmethod, setHandleFrontBackmethod] = useState<boolean>(true);
  const totalSteps = () => {
    return getSteps().length;
  };

  const skippedSteps = () => {
    return skipped.size;
  };

  const completedSteps = () => {
    return completed.size;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps() - skippedSteps();
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const handleNext = () => {
    if (activeStep === 1) {
      if (isFrontCard || isbackCard) {
        setFrontCard(false);
        setBackCard(false);
        setErrormsg(false);
      } else if (isFrontImageShow && isBackImageShow) {
        if (fileFrontURL.length && fileBackURL.length) {
          setAllow(true);
        }
      } else {
        setErrormsg(true);
      }
    } else {
      const newActiveStep =
        isLastStep() && !allStepsCompleted()
          ? steps.findIndex((step, i) => !completed.has(i))
          : activeStep + 1;
      setActiveStep(newActiveStep);
    }
  };

  const handleBack = () => {
    if (isFrontCard || isbackCard) {
      setFrontCard(false);
      setBackCard(false);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };



  function isStepComplete(step: number) {
    return completed.has(step);
  }
  function getSteps() {
    return ['', ''];
  }

  const setFrontImage = (url: string) => {
    setFileFrontURL(url);
    updateFormValues({ frontcard: url });
    setFrontCard(true);
    setFrontCardUpload(true);
    setFrontImageShow(true);
    setLoading(false);
    setImageError(false);
  };
  const handleChangeFront = async (e: any) => {
    try {
      setLoading(true);
      const file = e.target.files[0];
      const fileName: any = `F-insurance-${form.firstName}-${Date.now()}`;
      const storageref = firebase.storage().ref();
      const fileref = storageref.child(fileName.toString());
      await fileref.put(file).then(() => {
        console.log('filename', file.type);
      });
      if (
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/tiff-fx' ||
        file.type === 'image/tiff'
      ) {
        const url: string = await fileref.getDownloadURL();
        setFrontImage(url);
      } else {
        setImageError(true);
        setLoading(false);
      }
    } catch {
      setFrontCard(false);
    }
  };
  const setBackImage = (url: string) => {
    setFileBackURL(url);
    updateFormValues({ backCard: url });
    setBackCard(true);
    setBackCardUpload(true);
    setBackImageShow(true);
    setLoading(false);
    setImageError(false);
  };
  const handleChangeBack = async (e: any) => {
    try {
      setLoading(true);
      const file = e.target.files[0];
      const fileName: any = `B-insurance-${form.firstName}-${Date.now()}`;
      const storageref = firebase.storage().ref();
      const fileref = storageref.child(fileName.toString());
      await fileref.put(file).then(() => {});
      if (
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/tiff-fx' ||
        file.type === 'image/tiff'
      ) {
        const url: string = await fileref.getDownloadURL();
        setBackImage(url);
      } else {
        setImageError(true);
        setLoading(false);
      }
    } catch {
      setBackCard(false);
    }
  };

  const getInsurance = async () => {
    setLoading(true);
    try {
      let updatedData = await getUserInsurance(userData?.email);

      if (updatedData?.data?.status === 200) {
        updateInsuranceList(updatedData?.data?.data);
        handleModalClose();

        showlist();
      }
    } catch (e) {
      setLoading(false);
    }
  };

  const updateChangeOnClick = async (values: any) => {
    setLoading(true);
    let Data: any = {
      email: userData?.email ? userData?.email : '',
      insuranceId: !!data ? data.insuranceId : '',
      group: !!data ? data.group : '',
      subscriberFirstName: !!data ? data.subscriberFirstName : '',
      subscriberLastName: !!data ? data.subscriberLastName : '',
      frontCard: fileFrontURL,
      backCard: fileBackURL,
      payerList: !!data ? data.payerList : '',
      newCarrierName: !!data ? data.newCarrierName : '',
      insuranceFor: !!data ? data.insuranceFor : '',
      policyHolderFirstName: !!data ? data.policyHolderFirstName : '',
      policyHolderLastName: !!data ? data.policyHolderLastName : '',
      policyHolderbirthDate: !!data ? data.policyHolderbirthDate : '',
      subscriberbirthDate: !!data ? data?.subscriberbirthDate : '',
      pVerify: !!data ? data?.pVerify : false,
    };
    if (userData?.email) {
      let resultdata = await createInsurance({
        email: userData?.email ? userData?.email : '',
        insuranceId: !!data ? data.insuranceId : '',
        group: !!data ? data.group : '',
        subscriberFirstName: !!data ? data.subscriberFirstName : '',
        subscriberLastName: !!data ? data.subscriberLastName : '',
        frontCard: fileFrontURL,
        backCard: fileBackURL,
        payerList: !!data ? data.payerList : '',
        newCarrierName: !!data ? data.newCarrierName : '',
        insuranceFor: !!data ? data.insuranceFor : '',
        policyHolderFirstName: !!data ? data.policyHolderFirstName : '',
        policyHolderLastName: !!data ? data.policyHolderLastName : '',
        policyHolderbirthDate: !!data ? data.policyHolderbirthDate : '',
        createdDate: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        subscriberbirthDate: !!data ? data?.subscriberbirthDate : '',
        pVerify: !!data ? data?.pVerify : false,
      });

      if (resultdata?.data?.status === 200) {
        getInsurance();
        // handleModalClose()
        // history.push('/user/profile');
      }
      setLoading(false);
    } else {
      updateInsuranceList([Data]);
      handleModalClose();
      updatesFormValues({
        insurance: Data,
      });
      if(cPartnerID === 'LLT001'){
        goToSkip3Step()
      }
      goToNextStep();

      showlist();
      setLoading(false);
    }
  };

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return <Stepone />;
      case 1:
        if (isFrontCard) {
          return <SteptwoUploadFront />;
        } else if (isbackCard) {
          return <SteptwoUploadBack />;
        } else {
          return <Steptwo />;
        }
      default:
        return <Stepone />;
    }
  }
  const Stepone: React.FC = () => {
    return (
      <StepContent>
        <Heading>Choose How To Sumbit Photos</Heading>
        <SubHeading>
          In order to verify your insurance, please make sure:
        </SubHeading>
        <SubContent>
          1). Your insurance card is up to date <br />
          2). Take the photos on a well-lit flat surface
        </SubContent>
        <WrapContainer>
          <InsuranceCard>
            <InsuranceCardName>
              <AiOutlineInsurance size={40} style={{ visibility: 'hidden' }} />
              MY INSURANCE COMPANY NAME
            </InsuranceCardName>
            <InsuranceCardRow1>
              <InsuranceCardSubName>
                Subscriber Name <br />
                John Doe
              </InsuranceCardSubName>
              <InsuranceCardSubGroup>Group #: XXXXXX</InsuranceCardSubGroup>
            </InsuranceCardRow1>
            <InsuranceCardRow2>ID #: XXXXXXXXXX</InsuranceCardRow2>
          </InsuranceCard>
        </WrapContainer>
        <UploadOptionPhone
          style={{borderRadius:'1000px'}}
          onClick={() => {
            setTakePhoto(true);
            handleNext();
          }}
        >
          <BiCamera size={40} />
          Take A Photo With Your Phone
        </UploadOptionPhone >
        <SubContent>
          Your mobile phone must have a camera and a web browser
        </SubContent>
        <OrTag>
          <OrText>{'OR'}</OrText>
        </OrTag>
        <UploadOptionComputer
          className='btn outline-btn'
          style={{borderRadius:'1000px'}}
          onClick={() => {
            handleNext();
            setTakePhoto(false);
          }}
        >
          <FiUpload size={35} />
          Upload From Your Computer
        </UploadOptionComputer>
      </StepContent>
    );
  };
  const Steptwo: React.FC = () => {
    return (
      <StepContent>
        <Heading>{takePhoto?'Take a Photo From Your Camera':'Upload a Photo of Your Insurance Card'}</Heading>
        <WrapContainer>
          {!isFrontCardUpload ? (
            <>
              <InsuranceCard>
                <InsuranceCardName>
                  <AiOutlineInsurance
                    size={40}
                    style={{ visibility: 'hidden' }}
                  />
                  MY INSURANCE COMPANY NAME
                </InsuranceCardName>
                <InsuranceCardRow1>
                  <InsuranceCardSubName>
                    Subscriber Name: <br />
                    John Doe
                  </InsuranceCardSubName>
                  <InsuranceCardSubGroup>Group #: XXXXXX</InsuranceCardSubGroup>
                </InsuranceCardRow1>
                <InsuranceCardRow2>
                  ID #: XXXXXXXXXX
                  <PlusIcon
                    onClick={() => {
                      setFrontCard(true);
                      setErrormsg(false);
                    }}
                  >
                    <FaPlusCircle size={70} color={'rgb(74, 177, 117)'} />
                  </PlusIcon>
                </InsuranceCardRow2>
              </InsuranceCard>
              <SubHeading>
                {takePhoto?'Take a Front Photo of Your Insurance Card':'Upload a Photo of Your Insurance Card'}<br />
                <b>(FRONT)</b>
              </SubHeading>
            </>
          ) : (
            <>
              <InsuranceCard>
                <WebImage>
                  <img
                    style={{marginTop:'-10px'}}
                    className="image"
                    width="400"
                    height="200"
                    src={fileFrontURL}
                  />
                </WebImage>
                <MobileImage>
                  <img
                    style={{marginTop:'-10px'}}
                    className="image"
                    width="260"
                    height="200"
                    src={fileFrontURL}
                  />
                </MobileImage>
              </InsuranceCard>
              {takePhoto?(<ChoosePhoto style={{cursor:'pointer'}} onClick={() => {setWebCam(true);setHandleFrontBackmethod(true);}}> Retake Photo</ChoosePhoto>):(
                <ChoosePhoto>
                  <div>
                    <input
                      type="file"
                      id="diff-front-file"
                      accept="image/*"
                      name="insurance.frontCard"
                      onChange={handleChangeFront}
                      style={{ display: 'none' }}
                    />
                    <label
                      htmlFor="diff-front-file"
                      style={{ cursor: 'pointer' }}
                    >
                      <i>Choose a different photo</i>
                    </label>
                  </div>
                </ChoosePhoto>
              )}
            </>
          )}
          <StepTwoLineTag />
          {!isBackCardUpload ? (
            <>
              <InsuranceCard>
                <InsuranceCardName>
                  <AiOutlineInsurance
                    size={40}
                    style={{ visibility: 'hidden' }}
                  />
                  <HrLineTag>
                    <HrLines />
                    <HrLines />
                    <HrLines />
                  </HrLineTag>
                </InsuranceCardName>
                <InsuranceCardRow3>
                  <HrLineTag>
                    <HrLines />
                    <HrLines />
                    <HrLines />
                  </HrLineTag>
                  <PlusIcon
                    onClick={() => {
                      setBackCard(true);
                      setErrormsg(false);
                    }}
                  >
                    <FaPlusCircle size={70} color={'rgb(74, 177, 117)'} />
                  </PlusIcon>
                </InsuranceCardRow3>
              </InsuranceCard>
              <SubHeading>
                {takePhoto?'Take a Back side Photo of Your Insurance Card':'Upload a Photo of Your Insurance Card'}<br />
                <b>(BACK)</b>
              </SubHeading>
            </>
          ) : (
            <>
              <InsuranceCard>
                <WebImage>
                  <img style={{marginTop:'-10px'}} width="400" height="200" src={fileBackURL} />
                </WebImage>
                <MobileImage>
                  <img style={{marginTop:'-10px'}} width="260" height="200" src={fileBackURL} />
                </MobileImage>
              </InsuranceCard>
              {
                takePhoto?
                (<ChoosePhoto style={{cursor:'pointer'}} onClick={() => {setWebCam(true);setHandleFrontBackmethod(false)}}> Retake Photo</ChoosePhoto>)
                :
                (
                  <ChoosePhoto>
                    <div>
                      <input
                        type="file"
                        id="diff-back-file"
                        accept="image/*"
                        name="insurance.backCard"
                        onChange={handleChangeBack}
                        style={{ display: 'none' }}
                      />
                      <label htmlFor="diff-back-file" style={{ cursor: 'pointer' }}>
                        <i>Choose a different photo</i>
                      </label>
                    </div>
                  </ChoosePhoto>
                )
              }
            </>
          )}
        </WrapContainer>
      </StepContent>
    );
  };
  const SteptwoUploadFront: React.FC = () => {
    return (
      <StepContent>
        <Heading>
          {takePhoto
            ? 'Take the Front Photo of Your Insurance ID Card'
            : 'Upload the front of your insurance ID card'}
        </Heading>
        <SubHeading>
          {takePhoto
            ? 'Click the Below Button and Take a Photo of Front Side of Your Insurance ID Card'
            : 'Choose a photo of the front side of your ID card, then select continue to proceed.'}
        </SubHeading>
        {!takePhoto && (
          <>
            <UploadInstructionsHeading>
              Select a file to upload*
            </UploadInstructionsHeading>
            <UploadInstructions>
              JPG, PNG, TFF (16MB maximum)
            </UploadInstructions>
          </>
        )}
        <WrapContainerUpload>
          <InsuranceCardUpload>
            {!isFrontImageShow ? (
              <>
                {!loading && !takePhoto ? (
                  <UploadPhoto className="btn fill-btn">
                    <div>
                      <input
                        type="file"
                        id="new-front-file"
                        accept="image/*"
                        name="frontCard"
                        onChange={handleChangeFront}
                        style={{ display: 'none' }}
                      />
                      <label
                        htmlFor="new-front-file"
                        style={{ cursor: 'pointer' }}
                      >
                        Choose Photo
                      </label>
                    </div>
                  </UploadPhoto>
                ) : (
                  !loading && (
                    <UploadPhoto
                      className="btn fill-btn"
                      onClick={() => {
                        setWebCam(true);
                        setHandleFrontBackmethod(true);
                      }}
                    >
                      <BiCamera size={40} />
                      Open Camera
                    </UploadPhoto>
                  )
                )}
                {loading && <Loader />}
              </>
            ) : (
              <>
                <WebImage>
                  <img width="400" height="200" src={fileFrontURL} />
                </WebImage>
                <MobileImage>
                  <img width="260" height="200" src={fileFrontURL} />
                </MobileImage>
              </>
            )}
          </InsuranceCardUpload>
          {isFrontImageShow &&
            (!takePhoto ? (
              <ChoosePhoto>
                <div>
                  <input
                    type="file"
                    id="new-diff-front-file"
                    accept="image/*"
                    name="frontCard"
                    onChange={handleChangeFront}
                    style={{ display: 'none' }}
                  />
                  <label
                    htmlFor="new-diff-front-file"
                    style={{ cursor: 'pointer' }}
                  >
                    <i>Choose a different photo</i>
                  </label>
                </div>
              </ChoosePhoto>
            ) : (
              <ChoosePhoto
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setWebCam(true);
                  setHandleFrontBackmethod(true);
                }}
              >
                Retake A Photo
              </ChoosePhoto>
            ))}
        </WrapContainerUpload>
      </StepContent>
    );
  };
  const SteptwoUploadBack: React.FC = () => {
    return (
      <StepContent>
        <Heading>{takePhoto?'Take the Back Photo of Your Insurance ID Card':'Upload the Back of Your Insurance ID Card'}</Heading>
        <SubHeading>
          {takePhoto?'Click the Below Button and Take a Photo of Back Side of Your Insurance ID Card':'Choose a photo of the back side of your ID card, then select continue to proceed.'}
        </SubHeading>
        {
          !takePhoto&&(
            <>
              <UploadInstructionsHeading>
                Select a file to upload*
              </UploadInstructionsHeading>
              <UploadInstructions>JPG, PNG, TFF (16MB maximum)</UploadInstructions>
            </>
            )
        }
        <WrapContainerUpload>
          <InsuranceCardUpload>
            {!isBackImageShow ? (
              <>
              {!loading&&!takePhoto ? (
                  <UploadPhoto className='btn fill-btn'>
                    <div>
                      <input
                        type="file"
                        id="new-back-file"
                        accept="image/*"
                        name="backCard"
                        onChange={handleChangeBack}
                        style={{ display: 'none' }}
                      />
                      <label
                        htmlFor="new-back-file"
                        style={{ cursor: 'pointer' }}
                      >
                        Choose Photo
                      </label>
                    </div>
                  </UploadPhoto>
                ) : !loading&&<UploadPhoto className='btn fill-btn' onClick={() => {setWebCam(true);setHandleFrontBackmethod(false);}}><BiCamera size={40} />Open Camera</UploadPhoto>
                }
              {loading && <Loader />}
              </>
            ) : (
              <>
                <WebImage>
                  <img width="400" height="200" src={fileBackURL} />
                </WebImage>
                <MobileImage>
                  <img width="260" height="200" src={fileBackURL} />
                </MobileImage>
              </>
            )}
          </InsuranceCardUpload>
          {isBackImageShow && (
            !takePhoto?(
            <ChoosePhoto>
              <div>
                <input
                  type="file"
                  id="new-diff-back-file"
                  accept="image/*"
                  name="backCard"
                  onChange={handleChangeBack}
                  style={{ display: 'none' }}
                />
                <label
                  htmlFor="new-diff-back-file"
                  style={{ cursor: 'pointer' }}
                >
                  <i>Choose a different photo</i>
                </label>
              </div>
            </ChoosePhoto>
            ):(<ChoosePhoto style={{cursor:'pointer'}} onClick={() => {setWebCam(true);setHandleFrontBackmethod(false);}}>Retake A Photo</ChoosePhoto>)
          )}
        </WrapContainerUpload>
      </StepContent>
    );
  };
  return (
      <>
    <WebCam
        open={webCam}
        onClose={() => setWebCam(false)}
        firstName={form.firstName}
        onChange={handleFrontBackmethod?setFrontImage:setBackImage}
    />
    <Modal
      open={open}
      onClose={handleModalClose}
      maxWidth={800}
      blockScroll={false}
    >
      <Container>
      <CloseIcon>
          <AiOutlineClose size={25} onClick={handleModalClose} />
        </CloseIcon>
        <Title>VERIFY YOUR INSURANCE DETAILS</Title>
        
        <Formik
          initialValues={{
            insurance: {
              insuranceId: insurance.insuranceId,
              group: insurance.group,
              subscriberFirstName: insurance.subscriberFirstName,
              subscriberLastName: insurance.subscriberLastName,
              payerList: insurance.payerList,
              frontCard: insurance?.frontCard || '',
              backCard: insurance?.backCard || '',
              insuranceFor: insurance.policyHolderFirstName,
              policyHolderFirstName: insurance.policyHolderFirstName,
              policyHolderLastName: insurance.policyHolderLastName,
              policyHolderbirthDate: insurance.policyHolderbirthDate,
            },
            hasInsurance,
          }}
          onSubmit={(values) => {
            updateFormValues({
              insurance: {
                insuranceId: values.insurance.insuranceId,
                group: values.insurance.group,
                subscriberFirstName: values.insurance.subscriberFirstName,
                subscriberLastName: values.insurance.subscriberLastName,
                payerList: values.insurance.payerList,
                frontCard: fileFrontURL,
                backCard: fileBackURL,
                insuranceFor: values.insurance.insuranceFor,
                policyHolderFirstName: values.insurance.policyHolderFirstName,
                policyHolderLastName: values.insurance.policyHolderLastName,
                policyHolderbirthDate: values.insurance.policyHolderbirthDate,
              },
              hasInsurance: true,
            });
            console.log('card', fileFrontURL, fileBackURL);
            console.log('modal values', values);
            console.log('modal insurance', insurance);
            if (fileFrontURL.length && fileBackURL.length && allow) {
              updateChangeOnClick(values);
            }
          }}
        >
          {() => {
            return (
              <Form>
                <div className={classes.root}>
                  <Stepper alternativeLabel nonLinear activeStep={activeStep}>
                    {steps.map((label, index) => {
                      const stepProps: { completed?: boolean } = {};
                      const buttonProps: { optional?: React.ReactNode } = {};

                      return (
                        <Step key={label} {...stepProps}>
                          <StepButton
                            completed={isStepComplete(index)}
                            {...buttonProps}
                          >
                            {label}
                          </StepButton>
                        </Step>
                      );
                    })}
                  </Stepper>
                  <div>
                    <div>
                      <Typography
                        className={classes.instructions}
                        component={'div'}
                      >
                        {getStepContent(activeStep)}
                      </Typography>

                      {errormsg && (
                        <ErrorText hasError>
                          {'Please Enter required details'}
                        </ErrorText>
                      )}
                      {imageError && (
                        <ErrorText hasError>
                          {'Please upload a image in JPG, PNG, TFF format'}
                        </ErrorText>
                      )}
                      {activeStep === 0 && (
                        <ReturnButton onClick={handleModalClose} type="button" className='btn outline2-btn'>
                          Return
                        </ReturnButton>
                      )}
                      {activeStep === 1 && (
                        <ButtonsContainer>
                          <Button
                            disabled={loading}
                            onClick={handleBack}
                            libraryType="default"
                            type="button"
                            className='btn elevated-btn'
                          >
                            Return
                          </Button>
                          <Space />
                          <Button
                            disabled={loading}
                            libraryType="primary"
                            type="submit"
                           className='btn fill-btn'
                            onClick={handleNext}
                          >
                            {loading ? 'Processing...' : 'Save & Continue'}
                          </Button>
                        </ButtonsContainer>
                      )}
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Container>
    </Modal>
    </>
  );
};

export default AddInsuranceModal;
