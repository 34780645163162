import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import { config } from '../../../config';
import {
    Container,
    Content,
    PageHeader,
    Button,
} from '../../shared/components/styled';
import { colors } from '../../../styles/colors';
import { useStepsState, useStepsDispatch } from '../../schedule/provider';
import NavigationButtons from '../../shared/components/NavigationButtons';
import { Breakpoints } from '../../../dictionaries';
import Inputs from '../../shared/components/form/Input';
import { useSharedState, useSharedDispatch } from '../../shared/provider';
import PaymentModal from '../../schedule/components/ConfirmAndPay/PaymentModal';
import FormLabel from '../../shared/components/form/FormLabel';
import { format, parse, isBefore, subHours, startOfDay } from 'date-fns';
import pin from '../../../assets/pin-grey.svg';
import calendar from '../../../assets/calendar-grey.svg';
import clock from '../../../assets/clock.svg';
import person from '../../../assets/person-grey.svg';
import { convertPricing, getPeriodsFromSchedule } from '../../../utils';
import {
    createAppointment,
    checkReservationValidity,
    getCoupon,
    getNpDestionation,
    createAppointmentForLasLomitas,
    reserveSlot,
    cancelAppointment
} from '../../schedule/api';
import GoogleAnalytics from '../../../GoogleAnalytics';
import { getAppointmentdata, getQbenchConsolidationresults } from '../../admin/api';
import { useHistory } from 'react-router-dom';
import Loader from '../../shared/components/Loader';
import { Spin } from 'antd';

const PageSubTitle = styled.div`
    margin: 10px 0 10px 0;
`;
const Title = styled.h3`
    color: rgba(38, 38, 38, 0.8);
    font-size: 20px;
    font-weight: 700;
    line-height: 22px;
    margin: 30px 0 20px 0;
`;
const WrapContainer = styled.div`
    background: ${colors.blue8};
    margin-bottom: 30px;
    align-items: center;
    padding: 20px;
    @media (max-width: ${Breakpoints.sm}px) {
        position: relative;
    }
`;
const WrapContent = styled.div`
    display: flex;
    position: relative;
    gap: 50px;
    @media (max-width: ${Breakpoints.sm}px) {
        display: grid;
        gap: 5px;
    }
`;
const Flexbox = styled.div`
    display: flex;
    gap: 30px;
    width: 33%;
    &:nth-child(1),
    &:nth-child(2) {
        width: 40%;
    }

    &:nth-child(3) {
        width: 20%;
        height: 20%;
    }
    @media (max-width: ${Breakpoints.sm}px) {
        margin-bottom: 20px;
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
            width: 100%;
            height: 70%;
        }
    }
`;
const Vl = styled.div`
    border-left: 1px solid #2a5f87;
    height: 100%;
    margin-left: 80px;
    @media (max-width: ${Breakpoints.sm}px) {
        display: none;
    }
`;
const FlexboxIcon = styled.div`
    position: relative;
    color: ${colors.grey};
`;
const FlexboxText = styled.div`
    display: block;
    font-size: 14px;
    font-weight: 500;
    margin-top: 5px;
`;

const Cardtitle = styled.div`
    display: flex;
    justify-content: flex-start;
    flex: 1 1 0%;

    margin-bottom: 15px;
    @media (max-width: ${Breakpoints.sm}px) {
        position: relative;
        left: -40px;
        width: 110%;
    }
`;
const TitleKey = styled.h3`
margin: 0 0 14px 0;
color: rgba(38, 38, 38, 0.8);
font-weight: 700;
font-size: 16px;
line-height: 22px;
min-width: 175px;
@media (max-width: ${Breakpoints.sm}px) {
  min-width: 140px;
  margin-left: 0px !important;
  }
}
`;

const TitleValue = styled.h4`
    margin: 0 0 14px 0;
    color: rgba(38, 38, 38, 0.8);
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
`;
const Subcontent = styled.h4`
    margin: 60px 0 0 0;
    @media (max-width: ${Breakpoints.sm}px) {
        padding-left: 2.4rem;
    }
`;

interface ButtonProps {
    libraryType: 'primary' | 'default';
    accentColor?: string;
    size?: 'sm' | 'lg';
}

const ReturnButton = styled.button`
    border: 1px solid
        ${({ accentColor }: ButtonProps) => accentColor || colors.darkBlue};
    font-size: ${({ size }: ButtonProps) => (size === 'sm' ? '14px' : '20px')};
    line-height: 1.6;
    border-radius: 5px;
    padding: ${({ size }: ButtonProps) =>
        size === 'sm' ? '4px 15px' : '9px 20px'};
    font-weight: ${({ size }: ButtonProps) =>
        size === 'sm' ? 'normal' : 'bold'};
    width: 100%;
    text-align: center;
    color: ${({ libraryType, accentColor }: ButtonProps) => {
        return libraryType === 'primary'
            ? colors.white
            : accentColor || colors.darkBlue;
    }};
    background: ${({ libraryType, accentColor }: ButtonProps) =>
        libraryType === 'primary'
            ? accentColor || colors.darkBlue
            : colors.white};
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:disabled {
        background: ${({ libraryType, accentColor }: ButtonProps) =>
        libraryType === 'primary'
            ? accentColor || colors.darkBlue50
            : colors.white};
        border: 1px solid transparent;
        color: ${({ libraryType, accentColor }: ButtonProps) => {
        return libraryType === 'primary'
            ? colors.white
            : accentColor || colors.darkBlue50;
    }};
    }
`;

export const PageTitle = styled.h2`
    color: ${colors.fullBlack};
    font-size: 28px;
    font-weight: 700;
    margin: 0;
    text-align: left;

    @media (max-width: ${Breakpoints.sm}px) {
        font-size: 25px;
        margin-top: 30%;
    }
`;
const Error = styled.div`
    margin-top: 15px;
`;
export const TestingInfoContainer = styled.div`
    background: ${colors.blue8};
    padding: 25px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 6px;

    & > * {
        &:first-child {
            margin-bottom: 20px;
        }
    }

    @media (min-width: ${Breakpoints.sm}px) {
        flex-direction: row;
        padding: 30px;
        justify-content: space-between;

        & > * {
            &:first-child {
                margin-bottom: 0;
            }
        }
    }
`;
export const TestingInfo = styled.div`
    position: relative;
    padding-left: 31px;

    svg {
        width: 24px;
        height: 24px;
    }
`;
interface TestingInfoRow {
    width?: string;
}
export const Icon = styled.div`
    position: absolute;
    left: 0;
    top: 1px;
`;
export const PinIcon = styled(Icon)`
    left: 5px;
    width: 14px;
    height: 20px;
    background: url(${pin}) no-repeat center center;
`;
export const CalendarIcon = styled(Icon)`
    width: 24px;
    height: 24px;
    background: url(${calendar}) no-repeat center center;
`;

export const ClockIcon = styled(Icon)`
    width: 24px;
    height: 24px;
    background: url(${clock}) no-repeat center center;
`;

export const PersonIcon = styled(Icon)`
    width: 24px;
    height: 24px;
    background: url(${person}) no-repeat center center;
`;
const CouponContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
`;
interface TestingInfoRow {
    width?: string;
}
export const TestingInfoRow = styled.div`
    display: flex;
    flex-direction: column;
    margin: -8px 0;
    width: ${({ width }: TestingInfoRow) => width || ''} & > * {
        margin: 8px 0;
    }

    @media (min-width: ${Breakpoints.md}px) {
        flex-direction: row;
        margin: 0 -30px;

        & > * {
            margin: 0 10px;
        }
    }
    @media (max-width: ${Breakpoints.sm}px) {
        gap: 10px;
    }
`;

const UpgradeTest: React.FC = () => {
    let history = useHistory();
    const {
        form: {
            location,
            date,
            slot,
            minors,
            isExpressSameDayTest,
            departureDateAndTime,
            registeringFor,
            isRapidTest,
            destination,
            airlineCode,
            testSelection,
            hasInsurance,
            payingMethod,
            travelType,
            insurance,
            email,
            promoCode,
            discountAmount,
            isAntigen,
            isWalkUpTest,
            isNpDestination,
            cPartnerID
        },
        form,
        prices,
    } = useStepsState();
    const {
        confirmationId,
        agreeToCancel,
        commitToAttend,
        consentForTesting,
        hipaaConfirmed,
        confirmEmail,
        ...appointment
    } = form;
    const {
        updateFormValues,
        goToNextStep,
        goToPrevStep,
        goToSkip2Step,
    } = useStepsDispatch();
    const {
        userData,
        isAdmins,
        airline,
        standradTest,
        //travellType,
        district,
        promos,
    } = useSharedState();

    const employeePromocode = promos?.filter((e:any) => {
      if( e.iswslEmployee){
          return true
      }
  }).map((ee:any) => ee.code)
  
  const allPromocodes = promos?.filter((e:any) => {
      if( e.isAmountReduce){
          return true
      }
  }).map((ee:any) => ee.code)

    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [npArray, setNpArray] = useState([]);
    const [codeLoading, setCodeLoading] = useState(false);
    const [upgradedTestType, setUpgradedTestType] = useState('');
    const [oldTestType, setOldTestType] = useState('')
    const [currentAmount, setCurrentAmount] = useState();
    const [pageLoading, setPageLoading] = useState<boolean>(true);
    const [formLoading, setFormLoading] = useState(false)
    const [isAppointmentPresent, setIsAppointmentPresent] = useState<boolean>(true)
    const [isResulted, setIsResulted] = useState<boolean>(false)

    const getIsResulted = async () => {
        setPageLoading(true);
        const confirmationId = localStorage.getItem('appointmentId');
        const testType = localStorage.getItem('upgradeTestType');
        if (!!testType) setUpgradedTestType(testType);
        //console.log(testType);
        const res = await getQbenchConsolidationresults({ confirmationId });        
        //console.log('::res', res.data.message);
        if (res.data.status && res.data.message?.length === 0) {
            setFormLoading(true)
            //console.log('::qbenchResult.length', res.data.message.length);
            await getAppointmentdata({ confirmationId })
                .then(async (response: any) => {
                    if (response.data.length > 0) {
                        setFormLoading(true);
                        updateFormValues({ ...response.data[0] });
                        //console.log('log data::', response.data[0]);
                        let currentAmt = response.data[0]?.amount ? response.data[0]?.amount : 0;
                        let testType =
                            response.data[0]?.isExpressSameDayTest === true
                                ? 'Express PCR Test '
                                : 'Standard PCR Test ';
                        setOldTestType(testType)
                        setCurrentAmount(currentAmt);
                        setPageLoading(false);
                        return true;
                    } else {
                        console.log('Appointment not present in collection');
                        setIsAppointmentPresent(false);
                        setPageLoading(false);
                    }
                })
                .catch(() => {});
            setTimeout(() => {
                setFormLoading(false);
            }, 3000);
        } else {
            //console.log(':: res.data.message', res.data);
            setIsResulted(true);
            setPageLoading(false);
        }
        console.log();
        //setPageLoading(false);
    };
    useEffect(() => {
        setPageLoading(true);

        const qbenchResult: any = getIsResulted();
        //console.log('::qbenchResult', qbenchResult);
    }, []);

    const price = convertPricing(
      upgradedTestType === 'express' ? true : false,
      upgradedTestType === 'rapid' ? true : false,
      prices,
      travelType,
      destination,
      isAntigen,
      npArray,
      location?.isNpDestination
    ); //form.isExpressSameDayTest ? prices.expedited : form.isRapidTest? prices.rapid : prices.standard;

    var amount: number = 0;
    const [CheckReservation, setCheckReservation] = useState(false);
    if (registeringFor === 'minorOnly') {
        let count = 0
        count = form.minors.filter((item: any) => item.isCanceled !== true).length;
        amount = price * count;
    } else if (registeringFor === 'myself') {
        amount = price;
    } else {
        let count = 0
        count = form.minors.filter((item: any) => item.isCanceled !== true).length;
        amount = price + price * count;
    }
    const { upateTestType } = useSharedDispatch();

    const selectedDate = date
        ? parse(date, config.dateFormat, new Date())
        : new Date();

    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [btnloading, setBtnLoading] = useState<boolean>(false);
    const [message, setMessage] = useState('');
    const [isAmountReduce, setAmountReduce] = useState(false);
    const { apptFromSchdule, toNextStep } = useSharedState();


    const adminLogin =
        isAdmins && isAdmins !== undefined && isAdmins !== null
            ? isAdmins
            : localStorage.getItem('isAdmin');
    // set user data into form
    useEffect(() => {
        setFormLoading(true);
        setPageLoading(true);
        getDestination();
        GoogleAnalytics(form,'/ConfirmBooking');
        setTimeout(() => {
            localStorage.setItem('verifyAccount', 'false');
        }, 1000);
        // window.scrollTo(0, 0);

        upateTestType(
            isRapidTest
                ? false
                : isExpressSameDayTest
                    ? false
                    : isAntigen
                        ? false
                        : true
        );
        updateFormValues({
            ...userData,
            destination: destination,
            travelType: travelType,
            airline: airline,
            airlineCode: airlineCode,
            registeringFor: registeringFor,
            cPartnerID: location?.cPartnerID || '',
            minors: [...form.minors],
        });

        if (promoCode === '') {
            setMessage('');
            updateFormValues({
                couponId: '',
                discountAmount: 0,
            });
        }       

        if (
            (isExpressSameDayTest || isRapidTest || standradTest) &&
            promoCode !== ''
        ) {
            promoCodeValidation('');
            
        }
        setTimeout(() => {
            setPageLoading(false);
            setFormLoading(false);
        }, 1000);
    }, [isExpressSameDayTest, isRapidTest, standradTest]);

    const getDestination = async () => {
        const arr: any = [];
        const response = await getNpDestionation({}).then((result: any) => {
            const data = result.data.data;
            data.forEach((element: any) => {
                arr.push(element.value);
            });
            console.log('values', arr);
        });
        setNpArray(arr);
    };

    const promoCodeValidation = async (promoCodes: any) => {
        try {
            setBtnLoading(true);
            setCodeLoading(true);

            const response = await getCoupon({
                code: promoCodes ? promoCodes : promoCode,
                email: form.email,
                testSelection: testSelection,
            });

            let count = 0;
            if(appointment.registeringFor === 'myself'){
              count = 1;
            }else if(appointment.registeringFor === 'minorOnly'){
              count = appointment.minors.length;
            }else{
              count = 1 + +appointment.minors.length;
            }
      

            if (response?.data?.status === 200) {
                console.log('response.data.data', response.data.data);
                updateFormValues({ couponId: response.data.couponId });
                setCodeLoading(false);
                setAmountReduce(response.data.data.isAmountReduce);
                if (response.data.data.isAmountReduce === false) {
                    if (isRapidTest) {
                        updateFormValues({
                            // discountAmount: (response.data.amount / 100) * 3,
                            discountAmount: response.data.data.RapidTest_Amount,
                        });
                        setMessage(
                            `Coupon code applied succesfully. You have got $${response.data.data.RapidTest_Amount * count} as discount. Please click pay now.`
                        );
                    } else if (isExpressSameDayTest) {
                        updateFormValues({
                            discountAmount:
                                response.data.data.ExpressTest_Amount,
                        });
                        setMessage(
                            `Coupon code applied succesfully. You have got $${response.data.data.ExpressTest_Amount * count} as discount. Please click pay now.`
                        );
                    }  else if (isAntigen) {
                      updateFormValues({
                          discountAmount: response.data.data.AntigenTest_Amount,
                      });
                      setMessage(
                          `Coupon code applied succesfully. You have got $${response.data.data.AntigenTest_Amount * count
                          } as discount. Please click pay now.`
                      );
                    }else {
                        updateFormValues({
                            discountAmount:
                                response.data.data.StandardTest_Amount,
                        });
                        setMessage(
                            `Coupon code applied succesfully. You have got $${response.data.data.StandardTest_Amount * count} as discount. Please click pay now.`
                        );
                    }
                } else if 
                  (response.data.data.isAmountReduce === true &&
                    allPromocodes?.includes(promoCode) || employeePromocode?.includes(promoCode) || employeePromocode?.includes(promoCode && !hasInsurance)

                  //   promoCode === 'EYEPROD') ||
                  // promoCode === 'GRAVY' ||
                  // promoCodes === 'EMPLOYEE' ||
                  // (promoCode === 'EMPLOYEE' && !hasInsurance)
                ) {
                  updateFormValues({
                    amount: amount,
                  });
                  updateFormValues({
                    discountAmount: amount,
                  });
                  setMessage(
                    'Coupon code successfully applied.  There will be no credit card charge.  Please click "Confirm" to complete your booking.'
                  );
                } else {
                  console.log(amount);
                  updateFormValues({
                    discountAmount: 0,
                  });
                  setMessage(
                    `Coupon code applied succesfully. You have got $${amount * count} as discount. Please click pay now.`
                  );
                }
            } else {
                if (promoCode !== '') setMessage(response?.data?.message);
                updateFormValues({ discountAmount: 0 });
                setAmountReduce(false);
            }
            setBtnLoading(false);
        } catch (e) {
            setMessage('Invalid Coupon Code');
            updateFormValues({ discountAmount: 0 });
            console.log('error1', error);
            setBtnLoading(false);
            setAmountReduce(false);
        }
    };

    const handleCreateAppointment = async (method: string) => {
        try {
            setLoading(true);
            if (CheckReservation && cPartnerID !== 'LLT001') {
                setLoading(false);
                updateFormValues({
                    slot: undefined,
                });
            } else if(cPartnerID === 'LLT001'){
                const result = await createAppointmentForLasLomitas({
                    ...appointment,
                    travelType: travelType,
                    paymentIntentId: method,
                    firstName:
                        form?.registeringFor === 'minorOnly' &&
                            !!form?.guardian?.firstName
                            ? form?.guardian?.firstName
                            : form?.firstName,
                    lastName:
                        form?.registeringFor === 'minorOnly' &&
                            !!form?.guardian?.lastName
                            ? form?.guardian?.lastName
                            : form?.lastName,
                    guardian:
                        form?.registeringFor === 'minorOnly' && !!form?.guardian
                            ? form?.guardian
                            : null,
                    minors: minors.length
                        ? minors?.map((minor: any) => {
                            if (
                                minor['isChecked'] === true ||
                                minor['isChecked'] === false
                            ) {
                                delete minor['isChecked'];
                                delete minor['index'];
                            }
                            return minor;
                        })
                        : [],
                    insurance: hasInsurance ? form?.insurance : null,
                    destination: destination,
                    registeringFor: form?.registeringFor,

                    district: district,
                    isNotHavePermanentAddress:
                        !!userData?.isNotHavePermanentAddress
                            ? userData?.isNotHavePermanentAddress
                            : form.isNotHavePermanentAddress,

                    school: testSelection === 'school' ? form?.school : '',
                    schoolTestFor:
                        testSelection === 'school' ? form?.schoolTestFor : '',
                    studentID:
                        testSelection === 'school' ? form?.studentID : '',
                    adminLogin:
                        adminLogin &&
                            adminLogin !== null &&
                            adminLogin !== undefined
                            ? true
                            : false,
                });

                await new Promise<void>((resolve) => {
                    setTimeout(() => {
                        resolve();
                    }, 500);
                });
                updateFormValues({ confirmationId: result.data.id });
                const docId = localStorage.getItem('docId');
                await cancelAppointment({ id: docId, index: [] });
                goToNextStep();
                setLoading(false);
            } else {
                const result = await createAppointment({
                    ...appointment,
                    travelType: travelType,
                    paymentIntentId: method,
                    firstName:
                        form?.registeringFor === 'minorOnly' &&
                            !!form?.guardian?.firstName
                            ? form?.guardian?.firstName
                            : form?.firstName,
                    lastName:
                        form?.registeringFor === 'minorOnly' &&
                            !!form?.guardian?.lastName
                            ? form?.guardian?.lastName
                            : form?.lastName,
                    guardian:
                        form?.registeringFor === 'minorOnly' && !!form?.guardian
                            ? form?.guardian
                            : null,
                    minors: minors.length
                        ? minors?.filter((item:any)=> item.isCanceled !== true).map((minor: any) => {
                            if (
                                minor['isChecked'] === true ||
                                minor['isChecked'] === false
                            ) {
                                delete minor['isChecked'];
                                delete minor['index'];
                            }
                            return minor;
                        })
                        : [],
                    insurance: hasInsurance ? form?.insurance : null,
                    destination: destination,
                    registeringFor: form?.registeringFor,

                    district: district,
                    isNotHavePermanentAddress:
                        !!userData?.isNotHavePermanentAddress
                            ? userData?.isNotHavePermanentAddress
                            : form.isNotHavePermanentAddress,

                    school: testSelection === 'school' ? form?.school : '',
                    schoolTestFor:
                        testSelection === 'school' ? form?.schoolTestFor : '',
                    studentID:
                        testSelection === 'school' ? form?.studentID : '',
                    adminLogin:
                        adminLogin &&
                            adminLogin !== null &&
                            adminLogin !== undefined
                            ? true
                            : false,
                    amount:amount,
                    discountAmount:amount
                });

                await new Promise<void>((resolve) => {
                    setTimeout(() => {
                        resolve();
                    }, 500);
                });
                updateFormValues({ confirmationId: result.data.id });
                const docId = localStorage.getItem('docId');
                cancelAppointment({ id: docId, index: [] });
                goToNextStep();
                setLoading(false);
            }
        } catch (e: any) {
            console.log(e.message);
            setError('Appointment booking failed, please try again.');
            setLoading(false);
        }
    };

    var numberOfGuests: number = 0;

    if (registeringFor === 'minorOnly') {
        numberOfGuests = minors.length;
    } else if (appointment?.registeringFor === 'myself') {
        numberOfGuests = 1;
    } else {
        numberOfGuests = minors.length + 1;
    }
    const desiredSlot = {
        date: appointment?.slot?.date || new Date().toISOString(),
        period: appointment?.slot?.period || 0,
        locationId: appointment?.slot?.locationId || 0,
        label: appointment?.slot?.label || '' 
    }
        
    // setPageLoading(false);
    if(pageLoading) {
        return <Loader />
    }
    if (isResulted === true){
        return (
            <Container size="lg">
                <Content>
                    <PageHeader>
                        <PageTitle style={{ fontSize: '26px' }}>
                            This link has expired. Please contact customer
                            service if you have any questions. You can reach
                            customer service by calling +1-833-747-1122
                        </PageTitle>
                        {/* <PageSubTitle>
                            By clicking the below button you can go back to the
                            Result page
                        </PageSubTitle>
                        <div>
                            <Button
                                libraryType="primary"
                                type="button"
                                onClick={() => history.push('/user/results')}
                                style={{ width: '50%' }}
                            >
                                Results
                            </Button>
                        </div> */}
                    </PageHeader>
                </Content>
            </Container>
        );
    }else
        return (
          <Container size="lg">
            <Content>
              {isAppointmentPresent === false ? (
                <PageHeader>
                  <PageTitle style={{ fontSize: '26px' }}>
                    Your appointment has already been upgraded to
                    {upgradedTestType === 'rapid'
                      ? ` Rapid PCR Test`
                      : ` Express PCR Test`}
                  </PageTitle>
                  <br />
                  <PageSubTitle>
                    By clicking the below button you can go back to the My
                    Profile page
                  </PageSubTitle>
                  <div>
                    <Button
                      libraryType="primary"
                      type="button"
                      onClick={() => history.push('/user/profile')}
                      style={{ width: '50%' }}
                    >
                      My Profile
                    </Button>
                  </div>
                </PageHeader>
              ) : (
                <>
                  {form.location?.name ? (
                    <>
                      <PageHeader>
                        <PageTitle>Confirm your appointment details</PageTitle>
                        <PageSubTitle>
                          Please review and confirm the information below is
                          correct by clicking the{' '}
                          <strong>"Confirm & Pay".</strong>{' '}
                        </PageSubTitle>
                      </PageHeader>
                      <Formik
                        enableReinitialize
                        initialValues={{
                          slot,
                          commitToAttend,
                          agreeToCancel,
                          isExpressSameDayTest,
                          departureDateAndTime,
                          promoCode: promoCode,
                        }}
                        onSubmit={async (values) => {
                          setLoading(true);
                          setFormLoading(true);
                          if (appointment.isExpressSameDayTest) {
                            appointment.isExpressSameDayTest = false;
                            updateFormValues({
                              isExpressSameDayTest: false,
                            });
                          }
                          if (upgradedTestType === 'rapid') {
                            appointment['isRapidTest'] = true;
                            updateFormValues({
                              isRapidTest: true,
                            });
                          } else {
                            appointment.isExpressSameDayTest = true;
                            updateFormValues({
                              isExpressSameDayTest: true,
                            });
                          }
                          // await reserveSlot(
                          //     desiredSlot,
                          //     appointment.slot,
                          //     numberOfGuests,
                          //     isRapidTest,
                          //     appointment?.reservationId,
                          //     false,
                          //     appointment,
                          //     isWalkUpTest
                          // ).then((res) => {
                          //     if (res.data.status == 200) {
                          //         updateFormValues({
                          //             reservationId:
                          //                 res.data
                          //                     .reservationId,
                          //             reservationCount:
                          //                 res.data
                          //                     .numberOfGuests,
                          //         });
                          //     }
                          // });

                          if (
                            testSelection === 'school' ||
                            testSelection === 'community' ||
                            allPromocodes?.includes(promoCode)
                            // promoCode === 'EYEPROD' ||
                            // promoCode === 'GRAVY' ||
                            // promoCode === 'EMPLOYEE'
                          ) {
                            setError('');
                            handleCreateAppointment(testSelection);
                          } else if (hasInsurance) {
                            setError('');
                            handleCreateAppointment(testSelection);
                          } else if (
                            (testSelection === 'general' ||
                              testSelection === 'flight' ||
                              testSelection === 'sfoairport') &&
                            (payingMethod === 'Credit Card' ||
                              payingMethod === 'Credit card')
                          ) {
                            if (CheckReservation) {
                              setLoading(false);
                              updateFormValues({
                                slot: undefined,
                              });
                            } else {
                              //setLoading(false);
                              setShowPaymentModal(true);
                              setPageLoading(false);
                              setFormLoading(true);
                            }
                          } else if (
                            (testSelection === 'general' ||
                              testSelection === 'flight') &&
                            payingMethod === 'No Insurance'
                          ) {
                            setError('');
                            handleCreateAppointment('general');
                          } else {
                            goToNextStep();
                          }
                        }}
                      >
                        {({ values }) => (
                          <Form>
                            {formLoading ? (
                              <div
                                style={{
                                  textAlign: 'center',
                                  padding: '16px',
                                }}
                              >
                                <Spin size="large" />
                              </div>
                            ) : (
                              <>
                                <Title>Testing information</Title>

                                {!!testSelection &&
                                testSelection !== 'flight' ? null : (
                                  <TestingInfoContainer>
                                    <TestingInfo>
                                      <PinIcon />
                                      <strong>Departure date and time</strong>
                                      <br />
                                      {departureDateAndTime}
                                    </TestingInfo>
                                  </TestingInfoContainer>
                                )}
                                <TestingInfoContainer>
                                  <TestingInfo>
                                    <PinIcon />
                                    <strong>
                                      {form.location?.name || 'Loading...'}
                                    </strong>
                                    <br />
                                    {location?.address1 || 'Loading...'}{' '}
                                    {location?.address2}
                                    <br />
                                    {cPartnerID === 'LLT001'
                                      ? location?.room
                                      : ''}
                                  </TestingInfo>
                                </TestingInfoContainer>
                                <TestingInfoContainer>
                                  <TestingInfoRow
                                    style={{
                                      paddingLeft: '16px',
                                    }}
                                  >
                                    <TestingInfo>
                                      <CalendarIcon />
                                      <strong>Date</strong>
                                      <br />
                                      {format(
                                        selectedDate,
                                        config.weekDateFormat
                                      ) || 'Loading...'}
                                    </TestingInfo>
                                    <TestingInfo>
                                      <ClockIcon />
                                      <strong>Time</strong>
                                      <br />
                                      {slot !== undefined && slot !== null
                                        ? slot.label || 'Loading...'
                                        : 'Loading...'}
                                    </TestingInfo>
                                    {location?.cPartnerID === 'LLT001' && (
                                      <TestingInfo>
                                        <strong>Cadence</strong>
                                        <br />
                                        Weekly
                                      </TestingInfo>
                                    )}
                                  </TestingInfoRow>
                                </TestingInfoContainer>

                                {testSelection !== 'school' &&
                                  testSelection !== 'community' && (
                                    <>
                                      <TestingInfoContainer>
                                        <TestingInfoRow>
                                          <TestingInfo>
                                            <strong>Current Test type</strong>
                                            <br />
                                            {oldTestType !== ''
                                              ? `${oldTestType} - $${currentAmount}`
                                              : `Loading...` || 'Loading...'}
                                          </TestingInfo>
                                          <TestingInfo>
                                            <strong>Upgraded Test type</strong>
                                            <br />
                                            {upgradedTestType === 'rapid'
                                              ? `Rapid PCR Test - $${
                                                  amount || 'Loading...'
                                                }`
                                              : `Express PCR Test - $${
                                                  amount || 'Loading...'
                                                }` || 'Loading...'}
                                          </TestingInfo>
                                        </TestingInfoRow>
                                      </TestingInfoContainer>
                                    </>
                                  )}

                                <Subcontent>
                                  <Cardtitle>
                                    <TitleKey>Name</TitleKey>
                                    <TitleValue>
                                      {form?.firstName} {form?.middleName}{' '}
                                      {form?.lastName}
                                    </TitleValue>
                                  </Cardtitle>

                                  {/* <ChangeNameModal
                                                    open={openNameDialog}
                                                    handleNameClose={handleNameClose}
                                                    isProfile={false}
                                                    setErrormsg={handleSetError}
                                                /> */}

                                  <Cardtitle>
                                    <TitleKey>Date of Birth</TitleKey>
                                    <TitleValue>{form?.birthDate}</TitleValue>
                                  </Cardtitle>

                                  {/* <ChangeDobModal
                                                    open={openDobDialog}
                                                    handleDobClose={handleDobClose}
                                                    isProfile={false}
                                                    setErrormsg={handleSetError}
                                                /> */}

                                  <Cardtitle>
                                    <TitleKey>Phone Number</TitleKey>
                                    <TitleValue>{form?.phone}</TitleValue>
                                  </Cardtitle>

                                  {/* <ChangePhoneModal
                                                     open={openPhoneDialog}
                                                    handlePhoneClose={handlePhoneClose}
                                                    isProfile={false}
                                                /> */}

                                  <Cardtitle>
                                    <TitleKey>Home Address</TitleKey>
                                    <TitleValue>
                                      <strong>{form?.address?.address} </strong>
                                      <br />
                                      {form?.address?.city} ,
                                      {form?.address?.state} ,
                                      {form?.address?.country}
                                      {form?.address?.zipCode}
                                      <br />
                                    </TitleValue>
                                  </Cardtitle>

                                  {/* <ChangeAddressModal
                                                    open={openAddressDialog}
                                                    handleAddressClose={handleAddressClose}
                                                    isProfile={false}
                                                    /> */}
                                </Subcontent>
                                {registeringFor !== 'myself' &&
                                  minors.length > 0 &&
                                  minors.filter(
                                    (item: any) => item.isCanceled !== true
                                  ).length > 0 && (
                                    <PageSubTitle>
                                      <b>Minors</b>
                                    </PageSubTitle>
                                  )}
                                {registeringFor !== 'myself' &&
                                  minors.length > 0 && (
                                    <>
                                      {minors
                                        .filter(
                                          (item: any) =>
                                            item?.isCanceled !== true
                                        )
                                        .map(
                                          (
                                            {
                                              firstName,
                                              lastName,
                                              middleName,
                                              birthDate,
                                            }: any,
                                            i: any
                                          ) => (
                                            <WrapContainer key={i}>
                                              <WrapContent>
                                                <Flexbox>
                                                  <FlexboxIcon>
                                                    <PersonIcon />
                                                  </FlexboxIcon>
                                                  <FlexboxText>
                                                    <b>{'Name : '}</b>
                                                    {firstName} {middleName}{' '}
                                                    {lastName}
                                                  </FlexboxText>
                                                  <Vl></Vl>
                                                </Flexbox>
                                                <Flexbox>
                                                  <FlexboxIcon>
                                                    <CalendarIcon />
                                                  </FlexboxIcon>
                                                  <FlexboxText>
                                                    <b>{'Date of Birth : '}</b>
                                                    {birthDate}
                                                  </FlexboxText>
                                                </Flexbox>
                                                {/* <Flexbox>
                                                        <Button
                                                            type="button"
                                                            onClick={() => {
                                                                handleMinorOpen();
                                                                setEditKey({
                                                                    key:
                                                                        editKey.key ===
                                                                            i
                                                                            ? -1
                                                                            : i,
                                                                });
                                                            }}
                                                            size="sm"
                                                            libraryType="default"
                                                        >
                                                            Edit
                                                        </Button>
                                                        {editKey.key === i && (
                                                            <ChangeMinorModal
                                                                isConfirmBooking={
                                                                    true
                                                                }
                                                                isRegister={
                                                                    false
                                                                }
                                                                close={
                                                                    handleMinorClose
                                                                }
                                                                open={
                                                                    openMinorDialog
                                                                }
                                                                index={i}
                                                                isProfile={true}
                                                            />
                                                        )}
                                                    </Flexbox> */}
                                              </WrapContent>
                                            </WrapContainer>
                                          )
                                        )}
                                    </>
                                  )}
                                {error && <Error>{error}</Error>}

                                {form.promoCode && (
                                  <CouponContainer>
                                    <FormLabel label="Coupon Code">
                                      <Inputs
                                        disabled
                                        name="promoCode"
                                        defaultValue={form.promoCode}
                                        value={form.promoCode}
                                      />
                                    </FormLabel>
                                    <FormLabel label="">
                                      <Button
                                        disabled
                                        libraryType="primary"
                                        type="button"
                                        // onClick={() =>
                                        //     promoCodeValidation(
                                        //         ''
                                        //     )
                                        // }
                                      >
                                        {btnloading ? 'Processing...' : 'Apply'}
                                      </Button>
                                    </FormLabel>
                                  </CouponContainer>
                                )}
                                {message && (
                                  <Error
                                    style={{
                                      color:
                                        discountAmount > 0
                                          ? 'green'
                                          : isAmountReduce
                                          ? 'green'
                                          : 'red',
                                    }}
                                  >
                                    {message}
                                  </Error>
                                )}
                                {/* {form?.slot === undefined ||
                                                form?.slot === null ? (
                                                    <Error
                                                        style={{ color: 'red' }}
                                                    >
                                                        Your Reservation time is
                                                        Cancelled, Please Select
                                                        the timing again
                                                    </Error>
                                                ) : (
                                                    ''
                                                )} */}
                                {console.log('promoCode', promoCode)}
                                <NavigationButtons
                                  confirmBtnText={'Confirm & Pay'}
                                  // disabled={button && testSelection=="general" && freeCode ?true:false}
                                  isValid={slot !== null && slot !== undefined}
                                  loading={loading}
                                  onReturnButtonClick={() => {
                                    history.push('/user/profile');
                                  }}
                                />
                              </>
                            )}
                          </Form>
                        )}
                      </Formik>
                    </>
                  ) : (
                    <div
                      style={{
                        textAlign: 'center',
                        padding: '16px',
                      }}
                    >
                      <Spin size="large" />
                    </div>
                  )}
                </>
              )}
            </Content>
            {/* <ChangeLocationModal
                openModal={() => setShowChangeDatetimeModal(true)}
            />
            <ChangeDateTimeModal
                showModal={showChangeDatetimeModal}
                closeModal={() => setShowChangeDatetimeModal(false)}
            /> */}
            <PaymentModal
              showModal={showPaymentModal}
              closeModal={() => setShowPaymentModal(false)}
            />
            {/* <ChangeApptAttendeeModal
                showModal={showChangeApptAttendeeModal}
                closeModal={() => setShowChangeApptAttendeeModal(false)}
            /> */}
          </Container>
        );
};

export default UpgradeTest;
