import React, { useState,useEffect } from 'react';
import { Form, Formik ,FieldArray,useFormikContext} from 'formik';
import styled from 'styled-components';
import { parse, format } from 'date-fns';

import { Button, ErrorText } from '../../../../shared/components/styled';
import { colors } from '../../../../../styles/colors';
import { config } from '../../../../../config';

import { Minor as IMinor } from '../../../../schedule/models';

import Modal from '../../../../shared/components/Modal';
import Input from '../../../../shared/components/form/Input';
import FormLabel from '../../../../shared/components/form/FormLabel';
import { AppointmentListItem } from '../../../models';
import { updateAppointment } from '../../../api';
import { DatepickerField } from '../../../../shared/components/Datepicker';
import { isUSPhone, isValidEmail } from '../../../../../validations';
import { useAdminDispatch, useAdminState } from '../../../provider';
import ChangeDateTimeModal from '../../../../schedule/components/ConfirmAndPay/ChangeDateTimeModal';
import { useStepsDispatch } from '../../../../schedule/provider';
import pin from '../../../../../assets/pin-grey.svg';
import calendar from '../../../../../assets/calendar-grey.svg';
import clock from '../../../../../assets/clock.svg';
import person from '../../../../../assets/person-grey.svg';
import Select from '../../../../shared/components/form/Select';
import {
  Content,
  PageHeader,
  PageTitle,
  PageSubTitle,
} from '../../../../shared/components/styled';
import { Breakpoints } from '../../../../../../src/dictionaries';
import { ageCalculator } from '../../../../../../src/utils';

import ZipCodeInput from '../../../../schedule/components/Address/ZipCodeInput';
import { states } from '../../../../schedule/components/Address/states';
import { updateAppointmentdata } from '../../../api';

import Checkbox from '../../../../shared/components/form/Checkbox';
import NavigationButtons from '../../../../shared/components/NavigationButtons';
import ChangeLocationModal from '../../../../shared/components/ChangeLocationModal';
import PaymentModal from '../../../../schedule/components/ConfirmAndPay/PaymentModal';
import ChangeApptAttendeeModal from '../../../../schedule/components/ConfirmAndPay/ChangeApptAttendeeModal';
import classes from '*.module.css';
const Container = styled.div`
`;

const Title = styled.h2`
  color: ${colors.fullBlack};
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 13px;
`;

const CityStateRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > *:first-child {
    width: 60%;
  }

  & > *:last-child {
    width: 38%;
  }
`;


const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 240px;
  margin: 30px -5px 0;

  & > * {
    margin: 0 5px;
  }
`;
const TestingInfoBtn = styled(Button)`
  max-width: 200px;
`;


export const TestingInfoContainer = styled.div`
  background: ${colors.blue8};
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 6px;

  & > * {
    &:first-child {
      margin-bottom: 20px;
    }
  }

  @media (min-width: ${Breakpoints.sm}px) {
    flex-direction: row;
    padding: 30px;
    justify-content: space-between;

    & > * {
      &:first-child {
        margin-bottom: 0;
      }
    }
  }
`;

export const Icon = styled.div`
  position: absolute;
  left: 0;
  top: 1px;
`;

export const PinIcon = styled(Icon)`
  left: 5px;
  width: 14px;
  height: 20px;
  background: url(${pin}) no-repeat center center;
`;

export const CalendarIcon = styled(Icon)`
  width: 24px;
  height: 24px;
  background: url(${calendar}) no-repeat center center;
`;

export const ClockIcon = styled(Icon)`
  width: 24px;
  height: 24px;
  background: url(${clock}) no-repeat center center;
`;

export const PersonIcon = styled(Icon)`
  width: 24px;
  height: 24px;
  background: url(${person}) no-repeat center center;
`;

export const TestingInfoRow = styled.div`
  display: flex;
  flex-direction: column;
  margin: -8px 0;

  & > * {
    margin: 8px 0;
  }

  @media (min-width: ${Breakpoints.md}px) {
    flex-direction: row;
    margin: 0 -30px;

    & > * {
      margin: 0 30px;
    }
  }
`;

export const TestingInfo = styled.div`
  position: relative;
  padding-left: 31px;

  svg {
    width: 24px;
    height: 24px;
  }
`;

const MinorsListWrapper = styled.div``;

const Minor = styled.div`
  padding: 25px 15px 20px;
  background: ${colors.blue8};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5px;

  & > * {
    width: 100%;
  }

  & input {
    padding:15px 11px;
  }

  @media (min-width: ${Breakpoints.sm}px) {
    flex-direction: row;
    margin: 0 -8px;
    padding: 25px 15px 5px;

    & > * {
      margin: 0 8px;

      &:nth-child(1),
      &:nth-child(2) {
        flex-basis: 27%;
      }

      &:nth-child(3) {
        flex-basis: 24%;
      }

      &:nth-child(4) {
        flex-basis: 20%;
      }

      &:nth-child(5) {
        flex-basis: 3%;
      }
    }
  }

  @media (min-width: ${Breakpoints.md}px) {
    padding: 5px;
    margin: 0 -15px;

    & > * {
      margin: 0 15px;
    }
  }
`;

const InputRow = styled.div`
display: flex;
align-items: center;
justify-content: space-between;

& > *:first-child {
  width: 48%;
}

& > *:last-child {
  width: 48%;
}
`;

// const inputname = classes.inputname'





interface Props {
  showModal: boolean;
  closeModal: () => void;
}

const EditAppointmentModal: React.FC<Props> = ({ showModal, closeModal }) => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const { updateAppointmentInStore } = useAdminDispatch();
  const { toggleChangeLocationModal, updateFormValues } = useStepsDispatch();
  const [showChangeDatetimeModal, setShowChangeDatetimeModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showChangeApptAttendeeModal, setShowChangeApptAttendeeModal] = useState(false);
  const { params, appointments, Editappointments } = useAdminState();
  const locationdata = Editappointments !== null ? Editappointments[0].location : null
  const appointmentdata: any = Editappointments !== null ? Editappointments[0] : null
  
  useEffect(() => {

   console.log("doiewqjofjoewfk",appointmentdata.minors) 
   appointmentdata.minors.map((e:any)=>e.birthDate=e.birthDate
   ? parse(e.birthDate, config.dateFormat, new Date())
   : null)
  }, []);
  

  const handleSearch = () => {
    const location = {
      address1: "6695 Green Valley Circle",
      address2: "Culver City, CA 90230",
      name: "LAX",
      qbenchCustomerId: config.qbenchCustomerId.LosAngeles

    }
    toggleChangeLocationModal(true)
    updateFormValues({ location })
  };

  const add123 = {
    label: 'Alabama',
    value: 'AL',
  }

  const updateAddressValue = (newValue: any) => {
    updateFormValues({
      address: {
        ...appointmentdata.address,
        ...newValue,
      },
    });
  }


  const saveMinorDetails = (
    fieldName: string,
    index: number,
    newValue: string | Date | null
  ) => {
    const newMinors = [...appointmentdata?.minors.minors];
    newMinors[index][fieldName] = newValue;

    updateFormValues({
      minors: newMinors,
    });
  };


  return (
    <Modal open={showModal} onClose={closeModal} maxWidth={800}>
      <Container>
        <Title>Edit basic Info</Title>
        <Formik
          initialValues={{
            firstName: appointmentdata?.firstName,
            lastName: appointmentdata?.lastName,
            birthDate: appointmentdata?.birthDate
              ? parse(appointmentdata.birthDate, config.dateFormat, new Date())
              : null,
            email: appointmentdata?.email,
            phone: appointmentdata?.phone,
            address: appointmentdata?.address,
            add123: states.filter((o) => Object.values(o).includes(appointmentdata?.address.state))[0],
            minors:appointmentdata?.minors



          }}
          onSubmit={async (values) => {
            setLoading(true);

            appointmentdata.firstName = values.firstName
            appointmentdata.lastName = values.lastName
            appointmentdata.birthDate = values.birthDate
              ? format(values.birthDate, config.dateFormat)
              : appointmentdata.birthDate
            appointmentdata.email = values.email
            appointmentdata.phone = values.phone
            appointmentdata.address = values.address
            appointmentdata.address.state = values.add123.value

            appointmentdata.minors=values.minors

            appointmentdata.minors.map((e:any)=>e.birthDate=e.birthDate
            ? format(e.birthDate, config.dateFormat)
            : appointmentdata.birthDate)

            console.log("appointmentdata",appointmentdata)

            updateAppointmentdata(appointmentdata)

            appointmentdata.minors.map((e:any)=>e.birthDate=e.birthDate
            ? parse(e.birthDate, config.dateFormat, new Date())
            : null)

            
            closeModal();

            setLoading(false);
          }}
        >
          {() => (
            <Form>

<InputRow>


              <FormLabel label="First name">
                <Input name="firstName" isRequired />
              </FormLabel>
              <FormLabel label="Last name">
                <Input name="lastName" isRequired />
              </FormLabel>
              
              </InputRow>
              <InputRow>
              <FormLabel label="Date of birth">
                <DatepickerField
                  inputPlaceholder="MM/DD/YYYY"
                  name="birthDate"
                />
              </FormLabel>
              
             
              {/* {appointmentdata?.minorIndex === null && ( */}
             
             
              <FormLabel label="Email">
                <Input
                  name="email"
                  validate={(value) => {
                    let error;

                    if (!isValidEmail.test(value)) {
                      error = 'Please enter valid email address';
                    }

                    if (value === '') {
                      error = 'Required field';
                    }

                    return error;
                  }}
                />
              </FormLabel>
            
              </InputRow>
              {/* )} 


             {appointmentdata?.minorIndex === null && ( */}
              <FormLabel label="Phone">
                <Input
                  name="phone"
                  validate={(value) => {
                    let error;

                    if (!isUSPhone.test(value)) {
                      error = 'Please enter valid US phone number';
                    }

                    if (value === '') {
                      error = 'Required field';
                    }

                    let valueArr=value.split("")
                    if(valueArr.includes("+") || valueArr.includes("+1")){
                      error="Please exclude country code(+1)"
                    }
                    if(valueArr.includes(" ") || valueArr.includes("-") || valueArr.includes("(") || valueArr.includes(")") ){
                      error = "Please avoid special characters and spaces.(The format is:3239205839)"
                    }

                    return error;
                  }}
                /> </FormLabel>
              {/* )} */}

              

              <FormLabel label="Street address">
                <Input
                  onChange={(value) => updateAddressValue({ address: value })}
                  name="address.address"
                  isRequired
                />
              </FormLabel>
              <CityStateRow>
                <FormLabel label="City">
                  <Input
                    onChange={(value) => updateAddressValue({ city: value })}
                    name="address.city"
                    isRequired
                  />
                </FormLabel>
                <FormLabel label="State">
                  <Select<string>
                    name="add123"
                    options={states}
                    //value = {}
                    onChange={(value) => updateAddressValue({ state: value })}
                    validate={(value) => {
                      let error;

                      if (!value) {
                        error = 'Required field';
                      }

                      return error;
                    }}
                  />
                  
                </FormLabel>
              </CityStateRow>
              <ZipCodeInput updateAddressValue={updateAddressValue} updateCountyValue={updateAddressValue} />


             { appointmentdata?.minors.length>0?<Title>Minor Information</Title>:false}

              {appointmentdata?.minors.map((minor: IMinor, index: number) => (
               <Minor key={index}>
              
              {/* <InputRow > */}
                <FormLabel label="First Name">
                  <Input
                    name={`minors.${index}.firstName`}
                    isRequired
                    // onChange={(value) =>
                    //   saveMinorDetails('firstName', index, value)
                    // }
                  />
                </FormLabel>
                <FormLabel label="Last Name">
                  <Input
                    name={`minors.${index}.lastName`}
                    isRequired
                    // onChange={(value) =>
                    //   saveMinorDetails('lastName', index, value)
                    // }
                  />
                </FormLabel>
                {/* </InputRow> */}
                {/* <InputRow> */}
                <FormLabel label="Date of Birth">
                  <DatepickerField
                    name={`minors.${index}.birthDate`}
                    // name={`parse(minors.${index}.birthDate, config.dateFormat, new Date())`}
                    inputPlaceholder="MM/DD/YYYY"
                    maxDate={new Date()}
                    isRequired
                    validate={(value) => {
                      let error;

                      let date = ageCalculator(value)
                      if (date.years >= 18 && value !== null) {
                        if (date.years == 18 && date.months !== 0 || date.months == 0 && date.days !== 0) {
                          error = 'Minor must be under 18 years old';
                        } else if (date.years > 18) {
                          error = 'Minor must be under 18 years old';
                        }
                      }

                      // if (differenceInYears(new Date(), value) > 18) {
                      //   error = 'Minor must be under 18 years old';
                      // }

                      return error;
                    }}
                    onChange={(value: Date | null) =>
                      // saveMinorDetails('birthDate', index, value)

                      appointmentdata.minors[index].birthDate=value
                    }
                  /> 
                </FormLabel>
                <FormLabel label="Relationship">
                  <Input 
                    name={`minors.${index}.relationship`}
                    isRequired
                    // onChange={(value) =>
                    //   saveMinorDetails('relationship', index, value)
                    // }
                  />
                </FormLabel>
                {/* </InputRow> */}
               
                </Minor>
            ))}
          

              <ButtonsContainer>
                <Button
                  libraryType="default"
                  size="sm"
                  type="button"
                  onClick={closeModal}
                >
                  Cancel
                </Button>
                <Button libraryType="primary" size="sm" type="submit">
                  {loading ? 'Saving...' : 'Save'}
                </Button>
              </ButtonsContainer>
            </Form>
          )}
        </Formik>
        {error && <ErrorText hasError>{error}</ErrorText>}
      </Container>
    </Modal>
  );
};

export default EditAppointmentModal;
