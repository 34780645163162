import styled from 'styled-components';

import { colors } from '../../../styles/colors';
import { Breakpoints } from '../../../dictionaries';
import { Switch, withStyles } from '@material-ui/core';

interface ContainerProps {
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  noPadding?: boolean;
  marginTop?: boolean;
  insurance?:boolean;
}

interface WrapperProps{
  backgroundColor?: string | null;
}
interface Props {
  district?: string;
  height?: string;
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: auto;
  background: ${({backgroundColor}:WrapperProps)=> backgroundColor || colors.white};
  & > * {
    &:nth-child(2) {
      flex-grow: 1;
    }
  }
`;
 
export const Container = styled.div`
  margin: 0 auto;
  max-width: ${({ size }: ContainerProps) => {
    if (size === 'md') {
      return '760px';
    }

    if (size === 'lg') {
      return '920px';
    }

    if (size === 'xl') {
      return '1110px';
    }

    if (size === 'xxl') {
      return '1400px';
    }

    return '650px';
  }};
  margin-top: ${({ marginTop }: ContainerProps) =>
    marginTop ? '40px' : '9.2vh'};
  padding: ${({ noPadding }: ContainerProps) => (noPadding ? '0px' : '0 15px')};
  @media (min-width: ${Breakpoints.sm}px) and (max-width: ${Breakpoints.md}px) {
    margin-left: ${({ insurance }: ContainerProps) => (insurance ? '5%' : '')};
  }
`;
// @media (max-width: ${Breakpoints.sm}px) {
  //   margin-top: 60px !important;
  // }
export const WideContainer = styled.div`
  margin: 0 auto;
  max-width: 2500px;
  padding: ${({ noPadding }: ContainerProps) => (noPadding ? '0px' : '0 25px')};
`;

export const Content = styled.div`
  margin-left: '18%';
  @media (min-width: ${Breakpoints.md}) {
     margin-left: '0%'
    padding: 88px 0;
  }
  @media (max-width: ${Breakpoints.sm}px) {
    margin-left: '0%'
    padding: 0px 0px 10px 0px !important;
  }
`;


export const Contents = styled.div`
  padding: 30px 30px;

  @media (min-width: ${Breakpoints.md}) {
    padding: 88px 0;
  }
  @media (max-width: ${Breakpoints.sm}px) {
    padding: 30px 0px 10px 0px !important;
    
  }
`;

export const PageHeader = styled.div`
  margin-bottom: 18px;
`;

interface PageTitleProps {
  color?: string;
  textAlign?: string;
  fontsize?: string;
}

export const PageTitle = styled.h2`
  color:  ${({ color }: PageTitleProps) => color || colors.fullBlack};
  font-size:  ${({ fontsize }: PageTitleProps) => fontsize || '28px'} ;
  font-weight: 700;
  margin: 0;
  text-align: ${({ textAlign }: PageTitleProps) => textAlign || 'left'};

  @media (min-width: ${Breakpoints.md}) {
    font-size: 32px;
  }
  @media (max-width: ${Breakpoints.sm}px) {
    font-size: 21px;
    text-align: center;
  }

`;

export const PageSubTitle = styled.div`
  margin: 10px 0 0 0;
`;

interface TextProps {
  color?: string;
}

export const Text = styled.p`
color:${({ color }: TextProps) => color || colors.black}
`;

interface ButtonProps {
  libraryType: 'primary' | 'default';
  accentColor?: string;
  size?: 'sm' | 'lg';
  isNoBoxShadow?:boolean
}

export const Button: any = styled.button`
  border: 1px solid
    ${({ accentColor }: ButtonProps) => accentColor || ''};
  font-size: ${({ size }: ButtonProps) => (size === 'sm' ? '14px' : '16px')};
  line-height: 1.6;
  border-radius: 32px !important;
  padding: ${({ size }: ButtonProps) =>
    size === 'sm' ? '4px 15px' : '9px 20px'};
  font-weight: ${({ size }: ButtonProps) =>
    size === 'sm' ? 'normal' : 'bold'};
  width: 100%;
  color: ${({ libraryType, accentColor }: ButtonProps) => {
    return libraryType === 'primary'
      ? colors.white
      : accentColor || colors.rebrandBlack;
  }};
  background: ${({ libraryType, accentColor }: ButtonProps) =>
    libraryType === 'primary' ? accentColor || colors.rebrandOrange : colors.white};
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:disabled {
    background: ${({ libraryType, accentColor }: ButtonProps) =>
    libraryType === 'primary'
      ? colors.lightOrange50 || colors.darkBlue50
      : colors.white};
    border: 1px solid transparent;
    color: ${({ libraryType, accentColor }: ButtonProps) => {
    return libraryType === 'primary'
      ? colors.white
      : accentColor || colors.darkBlue50;
  }};
  }
  @media (max-width: ${Breakpoints.sm}px) {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 16px;
    margin-top:10px !important;
    
  }
  @media (max-width: ${Breakpoints.md}px){
    display: block;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 10px !important;
  }
  @media (max-width: ${Breakpoints.lg}px){
    display: block;
    margin-top: 5px !important;
  }
`;

export const ErrorText = styled.div`
  font-size: 13px;
  color: ${colors.red};
  opacity: ${({ hasError }: { hasError: boolean }) => (hasError ? 1 : 0)};
  transition: opacity 0.15s ease-in-out;
`;

export const ErrorTextLarge = styled.div`
  font-size: 17px;
  color: ${colors.red};
  opacity: ${({ hasError }: { hasError: boolean }) => (hasError ? 1 : 0)};
  transition: opacity 0.15s ease-in-out;
`;

interface SubHeaderDescriptionProps {
  color?: string;
}

export const SubHeaderDescription = styled.p`
  color: ${({ color }: SubHeaderDescriptionProps) => color || ''};
  margin-bottom: 14px;
  font-size: 15px;
  font-weight: 500;
  @media (max-width: ${Breakpoints.sm}px) {
    padding: 0 !important;
    padding-top: 15px !important;
  }
`;

export const ResponsiveTable = styled.div`
  margin: 0;
  border-collapse: collapse;
  font-size: 14px;
  width: 100%;
  overflow-y: hidden;
  max-width: 2500px;

  table {
    margin: 0;

    td,
    th {
      border: ${colors.white};
      padding: 0.7rem;
      min-width: 90px;
    }

    th {
      text-align: left;
      border-top: none;
      background: ${colors.blue8};
      color: ${colors.black};
    }

    td {
      border-bottom: none;
      border-bottom: 1px solid ${colors.grey20};
    }

    tr:last-child {
      td {
        border-bottom: 0;
      }
    }
  }
`;


export const getlogo = () => {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let dist = params.get('district') || '';
  return dist;
};

export const LogoCard = styled.div`
  display: flex;
  justify-content: center;
  padding: ${({district}:Props)=> (getlogo() === 'SUHSD' || district === 'SEQ001') ? '20px' : '0px'};
`;

export const LogoImage = styled.img`
  height: ${({height}:Props)=> height || '90px'};
  width: auto;
  @media (max-width: ${Breakpoints.sm}px) {
    margin-top: 15px;
  }
`;
export const P = styled.p`
  font-size: 10px;
  font-weight: 600;
  text-align: left;
  width: 100%;
  @media (max-width: ${Breakpoints.xl}px) {
    // margin-bottom: 280px;
  }
`
export const CustomSwitch = withStyles({
  switchBase: {
    '&$checked': {
      color: '#243D4D',
    },
  },
  checked: {},
  track: {},
})(Switch);
//ltst