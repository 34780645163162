import React, { useEffect, useRef, useState, useCallback } from 'react';
import styled from 'styled-components';
import { colors } from '../../../../../styles/colors';
import { MdExpandMore, MdExpandLess, MdDateRange } from 'react-icons/md';
import { BiTimeFive, BiDollarCircle } from 'react-icons/bi';
import {
  IoLocationOutline,
  IoPersonOutline,
  IoPeopleOutline,
  IoPersonAddOutline,
} from 'react-icons/io5';
import { FaFileInvoiceDollar } from 'react-icons/fa';
import { Breakpoints } from '../../../../../dictionaries';
import { Button } from '../../../../shared/components/styled';
import { useReactToPrint } from 'react-to-print';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PrintableConfirmation from '../../../../schedule/components/Confirmation/PrintableConfirmation';
import DownloadableConfirmatiom from '../../../../schedule/components/Confirmation/DownloadableConfirmation';
import { useStepsDispatch } from '../../../../schedule/provider';
import { useSharedDispatch, useSharedState } from '../../../../shared/provider';
import { getQbenchOrderById } from '../../../../manage/api';
import Loader from '../../../../shared/components/Loader';
import { AppointmentListItem } from '../../../../shared/models';
import { format, isAfter, isEqual, isSameDay, parse } from 'date-fns';
import { config } from '../../../../../config';
import { getPeriodsFromSchedule } from '../../../../../utils';
import { useAdminDispatch, useAdminState } from '../../../../admin/provider';
import { useHistory } from 'react-router-dom';
import CancelAppointmentModal from './CancelAppointmentModal';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { merge, keyBy, values, sumBy, filter, orderBy } from 'lodash';
import {
  downloadResultsPDF,
  downloadCrelioPdf,
  downloadReceipt,
} from '../../../../admin/api';
import { getAppointmentResults, getUserInsurance } from '../../../api';
import CancelApptModal from './CancelApptModal';
import { getNpDestionation } from '../../../../schedule/api';
import { renderToString } from 'react-dom/server';
import { jsPDF } from 'jspdf';
import GenerateReceipt from './GenerateReceipt';
import { includes } from 'lodash';
import { useStepsState } from '../../../provider';

const Mainpage = styled.div`
  margin: 7% 0 0px 10%;
  @media (max-width: ${Breakpoints.sm}px) {
    margin: 25% 0 0 17%;
  }
  @media (max-width: ${Breakpoints.md}px) {
    margin-top: 25%;
    margin-left: 17%;
  }
  @media (min-width: ${Breakpoints.md}px) and (max-width: ${Breakpoints.lg}px)  {
    margin-top: 15%;
    margin-left: 14%;
  }
`;
const PageTitle = styled.div`
  color: ${colors.fullBlack};
  font-size: 28px;
  font-weight: 700;
  margin: 10px;
  text-align: left;
  margin-top: 10px;
  @media (max-width: ${Breakpoints.sm}px) {
    font-size: 24px;
    margin-top: 10px;
    text-align: center;
  }
  @media (max-width: ${Breakpoints.lg}px) {
    font-size: 24px;
    margin-top: 10px;
    text-align: center;
  }
`;
const ExpandWrapContainer = styled.div`
  background: ${colors.rebrandBlack};
  margin: 30px 5px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 95%;
  @media only screen and (max-width: ${Breakpoints.md}px) {
    padding: 40px 20px 40px 45px !important;
    margin: 5%;
  }
  @media only screen and (max-width: ${Breakpoints.lg}px) {
    padding: 40px 20px 40px 45px !important;
    margin: 5%;
    width: 85%;
    margin-right: 1px;
  }
`;
const ExpandWrapContainerTwo = styled.div`
 background: ${colors.rebrandBlack};
  margin: 30px 5px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 95%;
  border-radius: 12px;
  
  @media only screen and (max-width: ${Breakpoints.lg}px) {
    padding: 40px 20px 40px 45px !important;
    margin-left: 5%;
    margin-right: 1px;
    width: 85%;
    /* width: 100%; */
  }
`;
const Space = styled.div`
  margin-top: 40px;
  width: 10px;
  height: 10px;
`;
const Expand = styled.div`
  color: ${colors.rebrandYellow};
  display: inline-flex;
  align-items: center;
  position: relative;
  font-size: 16px;
  margin-top: 40px;
  cursor: Pointer;
  @media (max-width: ${Breakpoints.md}px){
    text-align: center;
  }
`;
export const ConfirmationNumber = styled.div`
   display: grid;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 20px;

  @media (max-width: ${Breakpoints.sm}px) {
    font-size: 18px;
  }
  @media (max-width: ${Breakpoints.md}px){
  /* font-size: 15px; */
  }
`;
const FlexboxRight = styled.div`
  float: left;
`;
const FlexboxCenter = styled.div`
  margin-right: 30%;
  margin-left: auto;
`;
const FlexboxLeft = styled.div`
  float: right;
  @media (max-width: ${Breakpoints.sm}px) {
    float: left;
    position: relative;
  }
`;
const ExpandRight = styled.div`
  display: grid;
  width: 85%;
  row-gap: 30px;
  position: relative;
  top: -50px;
  @media (max-width: 850px) {
    width: 100%;
    top: 0px;
  }
  @media (max-width: ${Breakpoints.sm}px) {
    top: 30px;
  }
`;
const ExpandRightUpcoming = styled.div`
  display: grid;
  width: 100%;
  row-gap: 30px;
  position: relative;
  top: -15px;
  @media (max-width: ${Breakpoints.sm}px) {
    top: 30px;
  }
`;
const ExpandLeft = styled.div``;
const ExpandCenter = styled.div``;
const ExpandVl = styled.div`
  border-left: 1px solid white;
  height: 100%;
`;
const ExpandIcon = styled.div`
  position: relative;
  color: ${colors.grey};
`;
const Expandtext = styled.div`
  font-size: 16px;
  font-weight: 500;
`;
const Text = styled.div`
  font-size: 16px;
  font-weight: normal;
`;
const ExpandWrapbutton = styled.div`
  display: flex;
  flex-direction: column;

  & > * {
    margin: 5px 0;
    background-color: white;
    border: 1px solid #2a5f87;
    border-radius: 32px;
    color: #2a5f87;
    padding: 4px 15px;
  }
`;
const WrapExpand = styled.div``;
const Expandrow1 = styled.div`
  display: flex;
  gap: 20px;
`;
const Datetag = styled.div`
  display: flex;
  gap: 20px;
`;
const Patientstag = styled.div`
  display: flex;
  gap: 20px;
`;
const Timetag = styled.div`
  display: flex;
  gap: 20px;
`;
const Testtag = styled.div`
  display: flex;
  gap: 20px;
`;
const PaymentMethod = styled.div`
  display: grid;
  row-gap: 30px;
`;
const Payment = styled.div`
  display: flex;
  gap: 20px;
  @media (max-width: ${Breakpoints.sm}px) {
    margin-bottom: 30px;
  }
`;
const DateAndPatient = styled.div`
  display: grid;
  row-gap: 30px;
`;
const TimeAndTest = styled.div`
  display: grid;
  row-gap: 30px;
`;
const ExapndlistTag = styled.div`
  display: flex;
  gap: 30px;
  @media (max-width: ${Breakpoints.md}px) {
    gap: 10px;
  }
  @media (max-width: ${Breakpoints.sm}px) {
    display: block;
  }
`;

const Hide = styled.div`
  margin-top: 20px;
  display: none;
`;
const WrapContainer = styled.div`
  background: ${colors.blue8};
  margin: 30px 10px;
  margin-bottom: 60px;
  width: 80%;
  height: 150px;
  @media (max-width: ${Breakpoints.sm}px) {
    width: 90%;
    height: 100px;
  }
`;
const WrapText = styled.div`
  color: black;
  font-size: 24px;
  font-weight: bold;
  text-align: left;
  @media (max-width: ${Breakpoints.sm}px) {
    font-size: 18px;
    text-align: center;
    line-height: 25px;
  }
`;
const WrapButton = styled.div`
  margin: 0 0 20px 25px;
  white-space: nowrap;
  align-items: center;
  border: 1px solid #4ab175;
  border-radius: 5px;
  font-size: 20px;
  padding: 15px 40px;
  font-weight: bold;
  height: 60px;
  width: 35%;
  cursor: pointer;
  background-color: #4ab175;
  color: #fff;
  box-shadow: 2px 5px #cbd0cb;
  @media (max-width: ${Breakpoints.sm}px) {
    margin: 0;
    font-size: 18px;
    display: flex;
    justify-content: center;
    padding: 15px;
    width: 100%;
  }
`;
const RowList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: ${Breakpoints.sm}px) {
    display: grid;
  }
`;
const InsuranceContainer = styled.div`
background: ${colors.white};
border: 0.5px solid rgba(36, 61, 77, 0.14);
margin: 0px 5px;
margin-bottom: 15px;
display: flex;
flex-direction: column;
justify-content: space-between;
width: 80%;
// Drop Shadow
box-shadow: -4px -4px 8px rgba(153, 153, 153, 0.05),
  4px 4px 8px rgba(0, 0, 0, 0.15);
border-radius: 12px;
padding: 30px 20px 30px 60px;
&:hover {
  background: #FDD56B;
}
@media (max-width: ${Breakpoints.sm}px) {
  padding: 40px 30px 50px 20px !important;
  margin: 0;
  left: 3%;
  position: relative;
  width: 90%;
  &:hover {
    background: none;
  }
}
@media (max-width: ${Breakpoints.lg}px) and (min-width: ${Breakpoints.sm}px) {
  left: 3%;
  width: 95%;
}
`;
const ButtonWrapExpand = styled.div`
  width: 60%;
  @media (max-width: ${Breakpoints.sm}px) {
    width: 100%;
  }
  @media (max-width: ${Breakpoints.md}px) and (min-width: ${Breakpoints.sm}px) {
    width: 80%;
  }
`;
const periods = getPeriodsFromSchedule(config.schedule);

function checkDisable(
  params: any,
  key: any,
  // print: any,
  cancel: any,
  enableEditAppointment: (data: any) => void,
  handleCancelApptOpen: () => void,
  // OpenCancelAppointment?: (data: any) => void
  setCancel: (data: any) => void
) {
  let disable: any = false;
  params.qbenchAcknowledgement.map((res: any) => {
    if (res.results === '' || res.results === null) {
      disable = false;
    } else {
      disable = true;
    }
  });
  return (
    <>
      <Button
        type="button"
        size="sm"
        libraryType="default"
        accentColor={colors.darkBlue}
        className='btn elevated-btn'
        disabled={disable}
        onClick={() => {
          enableEditAppointment(params);
        }}
      >
        Edit{' '}
      </Button>
      <Button
        type="button"
        size="sm"
        libraryType="default"
        accentColor={colors.darkBlue}
        disabled={disable}
        className='btn elevated-btn'
        onClick={() => {
          setCancel({ key: cancel.key == key ? -1 : key });
          handleCancelApptOpen();
          // OpenCancelAppointment({ key: print.key == key ? -1 : key });
        }}
      >
        Cancel{' '}
      </Button>
    </>
  );
}

const Appointmentpage: React.FC = () => {
  const [expand, setExpand] = useState({ key: -1 });
  const [expandone, setExpandone] = useState({ key: -1 });
  const user = JSON.parse(localStorage.getItem('user') || '');
  const [print, setPrint] = useState({ key: -1 });

  const [download, setDownload] = useState({ key: -1 });
  const [loadings, setLoadings] = useState(false);

  const printableRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => printableRef.current,
  });
  const [printFunction, setPrintFunction] = useState(handlePrint);

  let history = useHistory();

  const redirect = () => {
    history.push('/bookappointment');
  };
  const { userData, promos } = useSharedState();
  const [loading, setLoading] = useState(false);
  const { confirmationId } = useSharedState();
  const { updateEditConfirmationId, updateInsuranceList } = useSharedDispatch();
  const [upcomingAppointment, setUpcomingAppointment] = useState<
    AppointmentListItem[]
  >([]);
  const [pastAppointment, setPastAppointment] = useState<AppointmentListItem[]>(
    []
  );
  const [flag, setflag] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [selectedKey, setselectedKey] = useState(-1);
  const [results, setResults] = useState<[]>([]);
  const [npArray, setNpArray] = useState<any>([]);
  const [cancel, setCancel] = useState({ key: -1 });
  const { form } = useStepsState();
  const [ShowEditAppointmentModal, setShowEditAppointmentModal] =
    useState(false);
  const [OpenCancelAppointment, setOpenCancelAppointment] =
    React.useState<boolean>(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const allPromocodes =  promos?.filter((e:any) => e.isAmountReduce === true).map((ee:any) => ee.code)
  const employeePromocode = promos?.filter((e:any) =>  e.iswslEmployee).map((ee:any) => ee.code)
  let destination : any = form?.destination && (typeof(form?.destination) === "string" ? form?.destination : form?.destination?.value ?? 0)
  const npData = npArray.includes(destination) && form?.testSelection === 'flight'

  const getAppointmentsData = async () => {
    setLoading(true);
    const loggedUserData = JSON.parse(localStorage.getItem('user') || '');
    const resultData: any = await getQbenchOrderById({
      email:
        loggedUserData?.email !== undefined &&
        loggedUserData?.email !== null &&
        loggedUserData?.email !== ''
          ? loggedUserData.email
          : userData?.email,
    });
    const fetchedAppointment = resultData?.data?.['appointments'];
    let upcommingData: AppointmentListItem[] = [];
    let pastData: AppointmentListItem[] = [];
    fetchedAppointment.forEach((appt: AppointmentListItem) => {
      if (
        appt?.slot?.date &&
        appt?.results === '' &&
        (isAfter(
          parse(appt?.slot?.date, config.dateFormat, new Date()),
          new Date()
        ) ||
          isSameDay(
            parse(appt?.slot?.date, config.dateFormat, new Date()),
            new Date()
          ))
      ) {
        upcommingData.push(appt);
      } else {
        pastData.push(appt);
      }
    });
    upcommingData = await orderBy(upcommingData, ['slot.date'], ['asc']);
    pastData = await orderBy(pastData, ['slot.date'], ['desc']);
    setPastAppointment(pastData);
    setUpcomingAppointment(upcommingData);
    console.log('pastAppointment', pastAppointment);
    setLoading(false);
  };

  const getDestination = async () => {
    const arr: any = [];
    const response = await getNpDestionation({}).then((result: any) => {
      const data = result.data.data;
      data.forEach((element: any) => {
        arr.push(element.value);
      });
      console.log('values', arr);
    });
    setNpArray(arr);
  };
  // Get appointment results
  const getResult = useCallback(async (email: string) => {
    let data = { email };
    let resultData = await getAppointmentResults(data);
    if (resultData?.data?.status === 200) {
      setResults(resultData?.data?.data);
      console.log('result', results);
    } else {
      setResults([]);
    }
  }, []);

  const getInsurance = async () => {
    try {
      let updatedData = await getUserInsurance(userData?.email);

      if (updatedData?.data?.status === 200) {
        updateInsuranceList(updatedData?.data?.data);
      }
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAppointmentsData();
    getDestination();
    if (userData?.email) {
      getResult(userData?.email);
      getInsurance();
    } else {
      setResults([]);
    }
  }, [userData?.email]);

  const handleEditAppointment = () => {
    setShowEditAppointmentModal(false);
  };
  const handleCancelApptOpen = () => {
    setOpenCancelAppointment(true);
  };
  const handleCancelApptClose = () => {
    setOpenCancelAppointment(false);
  };
  const handleCancelReload = () => {
    setOpenCancelAppointment(false);
    getAppointmentsData();
  };
  const { editformdata }: any = useSharedState();
  // const { SetEditformdata } = useStepsDispatch();
  const enableEditAppointment = (value: any) => {
    updateEditConfirmationId(value.confirmationId);
    localStorage.setItem('Appointmentdata', JSON.stringify(''));
    localStorage.setItem('Appointmentdata', JSON.stringify(value));
    // SetEditformdata(value);
    history.push('/user/editAppointment');
  };

  const downloadResultPDF = async (model: any, key: number) => {
    setDownloadLoading(true);
    setselectedKey(key);
    if (model.qbenchId) {
      await downloadResultsPDF(model.sampleId);
    } else if (model.crelioId && model?.report?.reportBase64) {
      await downloadCrelioPdf(
        model.firstName + ' ' + model.lastName,
        model?.report?.reportBase64
      );
    }
    setselectedKey(-1);
    setDownloadLoading(false);
  };

  if (loading) {
    return <Loader />;
  }

  const onGenerateReceiptPdf = async (appointment: any, key?: any) => {
    setLoadings(true);
    const downloadData = await downloadReceipt(appointment);
    let receipt = '';
    if (downloadData.data.status === 200) {
      setLoadings(true);
      receipt = renderToString(
        <GenerateReceipt appointment={downloadData.data.data} />
      );

      const pdf = new jsPDF('portrait', 'pt', 'a4');

      pdf.html(receipt, {
        callback(doc) {
          doc.deletePage(2);
          doc.save('Patient Receipt');
        },
        x: 0,
        y: 0,
      });
      setLoadings(false);
    } else {
      setLoadings(false);
    }
  };

  return (
    <Mainpage>
      {/* <PageTitle>Result Tracker</PageTitle> */}
      {/* <ResultTracker /> */}
      {editformdata}
      {upcomingAppointment && <PageTitle>Upcoming appointments</PageTitle>}
      {upcomingAppointment?.length ? (
        <>
          {(upcomingAppointment || []).map((model: any, key: number) => (
            <ExpandWrapContainer
              key={key}
              style={{
                padding:
                  expand.key == key
                    ? '40px 20px 10px 60px'
                    : '40px 20px 40px 60px',
                borderRadius: '12px',
              }}
            >
              <RowList>
                <FlexboxRight style={{ color: '#FFFFFF' }}>
                  <Text>Confirmation #</Text>
                  <ConfirmationNumber>
                    {model?.confirmationId}
                  </ConfirmationNumber>
                  {!(expand.key == key) && (
                    <>
                      <Expandrow1>
                        <ExpandIcon>
                          <MdDateRange size={25} />
                        </ExpandIcon>
                        <Expandtext>
                          {' '}
                          <strong> Date</strong>
                          <br />
                          {model?.slot?.date
                            ? format(
                                new Date(model?.slot?.date),
                                'MMMM dd,yyyy'
                              )
                            : ''}
                          <br />
                        </Expandtext>
                      </Expandrow1>
                    </>
                  )}
                </FlexboxRight>
                <FlexboxCenter>
                  <Expand
                    key={key}
                    onClick={() => {
                      setExpand({ key: expand.key == key ? -1 : key });
                      setPrint({ key: print.key == key ? -1 : key });
                    }}
                  >
                    {expand.key == key ? (
                      <>
                        Hide details <MdExpandLess size={20} />
                      </>
                    ) : (
                      <>
                        Show details <MdExpandMore size={20} />
                      </>
                    )}
                  </Expand>
                </FlexboxCenter>
                <FlexboxLeft>
                  <ExpandWrapbutton>
                    {flag && (
                      <PDFDownloadLink
                        document={
                          <DownloadableConfirmatiom
                            form={model}
                            periods={periods}
                          />
                        }
                        fileName="worksite-labs-confirmation.pdf"
                      >
                        {({ loading }) =>
                          loading ? <>Loading...</> : <>Download</>
                        }
                      </PDFDownloadLink>
                    )}

                    <Button
                      type="button"
                      size="sm"
                      libraryType="default"
                      accentColor={colors.darkBlue}
                      key={key}
                      className='btn elevated-btn'
                      onClick={() => {
                        setPrintFunction(handlePrint);

                        setPrint({ key: print.key == key ? -1 : key });
                      }}
                    >
                      Print
                    </Button>
                    {print.key == key && (
                      <>
                        <Hide>
                          {' '}
                          <PrintableConfirmation
                            form={model}
                            ref={printableRef}
                            periods={periods}
                          />{' '}
                        </Hide>
                      </>
                    )}
                    {checkDisable(
                      model,
                      key,
                      // print,
                      cancel,
                      enableEditAppointment,
                      handleCancelApptOpen,
                      setCancel
                    )}
                    {/* <Button
                      type="button"
                      size="sm"
                      libraryType="default"
                      accentColor={colors.darkBlue}
                      onClick={() => {
                        enableEditAppointment(model);
                      }}
                    >
                      Edit{' '}
                    </Button>
                    <Button
                      type="button"
                      size="sm"
                      libraryType="default"
                      accentColor={colors.darkBlue}
                      onClick={handleCancelApptOpen}
                    >
                      Cancel{' '}
                    </Button> */}
                    {/* <Dialog
                      fullScreen={fullScreen}
                      open={OpenCancelAppointment}
                      onClose={handleCancelApptClose}
                    >
                      <CancelAppointmentModal
                        handleCancelApptClose={handleCancelApptClose}
                        form={model}
                      />
                    </Dialog> */}
                    {cancel.key == key && (
                      <CancelApptModal
                        open={OpenCancelAppointment}
                        handleCancelApptClose={handleCancelApptClose}
                        form={model}
                        handleCancelReload={handleCancelReload}
                        setCancel={setCancel}
                      />
                    )}
                  </ExpandWrapbutton>
                </FlexboxLeft>
              </RowList>
              {expand.key == key && (
                <WrapExpand style={{ color: '#ffff' }}>
                  <ExpandRight>
                    <Expandrow1>
                      <ExpandIcon>
                        <IoLocationOutline size={25} />
                      </ExpandIcon>
                      <Expandtext>
                        <strong>{model?.location?.name}</strong>
                        <br />
                        {model?.location?.address1} {model?.location?.address2}
                        <br />
                      </Expandtext>
                    </Expandrow1>
                    <ExapndlistTag>
                      <DateAndPatient>
                        <Datetag>
                          <ExpandIcon>
                            <MdDateRange size={25} />
                          </ExpandIcon>
                          <Expandtext>
                            {' '}
                            <strong> Date</strong>
                            <br />
                            {model?.date
                              ? format(new Date(model?.date), 'MMMM dd,yyyy')
                              : ''}
                            <br />
                          </Expandtext>
                        </Datetag>
                        <Patientstag>
                          <ExpandIcon>
                            <IoPersonOutline size={25} />
                          </ExpandIcon>
                          <Expandtext>
                            {' '}
                            <strong> Patients</strong>
                            <br />
                            {model.registeringFor !== 'minorOnly' && (
                              <>
                                {model?.firstName}{' '}
                                {model?.middleName ? model?.middleName : ''}{' '}
                                {model?.lastName}
                              </>
                            )}
                            {model.minors
                              .filter((minor: any) => !minor.isCanceled)
                              .map((res: any) => {
                                return (
                                  <>
                                    <div>
                                      {res.firstName} {res.lastName}
                                    </div>
                                  </>
                                );
                              })}
                          </Expandtext>
                        </Patientstag>
                      </DateAndPatient>

                      <ExpandCenter>
                        <ExpandVl></ExpandVl>
                      </ExpandCenter>

                      <TimeAndTest>
                        <Timetag>
                          <ExpandIcon>
                            {' '}
                            <BiTimeFive size={25} />
                          </ExpandIcon>
                          <Expandtext>
                            {' '}
                            <strong> Time</strong> <br />
                            {model?.slot &&
                              (model?.slot?.label ||
                                periods[model?.slot.period]?.label)}
                            <br />{' '}
                          </Expandtext>
                        </Timetag>
                        <Testtag>
                          <ExpandIcon>
                            {' '}
                            <IoPeopleOutline size={25} />
                          </ExpandIcon>
                          <Expandtext>
                            {' '}
                            <strong>Test</strong> <br />
                            {model.isAntigen || model.isPanel  ? 'Antigen' : 'COVID-19 RT-PCR'}
                            <br />
                          </Expandtext>
                        </Testtag>
                      </TimeAndTest>
                      <ExpandCenter>
                        <ExpandVl></ExpandVl>
                      </ExpandCenter>

                      <TimeAndTest>
                        <Timetag>
                          <ExpandIcon>
                            {' '}
                            <BiDollarCircle size={25} />
                          </ExpandIcon>
                          <Expandtext>
                            {' '}
                            <strong> Payment Method</strong> <br />
                            <div>
                              {' '}
                              {model?.payingMethod == 'Out of pocket'
                                  ? model?.testSelection == 'school'
                                    ? 'No Insurance'
                                    : 'Credit Card'
                                  : (model?.payingMethod == 'Credit Card' ||
                                    model?.payingMethod == 'Credit card') && model?.promoCode !== ''
                                    && model?.amount !== model?.discountAmount || (employeePromocode?.includes(model?.promoCode) && model?.minors?.length > 0)
                                    ? `${model?.promoCode} VOUCHER and ${model?.payingMethod}` :
                                    (model?.payingMethod == 'Credit Card' ||
                                      model?.payingMethod == 'Credit card') &&
                                      includes(allPromocodes, model?.promoCode)
                                      ? `${model?.promoCode} VOUCHER`
                                      : model?.payingMethod
                              }
                            </div>
                            <br />{' '}
                          </Expandtext>
                        </Timetag>
                        <Testtag>
                          <ExpandIcon>
                            {' '}
                            <IoPersonAddOutline size={25} />
                          </ExpandIcon>
                          <Expandtext>
                            {' '}
                            <strong>Test Type</strong> <br />
                            {model.isRapidTest
                              ? 'Rapid PCR Test'
                              : model.isExpressSameDayTest
                              ? 'Express PCR Test'
                              : model.isAntigen
                              ? 'Antigen Test'
                              : model.isPanel
                              ? 'Flu A&B/Covid-19'
                              : 'Standard PCR Test'}
                            <br />{' '}
                          </Expandtext>
                        </Testtag>
                        {/* <FaFileInvoiceDollar /> */}
                      </TimeAndTest>
                    </ExapndlistTag>
                  </ExpandRight>
                </WrapExpand>
              )}
            </ExpandWrapContainer>
          ))}
        </>
      ) : (
        <>
          {' '}
          <InsuranceContainer
           style={{
            left: '0px',
            border: '0.5px solid rgba(36, 61, 77, 0.14)',
            /* Drop Shadow */
            boxShadow:
              '-4px -4px 8px rgba(153, 153, 153, 0.05), 4px 4px 8px rgba(0, 0, 0, 0.15)',
            borderRadius: '12px',
          }}
          >
            <WrapText>{'You have no upcoming appointments'}</WrapText>
            <br/>
            <ButtonWrapExpand>
              <Button
                libraryType="primary"
                type="submit"
                className="fill-bookAppt-btn"
                disabled={false}
                onClick={redirect}
              >
                {'Book An Appointment Now'}
              </Button>
            </ButtonWrapExpand>
          </InsuranceContainer>
          <Space />
        </>
      )}
      {pastAppointment && <PageTitle>Previous appointments</PageTitle>}
      {pastAppointment?.length ? (
        <>
          {(pastAppointment || []).map((model: any, key: number) => (
            <div>
              <ExpandWrapContainerTwo
                key={key}
                style={{
                  padding:
                    expandone.key == key
                      ? '40px 20px 10px 60px'
                      : '40px 20px 40px 60px',
                }}
              >
                <RowList>
                  <FlexboxRight style={{ color: '#FFFFFF' }}>
                    <Text>Confirmation #</Text>
                    <ConfirmationNumber>
                      {model?.confirmationId}
                    </ConfirmationNumber>
                    {!(expandone.key == key) && (
                      <>
                        <Expandrow1>
                          <ExpandIcon>
                            <MdDateRange size={25} />
                          </ExpandIcon>
                          <Expandtext>
                            {model?.slot?.date
                              ? format(
                                  new Date(model?.slot?.date),
                                  'MMMM dd,yyyy'
                                )
                              : ''}
                            <br />
                          </Expandtext>
                        </Expandrow1>
                      </>
                    )}
                  </FlexboxRight>
                  <FlexboxCenter>
                    <Expand
                      onClick={() => {
                        setExpandone({
                          key: expandone.key == key ? -1 : key,
                        });
                      }}
                    >
                      {expandone.key == key ? (
                        <>
                          Hide details <MdExpandLess size={20} />
                        </>
                      ) : (
                        <>
                          Show details <MdExpandMore size={20} />
                        </>
                      )}
                    </Expand>
                  </FlexboxCenter>
                  <FlexboxLeft>
                    <ExpandWrapbutton>
                      {(results || []).map(
                        (resultModel: any, resultsKey: number) => (
                          <>
                            {resultModel?.confirmationId ===
                              model?.confirmationId && (
                              <Button
                                type="button"
                                size="sm"
                                libraryType="default"
                                accentColor={colors.darkBlue}
                                className='btn elevated-btn'
                                onClick={async () => {
                                  downloadResultPDF(resultModel, resultsKey);
                                }}
                              >
                                {downloadLoading
                                  ? 'Processing...'
                                  : 'Download Results'}
                              </Button>
                            )}
                          </>
                        )
                      )}

                      <Button
                        type="button"
                        size="sm"
                        libraryType="default"
                        accentColor={colors.darkBlue}
                        className='btn elevated-btn'
                        onClick={() => {
                          setDownload({ key: download.key === key ? -1 : key });
                          onGenerateReceiptPdf(model, key);
                        }}
                      >
                        {loadings && download.key === key
                          ? 'downloading...'
                          : 'Download Receipt'}{' '}
                      </Button>
                    </ExpandWrapbutton>
                  </FlexboxLeft>
                </RowList>
                {expandone.key == key && (
                  <WrapExpand style={{ color: '#ffff' }}>
                    <ExpandRightUpcoming>
                      <Expandrow1>
                        <ExpandIcon>
                          <IoLocationOutline size={25} />
                        </ExpandIcon>
                        <Expandtext>
                          <strong>{model?.location?.name}</strong>
                          <br />
                          {model?.location?.address1}{' '}
                          {model?.location?.address2}
                          <br />
                        </Expandtext>
                      </Expandrow1>

                      <ExapndlistTag>
                        <DateAndPatient>
                          <Datetag>
                            <ExpandIcon>
                              <MdDateRange size={25} />
                            </ExpandIcon>
                            <Expandtext>
                              {' '}
                              <strong> Date</strong>
                              <br />
                              {model?.date
                                ? format(new Date(model?.date), 'MMMM dd,yyyy')
                                : ''}
                              <br />
                            </Expandtext>
                          </Datetag>
                          <Patientstag>
                            <ExpandIcon>
                              <IoPersonOutline size={25} />
                            </ExpandIcon>
                            <Expandtext>
                              {' '}
                              <strong> Patients</strong>
                              <br />
                              {model.registeringFor !== 'minorOnly' && (
                                <>
                                  {model?.firstName}{' '}
                                  {model?.middleName ? model?.middleName : ''}{' '}
                                  {model?.lastName}
                                </>
                              )}
                              {model.minors
                                .filter((minor: any) => !minor.isCanceled)
                                .map((res: any) => {
                                  return (
                                    <>
                                      <div>
                                        {res.firstName} {res.lastName}
                                      </div>
                                    </>
                                  );
                                })}
                            </Expandtext>
                          </Patientstag>
                        </DateAndPatient>

                        <ExpandCenter>
                          <ExpandVl></ExpandVl>
                        </ExpandCenter>

                        <TimeAndTest>
                          <Timetag>
                            <ExpandIcon>
                              {' '}
                              <BiTimeFive size={25} />
                            </ExpandIcon>
                            <Expandtext>
                              {' '}
                              <strong> Time</strong> <br />
                              {model?.slot &&
                                (model?.slot.label ||
                                  periods[model?.slot.period].label)}
                              <br />{' '}
                            </Expandtext>
                          </Timetag>
                          <Testtag>
                            <ExpandIcon>
                              {' '}
                              <IoPeopleOutline size={25} />
                            </ExpandIcon>
                            <Expandtext>
                              {' '}
                              <strong>Test</strong> <br />
                              {model.isAntigen || model.isPanel  ? 'Antigen' : 'COVID-19 RT-PCR'}
                              <br />
                            </Expandtext>
                          </Testtag>
                        </TimeAndTest>

                        <ExpandCenter>
                          <ExpandVl></ExpandVl>
                        </ExpandCenter>

                        <TimeAndTest>
                          <PaymentMethod>
                            <Payment>
                              <ExpandIcon>
                                {' '}
                                <BiDollarCircle size={25} />
                              </ExpandIcon>
                              <Expandtext>
                                {' '}
                                <strong> Payment Method</strong> <br />
                                {console.log('paying method', model)}
                                <div>
                                  {' '}
                                  {model?.payingMethod == 'Out of pocket'
                                      ? model?.testSelection == 'school'
                                        ? 'No Insurance'
                                        : 'Credit Card'
                                      : (model?.payingMethod == 'Credit Card' ||
                                        model?.payingMethod == 'Credit card') && model?.promoCode !== ''
                                        && model?.amount !== model?.discountAmount || (employeePromocode?.includes(model?.promoCode) && model?.minors?.length > 0)
                                        ? `${model.promoCode} VOUCHER and ${model?.payingMethod}` :
                                        (model.payingMethod == 'Credit Card' ||
                                          model.payingMethod == 'Credit card') &&
                                          includes(allPromocodes, model?.promoCode)
                                          ? `${model?.promoCode} VOUCHER`
                                          : model?.payingMethod
                                  }
                                </div>
                                <br />{' '}
                              </Expandtext>
                            </Payment>
                          </PaymentMethod>

                          <Testtag>
                            <ExpandIcon>
                              {' '}
                              <IoPersonAddOutline size={25} />
                            </ExpandIcon>
                            <Expandtext>
                              {' '}
                              <strong>Test Type</strong> <br />
                              {model.isRapidTest
                                ? 'Rapid PCR Test'
                                : model.isExpressSameDayTest
                                ? 'Express PCR Test'
                                : model.isAntigen
                                ? 'Antigen Test'
                                : model.isPanel
                                ? 'Flu A&B/Covid-19'
                                : 'Standard PCR Test'}
                              <br />{' '}
                            </Expandtext>
                          </Testtag>
                        </TimeAndTest>
                        <ExpandLeft></ExpandLeft>
                      </ExapndlistTag>
                    </ExpandRightUpcoming>

                    <ExpandLeft></ExpandLeft>
                  </WrapExpand>
                )}
              </ExpandWrapContainerTwo>
            </div>
          ))}{' '}
        </>
      ) : (
        <>
          {' '}
          <InsuranceContainer>
            <WrapText>{'You have no previous appointments'}</WrapText>
            {/* <ButtonWrapExpand>
              <Button
                libraryType="primary"
                type="submit"
                disabled={false}
                style={{
                  backgroundColor: '#F29A4E',
                  border: '1px solid #F29A4E',
                }}
                onClick={redirect}
              >
                {'Book Appointment'}
              </Button>
            </ButtonWrapExpand> */}
          </InsuranceContainer>
          <Space />
        </>
      )}
    </Mainpage>
  );
};

export default Appointmentpage;

//ltst
