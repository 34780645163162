import React, {useEffect} from 'react';
import { Formik, Form, FormikErrors, FormikValues } from 'formik';
import styled from 'styled-components';
import { Breakpoints } from '../../../../dictionaries';

import {
  Container,
  Content,
  PageHeader,
  SubHeaderDescription,
} from '../../../shared/components/styled';
// import { useStepsDispatch } from '../../provider';
import { colors } from '../../../../styles/colors';
import {useStepsState, useStepsDispatch} from '../../../schedule/provider'


import YesNoButtonGroup from '../../../shared/components/form/YesNoButtonGroup';
import NavigationButtons from '../../../shared/components/NavigationButtons';
import SymptomsCheckboxes from './SymptomsCheckboxes';
import mixpanel from 'mixpanel-browser';
import GoogleAnalytics from '../../../../GoogleAnalytics';

const InfoContainer = styled.div`
  background: ${colors.blue8};
  padding: 20px;

  h3 {
    color: rgba(38, 38, 38, 0.8);
    margin-bottom: 15px;
    font-weight: 700;
  }

  p {
    margin-bottom: 15px;
  }

  i {
    font-size: 15px;
  }

  strong {
    font-weight: 600;
  }
`;
export const PageTitle = styled.h2`
  color: ${colors.fullBlack};
  font-size:  28px ;
  font-weight: 700;
  margin: 0;
  text-align: left;

  @media (max-width: ${Breakpoints.sm}px) {
    font-size: 25px;
    margin-top: 30%;
  }

`;


const Symptoms: React.FC = () => {
  const {
    form: { symptoms, hasSymptoms },form
  } = useStepsState();
  const { updateFormValues, goToNextStep, goToPrevStep } = useStepsDispatch();

  useEffect(() => {
    window.scrollTo(0, 0)
    GoogleAnalytics(form,'/Symptoms','Symptoms');
  }, [])
  
  return (
    <Container>
      <Content>
        <PageHeader>
        <PageTitle>Do you have any of the following symptoms?</PageTitle>
        </PageHeader>
        <Formik
          initialValues={{
            hasSymptoms,
            symptoms,
          }}
          onSubmit={(values) => {
            updateFormValues(values);
            mixpanel.track("symptoms page");
            goToNextStep();
          }}
          validate={(values) => {
            const errors: FormikErrors<FormikValues> = {};

            if (values.hasSymptoms && values.symptoms.length === 0) {
              errors.hasSymptoms = 'Please choose at least one symptom';
            }

            return errors;
          }}
        >
          {({ values, setFieldValue }) => {
            return (
              <Form>
                <YesNoButtonGroup
                  name="hasSymptoms"
                  onChange={(answer) => {
                    updateFormValues({ hasSymptoms: answer });

                    if (!answer && values.symptoms.length > 0) {
                      updateFormValues({ symptoms: [] });
                      setFieldValue('symptoms', []);  
                    }
                    if(!answer) goToNextStep();
                  }}
                  additionalContent={<SymptomsCheckboxes />}
                />

                <NavigationButtons onReturnButtonClick={() => { goToPrevStep(); }} isValid={hasSymptoms !== null ? true : false} />
              </Form>
            );
          }}
        </Formik>
      </Content>
    </Container>
  );
};

export default Symptoms;

              /*  <InfoContainer>
                  <h3>When to seek emergency medical attention</h3>
                  <p>
                    Look for <strong>emergency warning signs*</strong> for
                    COVID-19. If someone is showing any of these signs,{' '}
                    <strong>seek emergency medical care immediately</strong>:
                  </p>
                  <ul>
                    <li>Trouble breathing</li>
                    <li>Persistent pain or pressure in the chest</li>
                    <li>New confusion</li>
                    <li>Inability to wake or stay awake</li>
                    <li>Bluish lips or face</li>
                  </ul>
                  <p>
                    <i>
                      *This list is not all possible symptoms. Please call your
                      medical provider for any other symptoms that are severe or
                      concerning to you.
                    </i>
                  </p>
                  <p>
                    <strong>
                      Call 911 or call ahead to your local emergency facility:
                    </strong>{' '}
                    Notify the operator that you are seeking care for someone
                    who has or may have COVID-19.
                  </p>
                </InfoContainer> */