import React, { useState } from 'react';
import styled from 'styled-components';
import { Breakpoints } from '../../../../../../dictionaries';
import { Form, Formik } from 'formik';
import Input from '../../../../../shared/components/form/Input';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import ConfirmationPasswordModal from './ConfirmationPasswordModal';
import { changEmailPassword } from '../../../../api';
import { colors } from '../../../../../../styles/colors';
import {
  useSharedDispatch,
  useSharedState,
} from '../../../../../shared/provider';
import { Button } from './../../../../../shared/components/styled';
import Modal from './../../../../../shared/components/Modal';
import {useHistory } from 'react-router-dom';

const Container = styled.div``;
const Title = styled.h2`
  color: ${colors.fullBlack};
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 25px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 240px;
  margin: 0 -5px;

  & > * {
    margin: 0 5px;
  }
  @media (max-width: ${Breakpoints.sm}px) {
    display: grid;
    row-gap: 10px;
    margin-top: 30px;
  }
`;
const Text = styled.div`
  text-align: left;
  font-size: 15px;
  font-weight: 500;
  margin: 0px 25px;
`;
const SubTitle = styled.div`
  font-size: 16px;
  font-style: italic;
  margin-bottom: 10px;
  color: rgba(0, 0, 0, 0.54);
  font-weight: bold;
`;
const SubContainer = styled.div`
  margin: 20px 25px;
`;
const Error = styled.div`
  color: ${colors.red};
  margin-bottom: 30px;
  text-align: center;
`;
const Space = styled.div`
  @media (max-width: ${Breakpoints.sm}px) {
    margin-top: 35px;
  }
`;
type Props = {
  open: boolean;
  handlePasswordClose: () => void;
};
const ChangePasswordModal: React.FC<Props> = ({
  open,
  handlePasswordClose,
}) => {
  const [loading, setLoading] = useState(false);
  const [isVerifyScreen, setVerifyScreen] = useState(false);
  const handleVerifyScreen = () => {
    setVerifyScreen(false);
  };
  const [error, setError] = useState<string | null>(null);
  const [errorMsg, setErrorMsg] = useState<boolean>(false);
  let history = useHistory();

  const { userData } = useSharedState();
  const [isLoginScreen, setLoginScreen] = useState(true);

  const [validLength, setValidLength] = useState(false);
  const [upperCase, setUpperCase] = useState(false);
  const [lowerCase, setLowerCase] = useState(false);
  const [specialChar, setSpecialChar] = useState(false);
  const [requiredLength, setRequiredLength] = useState(8);

  const [
    openConfirmPasswordDialog,
    setOpenConfirmPasswordDialog,
  ] = React.useState<boolean>(false);
  const handleConfirmPasswordOpen = () => {
    setOpenConfirmPasswordDialog(true);
  };
  const handleConfirmPasswordClose = () => {
    setOpenConfirmPasswordDialog(false);
  };

  const handleSubmit = async (data: any) => {
    setLoading(true);

    try {
      let emailPwdChange = await changEmailPassword(data);

      if (emailPwdChange?.data?.status === 200) {
        setErrorMsg(false);
        setVerifyScreen(true);
      } else {
        setError(emailPwdChange?.data?.message);
        setErrorMsg(true)
        setVerifyScreen(false);
      }
    } catch (e) {
      setError('Enter details correctly');
      setVerifyScreen(false);
    }

    setLoading(false);
  };
  const validatePassword = (password: string) => {
    let error: string = '';

    if (password) {
      setValidLength(password.length >= requiredLength ? true : false);
      setUpperCase(password.toLowerCase() !== password);
      setLowerCase(password.toUpperCase() !== password);
      setSpecialChar(/[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(password));
      if (validLength && upperCase && lowerCase && specialChar) {
        error = '';
        // setVerifyScreen(true);
      } else {
        error =
          ' 8 character minimum, 1 uppercase, one lowercase, one special character ';
        // setVerifyScreen(false);
      }
    }

    return error;
  };

  const validateConfirmPassword = (
    password: string,
    confirmPassword: string
  ) => {
    let error: string = '';

    if (password && confirmPassword) {
      if (password !== confirmPassword) {
        error = 'Password not matched';
        setLoginScreen(false);
      }
      setLoginScreen(true);
    }
    return error;
  };
  return (
    <div>
      {!isVerifyScreen ? (
        <>
          <Modal open={open} onClose={handlePasswordClose} maxWidth={800} blockScroll={false}>
            <Container>
              <Title>
                <AiOutlineQuestionCircle size={22} color={'#000'} />
                Change your password
              </Title>
              <Text>
                Please enter your current password, then enter a new password below.
              </Text>
              <SubContainer>
                <SubTitle>Current Password</SubTitle>
                <Formik
                  initialValues={{
                    email: userData?.email,
                    password: '',
                    newPassword: '',
                    confirmPassword: '',
                  }}
                  onSubmit={(values) => {
                    if (
                      values.newPassword === values.confirmPassword &&
                      values.email
                    ) {
                      if (values.newPassword === values.password) {
                        setError(
                          'Entered password and new password both are same'
                        );
                      } else {
                        handleSubmit(values);
                      }
                    } else {
                      setError(
                        'New Password does not match with confirm password'
                      );
                    }
                  }}
                  enableReinitialize={false}
                >
                  {() => (
                    <Form>
                      <Input
                        name="password"
                        placeholder="Current Password"
                        isRequired
                        type="password"
                      />
                      <Space />
                      <SubTitle>New Password</SubTitle>
                      <Input
                        name="newPassword"
                        placeholder="New Password"
                        isRequired
                        type="password"
                        validate={(value) => validatePassword(value)}
                      />
                      <Space />
                      <Input
                        name="confirmPassword"
                        placeholder="Confirm New Password"
                        isRequired
                        type="password"
                      />
                      {error && !errorMsg && <Error>{error}</Error>}
                      {errorMsg && <Error>{error} {"If you forget your password,"} {<a  onClick={()=>{ history.push("/forgotpassword") }}>click here</a>}  </Error>}

                      <ButtonsContainer>
                        <Button
                          libraryType="default"
                          size="sm"
                          type="button"
                          onClick={() => {
                            setVerifyScreen(false);
                            handlePasswordClose();
                            setError(null);
                          }}
                          className='btn elevated-btn'
                        >
                          Cancel
                        </Button>
                        <Button
                          libraryType="primary"
                          size="sm"
                          type="submit"
                          onClick={() => {
                            handleConfirmPasswordOpen();
                          }}
                          className='btn fill-btn'
                        >
                          {loading ? 'Processing...' : 'Confirm'}
                        </Button>
                      </ButtonsContainer>
                    </Form>
                  )}
                </Formik>
              </SubContainer>
            </Container>
          </Modal>
        </>
      ) : (
        <ConfirmationPasswordModal
          open={openConfirmPasswordDialog}
          handlePasswordClose={handlePasswordClose}
          handleVerifyScreen={handleVerifyScreen}
        />
      )}
    </div>
  );
};

export default ChangePasswordModal;
