import React, {
  useCallback,
  useState,
  createContext,
  useMemo,
  useEffect,
} from 'react';
import { nanoid } from 'nanoid';

import { AnyObject } from '../shared/models';
import { ScheduleDispatch, ScheduleState } from './models';
import { steps } from './components/Steps';
import { getPrices } from './api';
import { config } from '../../config';
import { getSiteHostName, getHostName, gettraveltype, getdestination, getdistrict, getAddress, SetPrices, getRefersion, gettenant } from '../../utils';
// import { getSlotsConfigurations } from "../shared/api";
import { testSelectionSteps } from "../register/components/AppointmentSteps";
import { useHistory } from 'react-router';
import { useSharedDispatch } from '../shared/provider';
import { getQbenchacknowledgementBySampleId, loginForFreeTest } from '../shared/api';

const initialFormState = {
  id: `${nanoid()}-${Date.now()}`,
  phone: '',
  sendMessagesAboutTestResults: false,
  location: null,
  date: null,
  slot: null,
  address: {
    zipCode: '',
    address: '',
    city: '',
    state: '',
    county: '',
    country:'United States',
  },
  employeeId:'',
  firstName: '',
  lastName: '',
  middleName: '',
  isINTNameFormat: false,
  birthDate: null,
  hasMinors: false,
  minors: [],
  hasSymptoms: null,
  symptoms: [],
  email: '',
  password: '',
  confirmEmail: '',
  hasConditions: null,
  hadContact: null,
  sex: null,
  gender: null,
  sexualOrientation: null,
  race: null,
  ethnicity: null,
  isHACustomer: false,
  hipaaConfirmed: false,
  consentForTesting: false,
  insuranceForTesting: false,
  commitToAttend: false,
  agreeToCancel: false,
  confirmationId: '',
  departureDateAndTime: null,
  isExpressSameDayTest: false,
  isRapidTest: false,
  IsAirline: true,
  airlineCode: '',
  travelType: '',
  destination: null,
  payingMethod: '',
  socialSecurityNumber: '',
  driverLicenseNumber: '',
  hasInsurance: null,
  insurance: {
    id: '',
    insuranceId: '',
    email: '',
    group: '',
    subscriberFirstName: '',
    subscriberLastName: '',
    frontCard: '',
    backCard: '',
    payerList: '',
    newCarrierName:''
  },
  guardian: {
    firstName: '',
    lastName: '',
    relationship: '',
    phone: '',
    email: '',
  },
  govtId:{
    id:'',
    email:'',
    frontCard: '',
    backCard: '',
  },
  registeringFor: '',
  confirmPassword: '',
  postCode: '',
  hasAgreement: false,
  hasMarketCommuni: false,
  userSelect: false,
  reservationId: null,
  reservationCount: 0,
  rescheduleReservationId: '',
  testSelection: '',
  cPartnerID: '',
  district: '',
  school: '',
  studentID: '',
  isNotHavePermanentAddress: false,
  schoolTestFor: '',
  higherCost: false,
  commitToConfirm: false,
  testReason: '',
  vaccineType: '',
  isVaccinated: '',
  couponId: '',
  promoCode: '',
  discountAmount: 0,
  signatureURL:'',
  isAntigen:false,
  isWalkUpTest: false,
  paymentMethod:"",
  isHubSpotContact: undefined,
  rebooking: {
    active: false,
    sampleId: 0,
    isRebook: false,
    isLoading: false
  },
  testType:''
};

export const newMinor = {
  firstName: '',
  lastName: '',
  birthDate: null,
  relationship: '',
  passportCountry: '',
  passportNo: '',
  school: '',
  studentID: '',
  schoolTestFor: '',
};

export const StepsContext = createContext<ScheduleState>({
  form: initialFormState,
  step: 0,
  showChangeLocationModal: false,
  showAlertModal: false,
  showDepartureTime: false,
  slotsList: [],
  prices: {
    standard: 0,
    expedited: 0,
    rapid: 0,
    standard_INT: 0,
    expedited_INT: 0,
    rapid_INT: 0,
    antigen: 0,
    antigen_INT: 0
  },
  configured: false,
  airline: '',
  isLoginScreen: false,
  // reservationId: "",
  // reservationCount: 0
  blockOutDays:[],
  rebooking: {
    active: false,
    sampleId: 0,
    isRebook:false,
    isLoading:false
  }
});

export const StepsDispatchContext = createContext<ScheduleDispatch>({
  goToNextStep() { },
  goToSkipStep() { },
  goToSkip2Step() { },
  goToSkip3Step() { },
  goToSkip4Step() { },
  goToPrevStep() { },
  updateFormValues() { },
  updatesFormValues() { },
  toggleChangeLocationModal() { },
  toggleShowAlertModal() { },
  updateSlotsList() { },
  toggleShowDepartureDateTimeModal() { },
  updateAirlineType() { },
  updateLoginScreen() { },
  // addReservationId() { },
  // addReservationCount() { }
  goToSkipPrevStep() { },
  clearFormValues(){},
  updateBlockOutDays(){},
  goToStep(){},
  rebookingLoader() { }
});

export const ScheduleProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const history = useHistory();
  const tenant: string | null = gettenant() || null;
  const [blockOutDays, setBlockOutDays] = useState<Number[]>([])
  const [form, setForm] = useState(initialFormState);
  const district: string | null = getdistrict() || null;
  const [step, setStep] = useState(()=>{
    let data = localStorage.getItem('testSelection')==='true';
    if(history.location.pathname==='/')
    {
      return 0;
    }
    else
    {
      if(data || district === 'LLESD' || district === 'llesd')
        return 1;
        if (data || tenant === 'KERN' || tenant === 'kern' || tenant==='Kern')
        return 1;
      else
        return 0;
    }
  });
  const {
    setloggedUserData
  } = useSharedDispatch();
  const [showChangeLocationModal, setShowChangeLocationModal] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [slotsList, setSlotsList] = useState([]);
  const [showDepartureTime, setShowDepartureDateTimeModal] = useState(false);
  const [prices, setPrices] = useState({
    standard: 0,
    expedited: 0,
    rapid: 0,
    standard_INT: 0,
    expedited_INT: 0,
    rapid_INT: 0,
    antigen: 0,
    antigen_INT: 0
  });
  const [configured, setConfigured] = useState(false);
  const [airline, setAirline] = useState('');
  const [isLoginScreen, setLoginScreen] = useState(false);
  // const [reservationId, setReservationId] = useState<string>("");
  // const [reservationCount, setReservationCount] = useState<number>(0);
  const [rebooking, setRebooking] = useState({
    active: parseInt(Object.fromEntries(new URLSearchParams(window.location.search).entries())?.rebooking ?? 0) > 0 ? true :false,
    sampleId: parseInt(Object.fromEntries(new URLSearchParams(window.location.search).entries())?.rebooking ?? 0),
    isRebook:false,
    isLoading:true,
  })

  const freeTestData = async (param: any) => {
    try {
      const response: any = await getQbenchacknowledgementBySampleId(param)
      const sampleDetails: any = response.data[0];
      if (response.data.length===1 && !sampleDetails.isRebooked && sampleDetails?.results==='INDETERMINATE') {
        setRebooking({
          ...rebooking,active : parseInt(Object.fromEntries(new URLSearchParams(window.location.search).entries())?.rebooking ?? 0) > 0 ? true :false
        })
        const loginUser: any = await loginForFreeTest(sampleDetails.email)
        console.log('loginUser.data[0]',loginUser,loginUser.data[0])
        const res:any=loginUser.data[0]
        if (loginUser.data.length) {
          setloggedUserData(res)
          localStorage.setItem('user', JSON.stringify(res));
          if (sampleDetails.minorIndex === -1) {
            updateFormValues({ ...sampleDetails ,registeringFor:'myself',previousPayment: sampleDetails?.payingMethod.toLowerCase(), minors: [], slot: null, date: '', payingMethod: 'No Payment',paymentIntentId:null,promoCode:''})
          } else {
            updateFormValues({ ...sampleDetails,registeringFor:'minorOnly' ,previousPayment: sampleDetails?.payingMethod.toLowerCase(), slot: null, minors: [sampleDetails.minors[sampleDetails.minorIndex]], date: '', payingMethod: 'No Payment',paymentIntentId:null,promoCode:'' })
          }
          // goToNextStep();
        }
      }else if(response.data.length ===1 && sampleDetails.isRebooked) {
        setloggedUserData(null)
        // history.push('/signin')
        setRebooking({...rebooking,isRebook:true})
      } else {
        console.log(22222)
        setloggedUserData(null)
        setRebooking({...rebooking,active:false,sampleId:0})
        history.push('/signin')
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  useEffect(() => {
    if (!configured) {
    getPrices().then((result) => {
        setConfigured(true);
        setPrices(SetPrices(prices, result));
    });

      // getSlotsConfigurations().then((result) => {
      //   setSlotsList(result.data);
      // })
     if (rebooking.sampleId !==0 ) {
        freeTestData(rebooking)
      }
      const IsAirline: boolean = Boolean(localStorage.getItem('isAirline')) || getSiteHostName();
      updateFormValues({ IsAirline: IsAirline })

      const Airline: string | null = getHostName() || null;
      updateFormValues({ airlineCode: Airline })
      updateFormValues({ airline: Airline })
      updateAirlineType(Airline)

      
      const district: string | null = getdistrict().toLowerCase() || null;
      const tenant: string | null = localStorage.getItem('tenant')?.toLocaleLowerCase() || null ||gettenant().toLowerCase();

      if (Airline) {
        updateFormValues({ testSelection: 'flight' })
        updateFormValues({ cPartnerID: 'WSL001' })
      }
      else if (district === 'LLESD'.toLowerCase()){
        window.location.replace('https://forms.worksitelabs.com/220755468478974');
      }
       else if (district) {
        updateFormValues({ testSelection: district == 'COM001'.toLowerCase()  ? 'community' : district === 'PCMA001'.toLowerCase() ? 'general' : 'school' })
        updateFormValues({ cPartnerID: district === 'SSFSD'.toLowerCase()  ? 'SSF001' 
        : district === 'BRSSD'.toLowerCase()  ? 'BEL001' 
        : district === 'COM001'.toLowerCase()  ? 'COM001' 
        : district === 'PVSD'.toLowerCase() ? 'POR001' 
        : district === 'SCSD'.toLowerCase() ? 'SCS001'
        : district === 'LLESD'.toLowerCase() ? 'LLT001'
        : district === 'PCMA'.toLowerCase() ? 'PCMA001':
        'SEQ001' });
        if(district==='PCMA'.toLocaleLowerCase()){
            updateFormValues({
                testSelection:'general',
            })
        }
      } else if (tenant === 'sfoairport') {
        updateFormValues({ testSelection: 'sfoairport' })
        updateFormValues({ cPartnerID: 'SFO001' })
      }else if(tenant==='kern'){
        updateFormValues({ testSelection: 'kern' })
        updateFormValues({ cPartnerID: 'KEN001' })
        updateFormValues({ paymentMethod: 'No payment' })
        updateFormValues({ payingMethod: 'No payment' })
     } else {
        updateFormValues({ testSelection: 'general' })
        updateFormValues({ cPartnerID: 'WSL001' })
      }
      let testtype = JSON.parse(JSON.parse(JSON.stringify(localStorage.getItem('testSelectionData'))))?.testSelection;
      if(testtype!==undefined&&testtype!=='')
      {
        updateFormValues({
          testSelection:testtype
        })
        let data = {testSelection:undefined}
        localStorage.setItem('testSelectionData',JSON.stringify(data));
      }
      const traveltype: string | null = gettraveltype() || null;
      updateFormValues({ travelType: traveltype })

      const destination: string | null = getdestination() || null;
      updateFormValues({ destination: destination })

      const refersion: string | null = getRefersion() || null;
      updateFormValues({ refersion: refersion })
      
      updateFormValues({ minors: [] });

      let address: any = getAddress() || form.address;

      if(tenant==='kern'){
        updateFormValues({ testSelection: 'kern' })
        updateFormValues({ cPartnerID: 'KEN001' })
    }

      updateFormValues({ address: address });
      let user: any =JSON.parse(localStorage.getItem('user') || '{}');
      if(user){
  
        updateFormValues({
  
            firstName: user.firstName,
  
            lastName: user.lastName,
  
            birthDate: user.birthDate,
  
            phone: user.phone,
  
            email: user.email,
  
        })
    }
    }
  }, [configured, prices,!rebooking.active]);

  const updateFormValues = useCallback(
    (update: AnyObject) => {
        console.log("updateFormValues",update)
      setForm((f) => {
        console.log("pirate",f)
        return ({
          ...f,
          ...update,
        })
      });
    },
    [setForm]
  );

  const updatesFormValues = useCallback(
    (update: AnyObject) => {
      setForm((f) => {
        return {
          ...f,
          ...update,
        };
      });
    },
    [setForm]
  );

  const updateSlotsList = useCallback(
    (update: any) => {
      setSlotsList(update)
    },
    [setSlotsList]
  );

  const goToNextStep = useCallback(() => {
    setStep((s: number) => {
      if (s + 1 <= testSelectionSteps.length - 1) {
        return s + 1;
      }

      return s;
    });
  }, [setStep]);

  const goToSkipStep = useCallback(() => {
    setStep((s: number) => {
      if (s + 1 <= testSelectionSteps.length - 1) {
        return s + 2;
      }

      return s;
    });
  }, [setStep]);

  const goToSkip2Step = useCallback(() => {
    setStep((s: number) => {
      if (s + 1 <= testSelectionSteps.length - 1) {
        return s + 3;
      }

      return s;
    });
  }, [setStep]);

  const goToSkip3Step = useCallback(() => {
    setStep((s: number) => {
      if (s + 1 <= testSelectionSteps.length - 1) {
        return s + 4;
      }

      return s;
    });
  }, [setStep]);
  const goToStep = useCallback((data:number)=>{
    setStep((s : number)=>{
        return data
    })
  },[setStep]);
  const goToSkip4Step = useCallback(() => {
    setStep((s: number) => {
        if (s + 6 <= testSelectionSteps.length - 1) {
            return s + 6;
        }
        return s;
    });
  }, [setStep]);

  const goToPrevStep = useCallback(() => {
    setStep((s: number) => {
      if(s===1)
      {
        localStorage.setItem('testSelection','false');
      }
      if (s - 1 >= 0) {
        return s - 1;
      }

      return s;
    });
  }, [setStep]);

  const goToSkipPrevStep = useCallback(() => {
    setStep((s: number) => {
      if (s - 2 >= 0) {
        return s - 2;
      }

      return s;
    });
  }, [setStep]);

  const toggleChangeLocationModal = useCallback(
    (show) => setShowChangeLocationModal(show),
    [setShowChangeLocationModal]
  );

  // const addReservationId = useCallback(
  //   (reservationId: string) => setReservationId(reservationId),
  //   [setReservationId]
  // );

  // const addReservationCount = useCallback(
  //   (reservationCount: number) => setReservationCount(reservationCount),
  //   [reservationCount]
  // );

  const toggleShowAlertModal = useCallback(
    (show) => setShowAlertModal(show),
    [setShowAlertModal]
  );

  const toggleShowDepartureDateTimeModal = useCallback(
    (show) => setShowDepartureDateTimeModal(show),
    [setShowDepartureDateTimeModal]
  );

  const updateAirlineType = useCallback(
    (type: string | null) => {
      setAirline(type ? type : '');
    },
    [setAirline]
  );

  const updateLoginScreen = useCallback(
    (type: boolean) => {
      setLoginScreen(type);
    },
    [setLoginScreen]
  );
  const updateBlockOutDays = useCallback(
    (data:Number[] ) => {
        setBlockOutDays(data);
    },
    [setLoginScreen]
  );

  const rebookingLoader = useCallback(
    (flag) => setRebooking({...rebooking,isLoading:flag}),
    [setRebooking]
  );

  const clearFormValues = useCallback(()=>{
    updateFormValues(initialFormState)
  },[])
  const store = useMemo(
    () => ({
      form,
      step,
      showChangeLocationModal,
      showAlertModal,
      prices,
      configured,
      slotsList,
      showDepartureTime,
      airline,
      isLoginScreen,
      blockOutDays,
      rebooking
      // reservationId,
      // reservationCount
    }),
    [step, form, showChangeLocationModal, showAlertModal, prices, configured, slotsList, showDepartureTime,blockOutDays,rebooking] //  reservationId, reservationCount
  );

  const dispatch = useMemo(
    () => ({
      goToNextStep,
      goToSkipStep,
      goToSkip2Step,
      goToSkip3Step,
      goToSkip4Step,
      goToPrevStep,
      updateFormValues,
      updatesFormValues,
      toggleChangeLocationModal,
      toggleShowAlertModal,
      updateSlotsList,
      toggleShowDepartureDateTimeModal,
      updateAirlineType,
      updateLoginScreen,
      goToSkipPrevStep,
      clearFormValues,
      updateBlockOutDays,
      goToStep,
      rebookingLoader
      // addReservationId,
      // addReservationCount
    }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <StepsContext.Provider value={store}>
      <StepsDispatchContext.Provider value={dispatch}>
        {children}
      </StepsDispatchContext.Provider>
    </StepsContext.Provider>
  );
};

export const useStepsState = () => {
  const context = React.useContext(StepsContext);

  if (typeof context === 'undefined') {
    throw new Error(
      '`useStepsState` hook must be used within a `Provider` component'
    );
  }

  return context;
};

export const useStepsDispatch = () => {
  const context = React.useContext(StepsDispatchContext);

  if (typeof context === 'undefined') {
    throw new Error(
      '`useStepsDispatch` hook must be used within a `Provider` component'
    );
  }

  return context;
};

//ltst