import React, { useState } from 'react';
import styled from 'styled-components';

import {
    Button,
    Container,
    Content,
    PageHeader,
    PageTitle,
} from '../../../../shared/components/styled';
import { Breakpoints } from '../../../../../dictionaries';
import { AdminManagePage } from '../dictionaries';
import { cancelAppointment, cancelCrelioAppointment } from '../../../../schedule/api';
import { colors } from '../../../../../styles/colors';
import { useAdminDispatch, useAdminState } from '../../../provider';

const CancelBtn = styled(Button)`
  width: 100%;

  @media (min-width: ${Breakpoints.sm}px) {
    width: 450px;
  }
`;

const Text = styled.p`
  margin-bottom: 45px;
`;

const Error = styled.div`
  color: ${colors.red};
  margin-top: 15px;
  text-align: left;
`;

const ManageCancel: React.FC = () => {
    const { manageAppointment } = useAdminState();
    const { goToPage } = useAdminDispatch();
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);


    const cancelAppointmentHandler = async () => {
        setLoading(true);
        setError(null);
        try {
            if (manageAppointment?.qbenchId) {
                await cancelAppointment(manageAppointment.id);
                goToPage(AdminManagePage.ManageCancelConfirm);
            } else if (manageAppointment?.crelioId || manageAppointment?.location?.limsinfo?.type === 'crelio') {
                await cancelCrelioAppointment(manageAppointment.id)
                goToPage(AdminManagePage.ManageCancelConfirm);

            } else {
                // @ts-ignore
                throw new Error('Appointment data is incomplete');
            }
        } catch (e) {
            setLoading(false);
            setError(e.message);
            console.error(e);
        }
    }

    return (
        <Container size="md">
            <Content>
                <PageHeader>
                    <PageTitle>
                        Are you sure you want to cancel your appointment?
                    </PageTitle>
                </PageHeader>
                <Text>
                    If you’d like to cancel your appointment, please click on the link
                    below.
                </Text>
                <CancelBtn
                    type="button"
                    libraryType="primary"
                    disabled={loading}
                    onClick={() => cancelAppointmentHandler()}>
                    {loading ? 'Cancelling...' : 'Cancel Appointment'}
                </CancelBtn>
                {error && <Error>{error}</Error>}
            </Content>
        </Container>
    );
};

export default ManageCancel;
