import React, { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';

import { RegisterProvider } from '../provider';
import SigninSteps from './SigninSteps';

function SigninProvider() {
  useEffect(() => {
    mixpanel.track('Page View');
  }, []);
  
  return (
    <RegisterProvider>
      <SigninSteps />
    </RegisterProvider>
  );
}

export default SigninProvider;