import React, { useState, useMemo } from 'react';

import { rescheduleAppointment, rescheduleCrelioAppointment } from '../../../../manage/api';
import Schedule from '../../../../shared/components/Schedule';
import { AdminManagePage } from '../dictionaries';
import ManageAdminPaymentModal from "./ManageAdminPaymentModal";
import { cloneDeep } from 'lodash';
import DownGradeModal from '../../../../manage/components/DownGradeModal';
import { useAdminDispatch, useAdminState } from '../../../provider';

const Reschedule: React.FC = () => {
	const { manageAppointment, apptPricesAdmin, showStandradTestAdmin } = useAdminState();
	const { updateAdminManageAppointment, goToPage, toggleAdminLocationModal } = useAdminDispatch();
	const [loading, setLoading] = useState<boolean>();

	const [showPaymentModal, setShowPaymentModal] = useState(false);
	const [showModal, setShowModal] = useState(false);

	const rollbackisExpressSameDayTest = useMemo(() => cloneDeep(manageAppointment?.isExpressSameDayTest), [true]);
    const rollbackisRapidTest = useMemo(() => cloneDeep(manageAppointment?.isRapidTest), [true]);

	if (!manageAppointment) {
		return null;
	}

	// console.log("appointment", appointment);

	// const AmoutPaid = rollbackisExpressSameDayTest ? (apptPrices.expedited * (minors.length + 1)): (apptPrices.standard * (minors.length + 1))
	// const refundableAmount = rollbackisExpressSameDayTest ? standard ? (AmoutPaid - (60 * (minors.length + 1))): 0 : 0;
	// const AdditonalCharge = !rollbackisExpressSameDayTest ? express ? (60 * (minors.length + 1)): 0 : 0;


	const resheduleAppointmentHandler = async () => {
		setLoading(true);
		try {
			if (
				manageAppointment?.isExpressSameDayTest !== rollbackisExpressSameDayTest &&
				manageAppointment?.isExpressSameDayTest
			) {
				setShowPaymentModal(true);
			} else if (
				manageAppointment?.isExpressSameDayTest !== rollbackisExpressSameDayTest &&
				manageAppointment?.isExpressSameDayTest == false
			) {
				setShowModal(true);
			} else if (
				manageAppointment?.isRapidTest !== rollbackisRapidTest &&
				manageAppointment?.isRapidTest
			) {
				setShowPaymentModal(true);
			} else if (
				manageAppointment?.isRapidTest !== rollbackisRapidTest &&
				manageAppointment?.isRapidTest == false
			) {
				setShowModal(true);
			} else {
				let appointmentData: any = manageAppointment;
				delete appointmentData?.qbenchAcknowledgement;
				if(appointmentData?.crelioId) {
					await rescheduleCrelioAppointment(appointmentData);
					goToPage(AdminManagePage.ManageRescheduleConfirm);
					setLoading(false);
				} else if (appointmentData?.qbenchId) {
					await rescheduleAppointment(appointmentData);
					goToPage(AdminManagePage.ManageRescheduleConfirm);
					setLoading(false);
				}
			}
		} catch (e) {
			setLoading(false);
		}
	};


	return (
		<>
			<Schedule
				isAdmin={true}
				standardTest={showStandradTestAdmin}
				prices={apptPricesAdmin}
                title="Reschedule your appointment: Pick a date and time"
				onLocationChange={() => {
					// toggleLocation(true);
					toggleAdminLocationModal(true);
				}}
				onSchedule={() => resheduleAppointmentHandler()}
				onFieldUpdate={(update) =>
					updateAdminManageAppointment({ ...manageAppointment, ...update })
				}
				appointment={manageAppointment}
				confirmBtnText="Confirm Reschedule"
				loading={loading}
			/>
			<ManageAdminPaymentModal
				showModal={showPaymentModal}
				closeModal={() => {
					setLoading(false);
					setShowPaymentModal(false);
				}}
			/>
			<DownGradeModal
				showModal={showModal}
				onCancel={() => {
					setShowModal(false);
					setLoading(false);
				}}
				title={"Note"}
				content={"Please call or email customer service at 213-948-2461 or support@worksitelabs.com to request for a refund for your downgrade to a standard PCR test"}
				onConfirm={async () => {
					setShowModal(false);
					let appointmentData: any = manageAppointment;
					delete appointmentData?.qbenchAcknowledgement;
					await rescheduleAppointment(appointmentData);
					goToPage(AdminManagePage.ManageRescheduleConfirm);
				}}
			/>
		</>
	);
};

export default Reschedule;
