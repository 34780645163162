import {
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  roseCardHeader,
  whiteColor
} from "../AppointmentList/material-dashboard-react";


const styles = {
  fullWidth: {
    width: '100%',
  },
  breadcrumb: {
    backgroundColor: '#fff',
  },
  title: {
    margin: '24px 16px',
  },
  card: {
    paddingBottom: 0,
  },
  content: {
    margin: 24,
  },
  searchBarActions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  spaceWidth: {
    width: '100%',
  },
  primaryCardHeader: {
    color: whiteColor,
    borderRadius:"3px",
    "&:not($cardHeaderIcon)": {
      ...primaryCardHeader
    }
  },

  
};

export { styles };
