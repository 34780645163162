import React from 'react';
import { format } from 'date-fns';
import { config } from '../../../../../config';

interface Props {
    appointment?: any;
}

const GenerateReceipt: React.FC<Props> = ({ appointment }) => {
    return (
      <div
        style={{
          margin: '0',
          padding: '30px 60px',
          background: 'white',
          width: '595px',
          height: '842px',
          fontFamily: 'Arial, Open Sans, sans-serif !important',
          fontWeight: 'normal',
          fontStyle: 'normal',
          fontSize: '12px',
          lineHeight: '22px',
          color: '#515151',
          textDecoration: 'none',
          position: 'relative',
        }}
      >
        <table
          style={{
            width: '100%',
            border: '3px solid #2A5F87',
            display: 'block',
          }}
        >
          <tr>
            <td
              style={{
                fontWeight: 'bold',
                color: '#ffffff',
                background: '#2A5F87',
                padding: '5px 26px',
                display: 'flex',
                alignItems: 'center',
                fontSize: '15px',
              }}
            >
              Billing Details
            </td>
            <td
              style={{
                fontWeight: 'bold',
                color: '#ffffff',
                background: '#2A5F87',
                padding: '5px 26px',
                display: 'flex',
                alignItems: 'center',
                fontSize: '15px',
              }}
            ></td>
          </tr>
          <tr>
            <td style={{ height: '25px' }}></td>
          </tr>
          <tr>
            <td
              style={{
                padding: '0',
                paddingLeft: '13px',
                paddingRight: '13px',
              }}
            >
              {(appointment.payingMethod === 'Credit Card' ||
                appointment.payingMethod === 'Credit card') &&
              appointment.promoCode !== 'EYEPROD' &&
              appointment.promoCode !== 'GRAVY' &&
              appointment.promoCode !== 'EMPLOYEE' ? (
                <div>
                  <div>
                    <div style={{ display: 'flex' }}>
                      <div style={{ marginRight: 'auto' }}>
                        <div>
                          <b
                            style={{
                              fontSize: '12px',
                              lineHeight: '22px',
                              color: '#1A96DB',
                            }}
                          >
                            TOTAL AMOUNT PAID
                          </b>
                          <div
                            style={{
                              fontSize: '12px',
                            }}
                          >
                            $
                            {appointment.totalCost
                              ? parseInt(appointment.totalCost) -
                                parseInt(appointment.discountAmount)
                              : 0}
                          </div>
                          <br />
                        </div>
                      </div>
                      <div style={{ marginRight: 'auto' }}>
                        <div>
                          {' '}
                          <b
                            style={{
                              fontSize: '12px',
                              lineHeight: '22px',
                              color: '#1A96DB',
                            }}
                          >
                            DATE PAID
                          </b>
                          <div
                            style={{
                              fontSize: '12px',
                            }}
                          >
                            {format(
                              new Date(
                                typeof appointment?.createddate == 'string'
                                  ? appointment?.createddate
                                  : appointment?.date
                              ),
                              config.dateFormat
                            )}
                          </div>
                          <br />
                        </div>
                      </div>
                      <div>
                        <div>
                          {' '}
                          <b
                            style={{
                              fontSize: '12px',
                              lineHeight: '22px',
                              color: '#1A96DB',
                            }}
                          >
                            PAYMENT METHOD
                          </b>
                          <div
                            style={{
                              fontSize: '12px',
                            }}
                          >
                            {appointment.payingMethod == 'Credit Card'
                              ? `Credit Card ending in ${appointment.last4}`
                              : appointment.payingMethod}
                          </div>
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>

                  <b
                    style={{
                      fontSize: '12px',
                      lineHeight: '22px',
                      color: '#1A96DB',
                    }}
                  >
                    SUMMARY
                  </b>
                  <br />
                  <br />
                  <div
                    style={{
                      background: '#EDF7FC',
                      padding: '15px',
                      fontSize: '12px',
                    }}
                  >
                    <div style={{ display: 'flex' }}>
                      <p style={{ marginRight: 'auto' }}>
                        {appointment.patientCount || 0}x COVID-19
                        {appointment.isAntigen ? ' ANTIGEN ' : 'PCR '} TEST
                      </p>
                      <p>
                        ${appointment.totalCost ? appointment.totalCost : 0}
                      </p>
                    </div>
                    <br />
                    {appointment.discountAmount != 0 &&
                    appointment.discountAmount !== undefined &&
                    appointment.discountAmount !== null ? (
                      <div style={{ display: 'flex' }}>
                        <p style={{ marginRight: 'auto' }}>Discount amount</p>
                        <p>${appointment.discountAmount}</p>
                      </div>
                    ) : (
                      ''
                    )}
                    <hr />
                    <div style={{ display: 'flex' }}>
                      <b style={{ marginRight: 'auto' }}>Amount Charged</b>
                      <b>
                        $
                        {appointment.totalCost
                          ? parseInt(appointment.totalCost) -
                            parseInt(appointment.discountAmount)
                          : 0}
                      </b>
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}
              <br />

              <div style={{ display: 'flex', width: '100%', justifyContent:'center' }}>
                <div style={{ width: '50%' }}>
                  <b
                    style={{
                      fontSize: '12px',
                      lineHeight: '22px',
                      color: '#1A96DB',
                    }}
                  >
                    NPI #
                  </b><br />
                    <b
                      style={{
                        fontSize: '12px',
                      }}
                    >
                      {appointment?.location?.NPI ? appointment.location.NPI : '1467030536'}
                    </b>
                  <br />
                </div>
                <div style={{ width: '50%' }}>
                  <b
                    style={{
                      fontSize: '12px',
                      lineHeight: '22px',
                      color: '#1A96DB',
                    }}
                  >
                    CPT Code(s)
                  </b><br />
                    <b
                      style={{
                        fontSize: '12px',
                      }}
                    >
                      { appointment.isPanel ? appointment?.location?.panelTestCode?.CPT ?? '' :  appointment?.location?.CPT ? appointment.location.CPT : 'U0003,U0005'}
                    </b>
                  <br />
                </div>
              </div><br /><br />
              <div style={{ display: 'flex', width: '100%', justifyContent:'center' }}>
                <div style={{width:'50%'}}>
                    <b
                      style={{
                        fontSize: '12px',
                        lineHeight: '22px',
                        color: '#1A96DB',
                        width:'50%'
                      }}
                    >
                      CLIA #
                    </b><br />
                    <b
                      style={{
                        fontSize: '12px',
                      }}
                    >
                      {appointment?.location?.CLIA ?? ''}
                    </b>
                </div>
                <div style={{width:'50%'}}>
                  <b
                    style={{
                      fontSize: '12px',
                      lineHeight: '22px',
                      color: '#1A96DB'
                    }}
                  >
                    ICD-10 Code
                  </b><br />
                  <b
                    style={{
                      fontSize: '12px',
                    }}
                  >
                    {appointment?.isPanel ?appointment?.location?.panelTestCode?.ICD : appointment?.location?.ICD ? appointment?.location?.ICD : 'Z11.52'}
                  </b>
                </div>
              </div> <br />
              <i
                style={{
                  fontSize: '12px',
                  lineHeight: '22px',
                  marginBottom: '20px',
                }}
              >
                <b>NOTICE TO INSURANCE COMPANY OF ASSIGNMENT: </b>You are
                instructed to PAY DIRECTLY TO THE ABOVE NAMED SUBSCRIBER AND/OR
                PATIENT for all professional services rendered. Reimbursement
                issued to your member will repressent the benefit amount payable
                for the service and will be attached to an Explanation of
                Benefits (EOB).
              </i>
            </td>
          </tr>
          <tr>
            <td style={{ height: '29px' }}></td>
            <br />
          </tr>
        </table>
      </div>
    );

}

export default GenerateReceipt;