import React from 'react';
import styled from 'styled-components';
import { Breakpoints } from '../../../../../../dictionaries';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import Modal from './../../../../../shared/components/Modal';
import { colors } from '../../../../../../styles/colors';
import { Button } from './../../../../../shared/components/styled';

const Container = styled.div`
`;
const Title = styled.h2`
  color: ${colors.fullBlack};
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 25px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 120px;
  margin: 0 -5px;

  & > * {
    margin: 0 5px;
  }
  @media (max-width: ${Breakpoints.sm}px) {
      display: grid;
      row-gap: 10px;
      margin-top: 30px;
  }
    `;
const Text = styled.div`
text-align: left;
font-size: 15px;
font-weight: 500;
margin: 0px 25px;
`;
const SubContainer = styled.div`
  margin: 20px 25px;
`;
type Props = {
    open: boolean;
    handleVerifyScreen: () => void;
    handlePasswordClose: () => void;

  };
const ConfirmationPasswordModal: React.FC<Props> = ({ open, handlePasswordClose, handleVerifyScreen }) => {
    return (
        <Modal open={open} onClose={handlePasswordClose} maxWidth={600} blockScroll={false}>
            <Container>
            <Title>
            <AiOutlineQuestionCircle size={22} color={'#000'} />
            {' Your password has been changed.'}
            </Title>
            <Text>
            Please use your new password the next time you log in to your
            account.
              </Text>
            </Container>
            <SubContainer>
            <ButtonsContainer>
                <Button  onClick={() => {
                        handleVerifyScreen();
                        handlePasswordClose(); }}
                        libraryType="primary" size="sm" type="submit" className='btn fill-btn'>
                {'OK'}
                </Button>
              </ButtonsContainer>
              </SubContainer>
        </Modal>
    )
}

export default ConfirmationPasswordModal;
