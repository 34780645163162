import React ,{useState, useEffect}from 'react';
import { Formik, Form } from 'formik';

import {
  Container,
  Content,
  PageHeader,
  PageTitle,
} from '../../../shared/components/styled';
import { useStepsState, useStepsDispatch } from '../../../schedule/provider';
import NavigationButtons from '../../../shared/components/NavigationButtons';
import YesNoButtonGroup from '../../../shared/components/form/YesNoButtonGroup';
import { useManageDispatch } from '../../../manage/provider';
import { useSharedDispatch, useSharedState } from '../../../shared/provider';
import CreditcardInsurancebutton from '../../../shared/components/form/CreditcardInsurancebutton';
import GoogleAnalytics from '../../../../GoogleAnalytics';
import { config } from '../../../../config';
import PayingMethod from '../PayingMethod';

const InsuranceYorN: React.FC = () => {
  const {
    form,
  } = useStepsState();
  const { hasInsurance, testSelection, location, payingMethod} = form;
  const {
    updateFormValues,
    goToNextStep,
    goToPrevStep,
    goToSkipStep,
  } = useStepsDispatch();
  const { updateStandradTest } = useManageDispatch();
  const { upateTestType } = useSharedDispatch();
  const { isWalkUp } = useSharedState();
  const [insurance, setInsurance] = useState(false);
  const [noInsurance, setNoInsurance] = useState(false);
  const [creditCard, setCreditCard] =useState(false);


  // useEffect(() => {
  //   console.log(
  //     '!!form.location?.isInsuranceDisabled',
  //     !!form.location?.isInsuranceDisabled
  //   );

  //   if (!!form.location?.isInsuranceDisabled) {
  //     updateFormValues({ payingMethod: 'Credit card' });
  //     goToSkipStep();
  //     goToSkipStep();
  //   }
  // }, [form.location]);
  React.useEffect(() => {
    if(testSelection==='kern' && location?.cPartnerID==='KEN001'){
        updateFormValues({payingMethod:'No Payment'})
        updateFormValues({ cPartnerID: 'KEN001' })
        goToSkipStep()
      }
    GoogleAnalytics(form,'/Insurance Yes r No');
    handlePaymentMethod()
    updateFormValues({isRapid: false, isRapidTest:false,  isAntigen: false, isExpressSameDayTest: false})
  }, [])

  const handlePaymentMethod = () => {
	
    if (location?.isCreditCard.standard || location?.isCreditCard.antigen || location?.isCreditCard.rapid || location?.isCreditCard.express) {
      setCreditCard(true);
    } else {
      setCreditCard(false);
    }
    if (location?.isInsurance.standard || location?.isInsurance.antigen || location?.isInsurance.rapid || location?.isInsurance.express) {
      setInsurance(true);
    } else {
      setInsurance(false);
    }
    if (location?.isNoInsurance.standard || location?.isNoInsurance.antigen || location?.isNoInsurance.rapid || location?.isNoInsurance.express) {
      setNoInsurance(true);
    } else {
      setNoInsurance(false);
    }
    }
  return (
    <Container>
      <Content>
        <PageHeader>
          { <PageTitle>{insurance && creditCard ? 'How will you pay for this?' : ''}</PageTitle>}
          {<PageTitle>{!creditCard && insurance && noInsurance ? 'Do you have insurance?' : ''}</PageTitle>}
        </PageHeader>
        <Formik
          enableReinitialize
          initialValues={{
            hasInsurance,
          }}
          onSubmit={(values) => {
            updateFormValues(values);
            // if (values.hasInsurance) {
            //   goToNextStep();
            // } else if (testSelection === 'school') goToSkipStep();
            // else goToNextStep();
            if (values.hasInsurance) {
              updateFormValues({ payingMethod: 'Insurance' });
              goToSkipStep();
              goToNextStep()
            } if (payingMethod === 'Credit Card' && !values.hasInsurance) {
              updateFormValues({ payingMethod: 'Credit Card' });
              goToSkipStep();
              goToSkipStep();
            } if (payingMethod === 'No Insurance' && !values.hasInsurance) {
              updateFormValues({ payingMethod: 'No Insurance' });
              goToSkipStep();
              
            }
            //  else if (testSelection === 'school' ) {
            //   updateFormValues({ payingMethod: 'No Insurance' });
            //   goToSkipStep();
            // } else if (testSelection !== 'school') {
            //   updateFormValues({payingMethod: 'Credit Card'});
            //   goToSkipStep();
            //   goToSkipStep();
          }}
        >
          {() => {
            return (
              <Form>
                {creditCard && (insurance || noInsurance) ? (<CreditcardInsurancebutton
                  name="hasInsurance"
                  insurance = {insurance}
                  noInsurance = {noInsurance}
                  onChange={(answer) => {
                    // updateFormValues({ hasInsurance: answer });
                    if (answer === 'Insurance') {
                      updateFormValues({ payingMethod: 'Insurance',slot : undefined, hasInsurance: true });
                      // upateTestType(true);
                      // updateStandradTest(true);
                      goToNextStep();
                      goToSkipStep();
                      // if(form.location.qbenchCustomerId === config.qbenchCustomerId.PCMADestination){
                      //   updateFormValues({isAntigen:true})
                      // }
                      // if(isWalkUp?.active && isWalkUp?.locationId === config.qbenchCustomerId.PCMADestination){
                      //   goToPrevStep()
                      // }
                    } else if (answer === 'Credit Card'){
                      updateFormValues({ payingMethod: 'Credit Card', hasInsurance: false });
                      goToSkipStep();
                      goToSkipStep();
                      // if(form.location.qbenchCustomerId === config.qbenchCustomerId.PCMADestination){
                      //   updateFormValues({isAntigen:true})
                      // }
                      // if(isWalkUp?.active && isWalkUp?.locationId === config.qbenchCustomerId.PCMADestination){
                      //   goToPrevStep()
                      // }
                    } else if (answer === 'No Insurance'){
                      updateFormValues({payingMethod: 'No Insurance', hasInsurance: false});
                      goToSkipStep()
                      // if(form.location.qbenchCustomerId === config.qbenchCustomerId.PCMADestination){
                      //   updateFormValues({isAntigen:true})
                      // }
                      // if(isWalkUp?.active && isWalkUp?.locationId === config.qbenchCustomerId.PCMADestination){
                      //   goToPrevStep()
                      // }
                    }
                    else goToNextStep();
                  }}
                  isRequired
                />) :
                  (<YesNoButtonGroup
                    name="hasInsurance"
                    onChange={(answer) => {
                      updateFormValues({ hasInsurance: answer });
                      if (answer) {
                        updateFormValues({ payingMethod: 'Insurance' });
                        upateTestType(true);
                        updateStandradTest(true);
                        goToNextStep();
                        goToSkipStep();
                        if(isWalkUp?.active && isWalkUp?.locationId === config.qbenchCustomerId.PCMADestination){
                            goToPrevStep()
                        }
                      } else {
                        updateFormValues({ payingMethod: 'No Insurance' });
                        goToSkipStep();
                        if(isWalkUp?.active && isWalkUp?.locationId === config.qbenchCustomerId.PCMADestination){
                            goToPrevStep()
                        }
                      }
                      //  else if (testSelection === 'sfoairport') {
                      //   updateFormValues({ payingMethod: 'Credit card' });
                      //   goToSkipStep();
                      //   goToSkipStep();
                      // }
                      // else goToNextStep();
                    }}
                    isRequired
                  />)}
                <NavigationButtons
                  onReturnButtonClick={() => {
                    goToPrevStep();
                  }}
                  isValid={form.payingMethod !== '' ? true : false}
                />
              </Form>
            );
          }}
        </Formik>
      </Content>
    </Container>
  );
};

export default InsuranceYorN;
