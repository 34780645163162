import React, { useState } from 'react';
import { Formik, Form, FormikErrors } from 'formik';
import styled from 'styled-components';
import { differenceInYears, format, parse } from 'date-fns';

import {
  Container,
  Content,
  PageHeader,
  PageTitle,
} from '../../../shared/components/styled';
import { useStepsDispatch, useStepsState, newMinor } from '../../provider';
import { Breakpoints } from '../../../../dictionaries';
import { config } from '../../../../config';

import NavigationButtons from '../../../shared/components/NavigationButtons';
import FormLabel from '../../../shared/components/form/FormLabel';
import Input from '../../../shared/components/form/Input';
import { DatepickerField } from '../../../shared/components/Datepicker';
import Checkbox from '../../../shared/components/form/Checkbox';
import Minors from './Minors';
import AntDDatePicker from '../../../shared/components/AntDDatePicker';
import { ErrorText } from '../../../shared/components/styled';
import { country } from './PassportCountry';
import Select from '../../../shared/components/form/Select';

const InputRow = styled.div`
  display: flex;
  flex-direction: column;
  margin: -8px 0;

  @media (min-width: ${Breakpoints.sm}px) {
    flex-direction: row;
    margin: 0 -8px;
  }

  @media (min-width: ${Breakpoints.lg}px) {
    margin: 0 -15px;
  }

  & > * {
    flex-basis: 33%;
    margin: 8px 0;

    @media (min-width: ${Breakpoints.sm}px) {
      margin: 0 8px;
    }

    @media (min-width: ${Breakpoints.lg}px) {
      margin: 0 15px;
    }
  }
`;
const PassportRow = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;

  & > *:first-child {
    width: 32%;
  }

  & > *:last-child {
    width: 32%;
  }
`;

const Error = styled.div`
  position: absolute;
  left: 6px;
  top: 58px;
`;

const PersonalInformation: React.FC = () => {
  const {
    form: {
      minors,
      firstName,
      lastName,
      birthDate,
      location,
      isHACustomer,
      passportCountry,
      passportNo,
      travelType,
    },
  } = useStepsState();
  const { updateFormValues, goToNextStep } = useStepsDispatch();
  const [hasMinors, setHasMinors] = useState(minors.length > 0);

  const [haCustomer, setHACustomer] = useState(isHACustomer);

  const [birthDateError, setBirthDateError] = useState(String);

  function birthDateValidation(birthDate: any) {
    if (birthDate == '' || birthDate == null) {
      setBirthDateError('Required Field');
      return false;
    } else if (differenceInYears(new Date(), birthDate) < 18) {
      setBirthDateError('You must be 18 years old or above');
      return false;
    } else {
      return true;
    }
  }

  return (
    <Container size="xl">
      <Content>
        <PageHeader>
          <PageTitle>Enter your information</PageTitle>
        </PageHeader>
        <Formik
          initialValues={{
            minors: minors.map((minor: any) => ({
              ...minor,
              birthDate:
                minor.birthDate &&
                parse(minor.birthDate, config.dateFormat, new Date()),
            })),
            firstName,
            lastName,
            birthDate:
              birthDate && parse(birthDate, config.dateFormat, new Date()),
            hasMinors,
            passportNo,
            passportCountry,
            haCustomer,
          }}
          onSubmit={(values) => {
            // let birthDate: any = values.birthDate

            // let validation: any = birthDateValidation(birthDate);
            // console.log("validation",validation, values);
            // if (validation) {
            updateFormValues({
              ...values,
              birthDate:
                values.birthDate && format(values.birthDate, config.dateFormat),
              minors: values.minors.map((minor: any) => ({
                ...minor,
                birthDate:
                  minor.birthDate && format(minor.birthDate, config.dateFormat),
                haCustomer,
              })),
            });
            goToNextStep();
            // }
          }}
        >
          {({ setFieldValue, errors }) => {
            return (
              <Form>
                {location?.haCustomerOnly && (
                  <Checkbox
                    name="haCustomer"
                    onChange={(checked) => {
                      setFieldValue('haCustomer', checked);
                      updateFormValues({ isHACustomer: checked });
                      setHACustomer(checked);
                    }}
                    isRequired
                  >
                    {location?.qbenchCustomerId === config.qbenchCustomerId.LasVegas_TheOrleans
                      ? 'I understand these testing services are exclusive to Hawaiian Airlines and by checking this box confirm this test is for travel on Hawaiian Airlines (or Vacations Hawaii).'
                      : 'I understand these testing services are exclusive to Hawaiian Airlines and by checking this box confirm this test is for travel on Hawaiian.'}
                  </Checkbox>
                )}
                <InputRow>
                  <FormLabel label="First Name">
                    <Input
                      name="firstName"
                      isRequired
                      onChange={(value) =>
                        updateFormValues({ firstName: value })
                      }
                    />
                  </FormLabel>
                  <FormLabel label="Last Name">
                    <Input
                      name="lastName"
                      isRequired
                      onChange={(value) =>
                        updateFormValues({ lastName: value })
                      }
                    />
                  </FormLabel>
                  <FormLabel label="Date of Birth">
                    <DatepickerField
                      name="birthDate"
                      isRequired
                      inputPlaceholder="MM/DD/YYYY"
                      validate={(value) => {
                        let error;

                        if (differenceInYears(new Date(), value) < 18) {
                          error = 'You must be 18 years old or above';
                        }

                        return error;
                      }}
                    />
                  </FormLabel>
                </InputRow>
                <PassportRow>
                  <FormLabel label="Passport Country">
                    <Select<string>
                      name="passportCountry"
                      options={country}
                      onChange={(value: any) =>
                        updateFormValues({ passportCountry: value })
                      }
                      validate={(value) => {
                        if (travelType == "INT" && !value) {
                          return 'Required field';
                        }
                      }}
                    />
                  </FormLabel>
                  <FormLabel label="Passport #">
                    <Input
                      onChange={(value) =>
                        updateFormValues({ passportNo: value })
                      }
                      name="passportNo"
                      validate={(value) => {
                        if (travelType == "INT" && (!value || !value.trim().length)) {
                          return 'Required field';
                        }
                      }}
                      isRequired
                    />
                  </FormLabel>
                </PassportRow>
                <Checkbox
                  name="hasMinors"
                  onChange={(checked) => {
                    const newMinors = checked ? [newMinor] : [];
                    setFieldValue('minors', newMinors);
                    updateFormValues({ minors: newMinors });
                    setHasMinors(checked);
                  }}
                >
                  I am registering for minors.
                </Checkbox>
                {hasMinors && <Minors />}
                <NavigationButtons
                  isValid={
                    location?.haCustomerOnly ? isHACustomer : !isHACustomer
                  }
                />
              </Form>
            );
          }}
        </Formik>
      </Content>
    </Container>
  );
};

export default PersonalInformation;
