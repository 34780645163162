import React from 'react';
import { useStepsState } from '../../schedule/provider';
import { Wrapper } from '../../shared/components/styled';
import Header from '../../shared/components/Header';
import FooterRegister from './FooterRegister';
import UpgradeTest from './UpgradeTest';
import Confirmation from './Confirmation/Confirmation';

export const steps = [
  UpgradeTest,
  Confirmation
];

function UpgradeSteps() {
  const { step } = useStepsState();
  const CurrentStep = steps[step];

  return (
    

    <Wrapper backgroundColor="#FFFFFF">
      {step !== 0 && (
        <Header isHelp />
      )}
      <div>
        <CurrentStep />
      </div>
      {step !== 0 && step !== 1 && <FooterRegister />}
      {/* <FooterRegister /> */}
    </Wrapper>
  );
}

export default UpgradeSteps;
