import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors } from '../../../../../styles/colors';
import { MdDateRange } from 'react-icons/md';
import { BiTimeFive } from 'react-icons/bi';
import { Breakpoints } from '../../../../../dictionaries';
import { Button } from '../../../../shared/components/styled';
import { useSharedState } from '../../../../shared/provider';
import { downloadResultsPDF, downloadCrelioPdf } from '../../../../admin/api';
import { getAppointmentResults } from '../../../api';
import { format } from 'date-fns';
import Loader from '../../../../shared/components/Loader';
import { orderBy } from 'lodash';
import moment from 'moment';

import {
    IoPersonAddOutline,
} from 'react-icons/io5';
import { config } from '../../../../../config';
import { Grid } from '@material-ui/core';


const Mainpage = styled.div`
  margin: 8% 0 0 8%;
  max-width: 90vw;
  @media (max-width: ${Breakpoints.xl}px) {
    margin-left: 12%;
  }
  @media (max-width: ${Breakpoints.lg}px) {
    margin-left: 16%;
  }
`;
const PageTitle = styled.div`
  color: ${colors.fullBlack};
  font-size: 28px;
  font-weight: 700;
  margin: 10px;
  text-align: left;
  /* margin-top: 10px; */
  @media (max-width: ${Breakpoints.sm}px) {
    font-size: 24px;
    margin-top: 10px;
    text-align: center;
  }
  @media (max-width: ${Breakpoints.lg}px) {
    font-size: 24px;
    margin-top: 25%;
    text-align: center;
  }
`;
const WrapContainer = styled.div`
  max-width: 90vw;
  background: ${colors.rebrandBlack};
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px;
  border-radius: 12px;
  @media (max-width: ${Breakpoints.lg}px) {
    max-width: 80vw;
  }
`;
const WrapText = styled.div`
  color: black;
  font-size: 24px;
  font-weight: bold;
  padding: 40px 40px;
  text-align: left;
  /* @media (max-width: ${Breakpoints.sm}px) {
    font-size: 18px;
    text-align: left;
    line-height: 25px;
  } */
`;
const FlexboxOne = styled.div`
  /* display: flex; */
  gap: 20px;
  /* @media (max-width: ${Breakpoints.sm}px) {
    margin-bottom: 20px;
  } */
`;
const FlexboxTwo = styled.div`
  /* display: flex; */
  gap: 20px;
  /* @media (max-width: ${Breakpoints.sm}px) {
    margin-bottom: 20px;
  } */
`;
const FlexboxThree = styled.div`
  /* display: flex; */
  gap: 20px;
  /* @media (max-width: ${Breakpoints.sm}px) {
    margin-bottom: 20px;
    width: 100%;
  } */
  /* width: 16%; */
`;
const FlexboxFour = styled.div`
  /* display: flex; */
  gap: 20px;
`;
const FlexboxFive = styled.div``;
const Vl = styled.div`
  border-left: 1px solid #ffff;
  /* height: 100%; */
`;
const FlexboxIcon = styled.div`
  margin-right: 8px;
  color: ${colors.grey};
`;
const FlexboxText = styled.div`
  /* display: block; */
  justify-content: space-between;
  padding: 10px;
  font-size: 16px;
  font-weight: 500;
`;
const FlexboxButton = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 12px 0px 12px;

  @media (max-width: ${Breakpoints.md}px) {
    margin: 0px 22px 0px 22px;
  }
  @media (max-width: ${Breakpoints.lg}px) {
    margin: 0px 72px 0px 72px;
  }
  @media (max-width: ${Breakpoints.sm}px) {
    margin: 0px 12px 0px 12px;
  }
`;
const WrapContent = styled.div`
  width: 100%;
  @media (max-width: ${Breakpoints.md}px) {
  }
  @media (max-width: ${Breakpoints.lg}px) {
  }
`;
const ExapndlistTag = styled.div`
  display: flex;
  gap: 40px;
  @media (max-width: ${Breakpoints.sm}px) {
    display: block;
  }
`;
const TextWrapContainer = styled.div`
  background: ${colors.white};
  /* margin: 30px 0px; */
  margin-bottom: 60px;
  /* display: flex; */
  /* flex-direction: row; */
  justify-content: space-between;
  /* width: 100%;
  height: 103px; */
  left: 0px;
  top: 59px;
  box-sizing: borderbox;
  border: 0.5px solid rgba(36, 61, 77, 0.75);
  border-radius: 12px;
  height: 150px;
  /* @media (max-width: ${Breakpoints.sm}px) {
    margin-left: 18%;
    height: 100px;
  } */
`;

const HideBtn = styled.div`
  display: none;
`;
const GridItem = styled.div`
  display: flex;
`;
const AppointmentResult: React.FC = () => {
  const { userData } = useSharedState();
  const [results, setResults] = useState<[]>([]);
  const [loading, setLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [selectedKey, setselectedKey] = useState(-1);

  // Get appointment results
  const getResult = useCallback(async (email: string) => {
    let data = { email };
    setLoading(true);
    let upcomingData: any = [];
    let resultData = await getAppointmentResults(data);

    if (resultData?.data?.status === 200) {
      console.log('result before', resultData?.data?.data);

      // upcomingData = orderBy(resultData?.data?.data, ['slot.date'], ['desc']);
      let upcomingData = resultData?.data?.data?.sort((a: any, b: any) => moment(a.slot.date)
      .format('YYYY-MM-DD') <  moment(b.slot.date).format('YYYY-MM-DD') ? 1 : moment(b.slot.date)
      .format('YYYY-MM-DD') < moment(a.slot.date).format('YYYY-MM-DD') ? -1 : 0 )

      setResults(upcomingData);
      console.log('result after', upcomingData);
    } else {
      setResults([]);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (userData?.email) {
      getResult(userData?.email);
    } else {
      setResults([]);
    }
  }, [userData?.email]);

  const downloadResultPDF = async (model: any, key: number) => {
    setDownloadLoading(true);
    setselectedKey(key);
    if (model.qbenchId) {
      await downloadResultsPDF(model.sampleId);
    } else if (model.crelioId && model?.report?.reportBase64) {
      await downloadCrelioPdf(
        model.firstName + ' ' + model.lastName,
        model?.report?.reportBase64
      );
    }
    setselectedKey(-1);
    setDownloadLoading(false);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Mainpage>
      <PageTitle>Your lab results</PageTitle>
      {(results || []).map((model: any, key: number) => (
        <WrapContainer key={key} style={{ color: 'white' }}>
          <WrapContent>
            <div>
              <div>
                <b> Confirmation #: </b> {model.confirmationId}
              </div>
              <div>
                <b> Patient #: </b> {model.firstName} {model.lastName}
              </div>
            </div>
            <br />
            {model.isPanel ? model.tests.map((e:any, index:number)=>
                <Grid container spacing={3} alignItems='center' justifyContent='space-between'>
                    <Grid item xs={12} lg={2} md={4} sm={6}>
                        <GridItem>
                            <FlexboxIcon>
                                <MdDateRange size={22} />
                            </FlexboxIcon>
                            <div>
                                <strong>Date</strong>
                                <br />
                                {model?.slot?.date
                                    ? format(new Date(model?.slot?.date), 'MMMM dd,yyyy')
                                    : ''}
                            </div>
                        </GridItem>
                    </Grid>
                    <Grid item xs={12} lg={2} md={4} sm={6}>
                        <GridItem>
                            <FlexboxIcon>
                                <BiTimeFive size={22} />
                            </FlexboxIcon>
                            <div>
                                <strong>Test</strong>
                                <br />
                                {e.name}
                            </div>
                        </GridItem>
                    </Grid>
                    <Grid item xs={12} lg={2} md={4} sm={6}>
                        <GridItem>
                            <FlexboxIcon>
                                <BiTimeFive size={22} />
                            </FlexboxIcon>
                            <div>
                                <strong>Result</strong>
                                <br />
                                {e.result}
                            </div>
                        </GridItem>
                    </Grid>
                    <Grid item xs={12} lg={2} md={6} sm={6}>
                        <GridItem>
                            <FlexboxIcon>
                                <IoPersonAddOutline size={22} />
                            </FlexboxIcon>
                            <div>
                                <strong>Test Type</strong>
                                <br />
                                {model.isRapidTest
                                    ? 'Rapid PCR Test'
                                    : model.isExpressSameDayTest
                                    ? 'Express PCR Test'
                                    : model.isAntigen
                                    ? 'Antigen Test'
                                    : model.isPanel
                                    ?  'Flu A&B/Covid-19'
                                    : 'Standard PCR Test'}
                            </div>
                        </GridItem>           
                    </Grid>
                    <Grid item xs={12} lg={2} md={4} sm={12}>
                        {index === 0 && <FlexboxButton>
                            {model?.results === 'DETECTED' &&
                                model?.location?.test2Treat === true && (
                                <>
                                    <Button
                                    type="button"
                                    size="sm"
                                    libraryType="default"
                                    accentColor={colors.red}
                                    className='btn elevated-btn'
                                    onClick={() => {
                                        window.open(
                                        `${
                                            config.telemedicine.drChronoUrl
                                        }&appointment_profile=484140&office=338666&written_reason_for_visit=COVID%2019%20Consultation&patient_name=${
                                            model.firstName ?? ''
                                        }%20${model.lastName ?? ''}&date_of_birth=${
                                            Object.keys(model.birthDate).length !== 0 &&
                                            model.birthDate.constructor !== Object
                                            ? model.birthDate
                                            : ''
                                        }&email=${
                                            model.email ?? ''
                                        }&cell_phone=${model.phone.slice(-10)}`,
                                        '_blank'
                                        );
                                    }}
                                    >
                                    <b> Schedule Telemedicine <br /> Appointment </b>
                                    </Button>
                                    <br />
                                </>
                                )}
                            <Button
                                type="button"
                                size="sm"
                                libraryType="default"
                                accentColor={colors.darkBlue}
                                className='btn elevated-btn'
                                onClick={async () => {
                                downloadResultPDF(model, key);
                                }}
                            >
                                {downloadLoading && key === selectedKey
                                ? 'Processing...'
                                : <b>Download PDF</b>}
                            </Button>
                            <HideBtn>
                                {' '}
                                <Button
                                type="button"
                                size="sm"
                                libraryType="default"
                                accentColor={colors.darkBlue}
                                className='btn elevated-btn'
                                >
                                View Receipt
                                </Button>
                            </HideBtn>
                        </FlexboxButton>
                        }
                    </Grid>
                </Grid>
              )
              :
                <Grid container spacing={3} alignItems='center' justifyContent='space-between'>
                    <Grid item xs={12} lg={2} md={4} sm={6}>
                        <GridItem>
                            <FlexboxIcon>
                                <MdDateRange size={22} />
                            </FlexboxIcon>
                            <div>
                                <strong>Date</strong>
                                <br />
                                {model?.slot?.date
                                    ? format(new Date(model?.slot?.date), 'MMMM dd,yyyy')
                                    : ''}
                            </div>
                        </GridItem>
                    </Grid>
                    <Grid item xs={12} lg={2} md={4} sm={6}>
                        <GridItem>
                            <FlexboxIcon>
                                <BiTimeFive size={22} />
                            </FlexboxIcon>
                            <div>
                                <strong>Test</strong>
                                <br />
                                {model.isAntigen || model.isPanel ? 'Antigen' : 'COVID-19 RT-PCR'}
                            </div>
                        </GridItem>
                    </Grid>
                    <Grid item xs={12} lg={2} md={4} sm={6}>
                        <GridItem>
                            <FlexboxIcon>
                                <BiTimeFive size={22} />
                            </FlexboxIcon>
                            <div>
                                <strong>Result</strong>
                                <br />
                                {model.results}
                            </div>
                        </GridItem>
                    </Grid>
                    <Grid item xs={12} lg={2} md={6} sm={6}>
                        <GridItem>
                            <FlexboxIcon>
                                <IoPersonAddOutline size={22} />
                            </FlexboxIcon>
                            <div>
                                <strong>Test Type</strong>
                                <br />
                                {model.isRapidTest
                                    ? 'Rapid PCR Test'
                                    : model.isExpressSameDayTest
                                    ? 'Express PCR Test'
                                    : model.isAntigen
                                    ? 'Antigen Test'
                                    : model.isPanel
                                    ?  'Flu A&B/Covid-19'
                                    : 'Standard PCR Test'}
                            </div>
                        </GridItem>           
                    </Grid>
                    <Grid item xs={12} lg={2} md={4} sm={12}>
                        <FlexboxButton>
                            {model?.results === 'DETECTED' &&
                                model?.location?.test2Treat === true && (
                                <>
                                    <Button
                                    type="button"
                                    size="sm"
                                    libraryType="default"
                                    accentColor={colors.red}
                                    className='btn elevated-btn'
                                    onClick={() => {
                                        window.open(
                                        `${
                                            config.telemedicine.drChronoUrl
                                        }&appointment_profile=484140&office=338666&written_reason_for_visit=COVID%2019%20Consultation&patient_name=${
                                            model.firstName ?? ''
                                        }%20${model.lastName ?? ''}&date_of_birth=${
                                            Object.keys(model.birthDate).length !== 0 &&
                                            model.birthDate.constructor !== Object
                                            ? model.birthDate
                                            : ''
                                        }&email=${
                                            model.email ?? ''
                                        }&cell_phone=${model.phone.slice(-10)}`,
                                        '_blank'
                                        );
                                    }}
                                    >
                                    <b> Schedule Telemedicine <br /> Appointment </b>
                                    </Button>
                                    <br />
                                </>
                                )}
                            <Button
                                type="button"
                                size="sm"
                                libraryType="default"
                                accentColor={colors.darkBlue}
                                className='btn elevated-btn'
                                onClick={async () => {
                                downloadResultPDF(model, key);
                                }}
                            >
                                {downloadLoading && key === selectedKey
                                ? 'Processing...'
                                : <b>Download PDF</b>}
                            </Button>
                            <HideBtn>
                                {' '}
                                <Button
                                type="button"
                                size="sm"
                                libraryType="default"
                                accentColor={colors.darkBlue}
                                className='btn elevated-btn'
                                >
                                View Receipt
                                </Button>
                            </HideBtn>
                        </FlexboxButton>
                    </Grid>
                </Grid>
              }

            {/* <ExapndlistTag>
              <FlexboxOne>
                <FlexboxIcon>
                  <MdDateRange size={25} />
                </FlexboxIcon>
                <FlexboxText>
                  {' '}
                  <strong>Date</strong>
                  <br />
                  {model?.slot?.date
                    ? format(new Date(model?.slot?.date), 'MMMM dd,yyyy')
                    : ''}
                  <br />
                </FlexboxText>
              </FlexboxOne>
              <FlexboxFive>
                <Vl></Vl>
              </FlexboxFive>
              <FlexboxTwo>
                <FlexboxIcon>
                  <BiTimeFive size={25} />
                </FlexboxIcon>
                <FlexboxText>
                  {' '}
                  <strong>Test</strong>
                  <br />
                  {model.isAntigen ? 'Antigen' : 'COVID-19 RT-PCR'}
                  <br />
                </FlexboxText>
              </FlexboxTwo>
              <FlexboxFive>
                <Vl></Vl>
              </FlexboxFive>
              <FlexboxThree>
                <FlexboxIcon>
                  <BiTimeFive size={25} />
                </FlexboxIcon>
                <FlexboxText>
                  {' '}
                  <strong>Result</strong>
                  <br />
                  {model.results}
                  <br />
                </FlexboxText>
              </FlexboxThree>
              <FlexboxFive>
                <Vl></Vl>
              </FlexboxFive>
              <FlexboxTwo>
                <FlexboxIcon>
                  <IoPersonAddOutline size={25} />
                </FlexboxIcon>
                <FlexboxText>
                  {' '}
                  <strong>Test Type</strong>
                  <br />
                  {model.isRapidTest
                    ? 'Rapid PCR Test'
                    : model.isExpressSameDayTest
                    ? 'Express PCR Test'
                    : model.isAntigen
                    ? 'Antigen Test'
                    : 'Standard PCR Test'}
                  <br />{' '}
                </FlexboxText>
              </FlexboxTwo>
              <FlexboxFive>
                <Vl></Vl>
              </FlexboxFive>
              <FlexboxFour>
                <FlexboxButton>
                  {model?.results === 'DETECTED' &&
                    model?.location?.test2Treat === true && (
                      <>
                        <Button
                          type="button"
                          size="sm"
                          libraryType="default"
                          accentColor={colors.red}
                          style={{
                            background: '#FFFFFF',
                            border: '1px solid #EE782B',
                            borderRadius: '1500px',
                          }}
                          onClick={() => {
                            window.open(
                              `${
                                config.telemedicine.drChronoUrl
                              }&appointment_profile=484140&office=338666&written_reason_for_visit=COVID%2019%20Consultation&patient_name=${
                                model.firstName ?? ''
                              }%20${model.lastName ?? ''}&date_of_birth=${
                                Object.keys(model.birthDate).length !== 0 &&
                                model.birthDate.constructor !== Object
                                  ? model.birthDate
                                  : ''
                              }&email=${
                                model.email ?? ''
                              }&cell_phone=${model.phone.slice(-10)}`,
                              '_blank'
                            );
                          }}
                        >
                         <b> Schedule Telemedicine <br /> Appointment </b>
                        </Button>
                        <br />
                      </>
                    )}
                  <Button
                    type="button"
                    size="sm"
                    libraryType="default"
                    accentColor={colors.darkBlue}
                    className='btn elevated-btn'
                    onClick={async () => {
                      downloadResultPDF(model, key);
                    }}
                  >
                    {downloadLoading && key === selectedKey
                      ? 'Processing...'
                      : <b>Download PDF</b>}
                  </Button>
                  <HideBtn>
                    {' '}
                    <Button
                      type="button"
                      size="sm"
                      libraryType="default"
                      accentColor={colors.darkBlue}
                    >
                      View Receipt
                    </Button>
                  </HideBtn>
                </FlexboxButton>
              </FlexboxFour>{' '}
            </ExapndlistTag> */}
          </WrapContent>
        </WrapContainer>
      ))}
      {!results.length && (
        <TextWrapContainer>
          <WrapText>You have no results</WrapText>
        </TextWrapContainer>
      )}
    </Mainpage>
  );
};

export default AppointmentResult;
