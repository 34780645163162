import React, { useState, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { useFormikContext } from 'formik';
import { useAsync } from 'react-async-hook';
import { Popconfirm } from 'antd';
import { Breakpoints } from '../../../../dictionaries';

// import { isValidZIP } from '../../../../validations';

import Input from '../../../shared/components/form/Input';
import FormLabel from '../../../shared/components/form/FormLabel';
import { AnyObject } from '../../../shared/models';
import { colors } from '../../../../styles/colors';
import { getPostalCodeDetails } from '../../api';
import { PostalCodes } from '../../../register/components/PostalCode';

const Container = styled.div`
  position: relative;
  margin-bottom: 35px;
`;

const Address = styled.div`
  position: absolute;
  bottom: -30px;
  font-size: 14px;
  line-height: 22px;
  opacity: ${({ show }: { show: boolean }) => (show ? 1 : 0)};
  transition: opacity 0.15s ease-in-out;
  margin-top: -10px;
`;

const PopConfirmStyle = createGlobalStyle`
  .customized-popover-confirm {
    .ant-popover-content {
      .ant-popover-inner {
        border-radius: 10px;

        .ant-popover-inner-content {
          .ant-popover-message-title {
            font-size: 14px;
          }

          .ant-popover-buttons {
            .ant-btn {
              box-sizing: content-box;
              font-size: 14px;
              line-height: 1.6;
              border-radius: 5px;
              padding: 4px 15px;
              font-weight: normal;
              cursor: pointer;

              &.ant-btn-sm{
                border: 1px solid ${colors.darkBlue};
                color: ${colors.darkBlue};
                background: ${colors.white};
              }

              &.ant-btn-primary.ant-btn-sm {
                border: 1px solid ${colors.darkBlue};
                color: ${colors.white};
                background: ${colors.darkBlue};
              }
            }
          }
        }
      }
    }
  }
`;

interface Props {
  updateAddressValue: (newValue: any) => void;
  updateCountyValue: (newValue: any) => void;
}

const fetchAddressByZipCode = async (zipCode: string) =>
  // (
  //   await fetch(
  //     `https://maps.googleapis.com/maps/api/geocode/json?components=postal_code:${zipCode}|country:US&key=${process.env.REACT_APP_GEOCODE_API_KEY}`
  //   )
  // ).json();
  await getPostalCodeDetails(zipCode);

const ZipCodeInput: React.FC<Props> = ({
  updateAddressValue,
  updateCountyValue,
}) => {
  const { values, errors } = useFormikContext<{ address: AnyObject }>();
  const address: any = values.address;
  console.log("schedule adress",address)
  const [popupVisible, setPopupVisible] = useState(false);
  const [city, setCity] = useState('');
  const [state, setState] = useState({
    longName: '',
    shortName: '',
  });

  const [county, setCounty] = useState(String);

  const { result }: any = useAsync(fetchAddressByZipCode, [address.zipCode]);


  const hasResult =
    address.zipCode &&
    result !== undefined &&
    result!.length > 0 &&
    Array.isArray(result);

  const handleUpdate = () => {
    updateAddressValue({
      city: city,
      state: state.shortName,
    });
  };

  const handleCancel = () => {
    setPopupVisible(false);
  };
  let isValidZIP: any;
  useEffect(() => {
    if (hasResult) {
      // const { results } = result;
      for (const component of result) {
        if (component.ZipCode && component.County) {
          updateCountyValue({ county: component.County });
        }
      }
    }
  }, [hasResult, result]);


  return (
    <Container>
      <FormLabel label="ZIP Code">
        <PopConfirmStyle />
        {/* <Popconfirm
          cancelText="Cancel"
          okText="Update"
          title={'The zip code doesn\'t match with your city and state'}
          placement={'topLeft'}
          visible={popupVisible}
          onCancel={handleCancel}
          onConfirm={handleUpdate}
          onVisibleChange={handleCancel}
          overlayClassName={'customized-popover-confirm'}
        > */}
        <Input
        value={address.zipCode}
          name="address.zipCode"
          onChange={(value) => {
            updateAddressValue({ zipCode: value });
          }}
          validate={(value) => {
            let error;

            
            PostalCodes.map((codes) => {
              if (codes.Country == address.country) {
                isValidZIP = new RegExp(codes.Regex)
              }
            });
            if (!isValidZIP.test(value)) {
              error = `Please enter valid ${address.country} postal code`;
            }

            if (!value) {
              error = 'Required field';
            }

            return error;
          }}
        />
        {/* </Popconfirm> */}
      </FormLabel>
      {/* <Address show={hasResult && errors?.address?.zipCode === undefined}>
        {result?.results?.[0]?.formatted_address}
      </Address> */}
    </Container>
  );
};

export default ZipCodeInput;
