import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import styled from 'styled-components';
import { differenceInYears, format, parse } from 'date-fns';
import {
    IoLocationOutline,
    IoPersonOutline,
    IoPeopleOutline,
} from 'react-icons/io5';
import { MdExpandMore, MdExpandLess, MdDateRange } from 'react-icons/md';

import Inputs from '../../../shared/components/form/Input';
import {
    Container,
    Content,
    PageHeader,
    PageTitle,
    ErrorText,
    ErrorTextLarge,
    SubHeaderDescription,
} from '../../../shared/components/styled';
import { Button } from '../../../shared/components/styled';
import ChangeMinorModal from '../ChangePersonalInfo/ChangeMinorModal';
import ChangeNameDobModal from '../ChangePersonalInfo/ChangeNameDobModal';
import { useManageDispatch } from '../../../manage/provider';

import {
    useStepsState,
    useStepsDispatch,
    newMinor,
} from '../../../schedule/provider';
import { Breakpoints } from '../../../../dictionaries';
import { config } from '../../../../config';
import { colors } from '../../../../styles/colors';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import NavigationButtons from '../../../shared/components/NavigationButtons';
import FormLabel from '../../../shared/components/form/FormLabel';
import { DatepickerField } from '../../../shared/components/Datepicker';
import { country } from '../../../schedule/components/PersonalInformation/PassportCountry';
import Select from '../../../shared/components/form/Select';
import { ReactComponent as PlusIcon } from '../../../../assets/plus.svg';
import Minors from './Minors';
import { useSharedState, useSharedDispatch } from '../../../shared/provider';
import { updateRegister, getLosLomitasDetails } from '../../api';
import check from '../../../../assets/check.svg';
import { Divider } from 'antd';
import { filter } from 'lodash';
import { IDropDown } from '../../models';
import mixpanel from 'mixpanel-browser';
import { firebase } from '../../../../firebase';
import GoogleAnalytics from '../../../../GoogleAnalytics';
import ChangeNameModal from '../ChangePersonalInfo/ChangeNameModal';

const WrapContainer = styled.div`
  height: 70px;
  border: 0.5px solid rgba(36, 61, 77, 0.14);
  border-radius: 12px;
  background: ${colors.rebrandBlack};
  margin-bottom: 30px;
  align-items: center;
  padding: 20px;
  width: 100%;
  @media (max-width: ${Breakpoints.sm}px) {
    position: relative;
    width: 100%;
    height:45%;
  }
`;
const WrapContent = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  gap: 0px;
  @media (max-width: ${Breakpoints.sm}px) {
    display: grid;
    gap: 5px;
  }
`;
const Flexbox = styled.div`
  display: flex;
  align-items: center;
  width: 40%;
  &:nth-child(1) {
    width: max-content;
    margin-right: 5px;
  }
  &:nth-child(2) {
    border: none;
    border-right: 1px solid #2a5f87;
    margin-right: 0px;
    padding-right: 0px;
  }
  &:nth-child(3) {
    margin-left: 50px;
    border-right: 1px solid #2a5f87;
  }
  &:nth-child(4) {
    margin-left: 50px;
  }
  @media (max-width: ${Breakpoints.sm}px) {
    margin-bottom: 20px;
    &:nth-child(1),
    &:nth-child(2) {
      width: 100%;
      border-right: none;
    }
    &:nth-child(4) {
      margin-left: 0px;
      border-right: none;
    }
    &:nth-child(3) {
      width: 100%;
      margin-left: 0px;
      border-right: none;
    }
  }
`;

const FlexboxIcon = styled.div`
  position: relative;
  color: ${colors.grey};
  display: inline-flex;
`;
const FlexboxText = styled.div`
   display: block;
  font-size: 14px;
  font-weight: 500;
  padding-left: 0px;
  word-break: break-all;
  color: #FFF;
`;

export const Input = styled.input`
  height: 19px;
  width: 19px;
  border: 1px solid ${colors.blueLight};
  border-radius: 0px;
  outline: none;
  transition-duration: 0.3s;
  cursor: pointer;
  background-color: ${colors.white};
`;

const useStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow:
            'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage:
            'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#137cbd',
        backgroundImage:
            'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    },
});

const PageSubTitle = styled.h3`
  margin: 10px 0 10px 0;
`;

const MinorContainer = styled.div`
  width: 100%;
`;
const AddMinor = styled.button`
  font-family:'Quicksand';
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: none;
  padding: 20px;
  margin-top: 30px;
  border: 2px dashed ${colors.green};
  border-radius: 10px;
  color: ${colors.rebrandBlack};
  margin-bottom: 20px;
  & > * {
    margin: 0 5px;
  }
`;

const Center = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`;

const PassportRow = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;
  & > *:first-child {
    width: 50%;
  }
  & > *:last-child {
    width: 50%;
  }
  @media (max-width: ${Breakpoints.sm}px) {
    gap: 5px;
    flex-direction: column;
    & > *:first-child {
      width: 100%;
    }
    & > *:last-child {
      width: 100%;
      
    }
  }
`;
const Gap = styled.div`
  width: 90px;
  @media  (min-width: ${Breakpoints.sm}px) and (max-width: ${Breakpoints.xl}px) {
    width: 0px;
  }
`;

function StyledRadio(props: RadioProps) {
    const classes = useStyles();

    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            // checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            // icon={<span className={classes.icon} />}
            {...props}
            style={{
                color: '#DADADA'
            }}
        />
    );
}

const SchedulingInfo: React.FC = () => {
    const db = firebase.firestore();
    const {
        form: {
            minors,
            passportCountry,
            passportNo,
            userSelect,
            testSelection,
            cPartnerID,
            schoolTestFor,
            studentID,
            school,
            location,
            payingMethod,
        },
    } = useStepsState();
    useEffect(() => {
        GoogleAnalytics(form, '/Who are you scheduling this test for?', 'ScheduleInfo');
        handlePaymentMethod()
    }, [])
    const { userData, apptFromSchdule, travellType, locations, isWalkUp,destination } = useSharedState();
    const { setloggedUserData } = useSharedDispatch();
    const {
        updateFormValues,
        goToNextStep,
        goToPrevStep,
        goToSkip2Step,
        goToSkipStep,
        toggleChangeLocationModal,
        updatesFormValues,
        goToSkip3Step
    } = useStepsDispatch();
    const { form } = useStepsState();
    const [loading, setLoading] = useState<boolean>(false);
    const [openMinorDialog, setopenMinorDialog] = React.useState<boolean>(false);
    const [editKey, setEditKey] = useState({ key: -1 });
    const [addMinor, setAddMinor] = useState(false);
    const [check, setCheck] = useState(userSelect);
    const [count, setCount] = useState(0);
    const [openNameDialog, setopenNameDialog] = React.useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [schoolDropDown, setSchoolDropDown] = useState<IDropDown[]>([]);
    const [hasSchoolList, setHasSchoolList] = useState<boolean>(false);
    const [insurance, setInsurance] = useState(false);
    const [noInsurance, setNoInsurance] = useState(false);
    const [creditCard, setCreditCard] =useState(false);
    const [schoolShowList, setSchoolShowList] = useState<string>(
        (cPartnerID === 'BEL001'
            ? userData?.schoolTestFor === 'faculty'
                ? 'communityMember'
                : userData?.schoolTestFor
            : userData?.schoolTestFor === 'communityMember'
                ? 'faculty'
                : userData?.schoolTestFor) || ''
    );
    const [schoolValidationMethod, setSchoolValidationMethod] = useState<string>(
        userData?.schoolTestFor || ''
    );

    const { updateStandradTest } = useManageDispatch();
    const { upateTestType } = useSharedDispatch();

    const [userDataArray, setUserDataArray] = useState<any>([]);

    let userDataMinors: any =
        userData?.hasMinors !== undefined && userData?.hasMinors
            ? userData?.minors
            : [];

    const handleMinorOpen = () => {
        setopenMinorDialog(true);
    };
    const handleMinorClose = () => {
        setopenMinorDialog(false);
    };
    const handleMinorReload = () => {
        setopenMinorDialog(false);
        setUserDataArray(userData?.minors);
    };
    const handleNameOpen = () => {
        setopenNameDialog(true);
    };
    const handleNameClose = () => {
        setopenNameDialog(false);
    };
    const handleSetError = () => {
        setError('');
    };
    const handlePaymentMethod = () => {
        if(location?.partnerName !== 'goHealth'){
          if (location?.isCreditCard.standard || location?.isCreditCard.antigen || location?.isCreditCard.rapid || location?.isCreditCard.express) {
            setCreditCard(true);
          } else {
            setCreditCard(false);
          }
          if (location?.isInsurance.standard || location?.isInsurance.antigen || location?.isInsurance.rapid || location?.isInsurance.express) {
            setInsurance(true);
          } else {
            setInsurance(false);
          }
          if (location?.isNoInsurance.standard || location?.isNoInsurance.antigen || location?.isNoInsurance.rapid || location?.isNoInsurance.express) {
            setNoInsurance(true);
          } else {
            setNoInsurance(false);
          }
          } else {
            toggleChangeLocationModal(false)
            updatesFormValues({minors:[]})
            updateFormValues({minors:[]})
          }
        }
    const updateChangeOnClick = async (values: any) => {
        setLoading(true);
        const tmp = [...userDataMinors];
        values.minors.forEach((minor: any) => {
            tmp.push({
                ...minor,
                schoolTestFor:
                    form.cPartnerID === 'BEL001'
                        ? minor?.schoolTestFor === 'faculty'
                            ? 'communityMember'
                            : minor?.schoolTestFor
                        : minor?.schoolTestFor === 'communityMember'
                            ? 'faculty'
                            : minor?.schoolTestFor,
                birthDate:
                    minor.birthDate && format(minor.birthDate, config.dateFormat),
            });
        });
        try {
            let studentInfo = [{
                id: values?.minors[0]?.studentID,
                lastName: values?.minors[0]?.lastName,
                firstName: values?.minors[0]?.firstName,
                school: values?.minors[0]?.school,
                schoolTestFor: values?.minors[0]?.schoolTestFor,
            }]
            if (form.cPartnerID === 'LLT001') {
                const detail = await getLosLomitasDetails(studentInfo[0]);
                if (detail?.data?.status === 200) {
                    setError('')
                    updateFormValues({
                        location: detail?.data?.location,
                        slot: detail?.data?.slot,
                        date: detail?.data?.slot?.date
                    })
                    let resultdata = await updateRegister({
                        email: userData?.email,
                        hasMinors: true,
                        minors: [...tmp],
                    });

                    if (resultdata?.data?.status === 200) {
                        setloggedUserData(resultdata?.data?.data);
                        localStorage.setItem('user', JSON.stringify(resultdata?.data?.data));
                        setUserDataArray(resultdata?.data?.data.minors);
                        setAddMinor(false);
                        setLoading(false);
                    }
                } else {
                    setLoading(false);
                    setError(detail?.data?.messages !== undefined && detail?.data?.messages !== null ?
                        detail?.data?.messages :
                        'Invalid Student ID. Please contact your school administrator for your correct ID.');
                }
            } else {
                let resultdata = await updateRegister({
                    email: userData?.email,
                    hasMinors: true,
                    minors: [...tmp],
                });

                if (resultdata?.data?.status === 200) {
                    setloggedUserData(resultdata?.data?.data);
                    localStorage.setItem('user', JSON.stringify(resultdata?.data?.data));
                    setUserDataArray(resultdata?.data?.data.minors);
                    setAddMinor(false);
                    setLoading(false);
                }
            }

        } catch (e) {
            setLoading(false);
            console.error(e);
        }
    };

    const handleUpdateRegister = async (values: any) => {
        setLoading(true);
        try {
            let studentInfo;
            var filteredlocation = filter(locations, function (data: any) {
                return data.cPartnerID == cPartnerID;
            });

            let data = filteredlocation.find((location: any) => location.name == minors[0]?.school?.value);
            if (values.registeringFor === 'minorOnly') {
                let dataMinor: any = minors[0]?.school;
                studentInfo = [{
                    id: minors[0]?.studentID,
                    lastName: minors[0]?.lastName,
                    firstName: minors[0]?.firstName,
                    school: cPartnerID === "LLT001" ? { ...dataMinor, qbenchCustomerId: data?.qbenchCustomerId } : values?.minors[0]?.school,
                    schoolTestFor: minors[0]?.schoolTestFor,
                }]
            }

            else if (values.registeringFor === 'myself') {
                studentInfo = [{
                    id: values?.studentID,
                    lastName: values?.lastName,
                    firstName: values?.firstName,
                    school: values?.school,
                    schoolTestFor: values?.schoolTestFor,
                }]
            }

            if (form.cPartnerID === 'LLT001' && studentInfo !== undefined && studentInfo) {
                const detail = await getLosLomitasDetails(studentInfo?.[0]);
                if (detail?.data?.status === 200) {
                    setError('')
                    updateFormValues({
                        location: detail?.data?.location,
                        slot: detail?.data?.slot,
                        date: detail?.data?.slot?.date
                    })
                    let resultdata = await updateRegister({
                        email: userData?.email,
                        school: values.school,
                        schoolTestFor: schoolShowList,
                        studentID: values.studentID,
                        minors: userDataArray.length
                            ? userDataArray?.map((minor: any) => {
                                if (minor['isChecked'] === true || minor['isChecked'] === false) {
                                    delete minor['isChecked'];
                                    delete minor['index'];
                                }
                                return minor;
                            })
                            : [],
                    });
                    if (resultdata?.data?.status === 200) {
                        setloggedUserData(resultdata?.data?.data);
                        localStorage.setItem('user', JSON.stringify(resultdata?.data?.data));
                        setLoading(false);
                        // if (cPartnerID === 'POR001') {
                        console.log('12')
                        // updateFormValues({ payingMethod: 'Insurance' });
                        // updateFormValues({ hasInsurance: true });
                        if(payingMethod === 'No Payment') {
                            updateFormValues({ hasInsurance: false,payingMethod:'No Payment' });
                            goToSkipStep();
                            goToSkipStep();
                            goToNextStep();
                        }
                        // upateTestType(true);
                        // updateStandradTest(true);
                        // goToNextStep();
                        // goToSkipStep();
                        // // }

                        // goToNextStep();
                    }
                    setLoading(false);

                } else {
                    setLoading(false);
                    setError(detail?.data?.messages !== undefined && detail?.data?.messages !== null ?
                        detail?.data?.messages :
                        'Invalid Student ID. Please contact your school administrator for your correct ID.');
                }
            } else {
                let resultdata = await updateRegister({
                    email: userData?.email,
                    school: values.school,
                    schoolTestFor: schoolShowList,
                    studentID: values.studentID,
                    minors: userDataArray.length
                        ? userDataArray?.map((minor: any) => {
                            if (minor['isChecked'] === true || minor['isChecked'] === false) {
                                delete minor['isChecked'];
                                delete minor['index'];
                            }
                            return minor;
                        })
                        : [],
                });
                if (resultdata?.data?.status === 200) {
                    setloggedUserData(resultdata?.data?.data);
                    localStorage.setItem('user', JSON.stringify(resultdata?.data?.data));
                    setLoading(false);
                    // if (cPartnerID === 'POR001' || cPartnerID === 'LLT001') {
                    console.log('12')
                    updateFormValues({ payingMethod: 'Insurance' });
                    updateFormValues({ hasInsurance: true });
                    upateTestType(true);
                    updateStandradTest(true);
                    goToNextStep();
                    goToSkipStep();
                    // }

                    goToNextStep();
                }
                setLoading(false);
            }
        } catch (e) {
            setLoading(false);
            console.error(e);
        }
    };

    const confirmationSchoolSelectionHandler = (method: string) => {
        setSchoolValidationMethod(method);

        if (method === 'student') {
            setSchoolShowList('student');
        } else if (method === 'parentOrGuardian') {
            setSchoolShowList('parentOrGuardian');
        } else if (method === 'staff') {
            setSchoolShowList('staff');
        } else if (method === 'faculty') {
            setSchoolShowList('faculty');
        } else {
            setSchoolShowList(method);
        }
    };

    const getSchoolList = async () => {
        const response = db
            .collection('clientpartner')
            .where('cPartnerID', '==', cPartnerID);
        const snapShotData = await response.get();
        const data = snapShotData.docs.map((doc: any) => doc.data());

        const schools = data[0].Schools;
        if (schools) {
            var dropDown: any = [];
            schools.forEach((school: any) => {
                dropDown.push({ label: school, value: school });
            });
            if (dropDown.length === 1) {
                updateFormValues({ school: dropDown[0].value })
            }
            setSchoolDropDown(dropDown);
            setHasSchoolList(true);
        }
    };

    React.useEffect(() => {
        window.scrollTo(0, 0);
        console.log(schoolDropDown);

        //  getSchoolList();
        if (true) {
            var filteredlocation = filter(locations, function (data: any) {
                return data.cPartnerID == cPartnerID;
            });
            var dropDown: any = [];
            filteredlocation.forEach((location) => {
                dropDown.push({ label: location.name, value: location.name, qbenchCustomerId: location.qbenchCustomerId });
            });
            if (dropDown.length === 1) {
                updateFormValues({ school: dropDown[0].value })
            }
            setSchoolDropDown(dropDown);
        }
        if (
            userData?.minors !== undefined &&
            userData?.minors.length > 0 &&
            userData?.minors !== null
        ) {
            let userUpdatedArray: any = [];
            userData?.minors.map((item: any, index: any) => {
                userUpdatedArray.push({
                    ...item,
                    isChecked: false,
                    index: index,
                    school: item?.school !== undefined ? item?.school : '',
                    studentID: item?.studentID !== undefined ? item?.studentID : '',
                    schoolTestFor:
                        item?.schoolTestFor !== undefined
                            ? form.cPartnerID === 'BEL001'
                                ? item?.schoolTestFor === 'faculty'
                                    ? 'communityMember'
                                    : item?.schoolTestFor
                                : item?.schoolTestFor === 'communityMember'
                                    ? 'faculty'
                                    : item?.schoolTestFor
                            : '',
                });
            });
            minors.map((res: any) => {
                userUpdatedArray[res.index].isChecked = true;
                userUpdatedArray[res.index].school = res?.school;
                userUpdatedArray[res.index].studentID = res?.studentID;
                userUpdatedArray[res.index].schoolTestFor = res?.schoolTestFor;
            });
            setUserDataArray(userUpdatedArray);
        }
        if(location.qbenchCustomerId !== config.qbenchCustomerId.SFOAirport_BARTStation){
            updateFormValues({isAntigen: false, isRapidTest: false, isExpressSameDayTest: false, payingMethod: ''})
        }
        
    }, [userData]);
    React.useEffect(() => {
        if(location?.partnerName === 'goHealth'){
            toggleChangeLocationModal(false)
            // updatesFormValues({minors:[]})
            updateFormValues({minors:[]})
        }
    },[])
    const updateMinorsToProvider = (minor: any, checked: any, sIndex: any) => {
        userDataArray[sIndex].isChecked = checked;
        let updateMinors: any = minors;

        var filteredlocation = filter(locations, function (data: any) {
            return data.cPartnerID == cPartnerID;
        });

        var minorSchool;
        let minorSchoolData: any = filteredlocation.find((location) => location.name == minor.school.value);
        minorSchool = {
            ...minor.school,
            qbenchCustomerId: minorSchoolData?.qbenchCustomerId
        }

        let filtersData: any = updateMinors.filter(
            (res: any) => res.index === sIndex
        );

        if (filtersData.length > 0 && !checked) {
            for (var i = 0; i < updateMinors.length; i++) {
                if (updateMinors[i].index === sIndex) {
                    updateMinors.splice(i, 1);
                }
            }
        } else {
            //Add the minors to array
            updateMinors.push({
                ...minor,
                isChecked: checked,
                index: sIndex,
                school: minor?.school !== undefined ? cPartnerID === 'LLT001' ? minorSchool : minor.school : '',
                studentID: minor?.studentID !== undefined ? minor.studentID : '',
                schoolTestFor:
                    minor?.schoolTestFor !== undefined ?
                        form.cPartnerID === 'BEL001' ? minor?.schoolTestFor === 'faculty'
                            ? 'communityMember'
                            : minor?.schoolTestFor
                            : minor?.schoolTestFor === 'communityMember'
                                ? 'faculty'
                                : minor?.schoolTestFor
                        : '',
            });
        }

        updateFormValues({
            minors: updateMinors,
        });
    };

    const updateTheMinorsInformation = (
        fieldName: string,
        index: number,
        newValue: string | null
    ) => {
        // let updateMinors: any = userDataArray;
        let updateMinors: any = minors;
        // updateMinors[index][fieldName] = newValue;

        minors.map((res: any) => {
            if (index === res.index) {
                if (fieldName === 'school') {
                    res.school = { label: newValue, value: newValue };
                } else if (fieldName === 'schoolTestFor') {
                    res.schoolTestFor = newValue;
                } else if (fieldName === 'studentID') {
                    res.studentID = newValue;
                }
            }
        });

        updateFormValues({
            minors: updateMinors,
        });

        if (
            userData?.minors !== undefined &&
            userData?.minors.length > 0 &&
            userData?.minors !== null
        ) {
            let userUpdatedArray: any = [];
            userData?.minors.map((item: any, index: any) => {
                userUpdatedArray.push({
                    ...item,
                    isChecked: false,
                    index: index,
                    school: item?.school !== undefined ? item?.school : '',
                    studentID: item?.studentID !== undefined ? item?.studentID : '',
                    schoolTestFor:
                        item?.schoolTestFor !== undefined ? item?.schoolTestFor : '',
                });
            });
            minors.map((res: any) => {
                userUpdatedArray[res.index].isChecked = true;
                userUpdatedArray[res.index].school = res?.school;
                userUpdatedArray[res.index].studentID = res?.studentID;
                userUpdatedArray[res.index].schoolTestFor = res?.schoolTestFor;
            });

            setUserDataArray(userUpdatedArray);
        }
    };

    return (
        <Container size="xl">
            <Content>
                <Center>
                    <PageHeader>
                        <PageTitle className="title-wrap">
                            Who are you scheduling the test for?
                        </PageTitle>
                    </PageHeader>

                    <MinorContainer>
                        <Formik
                            initialValues={{
                                registeringFor: form.registeringFor,
                                userSelect: form.userSelect ? true : false,
                                passportCountry: !passportCountry
                                    ? userData?.passportCountry
                                    : passportCountry,
                                passportNo: !passportNo ? userData?.passportNo : passportNo,
                                school:
                                    !!userData && userData.school
                                        ? userData.school
                                        : form?.school,
                                studentID:
                                    !!userData && userData?.studentID
                                        ? userData?.studentID
                                        : form.studentID,
                                schoolTestFor: schoolShowList,
                                minors:
                                    !!userData && !!userData?.minors && userData?.minors.length
                                        ? userData?.minors.map((minor: any) => ({
                                            ...minor,
                                            birthDate:
                                                minor.birthDate &&
                                                parse(minor.birthDate, config.dateFormat, new Date()),
                                        }))
                                        : [],
                            }}
                            onSubmit={(values) => {
                                updateFormValues({
                                    registeringFor: values.registeringFor,
                                    userSelect: values.userSelect,
                                    passportCountry: values.passportCountry,
                                    passportNo: values.passportNo,
                                    school: values.school,
                                    schoolTestFor: values.schoolTestFor,
                                    studentID: values.studentID,
                                    // minors: values.minors.length
                                    //   ? values.minors.map((minor: any) => ({
                                    //       ...minor,
                                    //       birthDate:
                                    //         minor.birthDate &&
                                    //         format(minor.birthDate, config.dateFormat),
                                    //     }))
                                    //   : [],
                                });
                                setError('');
                                if(testSelection === "flight" && location?.disableTravelInsurance){
                                    updateFormValues({payingMethod:'Credit Card',hasInsurance:false})
                                    goToSkipStep()
                                    goToSkipStep() 
                                }
                                if (
                                    !userData?.firstName ||
                                    !userData?.lastName ||
                                    !userData?.birthDate
                                ) {
                                    setError(
                                        'Your details are incomplete. Please edit before proceed to next step.'
                                    );
                                } else if (testSelection === 'school') {
                                    setError('');
                                    let checks: boolean = true;
                                    minors.map((minor: any) => {
                                        if (minor?.schoolTestFor?.length === 0) {
                                            setError('Please select anyone');
                                            checks = false;
                                        }
                                    });
                                    if (checks) handleUpdateRegister(values);
                                    return
                                }
                                //  else if (testSelection==='community') {
                                //   updateFormValues({ hasInsurance: true,payingMethod:'Insurance' });
                                //   goToNextStep()
                                //    goToSkip2Step()
                                // }
                                if(payingMethod==='No Payment' || cPartnerID === 'KEN001' || testSelection === 'kern') {
                                    updateFormValues({ hasInsurance: false,
                                        payingMethod:'No Payment', 
                                        isAntigen: true,
                                        cPartnerID: 'KEN001',
                                        testSelection: 'kern' });
                                    goToSkipStep();
                                }
                                else {
                                    setError('');
                                    mixpanel.track('user details page');
                                    if(!insurance && creditCard && !noInsurance /*payingMethod === 'Credit Card'*/) {
										if((!location?.standardTest || !location?.isCreditCard.standard) && location?.isExpressSameDay && location?.isCreditCard.express){
											updateFormValues({isExpressSameDayTest: true})
										}else if((!location?.standardTest || !location?.isCreditCard.standard) && (!location?.isExpressSameDay || !location?.isCreditCard.express) && location?.rapidTest && location?.isCreditCard.rapid ){
											updateFormValues({isRapidTest: true})
										}else if((!location?.standardTest || !location?.isCreditCard.standard) && (!location?.isExpressSameDay || !location?.isCreditCard.express) && (!location?.rapidTest || !location?.isCreditCard.rapid) && location?.isAntigen && location?.isCreditCard.antigen ) {
											updateFormValues({isAntigen : true})
										}
										updateFormValues({ hasInsurance: false,payingMethod:'Credit Card' });
										goToSkipStep();
										goToSkipStep();
                                        goToNextStep();
									}
									else if(insurance && !creditCard && !noInsurance /*payingMethod === 'Insurance'*/) {
										updateFormValues({ hasInsurance: true,payingMethod:'Insurance' });
										goToSkipStep();
                                        goToSkipStep();
									} else if(!insurance && !creditCard && noInsurance /*payingMethod === 'No Insurance'*/) {
										updateFormValues({ hasInsurance: false,payingMethod:'No Insurance' });
										goToSkipStep();
                                        goToNextStep();
									}
                                    else goToNextStep();
                                    // if (isWalkUp.active) {
                                    //     goToNextStep()
                                    // }
                                    if(location.qbenchCustomerId===config.qbenchCustomerId.SFOAirport_BARTStation && destination=='JPN' && travellType=='INT' ){
                                        updateFormValues({payingMethod:'Credit card',hasInsurance:false,isRapidTest:true})
                                        // goToNextStep()
                                        goToSkip3Step()
                                      }
                                    // if (((testSelection === 'flight' || testSelection === 'general') && form.address.country !== 'United States') || (location?.isInsuranceDisabled !== undefined && location.isInsuranceDisabled === true && location?.qbenchCustomerId !== config.qbenchCustomerId.SFOAirport_BARTStation
                                    //     && location?.qbenchCustomerId !== config.qbenchCustomerId.Atlanta)
                                    // ) {
                                    //     // updateFormValues({ payingMethod: 'Credit card' });
                                    //     // goToSkip2Step();
                                    //     // goToSkipStep();
                                    //     goToNextStep();
                                    //     // location?.qbenchCustomerId === config.qbenchCustomerId.SFOAirport_BARTStation && goToSkipStep()
                                    // }

                                    // SFO FLOW allows RAPID Test for the locationID 237
                                    // else if (location?.qbenchCustomerId === config.qbenchCustomerId.SFOAirport_BARTStation && testSelection === 'sfoairport') {
                                    //     updateFormValues({ hasInsurance: true, payingMethod: 'Insurance' });
                                    //     goToNextStep()
                                    //     goToSkip2Step()
                                    // }
                                    //   } else if (location?.qbenchCustomerId===config.qbenchCustomerId.BoysGirlsClub) {
                                    //         updateFormValues({ hasInsurance: true,payingMethod:'Insurance' });
                                    //         goToNextStep()
                                    //         goToSkip2Step()
                                    //     }
                                    // else goToNextStep();
                                    // console.log(form.address.country);

                                    // if (((testSelection === 'flight' || testSelection === 'general') && form.address.country === 'United States') &&
                                    //     ([config.qbenchCustomerId.WestPalmBeach_AmericanResearchLabs,
                                    //     config.qbenchCustomerId.FortLauderdale_AmericanResearchLabs,
                                    //     config.qbenchCustomerId.PalmBeachGardens_AmericanResearchLabs,
                                    //     config.qbenchCustomerId.WestPalmBeach_CTRX, config.qbenchCustomerId.Atlanta].includes(location?.qbenchCustomerId))) {
                                    //     updateFormValues({ payingMethod: 'Credit card', hasInsurance: false });
                                    //     goToNextStep();
                                    //     goToNextStep();
                                    //     goToNextStep();
                                    //     goToNextStep();
                                    // }
                                    // if(location.qbenchCustomerId === config.qbenchCustomerId.LasVegasSpringValley)
                                    // {
                                    //     goToSkip2Step()
                                    //     goToNextStep()
                                    // }
                                    //   goToNextStep();
                                }
                            }}
                        >
                            {({ setFieldValue, values }) => {
                                return (
                                    <Form>
                                        <WrapContainer key={-1}>
                                            <WrapContent>
                                                <Flexbox>
                                                    {cPartnerID !== 'LLT001' && (
                                                        <>
                                                            <Input
                                                                type="checkbox"
                                                                style={{
                                                                    accentColor: '#243D4D',
                                                                }}
                                                                defaultChecked={form.userSelect ? true : false}
                                                                onChange={(event) => {
                                                                    if (event.target.checked) {
                                                                        setCheck(true);
                                                                        setFieldValue('userSelect', true);

                                                                        if (minors.length) {
                                                                            setFieldValue(
                                                                                'registeringFor',
                                                                                'myselfAndMinor'
                                                                            );
                                                                        } else {
                                                                            setFieldValue('registeringFor', 'myself');
                                                                        }
                                                                    } else {
                                                                        setCheck(false);
                                                                        setFieldValue('userSelect', false);

                                                                        if (minors.length) {
                                                                            setFieldValue('registeringFor', 'minorOnly');
                                                                        } else {
                                                                            setFieldValue('registeringFor', '');
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                        </>
                                                    )}
                                                </Flexbox>

                                                <Flexbox>
                                                    <FlexboxIcon>
                                                        <IoPersonOutline size={25} />
                                                    </FlexboxIcon>
                                                    <FlexboxText>
                                                        <b>{'Name : '}</b>
                                                        {userData?.firstName} {userData?.middleName}{' '}
                                                        {userData?.lastName}
                                                    </FlexboxText>
                                                </Flexbox>
                                                <Flexbox>
                                                    <FlexboxIcon>
                                                        <MdDateRange size={25} />
                                                    </FlexboxIcon>
                                                    <FlexboxText>
                                                        <b>{'Date of Birth : '}</b>
                                                        {userData?.birthDate}
                                                    </FlexboxText>
                                                </Flexbox>
                                                <Flexbox>
                                                    <Button
                                                        type="button"
                                                        onClick={() => {
                                                            handleMinorOpen();
                                                            setEditKey({ key: -1 });
                                                            handleNameOpen();
                                                        }}
                                                        size="sm"
                                                        libraryType="default"
                                                        className='btn elevated-btn'
                                                    >
                                                        Edit
                                                    </Button>
                                                    {editKey.key === -1 && (
                                                        <ChangeNameModal
                                                            handleNameClose={handleNameClose}
                                                            open={openNameDialog}
                                                            isProfile={true}
                                                            // setErrormsg={handleSetError}
                                                        />
                                                    )}
                                                </Flexbox>
                                            </WrapContent>
                                        </WrapContainer>
                                        {travellType === 'INT' && check && (
                                            <PassportRow>
                                                <FormLabel label="Passport Country ">
                                                    <Select<string>
                                                        name="passportCountry"
                                                        options={country}
                                                        validate={(value) => {
                                                            if (!value) {
                                                                return 'Required field';
                                                            }
                                                        }}
                                                    />
                                                </FormLabel>
                                                <FormLabel label="Passport # ">
                                                    <Inputs
                                                        name="passportNo"
                                                        value={form?.passportNo}
                                                        onChange={(value) =>
                                                            updateFormValues({ passportNo: value })
                                                          }
                                                        validate={(value) => {
                                                            if (!value || !value.trim().length) {
                                                                return 'Required field';
                                                            }
                                                        }}
                                                        isRequired
                                                    />
                                                </FormLabel>
                                            </PassportRow>
                                        )}
                                        {testSelection === 'school' &&
                                            check &&
                                            cPartnerID !== 'POR001' && (
                                                <>
                                                    <SubHeaderDescription style={{ marginBottom: '5px' }}>
                                                        I am booking for a{' '}
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </SubHeaderDescription>
                                                    <WrapContainer>
                                                        <FormControl
                                                            component="fieldset"
                                                            variant="standard"
                                                        >
                                                            <RadioGroup
                                                                row
                                                                name={'schoolTestFor'}
                                                                value={schoolValidationMethod}
                                                                onChange={(event) => {
                                                                    updateFormValues({
                                                                        schoolTestFor: event.target.value,
                                                                    });
                                                                    confirmationSchoolSelectionHandler(
                                                                        event.target.value
                                                                    );
                                                                }}
                                                            >
                                                                {form?.cPartnerID !== 'LLT001' && <>
                                                                <FormControlLabel
                                                                    className="white"
                                                                        value="student"
                                                                        control={<StyledRadio />}
                                                                        checked={schoolShowList === 'student'}
                                                                        label={'Student'}
                                                                    />
                                                                    <Gap />
                                                                </>}
                                                                
                                                                {form?.cPartnerID !== 'SCS001' && form?.cPartnerID !== 'LLT001' && <>
                                                                <FormControlLabel
                                                                    className="white"
                                                                        value="parentOrGuardian"
                                                                        control={<StyledRadio />}
                                                                        checked={
                                                                            schoolShowList === 'parentOrGuardian'
                                                                        }
                                                                        label={'Parent/Guardian of student'}
                                                                    />
                                                                    {/* <Gap /> */}
                                                                </>}
                                                            <FormControlLabel
                                                                className="white"
                                                                    value="staff"
                                                                    control={<StyledRadio />}
                                                                    checked={schoolShowList === 'staff'}
                                                                    label={'Staff'}
                                                                />
                                                                <Gap />
                                                                {form?.cPartnerID !== 'SCS001' && form?.cPartnerID !== 'LLT001' && <>
                                                                <FormControlLabel
                                                                    className="white"
                                                                        value={
                                                                            form?.cPartnerID === 'BEL001'
                                                                                ? 'communityMember'
                                                                                : 'faculty'
                                                                        }
                                                                        control={<StyledRadio />}
                                                                        checked={
                                                                            schoolShowList === 'faculty' ||
                                                                            schoolShowList === 'communityMember'
                                                                        }
                                                                        label={
                                                                            form?.cPartnerID === 'BEL001'
                                                                                ? 'Community Member'
                                                                                : 'Faculty'
                                                                        }
                                                                    />
                                                                </>}
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </WrapContainer>
                                                    <PassportRow>
                                                        <FormLabel label="School">
                                                            <Select<string>
                                                                name="school"
                                                                options={schoolDropDown}
                                                                defaultValue={schoolDropDown.length === 1 ? schoolDropDown[0] : null}
                                                                onChange={(value: any) => {
                                                                    updateFormValues({ school: value });
                                                                    value = { school };
                                                                }}
                                                                validate={(value) => {
                                                                    if (testSelection === 'school' && !value && !form.school) {
                                                                        return 'Required field';
                                                                    }
                                                                }}
                                                            />
                                                        </FormLabel>
                                                        {schoolShowList === 'student' && (
                                                            <FormLabel label={form.cPartnerID === 'SCS001' ? "Student ID # (optional)" : "Student ID #"}>
                                                                <Inputs
                                                                    value={studentID}
                                                                    onChange={(value) => {
                                                                        updateFormValues({ studentID: value });
                                                                    }}
                                                                    name="studentID"
                                                                    validate={(value) => {
                                                                        if (
                                                                            testSelection === 'school' &&
                                                                            (!value || !value.trim().length) && form.cPartnerID !== 'SCS001'
                                                                        ) {
                                                                            return 'Required field';
                                                                        }
                                                                    }}
                                                                    isRequired
                                                                />
                                                            </FormLabel>
                                                        )}
                                                        {schoolShowList === 'staff' && (
                                                            <FormLabel label={form?.cPartnerID === 'SCS001' ? "Staff ID # (optional)" : "Staff ID #"}>
                                                                <Inputs
                                                                    onChange={(value) => {
                                                                        updateFormValues({ studentID: value });
                                                                    }}
                                                                    name="studentID"
                                                                    validate={(value) => {
                                                                        if (
                                                                            testSelection === 'school' &&
                                                                            (!value || !value.trim().length) &&
                                                                            form?.cPartnerID !== 'SCS001'
                                                                        ) {
                                                                            return 'Required field';
                                                                        }
                                                                    }}
                                                                    isRequired
                                                                />
                                                            </FormLabel>
                                                        )}
                                                        {(schoolShowList === 'faculty' ||
                                                            schoolShowList === 'communityMember') && (
                                                                <FormLabel
                                                                    label={
                                                                        form?.cPartnerID === 'BEL001'
                                                                            ? 'Community Member ID #'
                                                                            : 'Faculty ID #'
                                                                    }
                                                                >
                                                                    <Inputs
                                                                        onChange={(value) => {
                                                                            updateFormValues({ studentID: value });
                                                                        }}
                                                                        name="studentID"
                                                                        validate={(value) => {
                                                                            if (
                                                                                testSelection === 'school' &&
                                                                                (!value || !value.trim().length)
                                                                            ) {
                                                                                return 'Required field';
                                                                            }
                                                                        }}
                                                                        isRequired
                                                                    />
                                                                </FormLabel>
                                                            )}
                                                    </PassportRow>
                                                </>
                                            )}

                                        {form?.location?.partnerName !== 'goHealth' && form.cPartnerID !=="KEN001" && userData?.hasMinors !== undefined && userData?.hasMinors && (
                                            <PageSubTitle>
                                                <b>Minors on account</b>
                                            </PageSubTitle>
                                        )}
                                        {form?.location?.partnerName !== 'goHealth' && form.cPartnerID !=="KEN001" && userData?.hasMinors !== undefined &&
                                            userDataArray.length > 0 &&
                                            userDataArray.map((minor: any, i: number) => (
                                                <>
                                                    <WrapContainer key={i}>
                                                        <WrapContent>
                                                            <Flexbox>
                                                                { location?.partnerName !== 'goHealth' && (
                                                                <>
                                                                <Input
                                                                    type="checkbox"
                                                                    defaultChecked={minor.isChecked}
                                                                    style={{
                                                                        accentColor: '#243D4D',
                                                                    }}
                                                                    onChange={(event) => {
                                                                        // RegisteringFor value assigning
                                                                        if (event.target.checked) {
                                                                            if (values.userSelect) {
                                                                                setFieldValue(
                                                                                    'registeringFor',
                                                                                    'myselfAndMinor'
                                                                                );
                                                                            } else {
                                                                                setFieldValue(
                                                                                    'registeringFor',
                                                                                    'minorOnly'
                                                                                );
                                                                            }
                                                                        } else {
                                                                            let tmp: any = [...values.minors];
                                                                            tmp = tmp.filter((item: any, i: any) => {
                                                                                if (
                                                                                    JSON.stringify({
                                                                                        ...item,
                                                                                        birthDate: '',
                                                                                    }) ===
                                                                                    JSON.stringify({
                                                                                        ...minor,
                                                                                        birthDate: '',
                                                                                    })
                                                                                ) {
                                                                                    console.log(i);
                                                                                } else {
                                                                                    return item;
                                                                                }
                                                                            });
                                                                            if (!tmp.length) {
                                                                                if (values.userSelect) {
                                                                                    setFieldValue(
                                                                                        'registeringFor',
                                                                                        'myself'
                                                                                    );
                                                                                } else {
                                                                                    setFieldValue('registeringFor', '');
                                                                                }
                                                                            } else {
                                                                                if (
                                                                                    values.userSelect &&
                                                                                    minors.length === 1
                                                                                ) {
                                                                                    setFieldValue(
                                                                                        'registeringFor',
                                                                                        'myself'
                                                                                    );
                                                                                } else if (
                                                                                    values.userSelect &&
                                                                                    minors.length !== 1
                                                                                ) {
                                                                                    setFieldValue(
                                                                                        'registeringFor',
                                                                                        'myself'
                                                                                    );
                                                                                } else if (
                                                                                    !values.userSelect &&
                                                                                    minors.length == 1
                                                                                ) {
                                                                                    setFieldValue('registeringFor', '');
                                                                                } else {
                                                                                    setFieldValue(
                                                                                        'registeringFor',
                                                                                        'minorOnly'
                                                                                    );
                                                                                }
                                                                            }
                                                                        }

                                                                        updateMinorsToProvider(
                                                                            cPartnerID === 'POR001' ? { ...minor, school: { "label": 'Corte Madera Elementary - PVSD District Office Annex', "value": "Corte Madera Elementary - PVSD District Office Annex" }, schoolTestFor: '', studentID: "" } : minor,
                                                                            event.target.checked,
                                                                            i
                                                                        );

                                                                    }}
                                                                    disabled={cPartnerID === "LLT001" && minors.length == 1 && minors[0].index !== i ? true : false}
                                                                />
                                                                </>)}
                                                            </Flexbox>
                                                            <Flexbox>
                                                                <FlexboxIcon>
                                                                    <IoPersonOutline size={25} />
                                                                </FlexboxIcon>
                                                                <FlexboxText>
                                                                    <b>{'Name : '}</b>
                                                                    {minor.firstName} {minor.middleName}{' '}
                                                                    {minor.lastName}
                                                                </FlexboxText>
                                                            </Flexbox>
                                                            <Flexbox>
                                                                <FlexboxIcon>
                                                                    <MdDateRange size={25} />
                                                                </FlexboxIcon>
                                                                <FlexboxText>
                                                                    <b>{'Date of Birth : '}</b>
                                                                    {minor.birthDate}
                                                                </FlexboxText>
                                                            </Flexbox>
                                                            <Flexbox>
                                                                <Button
                                                                    type="button"
                                                                    onClick={() => {
                                                                        handleMinorOpen();
                                                                        setEditKey({
                                                                            key: editKey.key === i ? -1 : i,
                                                                        });
                                                                    }}
                                                                    size="sm"
                                                                    libraryType="default"
                                                                    className='btn elevated-btn'
                                                                >
                                                                    Edit
                                                                </Button>
                                                                {editKey.key === i && (
                                                                    <ChangeMinorModal
                                                                        isRegister={false}
                                                                        close={handleMinorClose}
                                                                        open={openMinorDialog}
                                                                        index={i}
                                                                        isProfile={true}
                                                                        schoolDropDown={schoolDropDown}
                                                                        reload={handleMinorReload}
                                                                        cPartnerID={cPartnerID}
                                                                    />
                                                                )}
                                                            </Flexbox>
                                                        </WrapContent>
                                                    </WrapContainer>
                                                    {testSelection === 'school' &&
                                                        minor.isChecked &&
                                                        cPartnerID !== 'POR001' && (
                                                            <>
                                                                <SubHeaderDescription
                                                                    style={{ marginBottom: '5px' }}
                                                                >
                                                                    I am booking for a{' '}
                                                                    <span style={{ color: 'red' }}>*</span>
                                                                </SubHeaderDescription>
                                                                <WrapContainer>
                                                                    <FormControl
                                                                        component="fieldset"
                                                                        variant="standard"
                                                                    >
                                                                        <RadioGroup
                                                                            row
                                                                            value={minor.schoolTestFor}
                                                                            name={`minors.${i}.schoolTestFor`}
                                                                            onChange={(event) => {
                                                                                updateTheMinorsInformation(
                                                                                    'schoolTestFor',
                                                                                    i,
                                                                                    event?.target.value
                                                                                );
                                                                            }}
                                                                        >
                                                                        <FormControlLabel
                                                                            className="white"
                                                                                value="student"
                                                                                control={<StyledRadio />}
                                                                                checked={
                                                                                    minor.schoolTestFor === 'student'
                                                                                }
                                                                                label={'Student'}
                                                                            />
                                                                            <Gap />
                                                                            {form?.cPartnerID !== 'SCS001' && form?.cPartnerID !== 'LLT001' && <>
                                                                            <FormControlLabel
                                                                                className="white"
                                                                                    value="parentOrGuardian"
                                                                                    control={<StyledRadio />}
                                                                                    checked={
                                                                                        minor.schoolTestFor ===
                                                                                        'parentOrGuardian'
                                                                                    }
                                                                                    label={'Parent/Guardian of student'}
                                                                                />
                                                                                {/* <Gap /> */}
                                                                            </>}
                                                                            
                                                                            {form?.cPartnerID !== 'LLT001' && <>
                                                                            <FormControlLabel
                                                                                className="white"
                                                                                    value="staff"
                                                                                    control={<StyledRadio />}
                                                                                    checked={
                                                                                        minor.schoolTestFor === 'staff'
                                                                                    }
                                                                                    label={'Staff'}
                                                                                />
                                                                                <Gap />
                                                                            </>}
                                                                            
                                                                            {form?.cPartnerID !== 'SCS001' && form?.cPartnerID !== 'LLT001' && <>
                                                                            <FormControlLabel
                                                                                className="white"
                                                                                    value={
                                                                                        form?.cPartnerID === 'BEL001'
                                                                                            ? 'communityMember'
                                                                                            : 'faculty'
                                                                                    }
                                                                                    control={<StyledRadio />}
                                                                                    checked={
                                                                                        minor.schoolTestFor === 'faculty' ||
                                                                                        minor.schoolTestFor ===
                                                                                        'communityMember'
                                                                                    }
                                                                                    label={
                                                                                        form?.cPartnerID === 'BEL001'
                                                                                            ? 'Community Member'
                                                                                            : 'Faculty'
                                                                                    }
                                                                                />
                                                                            </>}
                                                                        </RadioGroup>
                                                                    </FormControl>
                                                                </WrapContainer>
                                                                <PassportRow>
                                                                    <FormLabel label="School">
                                                                        <Select<string>
                                                                            name={`minors.${i}.school`}
                                                                            options={schoolDropDown}
                                                                            validate={(value) => {
                                                                                if (
                                                                                    testSelection === 'school' &&
                                                                                    !value
                                                                                ) {
                                                                                    return 'Required field';
                                                                                }
                                                                            }}
                                                                            onChange={(value: any) => {
                                                                                console.log(
                                                                                    { label: value, value },
                                                                                    '123'
                                                                                );
                                                                                updateTheMinorsInformation(
                                                                                    'school',
                                                                                    i,
                                                                                    value
                                                                                );
                                                                            }}
                                                                        />
                                                                    </FormLabel>
                                                                    {minor.schoolTestFor === 'student' && (
                                                                        <FormLabel label={(form?.cPartnerID === 'LLT001' || form?.cPartnerID === 'SCS001') ? "Student ID # (optional)" : "Student ID #"}>
                                                                            <Inputs
                                                                                value={minor.studentID}
                                                                                name={`minors.${i}.studentID`}
                                                                                validate={(value) => {
                                                                                    if ((!value || !value.trim().length) && form?.cPartnerID !== 'LLT001' && form.cPartnerID !== 'SCS001') {
                                                                                        return 'Required field';
                                                                                    }
                                                                                }}
                                                                                onChange={(value) =>
                                                                                    updateTheMinorsInformation(
                                                                                        'studentID',
                                                                                        i,
                                                                                        value
                                                                                    )
                                                                                }
                                                                            />
                                                                        </FormLabel>
                                                                    )}
                                                                    {minor.schoolTestFor === 'staff' && (
                                                                        <FormLabel label={form?.cPartnerID === 'SCS001' ? "Staff ID # (optional)" : "Staff ID #"}>
                                                                            <Inputs
                                                                                name={`minors.${i}.studentID`}
                                                                                validate={(value) => {
                                                                                    if ((!value || !value.trim().length) &&
                                                                                        form?.cPartnerID !== 'SCS001') {
                                                                                        return 'Required field';
                                                                                    }
                                                                                }}
                                                                                isRequired
                                                                                onChange={(value) =>
                                                                                    updateTheMinorsInformation(
                                                                                        'studentID',
                                                                                        i,
                                                                                        value
                                                                                    )
                                                                                }
                                                                            />
                                                                        </FormLabel>
                                                                    )}
                                                                    {(minor.schoolTestFor === 'faculty' ||
                                                                        minor.schoolTestFor ===
                                                                        'communityMember') && (
                                                                            <FormLabel
                                                                                label={
                                                                                    form?.cPartnerID === 'BEL001'
                                                                                        ? 'Community Member ID #'
                                                                                        : 'Faculty ID #'
                                                                                }
                                                                            >
                                                                                <Inputs
                                                                                    name={`minors.${i}.studentID`}
                                                                                    validate={(value) => {
                                                                                        if (!value || !value.trim().length) {
                                                                                            return 'Required field';
                                                                                        }
                                                                                    }}
                                                                                    isRequired
                                                                                    onChange={(value) =>
                                                                                        updateTheMinorsInformation(
                                                                                            'studentID',
                                                                                            i,
                                                                                            value
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </FormLabel>
                                                                        )}
                                                                </PassportRow>
                                                            </>
                                                        )}
                                                </>
                                            ))}

                                        <Formik
                                            initialValues={{
                                                minors: minors.map((minor: any) => ({
                                                    ...minor,
                                                    schoolTestFor:
                                                        form.cPartnerID === 'BEL001'
                                                            ? minor?.schoolTestFor === 'faculty'
                                                                ? 'communityMember'
                                                                : minor?.schoolTestFor
                                                            : minor?.schoolTestFor === 'communityMember'
                                                                ? 'faculty'
                                                                : minor?.schoolTestFor,
                                                    birthDate:
                                                        minor.birthDate &&
                                                        parse(
                                                            minor.birthDate,
                                                            config.dateFormat,
                                                            new Date()
                                                        ),
                                                })),
                                            }}
                                            onSubmit={(values) => {
                                                updateChangeOnClick(values);
                                            }}
                                        >
                                            {({ setFieldValue }) => {
                                                return (
                                                    <Form>
                                                        {form.cPartnerID !=="KEN001" && !addMinor && (
                                                            <AddMinor
                                                                type="button"
                                                                onClick={() => {
                                                                    setAddMinor(true);
                                                                    const newMinors = [
                                                                        {
                                                                            firstName: '',
                                                                            lastName: '',
                                                                            birthDate: null,
                                                                            relationship: '',
                                                                            passportCountry: '',
                                                                            passportNo: '',
                                                                            school: '',
                                                                            studentID: '',
                                                                            schoolTestFor: '',
                                                                        },
                                                                    ];
                                                                    setFieldValue('minors', newMinors);
                                                                    setCount(count + 1);
                                                                }}
                                                            >
                                                                <PlusIcon className="PlusIcon" />
                                                                Add minor
                                                            </AddMinor>
                                                        )}
                                                        {form.cPartnerID !=="KEN001" && addMinor && (
                                                            <>
                                                                <Minors
                                                                    isRegister={false}
                                                                    isProfile={true}
                                                                    schoolDropDown={schoolDropDown}
                                                                    setAddMinor={(p: boolean) => {
                                                                        setAddMinor(p);
                                                                    }}
                                                                />
                                                                <NavigationButtons
                                                                    loading={loading}
                                                                    confirmBtnText="Add Minors"
                                                                    onReturnButtonClick={() => setAddMinor(false)}
                                                                />
                                                            </>
                                                        )}
                                                    </Form>
                                                );
                                            }}
                                        </Formik>
                                        <Divider />
                                        {error && cPartnerID !== 'LLT001' && <ErrorText hasError>{error}</ErrorText>}
                                        {error && cPartnerID === 'LLT001' && <ErrorTextLarge hasError>{error}</ErrorTextLarge>}
                                        {!addMinor && (
                                            <NavigationButtons
                                                isValid={values.registeringFor.length ? true : false}
                                                loading={loading}
                                                onReturnButtonClick={() => {
                                                    if (cPartnerID === 'LLT001') {
                                                        goToPrevStep();
                                                    }
                                                    goToPrevStep();
                                                }}
                                            />
                                        )}
                                    </Form>
                                );
                            }}
                        </Formik>
                    </MinorContainer>
                </Center>
            </Content>
        </Container>
    );
};

export default SchedulingInfo;
