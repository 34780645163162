import React, { useState } from 'react';
import styled from 'styled-components';
import { Form, Formik } from 'formik';

import {
  Content,
  PageHeader,
  SubHeaderDescription,
  PageSubTitle,
  Button
} from '../../shared/components/styled';
import { colors } from '../../../styles/colors';

import Input from '../../shared/components/form/Input';
import FormLabel from '../../shared/components/form/FormLabel';
import Header from '../../shared/components/Header';
import Loader from '../../shared/components/Loader';
import Footer from '../../shared/components/Footer';
import { Breakpoints } from '../../../dictionaries';
import wsllogo from '../../../assets/WLABS_CMYK.png';
import hallogo from '../../../assets/hal_logo.png';
import orglogo from '../../../assets/org_logo.png';
import Publiclogo from '../../../assets/public_logo.png';
import groublogo from '../../../assets/groub_logo.png';
import { NonFormikCheckbox as Checkbox } from '../../shared/components/form/Checkbox';
import ForgotPassword from '../../schedule/components/ForgotPassword';
import { findEmail } from '../api';
import circleRectAngle from '../../../assets/circle-rectangle-dark-blue.png';
import circleRectAngleBottom from '../../../assets/circle-rectangle-dark-blue-bottom.png';

const Error = styled.div`
  color: ${colors.red};
  margin-top: 15px;
  text-align: center;
`;
const Success = styled.div`
  color: ${colors.green};
  margin-top: 15px;
  text-align: center;
`;

export const Logos = styled.div`
  display: flex;
`;

export const WLLogo = styled.div`
  height: 18px;
  width: 118px;
  background: url(${wsllogo}) no-repeat center center;
  background-size: cover;
  margin-right: 20px;
  display: inline-block;

  @media (min-width: ${Breakpoints.sm}px) {
    height: 70px;
    width: 300px;
  }
`;

export const Logos1 = styled.div`
  position: absolute;
  width: 601px;
  height: 161px;
  right: 432px;
  bottom: 518px;

  border: 1px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15));
  border-radius: 10px;
`;

export const Haldiv = styled.div`
  position: absolute;
  width: 120px;
  height: 120px;
  left: 616px;
  top: 408px;

  border: 1px solid rgb(235 227 227 / 50%);
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
  border-radius: 10px;
`;

export const HALLogo = styled.div`
  height: 18px;
  width: 118px;
  background: url(${hallogo}) no-repeat center center;
  background-size: cover;
  display: inline-block;
  margin: -20px 0px 20px 0px;

  @media (min-width: ${Breakpoints.sm}px) {
    height: 100px;
    width: 300px;
  }
`;
export const Text = styled.div`
  position: absolute;
  width: 521px;
  height: 44px;
  left: 447px;
  top: 180px;

  font-family: Open Sans;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 44px;
  text-align: center;
  padding-right: 68px;
  color: #000000;
`;

export const SubText = styled.div`
  position: absolute;
  width: 460px;
  height: 39px;
  left: 472px;
  top: 220px;

  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  /* or 137% */
  text-align: center;
  padding-right: 30px;

  color: ${colors.black};
`;

const CatogryFlexContainer = styled.p`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
`;

export const Orglogo = styled.div`
  height: 18px;
  width: 118px;
  background: url(${orglogo}) no-repeat center center;
  background-size: cover;
  display: inline-block;
  @media (min-width: ${Breakpoints.sm}px) {
    height: 80px;
    width: 85px;
  }
`;

export const CategoryContentBox = styled.div`
  flex: 0.3 1 0%;
  justify-content: center;
  border-radius: 10px;
  border: 2px solid ${colors.lightGrey};
  box-sizing: border-box;
  padding: 10px 0px 10px 0px;
  margin: 0px 5px 0px 5px;
  cursor: pointer;
`;

const CheckboxWrapper = styled.div`
  padding: 18px 20px 6px 30px;
  text-align: left;
`;

const symptomsCheckboxes = [
  'Text message to xxx-xxx-9914',
  'Email to jxxxxxxxx100@gmail.com',
];

export const GroupLogo = styled.div`
  height: 18px;
  width: 118px;
  background: url(${groublogo}) no-repeat center center;
  background-size: cover;
  display: inline-block;
  @media (min-width: ${Breakpoints.sm}px) {
    height: 79px;
    width: 97px;
  }
`;

const CTAButton = styled.button`
  display: block;
  width: 100%;
  color: ${colors.white};
  text-align: center;
  padding: 15px;
  background: ${colors.orange};
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  border: 0;
  font-size: 20px;
  line-height: 36px;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
`;

const ContinueButton = styled.button`
  width: 60%;
  color: ${colors.white};
  text-align: center;
  padding: 15px;
  background: ${colors.orange};
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  border: 0;
  font-size: 20px;
  line-height: 15px;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
  @media (max-width: ${Breakpoints.sm}px) {
    font-size: 17px;
  }
`;
const PageTitle = styled.h2`
  color: ${colors.fullBlack};
  font-size: 28px;
  font-weight: 700;
  margin: 0;
  text-align: center;
  @media (max-width: ${Breakpoints.sm}px) {
    font-size: 24px;
    text-align: center;
    margin-top: 100px;
  }
`;
const Div = styled.div`
    position: absolute;
    bottom: 0px;
    width: 100vw;
    height: 92.2vh;
    overflow-x: hidden;
    overflow-y: scroll;
    @media (max-width: ${Breakpoints.xl}px) {
        overflow-x: hidden;
        overflow-y: scroll;
    }
`
const Background = styled.div`
    background-image: url(${circleRectAngle});
    position: absolute;
    width: 100vw;
    height: 92.2vh;
    z-index: 1;
    background-repeat: no-repeat;
    background-size: 55% 140%;
    background-position: right;
    // background-attachment: fixed;

    @media (max-width: ${Breakpoints.xl}px) {
        background-position: bottom;
        background-image: url(${circleRectAngleBottom});
        background-size: 100% 15%;
        overflow-x: hidden;
        overflow-y: auto;
    }
    @media (max-width: ${Breakpoints.md}px) {
        background-size: 107% 15%;
        height:100%;
    }
    @media (max-width: ${Breakpoints.sm}px) {
        background-size: 130% 15%
        height:100%;
    }
`
const FooterDiv = styled.footer`
    position: fixed;
    width: 100vw;
    z-index: 11111;
    bottom: 0px;
    background-color: rgb(36, 61, 77);
    height: 40px;
    @media (max-width: 960px) {
        height: 40px;
        position: fixed;
    }
    @media (max-width: 600px) {
        height: 70px;
        position: fixed;
    }
`
const Stripe = styled.div`
    position: absolute;
    width: 100vw;
    height: 6.5%;
    background-color: rgb(97, 156, 138);
`;
interface ContainerProps {
    size?: 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
    noPadding?: boolean;
    marginTop?: boolean;
    insurance?:boolean;
  }
const Container = styled.div`
    margin: 0 auto;
    min-height: 350px;
    background-color: rgb(255, 255, 255);
    border: 0.5px solid rgba(36, 61, 77, 0.14);
    box-shadow: rgb(153 153 153 / 5%) -4px -4px 8px, rgb(0 0 0 / 15%) 4px 4px 8px;
    border-radius: 12px;
    max-width: ${({ size }: ContainerProps) => {
        if (size === 'md') {
        return '760px';
        }

        if (size === 'lg') {
        return '920px';
        }

        if (size === 'xl') {
        return '1110px';
        }

        if (size === 'xxl') {
        return '1400px';
        }

        return '650px';
    }};
    margin-top: ${({ marginTop }: ContainerProps) => (marginTop ? '40px' : '200px')};
    padding: ${({ noPadding }: ContainerProps) => (noPadding ? '0px' : '0 15px')};
    @media (max-width: ${Breakpoints.xl}px) {
        border:none;
        box-shadow:none;
        border-radius:none;
    }
    @media (min-width: ${Breakpoints.sm}px) and (max-width: ${Breakpoints.md}px)  {
        margin-left:${({ insurance }: ContainerProps) => (insurance ? '5%' : '')};
        margin-top:150px
    }
`
const FindEmail: React.FC = () => {
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);


  const handleFindEmail = async (phone: string) => {
    setLoading(true);
    setError('');
    setSuccess('');

    let emailList: any = await findEmail({ phone });

    if (emailList?.data?.status === 200) {
      // success message
      phone = '';
      setSuccess('Notification sent to phone');
    } else {
      // error message
      setError(emailList?.data?.message);
    }
    setLoading(false);
  };

  return (
    <div style={{ overflow: 'hidden' }}>
      <Header withoutBg isLogout isHelp />

		<Div>
            <Stripe />
            <Background>
                <Container>
                    <Content>
                        <>
                        <PageHeader>
                            <PageTitle>Find your Email</PageTitle>
                        </PageHeader>
                        <SubHeaderDescription style={{ textAlign: 'center' }}>
                            To find your email login, please enter the phone number
                            associated with your account. We will send you a notification to
                            the email address we have on file.
                        </SubHeaderDescription>
                        <Formik
                            initialValues={{
                            phone: '',
                            }}
                            onSubmit={(values) => {
                            handleFindEmail(values.phone);
                            }}
                            enableReinitialize={false}
                        >
                            {() => (
                            <Form>
                                <Input name="phone" placeholder="Phone Number" isRequired />

                                <div style={{ textAlign: 'center', marginTop: '10px' }}>
                                <Button
                                    libraryType="primary"
                                    type="submit"
                                    disabled={loading}
                                    className="btn fill-btn"
                                >
                                    {loading ? 'Processing...' : 'Send Notification'}
                                </Button>
                                {success && <Success>{success}</Success>}
                                {error && <Error>{error}</Error>}
                                </div>
                            </Form>
                            )}
                        </Formik>
                        </>
                    </Content>
                </Container>
            </Background>
            <FooterDiv>
                <Footer />
            </FooterDiv>
        </Div>
    </div>
  );
};

export default FindEmail;
