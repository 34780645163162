import React from 'react';
import { Formik, Form } from 'formik';
import styled from 'styled-components';

import { useStepsState } from '../../provider';
import { useRegisterStepsDispatch } from '../../../register/provider';
import {
  Container,
  Content,
  PageHeader,
  PageTitle,
} from '../../../shared/components/styled';
import { states } from './states';

import FormLabel from '../../../shared/components/form/FormLabel';
import NavigationButtons from '../../../shared/components/NavigationButtons';
import Input from '../../../shared/components/form/Input';
import ZipCodeInput from './ZipCodeInput';
import Select from '../../../shared/components/form/Select';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
const CityStateRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > *:first-child {
    width: 60%;
  }

  & > *:last-child {
    width: 38%;
  }
`;
const useStyles = makeStyles({
  countryDropDown: {
    orderRadius: '10px',
    width: '100%',
    border: "1px solid #B4B4BB",
    boxShadow: "0px 0px 4px 0px #4749a0c7",
    fontSize: "16px",
    lineHeight: "24px",
    padding: "15px 28px",
    webkitTransition: "all 0.15s ease-in-out",
    transition: "all 0.15s ease-in-out",
  },
  regionDropDown:
   {
    borderRadius: '10px',
    width: '100%',
    border: "2px solid #619c8a",
    boxShadow: "0px 0px 4px 0px #4749a0c7",
    fontSize: "16px",
    lineHeight: "24px",
    padding: "12px 20px",
    webkitTransition: "all 0.15s ease-in-out",
    transition: "all 0.15s ease-in-out",
  },
});

const Address: React.FC = () => {
  const { form } = useStepsState();
  const address: any = form.address;
  console.log('scheduleaddress', address.country);
  const { updateFormValues, goToNextStep, goToPrevStep } =
    useRegisterStepsDispatch();

  const updateAddressValue = (newValue: any) => {
    updateFormValues({
      address: {
        ...address,
        ...newValue,
      },
    });
  };
  const classes = useStyles();
  return (
    <Container>
      <Content>
        <PageHeader>
          <PageTitle>What is your address?</PageTitle>
        </PageHeader>
        <Formik
          enableReinitialize
          initialValues={{
            address: {
              ...address,
              // state: states.find((state) => state.value === address.state),
              country: address.country,
              state: address.state,
            },
          }}
          onSubmit={(values: any) => {
            updateFormValues({
              address: {
                ...values.address,
                state: values.address.state || null,
                country: values.address.country || null,
              },
            });
            goToNextStep();
          }}
        >
          {(values: any) => (
            <Form>
              <FormLabel label="Country">
                <CountryDropdown
                
                  classes={classes.countryDropDown}
                  value={address.country}
                  name="address.country"
                  defaultOptionLabel="United States"
                    onChange={(value) => updateAddressValue({ country:value, state: '' })}
                  // onChange={(value) => console.log('CountryDropdown', value)}
                />
              </FormLabel>

              <FormLabel label="Street address">
                <Input
                  onChange={(value) => updateAddressValue({ address: value })}
                  name="address.address"
                  isRequired
                />
              </FormLabel>
              {address.country == 'United States' ? (
                              <CityStateRow>
                                  <Grid container item xs={12} spacing={1}>
                                      <Grid item xs={12} md={7} >
                                          <FormLabel label="City">
                                              <Input
                                                  onChange={(value) => updateAddressValue({ city: value })}
                                                  name="address.city"
                                                  isRequired
                                              />
                                          </FormLabel>
                                      </Grid>
                                      <Grid item xs={12} md={5}>
                                          <FormLabel label="State">
                                              <RegionDropdown
                                                  classes={classes.regionDropDown}
                                                  name="address.state"
                                                  country={address.country}
                                                  value={address.state}
                                                  valueType="short"
                                                  onChange={(value) => updateAddressValue({ state: value })}
                                              />
                                              {!form.address.state && (<span style={{ color: "red", fontSize: "13px" }}>Required field</span>)}
                                          </FormLabel>
                                      </Grid>
                                  </Grid>
                              </CityStateRow>
                          ) : (
                              <CityStateRow>
                                  <Grid container item xs={12} spacing={1}>
                                      <Grid item xs={12} md={7} >
                                          <FormLabel label="City/Town">
                                              <Input
                                                  onChange={(value) => updateAddressValue({ city: value })}
                                                  name="address.city"
                                                  isRequired
                                              />
                                          </FormLabel>
                                      </Grid>
                                      <Grid item xs={12} md={5}>
                                          <FormLabel label="State/Province/Region">
                                              <RegionDropdown

                                                  classes={classes.regionDropDown}
                                                  name="address.state"
                                                  country={address.country}
                                                  value={address.state}
                                                  onChange={(value) =>
                                                      updateAddressValue({ state: value })
                                                  }
                                              />
                                              {!form.address.state && (<span style={{ color: "red", fontSize: "13px" }}>Required field</span>)}
                                          </FormLabel>
                                      </Grid>
                                  </Grid>
                              </CityStateRow>
              )}
              <ZipCodeInput
                updateCountyValue={updateAddressValue}
                updateAddressValue={updateAddressValue}
              />

              <NavigationButtons
                onReturnButtonClick={() => {
                  goToPrevStep();
                }}
                isValid={form.isNotHavePermanentAddress || (form.address.state && form.address.country)}
              />
            </Form>
          )}
        </Formik>
      </Content>
    </Container>
  );
};

export default Address;
