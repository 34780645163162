import React, { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { Breakpoints } from '../../../../dictionaries';
import { Form, Formik } from 'formik';
import Input from '../../../shared/components/form/Input';
import { useSharedDispatch, useSharedState } from '../../../shared/provider';
import { useRegisterStepsDispatch, useStepsState } from '../../provider';
import Modal from './../../../shared/components/Modal';
import { Button } from './../../../shared/components/styled';
import { colors } from '../../../../styles/colors';
import { updateRegister } from '../../api';
import FormLabel from '../../../shared/components/form/FormLabel';
import ZipCodeInput from '../Address/ZipCodeInput';
import Select from '../../../shared/components/form/Select';
import { states } from '../Address/states';
import { NonFormikCheckbox as Checkbox } from '../../../shared/components/form/Checkbox';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { makeStyles } from '@material-ui/core/styles';
import {Grid} from '@material-ui/core'

const useStyles = makeStyles({
	countryDropDown: {
        borderRadius: '10px',
        width: '100%',
        border: "2px solid #619c8a",
        boxShadow: "0px 0px 4px 0px #4749a0c7",
        fontSize: "16px",
        lineHeight: "24px",
        padding: "15px 28px",
        webkitTransition: "all 0.15s ease-in-out",
        transition: "all 0.15s ease-in-out",
    
    },
    
    regionDropDown: {
        borderRadius: '10px',
        width: '100%',
        border: "2px solid #619c8a",
        boxShadow: "0px 0px 4px 0px #4749a0c7",
        fontSize: "16px",
        lineHeight: "24px",
        padding: "12px 20px",
        webkitTransition: "all 0.15s ease-in-out",
        transition: "all 0.15s ease-in-out",
	}

})


const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 240px;
  margin: 0 -5px;

  & > * {
	margin: 0 5px;
  }
  @media (max-width: ${Breakpoints.sm}px) {
	display: grid;
	row-gap: 10px;
    margin-top: 20px;
  }
  @media (max-width: ${Breakpoints.md}px) {
	display: grid;
	row-gap: 10px;
    margin-top: 20px;
  }
  @media (max-width: ${Breakpoints.lg}px) {
	display: grid;
	row-gap: 10px;
  }
`;

const Title = styled.h2`
  color: ${colors.fullBlack};
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 25px;
`;
const Container = styled.div``;

const CityStateRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > *:first-child {
	width: 48%;
  }

  & > *:last-child {
	width: 48%;
  }
  @media (max-width: ${Breakpoints.sm}px) {
	& > *:last-child {
	  margin-top: 35px;
	}
  }
`;
const CheckboxWrapper = styled.div`
  text-align: left;
`;

type Props = {
	open: boolean;
	handleAddressClose: () => void;
	isProfile: boolean;
	validAddress?: any;
};
const ChangeAddressModal: React.FC<Props> = ({
	handleAddressClose,
	open,
	isProfile,
	validAddress
}) => {
	const [loading, setLoading] = useState(false);
	const [check, setCheck] = useState(false);
	const {
		form,
	} = useStepsState();

	const address: any = form.address;
	const { userData } = useSharedState();
	const { setloggedUserData } = useSharedDispatch();
	const { updateFormValues } = useRegisterStepsDispatch();
	const [isAddress, setIsAddress] = useState(
		!!userData?.isNotHavePermanentAddress
			? userData?.isNotHavePermanentAddress
			: false
	);
	const classes = useStyles();
	const updateAddressValue = (newValue: any) => {
		if (newValue.state) {
			address.zipCode = '';
		}
		updateFormValues({
			address: {
				...address,
				...newValue,
			},
		});
	};

	const updateChangeOnClick = async (values: any) => {
		setLoading(true);
		if (isProfile) {
			let resultdata = await updateRegister({
				email: userData?.email,
				address: values?.address,
				isNotHavePermanentAddress: isAddress
			});
			if (resultdata?.data?.status === 200) {
				setloggedUserData(resultdata?.data?.data);
				localStorage.setItem('user', JSON.stringify(userData));
				handleAddressClose();
				setLoading(false);
			} else {
				handleAddressClose();
				setLoading(false);
			}
		} else {
			handleAddressClose();
			setLoading(false);
		}
	};

	useEffect(() => {
		updateFormValues({
			address: {
				...address,
				country:address.country !== undefined && address.country !==null ? address.country : 'United States'
			},
		});
	}, [validAddress])

	// console.log('addressss222', address)
	return (
		<Modal open={open} onClose={handleAddressClose} maxWidth={800}>
			<Container>
				<Title>Enter your information</Title>
				<Formik
					enableReinitialize
					initialValues={{
						address
					}}
					onSubmit={(values) => {
						console.log("values.address.state", address.state, address.country)
						updateFormValues({
							address: {

								...values.address,
								state: values.address.state || address.state || null,
								country: values.address.country || address.country || null,
							},
						});

						updateChangeOnClick({

							address: {

								...values.address,
								state: values.address.state || address.state || null,
								country: values.address.country || address.country || null,
							},
						});
					}}
				>
					{() => (
						<Form>
							{userData?.isNotHavePermanentAddress &&
								<Checkbox
									name="isNotHavePermanentAddress"
									onChange={(checked) => {
										setIsAddress(checked);
									}}
									checked={isAddress}
								>
									I don't currently have a permanent address.
								</Checkbox>}
							<ZipCodeInput
								updateCountyValue={updateAddressValue}
								updateAddressValue={updateAddressValue}
								country={address.country}
								state={address.state}
							/>
							<FormLabel label="Country" isRequired>
								<CountryDropdown
									classes={classes.countryDropDown}
									value={address.country}
									name="address.country"
									onChange={(value) => {
										updateAddressValue({ country: value, state: '' })
										setCheck(true)
									}}
								/>

							</FormLabel>

							<FormLabel label="Street address" isRequired>
								<Input
									onChange={(value) => {
										updateAddressValue({ address: value })
										setCheck(true)
									}}
									name="address.address"
									isRequired
									value={address.address}
								/>
							</FormLabel>
							{console.log('address', address.country)}
							{form?.address.country == 'United States' ? (
                                <CityStateRow>
                                    <Grid container item xs={12} spacing={1}>
                                        <Grid item xs={12} md={7} >
                                            <FormLabel label="City" isRequired>
                                                <Input
                                                    onChange={(value) => updateAddressValue({ city: value })}
                                                    name="address.city"
                                                    isRequired
                                                    value={address.city}
                                                />
                                            </FormLabel>
                                        </Grid>
                                        <Grid item xs={12} md={5}>
                                            <FormLabel label="State" isRequired>
                                                <RegionDropdown
                                                    classes={classes.regionDropDown}
                                                    name="address.state"
                                                    country={address.country}
                                                    value={address.state}
                                                    valueType="short"
                                                    onChange={(value) => {
                                                        updateAddressValue({ state: value })
                                                        setCheck(true)
                                                    }}
                                                />
                                                {!form.address.state && (<span style={{ color: "red", fontSize: "13px" }}>Required field</span>)}
                                            </FormLabel>
                                        </Grid>
                                    </Grid>
                                </CityStateRow>
                            ) : (
                                <CityStateRow>
                                    <Grid container item xs={12} spacing={1}>
                                        <Grid item xs={12} md={7} >
                                            <FormLabel label="City/Town" isRequired>
                                                <Input
                                                    onChange={(value) => updateAddressValue({ city: value })}
                                                    name="address.city"
                                                    isRequired
                                                    value={address.city}
                                                />
                                            </FormLabel>
                                        </Grid>
                                        <Grid item xs={12} md={5}>
                                            <FormLabel label="State/Province/Region" >
                                                <RegionDropdown
                                                    classes={classes.regionDropDown}
                                                    name="address.state"
                                                    country={address.country}
                                                    value={address.state}
                                                    valueType="short"
                                                    labelType="full"
                                                    onChange={(value) => updateAddressValue({ state: value })}
                                                />
                                                {!form.address.state && (<span style={{ color: "red", fontSize: "13px" }}>Required field</span>)}
                                            </FormLabel>
                                        </Grid>
                                    </Grid>
                                </CityStateRow>
							)}
							{(validAddress === 'false' && isProfile && !check) ? (<div style={{ color: "red", fontSize: "15px", marginBottom: '27px' }}>Please update your address details.</div>) : ''}
							<ButtonsContainer>
								<Button
									libraryType="default"
									size="sm"
									type="button"
									onClick={() => {
										handleAddressClose();
									}}
									className='btn elevated-btn'
								>
									Cancel
								</Button>
								<Button libraryType="primary" size="sm" type="submit"
									disabled={
										form.address.country == 'United States'
											? form.address.state !== undefined && form.address.state !== null && form.address.state !== ""
												? false
												: true
											: false
									} className='btn fill-btn'>
									{loading ? 'Processing...' : 'Confirm'}{' '}
								</Button>
							</ButtonsContainer>
						</Form>
					)}
				</Formik>
			</Container>
		</Modal>
	);
};

export default ChangeAddressModal;