import React from 'react';
import { Form } from 'formik';
import styled from 'styled-components';

import {
  Container,
  PageHeader,
  Button,
} from '../../../shared/components/styled';
import { Breakpoints } from '../../../../dictionaries';
import { colors } from '../../../../styles/colors';
import Modal from './../../../shared/components/Modal';
import { AiOutlineQuestionCircle } from 'react-icons/ai';

export const Content = styled.div`
  padding: 10px;

  @media (min-width: ${Breakpoints.md}) {
    padding: 88px 0;
  }
  @media (max-width: ${Breakpoints.sm}px) {
    padding: 0px !important;
  }
`;
const BtnContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  flex-wrap: nowrap;
  padding-top: 20px;

  @media (min-width: 450px) {
    flex-direction: row;
  }
`;
const Space = styled.div`
  height: 10px;
  width: 20px;
`;

export const StyledButton = styled.button`
  border: 1px dashed ${colors.grey};
  border-radius: 10px;
  font-size: 16px;
  font-weight: 500;
  color: rgb(26 150 219);
  line-height: 24px;
  padding: 15px 28px;
  transition: all 0.15s ease-in-out;
  width: 100%;
  cursor: pointer;

  &:focus,
  &:active {
    border: 1px dotted ${colors.blueLight};
    box-shadow: 0 0 4px ${(colors.blueLight, 0.8)};
  }
  @media (max-width: ${Breakpoints.sm}px) {
    margin-top: 40px;
  }
`;
export const PageTitle = styled.h2`
  color: ${colors.fullBlack};
  font-size: 28px;
  font-weight: 700;
  margin: 0;
  text-align: left;

  @media (max-width: ${Breakpoints.sm}px) {
    font-size: 24px;
  }
`;
const Text = styled.p`
  margin-bottom: 45px;
`;

type Props = {
  open: boolean;
  close: () => void;
};
const ConfirmDialog: React.FC<Props> = ({
  open,
  close,

}) => {

  return (
    <Modal open={open} onClose={close} maxWidth={800} blockScroll={false}>
      <Content>
        <Container marginTop>
          <PageHeader>
            <PageTitle>
              <AiOutlineQuestionCircle size={22} color={'#2A5F87'} />
              {' Confirm'}
            </PageTitle>
          </PageHeader>
         
           
                  <Text>
                    {'Please enter the information exactly how it is displayed and spelled on your insurance card'}
                  </Text>
                  <BtnContainer>
                    <Button
                      libraryType="primary"
                      type="button"
                      accentColor="'#FF802E'"
                      className="btn fill-btn1"
                      // style={{
                      //   backgroundColor: '#F29A4E',
                      //   border: '1px solid #F29A4E',
                      // }}
                      onClick={() =>{
                         close()
                        }
                    }
                    >
                      Okay
                    </Button>
                    <Space />
                  </BtnContainer>
              </Container>
      </Content>
    </Modal>
  );
};

export default ConfirmDialog;
