import React, { useEffect } from 'react';
import styled from 'styled-components';
import Sidenav from "../Appointment/Sidenav"
import Profile from "./Mainpage/Profile"
import Page33 from "../Appointment/Mainpage/Page33"
import Appointmentpage from "./Mainpage/Appointmentpage"
import AppointmentResult from './Mainpage/Results'
import Header from '../../../shared/components/Header';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory
  } from "react-router-dom";
import Defaultpage from './Mainpage/Defaultpage';
import { Breakpoints } from '../../../../dictionaries';
import SideNavButton from './SideNavButton';
import { AdminProvider } from '../../../admin/provider';
import EditAppointmentFromList from './Mainpage/EditAppointmentFromList';
import SidenavCopy from './Sidenav copy';
import Billing from './Mainpage/Billing';
import HealthPassports from './Mainpage/HealthPassports';
import { useSharedDispatch, useSharedState } from '../../../shared/provider';
import InsuranceList from './Mainpage/InsuranceList';
import UpgradeTest from '../UpgradeTest';
// import ProviderListFn from '../Telemedicine/ProviderListFn';
// import AppoinementLists from '../Telemedicine/Appointments/AppoinementLists';
// import AppointmentListFn from '../Telemedicine/Appointments';

const Container = styled.div`
float: left;
width: 100%;
height: 100%;
`;

const Layout: React.FC = () => {

let history = useHistory();
const { userData } = useSharedState();
const { updateReasonType, updateVaccineType, updateVaccinated, updateDestination, updateTravelType, updateAirlineType, updateapptFromSchdule} = useSharedDispatch();
const redirect = () => {
    localStorage.clear();
    updateDestination('');
    updateTravelType('');
    updateAirlineType('');
    updateReasonType('');
    updateVaccinated('');
    updateVaccineType('');
    updateapptFromSchdule(false);
    history.push('/signin');
  };
if (!userData?.isVerified) {
  redirect();
}

return(
 <Container>
    <SidenavCopy/>
    <Switch>
        <Route exact path="/user">
            <Profile />
        </Route>
        <Route exact path="/user/profile">
            <Profile />
        </Route>
        <Route exact path="/user/appointments">
            <Appointmentpage />
        </Route>
        <Route exact path="/user/results">
            <AppointmentResult />
        </Route>
        <Route exact path="/user/insurance">
            <InsuranceList />
        </Route>
        {/* <Route exact path="/user/telemedicine">
            <AppointmentListFn />
        </Route> */}
        
        {/* <Route exact path="/user/telemedicine/provider">
            <ProviderListFn />
        </Route> */}
        {/* <Route exact path="/user/healthpassport">
            <HealthPassports />
        </Route> */}
        <Route exact path="/user/editAppointment">
            <AdminProvider>
                <EditAppointmentFromList />
            </AdminProvider>
        </Route>
        <Route path="/upgradeTest1 ">
            <UpgradeTest />
        </Route>
    </Switch>
 </Container>
)
}
export default Layout;