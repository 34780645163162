import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
    SubHeaderDescription,
    ErrorText,
    Button,
} from '../../../shared/components/styled';
import { Breakpoints } from '../../../../dictionaries';
import { useStepsDispatch, useStepsState } from '../../provider';
import { useSharedDispatch, useSharedState } from '../../../shared/provider';
import { Formik, Form } from 'formik';
import FormLabel from '../../../shared/components/form/FormLabel';
import Select from '../../../shared/components/form/Select';
import Checkbox from '../../../shared/components/form/Checkbox';
import { firebase } from '../../../../firebase';
import { orderBy } from 'lodash';
import { getCpartnerLogo, getDistrict } from '../../../register/api';
import Modal from '../../../shared/components/Modal';

const Text = styled.div`
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  margin-bottom: 5px;
`;

const SubContent = styled.p`
  margin-top: 20px;
  text-align: left;
  @media (max-width: ${Breakpoints.sm}px) {
    padding: 0 !important;
  }
`;

const BtnContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  flex-wrap: nowrap;
  padding-top: 20px;

  @media (min-width: 450px) {
    flex-direction: row;
  }
`;
const Space = styled.div`
  height: 10px;
  width: 20px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: right;
  justify-content: right;
  margin: 10px;

  & > * {
    margin: 0 5px;
  }
  @media (max-width: ${Breakpoints.sm}px) {
    display: grid;
    row-gap: 10px;
  }
`;
const returnBtnStyle = {
  backgroundColor: '#FFFFFF',
  color: '#101010',
  border: '0.5px solid #FFFFFF',
  fontWeight: '600',
  fontSize: '14px',
};
const saveBtnStyle = {
  backgroundColor: '#FF802E',
  border: '1px solid #FF802E',
  fontWeight: '600',
  fontSize: '14px',
};
const SchoolSelection: React.FC = () => {
    const { updateFormValues, goToNextStep, goToPrevStep } = useStepsDispatch();
    const { form } = useStepsState();
    const { testSelection, district, commitToConfirm } = form;
    const db = firebase.firestore();
    const { updatePartnerLogo, updateDistrict } = useSharedDispatch();
    const [loading, setLoading] = useState<boolean>(false);
    const [errormsg, setErrormsg] = useState<boolean>(false);
    const[ openModal, setModalOpen] = useState(false);
    const { isWalkUp, locations, testFlowSelectionDatas} = useSharedState();

    // useEffect(() => {
    //     fetchDistrict();
    // }, []);

    const [districtData, setDistrictData] = useState<
        { value: string; label: string }[]
    >(testFlowSelectionDatas?.districtData ?? []);

    const fetchDistrict = async () => {
        //const response = db.collection('district');
        //const snapShotData = await response.get();
        // const data = snapShotData.docs.map((doc: any) => doc.data());
        // console.log('::::: fetchDistrict',data);
        const res = await getDistrict();
        if (res.data.status === 200){
            const data: any = res.data.data;
            let list: any[] = [];

            if (isWalkUp.active) {
              data
                .filter((district: any) =>
                  isWalkUp.cPartnerIdArray?.includes(district.cPartnerID)
                )
                .reduce((index: any, district: any) => {
                  list.push({
                    value: district.value,
                    label: district.name,
                  });
                  return list;
                }, []);
            } else {
              data.reduce((index: any, vaccine: any) => {
                if (vaccine.isActive) {
                  list.push({
                    value: vaccine.value,
                    label: vaccine.name,
                  });
                }
                return list;
              }, []);
            }
            list = orderBy(list, ['label'], ['asc']);
            setDistrictData(list);
        }
        
    };

    const fetchCpartnerlogo = async () => {
        try {
            setLoading(true);
            let params = testSelection === 'school' ? district === 'SSFSD' ? 'SSF001' : district === 'BRSSD' ? 'BEL001' : district === 'SCSD' ? 'SCS001' : district === 'PVSD' ? 'POR001' : district === 'LLESD' ? 'LLT001' :'SEQ001' : 'WSL001'
            const data = await getCpartnerLogo(params);
            if (data?.data?.status === 200) {
                updatePartnerLogo({
                    logo: data?.data?.data.logo,
                    logoHeader: data?.data?.data.logoHeader,
                });
                setLoading(false);
                if(testSelection === 'school'  && (district.value === 'LLESD' || district === 'LLESD')) {
                    goToNextStep();
                } 
                goToNextStep();
            }
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <SubContent>
            <Formik
                initialValues={{
                    district: district,
                    commitToConfirm,
                }}
                onSubmit={(values) => {
                    let districts: string = district.value !== undefined ? district.value : district

                    updateFormValues({
                        ...values,
                        cPartnerID: testSelection === 'school' ? districts === 'SSFSD' ? 'SSF001' : districts === 'BRSSD' ? 'BEL001' : district === 'SCSD' ? 'SCS001' : district === 'PVSD' ? 'POR001': districts === 'LLESD' ? 'LLT001' : 'SEQ001' : 'WSL001'
                    });
                    if (commitToConfirm) {
                        fetchCpartnerlogo();
                    } else {
                        setErrormsg(true);
                    }
                }}
            >
                {() => (
                    <Form>
                        <SubHeaderDescription style={{ marginBottom: '5px' }}>
                            Please select your school district
                            <span style={{ color: 'red' }}>*</span>
                        </SubHeaderDescription>
                        <FormLabel label="">
                            <Select<string>
                                name="district"
                                options={districtData} //test
                                onChange={(value) => {
                                    updateFormValues({ district: value });
                                    // if(value === "SUHSD"){
                                    //     setModalOpen(true);
                                    // }
                                    updateDistrict(value);
                                    localStorage.setItem('dist', value);
                                }}
                                validate={(value) => {
                                    if (!value) {
                                        return 'Required field';
                                    }
                                }}
                            />
                        </FormLabel>
                        <Checkbox
                            name="commitToConfirm"
                            onChange={(checked) => {
                                updateFormValues({ commitToConfirm: checked });
                            }}
                            children={
                                <Text>
                                    I understand that I am booking a COVID-19 test and the results
                                    are not meant to be used for travel, and simply for
                                    identification of if I have an active COVID-19 infection or
                                    not.
                                    <span style={{ color: 'red' }}>*</span>
                                </Text>
                            }
                        />
                        {errormsg && <ErrorText hasError>{'Required Field'}</ErrorText>}
                        <BtnContainer>
                            <Button
                                libraryType="default"
                                type="button"
                                style={returnBtnStyle}
                               className='btn elevated-btn'
                                onClick={() => goToPrevStep()}
                            >
                                Return
                            </Button>
                            <Space />

                            <Button
                                libraryType="primary"
                                type="submit"
                                style={saveBtnStyle}
                               className='btn fill-btn'
                                disabled={loading}
                            >
                                {loading ? 'Processing...' : 'Save & Continue'}
                            </Button>
                        </BtnContainer>
                    </Form>
                )}
            </Formik>
            <Modal open={openModal} 
                    onClose={() => {
                        setModalOpen(false);
                    }}
                    maxWidth={800}
                >
                    <div className='modalText' style={{ color: "#000000", lineHeight: "25px", textAlign: "justify", fontWeight: "bold" }}>
                        Expanded Covid Testing program from Monday 1/17 - Sunday 1/23 from 7am to 7pm. Available to Students and Staff only. Please have your Student or Staff ID handy. Two school locations per day. You may schedule a test at any location.
                    </div>
                    <ButtonsContainer>
                        <Button
                            libraryType="primary"
                            size="sm"
                            accentColor="#f29a4e"
                            style={{
                                width: "200px"
                            }}
                            onClick={() => {
                                setModalOpen(false);
                            }}
                        >
                            Ok
                        </Button>
                    </ButtonsContainer>
                </Modal>
        </SubContent>
    );
};

export default SchoolSelection;
