import React, { useEffect, useState, useRef } from 'react';
import { Formik, Form } from 'formik';
import styled from 'styled-components';
// import SignaturePad from 'react-signature-canvas';

import {
  Container,
  Content,
  PageHeader,
} from '../../../shared/components/styled';
import { colors } from '../../../../styles/colors';
// import { useStepsDispatch } from '../../../register/provider';
import { useStepsState, useStepsDispatch } from '../../../schedule/provider';
import { Breakpoints } from '../../../../dictionaries';

import Checkbox, { NonFormikCheckbox } from '../../../shared/components/form/Checkbox';
import NavigationButtons from '../../../shared/components/NavigationButtons';
import { useSharedState } from '../../../shared/provider';
import ScrollableConsent from '../../../schedule/components/Consent/ScrollableConsent';
import ScrollableAirlineConsent from '../../../schedule/components/Consent/ScrollableAirlineConsent';
import ScrollableGeneralConsent from '../../../schedule/components/Consent/ScrollableGeneralConsent';
import ScrollableDynamicConsent from '../../../schedule/components/Consent/ScrollableDynamicConsent';
import ScrollableDynamicHippa from '../../../schedule/components/Consent/scrollableDynamicHippa';
import './../../../../index.css';
import { firebase } from './../../../../firebase';
import GoogleAnalytics from '../../../../GoogleAnalytics';

const Info = styled.div`
  background: ${colors.white};
  padding: 20px 30px;
  margin-bottom: 20px;
  border: 0.5px solid rgba(36, 61, 77, 0.14);
  border-radius: 12px;
  box-sizing: border-box;
  @media (min-width: ${Breakpoints.md}px) {
    padding: 30px 40px;
  }
`;
export const PageTitle = styled.h2`
  color: ${colors.fullBlack};
  font-size: 28px;
  font-weight: bold;
  margin: 0;
  text-align: left;

  @media (max-width: ${Breakpoints.sm}px) {
    font-size: 25px;
    margin-top: 30%;
  }
`;

const InfoTitle = styled.h3`
  color: rgba(38, 38, 38, 0.8);
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  margin: 0 0 20px 0;
  color: ${colors.rebrandBlack};
`;

const InfoContent = styled.p`
  margin: 10px 0px;
`;

const Space = styled.div`
  height: 30px;

  @media (min-width: ${Breakpoints.md}px) {
    height: 40px;
  }
`;

// signature pad styling
const Button = styled.button`
  padding: 15px;
  background: grey50;
  outline: none;
  border: 0;
`;
const Buttons = styled.button`
  padding: 5px 20px;
  background: grey50;
  outline: none;
  border: 1px solid gray;
  color: black;
`;
const BtnBox = styled.div`
  display: flex;
  /* align-item: center; */
  justify-content: space-around;
  width: 80%;
`;

const Consent: React.FC = () => {
  const {
    form: {
      consentForTesting,
      hipaaConfirmed,
      testSelection,
      insuranceForTesting,
      cPartnerID,
      hasInsurance,
      signatureURL,
      isHubSpotContact,
    },
    form
  } = useStepsState();
  const { userData, airline } = useSharedState();
  const { updateFormValues, goToNextStep, goToPrevStep } = useStepsDispatch();
  const [error, setError] = useState<string | null>(null);
  let sigCanvas = useRef({});
    const [isHubSpot, setIsHubSpot] = useState(isHubSpotContact ?? true);

  const [imageURL, setImageURL] = useState(null);
  const handleHubSpot = (checked: boolean) => {
    setIsHubSpot(checked);
    updateFormValues({
      isHubSpotContact: checked ? true : false,
    });
  };
  const save = () => {
    // setImageURL(sigCanvas.current.getTrimmedCanvas().toDataURL('image/png'));
    // console.log(sigCanvas.current.getTrimmedCanvas().toDataURL('image/png'));
  };

  // const clear = () => sigCanvas.current.clear();

  useEffect(() => {
    window.scrollTo(0, 0);
    GoogleAnalytics(form,'/HIPAA Consent');

    if (signatureURL) {
      console.log(signatureURL);
      setImageURL(signatureURL);
    }
  }, []);

  return (
    <Container size="md">
      <Content>
        <PageHeader>
          <PageTitle>HIPAA Release & Informed Consent</PageTitle>
        </PageHeader>
        <Formik
          initialValues={{
            consentForTesting,
            hipaaConfirmed,
            insuranceForTesting,
            isHubSpotContact: isHubSpot,
          }}
          onSubmit={(values) => {
            updateFormValues({ ...values, isHubSpotContact: isHubSpot });
            goToNextStep();
            // if (testSelection == 'school' && hasInsurance) {
            //   if (imageURL !== null) {
            //     updateFormValues({ signatureURL: imageURL });
            //     goToNextStep();
            //   }
            // } else goToNextStep();
          }}
        >
          {({ values, isValid }) => (
            <Form>
              <Info>
                <InfoTitle>HIPAA Confirmation</InfoTitle>
                <InfoContent>
                  <ScrollableDynamicHippa hasInsurance={false} />
                </InfoContent>
              </Info>
              <Checkbox
                name="hipaaConfirmed"
                isRequired
                onChange={(checked) =>
                  updateFormValues({ hipaaConfirmed: checked })
                }
              >
                <u>
                  <b> I agree to the contents of the HIPAA authorization.</b>
                </u>
              </Checkbox>
              <Space />
              <Info>
                <InfoTitle>
                  {cPartnerID === 'SEQ001'
                    ? 'Informed consent for COVID-19 and/or Flu A&B Diagnostic Testing'
                    : 'Informed consent for COVID-19 Diagnostic Testing'}
                </InfoTitle>
                <InfoContent>
                  {/* {airline === 'HAL' ? (
                    <ScrollableConsent />
                  ) : (
                    testSelection === 'general' ?
                    <ScrollableGeneralConsent /> :
                    <ScrollableAirlineConsent />
                  )} */}
                  <ScrollableDynamicConsent />
                </InfoContent>
              </Info>
              <Checkbox
                name="consentForTesting"
                isRequired
                onChange={(checked) =>
                  updateFormValues({ consentForTesting: checked })
                }
              >
                <u>
                  <b> I give my consent to be tested for COVID-19.</b>
                </u>
              </Checkbox>

              {/*Signature and new insurance consent */}
              {hasInsurance && (
                <>
                  <Space />
                  <Info>
                    <InfoTitle>Assignment of Insurance Benefits</InfoTitle>
                    <InfoContent>
                      <ScrollableDynamicHippa hasInsurance={true} />
                    </InfoContent>
                  </Info>
                  <Checkbox
                    name="insuranceForTesting"
                    isRequired
                    onChange={(checked) =>
                      updateFormValues({ insuranceForTesting: checked })
                    }
                  >
                    <u>
                      <b> I agree to the assignment of insurance benefits </b>
                    </u>
                  </Checkbox>
                  <Space />
                  {/* <div className="Apps">
                    <Popup
                      modal
                      position="center"
                      trigger={
                        signatureURL === '' ||
                          signatureURL == null ||
                          imageURL == null ? (
                          <Button>Open Signature Pad</Button>
                        ) : (
                          ''
                        )
                      }
                      closeOnDocumentClick={false}
                    >
                      {(close) => (
                        <div className="bg">
                          <SignaturePad
                            ref={sigCanvas}
                            canvasProps={{
                              className: 'signatureCanvas',
                            }}
                          /> */}
                  {/* Button to trigger save canvas image */}
                  {/* <BtnBox>
                            <Buttons
                              onClick={async () => {
                                setImageURL(
                                  sigCanvas.current
                                    .getTrimmedCanvas()
                                    .toDataURL('image/png')
                                );

                                updateFormValues({
                                  signatureURL: sigCanvas.current
                                    .getTrimmedCanvas()
                                    .toDataURL('image/png'),
                                });
                                close();
                              }}
                            >
                              Save
                            </Buttons>
                            <Buttons onClick={clear}>Clear</Buttons>
                            <Buttons onClick={close}>Close</Buttons>
                          </BtnBox>
                        </div> 
                  //     )}
                  //   </Popup>
                  //   <Space />
                  //   {imageURL ? (
                  //     <>
                  //       <img
                  //         src={imageURL}
                  //         alt="my signature"
                  //         style={{
                  //           display: 'block',
                  //           margin: '0 auto',
                  //           border: '1px solid black',
                  //           width: '180px',
                  //         }}
                  //       />

                  //       <Button
                  //         style={{
                  //           margin: '20px 0',
                  //         }}
                  //         onClick={() => {
                  //           setImageURL(null);
                  //           updateFormValues({ signatureURL: null });
                  //         }}
                  //       >
                  //         Clear
                  //       </Button>
                  //     </>
                  //   ) : null}
                  // </div> */}
                </>
              )}
              {/*  */}
              <NonFormikCheckbox
                name="isHubSpotContact"
                checked={isHubSpot}
                onChange={(checked) => handleHubSpot(checked)}
              >
                I would like to receive marketing communications and updates
                from Worksite Labs.
              </NonFormikCheckbox>
              <NavigationButtons
                isValid={
                  testSelection !== 'community' && hasInsurance
                    ? consentForTesting && hipaaConfirmed && insuranceForTesting
                    : // imageURL !== null
                      consentForTesting && hipaaConfirmed
                }
                onReturnButtonClick={() => {
                  if (
                    form?.cPartnerID === 'LLT001' &&
                    form?.hasInsurance === true &&
                    userData !== null
                  ) {
                    goToPrevStep();
                    goToPrevStep();
                    goToPrevStep();
                    goToPrevStep();
                  } else if (
                    form?.cPartnerID === 'LLT001' &&
                    form?.hasInsurance === false &&
                    userData !== null
                  ) {
                    goToPrevStep();
                    goToPrevStep();
                    goToPrevStep();
                    goToPrevStep();
                    goToPrevStep();
                  }
                  goToPrevStep();
                }}
              />
            </Form>
          )}
        </Formik>
      </Content>
    </Container>
  );
};

export default Consent;
