import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Breakpoints } from '../../../../dictionaries';
import { Form, Formik } from 'formik';
import Input from '../../../shared/components/form/Input';
import { useSharedDispatch, useSharedState } from '../../../shared/provider';
import Modal from './../../../shared/components/Modal';
import { Button } from './../../../shared/components/styled';
import { colors } from '../../../../styles/colors';
import { updateRegister } from '../../api';
import FormLabel from '../../../shared/components/form/FormLabel';
import Select from '../../../shared/components/form/Select';
import { IDropDown } from "../../models";
import { filter } from 'lodash';

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 240px;
  margin: 0 -5px;

  & > * {
    margin: 0 5px;
  }
  @media (max-width: ${Breakpoints.sm}px) {
    display: grid;
    row-gap: 10px;
  }
`;

const Title = styled.h2`
  color: ${colors.fullBlack};
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 25px;
`;
const Container = styled.div``;
const PassportRow = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 350px;
  @media (max-width: ${Breakpoints.sm}px) {
    
`;
type Props = {
  open: boolean;
  close: () => void;
};
const ChangeStudentModal: React.FC<Props> = ({close, open }) => {
  const { userData, locations } = useSharedState();

  useEffect(() => {
    var filteredlocation = filter(locations, function (data:any) { return data.cPartnerID ==  "SEQ001" });
    var dropDown:any = [];
    filteredlocation.forEach((location)=> {dropDown.push({label: location.name, value: location.name});} )
    setSchoolDropDown(dropDown);
    // console.log('dropdown', dropDown)
  }, []);
 
  
  const [loading, setLoading] = useState(false);
  
  const { setloggedUserData } = useSharedDispatch();
  const [schoolDropDown, setSchoolDropDown] = useState<IDropDown[]>([]);

  const updateChangeOnClick = async (values: any) => {
    setLoading(true);
    let resultdata = await updateRegister({
      email: userData?.email,
      school: values.school,
      studentID: values.studentID,
    });
    if (resultdata?.data?.status === 200) {
      setloggedUserData(resultdata?.data?.data);
      localStorage.setItem('user', JSON.stringify(resultdata?.data?.data));
      close();
    }
    setLoading(false);
  };

  return (
    <Modal open={open} onClose={close} maxWidth={800}>
      <Container>
        <Title>Enter your information</Title>
        <Formik
          enableReinitialize
          initialValues={{
            school: userData?.school,
            studentID: userData?.studentID,
          }}
          onSubmit={(values) => {
            updateChangeOnClick(values);
          }}
        >
          {() => (
            <Form>
              <PassportRow>
                <FormLabel label="School">
                  <Select<string>
                    name="school"
                    options={schoolDropDown}   
                    validate={(value) => {
                      if (!value) {
                        return 'Required field';
                      }
                    }}
                  />
                </FormLabel>
                {!!userData?.studentID && !!userData?.schoolTestFor && userData?.schoolTestFor === 'student' &&
                <FormLabel label="Student ID #">
                  <Input
                    name="studentID"
                    validate={(value) => {
                      if (!value || !value.trim().length) {
                        return 'Required field';
                      }
                    }}
                    isRequired
                  />
                </FormLabel>}
                {!!userData?.studentID && !!userData?.schoolTestFor && userData?.schoolTestFor === 'staff' &&
                <FormLabel label="Staff ID #">
                  <Input
                    name="studentID"
                    validate={(value) => {
                      if (!value || !value.trim().length) {
                        return 'Required field';
                      }
                    }}
                    isRequired
                  />
                </FormLabel>}
                {!!userData?.studentID && !!userData?.schoolTestFor && (userData?.schoolTestFor === 'faculty' || userData?.schoolTestFor === 'communityMember') &&
                <FormLabel label={userData?.schoolTestFor === 'faculty' ? "Faculty ID #" : "Community Member ID #"}>
                   <Input
                    name="studentID"
                    validate={(value) => {
                      if (!value || !value.trim().length) {
                        return 'Required field';
                      }
                    }}
                    isRequired
                  />
                </FormLabel>}
              </PassportRow>
              <ButtonsContainer>
                <Button
                  libraryType="default"
                  size="sm"
                  type="button"
                  onClick={() => close()}
                  className='btn elevated-btn'
                >
                  Cancel
                </Button>
                <Button libraryType="primary" size="sm" type="submit" className='btn fill-btn'>
                  {loading ? 'Processing...' : 'Confirm'}{' '}
                </Button>
              </ButtonsContainer>
            </Form>
          )}
        </Formik>
      </Container>
    </Modal>
  );
};

export default ChangeStudentModal;

//ltst