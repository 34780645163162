import React, { useMemo } from 'react';
import { Formik, Form } from 'formik';
import styled from 'styled-components';

import { useStepsState, useStepsDispatch } from '../../schedule/provider';
import { Button } from './styled';
import { colors } from '../../../styles/colors';
import { Breakpoints } from '../../../dictionaries';

import Modal from './Modal';
import LocationButtonGroup from './form/LocationButtonGroup';
import { cloneDeep } from 'lodash';
import AlertModal from "./AlertModal";
import { Location, Slot } from '../models';
import { useRegisterStepsDispatch } from '../../register/provider';

const Content = styled.div`
  width: 100%;

  @media (min-width: ${Breakpoints.md}px) {
    width: 540px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 240px;
  margin: 0 -5px;

  & > * {
    margin: 0 5px;
  }
  @media (max-width: ${Breakpoints.sm}px) {
    display: grid;
    row-gap: 10px;
}
  `;

const Title = styled.h2`
color: ${colors.fullBlack};
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 25px;
`;

interface Props {
    openModal: () => void;
    isManage?: boolean;
    showModal?: boolean;
    appointment?: any;
    payment?:string;
    test?:string;
    isEmpEmail?: boolean;
}

const ChangeLocationModal: React.FC<Props> = ({ openModal, isManage, showModal, appointment,  payment, test, isEmpEmail }) => {
    const {
        // form: { location, slot, isExpressSameDayTest },
        form,
        showChangeLocationModal,
    } = useStepsState();
    const { toggleChangeLocationModal, updateFormValues } = useStepsDispatch();
    const { toggleLocationModal } = useRegisterStepsDispatch();
    
    //Initial value for Schedule booking flow
    let location: Location | null =  form.location;
    let slot: Slot | null =  form.slot;
    let isExpressSameDayTest: boolean  =  form.isExpressSameDayTest;
    let visibleModal: any = showChangeLocationModal;
    
    //Value resigned for the reschedule appointment
    if(isManage){
        visibleModal = showModal;
        location = appointment?.location;
        slot = appointment?.slot;
        isExpressSameDayTest =  appointment?.isExpressSameDayTest;
    }
    
    const closeModal = () => isManage ? toggleLocationModal(false) : toggleChangeLocationModal(false);
    const rollbackLocation = useMemo(() => cloneDeep(location), [showChangeLocationModal]);
    const rollbackSlot = useMemo(() => cloneDeep(slot), [showChangeLocationModal]);
    const rollbackisExpressSameDayTest = useMemo(() => cloneDeep(isExpressSameDayTest), [showChangeLocationModal]);

    return (
        <Modal open={visibleModal} onClose={closeModal}>
            <Formik
                initialValues={{ location }}
                onSubmit={() => {
                    updateFormValues({ update: location });
                    updateFormValues({ update: slot });
                    if (slot === undefined) {
                        openModal();
                    }
                    closeModal();
                }}
            >
                {() => (
                    <Form>
                        <Content>
                            <Title>Change Location1</Title>
                            <LocationButtonGroup 
                            isManage={isManage} 
                             payment={payment} 
                              test={test} 
                              isEmpEmail={isEmpEmail}
                               appoitmentLocation={isManage ? appointment: {}} 
                               rescheduleAppointment={appointment} 
                               confirmAndPay={true} />
                            <ButtonsContainer>
                                <Button
                                    libraryType="default"
                                    size="sm"
                                    type="button"
                                    onClick={() => {
                                        updateFormValues({ location: rollbackLocation, slot: rollbackSlot, isExpressSameDayTest: rollbackisExpressSameDayTest });
                                        closeModal();
                                    }}
                                    className='btn elevated-btn'
                                >
                                    Cancel
                                </Button>
                                <Button libraryType="primary" size="sm" type="submit" className='btn fill-btn'>
                                    Change
                                </Button>
                            </ButtonsContainer>
                        </Content>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export default ChangeLocationModal;
