export default (form:any, pageUrl: any, title: any = '', data: any = {}) => {
    if (form.testSelection === 'general' || form.testSelection === "flight") {
        window.dataLayer.push({
            'event': 'BookAppointmentSteps',
            pageUrl,title,
            ...data
        });
    }
    if(pageUrl === 'GoHealth'){
        window.dataLayer.push({
            'event': 'GoHealth',
            pageUrl,title,
            ...data
        });
    }
}