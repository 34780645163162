import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Form, Formik } from 'formik';
import { colors } from '../../../../styles/colors';
import { useStepsDispatch, useStepsState } from '../../provider';
import { Breakpoints } from '../../../../dictionaries';
import { DatepickerField } from '../../../shared/components/Datepicker';
import Input from '../../../shared/components/form/Input';
import FormLabel from '../../../shared/components/form/FormLabel';
import { ageCalculator } from '../../../../utils';
import { country } from '../../../schedule/components/PersonalInformation/PassportCountry';
import Select from '../../../shared/components/form/Select';
import Modal from '../../../shared/components/Modal';
import { useSharedDispatch, useSharedState } from '../../../shared/provider';
import { differenceInYears, format, parse } from 'date-fns';
import { config } from '../../../../config';
import { Button, CustomSwitch } from './../../../shared/components/styled';
import { updateRegister } from '../../../register/api';
import { FormControlLabel, Switch } from '@material-ui/core';
import { minoremailValidate } from '../../../../validations';
import { filter } from 'lodash';
import { IDropDown } from '../../../register/models';

const Container = styled.div`
  margin-top: 40px;
`;
export const ErrorText = styled.div`
  font-size: 13px;
  color: ${colors.red};
  opacity: ${({ hasError }: { hasError: boolean }) => (hasError ? 1 : 0)};
  transition: opacity 0.15s ease-in-out;
`;

const Title = styled.h4`
  color: rgba(38, 38, 38, 0.8);
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  margin: 0 0 23px 0;
`;
const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 240px;
  margin: 0 -5px;

  & > * {
    margin: 0 5px;
  }
  @media (max-width: ${Breakpoints.sm}px) {
    display: grid;
    row-gap: 10px;
  }
`;
type Props = {
  isRegister: boolean;
  isProfile: boolean;
  open: boolean;
  close: () => void;
  index: number;
  isConfirmBooking: boolean;
};

const ChangeMinorModal: React.FC<Props> = ({
  isRegister,
  open,
  close,
  index,
  isProfile,
  isConfirmBooking,
}) => {
  const { updateFormValues } = useStepsDispatch();
  let {
    form: { minors, isINTNameFormat, testSelection, cPartnerID },form
  } = useStepsState();
  const [schoolDropDown, setSchoolDropDown] = useState<IDropDown[]>([]);
  const [errormsg, setErrormsg] = useState<boolean>(false);
  const { userData, travellType,locations } = useSharedState();
  const { setloggedUserData } = useSharedDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [newMinorData, setNewMinorData] = useState<any>([]);
  const [updateMinor, setUpdateMinor] = useState<boolean>(false);

  useEffect(() => {
    const getSchoolList = async () => {
      let filteredlocation = filter(locations, (data: any) => {
        return data.cPartnerID === cPartnerID;
      });
      let dropDown: any = [];
      filteredlocation.forEach((school) => {
        dropDown.push({ label: school.name, value: school.name, qbenchCustomerId: school.qbenchCustomerId });
      });
      setSchoolDropDown(dropDown);

    }


    getSchoolList()
  }, [cPartnerID, locations])
  const saveMinorDetails = (
    fieldName: string,
    index: number,
    newValue: string | Date | null | boolean,
    dateValue: Date | null
  ) => {
    const newMinors: any = isProfile ? userData!.minors : minors;
    if (updateMinor) {
      if (fieldName === 'birthDate') {
        newMinors[index][fieldName] =
          dateValue && format(dateValue, config.dateFormat);
      } else {
        newMinors[index][fieldName] = newValue;
      }
      setNewMinorData(newMinors);
    }
    setErrormsg(false);
  };
  const updateChangeOnClick = async (values: any) => {
    setLoading(true);
    if (isProfile) {
        const mergedMinors: any = []
        let lastestChange: any =values.minors
        userData?.minors.map((minor: any, index: any) => {

        const updatedMinor = values?.minors.find((updated: any) => updated.index == index);          
            if (updatedMinor) {
              mergedMinors.push(updatedMinor)
            } else {
              mergedMinors.push(minor)
            }
        });
      let resultdata = await updateRegister({
        email: userData?.email,
        minors: mergedMinors?.map((minor: any) => ({
          ...minor,
          birthDate:
            minor.birthDate && format(new Date(minor.birthDate), config.dateFormat),
        })),
      });
      if (resultdata?.data?.status === 200) {
        lastestChange = lastestChange?.map((minor: any) => ({
            ...minor,
            birthDate:
              minor.birthDate && format(new Date(minor.birthDate), config.dateFormat),
          }))
        updateFormValues({ minors: [...lastestChange] });
        setloggedUserData(resultdata?.data?.data);
        localStorage.setItem('user', JSON.stringify(resultdata?.data?.data));
        setUpdateMinor(true);
      } else {
        setUpdateMinor(false);
      }
    }
    close();
    setLoading(false);
  };
  const [validEmail, setValidEmail] = useState<boolean>(false);
  const [checkbox, setcheckbox] = useState(()=>{  
    let data = userData?.minors !== undefined && userData!.minors?.length > 0
    ? userData?.minors.map((minor) => ({
      ...minor,
      birthDate:
      minor.birthDate &&
      parse(minor.birthDate, config.dateFormat, new Date()),  
      notification:minor?.notification!==undefined?minor?.notification:false,
      email:minor?.email!==undefined?minor?.email:'',
    }))
    : minors.map((minor:any) => ({
      ...minor,
      birthDate:minor.birthDate &&parse(minor.birthDate, config.dateFormat, new Date()),
      notification:minor?.notification!==undefined?minor?.notification:false,
      email:minor?.email!==undefined?minor?.email:'',
    }))
    if(data[index]?.email===undefined||data[index]?.email===null||data[index]?.email==='')
    {
      setValidEmail(true);
      return false;
    }
    return data[index]?.notification!==undefined?data[index]?.notification:false;
  })  
  return (
    <Modal open={open} onClose={close} maxWidth={800} blockScroll={false}>
      <Container>
        <Title>Minor Information</Title>

        <Formik
          initialValues={{
            minors:
              userData?.minors !== undefined &&
              userData!.minors?.length > 0 &&
              !isConfirmBooking
                ? userData?.minors.map((minor) => ({
                    ...minor,
                    birthDate:
                      minor.birthDate &&
                      parse(minor.birthDate, config.dateFormat, new Date()),
                  }))
                : minors.map((minor: any) => ({
                    ...minor,
                    birthDate:
                      minor.birthDate &&
                      parse(minor.birthDate, config.dateFormat, new Date()),
                  })),
          }}
          onSubmit={async (values) => {
            let data = values.minors.map((e: any, i: Number) => {
              if (i === index) {
                return { ...e, notification: checkbox };
              } else return e;
            });
            let formate = { minors: data };
            if (isProfile) {
              //updateFormValues({ minors: newMinorData });
              updateChangeOnClick(formate);
            } else {
              close();
            }
          }}
        >
          {(data: any) => (
            <Form>
              <FormLabel label="First Name">
                <Input
                  name={`minors.${index}.firstName`}
                  value={data.values.minors[index].firstName}
                  isRequired
                  validate={(value) => {
                    let error;

                    if (
                      value != null &&
                      value != 'undefined' &&
                      value.trim() === ''
                    ) {
                      error = 'Required field';
                    }

                    return error;
                  }}
                  onChange={(value) =>
                    saveMinorDetails('firstName', index, value, null)
                  }
                />
              </FormLabel>
              {(travellType === 'INT' || !isINTNameFormat) && (
                <FormLabel label="Middle Name">
                  <Input
                    name={`minors.${index}.middleName`}
                    value={data.values.minors[index].middleName || ''}
                    // isRequired
                    // validate={(value) => {
                    //   let error;

                    //   if (
                    //     value != null &&
                    //     value != 'undefined' &&
                    //     value.trim() === ''
                    //   ) {
                    //     error = 'Required field';
                    //   }

                    //   return error;
                    // }}
                    onChange={(value) =>
                      saveMinorDetails('middleName', index, value, null)
                    }
                  />
                </FormLabel>
              )}
              <FormLabel label="Last Name">
                <Input
                  name={`minors.${index}.lastName`}
                  value={data.values.minors[index].lastName}
                  isRequired
                  validate={(value) => {
                    let error;

                    if (
                      value != null &&
                      value != 'undefined' &&
                      value.trim() === ''
                    ) {
                      error = 'Required field';
                    }

                    return error;
                  }}
                  onChange={(value) =>
                    saveMinorDetails('lastName', index, value, null)
                  }
                />
              </FormLabel>
              <FormLabel label="Email">
                <Input
                  name={`minors.${index}.email`}
                  value={
                    data.values.minors[index]?.email
                      ? data.values.minors[index]?.email
                      : ''
                  }
                  isRequired
                  validate={(value) => {
                    let error;
                    var pattern = new RegExp(minoremailValidate);
                    if (value !== '' && value !== undefined) {
                      if (!pattern.test(value)) {
                        setValidEmail(true);
                        setcheckbox(false);
                        error = 'Enter correct Email';
                        return error;
                      } else {
                        setValidEmail(false);
                      }
                    } else {
                      return;
                    }
                  }}
                  onChange={(value) =>
                    saveMinorDetails('email', index, value, null)
                  }
                />
              </FormLabel>
              <FormControlLabel
                control={
                  <CustomSwitch
                    name={`minors.${index}.notification`}
                    checked={checkbox}
                    disabled={validEmail}
                    onChange={(e) => {
                      saveMinorDetails(
                        'notification',
                        index,
                        e.target.checked,
                        null
                      );
                      setcheckbox(e.target.checked);
                    }}
                    color="primary"
                  />
                }
                label="Allow minor to receive test results"
                labelPlacement="end"
              />{' '}
              <br />
              <br />
              {!isRegister && testSelection === 'school' && (
                <>
                  <FormLabel label="School">
                    <Select<string>
                      name={`minors.${index}.school`}
                      options={schoolDropDown}
                      validate={(value) => {
                        if (testSelection === 'school' && !value) {
                          return 'Required field';
                        }
                      }}
                      onChange={(value) =>
                        saveMinorDetails('school', index, value, null)
                      }
                    />
                  </FormLabel>
                  <FormLabel
                    label={
                      form?.cPartnerID === 'BEL001'
                        ? 'Student/Community Member/Staff ID #'
                        : 'Student/faculty/Staff ID #'
                    }
                  >
                    <Input
                      name={`minors.${index}.studentID`}
                      value={data.values.minors[index].studentID}
                      isRequired
                      validate={(value) => {
                        if (
                          testSelection === 'school' &&
                          (!value || !value.trim().length)
                        ) {
                          return 'Required field';
                        }
                      }}
                      onChange={(value) =>
                        saveMinorDetails('studentID', index, value, null)
                      }
                    />
                  </FormLabel>
                </>
              )}
              <FormLabel label="Date of Birth">
                <DatepickerField
                  name={`minors.${index}.birthDate`}
                  inputPlaceholder="MM/DD/YYYY"
                  isRequired
                  validate={(value) => {
                    let error;

                    let date = ageCalculator(value);
                    if (date.years >= 18 && value !== null) {
                      if (
                        (date.years == 18 && date.months !== 0) ||
                        (date.months == 0 && date.days !== 0)
                      ) {
                        error = 'Minor must be under 18 years old';
                      } else if (date.years > 18) {
                        error = 'Minor must be under 18 years old';
                      }
                    }

                    //Validate less then 5 years old

                    return error;
                  }}
                  onChange={(value: Date | null) =>
                    saveMinorDetails('birthDate', index, null, value)
                  }
                />
              </FormLabel>
              <FormLabel label="Relationship">
                <Input
                  name={`minors.${index}.relationship`}
                  value={data.values.minors[index].relationship}
                  isRequired
                  validate={(value) => {
                    let error;

                    if (
                      value != null &&
                      value != 'undefined' &&
                      value.trim() === ''
                    ) {
                      error = 'Required field';
                    }

                    return error;
                  }}
                  onChange={(value) =>
                    saveMinorDetails('relationship', index, value, null)
                  }
                />
              </FormLabel>
              {!isRegister && userData?.travelType === 'INT' && (
                <>
                  <FormLabel label="Passport Country">
                    <Select<string>
                      name={`minors.${index}.passportCountry`}
                      options={country}
                      validate={(value) => {
                        if (travellType == 'INT' && !value) {
                          return 'Required field';
                        }
                      }}
                      onChange={(value) =>
                        saveMinorDetails('passportCountry', index, value, null)
                      }
                    />
                  </FormLabel>
                  <FormLabel label="Passport #">
                    <Input
                      name={`minors.${index}.passportNo`}
                      value={data.values.minors[index].passportNo}
                      isRequired
                      validate={(value) => {
                        if (
                          travellType == 'INT' &&
                          (!value || !value.trim().length)
                        ) {
                          return 'Required field';
                        }
                      }}
                      onChange={(value) =>
                        saveMinorDetails('passportNo', index, value, null)
                      }
                    />
                  </FormLabel>
                </>
              )}
          
              <ButtonsContainer>
                <Button
                  libraryType="default"
                  size="sm"
                  type="button"
                  onClick={async () => {
                    let user: any = JSON.parse(
                      localStorage.getItem('user') || ''
                    );
                    if (user !== '' && user?.minors) {
                    //   updateFormValues({ minors: user.minors });
                      setloggedUserData(user);
                    }
                    close();
                  }}
                  className="btn elevated-btn"
                >
                  Cancel
                </Button>
                <Button
                  libraryType="primary"
                  size="sm"
                  type="submit"
                  className="btn fill-btn"
                >
                  {loading ? 'Processing...' : 'Confirm'}
                </Button>
              </ButtonsContainer>
            </Form>
          )}
        </Formik>
      </Container>
    </Modal>
  );
};

export default ChangeMinorModal;
