import React from 'react';
import styled from 'styled-components';
import {
  Content,
  PageHeader,
  SubHeaderDescription,
  PageTitle,
 LogoCard, LogoImage,
 P
} from '../../../shared/components/styled';
import { colors } from '../../../../styles/colors';
import { Breakpoints } from '../../../../dictionaries';
import { useStepsDispatch, useStepsState } from '../../provider';
import { useSharedState } from '../../../shared/provider';
import SchoolSelection from './SchoolSelection';
import GeneralSelection from './GeneralSelection';
import FlightSelection from './FlightSelection';
import CommunitySelection from './CommunitySelection';
import SfoAirportSelection from './SfoAirportSelection';
import GoogleAnalytics from '../../../../GoogleAnalytics';
import { config } from '../../../../config';
import wLogo from '../../../../assets/wsl-w-only.png';
import circleRectAngleRight from '../../../../assets/circle+rectangle-teal.png';
import circleRectAngleBottom from '../../../../assets/circle+rectangle-teal-bottom.png';
import { CheckOutlined } from '@ant-design/icons';
import Footer from '../../../shared/components/Footer';


export const WLLogo = styled.div`
  height: 60px;
  width: 270px;
  background: url(${wLogo}) no-repeat center center;
  background-size: cover;
  margin-bottom: 10px;
  display: inline-block;

  @media (max-width: ${Breakpoints.sm}px) {
    margin-top: 5%;
  }
`;
export const BackgroundImage = styled.img`
    height: 100vw;
    width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(0%) rotate(270deg);
    @media (max-width: ${Breakpoints.xl}px) {
        top:auto;
        left:0;
        bottom:0;
        height:20vh;
        width:100vw;
        transform:translateY(0) translateX(0) rotate(0deg);
    }
`
export const Divreverse = styled.div`
    width: 100vw;
    display:flex;
    flex-wrap: wrap;
    justify-content: space-around;
    min-height: 91.2vh;
    align-content: center;
    flex-direction: row;
    @media (max-width: ${Breakpoints.xl}px) {
        flex-direction: column-reverse;
        min-height:80vh;
    }
`
const Container = styled.div`
    margin-top:35px;
    max-width: 580px;
    margin:0px 10px;
    background: linear-gradient(
        225.3deg,
        rgba(255, 255, 255, 0) 1.75%,
        rgba(255, 255, 255, 0.2) 46.27%,
        rgba(255, 255, 255, 0.2) 64.18%,
        rgba(255, 255, 255, 0.2) 100%
    ),
    #fcca46;
    box-shadow: -4px -4px 8px rgba(153, 153, 153, 0.05),
    4px 4px 8px rgba(0, 0, 0, 0.15);
    border-radius: 32px;
    @media (max-width: ${Breakpoints.xl}px) {
        margin-top:6rem;
    }
    @media (max-width: ${Breakpoints.md}px) {
        margin-top:6rem;
    }
    @media (max-width: ${Breakpoints.sm}px) {
        margin-top:6rem;
    }
`;
const Disclaimer = styled.p`
  font-size: 10px;
  font-weight: 400;
  text-align: left;
  margin-bottom: 10px;
  width: 70%;
`;
const Stripe = styled.div`
    position: absolute;
    width: 100vw;
    height: 6.5%;
    background-color: rgb(97, 156, 138);
`;
const Background = styled.div`
    background-image: url(${circleRectAngleRight});
    position: absolute;
    width: 100vw;
    height: 92.2vh;
    z-index: 1;
    background-repeat: no-repeat;
    background-size: 55% 140%;
    background-position: right;
    // background-attachment: fixed;

    @media (max-width: 1280px) {
        background-position: bottom;
        background-image: url(${circleRectAngleBottom});
        background-size: 100% 12%;
        overflow-y: scroll;
        overflow-x: hidden;
        height: 120vh;
    }
    @media (max-width: 960px) {
        background-size: 100% 8%;
        height: 170vh;
    }
    @media (max-width: 600px) {
        background-size: 117% 7%;
        height: 220vh;
    }
`
const Div = styled.div`
    position: absolute;
    bottom: 0px;
    width: 100vw;
    height: 92.2vh;
    overflow-y: scroll;
    overflow-x: hidden;
    @media (max-width: 1280px) {
        overflow-y: scroll;
        overflow-x: hidden;
    }
`
const Heading = styled.h2`
    font-weight:600px;
    font-size:58px;
    @media (max-width: ${Breakpoints.xl}px) {
        font-size:33px;
        margin-top:30px;
    }
`
const TextContainer = styled.div`
    max-width: 580px;
    margin:15px 10px 40px 10px;
    border-radius: 32px;
    background-color:white;
    @media (max-width: ${Breakpoints.xl}px) {
        margin-bottom:20vh;
    }
    `;
const TestFields = styled.div`
    margin:0px;
    @media (max-width: ${Breakpoints.sm}px) {
        margin:0px 10px;
    }
`
const FooterDiv = styled.div`
    position: fixed;
    width: 100vw;
    z-index: 11111;
    bottom: 0px;
    background-color: rgb(36, 61, 77);
    height: 40px;
    @media (max-width: 960px) {
        height: 40px;
    }
    @media (max-width: 600px) {
        height: 70px;
    }
`;  

const NewLoginTwo: React.FC = () => {
  const { form } = useStepsState();
  const { testSelection, cPartnerID, location } = form;
  const {tenant, isWalkUp} = useSharedState();
  const {
    updateFormValues,
    goToNextStep,
    // goToSkip4Step,
    // goToPrevStep,
    // goToSkipPrevStep,
    } = useStepsDispatch();
  React.useEffect(() => {
    GoogleAnalytics(form,'/Schedule Travel Test');
  }, []);

  React.useEffect(() => {
    if(isWalkUp.active && isWalkUp.locationId==config.qbenchCustomerId.KernHealthSystems){
       goToNextStep()
     }
    if(testSelection==='kern' || location?.cPartnerID==='KEN001' || tenant==='kern' ){
     updateFormValues({ cPartnerID: 'KEN001' })
     goToNextStep()
    }
   }, []);

  let { partnerLogo:{logo} } = useSharedState();

  return (
    <>
        <Div>
        {/* <Stripe /> */}
        <Background>
            <Divreverse>
                <div>
                    <TextContainer>
                        <Content style={{  padding: '16px 32px' }}>
                            <>
                                <Heading> BOOK YOUR APPOINTMENT</Heading>
                                <br />
                                <p>
                                Worksite Labs is here to make your COVID-19 pre-travel testing
                                simple.
                                <br />
                                Our testing method meets the pre-trave testing requiement for most
                                countries.
                                </p>
                                <br />

                                <ul
                                style={{ listStyleType: 'none', color: 'green', fontWeight: 400,fontStyle:'normal',fontSize:'14px' }}
                                >
                                <li>
                                    <CheckOutlined style={{ color: 'green',  }} />
                                    Results delivered within 24 hours (express 12 hour turnaround
                                    available in select locations).
                                </li>
                                <li>
                                    <CheckOutlined style={{ color: 'green', }} />
                                    Gold standard NAAT RT-PCR tests processed by CLIA certified labs
                                    that
                                    <br /> meet most countries travel testing requirements.
                                </li>
                                <li>
                                    <CheckOutlined style={{ color: 'green', }} />
                                    Approved Trusted Testing Partner with the State of Hawaii.
                                </li>
                                </ul>
                                <P>
                                    *International travel requirements change frequently and we do not
                                    restrict you from booking for any destination states or countries,
                                    regardless of current travel restrictions or requirements. Please
                                    research your destination country's requirements before booking an
                                    appointment.Worksite Labs is not responsible for refunding tests if
                                    there are travel restrictions for your final destination. Individual
                                    country requirements can be found on the
                                    <a
                                        href="https://travel.state.gov/content/travel/en/traveladvisories/COVID-19-Country-Specific-Information.html"
                                        target="_blank"
                                    >
                                        U.S. Department of State website here.
                                    </a>
                                    State of Hawaii travel requirements can be found{' '}
                                    <a href="https://hawaiicovid19.com/travel/" target="_blank">
                                        here.
                                    </a>{' '}
                                    Our default method of collection is self-collected mid-turbinate
                                    swabs. We have select locations that can perform nasopharyngeal
                                    collections for an additional fee. Please contact our customer
                                    service team to confirm if the site you would like to book at is
                                    currently offering nasopharyngeal swabs. Please contact our customer
                                    service team if you have questions regarding using our lab reports
                                    for entry at +1 833-747-1122 or support@worksitelabs.com.
                                    <span style={{ color: 'red' }}>*</span>
                                </P>
                            </>
                        </Content>
                    </TextContainer>
                    {/* </div> */}
                </div>
                <div>
                    <Container>
                    <Content style={{ textAlign: 'center', padding: '16px 32px' }}>
                        <>
                        <LogoCard district={cPartnerID}>
                            <LogoImage src={logo.length ? logo : ''} />
                        </LogoCard>
                        <PageHeader style={{ margin: 0 }}>
                            <PageTitle color={colors.fullBlack} textAlign="center">
                            Schedule your COVID-19 test
                            </PageTitle>
                        </PageHeader>
                        <SubHeaderDescription style={{ padding: '0px 40px', margin:'0px 10px' }}>
                            {testSelection === 'school'
                            ? 'Please note that this scheduling portal is meant to be used for School Districts that Worksite Labs is contracted with. If you would like to learn more about how to get your district enrolled, please contact support@worksitelabs.com'
                            : 'Please select the best option below to create or sign-in to your COVID-19 testing account with Worksite Labs.'}
                        </SubHeaderDescription>
                        <TestFields>
                            {testSelection === 'school' && <SchoolSelection />}
                            {testSelection === 'general' && <GeneralSelection />}
                            {testSelection === 'flight' && <FlightSelection />}
                            {testSelection === 'community' && <CommunitySelection />}
                            {testSelection === 'sfoairport' && <SfoAirportSelection />}
                        </TestFields>
                        </>
                    </Content>
                    </Container>
                    {/* </div> */}
                </div>
            </Divreverse>
        </Background>
        </Div>
        <FooterDiv>
            <Footer />
        </FooterDiv>
        </>
  );
};

export default NewLoginTwo;

//ltst