import React, { useState, useEffect, useMemo } from 'react';
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  Row,
  Space,
  Select,
  Table,
  Typography,
} from 'antd';
import { MoreOutlined, UpOutlined, DownOutlined } from '@ant-design/icons';
import { format } from 'date-fns';

import AntDDatePicker from '../../../shared/components/AntDDatePicker';
import { getAppointmentsByDate, downloadResultsPDF, getAppointmentsByconfirmationId,generateALDDReport,getALDDReportByDate } from '../../api';
import { useAdminState, useAdminDispatch } from '../../provider';
import { getPeriodsFromSchedule, compareStrings } from '../../../../utils';
import { AppointmentListItem } from '../../models';
import { config } from '../../../../config';
import { useSharedState } from '../../../shared/provider';

import DownloadCSV from './components/DownloadCSV';
import EditAppointmentModal from './components/EditAppointmentModal';
import EditAppointment from './components/EditAppointment';
import MoreActions from './components/MoreActions';
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  formatTableColumn,
  formItemLayout,
  searchBarActionLayout,
} from './constants';
import { styles } from './styles';

const { Content } = Layout;
const { Title } = Typography;
const { Option } = Select;

const periods = getPeriodsFromSchedule(config.schedule);
const rapidPeriods = getPeriodsFromSchedule(config.rapidSchedule);


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
       
        odd : {
          background: "#a6d0e3",
          "&:hover": {
            "& > td" : {
              backgroundColor: "#a6d0e3 !important",
              "&:last-child": {
                backgroundColor: "#f8f9fa !important",
              }
            }
           
           

          },
         
        },
        
        even: {
          background: "#f8f9fa",
          "&:hover": {
            "& > td" : {
              backgroundColor: "#f8f9fa !important",
            }
          },
          
        },

        rapid: {
          background: "#FFD580",
          "&:hover": {
            "& > td" : {
              backgroundColor: "#FFD580 !important",
            }
          },
          
        },
       
    }),
);




const sortByPeriod = (a: AppointmentListItem, b: AppointmentListItem) => {
  if (a.period !== null && b.period !== null) {
    return a.period - b.period;
  }
  return 0;
}

const renderSymptoms = (symptoms: any) =>{
  return symptoms.length
    ? symptoms.map !== undefined ? symptoms.map((symptom: any, symptomIndex: number) => (
      <div key={symptomIndex}>{symptom}</div>
    ))
    :  symptoms
    : 'None';
  
}

const renderResults = (results: any, record: any) =>
  results && (
    <Button
      type="link"
      onClick={() => {
        downloadResultsPDF(record.sampleId);
      }}
    >
      {results}
    </Button>
  );

const renderActionsMenuTitle = () => <MoreOutlined />;

function AppointmentList() {
  const classes = useStyles();
  const { params, appointments } = useAdminState();
  const { updateParams, setAppointments } = useAdminDispatch();
  const { locations } = useSharedState();
  const [ShowEditAppointmentModal, setShowEditAppointmentModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [alddloading, setalddLoading] = useState(false);
  const [mouseover, setmouseover] = useState(false);
  const [expand, setExpand] = useState(false);
  const [searchKeys, setSearchKeys] = useState({
    firstName: '',
    lastName: '',
    confirmationId: '',
    phone: '',
    birthDate: '',
    email: '',
    date: format(new Date(), config.dateFormat),
    locationQbenchId: null,
  });
  const [
    editAppointment,
    setEditAppointment,
  ] = useState<AppointmentListItem | null>(null);

  const renderActionsMenu = (action: any, record: any) => (
    <MoreActions
      record={record}
      onEditAppointment={setEditAppointment}
      //closeModal={() => setShowEditAppointmentModal(false)}
      handleDrawerToggle={setShowEditAppointmentModal}
    />
  );

  const renderTableColumn = formatTableColumn(
    renderSymptoms,
    renderResults,
    renderActionsMenuTitle,
    renderActionsMenu
  );


  const handleEditAppointment = () => {

    // handleDrawerToggle(false)
    //handleDrawerToggle(false)\
    setShowEditAppointmentModal(false)
    //SerachAppointment()
  };


  const SerachAppointment = () => {
    if (params.locationQbenchId) {
      setLoading(true);

      getAppointmentsByDate(params)
        .then(({ data }) => {
          const updatedAppointments = data
            .sort(sortByPeriod)
            .map((item: AppointmentListItem) => ({
              ...item,
              period:
                item.period !== null && item.period >= 0
                  ? periods[item.period].label
                  : '-',
              hadContact: item.hadContact ? 'Yes' : 'No',
            }));

          setAppointments(updatedAppointments);
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
        });
    }

  };

  const dataSourceAppointments = useMemo(
    () =>
  
      appointments
        ?.filter((appointment: any) => {
          const filterList = [
            'firstName',
            'lastName',
            'confirmationId',
            'phone',
            'birthDate',
            'email',
          ];
          for (const filterItem of filterList) {
            const searchKey = searchKeys[filterItem as keyof typeof searchKeys];
            const value = appointment[filterItem];

            if (searchKey && !compareStrings(value, searchKey.toString())) {
              return false;
            }
          }
          return true;
        })
        .map((appointment: any, appIndex: number) => ({
          ...appointment,
          key: appIndex,
        })),
        
    [appointments, searchKeys]
  );




  useEffect(() => {

    if (params.confirmationId) {


      setLoading(true);

      getAppointmentsByconfirmationId(params)
        .then(({ data }) => {
          const updatedAppointments = data
            .sort(sortByPeriod)
            .map((item: any) => ({
              ...item,
              period:
              item.isRapidTest || item.isRapidTest == "TRUE" ?
                (item.period !== null && item.period >= 0
                  ? rapidPeriods[item.period].label
                  : '-') :
                (item.period !== null && item.period >= 0
                  ? periods[item.period].label
                  : '-'),
              hadContact: item.hadContact ? 'Yes' : 'No',
	            // isExpressSameDayTest: item.isExpressSameDayTest ? 'Yes' : 'No'
              isExpressSameDayTest:  item.isExpressSameDayTest == "FALSE" ? "No": item.isExpressSameDayTest == "TRUE" ? "Yes" : item?.isExpressSameDayTest  ? 'Yes' : 'No',
              isRapidTest:  item.isRapidTest == "FALSE" ? "No": item.isRapidTest == "TRUE" ? "Yes" : item?.isRapidTest  ? 'Yes' : 'No',
              isCanceled: item.isCanceled ? "Canceled": ""
            }));

          setAppointments(updatedAppointments);
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
        });


    }
    else if (params.locationQbenchId) {
      setLoading(true);

      getAppointmentsByDate(params)
        .then(({ data }) => {
          const updatedAppointments = data
            .sort(sortByPeriod)
            .map((item: any) => ({
              ...item,
              period:
              item.isRapidTest || item.isRapidTest == "TRUE" ?
                (item.period !== null && item.period >= 0
                  ? rapidPeriods[item.period].label
                  : '-') :
                (item.period !== null && item.period >= 0
                  ? periods[item.period].label
                  : '-'),
              hadContact: item.hadContact ? 'Yes' : 'No',
              isExpressSameDayTest:  item.isExpressSameDayTest == "FALSE" ? "No": item.isExpressSameDayTest == "TRUE" ? "Yes" : item?.isExpressSameDayTest  ? 'Yes' : 'No',
              isRapidTest:  item.isRapidTest == "FALSE" ? "No": item.isRapidTest == "TRUE" ? "Yes" : item?.isRapidTest  ? 'Yes' : 'No',
              isCanceled: item.isCanceled ? "Canceled": ""
            }));

          setAppointments(updatedAppointments);
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
        });
    }
  }, [params, setAppointments]);

  const handleSearchKeysUpdate = (key: string) => (value: string | number) => {
    setSearchKeys({
      ...searchKeys,
      [key]: value,
    });
  };


  const handleHover = () => {
    setmouseover(true)
    }
  
    const handleHoverout = () => {
      setmouseover(false)
    }; 

    const getALDDReport = () => {
      setalddLoading(true);
      getALDDReportByDate(searchKeys)
        .then(({ data }) => {
          const updatedAppointments = data
            .sort(sortByPeriod)
            .map((item: any) => ({
              ...item,
              period:
              item.isRapidTest || item.isRapidTest == "TRUE" ?
              (item.period !== null && item.period >= 0
                ? rapidPeriods[item.period].label
                : '-') :
              (item.period !== null && item.period >= 0
                ? periods[item.period].label
                : '-'),
              hadContact: item.hadContact ? 'Yes' : 'No',
              isExpressSameDayTest:  item.isExpressSameDayTest == "FALSE" ? "No": item.isExpressSameDayTest == "TRUE" ? "Yes" : item?.isExpressSameDayTest  ? 'Yes' : 'No',
              isRapidTest:  item.isRapidTest == "FALSE" ? "No": item.isRapidTest == "TRUE" ? "Yes" : item?.isRapidTest  ? 'Yes' : 'No',
              isCanceled: item.isCanceled ? "Canceled": ""
            }));
            setalddLoading(false);
            generateALDDReport(updatedAppointments)
         
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
        });
  
    };

  const handleSearch = () => {
    updateParams(searchKeys);
  };

  return (
    <div>
      {!ShowEditAppointmentModal ? <Layout style={{ marginTop: "100px", backgroundColor: "#FFF", border: "1px solid #f3eeee", boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)" }}>


        <div style={{ borderRadius: "3px", background: "linear-gradient(60deg, #00acc1, #00acc1)", boxShadow: "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(156, 39, 176,.4)", margin: "0px 25px", marginTop: "-25px" }}>
          <Title level={4} style={{ margin: '24px 16px', color: "#FFF" }}>
            Day Wise Appointments
        </Title>
        </div>
        <Content style={styles.content}>
          <Space direction="vertical" style={styles.spaceWidth} size="middle">
            <Card bodyStyle={styles.card}>
              <Form {...formItemLayout}>
                <Row gutter={12}>
                  <Col span={8}>
                    <Form.Item label="Date" name="date">
                      <AntDDatePicker
                        style={styles.fullWidth}
                        disabled={loading}
                        defaultValue={new Date()}
                        showToday
                        onChange={(newDate) =>
                          handleSearchKeysUpdate('date')(
                            format(newDate!, config.dateFormat)
                          )
                        }
                        allowClear={false}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Location" name="location">
                      <Select
                        disabled={loading}
                        allowClear
                        placeholder="Please select"
                        showArrow
                        onChange={handleSearchKeysUpdate('locationQbenchId')}
                      >
                        {locations?.map((location) => (
                          <Option
                            key={location.qbenchCustomerId}
                            value={location.qbenchCustomerId}
                          >
                            {location.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Confirmation No" name="confirmationId">
                      <Input
                        allowClear
                        disabled={loading}
                        onChange={(event) =>
                          handleSearchKeysUpdate('confirmationId')(
                            event.target.value
                          )
                        }
                      />
                    </Form.Item>
                  </Col>
                  {expand && (
                    <>
                      <Col span={8}>
                        <Form.Item label="First Name" name="firstName">
                          <Input
                            disabled={loading}
                            allowClear
                            onChange={(event) =>
                              handleSearchKeysUpdate('firstName')(
                                event.target.value
                              )
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item label="Last Name" name="lastName">
                          <Input
                            disabled={loading}
                            allowClear
                            onChange={(event) =>
                              handleSearchKeysUpdate('lastName')(
                                event.target.value
                              )
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col span={8}>
                        <Form.Item label="Email" name="email">
                          <Input
                            disabled={loading}
                            allowClear
                            onChange={(event) =>
                              handleSearchKeysUpdate('email')(event.target.value)
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col span={8}>
                        <Form.Item label="Phone" name="phone">
                          <Input
                            allowClear
                            disabled={loading}
                            onChange={(event) =>
                              handleSearchKeysUpdate('phone')(event.target.value)
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item label="Date of birth" name="birthDate">
                          <AntDDatePicker
                            style={styles.fullWidth}
                            allowClear
                            disabled={loading}
                            showToday
                            onChange={(newDate) =>
                              handleSearchKeysUpdate('birthDate')(
                                newDate ? format(newDate!, config.dateFormat) : ''
                              )
                            }
                          />
                        </Form.Item>
                      </Col>
                    </>
                  )}
                  <Col span={8} style={{marginLeft:expand ?"0%":"65%"}}>
                    <Form.Item {...searchBarActionLayout}>
                      <Space size="small" style={styles.searchBarActions}>
                      <Button
                          type="primary"
                           loading={alddloading}
                          onClick={getALDDReport}
                          onMouseOver={handleHover}
                          onMouseDown={handleHoverout}
                          onMouseOut={handleHoverout}
                          onMouseEnter={handleHover}
                        >
                          {mouseover?"All Locations Daily Download" : "ALDD"}
                        </Button>
                        <Button
                          type="primary"
                          loading={loading}
                          onClick={handleSearch}
                        >
                          Search
                      </Button>
                        <Button
                          type="link"
                          onClick={() => {
                            setExpand(!expand);
                          }}
                        >
                          {expand ? (
                            <>
                              Less <UpOutlined />
                            </>
                          ) : (
                              <>
                                More <DownOutlined />
                              </>
                            )}
                        </Button>
                      </Space>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>

            <Card>
              {(!params.confirmationId && !params.locationQbenchId) && (
                <Alert
                  message="Please select a location or Enter ConfirmationNo"
                  type="warning"
                  showIcon
                  closable
                />
              )}
              <DownloadCSV />
              <Table
                size="small"
                columns={renderTableColumn as any}
                dataSource={(dataSourceAppointments as any) || []}
                loading={loading}
                scroll={{ x: 1500 }}
                rowKey={(record: any) => record.key}
                rowClassName={(record: any) => record.isRapidTest === 'Yes' ? classes.rapid : (record.isExpressSameDayTest === 'Yes' ? classes.odd : classes.even)}
              />
            </Card>
          </Space>
        </Content>
        {/* <EditAppointmentModal
        appointment={editAppointment}
        closeModal={() => setEditAppointment(null)}
      /> */}

      </Layout> :

        <div>
          <EditAppointment appointment={editAppointment} handleDrawerToggle={handleEditAppointment} />
        </div>
      }
    </div>
  );
}

export default AppointmentList;
