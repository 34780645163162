import React, { useState } from 'react'
import {
	Button,
	Dropdown,
	Menu,
} from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { parse, format } from 'date-fns';
import { config } from '../../../../../../config';

import Cancel from '../Cancel';
import SendConfirmation from '../SendConfirmation';
import firebase, { functions } from 'firebase';
import axios from 'axios';
import { useAdminDispatch, useAdminState } from '../../../../provider';
// import { getSlotsByLocationId } from '../../../../../shared/api';
import { getAppointmentdata } from '../../../../api';

interface IProps {
	record: any,
	onEditAppointment: (record: any) => void;
	handleDrawerToggle: Function
};

function MoreActions({
	record,
	onEditAppointment,
	handleDrawerToggle
}: IProps) {
	const [visible, setVisible] = useState(false);

	const { appointments } = useAdminState();
	const { updateParams, setEditAppointment, updateAdminSlots } = useAdminDispatch();

	const handleVisibleChange = (visibleStatus: boolean) => {
		setVisible(visibleStatus);
	};

	const handleEditAppointment = (record: any) => {
		setVisible(false);
		onEditAppointment(record)
		handleDrawerToggle(true)

		getAppointmentdata({ confirmationId: record.confirmationId })
			.then(async (response: any) => {

				if (response.data.length > 0) {
					// const periods: any = await getSlotsByLocationId(response.data[0].location?.qbenchCustomerId)
					// updateAdminSlots(periods)
					setEditAppointment(response.data)
					return true;

				} else {
					return true
				}

			})
			.catch(() => {

			})

	};


	const menu = (record: any) => (
		<Menu>
			{record.isCanceled === "" &&
				<>
					<Menu.Item key="0">
						<Button type="link" onClick={() => handleEditAppointment(record)} block>
							Edit
        			</Button>
					</Menu.Item>
					<Menu.Item key="1">
						<SendConfirmation confirmationId={record.confirmationId} email={record.email} />
					</Menu.Item>
				</>
			}
			<Menu.Item key="3">
				<Cancel appt={record} />
			</Menu.Item>
		</Menu>
	);

	return (
		<Dropdown
			overlay={menu(record)}
			trigger={['click']}
			visible={visible}
			onVisibleChange={handleVisibleChange}
		>
			<Button type="link" icon={<MoreOutlined />} />
		</Dropdown>
	)
}

export default MoreActions
