import React, { useState } from 'react';
import styled from 'styled-components';
import { Form, Formik } from 'formik';
import { Breakpoints } from '../../../dictionaries';
import { colors } from '../../../styles/colors';
import hallogo from '../../../assets/hal_logo.png';
import wsllogo from '../../../assets/WLABS_CMYK.png';
import wLogo from '../../../assets/wsl-w-only.png';
import circleRectAngle from '../../../assets/circle-rectangle-dark-blue.png';
import circleRectAngleBottom from '../../../assets/circle-rectangle-dark-blue-bottom.png';
import {
  Button,
  PageHeader,
  SubHeaderDescription,
  LogoCard, LogoImage
} from '../../shared/components/styled';
import Header from '../../shared/components/Header';
import { useHistory } from 'react-router-dom';
import { useSharedDispatch, useSharedState } from '../../shared/provider';
import { useStepsState } from '../provider';
import { registerLogin } from '../api';
import Input from '../../shared/components/form/Input';
import Footer from '../../shared/components/Footer';

export const WLLogo = styled.div`
  height: 60px;
  width: 270px;
  background: url(${wsllogo}) no-repeat center center;
  background-size: cover;
  margin-bottom: 15px;
  display: inline-block;

  @media (max-width: ${Breakpoints.sm}px) {
    margin-top: 20px;
  }
`;

const HALLogo = styled.div`
  height: 75px;
  width: 275px;
  background: url(${hallogo}) no-repeat center center;
  background-size: cover;
  display: inline-block;
  margin: 0px 0px 10px 0px;
  cursor: pointer;
  @media (max-width: ${Breakpoints.sm}px) {
    height: 70px;
    width: 220px;
    margin-top: 5%;
  }
`;

const SubText = styled.div`
  font-family: Open Sans;
  margin: 5px 0px 15px 0px;
  font-size: 16px;
  font-weight: 700;
  color: #243D4D;
  cursor: pointer;
`;
const Space = styled.div`
  @media (max-width: ${Breakpoints.sm}px) {
    margin-top: 30px;
  }
`;

const Error = styled.div`
  color: ${colors.red};
  margin-top: 15px;
  text-align: center;
`;
export const PageTitle = styled.h2`
  color:  ${colors.fullBlack};
  font-size: '28px'} ;
  font-weight: 700;
  margin: 0;
  text-align: center;
  @media (max-width: ${Breakpoints.sm}px) {
    font-size: 21px;
    text-align: center;
  }

`;
interface ContainerProps {
    size?: 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
    noPadding?: boolean;
    marginTop?: boolean;
    insurance?:boolean;
  }
const Container = styled.div`
    margin: 0px auto;
    min-height: 35px;
    background-color: rgb(255, 255, 255);
    border: 0.5px solid rgba(36, 61, 77, 0.14);
    box-shadow: rgb(153 153 153 / 5%) -4px -4px 8px, rgb(0 0 0 / 15%) 4px 4px 8px;
    border-radius: 12px;
    max-width: ${({ size }: ContainerProps) => {
        if (size === 'md') {
        return '760px';
        }

        if (size === 'lg') {
        return '920px';
        }

        if (size === 'xl') {
        return '1110px';
        }

        if (size === 'xxl') {
        return '1400px';
        }

        return '650px';
    }};
    margin-top: ${({ marginTop }: ContainerProps) => (marginTop ? '40px' : '80px')};
    padding: ${({ noPadding }: ContainerProps) => (noPadding ? '0px' : '0 15px')};
    @media (max-width: ${Breakpoints.xl}px) {
        border:none !important;
        box-shadow:none !important;
        border-radius:none !important;
    }
    @media (min-width: ${Breakpoints.sm}px) and (max-width: ${Breakpoints.md}px)  {
        margin-left:${({ insurance }: ContainerProps) => (insurance ? '5%' : '')};
    }
`

const Background = styled.div`
    background-image: url(${circleRectAngle});
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: 1;
    background-repeat: no-repeat;
    background-size: 55% 140%;
    background-position: right;
    // background-attachment: fixed;

    @media (max-width: ${Breakpoints.xl}px) {
        background-position: bottom;
        background-image: url(${circleRectAngleBottom});
        background-size: 100% 15%;
        overflow-x: hidden;
        overflow-y: scroll;
    }
    @media (max-width: ${Breakpoints.md}px) {
        background-size: 107% 15%;
        height:130vh;
    }
    @media (max-width: ${Breakpoints.sm}px) {
        background-size: 130% 15%
        height:130vh;
    }
`
const Div = styled.div`
    position: absolute;
    bottom: 0px;
    width: 100vw;
    height: 92.2vh;
    overflow-x: hidden;
    overflow-y: scroll;
    @media (max-width: ${Breakpoints.xl}px) {
        overflow-x: hidden;
        overflow-y: scroll;
    }
`
const FooterDiv = styled.div`
    position: fixed;
    width: 100vw;
    z-index: 11111;
    bottom: 0px;
    background-color: rgb(36, 61, 77);
    height: 40px;
    @media (max-width: 960px) {
        height: 40px;
        position: fixed;
    }
    @media (max-width: 600px) {
        height: 70px;
        position: fixed;
    }
`    
const Stripe = styled.div`
    position: absolute;
    width: 100vw;
    height: 6.5%;
    background-color: rgb(97, 156, 138);
`;
const MainContainer = styled.div`
    position: absolute;
    margin-top: 4%;
    z-index: 6;
    @media (max-width: ${Breakpoints.sm}px) {
        margin-top: 0%;
    }
`;
const Content = styled.div`
  @media (min-width: ${Breakpoints.md}) {
    padding: 88px 0;
  }
  @media (max-width: ${Breakpoints.lg}px) {
    padding: 0px 0px 10px 0px !important;
    
  }
`;
const BookApptFromSchdule: React.FC = () => {
  let history = useHistory();
  const [error, setError] = useState<string | null>(null);
  const { setloggedUserData, updateapptFromSchdule, setIsVerifyReq, updateVerifyFormValues } = useSharedDispatch();
  const { partnerLogo:{logo}, tenant } = useSharedState();
  const redirect = () => {
    localStorage.setItem('link',window.location.search)
    updateapptFromSchdule(true);
    if(tenant?.toLocaleLowerCase()==='kern' && userData)
        history.push('/user/profile');
    else
        history.push('/bookappointment');
  };

  const signinRedirect = () => {
    setIsVerifyReq(true); 
    history.push('/signin');
  };

  const { userData } = useSharedState();
  if (userData) {
    redirect();
  }

  const [loading, setLoading] = useState(false);
  const [accloading, setaccloading] = useState(false);
  const [isVerifyScreenMsg, setVerifyScreenMsg] = useState(false);

  let {
    form: { email, password },
  } = useStepsState();

  const district = localStorage.getItem('dist') || '';

  const handleSignIn = async (params: { email: string; password: string }) => {
    setLoading(true);
    updateapptFromSchdule(false);
    setaccloading(false);

    try {
      const loggedUser = await registerLogin(params);
      if (loggedUser?.data?.status == 200) {
        setloggedUserData(loggedUser?.data.data);
        localStorage.setItem('user', JSON.stringify(loggedUser?.data.data));
        if(tenant?.toLocaleLowerCase() === 'kern')
            history.push('/user/profile');
        else
            history.push('/bookappointment');
      } else {
        if(loggedUser?.data?.message === "Please verify your account to login."){
          updateVerifyFormValues({
            verifyEmail:loggedUser?.data?.data?.email,
            verifyPhone: loggedUser?.data?.data?.phone,
            verifyPassword: password,
          })
          setError(loggedUser?.data?.message);
          setVerifyScreenMsg(true);
      } else {
          setError(loggedUser?.data?.message);
      }
      }
      setLoading(false);
    } catch (e) {
      setError('Incorrect email or password');
      setLoading(false);
    }
  };
  if(window?.location?.search?.toLowerCase()?.includes('kern') || tenant?.toLowerCase() === 'kern'){
    localStorage.clear();
    return (
        <>
            <div style={{width:'100vw', height:'100vh', display:'flex', alignItems:'center', background:'white',position:'absolute', zIndex:100000, flexDirection:'column', padding:30}}>
                <p style={{fontSize:26, color:colors.black}}>
                    <br />
                    On-site testing services for Kern Family Health Care are no longer offered. If you would like to book a virtual testing appointment, <a style={{color:colors.rebrandBlack, textDecoration:'underline'}} href="https://calendly.com/wslconcierge/wsl-virtual-appt-kern?month=2022-09" target="_blank" rel="noreferrer">click here</a>. 
                    <br /><br />
                    Please contact <a style={{color:colors.rebrandBlack, textDecoration:'underline'}} href="https://support@worksitelabs.com" target="_blank" rel="noreferrer">support@worksitelabs.com</a> or call <a style={{color:colors.rebrandBlack, textDecoration:'underline'}} href="+1 (833) 747-1122" target="_blank" rel="noreferrer">+1 (833) 747-1122</a> with any questions. 
                </p>
            </div>
        </>    
    )
  }
  return (
    <>
    <Header withoutBg isHelp />
    <Div>
        <Stripe />
        <Background>
            <div style={{display:'flex', justifyContent:'center'}}>
                <div style={{display:'flex', justifyContent:'center',  alignItems:'center', position:'absolute', height:'100vh', width:'100vh',transform:'translate(-42%)', zIndex:0}}>
                    <img src={wLogo} alt="" />
                </div>
                <MainContainer>
                    <Container
                        style={{
                            minHeight: '500px',
                            backgroundColor: '#fff',
                            marginTop: '75px',
                            borderRadius: '14px',
                            border: '1px solid rgba(36, 61, 77, 0.14)',
                            boxShadow:'-4px -4px 8px rgba(153, 153, 153, 0.05), 4px 4px 8px rgba(0, 0, 0, 0.15)' 
                        }}
                        >
                         <Content
                            style={{ textAlign: 'center', padding: '10px 30px 30px 30px' }}
                            >
                            <>
                                <LogoCard>
                                <LogoImage
                                    src={logo.length ? logo : ''}
                                    height={'90px'}
                                />
                                </LogoCard>
                                <PageHeader>
                                    <PageTitle>Let's get you booked!</PageTitle>
                                </PageHeader>
                                <SubHeaderDescription style={{ padding: '0px 54px' }}>
                                    If you already have a Worksite Labs account, login to book your
                                    appointment.If you don't have an account, select "I don't have an
                                    account" to book your appointment.
                                </SubHeaderDescription>

                                <Formik
                                initialValues={{
                                    email: email,
                                    password: password,
                                }}
                                onSubmit={(values) => {
                                    handleSignIn({email:values?.email?.toLocaleLowerCase(),password:values.password});
                                }}
                                enableReinitialize={false}
                                >
                                {(values) => (
                                    <Form style={{ width: '80%', display: 'inline-block' }}>
                                    <Input
                                        name="email"
                                        placeholder="Email Address"
                                        isRequired
                                        value={values.values?.email?.toLocaleLowerCase()}
                                        onChange={(value) => (email = value)}
                                    />
                                    <Space />
                                    <Input
                                        name="password"
                                        placeholder="Password"
                                        type="password"
                                        isRequired
                                        onChange={(value) => (password = value)}
                                    />
                                    <Space />
                                    <SubText
                                        onClick={() => {
                                        history.push('/forgotpassword');
                                        }}
                                        className='underline'
                                    >
                                        Forgot Email or Password?
                                    </SubText>
                                    <Button
                                        libraryType="primary"
                                        type="submit"
                                        disabled={false}
                                        className="btn fill-btn"
                                    >
                                        {loading ? 'Processing...' : 'LOGIN'}
                                    </Button>
                                    <Space />
                                    {/* {(airline !== "" && destination !== "" && travelType !== "") && */}
                                    <Button
                                        libraryType="primary"
                                        type="button"
                                        disabled={false}
                                        className="outline1-btn"
                                        onClick={() => {
                                            setaccloading(true);
                                            redirect();
                                        }}
                                    >
                                        {accloading ? 'Processing...' : "I DON'T HAVE AN ACCOUNT"}
                                    </Button>
                                    {/* } */}

                                    {error && <Error>{error}<br/>{isVerifyScreenMsg && <a  onClick={()=> {signinRedirect(); }}>Click here</a>}  { isVerifyScreenMsg && "to verify your account."}</Error>}
                                    </Form>
                                )}
                                </Formik>

                                <SubHeaderDescription
                                style={{ fontSize: '12px', marginTop: '1px' }}
                                >
                                New to Worksite Labs? Sign up for your account below.
                                </SubHeaderDescription>

                                <div>
                                {tenant?.toLocaleLowerCase() === 'kern' && <SubText
                                        style={{ marginBottom: '20px' }}
                                        className='underline'
                                        onClick={() => {
                                        history.push('/register');
                                        }}
                                    >
                                        SIGN UP
                                </SubText>}
                                </div>
                            </>
                            </Content>
                    </Container>
                </MainContainer>
            </div>
        </Background>
        <FooterDiv>
            <Footer />
        </FooterDiv>
    </Div>
  </>
  );
};

export default BookApptFromSchdule;

//ltst