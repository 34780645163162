import React from 'react';
import styled from 'styled-components';

import { useStepsDispatch } from '../../schedule/provider';

import { Button } from './styled';

const Container = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  flex-wrap: nowrap;
  padding-top: 20px;

  @media (min-width: 450px) {
    flex-direction: row;
  }
  @media (min-width: 768px) {
    padding: 20px 0px 50px 0px;
  }
`;

const Space = styled.div`
  height: 10px;
  width: 20px;
`;

interface Props {
  onReturnButtonClick?: () => void;
  isValid?: any;
  confirmBtnText?: any;
  loading?: boolean;
  onClick?: () => void;
 disable?:boolean;
 isPromo?:boolean | false;
 isdisable?:boolean;
}

const NavigationButtons: React.FC<Props> = ({
  onReturnButtonClick,
  isValid,
  confirmBtnText,
  loading,
  onClick,
  isdisable,
  disable,
  isPromo,
}) => {
  const { goToPrevStep } = useStepsDispatch();

  return (
    <Container>
     { !isdisable &&  (<Button
        libraryType="default"
        accentColor="#f29a4e"
        type="button"
        onClick={() =>
          onReturnButtonClick ? onReturnButtonClick() : goToPrevStep()
        }
        className='btn elevated-btn'
      >
        Return
      </Button>)}
      <Space />
      <Button
        libraryType="primary"
        accentColor= "#f29a4e"
        type="submit"
        className={isPromo ? 'fill-btn-d' : isValid !== false ? 'btn fill-btn' : 'btn fill-btn-d'}
        disabled={isPromo || disable || loading || (isValid !== undefined ? !isValid : false)}
      >
        {loading ? 'Processing...' : confirmBtnText}
      </Button>
    </Container>
  );
};

NavigationButtons.defaultProps = {
  confirmBtnText: 'Save & Continue',
};

export default NavigationButtons;
