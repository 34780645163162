import React, { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';

import { ScheduleProvider } from '../provider';
import Steps from './Steps';

function Schedule() {
  useEffect(() => {
    mixpanel.track('Page View');
  }, []);

  return (
    <ScheduleProvider>
      <Steps />
    </ScheduleProvider>
  );
}

export default Schedule;
