import React, { useEffect } from 'react';

import { useStepsState } from '../provider';
import { Wrapper } from '../../shared/components/styled';

import Header from '../../shared/components/Header';
import Footer from '../../shared/components/Footer';

import SuccessfulAccountCreation from '../components/SuccessfulAccountCreation';
import ConfirmAccount from './ConfirmAccount';
import FooterRegister from './FooterRegister';
import SignIn from './SignIn';
import { useSharedDispatch, useSharedState } from '../../shared/provider';

export const steps = [
  SignIn,
  ConfirmAccount,
  SuccessfulAccountCreation,
];

export const skipSteps = [
    ConfirmAccount,
    SuccessfulAccountCreation,
  ];

function SigninSteps() {
  const { step } = useStepsState();
  const { isVerifyReq } = useSharedState();

  const CurrentStep = isVerifyReq ? skipSteps[step] : steps[step];

  return (
    <Wrapper backgroundColor="#FFFFFF">
      {step !== 0 && (
        <Header isHelp />
      )}
      <div>
        <CurrentStep />
      </div>
      {step !== 0 && step !== 1 && <FooterRegister />}
      {/* <FooterRegister /> */}
    </Wrapper>
  );
}

export default SigninSteps;
