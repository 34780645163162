import React, { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';
import AppointmentSteps from './AppointmentSteps';
import { ScheduleProvider, useStepsDispatch, useStepsState } from '../../schedule/provider';
import { useSharedDispatch, useSharedState } from '../../shared/provider';
import { useHistory, useParams } from 'react-router-dom';
import SigninProvider from './SigninProvider';
import { config } from '../../../config';
interface ParamTypes {locationId: string;}
function BookAppointment() {
  let history = useHistory();
  const { form , rebooking} = useStepsState();
  const { testSelection, tenant,cPartnerID, location } = form;
  const { setWalkUp } = useSharedDispatch();
  const { userData, apptFromSchdule, locations, isWalkUp } = useSharedState();
  const { goToNextStep, updateFormValues } = useStepsDispatch();
  const { locationId } = useParams<ParamTypes>();
  useEffect(() => {
    mixpanel.track('Page View');
    if(isWalkUp?.active && form?.location?.qbenchCustomerId=== config.qbenchCustomerId.KernHealthSystems.toString()){  
        console.log("reached1")  
        updateFormValues({cPartnerID: 'KEN001'})
        goToNextStep();        
        // localStorage.setItem('tenant','kern')
        console.log("reached")
      }
   else  if (locationId) {
        let cPartnerIdArray: string[] | undefined;
        if (locations !== null && locations !== undefined) {
            cPartnerIdArray = locations
                ?.filter(
                    (place) =>
                        place.qbenchCustomerId === parseInt(locationId)
                )
                .map((data) => data.cPartnerID);
        }

        setWalkUp({
            locationId: parseInt(locationId),
            cPartnerIdArray: cPartnerIdArray,
            active: true,
        });
    }
  }, [locationId, locations, setWalkUp]);

  // check its coming from schduler or not
  if (!userData && !apptFromSchdule && rebooking.sampleId ===0) {
    localStorage.clear();
    if (!locationId) history.push('/signin');
  }
  if (!userData && !apptFromSchdule && (rebooking.sampleId ===0 && !rebooking.isRebook)) return <SigninProvider />;
  else
      return (
          <ScheduleProvider>
              <AppointmentSteps />
          </ScheduleProvider>
      );
}

export default BookAppointment;