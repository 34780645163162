import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Popconfirm } from 'antd';

import { sendConfirmationEmail } from '../../../api';

const Text = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  white-space: nowrap;
  display: block;
  width: 100%;
  min-width: 1px;
`;

interface Props {
  email: string;
  confirmationId: string;
}

enum SendState {
  Idle,
  InProgress,
  Success,
  Error,
}

const stateToLabelMap = {
  [SendState.InProgress]: 'Sending ...',
  [SendState.Error]: 'Error',
  [SendState.Success]: 'Success',
  [SendState.Idle]: 'Resend Confirmation',
};

const SendConfirmation: React.FC<Props> = ({ email, confirmationId }) => {
  const [state, setState] = useState<SendState>(SendState.Idle);

  const handleConfirm = async () => {
    setState(SendState.InProgress);
    let params: any ={
      emailIds:[email], 
      confirmationId
    }
    try {
      setState(SendState.InProgress);

      await sendConfirmationEmail(params);
      setState(SendState.Success);
    } catch (e) {
      setState(SendState.Error);
      console.error(e);
    }
  }

  return (
    <>
      {state === SendState.Idle ? (
        <Popconfirm
          title={'Do you really want to resend a confirmation'}
          onConfirm={handleConfirm}
          okText="Resend"
          cancelText="No"
        >
          <Button
            type="link"
            block
            disabled={state !== SendState.Idle}
          >
            {stateToLabelMap[state]}
          </Button>
        </Popconfirm>
      ) : (
        <Text>{stateToLabelMap[state]}</Text>
      )}
    </>
  );
};

export default SendConfirmation;
