import React, { useEffect, useMemo } from 'react';
import { Field, FieldProps } from 'formik';
import styled from 'styled-components';
import { ErrorText } from '../styled';
import { colors } from '../../../../styles/colors';
import { useStepsDispatch, useStepsState } from '../../../schedule/provider';
import { Location } from '../../models';
import { Breakpoints } from '../../../../dictionaries';
import { useSharedDispatch, useSharedState } from '../../provider';
import AlertModal from '../AlertModal';
import { cloneDeep, filter } from 'lodash';
import { useManageDispatch } from '../../../manage/provider';
import { includes } from 'lodash';
import { config } from '../../../../config';
import { useRegisterStepsDispatch } from '../../../register/provider';
import { filterRebookLocations } from './reusableFunction';
interface ButtonProps {
  selected: boolean;
}

const LocationButton = styled.button`
  display: flex;
  min-height: 113px;
  position: relative;
  width: 100%;
  justify-content: space-between;
  align-items: start;
  border: 1px solid #b4b4bb99;
  background: ${({ selected }: ButtonProps) =>
    selected ? '#243D4D' : '#FFFFFF'};
  color: ${({ selected }: ButtonProps) =>
    selected ? colors.white : colors.black};
  transition: all 0.2s ease-in-out;
  padding: 18px 20px;
  cursor: pointer;
  margin-bottom: 4px;
  &:hover {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.45),
        rgba(255, 255, 255, 0.45)
      ),
      #fac21a;
    color: ${colors.black};
  }
  @media (min-width: ${Breakpoints.sm}px) {
    padding: 24px 33px;
  }
  @media (max-width: ${Breakpoints.sm}px) {
    &:hover {
      background: ${({ selected }: ButtonProps) =>
    selected ? '#243D4D' : 'none'};
        color: ${({ selected }: ButtonProps) =>
    selected ? colors.white : 'none'};  
    }
  }
`;

const Info = styled.div`
  text-align: left;
`;

const Name = styled.div`
  font-weight: bold;
  margin-bottom: 2px;
  @media (max-width: ${Breakpoints.sm}px) and (min-width: 480px) {
    word-wrap: break-word;
    width: 150px;
  }
  @media (max-width: 480px) {
    word-break: break-word;
    word-wrap: break-word;
    width: 140px;
  }
`;
const Address2 = styled.div`
  @media (min-width: ${Breakpoints.lsm}px) {
    position: relative;
    top: 13px;
  }
`;
const Address3 = styled.div`
  @media (max-width: ${Breakpoints.sm}px) and (min-width: 480px) {
    word-wrap: break-word;
  }
  @media (max-width: 480px) {
    word-break: break-word;
    word-wrap: break-word;
    width: 120px;
  }
`;
const Span1 = styled.div`
  position: absolute;
  width: 72%;
  @media (min-width: ${Breakpoints.md}px) {
    width: 50%;
  }
  @media (max-width: ${Breakpoints.sm}px) {
    width: 95%;
    word-break: break-all;
  }
  @media (min-width: 480px) {
    word-break: break-all;
    width: 72%;
  }
`;

const LocationsContainer = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 35px;
`;

const Error = styled.div`
  position: absolute;
  bottom: -25px;
  left: 0;
`;

const Tag = styled.div`
  border: 1px solid #ffffff;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 5%);
  position: absolute;
  width: 217px;
  height: 28px;
  overflow: hidden;
  left: -2px;
  top: 33px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  line-height: 30px;
  color: ${({ selected }: ButtonProps) =>
    selected ? colors.black : colors.white};
  -webkit-transform: rotate(36.31deg);
  -ms-transform: rotate(36.31deg);
  -webkit-transform: rotate(36.31deg);
  background-color: ${({ selected }: ButtonProps) =>
    selected ? colors.white : colors.rebrandBlack};
`;

const TagBox = styled.div`
  position: absolute;
  width: 164px;
  height: 113px;
  right: 0px;
  top: 1px;
  overflow: hidden;
`;
const RapidTag = styled.div`
  border: 1px solid #ffffff;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 5%);
  position: absolute;
  width: 222px;
  height: 28px;
  overflow: hidden;
  left: -13px;
  top: 42px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  line-height: 30px;
  color: ${({ selected }: ButtonProps) =>
    selected ? colors.black : colors.white};
  -webkit-transform: rotate(36.31deg);
  -ms-transform: rotate(36.31deg);
  -webkit-transform: rotate(36.31deg);
  background-color: ${({ selected }: ButtonProps) =>
    selected ? colors.white : colors.rebrandBlack};
`;

const AntigenTag = styled.div`
  border: 1px solid #FFFFFF;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 5%);
  position: absolute;
  width: 277px;
  height: 28px;
  overflow: hidden;
  left: -33px;
  top: 48px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  line-height: 30px;
  color: ${({ selected }: ButtonProps) =>
    selected ? colors.black : colors.white};
  -webkit-transform: rotate(36.31deg);
  -ms-transform: rotate(36.31deg);
  -webkit-transform: rotate(36.31deg);
  background-color: ${({ selected }: ButtonProps) =>
    selected ? colors.white : colors.rebrandBlack};
`;

const AntigenTagBox = styled.div`
  position: absolute;
  width: 246px;
  height: 113px;
  right: 0px;
  top: 1px;
  overflow: hidden;
`;

const RapidTagBox = styled.div`
  position: absolute;
  width: 195px;
  height: 113px;
  right: 0px;
  top: 1px;
  overflow: hidden;
`;
const PricingVariesTagBox = styled.div`
  position: absolute;
  width: 195px;
  height: 113px;
  right: 0px;
  top: 1px;
  overflow: hidden;
  `;
const PricingVariesTag = styled.div`
  border: 1px solid #ffffff;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 5%);
  position: absolute;
  width: 217px;
  height: 28px;
  overflow: hidden;
  left: 25px;
  top: 33px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 30px;
  color: ${({ selected }: ButtonProps) =>
    selected ? colors.black : colors.white};
  -webkit-transform: rotate(36.31deg);
  -ms-transform: rotate(36.31deg);
  -webkit-transform: rotate(36.31deg);
  background-color: ${({ selected }: ButtonProps) =>
    selected ? colors.white : colors.rebrandBlack};
  `;
interface props {
  confirmAndPay?: boolean;
  isManage?: boolean;
  isEdit?: boolean;
  appoitmentLocation?: any;
  rescheduleAppointment?: any;
  payment?: string;
  test?: string;
  isEmpEmail?: boolean;

}

const LocationButtonGroup: React.FC<props> = ({
  confirmAndPay,
  isManage,
  isEdit,
  appoitmentLocation,
  rescheduleAppointment,
  test,
  payment,
  isEmpEmail
}) => {
  const { updateFormValues, toggleShowAlertModal, goToStep } = useStepsDispatch();
  const { upateTestType, updatePartnerLogo,closedLocation } = useSharedDispatch();
  const { updateRescheduleAppoitment } = useRegisterStepsDispatch();
  const { locations, userData, apptFromSchdule, travellType, destination, partnerLogo, isWalkUp, tenant } =
    useSharedState();

  const {
    // form: {
    // 	isExpressSameDayTest,
    // 	slot,
    // 	location,
    // 	isRapidTest,
    // 	cPartnerID,
    // },x
    form,
    showChangeLocationModal,
    step,
    rebooking
  } = useStepsState();

  const closeModal = () => toggleShowAlertModal(false);

  //Assign the initial value for all the locations
  const appointmentdata: any =
    rescheduleAppointment !== undefined && rescheduleAppointment !== null
      ? rescheduleAppointment
      : null;
  let appointment: any =
    rescheduleAppointment !== undefined ? rescheduleAppointment : {};
  let cPartnerId: any = form.cPartnerID;
  let location: any = form.location;
  let slot: any = form.slot;
  let isExpressSameDayTest: any = form.isExpressSameDayTest;
  let isRapidTest: any = form.isRapidTest;
  let isAntigen: any = form.isAntigen;
  let isPanel: boolean = form?.isPanel ?? false;
  let isWalkUpTest: any = form.isWalkUpTest;
  let IstraveltypeINT: boolean = false;
  let IstraveltypeDOM: boolean = false;
  let LocationtravelType: string = travellType;
  let apptTravelType: string = appointmentdata?.travelType
    ? appointmentdata?.travelType
    : '';
  const { updateStandradTest } = useManageDispatch();

  if (isManage) {
    cPartnerId = appointmentdata?.cPartnerID;
    apptTravelType = appointmentdata?.travelType;
    location = appointmentdata?.location;
    slot = appointmentdata?.slot;
    isExpressSameDayTest = appointmentdata?.isExpressSameDayTest;
    isRapidTest = appointmentdata?.isRapidTest;
  }
  
  const paymentMethod = () => {
    if (payment === 'Credit Card' || payment === 'Credit card') {
      if (!location?.standardTest || !location?.isCreditCard.standard) {
        if (
          (!location?.standardTest || !location?.isCreditCard.standard) &&
          location?.isExpressSameDay &&
          location?.isCreditCard.express
        ) {
          updateFormValues({ isExpressSameDayTest: true });
        } else if (
          (!location?.standardTest || !location?.isCreditCard.standard) &&
          (!location?.isExpressSameDay || !location?.isCreditCard.express) &&
          location?.rapidTest &&
          location?.isCreditCard.rapid
        ) {
          updateFormValues({ isRapidTest: true });
        } else if (
          (!location?.standardTest || !location?.isCreditCard.standard) &&
          (!location?.isExpressSameDay || !location?.isCreditCard.express) &&
          (!location?.rapidTest || !location?.isCreditCard.rapid) &&
          location?.isAntigen &&
          location?.isCreditCard.antigen
        ) {
          updateFormValues({ isAntigen: true });
        }
      } else if (location?.standardTest && location?.isCreditCard.standard) {
        updateFormValues({
          isExpressSameDayTest: false,
          isRapidTest: false,
          isAntigen: false,
        });
      }
    } else if (payment === 'Insurance') {
      if (!location?.standardTest || !location?.isInsurance.standard) {
        if (
          (!location?.standardTest || !location?.isInsurance.standard) &&
          location?.isExpressSameDay &&
          location?.isInsurance.express
        ) {
          updateFormValues({ isExpressSameDayTest: true });
        } else if (
          (!location?.standardTest || !location?.isInsurance.standard) &&
          (!location?.isExpressSameDay || !location?.isInsurance.express) &&
          location?.rapidTest &&
          location?.isInsurance.rapid
        ) {
          updateFormValues({ isRapidTest: true });
        } else if (
          (!location?.standardTest || !location?.isInsurance.standard) &&
          (!location?.isExpressSameDay || !location?.isInsurance.express) &&
          (!location?.rapidTest || !location?.isInsurance.rapid) &&
          location?.isAntigen &&
          location?.isInsurance.antigen
        ) {
          updateFormValues({ isAntigen: true });
        }
      } else if (location?.standardTest && location?.isInsurance.standard) {
        updateFormValues({
          isExpressSameDayTest: false,
          isRapidTest: false,
          isAntigen: false,
        });
      }
    } else if (payment === 'No Insurance') {
      if (!location?.standardTest || !location?.isNoInsurance.standard) {
        if (
          (!location?.standardTest || !location?.isNoInsurance.standard) &&
          location?.isExpressSameDay &&
          location?.isNoInsurance.express
        ) {
          updateFormValues({ isExpressSameDayTest: true });
        } else if (
          (!location?.standardTest || !location?.isNoInsurance.standard) &&
          (!location?.isExpressSameDay || !location?.isNoInsurance.express) &&
          location?.rapidTest &&
          location?.isNoInsurance.rapid
        ) {
          updateFormValues({ isRapidTest: true });
        } else if (
          (!location?.standardTest || !location?.isNoInsurance.standard) &&
          (!location?.isExpressSameDay || !location?.isNoInsurance.express) &&
          (!location?.rapidTest || !location?.isNoInsurance.rapid) &&
          location?.isAntigen &&
          location?.isNoInsurance.antigen
        ) {
          updateFormValues({ isAntigen: true });
        }
      }
    } else if (location?.standardTest && location?.isNoInsurance.standard) {
      updateFormValues({
        isExpressSameDayTest: false,
        isRapidTest: false,
        isAntigen: false,
      });
    }
  };
  const rollbackLocation = useMemo(
    () => cloneDeep(location),
    [showChangeLocationModal]
  );
  const rollbackSlot = useMemo(
    () => cloneDeep(slot),
    [showChangeLocationModal]
  );
  const rollbackisExpressSameDayTest = useMemo(
    () => cloneDeep(isExpressSameDayTest),
    [showChangeLocationModal]
  );

  if (isEdit) {
    if (appoitmentLocation?.IsAirline) {
      IstraveltypeINT = true;
    } else {
      IstraveltypeDOM = true;
    }
  } else {
    if (
      LocationtravelType !== null &&
      LocationtravelType !== undefined &&
      LocationtravelType !== ''
    ) {
      IstraveltypeINT = LocationtravelType == 'INT' ? true : false;
      IstraveltypeDOM = LocationtravelType == 'DOM' ? true : false;
    } else {
      IstraveltypeINT = apptTravelType == 'INT' ? true : false;
      IstraveltypeDOM = apptTravelType == 'DOM' ? true : false;
    }

    if (apptFromSchdule) {
      IstraveltypeINT = travellType == 'INT' ? true : false;
      IstraveltypeDOM = travellType == 'DOM' ? true : false;
    }
  }
  var newTenant = localStorage.getItem('tenant')

// 
const rebookingLocations = () => {
    console.log("check1");
    let testType = form?.isAntigen
      ? 'antigen'
      : form?.isExpressSameDayTest
      ? 'express'
      : form?.isRapidTest
      ? 'rapid'
      : 'standard';
    console.log("check2", testType);
    const filterloc = (loc: any) => {
      if (form?.previousPayment?.toLowerCase() === 'credit card') {
        return loc?.isCreditCard?.[testType] === true;
      } else if (form?.previousPayment?.toLowerCase() === 'insurance') {
        console.log("check3");
        return loc?.isInsurance?.[testType] === true;
      } else {
        console.log("check4");
        return loc?.isNoInsurance?.[testType] === true;
      }
    };
    let filteredrebookLocations: any = [];
    locations?.map((loc) => {
      if (loc?.cPartnerID === form?.location?.cPartnerID && filterloc(loc)) {
        filteredrebookLocations.push(loc);
      }
    });
    console.log("filteredrebookLocations", filteredrebookLocations);
    return filteredrebookLocations;
  };
  
  var filteredlocation = isEdit
    ? isManage
    ? filter(rebooking.active ? rebookingLocations() : locations, function (data: any) {
        console.log(' Resch Location Appoinemt');
        if(data?.partnerName === 'goHealth' && form?.testSelection === 'flight' || form?.testSelection === 'general'){
          return true
        }
        if (data.schools && data.schools?.length) {
          return (
            data.schools.includes(rescheduleAppointment?.cPartnerID) &&
            (isWalkUpTest
              ? data.qbenchCustomerId.toString() ===
                location?.qbenchCustomerId.toString()
              : true)
          );
        } else {
          return data.cPartnerID === rescheduleAppointment?.cPartnerID &&
            data.testSelection === 'general'
            ? !!data.isGeneral
            : data.testSelection === 'flight'
            ? !!data.isTravel
            : data.testSelection === 'community'
            ? !!data.isCommunity
            : data.testSelection === 'sfoairport'
            ? !!data.isWork
            : false &&
              (isWalkUpTest
                ? data.qbenchCustomerId.toString() ===
                  location?.qbenchCustomerId.toString()
                : true);
        }
      })
    : filter(rebooking?.active ? rebookingLocations() : locations, function (data: any) {
        console.log('not appointment Location');
        if(data?.partnerName === 'goHealth' && form?.testSelection === 'general' || form?.testSelection === 'flight'){
          return true
        }
        if (data.schools && data.schools?.length) {
          return (
            data.schools.includes(appoitmentLocation?.cPartnerID) &&
            (isWalkUp.active
              ? data.qbenchCustomerId.toString() ===
                isWalkUp.locationId.toString()
              : true)
          );
        } else {
          return data.cPartnerID === appoitmentLocation?.cPartnerID &&
            data.testSelection === 'general'
            ? !!data.isGeneral
            : data.testSelection === 'flight'
            ? !!data.isTravel
            : data.testSelection === 'community'
            ? !!data.isCommunity
            : data.testSelection === 'sfoairport'
            ? !!data.isWork
            : false &&
              (isWalkUp.active
                ? data.qbenchCustomerId.toString() ===
                  isWalkUp.locationId.toString()
                : true);
        }
      })
      : filter(rebooking.active ? rebookingLocations() : locations, function (data: any) {
        console.log("datacheck",data)
      if(data?.partnerName === 'goHealth'){
        if(form?.testSelection === 'flight' || form?.testSelection === 'general'){
          return true
        }else{
          return false
        }
      }
        if (newTenant === 'kern') {
          return data.cPartnerID === 'KEN001' ? true : false;
        }
        if(rebooking.active && (payment)?.toLowerCase() ===('No Payment')?.toLowerCase()){
          return true
        }
        if (!rebooking.active && (payment)?.toLowerCase()  === ('No Payment')?.toLowerCase()) {
          return true;
        }

        if (
          isWalkUp &&
          isWalkUp.locationId === config.qbenchCustomerId.KernHealthSystems
        ) {
          return isWalkUp.active
            ? data.qbenchCustomerId.toString() ===
                isWalkUp.locationId.toString()
            : true;
        }
        if (!payment) {
            if (data.schools && data.schools?.length) {
                return (
                    data.schools.includes(cPartnerId) &&
                    (isWalkUp.active
                        ? data.qbenchCustomerId.toString() ===
                        isWalkUp.locationId.toString()
                        : true)
                );
            } else {
                return (
                    data.cPartnerID === cPartnerId ||
                    (data.locationType === 'Partner' &&
                        (!isManage
                            ? form.testSelection === 'general'
                                ? !!data.isGeneral
                                : form.testSelection === 'flight'
                                    ? !!data.isTravel
                                    : form.testSelection === 'community'
                                        ? !!data.isCommunity
                                        : form.testSelection === 'sfoairport'
                                            ? !!data.isWork
                                            : false
                            : appoitmentLocation.testSelection === 'general'
                                ? !!data.isGeneral
                                : appoitmentLocation.testSelection === 'flight'
                                    ? !!data.isTravel
                                    : appoitmentLocation.testSelection === 'community'
                                        ? !!data.isCommunity
                                        : appoitmentLocation.testSelection === 'sfoairport'
                                            ? !!data.isWork
                                            : false) &&
                        (isWalkUp.active
                            ? data.qbenchCustomerId.toString() ===
                            isWalkUp.locationId.toString()
                            : true))
                );
            }
        } else {
            if (data.schools && data.schools?.length) {
                return (
                    data.schools.includes(cPartnerId) &&
                    (payment === 'No Insurance'
                        ? data.isNoInsurance?.standard === true ||
                        data.isNoInsurance?.express === true ||
                        data.isNoInsurance?.rapid === true ||
                        data.isNoInsurance?.antigen === true
                        : payment === 'Insurance'
                            ? data.isInsurance?.standard === true ||
                            data.isInsurance?.express === true ||
                            data.isInsurance?.rapid === true ||
                            data.isInsurance?.antigen === true
                            : payment === 'Credit Card' || payment === 'Credit card'
                                ? data.isCreditCard?.standard === true ||
                                data.isCreditCard?.express === true ||
                                data.isCreditCard?.rapid === true ||
                                data.isCreditCard?.antigen === true
                                : false) &&
                    (isManage
                        ? test === 'standard'
                            ? (payment === 'Insurance' &&
                                data.isInsurance?.standard === true) ||
                            ((payment === 'Credit Card' || payment === 'Credit card') &&
                                data.isCreditCard?.standard === true) ||
                            (payment === 'No Insurance' &&
                                data.isNoInsurance?.standard === true)
                            : test === 'express'
                                ? (payment === 'No Insurance' &&
                                    data.isNoInsurance?.express === true) ||
                                (payment === 'Insurance' &&
                                    data.isInsurance?.express === true) ||
                                ((payment === 'Credit Card' || payment === 'Credit card') &&
                                    data.isCreditCard?.express === true)
                                : test === 'rapid'
                                    ? (payment === 'No Insurance' &&
                                        data.isNoInsurance?.rapid === true) ||
                                    (payment === 'Insurance' &&
                                        data.isInsurance?.rapid === true) ||
                                    ((payment === 'Credit Card' || payment === 'Credit card') &&
                                        data.isCreditCard?.rapid === true)
                                    : test === 'antigen'
                                        ? (payment === 'No Insurance' &&
                                            data.isNoInsurance?.antigen === true) ||
                                        (payment === 'Insurance' &&
                                            data.isInsurance?.antigen === true) ||
                                        ((payment === 'Credit Card' || payment === 'Credit card') &&
                                            data.isCreditCard?.antigen === true)
                                        : false
                        : true) &&
                    (isWalkUp.active
                        ? data.qbenchCustomerId.toString() ===
                        isWalkUp.locationId.toString()
                        : true)
                );
            } else {
                console.log("2222222,", data, form.testSelection, !isManage, test, payment)
                
                return (
                    data.cPartnerID === cPartnerId &&
              (payment === 'No Insurance'
                ? data.isNoInsurance?.standard === true ||
                  data.isNoInsurance?.express === true ||
                  data.isNoInsurance?.rapid === true ||
                  data.isNoInsurance?.antigen === true
                : payment === 'Insurance'
                ? data.isInsurance?.standard === true ||
                  data.isInsurance?.express === true ||
                  data.isInsurance?.rapid === true ||
                  data.isInsurance?.antigen === true
                : payment === 'Credit Card' || payment === 'Credit card'
                ? data.isCreditCard?.standard === true ||
                  data.isCreditCard?.express === true ||
                  data.isCreditCard?.rapid === true ||
                  data.isCreditCard?.antigen === true
                : false) &&
            //   (!isManage
            //     ? form.testSelection === 'general'
            //       ? !!data.isGeneral
            //       : form.testSelection === 'flight'
            //       ? !!data.isTravel
            //       : form.testSelection === 'community'
            //       ? !!data.isCommunity
            //       : form.testSelection === 'sfoairport'
            //       ? !!data.isWork
            //       : false
            //     : appoitmentLocation.testSelection === 'general'
            //     ? !!data.isGeneral
            //     : appoitmentLocation.testSelection === 'flight'
            //     ? !!data.isTravel
            //     : appoitmentLocation.testSelection === 'community'
            //     ? !!data.isCommunity
            //     : appoitmentLocation.testSelection === 'sfoairport'
            //     ? !!data.isWork
            //     : false) &&
              (isManage
                ? test === 'standard'
                  ? (payment === 'Insurance' &&
                      data.isInsurance?.standard === true) ||
                    ((payment === 'Credit Card' || payment === 'Credit card') &&
                      data.isCreditCard?.standard === true) ||
                    (payment === 'No Insurance' &&
                      data.isNoInsurance?.standard === true) ||
                    payment?.toLowerCase() === ('No Payment').toLowerCase()
                  : test === 'express'
                  ? (payment === 'No Insurance' &&
                      data.isNoInsurance?.express === true) ||
                    (payment === 'Insurance' &&
                      data.isInsurance?.express === true) ||
                    ((payment === 'Credit Card' || payment === 'Credit card') &&
                      data.isCreditCard?.express === true) ||
                    payment?.toLowerCase() === ('No Payment').toLowerCase()
                  : test === 'rapid'
                  ? (payment === 'No Insurance' &&
                      data.isNoInsurance?.rapid === true) ||
                    (payment === 'Insurance' &&
                      data.isInsurance?.rapid === true) ||
                    ((payment === 'Credit Card' || payment === 'Credit card') &&
                      data.isCreditCard?.rapid === true) ||
                    payment?.toLowerCase() === ('No Payment').toLowerCase()
                  : test === 'antigen'
                  ? (payment === 'No Insurance' &&
                      data.isNoInsurance?.antigen === true) ||
                    (payment === 'Insurance' &&
                      data.isInsurance?.antigen === true) ||
                    ((payment === 'Credit Card' || payment === 'Credit card') &&
                      data.isCreditCard?.antigen === true) ||
                    payment?.toLowerCase() === ('No Payment').toLowerCase()
                  : false
                : true) &&
              (isWalkUp.active
                ? data.qbenchCustomerId.toString() ===
                  isWalkUp.locationId.toString()
                : true)
                &&  (isEmpEmail && !data?.standardTest && !data?.isExpressSameDayTest
                    ? false
                    : true)
                  );

            }
        }
    });
    console.log("locatin", locations, filteredlocation)

//   var filteredlocation = isEdit
//     ? isManage
//       ? filter(rebooking?.active ? filterRebookLocations(form,locations) : locations, function (data: any) {
//         console.log(' Resch Location Appoinemt');
//         return data.cPartnerID === rescheduleAppointment?.cPartnerID && (isWalkUpTest ? data.qbenchCustomerId.toString() === location?.qbenchCustomerId.toString() : true);
//       })
//       : filter(rebooking?.active ? filterRebookLocations(form,locations) : locations, function (data: any) {
//         console.log('not appointment Location');
//         return data.cPartnerID === appoitmentLocation?.cPartnerID && (isWalkUp.active ? data.qbenchCustomerId.toString() === isWalkUp.locationId.toString() : true);
//       })
//     : filter(rebooking?.active ?  rebookingLocations() : locations, function (data: any) {
//         console.log("reached0909090")
//       if (data?.partnerName === 'goHealth') {
//         console.log("reach080808")
//         if (form?.testSelection === 'flight' || form?.testSelection === 'general') {
//             console.log("reach0707070707")
//           return true
//         } else {
//           return false
//         }
//       }
//       if (newTenant === 'kern' || tenant?.toLowerCase() === 'kern') {
//         return data.cPartnerID === 'KEN001' ? true : false;
//       }
//       if (rebooking.active && (payment)?.toLowerCase() === ('No Payment')?.toLowerCase()) {
//         return true
//       }
//       if (!rebooking.active && (payment)?.toLowerCase() === ('No Payment')?.toLowerCase()) {
//         return true;
//       }

//       if (isWalkUp && isWalkUp?.locationId === config.qbenchCustomerId?.KernHealthSystems) {
//         return isWalkUp?.active ? data?.qbenchCustomerId.toString() === isWalkUp.locationId.toString() : true
//       };
      
//       console.log("reachend",data)
//       console.log("form",form)
//         return data.cPartnerID === cPartnerId &&
//      
//         (isWalkUp.active ? data.qbenchCustomerId.toString() === isWalkUp.locationId.toString() : true);
//     });
  
  useEffect(() => {
    //console.log('standardTest', standardTest);
    upateTestType(isRapidTest ? false : isExpressSameDayTest ? false : isAntigen ? false : isPanel ? false : true);
  }, []);
  useEffect(() => {
    if (!location)
      updateFormValues({ paymentMethod: "" })
  }, [])
//   useEffect(() => {
//     const data = document.getElementsByClassName('sc-cfQIsQ dFbALq')
//     const data1 = document.getElementsByClassName('sc-elvGZv dzKzEM')
//     console.log("data", data, data1)
//   }, [])
  const TestTags: React.FC<any> = ({ field, loc, num, name }) => {
    const data: any = {
      express: 'EXPRESS',
      rapid: 'RAPID',
      antigen: 'ANTIGEN',
      pricingVaries: 'Pricing Varies',
    };
    return (
      <>
        {num === 1 && (
          <TagBox>
            <Tag selected={field.value?.name === loc.name}>
              {data[name]} TESTING{' '}
              {loc.qbenchCustomerId === config.qbenchCustomerId.PostIsolationRapidAntigenTest ? 'ONLY' : 'AVAILABLE'}
            </Tag>
          </TagBox>
        )}
        {num === 2 && (
          <RapidTagBox>
            <RapidTag selected={field.value?.name === loc.name}>
              {data[name]} TESTING{' '}
              {loc.qbenchCustomerId === config.qbenchCustomerId.PostIsolationRapidAntigenTest ? 'ONLY' : 'AVAILABLE'}
            </RapidTag>
          </RapidTagBox>
        )}
        {num === 3 && (
          <AntigenTagBox>
            <AntigenTag selected={field.value?.name === loc.name}>
              {data[name]} TESTING{' '}
              {loc.qbenchCustomerId === config.qbenchCustomerId.PostIsolationRapidAntigenTest ? 'ONLY' : 'AVAILABLE'}
            </AntigenTag>
          </AntigenTagBox>
        )}
        {num === 4 && (
          <PricingVariesTagBox>
            <PricingVariesTag selected={field.value?.name === loc?.name}>
              {data[name]}  {' '}
            </PricingVariesTag>
          </PricingVariesTagBox>
        )}
      </>
    );
  };
  return (
    <>
      <Field
        name="location"
        validate={(value: Location | null) => {
          let error;

          if (!value) {
            error = 'Please choose location';
          }

          return error;
        }}
      >
        {({ form: { setFieldValue }, field, meta: { error } }: FieldProps) => (
          <LocationsContainer>
            {console.log("filteredlocation",filteredlocation.length)}
            {filteredlocation &&
              filteredlocation.map((loc: any, index: any) => {
                console.log("reached heree")
                if (rebooking.active) {
                    console.log("reached1234")
                  return (
                    <LocationButton
                      //  id={loc.qbenchCustomerId + '' + }
                      key={loc?.name}
                      type="submit"
                      selected={
                        field.value?.name === loc?.name
                      }
                      onClick={async () => {
                        if (step !== 2 && loc?.partnerName === 'goHealth') {
                          goToStep(3)
                          updateFormValues({ minors: [], isAntigen: false, registeringFor: form?.registeringFor === 'minorOnly' ? '' : 'myself' })
                        }
                        setFieldValue('location', loc);
                        localStorage.setItem(
                          'location',
                          JSON.stringify(loc)
                        );

                        updateFormValues({
                          location: loc,
                          slot: undefined,
                          reservationId: null,
                        });

                        if (isWalkUp.active) {
                          updateFormValues({
                            cPartnerID: loc?.cPartnerID,
                          });
                        }

                        if (loc?.hasVipSlots == false) {
                          upateTestType(true);
                          updateFormValues({
                            isExpressSameDayTest: false,
                          });
                        }

                        updateRescheduleAppoitment({
                          ...appointment,
                          location: loc,
                          slot: undefined,
                          reservationId: null,
                        });

                        if (
                          loc?.hasVipSlots == false &&
                          loc?.rapidTest == false
                        ) {
                          updateRescheduleAppoitment({
                            ...appointment,
                            location: loc,
                            slot: undefined,
                            isExpressSameDayTest: false,
                            isRapidTest: false,
                          });
                        } else if (
                          loc?.hasVipSlots == true &&
                          loc?.rapidTest == false
                        ) {
                          upateTestType(false);
                          updateRescheduleAppoitment({
                            ...appointment,
                            location: loc,
                            slot: undefined,
                            isExpressSameDayTest: true,
                            isRapidTest: false,
                          });

                          updateFormValues({
                            location: loc,
                            slot: undefined,
                            isRapidTest: false,
                            isExpressSameDayTest: false,
                          });
                        }

                        if (payment) {
                            paymentMethod()
                        }
                        if (
                          loc?.qbenchCustomerId ===
                          config?.qbenchCustomerId
                            .BoysGirlsClub
                        ) {
                          updatePartnerLogo({
                            ...partnerLogo,
                            locationImage: loc?.image,
                          });
                        } else {
                          updatePartnerLogo({
                            ...partnerLogo,
                            locationImage: [],
                          });
                        }

                        closedLocation(false)
                      }}
                    >
                      <Info>
                        <Name>{loc?.name}</Name>
                        <Address3>
                          {' '}
                          {loc?.address1?.length > 60 ? (
                            <>
                              <Span1>{loc?.address1}</Span1>
                              <br />
                            </>
                          ) : (
                            loc?.address1
                          )}
                        </Address3>
                        <br />
                        <Address2>{loc?.address2}</Address2>
                        {loc?.isOnlyPaymentText && (
                          <>
                            <br />
                            <span style={{ color: 'red' }}>
                              {loc?.isOnlyPaymentText}
                            </span>
                          </>
                        )}
                        <br />
                        {
                          loc?.partnerName === 'goHealth' &&
                          <span>{`${loc?.goHealthPricing?.sentence1}`} <u><a href={`${loc?.goHealthPricing?.url}`} target="_blank">{`${loc?.goHealthPricing?.shortUrl}`}</a></u> {`${loc?.goHealthPricing?.sentence2}`}</span>
                        }
                      </Info>
                      {loc?.partnerName === 'goHealth' &&
                        <TestTags field={field} loc={loc} num={4} name={'pricingVaries'} />
                      }
                      {loc?.hasVipSlots ? (
                        <>
                          <TestTags
                            field={field}
                            loc={loc}
                            num={1}
                            name={'express'}
                          />
                          {loc?.rapidTest ? (
                            <>
                              <TestTags
                                field={field}
                                loc={loc}
                                num={2}
                                name={'rapid'}
                              />
                              {loc?.isAntigen && (
                                <TestTags
                                  field={field}
                                  loc={loc}
                                  num={3}
                                  name={'antigen'}
                                />
                              )}
                            </>
                          ) : (
                            loc?.isAntigen && (
                              <TestTags
                                field={field}
                                loc={loc}
                                num={2}
                                name={'antigen'}
                              />
                            )
                          )}
                        </>
                      ) : loc?.rapidTest ? (
                        <>
                          <TestTags
                            field={field}
                            loc={loc}
                            num={1}
                            name={'rapid'}
                          />
                          {loc?.isAntigen && (
                            <TestTags
                              field={field}
                              loc={loc}
                              num={2}
                              name={'antigen'}
                            />
                          )}
                        </>
                      ) : (
                        loc?.isAntigen && (
                          <TestTags
                            field={field}
                            loc={loc}
                            num={1}
                            name={'antigen'}
                          />
                        )
                      )}
                    </LocationButton>
                  )

                }
                if (form.cPartnerID === "PCMA001" && filteredlocation.length > 0 && !form.location) {
                  updateFormValues({ location: filteredlocation[0], isWalkUpTest: isWalkUp.active })
                  setFieldValue('location', filteredlocation[0]);
                  localStorage.setItem('location', JSON.stringify(filteredlocation[0]));
                  goToStep(1)
                }
                // if(test === 'goHealth'){
                //   if(loc?.partnerName === 'goHealth'){
                //     //Pause the booking for few location for the few destinations
                //     //   let dest: any = destination;
                //     let allowLocation: boolean = true;

                //     if (true) {
                //       allowLocation =
                //         // includes(config.pauseBooking.destinations, dest) &&
                //         includes(
                //           config.pauseBooking.locations,
                //           loc.qbenchCustomerId
                //         )
                //           ? false
                //           : true;
                //     }

                //     if (!allowLocation) {
                //       return <div />;
                //     }

                //     return (
                //       <LocationButton
                //         key={loc.name}
                //         type="submit"
                //         selected={field.value?.name === loc.name}
                //         onClick={async () => {
                //           setFieldValue('location', loc);
                //           localStorage.setItem('location', JSON.stringify(loc));

                //           updateFormValues({
                //             location: loc,
                //             slot: undefined,
                //             reservationId: null,
                //           });


                //           if (loc?.hasVipSlots == false) {
                //             upateTestType(true);
                //             updateFormValues({ isExpressSameDayTest: false });
                //           }

                //           updateRescheduleAppoitment({
                //             ...appointment,
                //             location: loc,
                //             slot: undefined,
                //             reservationId: null,
                //           });

                //           if (
                //             loc?.hasVipSlots == false &&
                //             loc?.rapidTest == false
                //           ) {
                //             updateRescheduleAppoitment({
                //               ...appointment,
                //               location: loc,
                //               slot: undefined,
                //               isExpressSameDayTest: false,
                //               isRapidTest: false,
                //             });
                //           } else if (
                //             loc?.hasVipSlots == true &&
                //             loc?.rapidTest == false
                //           ) {
                //             upateTestType(false);
                //             updateRescheduleAppoitment({
                //               ...appointment,
                //               location: loc,
                //               slot: undefined,
                //               isExpressSameDayTest: true,
                //               isRapidTest: false,
                //             });

                //             updateFormValues({
                //               location: loc,
                //               slot: undefined,
                //               isRapidTest: false,
                //               isExpressSameDayTest: false,
                //             });
                //           }
                //           if (
                //             confirmAndPay &&
                //             isExpressSameDayTest &&
                //             loc?.hasVipSlots == false
                //           ) {
                //             toggleShowAlertModal(true);
                //           } else if (
                //             confirmAndPay &&
                //             isRapidTest &&
                //             loc?.rapidTest == false
                //           ) {
                //             toggleShowAlertModal(true);
                //           }                                                       

                //           if (loc.qbenchCustomerId === 63 || loc.qbenchCustomerId === 68){
                //               updateFormValues({ isAntigen: true });
                //           }
                //             if (loc.qbenchCustomerId===66) {
                //                 updatePartnerLogo({ ...partnerLogo,locationImage:loc?.image})
                //             }
                //             else {
                //               updatePartnerLogo({ ...partnerLogo,locationImage:[]})
                //             }
                //             if (loc.qbenchCustomerId===67 && form.testSelection==='sfoairport') {
                //               updateFormValues({payingMethod:'Insurance',hasInsurance:true})
                //             } else if (loc.qbenchCustomerId===67) {
                //               updateFormValues({payingMethod:'Insurance',hasInsurance:true, isRapidTest:true,isAntigenTest:false,isExpressSameDayTest:false})
                //           }

                //         }}
                //       >

                //         <Info>
                //           <Name>{loc.name}</Name>
                //           {loc.address1.length>60?<><span style={{position:'absolute',width:'70%'}}>{loc.address1}</span><br/></>:loc.address1}
                //           <br />
                //           {loc.address2}
                //           {loc?.isOnlyPaymentText&&(<><br/><span style={{color:'red'}}>{loc?.isOnlyPaymentText}</span></>)}
                //         </Info>
                //         {
                //           loc.hasVipSlots?
                //             (
                //             <>
                //               <TestTags field={field} loc={loc} num={1} name={'express'}/>
                //               {loc.rapidTest?
                //                 (<>
                //                 <TestTags field={field} loc={loc} num={2} name={'rapid'}/>
                //                 {loc.isAntigen&&<TestTags field={field} loc={loc} num={3} name={'antigen'}/>}
                //                 </>)
                //               :
                //                 loc.isAntigen&&<TestTags field={field} loc={loc} num={2} name={'antigen'}/>
                //               }
                //             </>
                //             )
                //           :
                //           loc.rapidTest?
                //               (<>
                //                 <TestTags field={field} loc={loc} num={1} name={'rapid'}/>
                //                 {loc.isAntigen&&<TestTags field={field} loc={loc} num={2} name={'antigen'}/>}
                //                 </>)
                //               :
                //                 (loc.isAntigen&&<TestTags field={field} loc={loc} num={1} name={'antigen'}/>)
                //         }
                //       </LocationButton>
                //     );
                //   }}
                if (
                  loc?.cPartnerID === 'KEN001' ||
                  IstraveltypeDOM === loc?.isDomestic &&
                  loc?.isDomestic === true || isWalkUp?.locationId === config?.qbenchCustomerId?.PCMADestination || isWalkUp?.locationId === config.qbenchCustomerId.KernHealthSystems
                ) {
                  return (
                    <LocationButton
                      key={loc?.name}
                      type="submit"
                      selected={
                        field.value?.name === loc?.name
                      }
                      onClick={async () => {
                        if (step !== 2 && loc?.partnerName === 'goHealth') {
                          goToStep(3)
                          updateFormValues({ minors: [], isAntigen: false, registeringFor: form?.registeringFor === 'minorOnly' ? '' : 'myself' })
                        }
                        setFieldValue('location', loc);
                        localStorage.setItem(
                          'location',
                          JSON.stringify(loc)
                        );

                        updateFormValues({
                          location: loc,
                          slot: undefined,
                          reservationId: null,
                        });

                        if (isWalkUp.active) {
                          updateFormValues({
                            cPartnerID: loc?.cPartnerID,
                          });
                        }

                        if (loc?.hasVipSlots == false) {
                          upateTestType(true);
                          updateFormValues({
                            isExpressSameDayTest: false,
                          });
                        }

                        updateRescheduleAppoitment({
                          ...appointment,
                          location: loc,
                          slot: undefined,
                          reservationId: null,
                        });

                        if (
                          loc?.hasVipSlots == false &&
                          loc?.rapidTest == false
                        ) {
                          updateRescheduleAppoitment({
                            ...appointment,
                            location: loc,
                            slot: undefined,
                            isExpressSameDayTest: false,
                            isRapidTest: false,
                          });
                        } else if (
                          loc?.hasVipSlots == true &&
                          loc?.rapidTest == false
                        ) {
                          upateTestType(false);
                          updateRescheduleAppoitment({
                            ...appointment,
                            location: loc,
                            slot: undefined,
                            isExpressSameDayTest: true,
                            isRapidTest: false,
                          });

                          updateFormValues({
                            location: loc,
                            slot: undefined,
                            isRapidTest: false,
                            isExpressSameDayTest: false,
                          });
                        }
                        if (
                          loc?.cPartnerID === 'KEN001' &&
                          isWalkUp.active
                        ) {
                          updateFormValues({
                            isWalkUpTest: true,
                          });
                        }
                        if (payment) {
                            paymentMethod()
                        }

                        if (
                          confirmAndPay &&
                          isExpressSameDayTest &&
                          loc?.hasVipSlots == false
                        ) {
                          toggleShowAlertModal(true);
                        } else if (
                          confirmAndPay &&
                          isRapidTest &&
                          loc?.rapidTest == false
                        ) {
                          toggleShowAlertModal(true);
                        }

                        if (
                          loc?.qbenchCustomerId ===
                          config.qbenchCustomerId
                            .PostIsolationRapidAntigenTest ||
                          loc?.qbenchCustomerId ===
                          config.qbenchCustomerId
                            .ArroyoUpperSchool
                        ) {
                          updateFormValues({
                            isExpressSameDayTest: false,
                            isAntigen: true,
                          });
                          updateRescheduleAppoitment({
                            ...appointment,
                            location: loc,
                            slot: undefined,
                            isExpressSameDayTest: false,
                            isRapidTest: false,
                            isAntigen: true,
                          });
                        } else {
                          updateFormValues({
                            // isRapidTest: false,
                            // isExpressSameDayTest: false,
                            isAntigen: false,
                          });
                          updateRescheduleAppoitment({
                            ...appointment,
                            location: loc,
                            slot: undefined,
                            isExpressSameDayTest: false,
                            isRapidTest: false,
                            isAntigen: false,
                          });
                        }
                        // if(loc.qbenchCustomerId === config.qbenchCustomerId.Brooklyn){
                        //     updateFormValues({isAntigen:true })
                        // }
                        if (
                          loc?.qbenchCustomerId ===
                          config?.qbenchCustomerId
                            .BoysGirlsClub
                        ) {
                          updatePartnerLogo({
                            ...partnerLogo,
                            locationImage: loc?.image,
                          });
                        } else {
                          updatePartnerLogo({
                            ...partnerLogo,
                            locationImage: [],
                          });
                        }
                        if (
                          loc?.qbenchCustomerId ===
                          config.qbenchCustomerId.SFOAirport_BARTStation &&
                          form?.testSelection === 'sfoairport'
                        ) {
                          // updateFormValues({ payingMethod: 'Insurance', hasInsurance: true })
                        } else if (
                          loc?.qbenchCustomerId ===
                          config.qbenchCustomerId.SFOAirport_BARTStation &&
                          form?.payingMethod === 'Insurance' &&
                          form?.testSelection !== 'sfoairport'
                        ) {
                          updateFormValues({
                            isRapidTest: false,
                            isAntigen: true,
                            isExpressSameDayTest: false,
                          });
                          updateStandradTest(false);
                        }
                        if (
                          loc?.qbenchCustomerId ===
                          config.qbenchCustomerId.SFOAirport_BARTStation &&
                          form.payingMethod !== 'Insurance' &&
                          form.testSelection !== 'sfoairport'
                        ) {
                          updateFormValues({
                            isRapidTest: true,
                            isAntigen: false,
                            isExpressSameDayTest: false,
                          });
                          updateStandradTest(false)
                        }
                        // if (
                        //   loc.qbenchCustomerId ===
                        //     config.qbenchCustomerId
                        //       .SFOAirport_BARTStation &&
                        //   form.testSelection === 'sfoairport'
                        // ) {
                        //   updateFormValues({
                        //     isAntigen: true,
                        //   });
                        // }
                        //   if (
                        //     loc?.qbenchCustomerId ===
                        //     config.qbenchCustomerId
                        //       .LasVegasSpringValley
                        //   ) {
                        //     updateFormValues({
                        //       isRapidTest: true,
                        //       isAntigenTest: false,
                        //       isExpressSameDayTest: false,
                        //       hasInsurance: false,
                        //     //   payingMethod: 'Credit card',
                        //     });
                        //     updateStandradTest(false)
                        //     upateTestType(false);
                        //   }
                        if (loc?.isPanel) {
                          updateFormValues({
                            isRapidTest: false,
                            isAntigenTest: false,
                            isExpressSameDayTest: false,
                            isPanel: true
                          });
                          updateStandradTest(false)
                          upateTestType(false);
                        }
                        if (loc?.cPartnerID === 'SEQ001' && !loc?.isPanel) {
                          updateStandradTest(true)
                          upateTestType(true);
                          updateFormValues({ isPanel: false })
                        }
                      }}
                    >
                      <Info>
                        <Name>{loc?.name}</Name>
                        <Address3>
                          {' '}
                          {loc?.address1?.length > 60 ? (
                            <>
                              <Span1>{loc?.address1}</Span1>
                              <br />
                            </>
                          ) : (
                            loc?.address1
                          )}
                        </Address3>
                        <br />
                        <Address2>{loc?.address2}</Address2>
                        {loc?.isOnlyPaymentText && (
                          <>
                            <br />
                            <span style={{ color: 'red' }}>
                              {loc?.isOnlyPaymentText}
                            </span>
                          </>
                        )}
                        <br />
                        {
                          loc?.partnerName === 'goHealth' &&
                          <span>{`${loc?.goHealthPricing?.sentence1}`} <u><a href={`${loc?.goHealthPricing?.url}`} target="_blank">{`${loc?.goHealthPricing?.shortUrl}`}</a></u> {`${loc?.goHealthPricing?.sentence2}`}</span>
                        }
                      </Info>
                      {loc?.partnerName === 'goHealth' &&
                        <TestTags field={field} loc={loc} num={4} name={'pricingVaries'} />
                      }
                      {loc?.hasVipSlots ? (
                        <>
                          <TestTags
                            field={field}
                            loc={loc}
                            num={1}
                            name={'express'}
                          />
                          {loc?.rapidTest ? (
                            <>
                              <TestTags
                                field={field}
                                loc={loc}
                                num={2}
                                name={'rapid'}
                              />
                              {loc?.isAntigen && (
                                <TestTags
                                  field={field}
                                  loc={loc}
                                  num={3}
                                  name={'antigen'}
                                />
                              )}
                            </>
                          ) : (
                            loc?.isAntigen && (
                              <TestTags
                                field={field}
                                loc={loc}
                                num={2}
                                name={'antigen'}
                              />
                            )
                          )}
                        </>
                      ) : loc?.rapidTest ? (
                        <>
                          <TestTags
                            field={field}
                            loc={loc}
                            num={1}
                            name={'rapid'}
                          />
                          {loc?.isAntigen && (
                            <TestTags
                              field={field}
                              loc={loc}
                              num={2}
                              name={'antigen'}
                            />
                          )}
                        </>
                      ) : (
                        loc?.isAntigen && (
                          <TestTags
                            field={field}
                            loc={loc}
                            num={1}
                            name={'antigen'}
                          />
                        )
                      )}
                    </LocationButton>
                  );
                } else if (
                  IstraveltypeINT === loc?.isInternational &&
                  loc?.isInternational === true
                ) {
                  //Pause the booking for few location for the few destinations
                  let dest: any = destination;
                  let allowLocation: boolean = true;

                  if (dest) {
                    allowLocation =
                      includes(config.pauseBooking.destinations, dest) &&
                        includes(
                          config.pauseBooking.locations,
                          loc?.qbenchCustomerId
                        )
                        ? false
                        : true;
                  }

                  if (!allowLocation) {
                    return <div />;
                  }

                  return (
                    <LocationButton
                      key={loc?.name}
                      type="submit"
                      selected={
                        field.value?.name === loc?.name
                      }
                      onClick={async () => {
                        setFieldValue('location', loc);
                        localStorage.setItem(
                          'location',
                          JSON.stringify(loc)
                        );
                        if (step !== 2 && loc?.partnerName === 'goHealth') {
                          updateFormValues({ minors: [], isAntigen: false, registeringFor: form?.registeringFor === 'minorOnly' ? '' : 'myself' })
                          goToStep(3)
                        }
                        // const periods: any = await getSlotsByLocationId(loc.qbenchCustomerId);
                        // updateSlotsList(periods);
                        // updateAdminSlots(periods);
                        // updateRescheduleSlots(periods);
                        updateFormValues({
                          location: loc,
                          slot: undefined,
                        });

                        if (loc?.hasVipSlots == false) {
                          upateTestType(true);
                          updateFormValues({
                            isExpressSameDayTest: false,
                          });
                        }

                        updateRescheduleAppoitment({
                          ...appointment,
                          location: loc,
                          slot: undefined,
                        });

                        if (
                          loc?.hasVipSlots == false &&
                          loc?.rapidTest == false
                        ) {
                          updateRescheduleAppoitment({
                            ...appointment,
                            location: loc,
                            slot: undefined,
                            isExpressSameDayTest: false,
                          });
                        } else if (
                          loc?.hasVipSlots == true &&
                          loc?.rapidTest == false
                        ) {
                          upateTestType(false);
                          updateRescheduleAppoitment({
                            ...appointment,
                            location: loc,
                            slot: undefined,
                            isExpressSameDayTest: true,
                            isRapidTest: false,
                          });

                          updateFormValues({
                            location: loc,
                            slot: undefined,
                            isRapidTest: false,
                            isExpressSameDayTest: false,
                          });
                        }

                        if (
                          confirmAndPay &&
                          isExpressSameDayTest &&
                          loc?.hasVipSlots == false
                        ) {
                          toggleShowAlertModal(true);
                        } else if (
                          confirmAndPay &&
                          isRapidTest &&
                          loc?.rapidTest == false
                        ) {
                          toggleShowAlertModal(true);
                        }
                        if (
                          loc?.qbenchCustomerId ===
                          config.qbenchCustomerId
                            .PostIsolationRapidAntigenTest ||
                          loc?.qbenchCustomerId ===
                          config.qbenchCustomerId
                            .ArroyoUpperSchool
                        ) {
                          updateFormValues({
                            isExpressSameDayTest: false,
                            isAntigen: true,
                          });
                        }
                        if (
                          loc?.qbenchCustomerId ===
                          config.qbenchCustomerId.Brooklyn
                        ) {
                          updateFormValues({
                            isAntigen: true,
                          });
                        }
                        if (
                          loc?.qbenchCustomerId ===
                          config.qbenchCustomerId
                            .BoysGirlsClub
                        ) {
                          updatePartnerLogo({
                            ...partnerLogo,
                            locationImage: loc?.image,
                          });
                        } else {
                          updatePartnerLogo({
                            ...partnerLogo,
                            locationImage: [],
                          });
                        }
                        //   if (
                        //     loc?.qbenchCustomerId ===
                        //     config.qbenchCustomerId
                        //       .LasVegasSpringValley
                        //   ) {
                        //     updateFormValues({
                        //       isRapidTest: true,
                        //       isAntigenTest: false,
                        //       isExpressSameDayTest: false,
                        //       hasInsurance: false,
                        //     //   payingMethod: 'Credit card',
                        //     });
                        //     updateStandradTest(false)
                        //     upateTestType(false);
                        //   }
                        if (loc?.isPanel) {
                          updateFormValues({
                            isRapidTest: false,
                            isAntigenTest: false,
                            isExpressSameDayTest: false,
                            isPanel: true
                          });
                          updateStandradTest(false)
                          upateTestType(false);
                        }
                        if (loc?.cPartnerID === 'SEQ001' && !loc?.isPanel) {
                          updateStandradTest(true)
                          upateTestType(true);
                          updateFormValues({ isPanel: false })
                        }
                        if (
                          loc?.qbenchCustomerId ===
                          config.qbenchCustomerId.SFOAirport_BARTStation &&
                          form.payingMethod !== 'Insurance' &&
                          form.testSelection !== 'sfoairport'
                        ) {
                          updateFormValues({
                            isRapidTest: true,
                            isAntigen: false,
                            isExpressSameDayTest: false,
                          });
                          updateStandradTest(false)
                        }
                      }}
                    >
                      <Info>
                        <Name>{loc?.name}</Name>
                        <Address3>
                          {loc?.address1?.length > 60 ? (
                            <>
                              <Span1>{loc?.address1}</Span1>
                              <br />
                            </>
                          ) : (
                            loc?.address1
                          )}
                        </Address3>
                        <br />
                        <Address2>{loc?.address2}</Address2>
                        {loc?.isOnlyPaymentText && (
                          <>
                            <br />
                            <span style={{ color: 'red' }}>
                              {loc?.isOnlyPaymentText}
                            </span>
                          </>
                        )}
                        <br />
                        {
                          loc?.partnerName === 'goHealth' &&
                          <span>{`${loc?.goHealthPricing?.sentence1}`} <u><a href={`${loc?.goHealthPricing?.url}`} target="_blank">{`${loc?.goHealthPricing?.shortUrl}`}</a></u> {`${loc?.goHealthPricing?.sentence2}`}</span>
                        }
                      </Info>
                      {
                        loc?.partnerName === 'goHealth' &&
                        <TestTags field={field} loc={loc} num={4} name={'pricingVaries'} />
                      }
                      {loc?.hasVipSlots ? (
                        <>
                          <TestTags
                            field={field}
                            loc={loc}
                            num={1}
                            name={'express'}
                          />
                          {loc?.rapidTest ? (
                            <>
                              <TestTags
                                field={field}
                                loc={loc}
                                num={2}
                                name={'rapid'}
                              />
                              {loc?.isAntigen && (
                                <TestTags
                                  field={field}
                                  loc={loc}
                                  num={3}
                                  name={'antigen'}
                                />
                              )}
                            </>
                          ) : (
                            loc?.isAntigen && (
                              <TestTags
                                field={field}
                                loc={loc}
                                num={2}
                                name={'antigen'}
                              />
                            )
                          )}
                        </>
                      ) : loc?.rapidTest ? (
                        <>
                          <TestTags
                            field={field}
                            loc={loc}
                            num={1}
                            name={'rapid'}
                          />
                          {loc?.isAntigen && (
                            <TestTags
                              field={field}
                              loc={loc}
                              num={2}
                              name={'antigen'}
                            />
                          )}
                        </>
                      ) : (
                        loc?.isAntigen && (
                          <TestTags
                            field={field}
                            loc={loc}
                            num={1}
                            name={'antigen'}
                          />
                        )
                      )}
                    </LocationButton>
                  );
                }
              })}
            <Error>
              <ErrorText hasError={error !== undefined}>{error}</ErrorText>
            </Error>

            {/* <AlertModal
                            confirmAndPay={true}
                            title={'Are you sure you want to move your appointment location?'}
                            content={
                                'You have selected a site that does not have the express PCR testing. By selecting this location option you will be downgraded to standard PCR testing, which costs $90 and has a 24-hour turnaround time.'
                            }
                            onConfirm={() => {
                                // alert("confirm");
                                updateFormValues({ update: location });
                                updateFormValues({ update: slot });
                                setFieldValue('location', location);
                                upateTestType(true);
                                updateFormValues({ isExpressSameDayTest: false });
                                updateRescheduleAppoitment({
                                    ...appointment,
                                    isExpressSameDayTest: false,
                                });
                                closeModal();
                            }}
                            onCancel={() => {
                                upateTestType(false);
                                updateFormValues({
                                    location: rollbackLocation,
                                    slot: rollbackSlot,
                                    isExpressSameDayTest: rollbackisExpressSameDayTest,
                                });
                                setFieldValue('location', rollbackLocation);
                            }}
                        /> */}
          </LocationsContainer>
        )}
      </Field>
    </>
  );
};

export default LocationButtonGroup;
