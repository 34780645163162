import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  SubHeaderDescription,
  ErrorText,
  Button,
} from '../../../shared/components/styled';
import { Breakpoints } from '../../../../dictionaries';
import { useStepsDispatch, useStepsState } from '../../provider';
import { useSharedDispatch,useSharedState } from '../../../shared/provider';
import { Formik, Form } from 'formik';
import FormLabel from '../../../shared/components/form/FormLabel';
import Select from '../../../shared/components/form/Select';
import Checkbox from '../../../shared/components/form/Checkbox';
import { firebase } from '../../../../firebase';
import { orderBy } from 'lodash';
import { getCpartnerLogo, getTestReasons, getVaccines } from '../../../register/api';

const Text = styled.div`
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  margin-bottom: 5px;
`;

const SubContent = styled.p`
  margin-top: 20px;
  text-align: left;
  @media (max-width: ${Breakpoints.sm}px) {
    padding: 0 !important;
  }
`;

const BtnContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  flex-wrap: nowrap;
  padding-top: 20px;

  @media (min-width: 450px) {
    flex-direction: row;
  }
`;
const Space = styled.div`
  height: 10px;
  width: 20px;
`;
const returnBtnStyle = {
  backgroundColor: '#FFFFFF',
  color: '#101010',
  border: '0.5px solid #FFFFFF',
  fontWeight: '600',
  fontSize: '14px',
};
const saveBtnStyle = {
  backgroundColor: '#FF802E',
  border: '1px solid #FF802E',
  fontWeight: '600',
  fontSize: '14px',
};
const AirportSelection: React.FC = () => {
  const { updateFormValues, goToNextStep, goToPrevStep } = useStepsDispatch();
  const { form } = useStepsState();
  const { testSelection, district, commitToConfirm,testReason, vaccineType, isVaccinated } = form;
  const db = firebase.firestore();
  const {isVaccinate, testFlowSelectionDatas} = useSharedState();
  const [values, setvalues] = useState<{ value: string; label: string }[]>( [
                  
    {
      value: 'N',
      label: 'No',
    },
    {
      value: 'PNS',
      label: 'Prefer Not To State',
    },
    {
      value: 'Y',
      label: 'Yes',
    },
  ]);
  const { updatePartnerLogo, 
          updateReasonType,
          updateVaccinated,
          updateVaccineType,
        } = useSharedDispatch();
  
  const [loading, setLoading] = useState<boolean>(false);
  const [errormsg, setErrormsg] = useState<boolean>(false);

  useEffect(() => {
    fetchReasons();
    fetchVaccines();

  }, []);

  const [reasonData, setReasonData] = useState<{ value: string; label: string }[]>(testFlowSelectionDatas?.reasonData ?? []);
  const [vaccineData, setVaccineData] = useState<{ value: string; label: string }[]>(testFlowSelectionDatas?.vaccineData ?? []);

  const fetchReasons = async () => {
    // const response = db.collection('testreasons');
    // const snapShotData = await response.get();
    // const data = snapShotData.docs.map((doc: any) => doc.data());
    const res = await getTestReasons();
    if (res.data.status === 200) {
      const data: any = res.data.data;
      var list: any[] = [];
      data.reduce((index: any, reason: any) => {
        list.push({
          value: reason.value,
          label: reason.name,
        });
      }, []);
      list = orderBy(list, ['label'], ['asc']);
      setReasonData(list);
    }
  };
  const fetchVaccines = async () => {
    // const response = db.collection('vaccination');
    // const snapShotData = await response.get();
    // const data = snapShotData.docs.map((doc: any) => doc.data());
     const res = await getVaccines();
     if (res.data.status === 200) {
       const data: any = res.data.data;
       var list: any[] = [];
       data.reduce((index: any, vaccine: any) => {
         list.push({
           value: vaccine.value,
           label: vaccine.name,
         });
       }, []);
       list = orderBy(list, ['label'], ['asc']);
       setVaccineData(list);
     }
  }
  return (
    <SubContent>
      <Formik
        initialValues={{
          testReason: testReason,
          isVaccinated: isVaccinated,
          vaccineType: vaccineType,
          commitToConfirm,
        }}
        onSubmit={(values) => {
          // let districts: string = district.value !== undefined ? district.value : district
          updateFormValues({
            ...values,
            // cPartnerID: testSelection === 'community' ? district === 'SSFSD' : 'SFO001'
            cPartnerID: 'SFO001',
            travelType: null,
          });
          if (commitToConfirm) {
            goToNextStep();
          } else {
            setErrormsg(true);
          }
        }}
      >
        {() => (
          <Form>
            {/* <SubHeaderDescription style={{ marginBottom: '5px' }}>
              Please select your school district
              <span style={{ color: 'red' }}>*</span>
            </SubHeaderDescription>
            <FormLabel label="">
              <Select<string>
                name="district"
                options={districtData} //test
                onChange={(value) => {
                  updateFormValues({ district: value });
                  updateDistrict(value);
                  localStorage.setItem('dist', value);
                }}
                validate={(value) => {
                  if (!value) {
                    return 'Required field';
                  }
                }}
              />
            </FormLabel> */}
            <SubHeaderDescription style={{ marginBottom: '5px' }}>
              Why do you need this test?
              <span style={{ color: 'red' }}>*</span>
            </SubHeaderDescription>
            <FormLabel label="">
              <Select<string>
                name="testReason"
                options={reasonData} //test
                onChange={(value) => {
                  updateReasonType(value);
                  localStorage.setItem('reason', value);
                  updateFormValues({ testReason: value });
                }}
                validate={(value) => {
                  if (!value) {
                    return 'Required field';
                  }
                }}
              />
            </FormLabel>
            <SubHeaderDescription style={{ marginBottom: '5px' }}>
              Have you been vaccinated?
              <span style={{ color: 'red' }}>*</span>
            </SubHeaderDescription>
            <FormLabel label="">
              <Select<string>
                name="isVaccinated"
                options={values}
                onChange={(value) => {
                  updateVaccinated(value);
                  localStorage.setItem('isvac', value);
                  updateFormValues({ isVaccinated: value });
                }}
                validate={(value) => {
                  if (!value) {
                    return 'Required field';
                  }
                }}
              />
            </FormLabel>

            {isVaccinate === 'Y' && (
              <>
                <SubHeaderDescription style={{ marginBottom: '5px' }}>
                  If yes, what vaccine did you receive?
                  <span style={{ color: 'red' }}>*</span>
                </SubHeaderDescription>
                <FormLabel label="">
                  <Select<string>
                    name="vaccineType"
                    options={vaccineData}
                    onChange={(value: any) => {
                      updateVaccineType(value);
                      localStorage.setItem('vactype', value);
                      updateFormValues({ vaccineType: value });
                    }}
                    validate={(value) => {
                      if (!value) {
                        return 'Required field';
                      }
                    }}
                  />
                </FormLabel>
              </>
            )}
            <Checkbox
              name="commitToConfirm"
              onChange={(checked) => {
                updateFormValues({ commitToConfirm: checked });
              }}
              children={
                <Text>
                  I understand that I am booking a COVID-19 test and the results
                  are not meant to be used for travel, and simply for
                  identification of if I have an active COVID-19 infection or
                  not.
                  <span style={{ color: 'red' }}>*</span>
                </Text>
              }
            />
            {errormsg && <ErrorText hasError>{'Required Field'}</ErrorText>}
            <BtnContainer>
              <Button
                libraryType="default"
                type="button"
                style={returnBtnStyle}
                className="btn elevated-btn"
                onClick={() => goToPrevStep()}
              >
                Return
              </Button>
              <Space />

              <Button
                libraryType="primary"
                type="submit"
                style={saveBtnStyle}
                className="btn fill-btn"
                disabled={loading}
              >
                {loading ? 'Processing...' : 'Save & Continue'}
              </Button>
            </BtnContainer>
          </Form>
        )}
      </Formik>
    </SubContent>
  );
};

export default AirportSelection;
