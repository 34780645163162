import React, { useState } from 'react';
import styled from 'styled-components';
import { Form, Formik } from 'formik';
import {
  Container,
  Contents,
  PageHeader,
  SubHeaderDescription,
  PageTitle,
} from '../../shared/components/styled';
import { colors } from '../../../styles/colors';
import { useHistory, useParams } from 'react-router-dom';
import { Snackbar } from '@material-ui/core';
import Header from '../../shared/components/Header';
import Footer from '../../shared/components/Footer';
import { Breakpoints } from '../../../dictionaries';
import wsllogo from '../../../assets/WLABS_CMYK.png';
import hallogo from '../../../assets/hal_logo.png';
import orglogo from '../../../assets/org_logo.png';
import Publiclogo from '../../../assets/public_logo.png';
import groublogo from '../../../assets/groub_logo.png';
import { useStepsDispatch } from '../../schedule/provider';
import { MultiTenancyService } from '../../../services/mutitenancy.service';
import { verifyEmails} from '../../manage/api';
import { PrimeContainer } from './ConfirmAccount';
import { useSharedState, useSharedDispatch } from '../../shared/provider';
import { useRegisterStepsDispatch } from '../provider';
import MuiAlert from '@material-ui/lab/Alert'

export const Logos = styled.div`
  display: flex;
`;

export const WLLogo = styled.div`
  height: 18px;
  width: 118px;
  background: url(${wsllogo}) no-repeat center center;
  background-size: cover;
  margin-right: 20px;
  display: inline-block;

  @media (min-width: ${Breakpoints.sm}px) {
    height: 70px;
    width: 300px;
  }
`;

export const Logos1 = styled.div`
  position: absolute;
  width: 601px;
  height: 161px;
  right: 432px;
  bottom: 518px;

  border: 1px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15));
  border-radius: 10px;
`;

export const Haldiv = styled.div`
  position: absolute;
  width: 120px;
  height: 120px;
  left: 616px;
  top: 408px;

  border: 1px solid rgb(235 227 227 / 50%);
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
  border-radius: 10px;
`;

export const HALLogo = styled.div`
  height: 18px;
  width: 118px;
  background: url(${hallogo}) no-repeat center center;
  background-size: cover;
  display: inline-block;
  margin: -20px 0px 20px 0px;

  @media (min-width: ${Breakpoints.sm}px) {
    height: 100px;
    width: 300px;
  }
`;
export const Text = styled.div`
  position: absolute;
  width: 521px;
  height: 44px;
  left: 447px;
  top: 180px;

  font-family: Open Sans;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 44px;
  text-align: center;
  padding-right: 68px;
  color: #000000;
`;

export const SubText = styled.div`
  position: absolute;
  width: 460px;
  height: 39px;
  left: 472px;
  top: 220px;

  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  /* or 137% */
  text-align: center;
  padding-right: 30px;

  color: ${colors.black};
`;

export const Orglogo = styled.div`
  height: 18px;
  width: 118px;
  background: url(${orglogo}) no-repeat center center;
  background-size: cover;
  display: inline-block;
  @media (min-width: ${Breakpoints.sm}px) {
    height: 80px;
    width: 85px;
  }
`;

export const CategoryContentBox = styled.div`
  flex: 0.3 1 0%;
  justify-content: center;
  border-radius: 10px;
  border: 2px solid ${colors.lightGrey};
  box-sizing: border-box;
  padding: 10px 0px 10px 0px;
  margin: 0px 5px 0px 5px;
  cursor: pointer;
`;

export const PublicLogo = styled.div`
  height: 18px;
  width: 118px;
  background: url(${Publiclogo}) no-repeat center center;
  background-size: cover;
  display: inline-block;
  @media (min-width: ${Breakpoints.sm}px) {
    height: 80px;
    width: 85px;
  }
`;


export const GroupLogo = styled.div`
  height: 18px;
  width: 118px;
  background: url(${groublogo}) no-repeat center center;
  background-size: cover;
  display: inline-block;
  @media (min-width: ${Breakpoints.sm}px) {
    height: 79px;
    width: 97px;
  }
`;

export const CategoyTitle = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 18px;

  color: #2193d2;
  margin-top: 3px;
`;

const ContinueButton = styled.button`
  width: 40%;
  color: ${colors.white};
  text-align: center;
  padding: 15px;
  background: ${colors.orange};
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  border: 0;
  font-size: 20px;
  line-height: 15px;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
`;


type Props = {
  isVerfied?: boolean;
};

const VerifyEmail: React.FC<Props> = ({ isVerfied }) => {
  const { updateFormValues,updatesFormValues,goToSkipStep,goToNextStep,goToPrevStep,updateLoginScreen} = useStepsDispatch();
  const {
    updateapptFromSchdule,
    setTestSelectionFlow,
    setloggedUserData,
  } = useSharedDispatch();
//   const {updateFormValues}=useRegisterStepsDispatch()
  const [loading, setLoading] = useState(false);
  const [check, setCheck] = useState(false);
  const [alertbox, setalertbox] = React.useState<{ type: string, msg: string, open: true | false }>({ type: '', msg: '', open: false })
  function Alert(props: any) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  let history = useHistory();

  const params = useParams<any>()

  const handleClose = () => {
    setalertbox({ open: false, type: '', msg: '' })
  }
  
  const checks = async () =>{

    setLoading(true);
    localStorage.setItem('user', '');
   
    const data = String(params.mail).split('-');
    
    if (params) {
      const param = {
        id: data[0],
        isChangeEmail: data[1] === 'true' ? true : false,
      };
      const loggedUser = await verifyEmails(param);
      if (loggedUser?.data?.status === 200) {
        // getFormDetailsData({email:data[0]})
        setCheck(true);
        setLoading(false);
        setalertbox({ type: 'success', msg: 'Verification Successful', open: true })
        setTimeout(()=>{
          window.close()
          setalertbox({ type: '', msg: '', open: false })
        },2500)
      } else {
        history.push('/signin');
      }
    }
    setLoading(false);
  }

  React.useEffect(() => {
    checks();
    const data = String(params.mail).split('-')
   
    if(data[0] === undefined){
        history.push('/signin')
    }
    
  },[])
    
  return (
    <div >
      
      <Header  />
      <Snackbar open={alertbox.open} onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}>
          <Alert onClose={handleClose} severity={alertbox.type}>
            {alertbox.msg}
          </Alert>
        </Snackbar>

      <PrimeContainer style={{marginTop: '-20px',marginBottom: '20px'}}>
        <Container style={{ minHeight: '300px', backgroundColor: '#fff' }}>
          <Contents style={{ padding: '70px 50px' }}>
            <>
              <PageHeader style={{marginTop: '10px'}}>
                <PageTitle
                  color={colors.fullBlack}
                  textAlign="left"
                  fontsize={'28px'}
                >
                  {`Verify your Account` }
                </PageTitle>
              </PageHeader>
              <SubHeaderDescription
                style={{ fontSize: '17px', margin: '20px 0' }}
              >
                {/* please verify to confirm your
                account. */}
                {loading ? 'Please wait, your email is being verified.' : check ? 'Your account has been verified' : 'Please Verify your account'}
              </SubHeaderDescription>

              <Formik
                initialValues={{
                  otp: '',
                }}
                onSubmit={() => {
                  checks();
                }}
                enableReinitialize={false}
              >
                {() => (
                  <Form>
                    <div style={{ textAlign: 'left', marginTop:'10%'}}>
                      <ContinueButton type="submit">
                        {loading ? 'Processing...' : check ? 'Verified' : 'Verify'}
                      </ContinueButton>
                      <br/><br/>
                      {/* {check&& <p style={{fontSize:'18px',fontWeight:'bold'}}>Go to previous tab to complete your booking.</p>} */}
                    </div>
                  </Form>
                )}
              </Formik>
            </>
          </Contents>
        </Container>
        <div style={{ marginTop: '40px' }}>
          <Footer />
        </div>
      </PrimeContainer>
    </div>
  );
};

export default VerifyEmail;