import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { Breakpoints } from '../../../../dictionaries';
import { Form, Formik } from 'formik';
import Input from '../../../shared/components/form/Input';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { useSharedDispatch, useSharedState } from '../../../shared/provider';
import { useRegisterStepsDispatch, useStepsState } from '../../provider';
import Modal from './../../../shared/components/Modal';
import { Button } from './../../../shared/components/styled';
import { colors } from '../../../../styles/colors';
import { updateRegister } from '../../api';
import FormLabel from '../../../shared/components/form/FormLabel';
import CheckboxGroup from '../../../shared/components/form/CheckboxGroup';

const Content = styled.div`
  width: 100%;

  @media (min-width: ${Breakpoints.md}px) {
    width: 540px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 240px;
  margin: 0 -5px;

  & > * {
    margin: 0 5px;
  }
  @media (max-width: ${Breakpoints.sm}px) {
    display: grid;
    row-gap: 10px;
  }
`;

const Title = styled.h2`
  color: ${colors.fullBlack};
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 25px;
`;
const Container = styled.div``;
const InputRow = styled.div`
  display: flex;
  flex-direction: column;
  margin: -8px 0;

  @media (min-width: ${Breakpoints.sm}px) {
    flex-direction: row;
    margin: 0 -8px;
  }

  @media (min-width: ${Breakpoints.lg}px) {
    margin: 0 -15px;
  }

  & > * {
    flex-basis: 33%;
    margin: 8px 0;

    @media (min-width: ${Breakpoints.sm}px) {
      margin: 0 8px;
    }

    @media (min-width: ${Breakpoints.lg}px) {
      margin: 0 15px;
    }
  }
`;
type Props = {
  open: boolean;
  handleGenderClose: () => void;
  isProfile: boolean;
};
const ChangeGenderModal: React.FC<Props> = ({
  handleGenderClose,
  open,
  isProfile,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const {
    form: { sex },
  } = useStepsState();
  const { userData } = useSharedState();
  const { setloggedUserData } = useSharedDispatch();
  const { updateFormValues, goToNextStep, goToPrevStep } = useRegisterStepsDispatch();

  const updateChangeOnClick = async (values: any) => {
    setLoading(true);
    if (isProfile) {
      let resultdata = await updateRegister({
        email: userData?.email,
        sex: values?.sex,
      });
      if (resultdata?.data?.status === 200) {
        setloggedUserData(resultdata?.data?.data);
        localStorage.setItem('user', JSON.stringify(userData));
      }
    }
    handleGenderClose();
    setLoading(false);
  };
  return (
    <Modal open={open} onClose={handleGenderClose} maxWidth={800}>
      <Container>
        <Title>Enter your information</Title>
        <Formik
          initialValues={{
            sex:isProfile? userData?.sex: sex,
          }}
          onSubmit={(values) => {
            updateChangeOnClick(values)
            updateFormValues(values);
            handleGenderClose();
          }}
        >
          {() => (
            <Form>
              <CheckboxGroup
                name="sex"
                title="Sex (at birth)"
                options={[
                  { label: 'Female', value: 'Female' },
                  { label: 'Male', value: 'Male' },
                  // {
                  //   label: 'Non-binary',
                  //   value: 'Non-binary',
                  // },
                  // {
                  //   label: 'Prefer to self-describe',
                  //   value: 'Prefer to self-describe',
                  // },
                  {
                    label: 'Prefer not to state',
                    value: 'Prefer not to state',
                  },
                ]}
                isRequired
              />
              <ButtonsContainer>
                <Button
                  libraryType="default"
                  size="sm"
                  type="button"
                  onClick={() => {
                    handleGenderClose();
                  }}
                  className='btn elevated-btn'
                >
                  Cancel
                </Button>
                <Button libraryType="primary" size="sm" type="submit" className='btn fill-btn'>
                  {loading ? 'Processing...' : 'Confirm'}{' '}
                </Button>
              </ButtonsContainer>
            </Form>
          )}
        </Formik>
      </Container>
    </Modal>
  );
};

export default ChangeGenderModal;
