import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors } from '../../../../../styles/colors';
import { Breakpoints } from '../../../../../dictionaries';
import { useSharedDispatch, useSharedState } from '../../../../shared/provider';
import Loader from '../../../../shared/components/Loader';
import { useStepsDispatch } from '../../../../schedule/provider';
import {
  Container,
  PageHeader,
} from '../../../../shared/components/styled';
import { ReactComponent as PlusIcon } from '../../../../../assets/plus.svg';
import { IoPersonOutline, IoPeopleOutline } from 'react-icons/io5';
import { BiIdCard } from 'react-icons/bi';
import { AiOutlinePicture } from 'react-icons/ai';
import ChangeInsuranceModal from '../../ChangePersonalInfo/ChangeInsuranceModal';
import pencil from '../../../../../assets/pencil.png';
import { getUserInsurance ,getGovtId} from '../../../api';
import AddInsuranceList from './AddInsuranceList';
import { Insurance ,GovtID} from '../../../../shared/models';
import CancelInsuranceModal from '../../NewInsuranceScreen/CancelInsuranceModal';
import { RiDeleteBinLine } from 'react-icons/ri';
import DrivingLicenseModel from '../../DrivingLicenseModel'
import { BiPlus } from 'react-icons/bi';
import CancelGovernmentId from '../../NewInsuranceScreen/CancelGovernmentId';
import { EditOutlined } from '@ant-design/icons';
import { makeStyles } from "@material-ui/core/styles"
const useStyles = makeStyles({
    hideImage: {
     display:"none"
    },
    showImage:{
        display:"block"
    }
  })

const Mainpage = styled.div`
  margin: 7% 0 0px 10%;
  padding-bottom: 30px;
  @media (max-width: ${Breakpoints.sm}px) {
    margin: 20% 0px 0px 15%;
  }
  @media (max-width: ${Breakpoints.lg}px) {
    margin: 20% 0px 0px 15%;
  }
`;
const PageTitle = styled.div`
  color: ${colors.fullBlack};
  font-size: 28px;
  font-weight: 700;
  margin: 0;
  text-align: center;
  @media (max-width: ${Breakpoints.sm}px) {
    font-size: 24px;
    text-align: left;
    margin-left: 5%;
  }
`;

const PageSubBody = styled.div`
  color: ${colors.fullBlack};
  font-size: 16px;
  font-weight: 500;
  margin: 10px 10px 10px 10px;
  text-align: center;
  @media (max-width: ${Breakpoints.sm}px) {
    font-size: 24px;
    text-align: left;
    margin-left: 5%;
  }
`;


const BtnContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  flex-wrap: nowrap;
  padding-top: 20px;

  @media (min-width: 450px) {
    flex-direction: row;
  }
`;
const Space = styled.div`
  height: 10px;
  width: 20px;
`;

export const StyledButton = styled.button`
  border: 1px dashed ${colors.grey};
  border-radius: 10px;
  font-size: 16px;
  font-weight: 500;
  color: rgb(26 150 219);
  line-height: 24px;
  padding: 15px 28px;
  transition: all 0.15s ease-in-out;
  width: 100%;
  cursor: pointer;

  &:focus,
  &:active {
    border: 1px dotted ${colors.rebrandBlack};
    box-shadow: 0 0 4px ${(colors.white, 0.8)};
  }
  @media (max-width: ${Breakpoints.sm}px) {
    margin-top: 40px;
  }
  @media (min-width: ${Breakpoints.sm}px) and (max-width: ${Breakpoints.md}px)  {
    margin-left:2%;
    width: 98%;
  }
`;
const ButtonText = styled.div`
  display: inline-flex;
  align-items: center;
  position: relative;
  text-align: center;

  @media (max-width: ${Breakpoints.sm}px) {
    margin-left: 0px;
  }
`;
const MobileResponsiveGap = styled.div`
  margin-top: 0px;
  @media (max-width: ${Breakpoints.sm}px) {
    margin-top: 10px;
  }
`;

const InputRow = styled.div`
  display: flex;
  flex-direction: column;
  margin: -8px 0;

  @media (min-width: ${Breakpoints.sm}px) {
    flex-direction: row;
    margin: 0 -8px;
  }

  @media (min-width: ${Breakpoints.lg}px) {
    margin: 0 -15px;
  }

  & > * {
    flex-basis: 45%;
    margin: 8px 0;

    @media (min-width: ${Breakpoints.sm}px) {
      margin: 0 8px;
    }
    @media (min-width: ${Breakpoints.lg}px) {
      margin: 0 15px;
    }
  }
`;
const AddMinor = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: none;
  padding: 20px;
  margin-top: 30px;
  border: 2px dashed ${colors.grey};
  border-radius: 10px;
  color: ${colors.blueLight};
  margin-bottom: 20px;

  & > * {
    margin: 0 5px;
  }
  @media (min-width: ${Breakpoints.sm}px) and (max-width: ${Breakpoints.md}px)  {
    margin-left:2%;
    width: 98%;
  }
`;
const InsuranceContainer = styled.div`
  background: ${colors.white};
  margin: 0px 5px;
  margin-bottom: 15px;
  display: flex;
  border: 0.5px solid rgba(36, 61, 77, 0.75);
  border-radius: 12px;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px 30px;
    &:hover{
    background-color: #FDD56B;
    }
  @media (max-width: ${Breakpoints.sm}px) {
    padding: 40px 20px 40px 20px !important;
    margin: 0;
    position: relative;
    &:hover {
      background: none;
    }
  }
  @media (min-width: ${Breakpoints.sm}px) and (max-width: ${Breakpoints.md}px)  {
    margin-left:2%
  }
`;
const ExpandRight = styled.div`
  display: grid;
  width: 100%;
  row-gap: 30px;
  position: relative;
  top: -50px;
  @media (max-width: ${Breakpoints.sm}px) {
    top: 30px;
  }
`;
const InsurancelistTag = styled.div`
  display: flex;
  gap: 30px;
  @media (max-width: ${Breakpoints.sm}px) {
    display: block;
  }
`;
const Datetag = styled.div`
  display: flex;
  gap: 20px;
`;
const ExpandIcon = styled.div`
  position: relative;
  color: ${colors.grey};
`;
const DateAndPatient = styled.div`
  display: grid;
  row-gap: 30px;
`;
const WrapExpand = styled.div``;
const Expandtext = styled.div`
  font-size: 16px;
  font-weight: 500;
`;
const Patientstag = styled.div`
  display: flex;
  gap: 20px;
  @media (max-width: ${Breakpoints.sm}px) {
  }
`;
const Timetag = styled.div`
  display: flex;
  gap: 20px;
  @media (max-width: ${Breakpoints.sm}px) {
    margin-bottom: 30px;
  }
`;
const Testtag = styled.div`
  display: flex;
  gap: 20px;
`;
const WrapText = styled.div`
  color: black;
  font-size: 24px;
  font-weight: bold;
  text-align: left;
  @media (max-width: ${Breakpoints.sm}px) {
    font-size: 18px;
    text-align: left;
    line-height: 25px;
  }
`;
const SubNameandDob = styled.div`
  display: grid;
  @media (max-width: ${Breakpoints.sm}px) {
    row-gap: 30px;
  }
`;
const Card = styled.div`
  margin-top: 20px;
  @media (max-width: ${Breakpoints.sm}px) {
  }
`;
const ExpandCenter = styled.div``;
const ExpandVl = styled.div`
  border-left: 1px solid #2a5f87;
  height: 100%;
`;
const PageSubTitle = styled.h3`
  margin: 10px 0 10px 0;
`;
const InsurancePencilLogo = styled.div`
  height: 100%;
  width: auto;

  background: url(${pencil}) no-repeat center center;
  @media (max-width: ${Breakpoints.sm}px) {
  }
`;
const Logotag = styled.div`
  cursor: pointer;
`;
const EditTag = styled.div`
  display: flex;
  gap: 30px;
  @media (max-width: ${Breakpoints.sm}px) {
    gap: 10px;
  }
  @media (min-width: ${Breakpoints.sm}px) and (max-width: ${Breakpoints.md}px)  {
    margin-left:10%
  }
`;
const TitleTag = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const showImage = styled.div`
dispaly:block;
`;
const hideImage = styled.div`
display:none
`;
const InsuranceList: React.FC = () => {
  const { userData, userInsuranceData,userGovtId } = useSharedState();
  const { setloggedUserData, updateInsuranceList ,updateGovtId} = useSharedDispatch();
  console.log("UpdatedList", updateInsuranceList)
  let sortedDates = userInsuranceData?.sort((p1:any, p2:any) => (p1?.createdDate > p2?.createdDate) ? 1 : (p1?.createdDate < p2?.createdDate) ? -1 : 0);
  console.log("insuranceSort", sortedDates);
  const { updateFormValues } =useStepsDispatch()
  const [loading, setLoading] = useState(false);
  const [insurance, setInsurance] = useState<Insurance[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [editKey, setEditKey] = useState({ key: -1 });
  const [cancelKey, setCancelKey] = useState({ key: -1 });
  const [isGovtId,setIsGovtId]=useState(false)
  const [governmentId, setGovernmentId] = useState<GovtID[]>([]);

  const [addInsurance, setAddInsurance] = useState(false);
  const handleAddInsuranceOpen = () => {
    setAddInsurance(true);
  };
  const handleAddInsuranceClose = () => {
    setAddInsurance(false);
  };
  const [openInsuranceDialog, setopenInsuranceDialog] = React.useState<boolean>(
    false
  );
  const handleInsuranceOpen = () => {
    setopenInsuranceDialog(true);
  };
  const handleInsuranceClose = () => {
    setopenInsuranceDialog(false);
  };
  const handleShowList = () => {
    setAddInsurance(false);
  };
  const [opendeleteModal, setopendeleteModal] = React.useState<boolean>(false);
  const handledeleteOpen = () => {
    setopendeleteModal(true);
  };
  const handledeleteClose = () => {
    setopendeleteModal(false);
  };
  const [openCancelModal, setopenCancelModal] = React.useState<boolean>(
    false
  );
  const handleCancelOpen = () => {
    setopenCancelModal(true);
  };
  const handleCancelClose = () => {
    setopenCancelModal(false);
  };

  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const handleModalOpen = () => {
    setOpenModal(true);
  };
  const handleModalClose = () => {
    setOpenModal(false);
    getGovernmentId();
  };

  const getInsuranceData = async () => {
    try {
      setLoading(true);
      
      let resultData = await getUserInsurance(userData?.email);
      if (resultData?.data?.status === 200) {
        setInsurance(resultData?.data?.data);
        updateInsuranceList(resultData?.data?.data)
      } else {
        updateInsuranceList(null);
      }
      setLoading(false);
    } catch (err:any) {
      setError(err);
      setLoading(false);
    }
  };

  const getGovernmentId=async()=>{
    try{
      // let IdData=await getGovtId(userData?.email);
      let IdData = await getGovtId({email:userData?.email})
      if(IdData?.data.status===200){
        console.log("govtdatainsu",IdData.data.data)
        setGovernmentId(IdData.data.data);
        updateGovtId(IdData.data.data)
        setIsGovtId(true)
      }else{
        updateGovtId(null)
      }
      setLoading(false);
    }
    catch(error){
      setLoading(false);
    }
  }
  const classes = useStyles()
  useEffect(() => {
    getInsuranceData();
    getGovernmentId();
  }, [userData?.email]);

  let deleteFormFunction = () => {
    updateFormValues({
     insurance: {
       id: '',
       group: '',
       subscriberFirstName: '',
       subscriberLastName: '',
       insuranceId: '',
       // payerList: "Anthem",
       payerList: '',
       frontCard: '',
       backCard: '',
       insuranceFor: '',
       policyHolderFirstName: '',
       policyHolderLastName: "",
       policyHolderbirthDate:  '',
       pVerify: false,
       subscriberbirthDate:''
     },
   });
   }    
  if (loading) {
    return <Loader />;
  }

  return (
    <Mainpage>
      <PageHeader>
        {addInsurance ? (
          <>
            <PageTitle>
              Please enter your insurance billing information
            </PageTitle>
            {/* <PageSubBody>
              Please note that we currently do not accept insurance for school districts. <br/> Check back soon for using insurance for a general test. <br/> Insurance will not be valid for travel purposes.
            </PageSubBody> */}
          </>
        ) : (
          <PageTitle
            style={{
              height: '42px',
              top: '30px',
              position: 'relative',
            }}
          >
            Your insurance details
          </PageTitle>
        )}
      </PageHeader>
      <Container marginTop insurance>
        {addInsurance ? (
          <AddInsuranceList
            close={handleAddInsuranceClose}
            showlist={handleShowList}
          />
        ) : (
          <>
            {sortedDates?.length ? (
              <>
                {sortedDates.map((insurance, i: number) => (
                  <>
                  <TitleTag>
                    <EditTag>
                      <Logotag
                        style={{ width: '20px' }}
                        onClick={() => {
                          handleInsuranceOpen();
                          setEditKey({ key: editKey.key === i ? -1 : i });
                        }}
                      >
                        {/* <InsurancePencilLogo /> */}
                        <EditOutlined />
                      </Logotag>

                        <PageSubTitle>
                          <b>Insurance - {i + 1}</b>
                        </PageSubTitle>
                      </EditTag>
                      <Logotag
                        style={{ width: '20px' }}
                        onClick={() => {
                          setCancelKey({ key: cancelKey.key === i ? -1 : i });
                          handleCancelOpen();
                        }}
                      >
                        <RiDeleteBinLine size={25} />
                      </Logotag>
                    </TitleTag>
                    <InsuranceContainer
                    >
                      <WrapExpand>
                        <ExpandRight style={{ top: '0px' }}>
                          <InsurancelistTag>
                            <DateAndPatient>
                              <Datetag>
                                <ExpandIcon>
                                  <IoPersonOutline size={25} />
                                </ExpandIcon>
                                <Expandtext>
                                  {' '}
                                  <strong>Insurance Carrier Name</strong>
                                  <br />
                                  {insurance.payerList}
                                  <br />
                                </Expandtext>
                              </Datetag>
                              <Datetag>
                                <ExpandIcon>
                                  <BiIdCard size={25} />
                                </ExpandIcon>
                                <Expandtext>
                                  {' '}
                                  <strong>Insurance Member ID</strong>
                                  <br />
                                  {insurance.insuranceId}
                                  <br />
                                </Expandtext>
                              </Datetag>
                              <Patientstag>
                                <ExpandIcon>
                                  <IoPeopleOutline size={25} />
                                </ExpandIcon>
                                <Expandtext>
                                  {' '}
                                  <strong>Insurance Group</strong>
                                  <br />
                                  {insurance.group}
                                  <br />
                                </Expandtext>
                              </Patientstag>
                              <Timetag>
                                <ExpandIcon>
                                  {' '}
                                  <IoPersonOutline size={25} />
                                </ExpandIcon>
                                <Expandtext>
                                  {' '}
                                  <strong> Subscriber Name </strong> <br />
                                  {insurance.subscriberFirstName}{' '}
                                  {insurance.subscriberLastName}
                                  <br />
                                </Expandtext>
                              </Timetag>
                            </DateAndPatient>
                            <ExpandCenter>
                              <ExpandVl></ExpandVl>
                            </ExpandCenter>
                            <SubNameandDob>
                              <Testtag>
                                <ExpandIcon>
                                  {' '}
                                  <AiOutlinePicture size={25} />
                                </ExpandIcon>
                                <Expandtext>
                                  {' '}
                                  <strong>Front copy of the card</strong> <br />
                                  <Card>
                                    <img
                                      width="100"
                                      height="100"
                                      alt="No Image"                                         
                                      src={insurance?.frontCard && insurance?.frontCard !== null? insurance?.frontCard : ""}
                                    //   onError={(e:any) => e.target.style.display='none' }   
                                       className={insurance?.frontCard == null || insurance?.frontCard == "" ? classes.hideImage: classes.showImage}
                                    />
                                  </Card>
                                </Expandtext>
                              </Testtag>
                              <Testtag>
                                <ExpandIcon>
                                  {' '}
                                  <AiOutlinePicture size={25} />
                                </ExpandIcon>
                                <Expandtext>
                                  {' '}
                                  <strong>Back copy of the card</strong> <br />
                                  <Card>
                                    <img
                                      width="100"
                                      height="100"
                                      src={insurance?.backCard && insurance?.backCard !== null? insurance?.backCard : ""}
                                      className={insurance?.backCard == null || insurance?.backCard === "" ? classes.hideImage: classes.showImage}
                                    //  onError={(e:any) => e.target.style.display='none' } 
                                    />
                                  </Card>
                                </Expandtext>
                              </Testtag>
                            </SubNameandDob>
                          </InsurancelistTag>
                        </ExpandRight>
                      </WrapExpand>
                      {editKey.key === i && (
                        <ChangeInsuranceModal
                          close={handleInsuranceClose}
                          open={openInsuranceDialog}
                          insuranceList={{...insurance,subscriberbirthDate:insurance?.subscriberbirthDate ?? userData?.birthDate}}
                          isProfile={false}
                        />
                      )}
                      {cancelKey.key === i && (
                        <CancelInsuranceModal
                          close={handleCancelClose}
                          open={openCancelModal}
                          insuranceList={insurance}
                        />
                      )}
                    </InsuranceContainer>
                  </>
                ))}
              </>
            ) : (
              <>
                {' '}
                <InsuranceContainer>
                  <WrapText>{'You have no insurance details'}</WrapText>
                </InsuranceContainer>
                <Space />
              </>
            )}
            {!addInsurance && (
              <AddMinor
                type="button"
                style={{
                  background: '#FFFF',
                  border: '2px dashed #619C8A',
                  color: '#243d4d',
                  fontStyle: 'normal',
                  fontWeight: '700',
                  fontSize: '16px',
                  lineHeight: '24px',
                }}
                onClick={() => {
                  setAddInsurance(true);
                  deleteFormFunction()
                }}
              >
                <BiPlus size={25} />
                Add Insurance
              </AddMinor>
            )}
          </>
        )}
      </Container>
      <PageHeader>
        <PageTitle
          style={{
            height: '42px',
            top: '30px',
            position: 'relative',
          }}
        >
          Your Government ID{' '}
        </PageTitle>
      </PageHeader>
      <Container marginTop insurance>
        <>
          {governmentId?.length ? (
            <>
              {governmentId.map((govtID, i: number) => (
                <>
                  {console.log('govtIds', govtID)}
                  <TitleTag>
                    <EditTag>
                      <PageSubTitle>
                        <b>Government ID - {i + 1}</b>
                      </PageSubTitle>
                    </EditTag>
                    <Logotag
                      style={{ width: '20px' }}
                      onClick={() => {
                        setCancelKey({ key: cancelKey.key === i ? -1 : i });
                        handledeleteOpen();
                      }}
                    >
                      <RiDeleteBinLine size={25} />
                    </Logotag>
                  </TitleTag>
                  <InsuranceContainer
                    key={i}
                  >
                    <WrapExpand>
                      <ExpandRight style={{ top: '0px' }}>
                        <InsurancelistTag>
                          <SubNameandDob>
                            <Testtag>
                              <ExpandIcon>
                                {' '}
                                <AiOutlinePicture size={25} />
                              </ExpandIcon>
                              <Expandtext>
                                {' '}
                                <strong>Front copy of the card</strong> <br />
                                <Card>
                                  <img
                                    width="100"
                                    height="100"
                                    src={
                                      govtID?.frontCard ? govtID?.frontCard : ''
                                    }
                                  />
                                </Card>
                              </Expandtext>
                            </Testtag>
                            <Testtag>
                              <ExpandIcon>
                                {' '}
                                <AiOutlinePicture size={25} />
                              </ExpandIcon>
                              <Expandtext>
                                {' '}
                                <strong>Back copy of the card</strong> <br />
                                <Card>
                                  <img
                                    width="100"
                                    height="100"
                                    src={
                                      govtID?.backCard ? govtID?.backCard : ''
                                    }
                                  />
                                </Card>
                              </Expandtext>
                            </Testtag>
                          </SubNameandDob>
                        </InsurancelistTag>
                      </ExpandRight>
                    </WrapExpand>
                    {cancelKey.key === i && (
                      <CancelGovernmentId
                        close={handledeleteClose}
                        open={opendeleteModal}
                        idList={govtID}
                      />
                    )}
                  </InsuranceContainer>
                </>
              ))}
            </>
          ) : (
            <>
              {' '}
              <InsuranceContainer>
                <WrapText>{'You have no Government Id details'}</WrapText>
              </InsuranceContainer>
              <Space />
            </>
          )}
          {!addInsurance && (
            <>
              <StyledButton
                type="button"
                style={{
                  background: '#FFFF',
                  border: '2px dashed #619C8A',
                  color: '#243d4d',
                  fontStyle: 'normal',
                  fontWeight: '700',
                  fontSize: '16px',
                  lineHeight: '24px',
                }}
                onClick={() => {
                  // setTime(true);
                  handleModalOpen();
                }}
              >
                <ButtonText>
                  <BiPlus size={25} /> <Space />{' '}
                  {'Upload A Picture Of Your Government ID'}
                </ButtonText>
              </StyledButton>
              <DrivingLicenseModel
                handleModalClose={handleModalClose}
                open={openModal}
              />
            </>
          )}
        </>
      </Container>
    </Mainpage>
  );
};

export default InsuranceList;

//ltst