import React,{useEffect} from 'react';
import { Formik, Form } from 'formik';

import {
  Container,
  Content,
  SubHeaderDescription,
} from '../../shared/components/styled';
// import { useStepsDispatch } from '../provider';
import {useStepsState,useStepsDispatch} from '../../schedule/provider'
import styled from 'styled-components';
import YesNoButtonGroup from '../../shared/components/form/YesNoButtonGroup';
import NavigationButtons from '../../shared/components/NavigationButtons';
import WhyAreWeAsking from '../../shared/components/WhyAreWeAsking';
import { colors } from '../../../styles/colors';
import { Breakpoints } from '../../../dictionaries';
import mixpanel from 'mixpanel-browser';
import GoogleAnalytics from '../../../GoogleAnalytics';

export const PageHeader = styled.div`
  margin-bottom: 0px;
`;
export const PageTitle = styled.h2`
  color: ${colors.fullBlack};
  font-size:  28px ;
  font-weight: 700;
  margin: 0;
  text-align: left;

  @media (max-width: ${Breakpoints.sm}px) {
    font-size: 25px;
    margin-top: 30%;
  }

`;

const Conditions: React.FC = () => {
  const {
    form: { hasConditions },form
  } = useStepsState();
  const { updateFormValues, goToNextStep, goToPrevStep } = useStepsDispatch();
    useEffect(()=>{
      GoogleAnalytics(form,'/Chronic Medical Conditions');
  },[])

  return (
    <Container size="xl">
      <Content>
        <PageHeader >
          <PageTitle>
          Do you have chronic medical conditions that can increase the risk of
          severe COVID 19 illness?{' '}
          <WhyAreWeAsking tooltipContent="These questions are based on COVID-19 guidelines from the Centers for Disease Control and Prevention (CDC) and the World Health Organization (WHO) to remind guests of basic safety requirement and asks a few simple questions to ensure they are fit to fly. The form, which is displayed when checking in to your flight on the desktop or mobile website, and airport self-service kiosks, brings an additional layer of safety for guests and employees." />
          </PageTitle>
        </PageHeader>
        <Container noPadding marginTop>
          {/* <SubHeaderDescription>
            Please see the{' '}
            <a
              href="https://www.cdc.gov/coronavirus/2019-ncov/need-extra-precautions/index.html"
              target="_blank"
              rel="noreferrer noopener"
            >
              CDC Guidance for People at Increased Risk for Severe Illness
            </a>
            .
          </SubHeaderDescription> */}
          <Formik
            initialValues={{
              hasConditions,
            }}
            onSubmit={(values) => {
              updateFormValues(values);
              mixpanel.track("medical conditions page");
              goToNextStep();
              console.log("conditions", values)
            }}
          >
            {() => (
              <Form>
                <YesNoButtonGroup
                  name="hasConditions"
                  onChange={(answer) =>{
                    updateFormValues({ hasConditions: answer })
                    goToNextStep()
                  }}
                  isRequired
                />
                <NavigationButtons onReturnButtonClick={() => { goToPrevStep(); }} isValid={hasConditions !== null ? true : false} />
              </Form>
            )}
          </Formik>
        </Container>
      </Content>
    </Container>
  );
};

export default Conditions;
